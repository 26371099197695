import type { EmployeesTableItem, TableItemView } from '@types';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { ErrorMessages } from 'src/enums';
import BaseViewModel from '../BaseViewModel';
import type { GetEmployeesResponseItemWithRequiredId } from './EmployeesStore';

/** Вью модель элемента списка */
export class EmployeesItemViewModel extends BaseViewModel<GetEmployeesResponseItemWithRequiredId> implements TableItemView<
EmployeesTableItem,
GetEmployeesResponseItemWithRequiredId
> {
  constructor(data: GetEmployeesResponseItemWithRequiredId) {
    super(data);
    makeObservable(this);
  }

  /** фио */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Изменение ФИО */
  set id(value: string) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** выбран ли сотрудник */
  @observable
    selected: boolean = false;

  /** фио */
  @computed get fio() {
    return this.data.fullName ?? '';
  }

  /** Изменение ФИО */
  set fio(value: string) {
    runInAction(() => {
      this.data.fullName = value;
      this.validateField(
        'fio',
        (val) => {
          if (/[!@#$%^%&*()_+\-=:?"]/.test(val ?? '')) {
            return ErrorMessages.fio;
          }
          return undefined;
        },
      );
    });
  }

  /** ДР */
  @computed get birthdate() {
    return this.data.birthdate ? dayjs(this.data.birthdate) : undefined;
  }

  /** Изменение ДР */
  set birthdate(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.birthdate = value?.toISOString();
    });
  }

  /** Ближайшая карьерная встреча */
  @computed get nextCareerMeeting() {
    return this.data.nextCareerMeeting ? dayjs(this.data.nextCareerMeeting) : undefined;
  }

  /** Изменение Ближайшая карьерная встреча */
  set nextCareerMeeting(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.nextCareerMeeting = value?.toISOString();
    });
  }

  /** Специализация */
  @computed get mainSpecializationName() {
    return this.data.mainSpecializationName ?? '';
  }

  /** Изменение Специализации */
  set mainSpecializationName(value: string | undefined | null) {
    runInAction(() => {
      this.data.mainSpecializationName = value;
    });
  }

  /** Основная квалификация */
  @computed get positionName() {
    return this.data.positionName ?? '';
  }

  /** Изменение Основная квалификация */
  set positionName(value: string | undefined | null) {
    runInAction(() => {
      this.data.positionName = value;
    });
  }

  /** Получение "Актуальная ставка в час" */
  @computed get actualWorkingRate() {
    return this.data.actualWorkingRate;
  }

  /** Изменение "Актуальная ставка в час" */
  set actualWorkingRate(value: number | undefined | null) {
    runInAction(() => {
      this.data.actualWorkingRate = value;
    });
  }

  /** Получение "Актуальный оклад" */
  @computed get actualSalary() {
    return this.data.actualSalary;
  }

  /** Изменение "Актуальный оклад" */
  set actualSalary(value: number | undefined | null) {
    runInAction(() => {
      this.data.actualSalary = value;
    });
  }

  /** Наставник ФИО */
  @computed get mentorName() {
    return this.data.mentorName ?? '';
  }

  /** Изменение ФИО наставника  */
  set mentorName(value: string | undefined | null) {
    runInAction(() => {
      this.data.mentorName = value;
    });
  }

  /** Наставник Id */
  @computed get mentorId() {
    return this.data.mentorId ?? '';
  }

  /** Изменение ФИО наставника  */
  set mentorId(value: string | undefined | null) {
    runInAction(() => {
      this.data.mentorId = value;
    });
  }

  /** Удалённая работа */
  @computed get isRemoteWorkText() {
    if (this.data.isRemoteWork === null) {
      return '';
    }
    return this.data.isRemoteWork ? 'Да' : 'Нет';
  }

  /** Удалённая работа */
  @computed get isRemoteWork() {
    return this.data.isRemoteWork ?? false;
  }

  /** Изменение Удалённой работы */
  set isRemoteWork(value: boolean) {
    runInAction(() => {
      this.data.isRemoteWork = value;
    });
  }

  /** Наименование основной квалификации */
  @computed get mainQualificationName() {
    return this.data.mainQualificationName;
  }

  /** Изменение Наименование основной квалификации */
  set mainQualificationName(value: string | null | undefined) {
    runInAction(() => {
      this.data.mainQualificationName = value;
    });
  }

  /** Проекты */
  @computed get projects() {
    return this.data.resourceInProjects?.map(({ projectId, projectName }) => ({
      projectId, projectName,
    }));
  }

  /** Электронная почта */
  @computed get email() {
    return this.data.email;
  }

  /** Изменение Электронная почта */
  set email(value: string | null | undefined) {
    runInAction(() => {
      this.data.email = value;
    });
  }

  /** Телефон */
  @computed get phone() {
    return this.data.phone;
  }

  /** Изменение Телефон */
  set phone(value: string | null | undefined) {
    runInAction(() => {
      this.data.phone = value;
    });
  }

  /** Дата карьерной встречи */
  @computed get meetingDate() {
    return dayjs(this.data.nextCareerMeeting);
  }

  /** Получить 'Дата архивации' */
  @computed get deletedOn() {
    return this.data.deletedOn ? dayjs(this.data.deletedOn) : undefined;
  }

  /** Изменить 'Дата архивации' */
  set deletedOn(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.deletedOn = value?.toISOString();
    });
  }

  /** Получение сырых данных */
  getRawData() {
    return toJS(this.data);
  }
}
