import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import type { SelectOption } from '@components/ui/Select';
import { Select } from '@components/ui/Select';
import { useStores } from '@hooks';
import { runInAction } from 'mobx';
import { periods } from '@services/utils';
import ProjectTable from '@components/CalendarTable/ProjectList/ProjectTable';
import dayjs from 'dayjs';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import Card from '@components/ui/Card/Card';
import IconDescriptions from '@components/CalendarTable/IconDescriptions';

/** Блок с таблицей нагрузок */
const ProjectLoads: React.FC = () => {
  const { projectLoadStore } = useStores();

  /** Выбрать период */
  const selectPeriod = useCallback(
    (val?: SelectOption) => {
      if (val) {
        runInAction(() => {
          projectLoadStore.period = Number(val.value);
        });
      }
    },
    [projectLoadStore],
  );

  /** Кнопки хедера карточки */
  const headerButtons = useMemo(() => (
    <div className="flex flex-1 w-full justify-end gap-2 flex-wrap">
      <div className="flex items-center gap-2">
        <div>С даты:</div>
        <DatePicker
          clearable={false}
          height={30}
          onChange={(value) => {
            runInAction(() => {
              if (value) {
                runInAction(() => {
                  projectLoadStore.startDate = value.toDate();
                });
              }
            });
          }}
          value={dayjs(projectLoadStore.startDate)}
          utc
        />
      </div>
      <Select.Base
        clearable={false}
        height={30}
        onSelect={selectPeriod}
        options={periods}
        selectedOption={periods.find((e) => e.value === String(projectLoadStore.period))}
      />
    </div>
    //* WARN Зависимости правильные
  ), [selectPeriod, projectLoadStore.startDate, projectLoadStore.period]);

  if (!projectLoadStore.viewModel.length) {
    return null;
  }

  return (
    <Card
      headerButtons={headerButtons}
      headerLabel="Нагрузки"
    >
      <ProjectTable
        className="flex-1"
        columnWidth={25}
        listCellWidth={300}
        rowHeight={25}
      />
      <IconDescriptions
        className="p-2 border-1"
        isVisible={false}
      />
    </Card>

  );
};

export default observer(ProjectLoads);
