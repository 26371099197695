import React, { useEffect, useRef } from 'react';
import { HiOutlineChevronDoubleLeft, HiExclamationCircle } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { parseStyles, tokenName } from '@services/utils';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import { useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import { Logo } from '@assets';
import Image from '@components/ui/Image/Image';
import type { RouteItem } from 'src/routes';
import projectRoutes from 'src/routes';
import jwtDecode from 'jwt-decode';
import { SiteTitle } from 'src/enums';
import { TbDiamond } from 'react-icons/tb';
import MenuItem from './MenuItem';
import { MenuLayout } from './MenuLayout';
import ProfileMenu from './ProfileMenu';

/** Возвращает роуты только с именами и путями (рекурсивно) */
const getMenuRoutes = (routes: RouteItem[]): RouteItem[] => {
  const result = routes.flatMap((e) => (e.name
    ? e
    : (e.children?.map((child) => ({ ...child, path: `${e.path === '/' ? '' : e.path}${child.path}` })) ?? [])));

  if (result.some((e) => !e.name)) {
    return getMenuRoutes(result);
  }

  return result;
};

/** route items для левого меню */
const routes = getMenuRoutes(projectRoutes);

/** Компонент левого меню */
const LeftMenu: React.FC = () => {
  const { accountStore, privilegesStore, organizationStore } = useStores();
  const token = useReadLocalStorage<string | undefined>(tokenName);
  const isActive = token ? jwtDecode<{ IsActive: string }>(token).IsActive === 'true' : false;
  const [isOpen, toggle] = useLocalStorage(process.env.REACT_APP_MENU_OPEN || 'userMenuIsOpen', false);
  const ref = useRef(null);
  const navigate = useNavigate();

  /** Получение данных организации */
  useEffect(() => {
    organizationStore.fetch(accountStore.organizationId);

    return () => {
      organizationStore.clearStore();
    };
  }, [accountStore.organizationId, organizationStore]);

  /** Вью модель Личного кабинета */
  const { currentSubscription } = accountStore;
  const organizationViewModel = organizationStore.viewModel;

  return (
    <MenuLayout
      ref={ref}
      isOpen={isOpen}
    >
      <div>
        <div
          className="flex  items-center p min-h-menuItemH text-white p-2 cursor-pointer"
          onClick={() => navigate('/')}
        >
          <div className="min-h-[30px] min-w-[30px]">
            {organizationViewModel.logoLink
              ? (
                <Image
                  alt="organizationPicture"
                  className={parseStyles`
                    rounded-full object-cover h-[30px] w-[30px]
                    ${!currentSubscription.hasLimits && 'border-2 border-main'} 
                  `}
                  imageLink={organizationViewModel.logoLink}
                  title="У вас есть активная подписка. Вы потрясающие!"
                />
              )
              : (
                <Logo
                  className={parseStyles`
                    rounded-full object-cover h-[30px] w-[30px]
                    ${!currentSubscription.hasLimits && 'border-2 border-main'} 
                  `}
                />
              )}
          </div>
          {isOpen && (
            <div className="flex flex-1 items-center justify-between">
              <div className={parseStyles`ml-4 mr-2 opacity-80 `}>
                <div className="text-label !text-white">{SiteTitle}</div>
                {organizationViewModel.name ?? ''}
              </div>
              {currentSubscription.hasLimits
                ? (
                  <HiExclamationCircle
                    className="fill-[#FCE72F] flex-[auto_0_0] cursor-help min-h-[20px] min-w-[20px]"
                    size={20}
                    title="Вы используете Trial версию подписки. Перейдите в настройки организации для выбора тарифного плана."
                  />
                )
                : (
                  <TbDiamond
                    className="fill-main text-[#F7F6F0] min-h-[20px] min-w-[20px]"
                    size={20}
                    title="У вас есть активная подписка. Вы потрясающие!"
                  />
                )}
            </div>
          )}
        </div>
        <hr className="opacity-40" />
        {isActive && routes.filter((route) => {
          if (route.checkAccess) {
            if (route.checkAccess(privilegesStore)) {
              return route;
            }
            return null;
          }
          return route;
        }).map((item) => (
          <MenuItem
            key={item.id}
            route={item}
          />
        ))}
      </div>
      <div>
        <ProfileMenu />
        <div
          className={parseStyles`
            p-2 flex justify-end hover:bg-white hover:bg-opacity-20 hover:ease-out duration-300
            cursor-pointer transition-all
          `}
          onClick={() => toggle(!isOpen)}
        >
          <HiOutlineChevronDoubleLeft
            className={parseStyles`
              transition-all duration-[500ms]
              ${!isOpen && 'rotateY-180'}
            `}
            color="white"
            size={25}
          />
        </div>
      </div>
    </MenuLayout>
  );
};

export default observer(LeftMenu);
