import {
GetProjectRequirementEmployeeByIdResponse,
GetProjectRequirementEmployeesResponse,
PostProjectRequirementEmployeeRequest,
PostProjectRequirementEmployeeResponse,
ProblemDetails,
ProblemDetailsResponse,
PutProjectRequirementEmployeeByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class ProjectRequirementEmployeeService {
  static get RoutePath(): string {
    return "ProjectRequirementEmployee";
  }

  /**
   * Получение потребности проекта в сотруднике по ид
   *
   * @request GET:/api/v1.0/ProjectRequirementEmployee/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetProjectRequirementEmployeeByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление потребности проекта в сотруднике
   *
   * @request PUT:/api/v1.0/ProjectRequirementEmployee/{id}
   */
  static update = async (id: string, data: PutProjectRequirementEmployeeByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка потребностей проектов в сотруднике
   *
   * @request GET:/api/v1.0/ProjectRequirementEmployee
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      positions?: string[];
      specializations?: string[];
      minMaxWorkingRate?: number;
      maxMaxWorkingRate?: number;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetProjectRequirementEmployeesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание потребности проекта в сотруднике
   *
   * @request POST:/api/v1.0/ProjectRequirementEmployee
   */
  static create = async (data: PostProjectRequirementEmployeeRequest, params: RequestParams = {}) =>
    httpClient.request<PostProjectRequirementEmployeeResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}