import { Close } from '@assets';
import { parseStyles } from '@services/utils';
import React from 'react';
import type { TagProps } from './types';

/** Компонент тэг для мультиселектов */
const Tag: React.FC<TagProps> = ({
  option,
  onClear,
  disabled,
}) => (
  <div
    key={option.value}
    className={
      parseStyles`
      flex rounded-full text-tag items-center pl-2 pr-1 py-1 h-min break-all bg-btn-main-bg gap-1
        ${disabled ? 'bg-disabled' : 'bg-btn-main-bg pr-1'}
      `
    }
  >
    <div>{option.label}</div>
    {!disabled && (
      <div
        className="p-1 transition-colors rounded-full cursor-pointer hover:bg-btn-danger-bg active:bg-btn-danger-bg-hover"
        onClick={(e) => {
          e.stopPropagation();
          onClear(option);
        }}
      >
        <Close className="pointer-events-none p-[2px] contrast-200" />
      </div>
    )}
  </div>
);

export default Tag;
