import type { RolesTableItem, TableItemView } from '@types';
import type { EntityActions, GetRolesResponseItem } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  toJS,
} from 'mobx';
import { ErrorMessages } from 'src/enums';
import BaseViewModel from '../../BaseViewModel';

/** Вью модель роли */
export class RoleViewModel extends BaseViewModel<GetRolesResponseItem>
  implements TableItemView<RolesTableItem, GetRolesResponseItem> {
  constructor(data: GetRolesResponseItem) {
    super(data);
    makeObservable(this);
  }

  /** Выбрана ли роль */
  @observable
    selected: boolean = false;

  /** Респонс роли с сервера */
  getRawData() {
    return toJS(this.data);
  }

  /** Идентификатор роли */
  @computed get id() {
    return this.data.roleId ?? '';
  }

  /** Наименование роли */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение наименования роли */
  set name(value: string) {
    this.data.name = value;
    this.validateField(
      'name',
      (val) => {
        if (this._validationInProgress && !val) {
          return ErrorMessages.requiredField;
        }
        return undefined;
      },
    );
  }

  /** Описание роли */
  @computed get description() {
    return this.data.description ?? '';
  }

  /** Изменение описания роли */
  set description(value: string) {
    this.data.description = value;
  }

  /** id организации */
  @computed get organizationId() {
    return this.data.organizationId;
  }

  /** Привилегии роли */
  @computed get entityActions() {
    return this.data.entityActions ?? [];
  }

  /** Изменение привилегий роли */
  set entityActions(value: EntityActions[]) {
    this.data.entityActions = value;
  }
}
