import { EntityActions } from '@typesApi';
import { computed, makeObservable } from 'mobx';
import type { BasePrivileges } from './types';

export default class RolesPrivileges implements BasePrivileges {
  private _privileges: EntityActions[];

  constructor(privileges: EntityActions[]) {
    makeObservable(this);
    this._privileges = privileges;
  }

  @computed get canCreate() {
    return this._privileges.includes(EntityActions.CreateRoles);
  }

  @computed get canDelete() {
    return this._privileges.includes(EntityActions.DeleteRoles);
  }

  @computed get canUpdate() {
    return this._privileges.includes(EntityActions.UpdateRoles);
  }

  @computed get canRead() {
    return this._privileges.includes(EntityActions.ReadRoles);
  }

  @computed get canReadProjectRoles() {
    return this._privileges.includes(EntityActions.ReadProjectRoles);
  }

  @computed get canCreateProjectRoles() {
    return this._privileges.includes(EntityActions.CreateProjectRoles);
  }

  @computed get canUpdateProjectRoles() {
    return this._privileges.includes(EntityActions.UpdateProjectRoles);
  }

  @computed get canDeleteProjectRoles() {
    return this._privileges.includes(EntityActions.DeleteProjectRoles);
  }
}
