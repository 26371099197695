import type { TableItemView } from '@types';
import type { GetEmployeeByIdResponseResourceInProject } from '@typesApi';
import { computed, makeObservable, runInAction } from 'mobx';
import BaseViewModel from '../BaseViewModel';

export interface EmployeeProjectFields {
  projectName: string;
  id: string;
  workingRateInProject: number | undefined;
  roles: string;
}

/** Вью модель элемента списка */
export class EmployeeProjectsItemViewModel extends BaseViewModel<GetEmployeeByIdResponseResourceInProject> implements TableItemView<
EmployeeProjectFields,
GetEmployeeByIdResponseResourceInProject
> {
  constructor(data: GetEmployeeByIdResponseResourceInProject) {
    super(data);
    makeObservable(this);
  }

  /** id */
  @computed get id() {
    return this.data.resourceInProjectId ?? '';
  }

  /** Изменение id */
  set id(value: string) {
    runInAction(() => {
      this.data.projectId = value;
    });
  }

  /** название */
  @computed get projectName() {
    return this.data.projectName ?? '';
  }

  /** Изменение название */
  set projectName(value: string) {
    runInAction(() => {
      this.data.projectName = value;
    });
  }

  /** Ставка на проекте */
  @computed get workingRateInProject() {
    return this.data.workingRateInProject ?? undefined;
  }

  /** Изменение Ставка на проекте */
  set workingRateInProject(value: number | undefined) {
    runInAction(() => {
      this.data.workingRateInProject = value;
    });
  }

  /** Ставка на проекте */
  @computed get roles() {
    return this.data.projectRoles?.map((e) => e.projectRoleName).join(',\n') ?? '';
  }
}
