import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useInterceptChanges, useStores } from '@hooks';
import { FormInput } from '@components/ui/Input';
import { Select } from '@components/ui/Select';
import DatePicker from '@components/ui/DatePicker/DatePicker';

/** Форма создания техники */
const CreateEquipmentForm: React.FC<{changeDirty: () => void}> = ({ changeDirty }) => {
  const { equipmentStore } = useStores();
  const { viewModel, isDirty } = useInterceptChanges(equipmentStore.viewModel);

  useEffect(() => {
    if (isDirty) {
      changeDirty();
    }
  }, [changeDirty, isDirty]);

  return (
    <div className="flex flex-col gap-2">
      <FormInput
        error={viewModel.errors.name}
        label="Наименование"
        onChange={(event) => {
          viewModel.name = event.target.value;
        }}
        value={viewModel.name}
      />
      <Select.Catalog
        catalogIdField="typeId"
        catalogName="Equipment"
        catalogNameField="typeId"
        error={viewModel.errors.typeName}
        label="Тип техники"
        onSelect={(option) => {
          viewModel.typeId = option?.value;
          viewModel.typeName = option?.label;
        }}
        viewModel={viewModel}
      />
      <FormInput
        label="Инвентарный номер"
        onChange={(event) => {
          viewModel.inventoryNumber = event.target.value;
        }}
        value={viewModel.inventoryNumber}
      />
      <FormInput
        label="Параметры"
        onChange={(event) => {
          viewModel.parameters = event.target.value;
        }}
        value={viewModel.parameters}
      />
      <Select.Async
        label="Сотрудник"
        onChange={(search) => equipmentStore.fetchEmployeeNames(search)}
        onSelect={(option) => {
          viewModel.employeeName = option?.label;
          viewModel.employeeId = option?.value;
        }}
        selectedOption={(viewModel.employeeId && viewModel.employeeName)
          ? { label: viewModel.employeeName, value: viewModel.employeeId }
          : undefined}
        clearable
      />
      <Select.Async
        label="Помещение"
        onChange={(search) => equipmentStore.fetchPlaceNames(search)}
        onSelect={(option) => {
          viewModel.placeName = option?.label;
          viewModel.placeId = option?.value;
        }}
        selectedOption={viewModel.selectedPlace}
        clearable
      />
      <DatePicker
        className="border-1"
        label="Дата начала использования"
        onChange={(value) => {
          viewModel.useStartDate = value;
        }}
        value={viewModel.useStartDate}
      />
      <DatePicker
        className="border-1"
        label="Дата окончания срока службы"
        onChange={(value) => {
          viewModel.endOfLifeDate = value;
        }}
        value={viewModel.endOfLifeDate}
      />
      <DatePicker
        className="border-1"
        label="Последнее ТО"
        onChange={(value) => {
          viewModel.lastTechnicalInspection = value;
        }}
        value={viewModel.lastTechnicalInspection}
      />
      <DatePicker
        className="border-1"
        label="Следующее ТО"
        onChange={(value) => {
          viewModel.nextTechnicalInspection = value;
        }}
        value={viewModel.nextTechnicalInspection}
      />
      <FormInput
        label="Начальная стоимость"
        onChange={(event) => {
          viewModel.initialCost = +event.target.value;
        }}
        type="number"
        value={viewModel.initialCost}
      />
    </div>
  );
};

export default observer(CreateEquipmentForm);
