import { ResourceService } from '@servicesApi';
import type { ListBaseFilter, Period } from '@types';
import type { GetResourceInProjectByIdResponseLoadResourceInProject } from '@typesApi';
import type { Dayjs } from 'dayjs';
import { action, makeObservable, observable } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import { LoadResourceInProjectsItemViewModel } from './LoadResourceInProjectsItemViewModel';

/** Стор сущности */
export class LoadResourceInProjectStore extends BaseEntityListStore<
GetResourceInProjectByIdResponseLoadResourceInProject,
ListBaseFilter,
LoadResourceInProjectsItemViewModel> {
  @observable private privateData: GetResourceInProjectByIdResponseLoadResourceInProject[] = [];

  public async fetch(): Promise<void> {
    this.updateData();
  }

  constructor(data: GetResourceInProjectByIdResponseLoadResourceInProject[]) {
    super(20);
    makeObservable(this);
    this.privateData = observable.array(data);
    this.updateData();
  }

  /** Обновление данных */
  private updateData() {
    this.setData({
      entities: this.privateData?.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize),
      totalCount: this.privateData?.length,
    } as {
      entities: GetResourceInProjectByIdResponseLoadResourceInProject[];
      totalCount: number;
    }, LoadResourceInProjectsItemViewModel);
  }

  /** Добавление нагрузок ресурсу */
  public async addLoad(resourceInProjectId: string, load: Period) {
    await this.runWithStateControl(async () => {
      await ResourceService.resourceInProjectUpdate(resourceInProjectId, {
        ...load,
        startDate: load.startDate.toISOString() ?? '',
        finishDate: load.finishDate.toISOString() ?? '',
      });
    });
  }

  /** Удалить период */
  @action async deleteLoad(resourceInProjectId: string, startDate?: Dayjs | null, finishDate?: Dayjs | null) {
    await this.runWithStateControl(async () => {
      await ResourceService.loadResourceInProjectDelete(
        resourceInProjectId,
        {
          startDate: startDate?.toISOString(),
          finishDate: finishDate?.toISOString(),
        },
      );
    });
  }
}
