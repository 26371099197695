import { Accordion, Panel, Table } from '@components/ui';
import Button from '@components/ui/Button';
import Errors from '@components/ui/Errors/Errors';
import { Column } from '@components/ui/Table';
import { useConfirm, useStores } from '@hooks';
import type { GetResourcesInProjectItem } from '@typesApi';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MdDelete } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import type ResourcesInProjectViewModel from 'src/stores/Project/ResourcesInProjectViewModel';
import type { ResourcesInProjectsTableItem } from 'src/types/ResourcesInProjectsTableItem';
import ButtonIcons from '@components/ui/Button/ButtonIcons';
import AddResourceForm from './AddResourceForm';
import ChangeResourceInProjectForm from './ChangeResourceInProjectForm';

/** Блок информации ресурсы на проекте */
const ResourcesInProject: React.FC = () => {
  /** Стор ресурсов на проекте */
  const {
    projectStore,
    resourcesInProjectStore,
  } = useStores();
    /** Идентификатор открытого проекта */
  const { id } = useParams();
  const confirmDelete = useConfirm('Удалить ресурс и его нагрузку с проекта?');

  useEffect(() => {
    runInAction(() => {
      resourcesInProjectStore.projectId = id;
    });
    resourcesInProjectStore.fetch();
  }, [id, resourcesInProjectStore]);

  /** Отображение формы изменения ресурса */
  const [changeResourceInProjectId, setChangeResourceInProjectId] = useState<string>();

  /** Отображение модалки добавления ресурса на проект */
  const [showAddResourceForm, setShowAddResourceForm] = useState(false);

  const { canUpdateResource } = projectStore;

  /** Обработчик закрытия модалки добавления ресурса */
  const closeAddResourceHandler = useCallback(() => {
    setShowAddResourceForm(false);
  }, []);

  /** Обработчик удаления ресурса */
  const deleteResourceHandler = useCallback(async (resourceInProjectId?: string) => {
    if (await confirmDelete()) {
      if (resourceInProjectId) {
        await resourcesInProjectStore.deleteResourceFromProject(resourceInProjectId);
        if (id) {
          await resourcesInProjectStore.fetch();
        }
      }
    }
  }, [confirmDelete, id, resourcesInProjectStore]);

  /** Удаление нагрузки */
  const DeleteResourceCell = useCallback<React.FC<{data: ResourcesInProjectsTableItem}>>(({ data }) => (
    <div
      className="flex w-full h-full items-center justify-center cursor-pointer"
      onClick={(event) => {
        event.stopPropagation();
        deleteResourceHandler(data.resourceInProjectId);
      }}
    >
      <MdDelete />
    </div>
  ), [deleteResourceHandler]);

  const onCloseChangeResourceInProjectFormHandler = useCallback(() => {
    setChangeResourceInProjectId(undefined);
  }, []);

  /** Кнопки хедера формы */
  const headerButtons = useMemo(() => (
    <>
      {canUpdateResource && (
        <Button.Primary
          className="py-2"
          icon={ButtonIcons.Add}
          label="Добавить ресурс"
          onClick={() => setShowAddResourceForm(true)}
        />
      )}
    </>
  ), [canUpdateResource]);

  return (
    <Accordion
      className="flex-[1_1_0] min-w-[45%]"
      contentClassName="bg-accordion-content-bg [&>div]:min-h-[350px] flex flex-col [&>div]:flex-grow [&>div]:basis-0 overflow-hidden"
      openedIds={['Resources']}
    >
      <Panel
        header="Ресурсы на проекте"
        headerButtons={headerButtons}
        id="Resources"
      >
        <Table
          onRowClick={(resourceId) => setChangeResourceInProjectId(resourceId)}
          rowHeight={30}
          store={resourcesInProjectStore}
        >
          <Column<ResourcesInProjectsTableItem, GetResourcesInProjectItem>
            dataType="string"
            header="Наименование"
            keyExpr="name"
            pinned
            sortable
          />
          <Column<ResourcesInProjectsTableItem, GetResourcesInProjectItem>
            dataType="date"
            header="Дата начала"
            keyExpr="startDate"
            sortable
          />
          <Column<ResourcesInProjectsTableItem, GetResourcesInProjectItem>
            dataType="date"
            header="Дата окончания"
            keyExpr="finishDate"
            sortable
          />
          {canUpdateResource ? (
            <Column<ResourcesInProjectsTableItem, ResourcesInProjectViewModel>
              dataType="custom"
              header=""
              template={DeleteResourceCell}
              width={40}
            />
          ) : null}
        </Table>
        {showAddResourceForm && <AddResourceForm onClose={closeAddResourceHandler} />}
        {changeResourceInProjectId && (
          <ChangeResourceInProjectForm
            onClose={onCloseChangeResourceInProjectFormHandler}
            resourceInProjectId={changeResourceInProjectId}
          />
        )}
        <Errors store={projectStore} />
      </Panel>
    </Accordion>
  );
};

export default observer(ResourcesInProject);
