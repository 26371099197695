import { parseStyles } from '@services/utils';
import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStores } from '@hooks';
import Image from '@components/ui/Image/Image';
import { useLocalStorage } from 'usehooks-ts';
import leftMenuConfig from '@components/LeftMenu/LeftMenu.config';
import { ArrowUpDown } from '@assets';
import type { MenuItemProps } from './types';

/** Лайаут пункта левого меню */
const MenuItem: React.FC<MenuItemProps> = ({
  route,
}) => {
  const { accountStore } = useStores();
  const [menuIsOpen] = useLocalStorage(process.env.REACT_APP_MENU_OPEN || 'userMenuIsOpen', false);
  const subRoutes = useMemo(() => route.children?.filter((e) => e.name) ?? [], [route]);
  const [showSubRoutes, setShowSubRoutes] = useState(false);

  const { viewModel } = accountStore;
  const email = route.needEmail ? viewModel.email : undefined;

  return (
    <>
      <NavLink
        className={(navData) => parseStyles`
          flex flex-1 p-2 text-white opacity-80 
          hover:bg-white hover:bg-opacity-20 items-center ${email ? 'space-x-4' : 'space-x-5'}
          min-h-menuItemH cursor-pointer duration-300 ease-in hover:ease-out
          ${navData.isActive && 'bg-white bg-opacity-20 border-r-4 border-white'}
        `}
        onClick={subRoutes.length ? (event) => {
          event.stopPropagation();
          event.preventDefault();
          setShowSubRoutes((val) => !val);
        } : undefined}
        title={route.name}
        to={route.path}
      >
        <div>
          {route.path === 'profile' && accountStore.viewModel.avatarLink ? (
            <div className="h-[30px] w-[30px]">
              <Image
                alt="leftMenuIcon"
                className="rounded-full object-cover h-[30px] w-[30px]"
                imageLink={accountStore.viewModel.avatarLink}
              />
            </div>
          ) : route.icon}
        </div>
        <div className="grid flex-1">
          {menuIsOpen && <div className="max-w-link">{route.name ?? ''}</div>}
          {email && <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">{email}</div>}
        </div>
        {!!subRoutes.length && (
          <div className="min-w-[30px]">
            <ArrowUpDown
              className={parseStyles`
                transition-all duration-[400ms] pointer-events-none
                ${showSubRoutes ? 'rotate-0' : 'rotate-180'}
              `}
            />
          </div>
        )}
      </NavLink>
      <div
        className="flex flex-col bg-[rgba(0,0,0,0.2)] transition-all"
        style={{ height: showSubRoutes ? subRoutes.length * leftMenuConfig.theme.extend.menuItemHeight : 0 }}
      >
        {showSubRoutes && subRoutes.map((e) => (
          <NavLink
            key={e.id}
            className={(navData) => parseStyles`
              flex flex-1 p-2 text-white opacity-80 
              hover:bg-white hover:bg-opacity-20 items-center ${email ? 'space-x-4' : 'space-x-5'}
              min-h-menuItemH cursor-pointer duration-300 ease-in hover:ease-out
              ${navData.isActive && 'bg-white bg-opacity-20 border-r-4 border-white'}
            `}
            title={e.name}
            to={[route.path, e.path].join('/')}
          >
            <div>
              {e.icon}
            </div>
            <div className="grid grid-template-columns flex-1">
              {menuIsOpen && <div className="max-w-link">{e.name ?? ''}</div>}
              {email && <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">{email}</div>}
            </div>
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default MenuItem;
