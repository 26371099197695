import DrawerWithWrapper from '@components/DrawerResourceLayout/DrawerWithWrapper';
import type { SelectOption } from '@components/ui/Select';
import { Select } from '@components/ui/Select';
import { Column, Table } from '@components/ui/Table';
import { useConfirm, useInterceptChanges, useStores } from '@hooks';
import type { GetResourceInProjectByIdResponseLoadResourceInProject } from '@typesApi';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { MdDelete } from 'react-icons/md';
import type { LoadResourceInProjectFields } from 'src/stores/ResourceInProject/LoadResourceInProjectsItemViewModel';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { WorkingPeriodModal } from '@components/CalendarTable/Modals';
import type { Period } from '@types';
import Button from '@components/ui/Button';
import Title from '@components/ui/Title/Title';
import { ConfirmQuestions } from 'src/enums';
import type { EmployeeInProjectFormProps } from './types';

/** Форма добавления сотрудник на проект */
const EmployeeInProjectForm: React.FC<EmployeeInProjectFormProps> = ({ onClose, projectId, resourceInProjectId }) => {
  /** Сторы: сотрудник на проекте */
  const {
    employeeInProjectStore, projectRolesNamesStore, employeesInProjectStore, projectLoadStore, projectStore,
  } = useStores();
  const { viewModel, isDirty, resetIsDirty } = useInterceptChanges(employeeInProjectStore.viewModel);
  const confirm = useConfirm(ConfirmQuestions.confirmExit);
  const { loadResourceInProjectStore } = employeeInProjectStore.viewModel;
  /** Отображение модалки добавления нагрузки на проекте */
  const [showAddLoad, toggleShowAddLoad] = useToggle();
  const { canUpdateResource } = projectStore;

  /** Привилегии на удаление сотрудника с проекта */
  const { canDeleteEmployee } = employeeInProjectStore;

  const confirmDelete = useConfirm('Удалить сотрудника и его нагрузку с проекта?');

  useEffectOnce(() => {
    if (resourceInProjectId) {
      employeeInProjectStore.fetch(resourceInProjectId);
    }
    employeeInProjectStore.viewModel.projectId = projectId;
  });

  /** Изменение ролей пользователя */
  const changeRolesHandler = useCallback((options?: SelectOption[]) => {
    viewModel.projectRoles = options;
  }, [viewModel]);

  /** Обработчик закрытия модалки */
  const onCloseHandler = useCallback(() => {
    onClose();
    employeeInProjectStore.clearStore();
    employeeInProjectStore.viewModel.loadResourceInProjectStore.clearStore();
  }, [employeeInProjectStore, onClose]);

  /** Обработчик добавления сотрудника на проект */
  const addEmployeeHandler = useCallback(async () => {
    if (!viewModel.validate()) {
      return;
    }
    await employeeInProjectStore.save();
    await Promise.all([employeesInProjectStore.fetch(), projectLoadStore.reload()]);
    resetIsDirty();
  }, [employeeInProjectStore, employeesInProjectStore, projectLoadStore, resetIsDirty, viewModel]);

  /** Сохранение изменений */
  const saveEmployeeInProjectHandler = useCallback(async () => {
    await employeeInProjectStore.save();
    if (employeeInProjectStore.state.isSuccess) {
      if (employeesInProjectStore.projectId) {
        await Promise.all([employeesInProjectStore.fetch(), projectLoadStore.reload()]);
      }
      onCloseHandler();
    }
  }, [employeeInProjectStore, employeesInProjectStore, onCloseHandler, projectLoadStore]);

  /** Обработчик добавления нагрузки на сотрудника */
  const addLoadInResourceHandler = useCallback(async (load?: Period) => {
    if (viewModel.id && load) {
      await loadResourceInProjectStore.addLoad(viewModel.id, load);
      await Promise.all([employeeInProjectStore.fetch(viewModel.id), projectLoadStore.reload()]);
    }
    toggleShowAddLoad();
  }, [employeeInProjectStore, loadResourceInProjectStore, projectLoadStore, toggleShowAddLoad, viewModel.id]);

  /** Удаление сотрудника с проекта */
  const deleteEmployeeFromProjectHandler = useCallback(async () => {
    const result = await confirmDelete();
    if (resourceInProjectId) {
      if (result) {
        await employeesInProjectStore.deleteEmployeeInProject(resourceInProjectId);
      }
      if (employeesInProjectStore.state.isSuccess) {
        await employeesInProjectStore.fetch();
        await projectLoadStore.fetch(true);
        onClose();
      }
    }
  }, [confirmDelete, employeesInProjectStore, onClose, projectLoadStore, resourceInProjectId]);

  /** Ячейка рабочих дней */
  const WorkingDaysCell: React.FC<{data: LoadResourceInProjectFields}> = useCallback(({ data }) => {
    const days = data.workingDaysOfWeek || employeeInProjectStore.viewModel.workingDaysOfWeek;

    return (
      <div
        className="h-full flex items-center p-2"
        title={days?.join(', ')}
      >
        <div className="truncate">
          {days?.map((date, index, arr) => (
            <span key={date}>
              {date}
              {(index === arr.length - 1 ? '' : ', ')}
            </span>
          ))}
        </div>
      </div>
    );
  }, [employeeInProjectStore.viewModel.workingDaysOfWeek]);

  /** Кнопки футера модалки */
  const footer = useMemo(
    () => (viewModel.id
      ? (
        <div className="flex flex-1 gap-2 justify-between">
          {canDeleteEmployee && (
            <Button.Primary
              label="Удалить сотрудника с проекта"
              onClick={deleteEmployeeFromProjectHandler}
              type="danger"
            />
          )}
          <Button.Primary
            className="ml-auto"
            isLoading={employeeInProjectStore.state.isLoading}
            label="Сохранить"
            onClick={saveEmployeeInProjectHandler}
          />
        </div>
      )
      : !viewModel.id && (
        <Button.Primary
          className="self-end"
          label="Добавить сотрудника на проект"
          onClick={addEmployeeHandler}
        />
      )),
    [
      addEmployeeHandler,
      canDeleteEmployee,
      deleteEmployeeFromProjectHandler,
      employeeInProjectStore.state.isLoading,
      saveEmployeeInProjectHandler,
      viewModel.id,
    ],
  );

  /** Удаление нагрузки */
  const rowDeleteHandler = useCallback<React.FC<{data: LoadResourceInProjectFields}>>(
    ({ data }) => (
      <div
        className="flex w-full h-full items-center justify-center cursor-pointer"
        onClick={async () => {
          if (viewModel.id) {
            await loadResourceInProjectStore.deleteLoad(viewModel.id, data.startDate, data.finishDate);
            await Promise.all([employeeInProjectStore.fetch(viewModel.id), projectLoadStore.reload()]);
          }
        }}
      >
        <MdDelete />
      </div>
    ),
    [employeeInProjectStore, loadResourceInProjectStore, projectLoadStore, viewModel.id],
  );

  /** Хэндлер обработки подтверждения или отмены закрытия модалки */
  const confirmHandler = useMemo(() => {
    if (isDirty) {
      return confirm;
    }
    return undefined;
  }, [confirm, isDirty]);

  return (
    <DrawerWithWrapper
      confirm={confirmHandler}
      footer={canUpdateResource ? footer : undefined}
      onClose={onCloseHandler}
      title={resourceInProjectId ? viewModel.resourceName ?? '' : 'Добавление сотрудника на проект'}
    >
      <div className="flex flex-col gap-form">
        <Select.Async
          disabled={!!viewModel.id}
          error={viewModel.errors.resourceId}
          label="Сотрудник"
          onChange={(search) => employeeInProjectStore.fetchEmployeeNames(search)}
          onSelect={(option) => {
            viewModel.resourceId = option?.value;
            viewModel.resourceName = option?.label;
          }}
          placeholder="Начните вводить текст"
          selectedOption={viewModel.selectedEmployee}
        />
        <Select.AsyncMulti
          disabled={!canUpdateResource}
          error={viewModel.errors.projectRoles}
          label="Роли на проекте"
          onChange={(search) => projectRolesNamesStore.fetchProjectRoles(search)}
          onSelectOption={changeRolesHandler}
          placeholder="Выберите роли"
          selectedOptions={viewModel.projectRoles}
        />
      </div>
      {viewModel.id && (
        <>
          <div className="flex items-center justify-between my-4">
            <Title isSubtitle>Нагрузка на проекте</Title>
            <div>
              {!!viewModel.selectedEmployee?.value && canUpdateResource && (
                <Button.Primary
                  label="Добавить нагрузку"
                  onClick={toggleShowAddLoad}
                />
              )}
            </div>
          </div>
          <Table
            rowHeight={30}
            store={loadResourceInProjectStore}
          >
            <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
              dataType="date"
              header="Дата начала"
              keyExpr="startDate"
            />
            <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
              dataType="date"
              header="Дата окончания"
              keyExpr="finishDate"
            />
            <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
              dataType="number"
              header="Нагрузка"
              keyExpr="workingHoursPerDay"
            />
            <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
              dataType="custom"
              header="Рабочие дни"
              template={WorkingDaysCell}
            />
            {canUpdateResource ? (
              <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
                dataType="custom"
                header=""
                template={rowDeleteHandler}
                width={50}
              />
            ) : null}
          </Table>
        </>
      )}
      {showAddLoad && (<WorkingPeriodModal onClose={addLoadInResourceHandler} />)}
    </DrawerWithWrapper>
  );
};

export default observer(EmployeeInProjectForm);
