import Popup from '@components/Popup/Popup';
import Button from '@components/ui/Button';
import CheckBox from '@components/ui/CheckBox/CheckBox';
import { FormInput } from '@components/ui/Input';
import { useStores } from '@hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { ImFilter, ImSortAlphaAsc, ImSortAlphaDesc } from 'react-icons/im';
import { useOnClickOutside } from 'usehooks-ts';
import { Select } from '@components/ui/Select';
import type { ProjectStatuses } from '@typesApi';
import { RussianProjectStatusesForSelect } from 'src/enums';
import graphConfig from '../graph.config';

const ProjectsListHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
}> = ({
  headerHeight, rowWidth,
}) => {
  const { projectsLoadStore } = useStores();
  const [showFilter, setShowFilter] = useState<typeof projectsLoadStore.filter | null>(null);
  const filterPopupRef = useRef<HTMLDivElement>(null);
  const [filterRef, setFilterRef] = useState<HTMLElement | null>(null);

  useOnClickOutside(filterPopupRef, () => {
    runInAction(() => {
      projectsLoadStore.filter.filterName = showFilter!.filterName;
      projectsLoadStore.filter.isDeleted = showFilter!.isDeleted;
    });
    setShowFilter(null);
  });

  return (
    <>
      <div
        className="table border-graph-border border-x-0 bg-graph-header
        cursor-pointer align-middle
        hover:after:bg-headerHovered hover:after:w-full
        hover:after:h-full hover:after:absolute hover:after:top-0 hover:after:left-0 relative"
        onClick={() => {
          runInAction(() => {
            projectsLoadStore.filter.orderBy = 'name';
            projectsLoadStore.filter.isAscending = !projectsLoadStore.filter.isAscending;
            projectsLoadStore.filter.pageNumber = 1;
          });
          projectsLoadStore.fetch(true);
        }}
      >
        <div
          className="table-row list-none"
          style={{
            height: `calc(${headerHeight}px - ${graphConfig.theme.extend.borderWidth['graph-border']}*2)`,
          }}
        >
          <div
            className="table-cell align-middle pl-2 border-r-graph-border"
            style={{
              minWidth: rowWidth,
            }}
          >
            <div
              className="flex text-font-main-bold items-center"

            >
              Проект
              {projectsLoadStore.filter.isAscending && (
                <ImSortAlphaAsc
                  className="shrink-0"
                  height={20}
                  width={20}
                />
              )}
              {projectsLoadStore.filter.isAscending === false && (
                <ImSortAlphaDesc
                  className="shrink-0"
                  height={20}
                  width={20}
                />
              )}
              <div
                ref={setFilterRef}
                className="cursor-pointer z-30 hover:scale-125 ml-auto m-2"
              >
                <ImFilter
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilter({ ...projectsLoadStore.filter });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFilter && filterRef && (
        <Popup
          ref={filterPopupRef}
          targetRef={filterRef}
        >
          <div className="flex flex-col gap-form bg-white rounded-md border-1 p-4">
            <FormInput
              label="Название проекта"
              onChange={(e) => {
                runInAction(() => {
                  projectsLoadStore.filter.filterName = e.currentTarget.value;
                });
              }}
              value={projectsLoadStore.filter.filterName}
            />
            <Select.Base
              label="Статус"
              onSelect={(option) => {
                runInAction(() => {
                  projectsLoadStore.filter.status = option?.value as ProjectStatuses;
                });
              }}
              options={RussianProjectStatusesForSelect}
              selectedOption={RussianProjectStatusesForSelect.find((el) => el.value === projectsLoadStore.filter.status)}
            />
            <CheckBox
              checked={projectsLoadStore.filter.isDeleted ?? false}
              label="Архив"
              onChange={(val) => {
                runInAction(() => {
                  projectsLoadStore.filter.isDeleted = val;
                });
              }}
            />
            <div className=" px-4 pt-2 border-t-graph-border w-full flex gap-form justify-between">
              <Button.Primary
                label="Очистить"
                onClick={() => {
                  runInAction(() => {
                    projectsLoadStore.clearFilter();
                    projectsLoadStore.startDate = new Date(projectsLoadStore.startDate);
                  });
                  projectsLoadStore.fetch(true);
                  setShowFilter(null);
                }}
                type="outline"
              />
              <Button.Primary
                label="Применить"
                onClick={() => {
                  runInAction(() => {
                    projectsLoadStore.filter.pageNumber = 1;
                    projectsLoadStore.filter.isAscending = undefined;
                  });
                  projectsLoadStore.fetch(true);
                  setShowFilter(null);
                }}
              />
            </div>
          </div>

        </Popup>
      )}
    </>
  );
};

export default observer(ProjectsListHeader);
