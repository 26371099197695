import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ListBaseFilter } from '@types';
import { observer } from 'mobx-react-lite';
import { parseStyles } from '@services/utils';
import { useInterceptChanges } from '@hooks';
import type {
  CellWithSpan,
  ColumnProps,
  Row,
  TableProps,
} from './types';
import { useTable } from './useTable';
import ColumnComponent from './Column';
import TableRow from './Row';
import Header from './Header/Header';
import { childrenSame, getColumnKey, parseChildrenColumns } from './utils';
import { isCustomColumn } from './typeGuards';
import Pagination from './Pagination';
import Loader from '../Loader/Loader';

/** Табличка */
const Table = <RowT extends Row, EntityT, FilterT extends ListBaseFilter>({
  store, columns, parentIdExpr, hasChildrenExpr, className, dirtyChanged, withPagination = true, customPagination,
  onChange, children, treeLike = false, virtualScroll = false, rowHeight = virtualScroll ? 30 : undefined, onRowClick,
  noDataText = 'Нет данных', reorderSessionKey,
}: TableProps<RowT, EntityT, FilterT>) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const shadowRef = useRef<HTMLDivElement>(null);

  const [scrollTop, setScrollTop] = useState(0);
  const _columns = useMemo(() => {
    const childrenColumns = parseChildrenColumns(
      [children ?? []]
        .flat()
        .filter((e): e is React.ReactElement<ColumnProps<RowT, EntityT>, typeof ColumnComponent> => !!e)
        .filter((e) => e.type.name === ColumnComponent.name),
    );

    return (childrenColumns.length ? childrenColumns : columns) ?? [];
  }, [columns, children]);
  const { isDirty, viewModel } = useInterceptChanges(store.viewModel);
  useEffect(() => {
    // TODO придумать более элегантный способ передачи на верх "грязности"
    dirtyChanged?.(isDirty);
  }, [isDirty, dirtyChanged]);

  const {
    rows,
    columns: allColumns,
    expandOrCollapseRow,
    levelsOfColumns,
    reorder,
  } = useTable({
    data: viewModel,
    columns: _columns,
    parentIdExpr: parentIdExpr as string,
    hasChildrenExpr: hasChildrenExpr as string,
    treeLike,
    readonly: !onChange,
    reorderSessionKey,
  });

  const [firstLevelColumnKeys, setFirstLevelColumnKeys] = useState(allColumns.map((e) => getColumnKey(e)));

  const columnKeys = levelsOfColumns
    .flatMap((e) => e.flatMap((col) => {
      if (!col) {
        return [];
      }
      return getColumnKey(col);
    }));

  if ((new Set(columnKeys)).size !== columnKeys.length) {
    console.error('У колонок должны быть уникальные header\'ы или key');
  }

  const [widths, setWidths] = useState<(number | undefined)[]>(Array.from({ length: allColumns.length }));

  const hasFooter = useMemo(() => allColumns.some((e) => !!e.footer), [allColumns]);

  const onSortChanged = useCallback(
    () => store?.fetch(),
    [store],
  );

  const onWidthsChanged = useCallback(
    (w: (number | undefined)[]) => setWidths(w),
    [],
  );

  const scrollStopPointsForShadow = useMemo(() => {
    const pinnedColumnsIndexes = allColumns.reduce<number[]>((acc, cur, index) => {
      if (cur.pinned) {
        acc.push(index);
      }
      return acc;
    }, []);
    // [скролл после которого нужно показывать тень, ширина колонок]
    const stopPoints: [number, number][] = [];
    widths.reduce((acc, cur, index) => {
      const lastStopPoint = stopPoints.at(-1);
      const lastStopPointWidth = lastStopPoint?.[1] ?? 0;
      if (lastStopPoint && pinnedColumnsIndexes.includes(index) && lastStopPoint[1] === acc) {
        stopPoints[stopPoints.length - 1][1] += cur ?? 0;
        return (acc ?? 0) + (cur ?? 0);
      }
      if (pinnedColumnsIndexes.includes(index)) {
        stopPoints.push([(acc ?? 0) - (lastStopPoint?.[0] ?? 0) - (lastStopPoint?.[1] ?? 0), lastStopPointWidth + (cur ?? 0)]);
      }
      return (acc ?? 0) + (cur ?? 0);
    }, 0);
    return stopPoints;
  }, [allColumns, widths]);

  const shadowScrollHandler = useCallback(
    () => {
      const shadowStop = [...scrollStopPointsForShadow].reverse().find((e) => e[0] < (wrapperRef.current?.scrollLeft ?? Infinity));
      if (!shadowRef.current) {
        return;
      }
      if (shadowStop) {
        shadowRef.current.style.display = '';
        shadowRef.current.style.width = `${shadowStop[1]}px`;
      } else {
        shadowRef.current!.style.display = 'none';
      }
    },
    [scrollStopPointsForShadow],
  );

  useEffect(() => {
    const curKeys = allColumns.map((e) => getColumnKey(e));
    setFirstLevelColumnKeys((prevKeys) => {
      if (prevKeys.length === curKeys.length && prevKeys.every((e, i) => e === curKeys[i])) {
        return prevKeys;
      }
      return curKeys;
    });
  }, [allColumns]);

  useEffect(() => {
    shadowScrollHandler();
  }, [firstLevelColumnKeys, shadowScrollHandler]);

  let currentRowIndex = 1;

  const maxSpans = useMemo(() => rows.map((row) => allColumns.reduce<number>((acc, cur) => {
    if (isCustomColumn(cur) || !Array.isArray(row[cur.keyExpr])) {
      return Math.max(acc, 1);
    }
    return Math.max(acc, (row[cur.keyExpr] as CellWithSpan<unknown>[]).reduce<number>((acc2, cur2) => acc2 + cur2.span, 0));
  }, 0)), [rows, allColumns]);

  const rowTemplate = useMemo(() => maxSpans.reduce((acc, cur) => acc + cur, 0), [maxSpans]);

  return (
    <div className="flex flex-col min-h-[250px] h-full w-full overflow-auto">
      <div
        ref={wrapperRef}
        className={parseStyles`h-full 
          border-l-table-border-color border-t-table-border-color border-l-table border-t-table border-r-table-border-color border-r-table
          flex flex-col overflow-auto scrollbar relative
        `}
        onScroll={(e) => {
          shadowScrollHandler();
          if (virtualScroll) {
            setScrollTop(e.currentTarget.scrollTop);
          }
        }}
      >
        <Header
          allColumns={allColumns}
          levelsOfColumns={levelsOfColumns}
          onSortChanged={onSortChanged}
          onWidthsChanged={onWidthsChanged}
          reorder={reorder}
          storeFilter={store?.filter}
          widths={widths}
        />
        <div
          className="flex-1"
        >
          <div
            className={parseStyles`
              grid w-fit bg-white
              ${className}
            `}
            style={{
            // Array.from для того чтобы при первом рендере, когда ещё не обновился стейт widths не дёргалась табличка
              gridTemplateColumns: Array.from({ length: allColumns.length }, (_, i) => widths[i] ?? 0).map((e) => `${e}px`).join(' '),
              gridTemplateRows: store?.state.isLoading
                ? '1fr auto'
              // eslint-disable-next-line max-len
                : `${virtualScroll ? 'fit-content(0px) ' : ''}${rowTemplate ? `repeat(${rowTemplate}, ${rowHeight ? `${rowHeight}px` : 'auto'})` : ''} ${rowHeight ? '1fr ' : ''}fit-content(20px)`,
              height: (rowHeight && !store.state.isLoading) ? (rowTemplate * rowHeight) || undefined : undefined,
              minHeight: '100%',
            }}
          >
            {store?.state.isLoading || !rows.length
              ? (
                <>
                  {store?.state.isLoading
                    ? (
                      <div
                        className={`
                          border-r-table-border-color border-b-table-border-color border-r-table border-b-table 
                          row-span-1 flex items-center justify-center h-full p-2
                        `}
                        style={{
                          gridColumn: `span ${allColumns.length}`,
                        }}
                      >
                        <div className="w-10 h-10 sticky left-[calc(50%_-_1.25rem)] right-[calc(50%_-_1.25rem)]">
                          <Loader size={40} />
                        </div>
                      </div>
                    )
                    : (
                      <div
                        className="h-full w-full flex items-center justify-center bg-white"
                        style={{ gridColumn: `span ${allColumns.length}` }}
                      >
                        {noDataText}
                      </div>
                    ) }
                </>
              )
              : (
                <>
                  {virtualScroll && rowHeight && (
                    <div style={{
                      gridColumn: `span ${allColumns.length}`,
                      height: Math.max((Math.floor(scrollTop / rowHeight) - 2) * rowHeight, 0),
                    }}
                    />
                  )}
                  {rows?.map((rowData, rowIndex) => {
                    const curIndex = currentRowIndex;
                    const maxSpan = maxSpans[rowIndex];
                    currentRowIndex += maxSpan;
                    return (
                      !virtualScroll
                    || !wrapperRef.current
                    || (rowIndex >= Math.floor(scrollTop / rowHeight!) - 2
                      && rowIndex < Math.floor((scrollTop + wrapperRef.current.getBoundingClientRect().height) / rowHeight!)
                    )
                        ? (
                          <TableRow
                            key={rowData.id}
                            columns={allColumns}
                            even={!(rowIndex % 2)}
                            expandOrCollapseRow={expandOrCollapseRow}
                            height={rowHeight}
                            maxSpan={maxSpan}
                            onChange={onChange}
                            onRowClick={onRowClick}
                            row={rowData}
                            startIndex={curIndex}
                            treeLike={treeLike}
                            widths={widths}
                          />
                        )
                        : null);
                  })}
                  {virtualScroll && rowHeight && (
                    <div
                      className="border-r-table-border-color border-r-table"
                      style={{
                        gridColumn: `span ${allColumns.length}`,
                      }}
                    />
                  )}
                </>
              )}
            {hasFooter && allColumns.map((col, colIndex) => (
              <div
                key={getColumnKey(col) ?? colIndex}
                className="sticky bottom-0 break-all bg-white border-table-border-color border-table border-l-0 mt-[-2px]"
              >
                {col.footer && <col.footer />}
              </div>
            ))}
          </div>
        </div>
        <div
          className="h-full absolute z-20 pointer-events-none"
          style={{ width: widths.reduce((acc, cur) => (acc ?? 0) + (cur ?? 0)) }}
        >
          <div
            ref={shadowRef}
            className="top-0 h-full shadow-pinned-cell sticky left-0"
          />
        </div>
      </div>
      {withPagination && (
        <>
          <div className="h-[1px] bg-table-border-color mt-[-1px] sticky" />
          <div className="border-[1px] border-t-0 px-4 bg-white">
            {customPagination || <Pagination store={store} />}
          </div>
        </>
      )}
    </div>
  );
};

// мемоизация на props.children не работает, поэтому кастомизируем
export default memo(observer(Table), (prev, cur) => Object.keys(prev)
  .every((key) => {
    if (key === 'children') {
      return childrenSame(prev.children, cur.children);
    }
    // @ts-ignore
    return prev[key] === cur[key];
  })) as typeof Table;
