import LoginLayout from '@components/LoginLayout';
import Logo from '@components/Logo';
import Button from '@components/ui/Button';
import { parseStyles } from '@services/utils';
import type { FC } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ConfirmEmail: FC = () => {
  const navigate = useNavigate();

  return (
    <LoginLayout>
      <Logo />
      <div
        className={parseStyles`
          w-1/2 min-w-[300px] max-w-[400px]
          bg-white shadow-lg
          rounded-main
          mt-16
          px-[50px] py-[30px]
          self-center
          justify-self-center
          items-center
        `}
      >
        <div className="text-center font-bold text-lg">Необходимо подтвердить email.</div>
        <div className="text-center text-gray-400">Проверьте почту.</div>
        <div className="flex flex-col items-center">
          <Button.Outline
            className="flex justify-center"
            label="Авторизоваться"
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
      </div>
    </LoginLayout>
  );
};
