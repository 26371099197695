import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from '@hooks';
import type { RowCheckboxProps } from './types';
import RowCheckbox from './RowCheckbox';

/** Чекбокс выбора строки таблицы (для проектов) */
const RowCheckboxForDeleteProjects = <T extends RowCheckboxProps>({ data }: T) => {
  const { privilegesStore } = useStores();

  if (privilegesStore.projects.canDeleteById(data.id)) {
    return <RowCheckbox data={data} />;
  }
  return null;
};

export default observer(RowCheckboxForDeleteProjects);
