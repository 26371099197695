import { FormInput } from '@components/ui';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { Select } from '@components/ui/Select';
import { useCatalogs, useSelectOptions, useStores } from '@hooks';
import { ResourceTypes } from '@typesApi';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import type ProjectRequirementEquipmentViewModel from 'src/stores/ProjectRequirement/ProjectRequirementEquipmentViewModel';
import type { ProjectRequirementModalProps } from './types';

const ProjectRequirementEquipment: React.FC<ProjectRequirementModalProps> = ({
  projectRequirementId, disabled,
}) => {
  /** Стор */
  const { projectRequirementStore } = useStores();
  /** Модель */
  const viewModel = projectRequirementStore.viewModel as ProjectRequirementEquipmentViewModel;
  /** Каталог техники */
  const [equipment] = useCatalogs(['Equipment']);

  /** Список типов техники */
  const [equipmentOptions] = useSelectOptions([equipment.data]);

  /** Выбранный тип техники */
  const selectedEquipmentType = useMemo(() => {
    if (viewModel?.typeName && viewModel.typeId) {
      return ({ label: viewModel.typeName, value: viewModel.typeId });
    }
    return undefined;
  }, [viewModel.typeId, viewModel.typeName]);

  useEffect(() => {
    projectRequirementStore.resourceType = ResourceTypes.Equipment;
    if (projectRequirementId) {
      projectRequirementStore.fetch(projectRequirementId);
    }
  }, [projectRequirementId, projectRequirementStore]);

  return (
    <>
      <FormInput
        disabled={disabled}
        error={viewModel.errors.name}
        label="Наименование"
        onChange={(event) => {
          viewModel.name = event.target.value;
        }}
        value={viewModel.name}
      />
      <Select.AutoComplete
        disabled={disabled}
        error={viewModel.errors.typeName}
        label="Тип техники"
        loading={equipment.state?.isLoading}
        onSelect={(option) => {
          if (option) {
            viewModel.typeId = option.value;
            viewModel.typeName = option.label;
          }
        }}
        options={equipmentOptions}
        selectedOption={selectedEquipmentType}
      />
      <FormInput
        disabled={disabled}
        error={viewModel.errors.parameters}
        label="Параметры"
        onChange={(event) => {
          viewModel.parameters = event.target.value;
        }}
        value={viewModel.parameters}
      />
      <DatePicker
        borderClasses="border-1 rounded-main"
        disabled={disabled}
        error={viewModel.errors.startDate}
        label="Начало периода"
        onChange={(value) => {
          viewModel.startDate = value;
        }}
        value={viewModel.startDate}
      />
      <DatePicker
        borderClasses="border-1 rounded-main"
        disabled={disabled}
        error={viewModel.errors.finishDate}
        label="Окончание периода"
        onChange={(value) => {
          viewModel.finishDate = value;
        }}
        value={viewModel.finishDate}
      />
      <FormInput
        disabled={disabled}
        error={viewModel.errors.totalLoad}
        label="Нагрузка в день"
        onChange={(event) => {
          viewModel.totalLoad = Number(event.target.value);
        }}
        type="number"
        value={viewModel.totalLoad?.toString()}
      />
    </>
  );
};

export default observer(ProjectRequirementEquipment);
