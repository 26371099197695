import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import MultiSelect from '@components/ui/Select/MultiSelect/MultiSelect';
import { useInterceptChanges, useStores, useConfirm } from '@hooks';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmQuestions } from 'src/enums';
import { useEffectOnce } from 'usehooks-ts';
import Errors from '@components/ui/Errors/Errors';
import Switcher from '@components/ui/Switcher/Switcher';
import DrawerWithWrapper from '../DrawerResourceLayout/DrawerWithWrapper';
import type { ChangeUserFormProps } from './types';

/** Форма изменения ролей текущего пользователя */
const AccountChangeForm: React.FC<ChangeUserFormProps> = ({ onClose, id }) => {
  const navigate = useNavigate();
  const { inviteStore, rolesStore, accountsStore } = useStores();
  const { viewModel, isDirty } = useInterceptChanges(inviteStore.viewModel);

  /** Модалка подтверждения выхода без сохранения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  useEffectOnce(() => {
    inviteStore.fetch(id);
    rolesStore.fetch();
  });

  /** Кнопки футера для drawer */
  const footer = useMemo(() => {
    /** Сохранение изменений */
    const saveHandler = async () => {
      await inviteStore.save();
      if (inviteStore.state.isSuccess) {
        onClose();
        accountsStore.fetch();
      }
    };

    return (
      <Button.Primary
        isLoading={inviteStore.state.isLoading}
        label="Сохранить"
        onClick={saveHandler}
      />
    );
  }, [inviteStore, onClose, accountsStore]);

  return (
    <DrawerWithWrapper
      confirm={isDirty ? confirm : undefined}
      footer={footer}
      onClose={onClose}
      title={`Пользователь ${viewModel.name}`}
    >
      <div className="flex flex-col gap-form">
        <FormInput
          label="Электронная почта"
          value={viewModel.email}
          disabled
        />
        <FormInput
          label="Номер телефона"
          mask="+7(___)-___-__-__"
          value={viewModel.phone}
          disabled
        />
        <FormInput
          className="underline [&>input]:cursor-pointer caret-transparent"
          label="Сотрудник"
          onClick={() => navigate(`/resources/employees/${viewModel.employeeId}`)}
          value={viewModel.name}
        />
        <MultiSelect
          label="Системные роли"
          onSelectOption={(options) => {
            viewModel.employeeRoles = options;
          }}
          options={rolesStore.rolesForSelect}
          selectedOptions={viewModel?.employeeRoles}
        />
        <Switcher
          isActive={viewModel.isActive}
          label="Активность"
          onChange={() => {
            viewModel.isActive = !viewModel.isActive;
          }}
        />
      </div>
      <Errors store={inviteStore} />
    </DrawerWithWrapper>
  );
};

export default observer(AccountChangeForm);
