import type { PropsWithChildren } from 'react';
import React from 'react';
import type { WithClassName } from '../types';

/** Пропсы для подчеркиваемого текста */
type Props = WithClassName & PropsWithChildren;

/** Текст с анимированным подчеркиванием */
const AnimatedUnderlineText: React.FC<Props> = ({ children, className }) => (
  <div className="flex">
    <div className={`
      bg-left-bottom bg-gradient-to-r bg-no-repeat
      from-btn-outline to-btn-outline
      bg-[length:0%_2px]
      transition-all duration-300 ease-out
      ${className}
    `}
    >
      {children}
    </div>
  </div>
);

export default AnimatedUnderlineText;
