import Modal from '@components/Modal/Modal';
import Button from '@components/ui/Button';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import type { ConfirmModalProps, ConfirmRefProps } from './types';

/** Модалка подтверждения */
const ConfirmModal = forwardRef<ConfirmRefProps, ConfirmModalProps >(({
  className,
}, ref) => {
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState<string>();
  const [resolveCB, setResolveCB] = useState<((value: boolean | PromiseLike<boolean>) => void) | null>(null);

  const confirm = useCallback((q: string) => {
    setShow(true);
    setQuestion(q);
    return new Promise<boolean>((resolve) => {
      setResolveCB(() => resolve);
    });
  }, []);

  useImperativeHandle<ConfirmRefProps, ConfirmRefProps>(ref, () => ({ confirm }), [confirm]);

  /** Обработчик отмены */
  const rejectHandler = useCallback(() => {
    setShow(false);
    resolveCB?.(false);
  }, [resolveCB]);

  return !show ? null : (
    <Modal
      className={className}
      onClose={rejectHandler}
    >
      <div className="flex flex-col p-2 gap-4 min-w-[300px] max-w-[600px]">
        {question && <div className="font-bold text-lg">{question}</div>}
        <div className="flex gap-4 justify-between">
          <Button.Primary
            className="flex-1"
            label="Нет"
            onClick={rejectHandler}
            type="outline"
          />
          <Button.Primary
            className="flex-1"
            label="Да"
            onClick={() => {
              setShow(false);
              resolveCB?.(true);
            }}
          />
        </div>
      </div>
    </Modal>
  );
});

ConfirmModal.displayName = 'ConfirmModal';

export default React.memo(ConfirmModal);
