import type {
  GetResourceInProjectsResponseProjectResponseItem,
  GetResourceInProjectsResponseResourceItem,
  GetResourceInProjectsResponseUnavailableResourceItem,
  ResourceTypes,
} from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { makeObservable, observable } from 'mobx';

type Resource = Omit<GetResourceInProjectsResponseResourceItem, 'deletedOn'> & {deletedOn?: Dayjs}

export class ResourcesLoadViewModel implements Resource {
  /**
   * Ид ресурса
   * @format uuid
   */
  @observable resourceId?: string;

  /** Типы ресурсов */
  @observable resourceType?: ResourceTypes;

  /** Название ресурса */
  @observable resourceName?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  @observable deletedOn?: Dayjs;

  /** Список ресурсов проектов */
  @observable resourceInProjects?: GetResourceInProjectsResponseProjectResponseItem[] | null;

  /** Список недоступности ресурсов */
  @observable unavailableResources?: GetResourceInProjectsResponseUnavailableResourceItem[] | null;

  /** Список идентификаторов значений справочников */
  @observable catalogValues?: string[] | null;

  constructor(data: GetResourceInProjectsResponseResourceItem) {
    this.resourceId = data.resourceId;
    this.resourceType = data.resourceType;
    this.resourceName = data.resourceName;
    this.deletedOn = data.deletedOn ? dayjs.utc(data.deletedOn) : undefined;
    this.resourceInProjects = data.resourceInProjects;
    this.unavailableResources = data.unavailableResources;
    this.catalogValues = data.catalogValues;
    makeObservable(this);
  }
}
