import type { Ref } from 'react';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useCatalogs, useSelectOptions } from '@hooks';
import { Placeholders } from 'src/enums';
import type { CatalogSelectProps, SelectOption } from '../types';
import AutoComplete from '../AutoComplete/AutoComplete';

/** Компонент справочника*/
const CatalogSelect = forwardRef(<T extends object, P extends object>({
  label,
  placeholder = Placeholders.startTyping,
  error,
  height,
  clearable,
  disabled = false,
  showArrowButton = true,
  onSelect,
  catalogName,
  catalogIdField,
  catalogNameField,
  viewModel,
  backgroundColor,
  invalid,
}: CatalogSelectProps<T, P>, ref: Ref<HTMLInputElement>) => {
  const catalogNames = useMemo(() => [catalogName], [catalogName]);

  const [catalog] = useCatalogs(catalogNames);
  /** Опции для селекта */
  const [catalogOptions] = useSelectOptions([catalog.data]);

  /** Выбор опции */
  const onSelectHandler = useCallback((option?: SelectOption) => {
    (viewModel[catalogIdField] as string | undefined) = option?.value;
    if (catalogNameField) {
      (viewModel[catalogNameField] as string | undefined) = option?.label;
    }
    onSelect?.(option);
  }, [catalogIdField, catalogNameField, onSelect, viewModel]);

  return (
    <AutoComplete
      ref={ref}
      backgroundColor={backgroundColor}
      clearable={clearable}
      disabled={disabled}
      error={error}
      height={height}
      invalid={invalid}
      label={label}
      loading={catalog.state?.isLoading}
      onSelect={onSelectHandler}
      options={catalogOptions}
      placeholder={placeholder}
      selectedOption={catalogOptions?.find((e) => e.value === viewModel[catalogIdField])}
      showArrowButton={showArrowButton}
    />
  );
});

CatalogSelect.displayName = 'Catalog';

export default observer(CatalogSelect) as unknown as <T extends object, P extends object>(
  props: CatalogSelectProps<T, P> & { ref?: Ref<HTMLInputElement> }
) => JSX.Element;
