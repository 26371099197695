import { ProjectService, ResourceService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetProjectEmployeesResponseItem } from '@typesApi';
import { computed, makeObservable, observable } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import EmployeesInProjectItemViewModel from './EmployeesInProjectItemViewModel';

export type GetProjectEmployeesResponseItemWithRequiredId =
 Required<Pick<GetProjectEmployeesResponseItem, 'id'>>
 & Omit<GetProjectEmployeesResponseItem, 'id'>

class EmployeesInProjectStore extends BaseEntityListStore<
GetProjectEmployeesResponseItemWithRequiredId, ListBaseFilter, EmployeesInProjectItemViewModel> {
  /** Идентификатор проекта */
  @observable projectId?: string;

  constructor() {
    super();
    makeObservable(this);
  }

  public async fetch(): Promise<void> {
    if (this.projectId) {
      await this.runWithStateControl(async () => {
        const { data } = await ProjectService.employeesDetail(this.projectId!, this.filter);
        this.setData(data as {
          entities: GetProjectEmployeesResponseItemWithRequiredId[];
          totalCount: number;
        }, EmployeesInProjectItemViewModel);
      });
    }
  }

  @computed public get selectedEmployees() {
    return this.viewModel.filter((employee) => employee.selected);
  }

  public async deleteEmployeeInProject(resourceInProjectId: string) {
    await this.runWithStateControl(async () => {
      await ResourceService.resourceInProjectDelete(resourceInProjectId);
    });
  }
}

export default EmployeesInProjectStore;
