import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import useDebouncedCallback from '@services/useDebounceCallback';
import { Placeholders } from 'src/enums';
import BaseSelect from '../BaseSelect/BaseSelect';
import type { SelectOption, AsyncProps } from '../types';

/** Асинхронный селект */
const AsyncSelect = forwardRef<HTMLInputElement, AsyncProps>(({
  label,
  placeholder = Placeholders.startTyping,
  defaultOptions,
  selectedOption,
  onClick,
  error,
  height,
  disabled = false,
  showArrowButton = true,
  loading = true,
  clearable = true,
  cacheOptions,
  onSelect,
  onChange,
  onBlur,
  invalid,
}, ref) => {
  /** Опции (меняются при асинхронном вызове onChangeHandler)  */
  const [filteredOptions, setFilteredOptions] = useState(defaultOptions);

  /** Состояние загрузки результатов */
  const [isLoading, setIsLoading] = useState(false);
  /** Ошибка загрузки */
  const [popupError, setPopupError] = useState<string | undefined>();

  /** Обработчик ввода */
  const onChangeDebounce = useDebouncedCallback(async (value?: string) => {
    try {
      const result = await onChange(value);
      if (result) {
        setFilteredOptions(result);
      }
    } catch (err) {
      if (typeof (err as {title: string})?.title === 'string') {
        setPopupError((err as {title: string}).title);
      }
    } finally {
      setIsLoading(false);
    }
  }, 400);

  const onChangeCallback = useCallback(
    async (value?: string) => {
      setIsLoading(true);
      await onChangeDebounce(value);
    },
    [onChangeDebounce],
  );

  useEffect(() => {
    setFilteredOptions(defaultOptions);
  }, [defaultOptions]);

  /** Возвращение опций по умолчанию */
  const returnDefaultOptions = useCallback(() => {
    /** Только в случае отсутвия флага кэширования предыдущего результата */
    if (!cacheOptions) {
      setFilteredOptions(defaultOptions);
    }
  }, [cacheOptions, defaultOptions]);

  /** Выбор опции */
  const onSelectHandler = useCallback((option?: SelectOption) => {
    returnDefaultOptions();
    if (onSelect) {
      onSelect(option);
    }
  }, [onSelect, returnDefaultOptions]);

  /** Возврат опций на onBlur если по введенному запросу ничего не найдено */
  const onBlurHandler = useCallback(() => {
    setPopupError(undefined);
    if (!filteredOptions?.length) {
      returnDefaultOptions();
    }
    if (onBlur) {
      onBlur();
    }
  }, [filteredOptions?.length, onBlur, returnDefaultOptions]);

  return (
    <BaseSelect
      ref={ref}
      clearable={clearable}
      disabled={disabled}
      error={error}
      height={height}
      invalid={invalid}
      label={label}
      loading={loading && isLoading}
      onBlur={onBlurHandler}
      onChange={onChangeCallback}
      onClick={onClick}
      onOptionsShow={defaultOptions?.length ? undefined : onChangeCallback}
      onSelect={onSelectHandler}
      options={filteredOptions}
      placeholder={placeholder}
      popupError={popupError}
      selectedOption={selectedOption}
      showArrowButton={showArrowButton}
    />
  );
});

AsyncSelect.displayName = 'AsyncSelect';

export default React.memo(AsyncSelect);
