import MainInfo from '@components/EmployeeCard/MainInfo';
import EntityHeader from '@components/EntityHeader';
import { useStores } from '@hooks';
import { joinNonEmpty } from '@services/utils';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

/** Карточка сотрудника */
const Employee: FC = () => {
  const { employeeStore } = useStores();
  const { id } = useParams();

  if (!id) {
    return <div>Что-то пошло не так</div>;
  }

  useEffect(() => {
    employeeStore.fetch(id);
  }, [employeeStore, id]);

  return (
    <div className="w-full flex flex-col">
      <EntityHeader
        header={joinNonEmpty(['Сотрудник: ', employeeStore.viewModel.fullName, employeeStore.viewModel.isDeleted ? '(Архивный)' : null])}
        route="/resources/employees"
      />
      <MainInfo employeeId={id} />
    </div>
  );
};

export default observer(Employee);
