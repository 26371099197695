module.exports = {
  theme: {
    extend: {
      maxHeight: {
        options: '300px',
      },
      colors: {
        option: '#F6F6FA',
        'option-hover': '#E5E7EB',
        'option-arrow-hover': '#E5E7EB',
        select: '#F6F6FA',
        tag: '#ffffff',
      },
    },
  },
};
