import React from 'react';
import { parseStyles } from '@services/utils';
import BaseInput from './BaseInput/BaseInput';
import type { BaseInputProps } from './BaseInput/types';
import Label from '../Label/Label';
import type { FormInputsProps } from './types';

/** Текстовый инпут */
const FormInput: React.FC<FormInputsProps & BaseInputProps> = ({
  value,
  onChange,
  error,
  placeholder,
  className,
  type,
  disabled,
  pattern,
  label,
  mask,
  postfix,
  onClick,
  invalid,
  ...props
}) => (
  <div>
    <Label>{label}</Label>
    <BaseInput
      {...props}
      backgroundColor=""
      className={parseStyles`
        border-1
        ${disabled ? 'bg-i-bg-disabled text-i-disabled' : 'bg-white'}
        ${invalid || !!error ? '!text-error border-error' : 'border-gray-200 focus-within:border-i-border-active'}
        ${className}
      `}
      disabled={disabled}
      mask={mask}
      onChange={onChange}
      onClick={onClick}
      pattern={pattern}
      placeholder={placeholder}
      postfix={postfix}
      type={type}
      value={value}
    />
    {error && (
      <span className="text-error break-words text-xs text-left">
        {error}
      </span>
    )}
  </div>
);

export default React.memo(FormInput);
