import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { ErrorMessages } from 'src/enums';
import type BaseViewModel from 'src/stores/BaseViewModel';
import { RegexPatterns } from './utils';

export type ValidateTypes = 'email' | 'inn' | 'phone'

type ViewModelWithValidateFunc<T> = T & {validateField: (field: string, func: () => string | undefined) => void};

export function validationRequired<TValue>(value: TValue) {
  if ((!value || value == null) && value !== 0) {
    return ErrorMessages.requiredField;
  }
  return undefined;
}

export function emailValidation<TValue>(value?: TValue) {
  if (typeof value !== 'string') {
    throw new Error('Тип поля валидируемого как email должен быть string');
  }
  if (!RegexPatterns.email.test(value ?? '')) {
    return ErrorMessages.emailFormat;
  }
  return undefined;
}

export function innValidation<TValue>(value?: TValue) {
  if (typeof value !== 'string') {
    throw new Error('Тип поля валидируемого как inn должен быть string');
  }
  if (!RegexPatterns.inn.test(value ?? '')) {
    return ErrorMessages.innFormat;
  }
  return undefined;
}

export function phoneValidation<TValue>(value?: TValue) {
  if (typeof value !== 'string') {
    throw new Error('Тип поля валидируемого как phone должен быть string');
  }
  if (!RegexPatterns.phone.test(value ?? '')) {
    return ErrorMessages.phoneFormat;
  }
  return undefined;
}

/** Возвращает функцию валидации в зависимости от типа валидации */
export function validateType<TValue>(type?: ValidateTypes): (val: TValue) => string | undefined {
  switch (type) {
    case 'email':
      return emailValidation;
    case 'phone':
      return phoneValidation;
    case 'inn':
      return innValidation;
    default:
      return () => undefined;
  }
}

/** Валидация полей даты на правильный порядок */
export function isSameOrAfterDateValidate(startDate: Dayjs, endDate: Dayjs, errorText?: string) {
  return (!dayjs(endDate)?.isSameOrAfter(startDate, 'day')
    ? errorText || 'Дата окончания не может быть раньше даты начала'
    : undefined);
}

/** Декоратор обязательного поля с типом валидации */
export function required(type?: ValidateTypes, onlyOnSave = true) {
  return <T extends BaseViewModel<unknown>, K extends Extract<keyof T, string>>(_: T, propertyKey: K, descriptor: PropertyDescriptor) => {
    const originalSetter = descriptor.set;
    descriptor.set = function newSetter(this: T, value?: T[K]) {
      originalSetter?.call(this, value);
      if (onlyOnSave && !this._validationInProgress && !this.errors[propertyKey]) {
        return;
      }
      if (!value) {
        /** Если нет значения в поле то валидируется как обязательное поле */
        this.validateField(propertyKey, () => ErrorMessages.requiredField);
        return;
      }
      if (typeof value === 'string') {
        this.validateField(propertyKey, validateType(type));
        return;
      }
      this.validateField(propertyKey, () => undefined);
    };
  };
}

/** Декоратор с типом валидации */
export function validate(type: ValidateTypes) {
  return <T extends BaseViewModel<unknown>, K extends Extract<keyof T, string>>(_: T, propertyKey: K, descriptor: PropertyDescriptor) => {
    const originalSetter = descriptor.set;
    descriptor.set = function newSetter(this: ViewModelWithValidateFunc<T>, value?: T[K]) {
      originalSetter?.call(this, value);
      if (typeof value === 'string') {
        this.validateField(propertyKey, validateType(type));
      } else {
        console.warn('Тип валидации не соответствует типу данных');
      }
    };
  };
}

/** Декоратор валидации минимального числа или даты */
export function min(num?: number | Dayjs) {
  return <T extends BaseViewModel<unknown>, K extends Extract<keyof T, string>>(_: T, propertyKey: K, descriptor: PropertyDescriptor) => {
    const originalSetter = descriptor.set;
    descriptor.set = function newSetter(this: ViewModelWithValidateFunc<T>, value?: T[K]) {
      originalSetter?.call(this, value);

      if (value && (num !== undefined)) {
        if (typeof value === 'number' && typeof num === 'number') {
          this.validateField(propertyKey, () => (value < num ? `Число не может быть меньше ${num}` : undefined));
        } else if (dayjs.isDayjs(value) && dayjs.isDayjs(num)) {
          this.validateField(propertyKey, () => (value?.isBefore(num) ? `Дата должна быть не раньше ${num?.format('DD.MM.YYYY')}` : undefined));
        } else {
          console.warn('Тип валидации не соответствует типу данных');
        }
      }
    };
  };
}

/** Декоратор валидации максимального числа или даты */
export function max(num?: number | Dayjs) {
  return <T extends BaseViewModel<unknown>, K extends Extract<keyof T, string>>(_: T, propertyKey: K, descriptor: PropertyDescriptor) => {
    const originalSetter = descriptor.set;
    descriptor.set = function newSetter(this: ViewModelWithValidateFunc<T>, value?: T[K]) {
      originalSetter?.call(this, value);

      if (value && (num !== undefined)) {
        if (typeof value === 'number' && typeof num === 'number') {
          this.validateField(propertyKey, () => (value > num ? `Число не может быть больше ${num}` : undefined));
        } else if (dayjs.isDayjs(value) && dayjs.isDayjs(num)) {
          this.validateField(propertyKey, () => (value?.isAfter(num) ? `Дата должна быть не позже ${num?.format('DD.MM.YYYY')}` : undefined));
        } else {
          console.warn('Тип валидации не соответствует типу данных');
        }
      }
    };
  };
}

/** Декоратор валидации проверки дат на пересечение */
export function dateIntersection<T>(
  startDateKey: Extract<keyof T, string>,
  endDateKey: Extract<keyof T, string>,
  errorText?: string,
  requiredField: boolean = false,
) {
  return <V extends Extract<keyof T, string>>(_: T, propertyKey: V, descriptor: PropertyDescriptor) => {
    const originalSetter = descriptor.set;
    descriptor.set = function newSetter(this: ViewModelWithValidateFunc<T>, value?: T[V]) {
      originalSetter?.call(this, value);

      const startDateValue = this[startDateKey];
      const endDateValue = this[endDateKey];
      /** Если поля обязательные */
      if (requiredField) {
        this.validateField(propertyKey, () => (!value ? ErrorMessages.requiredField : undefined));
      }

      /** Очистить ошибки */
      const clearErrors = () => {
        this.validateField(startDateKey, () => undefined);
        this.validateField(endDateKey, () => undefined);
      };

      /** Если нет одного из значений */
      if (!startDateValue || !endDateValue) {
        clearErrors();
        return;
      }

      /** Если есть начальная и конечные даты */
      if (dayjs.isDayjs(startDateValue) && dayjs.isDayjs(endDateValue)) {
        const error = isSameOrAfterDateValidate(startDateValue!, endDateValue!, errorText);
        if (error && startDateKey === propertyKey) {
          this.validateField(startDateKey, () => error);
        } else if (error && endDateKey === propertyKey) {
          this.validateField(endDateKey, () => error);
        } else {
          clearErrors();
        }
      } else {
        console.warn('Тип валидации не соответствует типу данных');
      }
    };
  };
}
