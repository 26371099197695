import React, { } from 'react';
import { Navigate } from 'react-router-dom';

/** Страница ошибки оплаты */
const PaymentFailed: React.FC = () => (
  <Navigate
    state={{ status: 'При оплате произошла ошибка.' }}
    to="/settings/organization"
  />
);

export default PaymentFailed;
