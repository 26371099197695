import { User } from '@assets';
import Button from '@components/ui/Button';
import Card from '@components/ui/Card/Card';
import type { WithClassName } from '@components/ui/types';
import { useStores } from '@hooks';
import { parseStyles } from '@services/utils';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { useToggle } from 'usehooks-ts';
import Image from '@components/ui/Image/Image';
import FormGrid from '@components/ui/Form/FormGrid';
import FormInput from '@components/ui/Input/FormInput';
import AvatarEditModal from '@components/Profile/AvatarEditModal';
import Errors from '@components/ui/Errors/Errors';
import ButtonIcons from '@components/ui/Button/ButtonIcons';

/** Карточка организации */
const OrganizationCard: React.FC<WithClassName> = ({ className }) => {
  /** Стор организации */
  const { organizationStore, accountStore } = useStores();

  /** Модалка редактирования автарки */
  const [showAvatarEditModal, toggleAvatarEditModal] = useToggle();

  /** Вью модель организации */
  const { viewModel } = organizationStore;

  /** Состояние режима редактирования */
  const [isEditMode, toggle] = useToggle(false);

  /** Обработчик кнопки Сохранить */
  const onSaveClick = useCallback(async () => {
    const isValid = viewModel.validate();
    if (isEditMode && isValid) {
      await organizationStore.save();
      await organizationStore.fetch(accountStore.organizationId);
    }
    if (isValid || !isEditMode) {
      toggle();
    }
  }, [accountStore.organizationId, isEditMode, organizationStore, toggle, viewModel]);

  /** Обработчик кнопки Отменить */
  const onBackClick = useCallback(() => {
    toggle();
    organizationStore.fetch(accountStore.organizationId);
  }, [accountStore.organizationId, organizationStore, toggle]);

  return (
    <Card
      className={className}
      headerButtons={(
        <>
          <Button.Primary
            icon={isEditMode ? ButtonIcons.Save : ButtonIcons.Edit}
            isLoading={organizationStore.state.isLoading}
            label={isEditMode ? 'Сохранить' : 'Редактировать'}
            onClick={onSaveClick}
          />
          {isEditMode && (
            <Button.Primary
              label="Отменить"
              onClick={onBackClick}
              type="outline"
            />
          )}
        </>
      )}
      headerLabel="Данные организации"
    >
      <div className="flex grow-0 gap-8">
        <div className="self-center relative w-32">
          {isEditMode && (
            <div
              className={parseStyles`
                absolute left-0 right-0 bottom-0 w-full h-8 m-auto bg-white bg-opacity-40 
                hover:bg-opacity-50 active:bg-opacity-60 cursor-pointer 
              `}
              onClick={toggleAvatarEditModal}
            >
              <BsPencilSquare
                className="absolute left-0 right-0 bottom-1.5 w-full m-auto active:scale-110"
                size={20}
              />
            </div>
          )}
          {viewModel.logoLink ? (
            <Image
              alt="profilePicture"
              className="self-center rounded-full object-cover h-32 w-32"
              imageLink={viewModel.logoLink!}
            />
          ) : (
            <User
              className="h-32 w-32"
              color="gray"
            />
          )}
        </div>
        <FormGrid
          className="flex-grow h-fit"
          maxColumnsNumber={1}
        >
          <FormInput
            disabled={!isEditMode}
            error={viewModel.errors.name}
            label="Наименование"
            onChange={(event) => {
              viewModel.name = event.target.value;
            }}
            value={viewModel.name}
          />
          <FormInput
            disabled={!isEditMode}
            error={viewModel.errors.name}
            label="ИНН"
            onChange={(event) => {
              viewModel.inn = event.target.value;
            }}
            value={viewModel.inn}
          />
        </FormGrid>
      </div>
      {showAvatarEditModal && (
        <AvatarEditModal
          file={viewModel.logoLink}
          onClose={() => toggleAvatarEditModal()}
          setAvatar={(file) => organizationStore.uploadAvatar(file)}
        />
      )}
      <Errors store={organizationStore} />
    </Card>
  );
};

export default observer(OrganizationCard);
