import { Select } from '@components/ui/Select';
import { useStores, useTitle } from '@hooks';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import type { SelectOption } from 'src/components/ui/Select/types';
import { useEffectOnce } from 'usehooks-ts';
import ResourcesTable from '@components/CalendarTable/ResourcesList/ResourcesTable';
import { periods } from '@services/utils';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { ResourceTypes } from '@typesApi';
import IconDescriptions from '@components/CalendarTable/IconDescriptions';
import ListHeader from '@components/ListHeader';

/** Реестр ресурсов */
const ResourcesLoad: React.FC = () => {
  useTitle('Нагрузка сотрудников');
  const { resourcesLoadStore } = useStores();

  const selectCallback = useCallback(
    (val?: SelectOption) => {
      if (val) {
        runInAction(() => {
          resourcesLoadStore.period = Number(val.value);
        });
      }
    },
    [resourcesLoadStore],
  );

  useEffectOnce(() => {
    runInAction(() => {
      resourcesLoadStore.startDate = dayjs.utc().startOf('day').toDate();
      resourcesLoadStore.filter.resourceType = ResourceTypes.Employee;
    });
  });

  return (
    <div className="w-full flex flex-col">
      <ListHeader
        buttons={(
          <>
            <div className="flex justify-end gap-2 items-center">
              <div>С даты:</div>
              <DatePicker
                clearable={false}
                height={30}
                onChange={(value) => {
                  runInAction(() => {
                    if (value) {
                      runInAction(() => {
                        resourcesLoadStore.startDate = value?.utc().toDate();
                      });
                    }
                  });
                }}
                value={dayjs(resourcesLoadStore.startDate)}
                utc
              />
            </div>
            <Select.Base
              clearable={false}
              height={30}
              onSelect={selectCallback}
              options={periods}
              selectedOption={periods.find((e) => e.value === String(resourcesLoadStore.period))}
            />
          </>
        )}
        title="Нагрузка сотрудников"
      />
      <ResourcesTable
        className="flex-1"
        columnWidth={25}
        listCellWidth={300}
        rowHeight={25}
      />
      <IconDescriptions
        className="p-2 border-1"
        variant="resources"
      />
    </div>
  );
};

export default observer(ResourcesLoad);
