import React from 'react';
import { parseStyles } from '@services/utils';
import { AnimatedUnderlineText } from '../AnimatedUnderlineText';
import type { BaseButtonProps } from './types';

/** Кнопка с внешним выделением */
const OutlineButton: React.FC<BaseButtonProps> = ({
  label, onClick, className, isDisabled,
}) => (
  <button
    className={parseStyles`
      w-full
      transition-all duration-300 ease-in-out
      text-btn-default
      [&:hover>div>div]:bg-[length:100%_2px]
      text-btn-outline-active hover:text-btn-outline
      ${isDisabled && 'cursor-not-allowed'}
      ${className}
    `}
    disabled={isDisabled}
    onClick={onClick}
    title={label}
    type="button"
  >
    <AnimatedUnderlineText className="truncate">
      {label}
    </AnimatedUnderlineText>
  </button>
);

export default OutlineButton;
