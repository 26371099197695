import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useConfirm, useInterceptChanges, useStores } from '@hooks';
import { useToggle } from 'usehooks-ts';
import { useNavigate, useParams } from 'react-router-dom';
import { FormInput, FormTextarea } from '@components/ui/Input';
import Button from '@components/ui/Button';
import Card from '@components/ui/Card/Card';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import FormGrid from '@components/ui/Form/FormGrid';
import { Select } from '@components/ui/Select';
import ButtonIcons from '@components/ui/Button/ButtonIcons';
import type { ProjectStatuses } from '@typesApi';
import { RussianProjectStatusesForSelect } from 'src/enums';

/** Основная информация о проекте */
const ProjectMainInfo: React.FC = () => {
  /** Идентификатор проекта */
  const { id } = useParams();
  /** Сторы: проекта, привилегий, пользователя */
  const { projectStore, counterpartyStore } = useStores();
  /** Привилегии */
  const { canUpdateResponsible, canDelete, canEdit } = projectStore;
  /** Модалка подтверждения архивирования */
  const confirm = useConfirm('Архивировать проект?');
  /** Хук навигации */
  const navigate = useNavigate();
  /** Модель основной информации о проекте */
  const { viewModel, isDirty, resetIsDirty } = useInterceptChanges(projectStore.viewModel);
  /** Режим редактирования */
  const [editing, toggleEditing] = useToggle();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '100%';
    }
  }, []);

  /** Сохранение изменений формы */
  const saveHandler = useCallback(async () => {
    if (!viewModel.validate()) {
      return;
    }

    await projectStore.save();
    if (id && projectStore.state.isSuccess) {
      await projectStore.fetch(id);
    }
    if (projectStore.state.isSuccess) {
      toggleEditing();
      resetIsDirty();
    }
  }, [id, projectStore, resetIsDirty, toggleEditing, viewModel]);

  /** Обработчик архивирования проекта */
  const archiveHandler = useCallback(async () => {
    const confirmResult = await confirm();
    if (confirmResult) {
      if (viewModel.deletedOn) {
        await projectStore.unarchive();
      } else {
        await projectStore.archive();
      }
      if (id && projectStore.state.isSuccess) {
        await projectStore.fetch(id);
      }
    }
  }, [confirm, id, projectStore, viewModel.deletedOn]);

  /** Обработчик отмены изменений */
  const cancelHandler = useCallback(() => {
    if (isDirty && viewModel.id) {
      projectStore.fetch(viewModel.id);
      resetIsDirty();
    }
    toggleEditing();
  }, [isDirty, projectStore, resetIsDirty, toggleEditing, viewModel.id]);

  return (
    <Card
      className="flex-[1_1_0] min-w-[45%]"
      headerButtons={(canEdit || canDelete) && (
        <>
          {canEdit && (
            <Button.Primary
              icon={editing ? ButtonIcons.Save : ButtonIcons.Edit}
              isLoading={projectStore.state.isLoading}
              label={editing ? 'Сохранить' : 'Редактировать'}
              onClick={editing ? saveHandler : toggleEditing}
            />
          )}
          {editing && (
            <Button.Primary
              className="py-2"
              label="Отменить"
              onClick={cancelHandler}
              type="outline"
            />
          )}
          {canDelete && (
            <Button.Primary
              icon={viewModel.deletedOn ? ButtonIcons.ArchiveOut : ButtonIcons.ArchiveIn}
              isLoading={projectStore.state.isLoading}
              label={viewModel.deletedOn ? 'Вернуть из архива' : 'Архивировать'}
              onClick={archiveHandler}
              type={viewModel.deletedOn ? 'main' : 'danger'}
            />
          )}
        </>
      )}
      headerLabel="Основная информация о проекте"
    >
      <>
        <FormGrid maxColumnsNumber={1}>
          <FormInput
            disabled={!editing}
            error={viewModel.errors.name}
            label="Наименование проекта"
            onChange={(e) => {
              viewModel.name = e.target.value;
            }}
            value={viewModel.name}
          />
          <FormGrid minColumnWidth={2}>
            <Select.Async
              disabled={!editing || !canUpdateResponsible}
              label="Ответственный"
              onChange={async (value) => projectStore.fetchEmployeeNames(value)}
              onClick={viewModel.responsibleId && projectStore.canReadResponsible
                ? () => navigate(`/resources/employees/${viewModel.responsibleId}`)
                : undefined}
              onSelect={(option) => {
                viewModel.responsible = option;
              }}
              selectedOption={viewModel.responsible}
              clearable
            />
            <Select.AutoComplete
              disabled={!editing}
              label="Статус"
              onSelect={(option) => {
                viewModel.status = option?.value as ProjectStatuses;
              }}
              options={RussianProjectStatusesForSelect}
              selectedOption={viewModel.russianStatusForSelect}
            />
          </FormGrid>
        </FormGrid>
        <div className="flex gap-2 w-full flex-col lg:flex-row">
          <div className="flex flex-1 flex-col gap-2">
            <DatePicker
              disabled={!editing}
              label="Дата начала проекта"
              onChange={(value) => {
                viewModel.startDate = value;
              }}
              value={viewModel.startDate}
              utc
            />
            <DatePicker
              disabled={!editing}
              label="Дата окончания проекта"
              onChange={(value) => {
                viewModel.finishDate = value;
              }}
              value={viewModel.finishDate}
              utc
            />
            <Select.Async
              disabled={!editing}
              label="Контрагент"
              onChange={(query) => projectStore.fetchContragents(query)}
              onClick={counterpartyStore.canRead && viewModel.contragentId
                ? () => navigate(`/settings/counterparties/${viewModel.contragentId}`)
                : undefined}
              onSelect={(option) => {
                viewModel.contragentName = option?.label;
                viewModel.contragentId = option?.value;
              }}
              selectedOption={viewModel.selectedСontragent}
            />
          </div>
          <div className="flex-1 flex flex-col">
            <FormTextarea
              ref={textareaRef}
              className="flex-[1_1_0] [&>textarea]:resize-none [&>textarea]:min-h-[160px]"
              disabled={!editing}
              label="Комментарий"
              onChange={(e) => {
                viewModel.comment = e.target.value;
              }}
              value={viewModel.comment}
            />
          </div>
        </div>
      </>
    </Card>
  );
};

export default observer(ProjectMainInfo);
