import type { GetProjectRequirementEmployeeByIdResponse } from '@typesApi';
import { computed, runInAction } from 'mobx';
import { ErrorMessages } from 'src/enums';
import ProjectRequirementBaseViewModel from './ProjectRequirementBaseViewModel';

class ProjectRequirementEmployeeViewModel extends ProjectRequirementBaseViewModel<GetProjectRequirementEmployeeByIdResponse> {
  @computed get positionId() {
    return this.data.positionId ?? '';
  }

  set positionId(value: string | undefined) {
    runInAction(() => {
      this.data.positionId = value;
      this.validateField(
        'positionId',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get positionName() {
    return this.data.positionName ?? '';
  }

  set positionName(value: string | undefined) {
    runInAction(() => {
      this.data.positionName = value;
    });
  }

  @computed get specializationId() {
    return this.data.specializationId ?? '';
  }

  set specializationId(value: string | undefined) {
    runInAction(() => {
      this.data.specializationId = value;
      this.validateField(
        'specializationId',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get specializationName() {
    return this.data.specializationName ?? '';
  }

  set specializationName(value: string | undefined) {
    runInAction(() => {
      this.data.specializationName = value;
    });
  }

  @computed get qualificationId() {
    return this.data.qualificationId ?? '';
  }

  set qualificationId(value: string | undefined) {
    runInAction(() => {
      this.data.qualificationId = value;
      this.validateField(
        'qualificationId',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get qualificationName() {
    return this.data.qualificationName ?? '';
  }

  set qualificationName(value: string | undefined) {
    runInAction(() => {
      this.data.qualificationName = value;
    });
  }

  @computed get maxWorkingRate() {
    return this.data.maxWorkingRate;
  }

  set maxWorkingRate(value: number | undefined | null) {
    runInAction(() => {
      this.data.maxWorkingRate = value;
      this.validateField(
        'maxWorkingRate',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }
}

export default ProjectRequirementEmployeeViewModel;
