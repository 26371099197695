import { joinNonEmpty } from '@services/utils';
import type { GetProjectEmployeesResponseProjectRoles } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import type { EmployeesInProjectTableItem } from 'src/types/EmployeesInProjectTableItem';
import BaseViewModel from '../BaseViewModel';
import type { GetProjectEmployeesResponseItemWithRequiredId } from './EmployeesInProjectStore';

class EmployeesInProjectItemViewModel extends BaseViewModel<GetProjectEmployeesResponseItemWithRequiredId> implements EmployeesInProjectTableItem {
  @observable selected?: boolean = false;

  constructor(data: GetProjectEmployeesResponseItemWithRequiredId) {
    super(data);
    makeObservable(this);
  }

  /** Получение id */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получение surname */
  @computed get surname() {
    return this.data.surname;
  }

  /** Изменение surname */
  set surname(value: string | undefined | null) {
    runInAction(() => {
      this.data.surname = value;
    });
  }

  /** Получение personName */
  @computed get personName() {
    return this.data.personName;
  }

  /** Изменение personName */
  set personName(value: string | undefined | null) {
    runInAction(() => {
      this.data.personName = value;
    });
  }

  /** Получение patronymic */
  @computed get patronymic() {
    return this.data.patronymic;
  }

  /** Изменение patronymic */
  set patronymic(value: string | undefined | null) {
    runInAction(() => {
      this.data.patronymic = value;
    });
  }

  @computed get fullName() {
    return joinNonEmpty([this.surname, this.personName, this.patronymic]);
  }

  /** Получение mainSpecializationId */
  @computed get mainSpecializationId() {
    return this.data.mainSpecializationId;
  }

  /** Изменение mainSpecializationId */
  set mainSpecializationId(value: string | undefined | null) {
    runInAction(() => {
      this.data.mainSpecializationId = value;
    });
  }

  /** Получение mainSpecializationName */
  @computed get mainSpecializationName() {
    return this.data.mainSpecializationName ?? '';
  }

  /** Изменение mainSpecializationName */
  set mainSpecializationName(value: string | undefined) {
    runInAction(() => {
      this.data.mainSpecializationName = value;
    });
  }

  /** Получение mainQualificationId */
  @computed get mainQualificationId() {
    return this.data.mainQualificationId ?? '';
  }

  /** Изменение mainQualificationId */
  set mainQualificationId(value: string | undefined) {
    runInAction(() => {
      this.data.mainQualificationId = value;
    });
  }

  /** Получение mainQualificationName */
  @computed get mainQualificationName() {
    return this.data.mainQualificationName ?? '';
  }

  /** Изменение mainQualificationName */
  set mainQualificationName(value: string | undefined) {
    runInAction(() => {
      this.data.mainQualificationName = value;
    });
  }

  /** Получение positionId */
  @computed get positionId() {
    return this.data.positionId ?? '';
  }

  /** Изменение positionId */
  set positionId(value: string | undefined) {
    runInAction(() => {
      this.data.positionId = value;
    });
  }

  /** Получение positionName */
  @computed get positionName() {
    return this.data.positionName ?? '';
  }

  /** Изменение positionName */
  set positionName(value: string | undefined) {
    runInAction(() => {
      this.data.positionName = value;
    });
  }

  /** Получение email */
  @computed get email() {
    return this.data.email ?? '';
  }

  /** Изменение email */
  set email(value: string | undefined) {
    runInAction(() => {
      this.data.email = value;
    });
  }

  /** Получение phone */
  @computed get phone() {
    return this.data.phone ?? '';
  }

  /** Изменение phone */
  set phone(value: string | undefined) {
    runInAction(() => {
      this.data.phone = value;
    });
  }

  /** Получение projectRoles */
  @computed get projectRoles() {
    return this.data.projectRoles;
  }

  /** Изменение projectRoles */
  set projectRoles(value: GetProjectEmployeesResponseProjectRoles[] | null | undefined) {
    runInAction(() => {
      this.data.projectRoles = value;
    });
  }

  /** id ресурса на проекте */
  @computed get resourceInProjectId() {
    return this.data.resourceInProjectId;
  }
}

export default EmployeesInProjectItemViewModel;
