import { SubscriptionTypeService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetSubscriptionTypesResponseItem } from '@typesApi';
import { makeObservable, runInAction } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import SubscriptionsItemViewModel from './SubscriptionsItemViewModel';

/** Стор подписок */
class SubscriptionsStore extends BaseEntityListStore<GetSubscriptionTypesResponseItem, ListBaseFilter, SubscriptionsItemViewModel> {
  constructor() {
    super(20);
    makeObservable(this);
  }

  public async fetch(): Promise<void> {
    this.runWithStateControl(async () => {
      const { data } = await SubscriptionTypeService.list(this.filter);
      runInAction(() => {
        this.setData(data, SubscriptionsItemViewModel);
      });
    });
  }
}

export default SubscriptionsStore;
