import type { GetAccountByIdResponseEmployeeRoleItem } from '@typesApi';
import { makeObservable } from 'mobx';
import type { ListBaseFilter } from '@types';
import BaseEntityListStore from '../BaseEntityListStore';
import { AccountRolesItemViewModel } from './AccountRolesItemViewModel';

/** Стор ролей аккаунта */
export class AccountRolesStore extends BaseEntityListStore<GetAccountByIdResponseEmployeeRoleItem, ListBaseFilter, AccountRolesItemViewModel> {
  constructor(roles: GetAccountByIdResponseEmployeeRoleItem[]) {
    super();
    makeObservable(this);
    this.setData({ entities: roles, totalCount: roles.length }, AccountRolesItemViewModel);
  }

  /** Фетч ролей */
  public override async fetch(): Promise<void> {
    throw new Error('Function not implemented');
  }
}
