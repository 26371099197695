import { EmployeeService, ResourceService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetEmployeesResponseItem } from '@typesApi';
import { ResourceTypes } from '@typesApi';
import { observable, makeObservable } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import { EmployeesItemViewModel } from './EmployeesItemViewModel';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

export type GetEmployeesResponseItemWithRequiredId = Required<Pick<GetEmployeesResponseItem, 'id'>> & Omit<GetEmployeesResponseItem, 'id'>

interface EmployeesFilter extends ListBaseFilter {
  filterName?: string;
  filterProjectRoleName?: string;
  filterProjectRoleCode?: string;
  isDeleted?: boolean;
  isProjectDeleted?: boolean;
  isResourceInProjectsFinished?: boolean;
}

/** Стор реестра */
export class EmployeesStore extends BaseEntityListStore<GetEmployeesResponseItemWithRequiredId, EmployeesFilter, EmployeesItemViewModel> {
  @observable private _privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.resources.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.resources.canDelete
      || this._privilegesStore.responsibleForResources.some((x) => x.resourceType === ResourceTypes.Employee);
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.resources.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.resources.canRead;
  }

  /** Привилегия на импорт */
  public get canImport(): boolean {
    return this._privilegesStore.projects.canImport;
  }

  /** Получение списка */
  public async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await EmployeeService.list(this.filter);
      data.entities = data.entities?.map<GetEmployeesResponseItemWithRequiredId>((el) => ({ ...el, id: el.id ?? '' }));
      this.setData(data as {
        entities: GetEmployeesResponseItemWithRequiredId[];
        totalCount: number;
      }, EmployeesItemViewModel);
    });
  }

  public async deleteSelectedEmployees(): Promise<void> {
    await this.runWithStateControl(async () => {
      await Promise.all(this.viewModel.filter((e) => e.selected).map((e) => ResourceService.delete(e.id, { deleteRightNow: true })));
    });
    if (this.state.isSuccess) {
      await this.fetch();
    }
  }

  public async restoreSelectedEmployees(): Promise<void> {
    await this.runWithStateControl(async () => {
      await Promise.all(this.viewModel.filter((e) => e.selected).map((e) => ResourceService.delete(e.id, {})));
    });
    if (this.state.isSuccess) {
      await this.fetch();
    }
  }

  /** Импорт сотрудников */
  public async importEmployees(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.runWithStateControl(async () => {
      const { data } = await EmployeeService.importCreate(formData as Object);

      return data;
    });
  }
}
