import type { RequirementsTableItem, TableItemView } from '@types';
import type { GetProjectRequirementsResponseItem } from '@typesApi';
import { ResourceTypes } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { Resources } from 'src/enums';
import BaseViewModel from '../BaseViewModel';

/** Вью модель элемента списка */
export class RequirementsItemViewModel extends BaseViewModel<GetProjectRequirementsResponseItem> implements TableItemView<
RequirementsTableItem,
GetProjectRequirementsResponseItem
> {
  constructor(data: GetProjectRequirementsResponseItem) {
    super(data);
    makeObservable(this);
  }

  /** выбрана ли потребность */
  @observable
    selected: boolean = false;

  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получение */
  @computed get projectName() {
    return this.data.projectName ?? '';
  }

  /** Изменение */
  set projectName(value: string | undefined) {
    runInAction(() => {
      this.data.projectName = value;
    });
  }

  /** Получение id */
  @computed get projectId() {
    return this.data.projectId ?? '';
  }

  /** Изменение id */
  set projectId(value: string | undefined) {
    runInAction(() => {
      this.data.projectId = value;
    });
  }

  /** Получение */
  @computed get resourceType() {
    return this.data?.resourceType
      ? ResourceTypes[this.data.resourceType]
      : undefined;
  }

  /** Изменение */
  set resourceType(value: ResourceTypes | undefined) {
    runInAction(() => {
      this.data.resourceType = value ? ResourceTypes[value] : undefined;
    });
  }

  /** Тип ресурса для отображения */
  @computed get resourceTypeToDisplay() {
    return this.resourceType ? Resources[this.resourceType] : undefined;
  }

  /** Получение */
  @computed get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : undefined;
  }

  /** Изменение */
  set startDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
    });
  }

  /** Получение */
  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : undefined;
  }

  /** Изменение */
  set finishDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
    });
  }

  /** Получение */
  @computed get projectRequirementName() {
    return this.data.name ?? '';
  }

  /** Изменение */
  set projectRequirementName(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение */
  @computed get totalLoad() {
    return this.data.totalLoad ?? 0;
  }

  /** Изменение */
  set totalLoad(value: number | undefined) {
    runInAction(() => {
      this.data.totalLoad = value;
    });
  }

  /** Получение */
  @computed get description() {
    return this.data.description ?? '';
  }

  /** Изменение */
  set description(value: string | undefined) {
    runInAction(() => {
      this.data.description = value;
    });
  }
}
