import { useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import NoAccess from 'src/views/NoAccess';
import type { ProtectedRouteProps } from './types';
import Loader from '../Loader/Loader';

/** Компонент проверяет наличие доступа к странице и редиректит на главную если доступа нет */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  checkAccess,
  component,
}) => {
  const { privilegesStore } = useStores();

  if (!privilegesStore.privileges) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader size={40} />
      </div>
    );
  }

  if (!!privilegesStore.privileges && !checkAccess()) {
    return (
      <NoAccess prevPath={window.location.pathname} />
    );
  }

  return component;
};

export default observer(ProtectedRoute);
