import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useConfirm, useInterceptChanges, useStores } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { Select } from '@components/ui/Select';
import { ConfirmQuestions } from 'src/enums';
import Errors from '@components/ui/Errors/Errors';
import DrawerWithWrapper from '../DrawerResourceLayout/DrawerWithWrapper';
import type { ResourceModalProps } from './types';

/** Форма редактирования и добавления ресурса техника */
const EquipmentResourceForm: React.FC<ResourceModalProps> = ({
  resourceId,
  onClose,
}) => {
  const navigate = useNavigate();
  const {
    equipmentStore, equipmentsStore,
  } = useStores();
  /** Привилегии */
  const { canUpdateResponsible, canUpdate } = equipmentStore;
  /** ViewModel отслеживается на изменения isDirty = true, если были изменения */
  const { viewModel, isDirty, resetIsDirty } = useInterceptChanges(equipmentStore.viewModel);
  /** Возможность редактирования */
  const [editing, setEditing] = useState(!resourceId);

  useEffectOnce(() => {
    if (resourceId) {
      equipmentStore.fetch(resourceId);
    }
    equipmentStore.fetchPlaceNames();
    equipmentStore.fetchEmployeeNames();
    return () => equipmentStore.clearStore();
  });

  /** Хук для модалки подтверждения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  /** Хэндлер обработки подтверждения или отмены закрытия модалки */
  const confirmHandler = useMemo(() => {
    if (isDirty) {
      return confirm;
    }
    return undefined;
  }, [confirm, isDirty]);

  /** Обработчик отмены изменений */
  const cancelHandler = useCallback(async () => {
    if (isDirty && viewModel.id) {
      await equipmentStore.fetch(viewModel.id);
      resetIsDirty();
    }
    setEditing(!editing);
  }, [isDirty, equipmentStore, resetIsDirty, editing, viewModel.id]);

  /** Футер дровера */
  const footer = useMemo(() => {
    /** Создание и сохранение */
    const changeFormHandler = async () => {
      if (!viewModel.validate()) {
        return;
      }
      await equipmentStore.save();
      if (canUpdateResponsible) {
        await equipmentStore.setResponsible();
      }

      if (equipmentStore.state.isSuccess) {
        await equipmentsStore.fetch();
        onClose();
      }
    };

    return (
      <div className="flex">
        {!resourceId && (
          <Button.Primary
            isLoading={equipmentStore.state.isLoading}
            label="Добавить"
            onClick={changeFormHandler}
          />
        )}
        {!editing && canUpdate && resourceId && (
          <Button.Primary
            isLoading={equipmentStore.state.isLoading}
            label="Редактировать"
            onClick={() => setEditing(true)}
          />
        )}
        {editing && resourceId && (
          <Button.Primary
            isLoading={equipmentStore.state.isLoading}
            label="Сохранить"
            onClick={changeFormHandler}
          />
        )}
        {editing && !!resourceId && (
          <Button.Primary
            className="ml-2"
            label="Отменить"
            onClick={cancelHandler}
            type="outline"
          />
        )}
      </div>
    );
  }, [canUpdateResponsible, editing, equipmentStore, equipmentsStore, canUpdate, onClose, resourceId, viewModel]);

  const title = useMemo(() => {
    if (!resourceId) {
      return 'Добавление техники';
    }
    return `Техника ${viewModel.name ?? ''}${viewModel.isDeleted ? ' (Архивный)' : ''}`;
  }, [resourceId, viewModel.isDeleted, viewModel.name]);

  return (
    <DrawerWithWrapper
      confirm={confirmHandler}
      footer={footer}
      onClose={onClose}
      title={title}
    >
      <div className="flex flex-col gap-form">
        <FormInput
          disabled={!editing || !canUpdate}
          error={viewModel.errors.name}
          label="Наименование"
          onChange={(event) => {
            viewModel.name = event.target.value;
          }}
          value={viewModel.name}
        />
        <Select.Catalog
          catalogIdField="typeId"
          catalogName="Equipment"
          catalogNameField="typeId"
          disabled={!editing || !canUpdate}
          error={viewModel.errors.typeName}
          label="Тип техники"
          onSelect={(option) => {
            if (option) {
              viewModel.typeId = option.value;
              viewModel.typeName = option.label;
            }
          }}
          viewModel={viewModel}
        />
        <FormInput
          disabled={!editing || !canUpdate}
          label="Инвентарный номер"
          onChange={(event) => {
            viewModel.inventoryNumber = event.target.value;
          }}
          value={viewModel.inventoryNumber}
        />
        <FormInput
          disabled={!editing || !canUpdate}
          label="Параметры"
          onChange={(event) => {
            viewModel.parameters = event.target.value;
          }}
          value={viewModel.parameters}
        />
        <Select.Async
          disabled={!editing || !canUpdate}
          label="Сотрудник"
          onChange={(search) => equipmentStore.fetchEmployeeNames(search)}
          onClick={viewModel.employeeId && equipmentStore.canReadEmployee
            ? () => navigate(`/resources/employees/${viewModel.employeeId}`)
            : undefined}
          onSelect={(option) => {
            viewModel.employeeName = option?.label;
            viewModel.employeeId = option?.value;
          }}
          selectedOption={viewModel.employeeId && viewModel.employeeName
            ? { label: viewModel.employeeName, value: viewModel.employeeId }
            : undefined}
          clearable
        />
        <Select.Async
          disabled={!editing || !canUpdate}
          label="Помещение"
          onChange={(search) => equipmentStore.fetchPlaceNames(search)}
          onSelect={(option) => {
            viewModel.placeName = option?.label;
            viewModel.placeId = option?.value;
          }}
          selectedOption={viewModel.placeName && viewModel.placeId
            ? { label: viewModel.placeName ?? '', value: viewModel.placeId ?? '' }
            : undefined}
          clearable
        />
        <DatePicker
          className="border-1"
          disabled={!editing || !canUpdate}
          error={viewModel.errors.useStartDate}
          label="Дата начала использования"
          onChange={(value) => {
            viewModel.useStartDate = value;
          }}
          value={viewModel.useStartDate}
        />
        <DatePicker
          className="border-1"
          disabled={!editing || !canUpdate}
          error={viewModel.errors.endOfLifeDate}
          label="Дата окончания срока службы"
          onChange={(value) => {
            viewModel.endOfLifeDate = value;
          }}
          value={viewModel.endOfLifeDate}
        />
        <DatePicker
          className="border-1"
          disabled={!editing || !canUpdate}
          error={viewModel.errors.lastTechnicalInspection}
          label="Последнее ТО"
          onChange={(value) => {
            viewModel.lastTechnicalInspection = value;
          }}
          value={viewModel.lastTechnicalInspection}
        />
        <DatePicker
          className="border-1"
          disabled={!editing || !canUpdate}
          error={viewModel.errors.nextTechnicalInspection}
          label="Следующее ТО"
          onChange={(value) => {
            viewModel.nextTechnicalInspection = value;
          }}
          value={viewModel.nextTechnicalInspection}
        />
        <FormInput
          disabled={!editing || !canUpdate}
          error={viewModel.errors.initialCost}
          label="Начальная стоимость"
          onChange={(event) => {
            viewModel.initialCost = +event.target.value;
          }}
          type="number"
          value={viewModel.initialCost}
        />
        {resourceId
          ? (
            <Select.Async
              disabled={!editing || !canUpdateResponsible}
              error={viewModel.errors.responsibleFullName}
              label="Ответственный"
              onChange={(search) => equipmentStore.fetchEmployeeNames(search)}
              onClick={viewModel.responsibleId && equipmentStore.canReadResponsible
                ? () => navigate(`/resources/employees/${viewModel.responsibleId}`)
                : undefined}
              onSelect={(option) => {
                viewModel.responsible = option;
              }}
              selectedOption={viewModel.responsible}
              clearable
            />
          )
          : null }
      </div>
      <Errors store={equipmentStore} />
    </DrawerWithWrapper>
  );
};

export default observer(EquipmentResourceForm);
