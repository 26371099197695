import React, {
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Placeholders } from 'src/enums';
import BaseSelect from '../BaseSelect/BaseSelect';
import type { SelectOption, SelectProps } from '../types';

/** Компонент автоподбора значения из списка опций соответствующего введенным данным */
const AutoComplete = forwardRef<HTMLInputElement, SelectProps>(({
  label,
  placeholder = Placeholders.startTyping,
  options,
  selectedOption,
  error,
  height,
  clearable,
  disabled = false,
  showArrowButton = true,
  onSelect,
  loading,
  onClick,
  backgroundColor,
  invalid,
}, ref) => {
  /** Внутреннее состояние опций для фильтрации */

  const [filterQuery, setFilterQuery] = useState<string | undefined>(undefined);

  const filteredOptions = useMemo(
    () => options?.filter((option) => option.label
      .toLowerCase()
      .includes(filterQuery?.toLowerCase() ?? '')),
    [filterQuery, options],
  );

  /** Хэндлер обработки ввода данных в input */
  const onChangeHandler = useCallback((value?: string) => {
    setFilterQuery(value);
  }, []);

  /** Выбор опции */
  const onSelectHandler = useCallback((option?: SelectOption) => {
    setFilterQuery(undefined);
    if (onSelect) {
      onSelect(option);
    }
  }, [onSelect]);

  /** Возврат опций на onBlur если по введенному запросу ничего не найдено */
  const onBlurHandler = useCallback(() => {
    setFilterQuery(undefined);
  }, []);

  return (
    <BaseSelect
      ref={ref}
      backgroundColor={backgroundColor}
      clearable={clearable}
      disabled={disabled}
      error={error}
      height={height}
      invalid={invalid}
      label={label}
      loading={loading}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      onClick={onClick}
      onSelect={onSelectHandler}
      options={filteredOptions}
      placeholder={placeholder}
      selectedOption={selectedOption}
      showArrowButton={showArrowButton}
    />
  );
});

AutoComplete.displayName = 'AutoComplete';

export default React.memo(AutoComplete);
