import React from 'react';

interface IconsDescriptionProps {
  children: React.ReactNode;
  description: string;
}

const IconDescription: React.FC<IconsDescriptionProps> = ({
  description,
  children,
}) => (
  <div className="flex gap-2 items-center">
    <svg
      height={25}
      style={{ minWidth: 25 }}
      width={25}
    >
      {children}
    </svg>
    -
    <div>
      {description}
    </div>
  </div>
);

export default IconDescription;
