import { InputHeader, ProjectCreateForm } from '@components';
import ImportResultModal from '@components/ImportResultModal/ImportResultModal';
import TablePageLayout from '@components/TablePageLayout';
import Button from '@components/ui/Button';
import ButtonIcons from '@components/ui/Button/ButtonIcons';
import Dropdown from '@components/ui/Dropdown/Dropdown';
import Errors from '@components/ui/Errors/Errors';
import {
  LinkCell,
  Column,
  Table,
  RowCheckboxForDeleteProjects,
} from '@components/ui/Table';
import { useConfirm, useStores, useTitle } from '@hooks';
import { archiveHash, chooseFile } from '@services/utils';
import type { ProjectsTableItem } from '@types';
import type { FileImportResponse, GetProjectsResponseItem } from '@typesApi';
import { computed, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RussianProjectStatuses } from 'src/enums';
import { useEffectOnce, useToggle } from 'usehooks-ts';

const statusCell: React.FC<{data: ProjectsTableItem}> = ({ data }) => (
  <div className="h-full flex items-center pl-4">{data.status ? RussianProjectStatuses[data.status] : ''}</div>
);

/** Ячейка для перехода к контрагенту */
const LinkToContragentCell: React.FC<{data: ProjectsTableItem}> = ({ data }) => (
  <LinkCell
    linkId="contragentId"
    object={data}
    prefix="settings/counterparties"
    value="contragentName"
  />
);

const Projects: React.FC = () => {
  useTitle('Проекты');
  const { projectsStore } = useStores();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const {
    canRead, canCreate, canDelete, canImport,
  } = projectsStore;
  const [importResult, setShowImportResult] = useState<FileImportResponse>();
  const confirmDelete = useConfirm('Архивировать выбранные проекты?');
  const confirmRestore = useConfirm('Восстановить выбранные проекты?');

  /** Отображение архивных или действующих проектов */
  const [isArchive, setIsArchive] = useState(false);

  /** Отображение модалки создания нового проекта */
  const [showCreateModal, toggleCreate] = useToggle();

  useEffectOnce(() => {
    runInAction(() => {
      projectsStore.filter.orderBy = 'name';
      projectsStore.filter.isAscending = true;
    });
    return () => projectsStore.clearStore();
  });

  useEffect(() => {
    if (hash?.includes(archiveHash)) {
      setIsArchive(true);
      runInAction(() => {
        projectsStore.filter.isDeleted = true;
      });
    } else {
      setIsArchive(false);
      runInAction(() => {
        projectsStore.filter.isDeleted = false;
      });
    }
    projectsStore.fetch();
  }, [hash, projectsStore, projectsStore.filter, setIsArchive]);

  /** Хээндлер архивирования и восстановления проектов */
  const deleteRestoreHandler = useCallback(async () => {
    if (!isArchive && await confirmDelete()) {
      projectsStore.deleteSelectedProjects();
    }
    if (isArchive && await confirmRestore()) {
      projectsStore.restoreSelectedProjects();
    }
  }, [confirmDelete, confirmRestore, isArchive, projectsStore]);

  /** Заблокирована ли кнопка Удалить */
  const isDeleteDisabled = computed(() => !projectsStore.viewModel.filter((e) => e.selected).length).get();

  const onRowClickHandler = useCallback((id: string) => {
    navigate(isArchive ? `${id}${archiveHash}` : `${id}`);
  }, [isArchive, navigate]);

  /** Хедер колонки "Название проекта" */
  const projectNameHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="filterName"
      store={projectsStore}
    />
  ), [projectsStore]);

  /** Обработчик импорта сотрудников */
  const importProjectsHandler = useCallback(async (file: File) => {
    const result = await projectsStore.importProjects(file);
    if (projectsStore.state.isSuccess) {
      setShowImportResult(result);
      projectsStore.fetch();
    }
  }, [projectsStore]);

  return (
    <TablePageLayout
      buttons={(
        <>
          {canCreate && (
            <Button.Primary
              icon={ButtonIcons.Add}
              isLoading={projectsStore.state.isLoading}
              label="Добавить проект"
              onClick={toggleCreate}
            />
          )}
          {canDelete && (
            <Button.Primary
              icon={isArchive ? ButtonIcons.ArchiveOut : ButtonIcons.Delete}
              isDisabled={isDeleteDisabled}
              isLoading={projectsStore.state.isLoading}
              label={isArchive ? 'Вернуть выбранные' : 'Удалить выбранные'}
              onClick={deleteRestoreHandler}
              type={isArchive ? 'main' : 'danger'}
            />
          )}
          <Dropdown buttons={
            [{
              id: 'archive',
              isLoading: projectsStore.state.isLoading,
              label: isArchive ? 'Вернуться в реестр' : 'Архив',
              onClick: () => {
                runInAction(() => {
                  projectsStore.filter.isDeleted = !isArchive;
                  projectsStore.filter.pageNumber = 1;
                });
                navigate(isArchive ? '' : archiveHash);
              },
            },
            canImport ? {
              isDisabled: projectsStore.state.isLoading,
              id: 'import',
              label: 'Импорт',
              onClick: () => chooseFile(importProjectsHandler),
            } : null,
            ]
          }
          />
        </>
      )}
      title={`Проекты ${isArchive ? '- Архив' : ''}`}
    >
      <Table
        onRowClick={canRead ? onRowClickHandler : undefined}
        reorderSessionKey="projectsTable"
        rowHeight={30}
        store={projectsStore}
      >
        {canDelete ? (
          <Column<ProjectsTableItem, GetProjectsResponseItem>
            key="checkbox"
            dataType="custom"
            header=""
            template={RowCheckboxForDeleteProjects}
            width={50}
            pinned
          />
        ) : null}
        <Column
          header="Название проекта"
          sortExpr="name"
          pinned
        >
          <Column<ProjectsTableItem, GetProjectsResponseItem>
            dataType="string"
            header={projectNameHeader}
            keyExpr="name"
            minWidth={250}
          />
        </Column>
        <Column<ProjectsTableItem, GetProjectsResponseItem>
          dataType="custom"
          header="Статус"
          minWidth={100}
          sortExpr="status"
          template={statusCell}
        />
        <Column<ProjectsTableItem, GetProjectsResponseItem>
          dataType="date"
          header="Дата начала"
          keyExpr="startDate"
          minWidth={150}
          sortExpr="startDate"
        />
        <Column<ProjectsTableItem, GetProjectsResponseItem>
          dataType="date"
          header="Дата конца"
          keyExpr="finishDate"
          minWidth={150}
          sortExpr="finishDate"
        />
        <Column<ProjectsTableItem, GetProjectsResponseItem>
          dataType="custom"
          header="Контрагент"
          sortExpr="contragentName"
          template={LinkToContragentCell}
        />
        <Column<ProjectsTableItem, GetProjectsResponseItem>
          dataType="string"
          header="Комментарий"
          keyExpr="comment"
          minWidth={350}
          sortExpr="comment"
        />
        { isArchive ? (
          <Column<ProjectsTableItem, GetProjectsResponseItem>
            dataType="date"
            header="Дата архивации"
            keyExpr="deletedOn"
            sortExpr="deletedOn"
            width={140}
          />
        ) : null}
      </Table>
      {showCreateModal && (<ProjectCreateForm onClose={toggleCreate} />)}
      <Errors store={projectsStore} />
      {importResult && (
        <ImportResultModal
          entityName="проектов"
          onClose={() => setShowImportResult(undefined)}
          result={importResult}
        />
      )}
    </TablePageLayout>
  );
};

export default observer(Projects);
