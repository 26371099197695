import React, {
  useRef,
  useLayoutEffect,
  useState,
  forwardRef,
  useMemo,
  useImperativeHandle,
} from 'react';
import { createPortal } from 'react-dom';
import { useEffectOnce } from 'usehooks-ts';
import { getFocusableElements } from '@services/utils';
import PopupContainer from './PopupContainer';
import type { PopupProps } from './types';

const Popup = forwardRef<HTMLDivElement, PopupProps>(({
  children, targetRef, className, sameWidth, position = 'bottom', forceClose, closeWhenScroll = true, catchFocus = false,
}, ref) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [tooltipRect, setTooltipRect] = useState<DOMRect | undefined>();
  // eslint-disable-next-line react/prop-types
  const parentBoundingRect = targetRef.getBoundingClientRect();

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => contentRef.current);

  useEffectOnce(() => {
    if (!catchFocus) {
      return () => {};
    }
    const prevElement = (document.activeElement as HTMLElement);
    const allFocusable = getFocusableElements(document);
    const prevElementTabIndex = allFocusable.findIndex((e) => e === prevElement);
    const nextElement = allFocusable[prevElementTabIndex + 1] ?? document.body;
    contentRef.current?.focus();
    /** Невидимый элемент перед всеми focusable элементами popup для отлова фокуса на нем */
    const focusableFirstElement = document.createElement('button');
    focusableFirstElement.style.position = 'absolute';
    /** Невидимый элемент после всех focusable элементов popup для отлова фокуса на нем */
    const focusableLastElement = document.createElement('button');
    focusableLastElement.style.position = 'absolute';

    const blurCB = (e: FocusEvent) => {
      if (!contentRef.current?.contains(e.relatedTarget as HTMLElement)) {
        (prevElement as HTMLElement)?.focus?.();
        forceClose?.();
      }
    };

    const focusNext = () => {
      (nextElement as HTMLElement).focus?.();
      contentRef.current?.removeEventListener('blur', blurCB);
      forceClose?.();
    };

    /**
     * Фокус на первом невидимом элементе срабатывает когда пользователь либо только сфокусировался
     * на попапе и хочет сфокусироваться на первом видимом элементе
     * либо то что он хочет выйти из попапа и сфокусировать на предыдущем эелементе вне попапа
     */
    const focusPrev = (e: FocusEvent) => {
      const allContentFocusableElements = getFocusableElements(contentRef.current);
      /** При фокусе на первый невидимый элемент попапа перекидываем фокус на первый видимый элемент попапа */
      if (e.target === allContentFocusableElements[0] && e.relatedTarget !== allContentFocusableElements[1]) {
        allContentFocusableElements[1].focus();
      } else {
        /** Но если фокус на первый невидимый элемент пришел из первого видимого элемента, тогда кидаем фокус на предыдущий элемент вне попапа */
        (prevElement as HTMLElement).focus?.();
        contentRef.current?.removeEventListener('blur', blurCB);
        forceClose?.();
      }
    };

    /** Невидимый элемент для отлова фокуса на нем */
    focusableFirstElement.addEventListener('focus', focusPrev);
    focusableLastElement.addEventListener('focus', focusNext);

    contentRef.current?.appendChild(focusableLastElement);
    contentRef.current?.prepend(focusableFirstElement);
    contentRef.current?.addEventListener('blur', blurCB);

    return () => {
      contentRef.current?.removeEventListener('blur', blurCB);
      focusableLastElement.removeEventListener('focus', focusNext);
      focusableFirstElement.removeEventListener('focus', focusPrev);
    };
  });

  useEffectOnce(() => {
    if (!catchFocus) {
      return () => {};
    }
    const interactiveElements = getFocusableElements(contentRef.current);
    const nextPrevFocus = (e: KeyboardEvent) => {
      const activeElementIndex = interactiveElements.findIndex((el) => el === document.activeElement);
      switch (e.key) {
        case 'ArrowDown': {
          if (activeElementIndex === interactiveElements.length - 2) {
            (interactiveElements[0]?.focus());
            break;
          }
          (interactiveElements[activeElementIndex + 1]?.focus());
          break;
        }
        case 'ArrowUp': {
          if (activeElementIndex === 0) {
            (interactiveElements[interactiveElements.length - 2]?.focus());
            break;
          }
          if (activeElementIndex === interactiveElements.length - 1) {
            break;
          }
          (interactiveElements[activeElementIndex - 1]?.focus());
          break;
        }
        case 'Escape':
          forceClose?.();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', nextPrevFocus);
    return () => window.removeEventListener('keydown', nextPrevFocus);
  });

  useEffectOnce(() => {
    const canCloseWhenScroll = closeWhenScroll && forceClose;
    if (canCloseWhenScroll) {
      document.addEventListener('wheel', forceClose);
    }
    return () => {
      if (canCloseWhenScroll) {
        document.removeEventListener('wheel', forceClose);
      }
    };
  });

  useLayoutEffect(() => {
    setTooltipRect(contentRef.current?.getBoundingClientRect());
  }, [targetRef, children]);

  const fitX = useMemo(
    () => {
      // x - если вставить слева
      const leftX = parentBoundingRect.left - (tooltipRect?.width ?? 0);
      // x - если вставить справа
      const rightX = parentBoundingRect.right;
      switch (position) {
        case 'left': {
          if (leftX >= 0) {
            return leftX;
          }
          if (rightX + (tooltipRect?.width ?? 0) <= document.body.clientWidth) {
            return rightX;
          }
          return 0;
        }
        case 'right': {
          if (rightX + (tooltipRect?.width ?? 0) <= document.body.clientWidth) {
            return rightX;
          }
          if (leftX >= 0) {
            return leftX;
          }
          return document.body.clientWidth - (tooltipRect?.width ?? 0);
        }
        case 'bottom-left': {
          return parentBoundingRect.left;
        }
        case 'bottom-right': {
          return parentBoundingRect.left + (parentBoundingRect.width - (tooltipRect?.width ?? 0));
        }
        default: {
          const x = parentBoundingRect.left + (parentBoundingRect.width - (tooltipRect?.width ?? 0)) / 2;
          if (x < 0) {
            return 0;
          }
          if (x + (tooltipRect?.width ?? 0) > document.body.clientWidth) {
            return document.body.clientWidth - (tooltipRect?.width ?? 0);
          }
          return x;
        }
      }
    },
    [parentBoundingRect.left, parentBoundingRect.right, parentBoundingRect.width, position, tooltipRect],
  );

  const fitY = useMemo(
    () => {
      // y - если вставить сверху
      const topY = parentBoundingRect.top - (tooltipRect?.height ?? 0);
      // y - если вставить снизу
      const bottomY = parentBoundingRect.bottom;
      const y = parentBoundingRect.top + (parentBoundingRect.height - (tooltipRect?.height ?? 0)) / 2;
      switch (position) {
        case 'top': {
          if (topY >= 0) {
            return topY;
          }
          if (bottomY + (tooltipRect?.height ?? 0) <= document.body.clientHeight) {
            return bottomY;
          }
          return 0;
        }
        case 'bottom-left':
        case 'bottom-right':
        case 'bottom': {
          if (bottomY + (tooltipRect?.height ?? 0) <= document.body.clientHeight) {
            return bottomY;
          }
          if (topY >= 0) {
            return topY;
          }
          return document.body.clientHeight - (tooltipRect?.height ?? 0);
        }
        case 'center': {
          return y;
        }
        default: {
          if (y < 0) {
            return 0;
          }
          if (y + (tooltipRect?.height ?? 0) > document.body.clientHeight) {
            return document.body.clientHeight - (tooltipRect?.height ?? 0);
          }
          return y;
        }
      }
    },
    [parentBoundingRect.bottom, parentBoundingRect.height, parentBoundingRect.top, position, tooltipRect?.height],
  );

  return createPortal(
    <PopupContainer
      ref={contentRef}
      className={className}
      width={sameWidth ? parentBoundingRect.width : undefined}
      x={fitX}
      y={fitY}
    >
      {children}
    </PopupContainer>,
    document.body,
  );
});

Popup.displayName = 'Popup';

export default Popup;
