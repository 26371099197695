import { parseStyles } from '@services/utils';
import dayjs from 'dayjs';
import React from 'react';
import Label from '@components/ui/Label/Label';

interface WeekDayProps {
  dayClick: (val: number) => void;
  selected: boolean;
  e: number;
}

interface WeekPickerProps {
  dayClick: (val: number) => void;
  selected: number[];
  label: string;
}

const WeekButton: React.FC<WeekDayProps> = ({
  dayClick, selected, e,
}) => {
  const getColor = (d: number, isSelected: boolean) => {
    if (d === 6 || d === 0) {
      return isSelected ? ['border-red-500', 'bg-red-400', 'text-white'] : ['border-red-500', 'bg-white', '!text-red-500'];
    }
    return isSelected ? ['border-btn-main-bg-hover', 'bg-main', 'text-white'] : ['border-btn-main-bg-hover', 'bg-white', 'text-btn-main-bg-hover'];
  };

  return (
    <button
      className={parseStyles`
        h-8 w-8 rounded-full text-btn-default text-white border-2 border-solid
        ${getColor(e, selected).join(' ')}
      `}
      onClick={() => dayClick(e)}
      type="button"
    >
      {dayjs().day(e).format('dd')}
    </button>
  );
};

const WeekPicker: React.FC<WeekPickerProps> = ({
  dayClick, selected, label,
}) => (
  <>
    <Label>{label}</Label>
    <div className="flex flex-1 border-gray-200 items-center justify-evenly rounded-main h-[37px] border-solid border-1">
      {Array.from({ length: 7 })
        .map((_, index) => (index + 1) % 7)
        .map((e) => (
          <WeekButton
            key={e}
            dayClick={dayClick}
            e={e}
            selected={selected.includes(e)}
          />
        ))}
    </div>
  </>
);

export default WeekPicker;
