import { paramsSerializer } from '@services/utils';
import { CareerMeetingService } from '@servicesHr';
import type { ListBaseFilter } from '@types';
import type { GetCareerMeetingsResponseItem } from '@typesHr';
import BaseEntityListStore from '../BaseEntityListStore';
import { CareerMeetingsItemViewModel } from './CareerMeetingsItemViewModel';

interface CareerMeetingsFilter extends ListBaseFilter{
  /** Время встречи, с */
  meetingDateFrom?: string;
  /** Время встречи, по */
  meetingDateTo?: string;
  /** Результаты */
  results?: string;
  /** Признак удалённости */
  isDeleted?: boolean;
  /** Сотрудник */
  employeeId?: string;
  /** Идентификаторы приглашённых сотрудников */
  invitedEmployeesIds?: string[];
}

/** Стор сущности */
export class CareerMeetingsStore extends BaseEntityListStore<GetCareerMeetingsResponseItem, CareerMeetingsFilter, CareerMeetingsItemViewModel> {
  constructor() {
    super(20);
  }

  /** Получение списка */
  public async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await CareerMeetingService.list(this.filter, {
        paramsSerializer,
      });
      this.setData(data as {
        entities: GetCareerMeetingsResponseItem[];
        totalCount: number;
      }, CareerMeetingsItemViewModel);
    });
  }
}
