import React from 'react';
import NavigateBack from '@components/ui/NavigateBack/NavigateBack';

interface Props {
  header: string;
  route?: string;
  canGoBack?: boolean;
}

/** лайаут страницы с табличкой */
const EntityHeader: React.FC<Props> = ({ header, route, canGoBack = true }) => (
  <div className="flex min-h-header flex-shrink-0">
    {canGoBack && <NavigateBack route={route} />}
    <span className="text-page-header my-auto">{header}</span>
  </div>
);

export default EntityHeader;
