import React, { useCallback, useEffect, useState } from 'react';
import { useConfirm, useStores, useTitle } from '@hooks';
import { observer } from 'mobx-react-lite';
import type { EmployeesTableItem } from '@types';
import { computed, runInAction } from 'mobx';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import type { FileImportResponse, GetEmployeesResponseItem } from '@typesApi';
import { CreateEmployeeModal, InputHeader, Modal } from '@components';
import Errors from '@components/ui/Errors/Errors';
import { Column, Table } from '@components/ui/Table';
import TablePageLayout from '@components/TablePageLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@components/ui/Button';
import RowCheckboxForArchiveResources from '@components/ui/Table/RowCheckboxForArchiveResources';
import { chooseFile, archiveHash } from '@services/utils';
import ImportResultModal from '@components/ImportResultModal/ImportResultModal';
import Dropdown from '@components/ui/Dropdown/Dropdown';
import ButtonIcons from '@components/ui/Button/ButtonIcons';

const Employees: React.FC = () => {
  useTitle('Сотрудники');
  const [showCreateModal, toggleCreateModal] = useToggle();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { employeesStore } = useStores();
  /** Привилегии */
  const { canCreate, canDelete, canImport } = employeesStore;
  const confirmDelete = useConfirm('Архивировать выбранных сотрудников?');
  const confirmRestore = useConfirm('Восстановить выбранных сотрудников?');
  const [importResult, setShowImportResult] = useState<FileImportResponse>();

  const [isArchive, setIsArchive] = useState(false);
  const [formatError, setFormatError] = useState<string | null>(null);

  useEffectOnce(() => {
    runInAction(() => {
      employeesStore.filter.orderBy = 'fullName';
      employeesStore.filter.isAscending = true;
    });
    return () => {
      employeesStore.clearStore();
    };
  });

  useEffect(() => {
    if (hash?.includes(archiveHash)) {
      setIsArchive(true);
      runInAction(() => {
        employeesStore.filter.isDeleted = true;
      });
    } else {
      setIsArchive(false);
      runInAction(() => {
        employeesStore.filter.isDeleted = false;
      });
    }
    employeesStore.fetch();
  }, [hash, employeesStore, employeesStore.filter, setIsArchive]);

  /** Хедер колонки "ФИО" */
  const FIOHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="filterName"
      store={employeesStore}
    />
  ), [employeesStore]);

  /** Хээндлер архивирования и восстановления проектов */
  const deleteRestoreHandler = useCallback(async () => {
    if (!isArchive && await confirmDelete()) {
      employeesStore.deleteSelectedEmployees();
    }
    if (isArchive && await confirmRestore()) {
      employeesStore.restoreSelectedEmployees();
    }
  }, [confirmDelete, confirmRestore, employeesStore, isArchive]);

  const onRowClickHandler = useCallback((id: string) => {
    navigate(isArchive ? `${id}${archiveHash}` : `${id}`);
  }, [isArchive, navigate]);

  /** Заблокирована ли кнопка Архивировать/Восстановить */
  const isDeleteDisabled = computed(() => !employeesStore.viewModel.some((e) => e.selected)).get();

  /** Обработчик импорта сотрудников */
  const importEmployeesHandler = useCallback(async (file: File) => {
    const result = await employeesStore.importEmployees(file);
    if (employeesStore.state.isSuccess) {
      setShowImportResult(result);
      employeesStore.fetch();
    }
  }, [employeesStore]);

  return (
    <TablePageLayout
      buttons={(
        <>
          {canCreate && (
            <Button.Primary
              icon={ButtonIcons.AddUser}
              isLoading={employeesStore.state.isLoading}
              label="Добавить сотрудника"
              onClick={toggleCreateModal}
            />
          )}
          {canDelete && (
            <Button.Primary
              icon={isArchive ? ButtonIcons.ArchiveOut : ButtonIcons.Delete}
              isDisabled={isDeleteDisabled}
              isLoading={employeesStore.state.isLoading}
              label={isArchive ? 'Вернуть выбранные' : 'Удалить выбранные'}
              onClick={deleteRestoreHandler}
              type={isArchive ? 'main' : 'danger'}
            />
          )}
          <Dropdown
            buttons={[
              {
                isLoading: employeesStore.state.isLoading,
                label: isArchive ? 'Вернуться в реестр' : 'Архив',
                onClick: () => {
                  runInAction(() => {
                    employeesStore.filter.isDeleted = !isArchive;
                    employeesStore.filter.pageNumber = 1;
                  });
                  navigate(isArchive ? '' : archiveHash);
                },
                id: 'archive',
              },
              canImport ? {
                isDisabled: employeesStore.state.isLoading,
                onClick: () => chooseFile(importEmployeesHandler, ['text/csv'], () => {
                  setFormatError('Неверный формат файла');
                }),
                label: 'Импорт',
                id: 'import',
              } : null,
            ]}
          />
        </>
      )}
      title={`Сотрудники${isArchive ? ' - Архив' : ''}`}
    >
      <Table
        onRowClick={onRowClickHandler}
        reorderSessionKey="employeesTable"
        rowHeight={30}
        store={employeesStore}
      >
        {canDelete ? (
          <Column<EmployeesTableItem, GetEmployeesResponseItem>
            key="select"
            dataType="custom"
            header=""
            resizable={false}
            template={RowCheckboxForArchiveResources}
            width={50}
            pinned
          />
        ) : null}
        <Column
          header="ФИО"
          sortExpr="fullName"
          pinned
        >
          <Column<EmployeesTableItem, GetEmployeesResponseItem>
            key="fio"
            dataType="string"
            header={FIOHeader}
            keyExpr="fio"
            minWidth={250}
          />
        </Column>
        <Column<EmployeesTableItem, GetEmployeesResponseItem>
          dataType="string"
          header="Специализация"
          keyExpr="mainSpecializationName"
          width={200}
          sortable
        />
        <Column<EmployeesTableItem, GetEmployeesResponseItem>
          dataType="string"
          header="Должность"
          keyExpr="positionName"
          width={200}
          sortable
        />
        <Column<EmployeesTableItem, GetEmployeesResponseItem>
          dataType="string"
          header="Квалификация (грейд)"
          keyExpr="mainQualificationName"
          width={200}
          sortable
        />
        <Column<EmployeesTableItem, GetEmployeesResponseItem>
          dataType="string"
          header="Удаленная работа"
          keyExpr="isRemoteWorkText"
          sortExpr="isRemoteWork"
        />
        <Column<EmployeesTableItem, GetEmployeesResponseItem>
          dataType="string"
          header="Электронная почта"
          keyExpr="email"
          width={200}
          sortable
        />
        <Column<EmployeesTableItem, GetEmployeesResponseItem>
          dataType="string"
          header="Телефон"
          keyExpr="phone"
          width={185}
          sortable
        />
        {isArchive ? (
          <Column<EmployeesTableItem, GetEmployeesResponseItem>
            dataType="date"
            header="Дата архивации"
            keyExpr="deletedOn"
            sortExpr="deletedOn"
            width={140}
          />
        ) : null}
      </Table>
      {importResult && (
        <ImportResultModal
          entityName="Сотрудников"
          onClose={() => setShowImportResult(undefined)}
          result={importResult}
        />
      )}
      <Errors store={employeesStore} />
      {showCreateModal && (<CreateEmployeeModal onClose={toggleCreateModal} />)}
      {formatError && (
        <Modal
          className="bg-red-300 max-w-[80vw]"
          onClose={() => {
            setFormatError(null);
          }}
        >
          {formatError}
        </Modal>
      )}
    </TablePageLayout>
  );
};

export default observer(Employees);
