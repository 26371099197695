import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks';
import { FormInput } from '@components/ui';
import Modal from '@components/Modal/Modal';
import Button from '@components/ui/Button';
import type { CreateEmployeeModalProp } from './types';

const EmployeeCreateModal: React.FC<CreateEmployeeModalProp> = ({ onClose, onChangeNewUser }) => {
  const { employeeStore, accountsStore } = useStores();
  const { viewModel } = employeeStore;

  const createEmployeeHandler = async () => {
    if (viewModel.validate()) {
      const id = await employeeStore.addEmployee();
      if (id) {
        await employeeStore.fetch(id);
        onChangeNewUser(employeeStore.viewModel.id ?? '', employeeStore.viewModel.fullName);
        await accountsStore.fetch();
        onClose();
      }
    }
  };

  return (
    <Modal
      className="w-[500px] flex flex-col gap-form"
      onClose={onClose}
    >
      <div className="flex flex-col gap-form">
        <div className="font-bold text-center text-xl">Создание нового сотрудника</div>
        <FormInput
          error={viewModel.errors.name}
          label="Имя"
          onChange={(event) => {
            viewModel.name = event.target.value;
          }}
          value={viewModel?.name}
        />
        <FormInput
          error={viewModel.errors.surname}
          label="Фамилия"
          onChange={(event) => {
            viewModel.surname = event.target.value;
          }}
          value={viewModel?.surname}
        />
        <FormInput
          label="Отчество"
          onChange={(event) => {
            viewModel.patronymic = event.target.value;
          }}
          value={viewModel?.patronymic}
        />
        <FormInput
          error={viewModel.errors.email}
          label="Электронная почта"
          onChange={(event) => {
            viewModel.email = event.target.value;
          }}
          value={viewModel?.email}
        />
        <FormInput
          error={viewModel.errors.phone}
          label="Телефон"
          mask="+7(___)-___-__-__"
          onChange={(event) => {
            viewModel.phone = event.target.value;
          }}
          value={viewModel?.phone}
        />
      </div>
      <Button.Primary
        className="self-end"
        isLoading={employeeStore.state.isLoading}
        label="Создать"
        onClick={createEmployeeHandler}
      />
    </Modal>
  );
};

export default observer(EmployeeCreateModal);
