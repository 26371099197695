const borderWidth = 1;
const contextMenuIconWidth = 25;

module.exports = {
  theme: {
    extend: {
      borderWidth: {
        'graph-border': `${borderWidth}px`,
        'graph-border-double': `${borderWidth * 2}px`,
      },
      strokeWidth: ({ theme }) => ({
        'graph-border': theme('borderWidth.graph-border'),
        'graph-border-double': theme('borderWidth.graph-border-double'),
      }),
      colors: {
        'graph-header': '#F5F4F1',
        'graph-border': '#E5E5E5',
        'graph-header-unavailable-day-bg': '#fce6e6',
        'graph-weekend-bg': '#f5f9fc',
        'graph-hovered-cell-bg': '#888',
        'graph-hovered-row-bg': 'lightgray',
        'graph-overtime-pale': '#fce6e6',
        'graph-not-overtime-pale': '#e6fce6',
        'graph-overtime': '#f7b3b3',
        'graph-not-overtime': '#b3f7b3',
        'graph-overtime-hover': '#E30000',
        'graph-not-overtime-hover': '#00E300',
        'graph-project-underline': '#ffcc80',
      },
      width: {
        'graph-context-menu': `${contextMenuIconWidth}px`,
      },
    },
    contextMenuIconWidth,
  },
};
