import { RolesTable, ProjectRolesTable } from '@components';
import TablePageLayout from '@components/TablePageLayout';
import { useStores, useTitle } from '@hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';

/** Страница Администрирования */
const Administration: React.FC = () => {
  useTitle('Администрирование');

  const { rolesStore, projectRolesStore } = useStores();

  return (
    <TablePageLayout
      title="Администрирование"
    >
      <div className="flex flex-col gap-form">
        {rolesStore.canRead && <RolesTable />}
        {projectRolesStore.canRead && <ProjectRolesTable />}
      </div>
    </TablePageLayout>
  );
};

export default observer(Administration);
