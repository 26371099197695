/** Информация о роли для модалки роли */
export interface RoleModalInfo {
  /** Идентификатор потребности */
  roleId?: string;
  /** Наименование роли */
  name?: string;
  /** Описание роли */
  description?: string;
  /** id организации */
  organizationId?: string;
}

/** Пропсы для модалки роли */
export interface RoleModalProps {
  /** Информация о роли */
  roleInfo?: RoleModalInfo;
  /** Закрытие модального окна */
  onClose: () => void;
}

/** Пропсы для модалки проектной роли */
export interface ProjectRoleModalProps {
  /** Информация о проектной роли */
  roleInfo?: RoleModalInfo;
  /** Закрытие модального окна */
  onClose: () => void;
}

/** Пропсы для кнопок таблицы */
export interface TableButtonsProps {
  /** Наименование сущности */
  name: string;
  /** Добавление роли */
  onAddClick?: () => void;
  /** Удаление выбранных ролей */
  onDeleteClick?: () => void;
  /** Заблокирована ли кнопка Удалить */
  isDeleteDisabled?: boolean;
}

/** Виды уведомлений */
export enum NotificationEvents {
  StartProject = 'Начало проекта',
  UpdateProject = 'Обновление проекта',
  FinishProject = 'Окончание проекта',
  DeleteProject = 'Удаление проекта',
  UpdateResource = 'Обновление ресурса',
  DeleteResource = 'Удаление ресурса',
  StartLoadResourceInProject = 'Начало планирования ресурса на проекте',
  UnavailableResource = 'Недоступность ресурса',
  FinishLoadResourceInProject = 'Окончание планирования ресурса на проекте',
  StartWorkWeek = 'Начало рабочей недели',
}
