import type { RefObject } from 'react';
import React, {
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import type { CalendarProps } from '../Calendar/Calendar';
import Calendar from '../Calendar/Calendar';
import Data from './Data';
import Grid from '../Grid';
import { AnalyticsTableContext } from '../AnalyticsTableContext';
import graphConfig from '../graph.config';

export type GridWithDataProps = {
  rows: number;
  columns: number;
  columnWidth: number;
  rowHeight: number;
  calendarProps: CalendarProps;
  gridHeight?: number;
  listRef: RefObject<HTMLDivElement>;
  verticalContainerRef: RefObject<HTMLDivElement>;
  horizontalContainerRef: RefObject<HTMLDivElement>;
  firstDayDayOfWeek: number;
};
export const GridWithData: React.FC<GridWithDataProps> = ({
  rows,
  columns,
  columnWidth,
  rowHeight,
  calendarProps,
  gridHeight,
  listRef,
  verticalContainerRef,
  horizontalContainerRef,
  firstDayDayOfWeek,
}) => {
  const ganttSVGRef = useRef<SVGSVGElement>(null);

  const { setBoundingRect } = useContext(AnalyticsTableContext);

  const updateBoundingRect = useCallback(() => {
    const rect = horizontalContainerRef.current?.getBoundingClientRect();
    if (rect) {
      rect.width = verticalContainerRef.current?.getBoundingClientRect().width ?? rect.width;
    }
    setBoundingRect(rect ?? null);
  }, [horizontalContainerRef, setBoundingRect, verticalContainerRef]);

  useEffect(() => {
    updateBoundingRect();
    const resizeObserver = new ResizeObserver(updateBoundingRect);
    if (verticalContainerRef.current) {
      resizeObserver.observe(verticalContainerRef.current!);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [horizontalContainerRef, updateBoundingRect, verticalContainerRef]);

  return (
    <div
      ref={verticalContainerRef}
      className="overflow-hidden  m-0 p-0 border-r-graph-border"
    >
      <svg
        height={calendarProps.headerHeight}
        width={`calc(${columnWidth * columns}px - ${graphConfig.theme.extend.borderWidth['graph-border']}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Calendar {...calendarProps} />
      </svg>
      <div
        ref={horizontalContainerRef}
        className="m-0 p-0 overflow-hidden"
        style={
          gridHeight
            ? { height: gridHeight, width: `calc(${columnWidth * columns}px - ${graphConfig.theme.extend.borderWidth['graph-border']}` }
            : { width: `calc(${columnWidth * columns}px - ${graphConfig.theme.extend.borderWidth['graph-border']}` }
        }
      >
        <svg
          ref={ganttSVGRef}
          className="bg-white"
          height={rowHeight * rows}
          width={columnWidth * columns}
          xmlns="http://www.w3.org/2000/svg"
        >
          <Grid
            columnWidth={columnWidth}
            columns={columns}
            firstDayDayOfWeek={firstDayDayOfWeek}
            rowHeight={rowHeight}
            rows={rows}
          />
          <Data
            ganttSVGRef={ganttSVGRef}
            listRef={listRef}
          />
        </svg>
      </div>
    </div>
  );
};
