module.exports = {
  theme: {
    extend: {
      colors: {
        'btn-main-bg': '#E97224',
        'btn-main-bg-hover': '#F78439',
        'btn-main-bg-active': '#FF7922',
        'btn-disabled-bg': '#B1ADAB',
        'btn-danger-bg': '#dc3545',
        'btn-danger-bg-hover': '#c82333',
        'btn-danger-bg-active': '#bd2130',
        'btn-accent': '#bd5a1a',

        'btn-main': 'white',

        /** Стили для Button.Outlined */
        'btn-outline-active': '#F7A46E',
        'btn-outline': '#F3853C',

        /** Стили для Button.Primary с type="outlined" */
        'btn-outline-hover-border': '#F3853C',
        'btn-outline-hover-bg': '#FFE0CB',
        'btn-outline-active-bg': '#FFB98A',

      },
      fontSize: {
        'btn-default': ['14px', {
          lineHeight: '14px',
          fontWeight: '600',
        }],
      },
      height: {
        'btn-default': '30px',
      },
      padding: {
        'btn-default-x': '16px',
        'btn-with-icon': '10px',
        'btn-default-y': '4px',
      },
    },
  },
};
