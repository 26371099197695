import React from 'react';
import { observer } from 'mobx-react-lite';
import type { AccountsItemViewModel } from 'src/stores/Accounts/AccountsItemViewModel';
import InfoModal from '@components/ui/InfoModal/InfoModal';

/** Стандартная кнопка */
const AccountStatusChangeErrors: React.FC<{
  viewModels: AccountsItemViewModel[];
}> = ({
  viewModels,
}) => {
  const firstErrorInViewModels = viewModels.find((vm) => vm.state?.isError);

  return (
    <>
      {firstErrorInViewModels?.state?.isError && (
        <InfoModal
          header="Произошла ошибка"
          onClose={() => firstErrorInViewModels.clearError()}
          text={firstErrorInViewModels?.state?.error}
        />
      )}
    </>
  );
};

export default observer(AccountStatusChangeErrors);
