import { observer } from 'mobx-react-lite';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ProjectsListHeader from './ProjectsListHeader';
import ProjectsListTable from './ProjectsListTable';

export type ProjectsListProps = {
  headerHeight: number;
  rowWidth: string;
  gridHeight: number;
  projectListRef: React.RefObject<HTMLDivElement>;
  horizontalContainerRef: React.RefObject<HTMLDivElement>;
  horizontalContainerClass?: string;
};

const ProjectsList = forwardRef<HTMLDivElement, ProjectsListProps>(({
  headerHeight,
  rowWidth,
  gridHeight,
  projectListRef,
  horizontalContainerRef,
  horizontalContainerClass,
}, ref) => {
  const listRef = useRef<HTMLDivElement>(null);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => listRef.current);
  return (
    <div ref={projectListRef}>
      <ProjectsListHeader
        headerHeight={headerHeight}
        rowWidth={rowWidth}
      />
      <div
        ref={horizontalContainerRef}
        className={horizontalContainerClass}
        style={gridHeight ? { height: gridHeight } : {}}
      >
        <ProjectsListTable
          ref={listRef}
          rowWidth={rowWidth}
        />
      </div>
    </div>
  );
});

ProjectsList.displayName = 'ProjectsList';

export default observer(ProjectsList);
