import { ProjectService, ResourceService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetResourcesInProjectItem } from '@typesApi';
import { computed, makeObservable, observable } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import ResourcesInProjectViewModel from './ResourcesInProjectViewModel';

export type ResourceInProjectWithRequiredId = GetResourcesInProjectItem & {id: string}

class ResourcesInProjectStore extends BaseEntityListStore<GetResourcesInProjectItem,
ListBaseFilter & {
  orderBy: keyof GetResourcesInProjectItem;
}, ResourcesInProjectViewModel> {
  /** Идентификатор проекта */
  @observable projectId?: string;

  constructor() {
    super();
    makeObservable(this);
  }

  /** Получение нагрузки проекта */
  public async fetch(): Promise<void> {
    if (this.projectId) {
      await this.runWithStateControl(async () => {
        const { data } = await ProjectService.resourcesInProjectDetail(this.projectId!, this.filter);
        this.setData(data as {
          entities: GetResourcesInProjectItem[];
          totalCount: number;
        }, ResourcesInProjectViewModel);
      });
    }
  }

  /** Выбранные ресурсы */
  @computed get selectedResources() {
    return this.viewModel.filter((resource) => resource.selected);
  }

  public async deleteResourceFromProject(resourceInProjectId: string) {
    await this.runWithStateControl(async () => {
      await ResourceService.resourceInProjectDelete(resourceInProjectId);
    });
  }
}

export default ResourcesInProjectStore;
