import { AccountService } from '@servicesApi';
import { runInAction } from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import type { IInviteStore } from './InviteViewModel';
import InviteViewModel from './InviteViewModel';

class InviteStore extends EntityBaseStore<IInviteStore, InviteViewModel> {
  public viewModel = new InviteViewModel({});

  public async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await AccountService.detail(id);
      this.viewModel = new InviteViewModel({
        ...data,
        employeeRoles: data.employeeRoles?.map((role) => ({ label: role.name ?? '', value: role.id ?? '' })),
      });
    });
  }

  public async save(): Promise<void> {
    await this.runWithStateControl(async () => {
      if (this.viewModel.id) {
        await Promise.all([
          AccountService.rolesUpdate(this.viewModel.id, {
            roleIds: this.viewModel.employeeRoles?.map((role) => role.value),
          }),
          AccountService.stateUpdate(this.viewModel.id, { isActive: this.viewModel.isActive }),
        ]);
      }
    });
  }

  /** Получить токен приглашения */
  public async getInviteToken() {
    this.runWithStateControl(async () => {
      if (this.viewModel.newAccountEmployee) {
        const { data } = await AccountService.inviteTokenCreate({
          employeeId: this.viewModel.newAccountEmployee.employeeId,
          roleId: this.viewModel.newEmployeeRole?.roleId,
        });
        runInAction(() => {
          this.viewModel.inviteToken = data;
        });
      }
    });
  }
}

export default InviteStore;
