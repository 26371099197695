import {
EventEmployeeTypes,
GetPositionSettingByIdResponse,
GetPositionSettingsResponse,
PostPositionSettingRequest,
PostPositionSettingResponse,
ProblemDetails,
ProblemDetailsResponse,
PutPositionSettingRequest} from "./types.hr.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class PositionSettingService {
  static get RoutePath(): string {
    return "PositionSetting";
  }

  /**
   * Создание настройки профессии
   *
   * @request POST:/hr/v1.0/PositionSetting
   */
  static create = async (data: PostPositionSettingRequest, params: RequestParams = {}) =>
    httpClient.request<PostPositionSettingResponse, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение списка настроек профессий
   *
   * @request GET:/hr/v1.0/PositionSetting
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      positionSettings?: string[];
      positions?: string[];
      eventEmployeeTypes?: EventEmployeeTypes[];
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetPositionSettingsResponse, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Обновление настройки профессии
   *
   * @request PUT:/hr/v1.0/PositionSetting/{id}
   */
  static update = async (id: string, data: PutPositionSettingRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удаление настройки профессии по ид
   *
   * @request DELETE:/hr/v1.0/PositionSetting/{id}
   */
  static delete = async (id: string, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      ...params,
    });

  /**
   * Получение настройки профессии по ид
   *
   * @request GET:/hr/v1.0/PositionSetting/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetPositionSettingByIdResponse, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
}