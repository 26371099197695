import { validationRequired } from '@services/validationFunctions';
import type {
  GetProjectRequirementByIdResponse,
  GetProjectRequirementEmployeeByIdResponse,
  GetProjectRequirementEquipmentByIdResponse,
  GetProjectRequirementPlaceByIdResponse,
  ResourceTypes,
} from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, runInAction } from 'mobx';
import { ErrorMessages } from 'src/enums';
import BaseViewModel from '../BaseViewModel';

export type RequirementsResponse = GetProjectRequirementByIdResponse
| GetProjectRequirementEmployeeByIdResponse
| GetProjectRequirementEquipmentByIdResponse
| GetProjectRequirementPlaceByIdResponse

class ProjectRequirementBaseViewModel<T extends RequirementsResponse> extends BaseViewModel<T> {
  /** ID потребности */
  @computed get id() {
    return this.data.id;
  }

  /** Наименование потребности */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменить наименование потребности */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
      this.validateField('name', validationRequired);
    });
  }

  /** Тип ресурса */
  @computed get resourceType() {
    return this.data.resourceType;
  }

  /** Изменить тип ресурса */
  set resourceType(value: ResourceTypes | undefined) {
    runInAction(() => {
      this.data.resourceType = value;
      this.validateField('resourceType', validationRequired);
    });
  }

  /** ID проекта */
  @computed get projectId() {
    return this.data.projectId;
  }

  /** Изменить ID проекта */
  set projectId(value: string | undefined) {
    runInAction(() => {
      this.data.projectId = value;
    });
  }

  /** Наименование проекта */
  @computed get projectName() {
    return this.data.projectName ?? '';
  }

  /** Изменить наименование проекта */
  set projectName(value: string | undefined) {
    runInAction(() => {
      this.data.projectName = value;
      this.validateField(
        'projectName',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : undefined;
  }

  set startDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
      this.validateField(
        'startDate',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : undefined;
  }

  set finishDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
      this.validateField(
        'finishDate',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get totalLoad() {
    return this.data.totalLoad;
  }

  set totalLoad(value: number | undefined | null) {
    runInAction(() => {
      this.data.totalLoad = value;
      this.validateField(
        'totalLoad',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get description() {
    return this.data.description ?? '';
  }

  set description(value: string | undefined) {
    runInAction(() => {
      this.data.description = value;
    });
  }

  @computed get catalogValues() {
    return this.data.catalogValues ?? [];
  }

  set catalogValues(value: string[] | undefined) {
    runInAction(() => {
      this.data.catalogValues = value;
    });
  }
}

export default ProjectRequirementBaseViewModel;
