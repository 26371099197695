import type { SelectOption } from '@components/ui/Select';
import { joinNonEmpty } from '@services/utils';
import { dateIntersection, min, required } from '@services/validationFunctions';
import type { GetPlaceByIdResponse, GetPlaceByIdResponseProject } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, runInAction } from 'mobx';
import { ErrorMessages } from 'src/enums';
import BaseViewModel from '../BaseViewModel';

class PlaceViewModel extends BaseViewModel<GetPlaceByIdResponse> {
  /** Получение id */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Изменение id */
  set id(value: string | undefined) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** Получение name */
  @required()
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение name */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение appointment */
  @computed get appointment() {
    return this.data.appointment;
  }

  /** Изменение appointment */
  set appointment(value: string | undefined | null) {
    runInAction(() => {
      this.data.appointment = value;
    });
  }

  /** Получение address */
  @required()
  @computed get address() {
    return this.data.address ?? '';
  }

  /** Изменение address */
  set address(value: string | undefined) {
    runInAction(() => {
      this.data.address = value;
    });
  }

  /** Получение totalCapacity */
  @min(0)
  @computed get totalCapacity() {
    return this.data.totalCapacity ?? undefined;
  }

  /** Изменение totalCapacity */
  set totalCapacity(value: number | undefined) {
    runInAction(() => {
      this.data.totalCapacity = value;
    });
  }

  /** Получение numberOfSeats */
  @min(0)
  @computed get numberOfSeats() {
    return this.data.numberOfSeats ?? undefined;
  }

  /** Изменение numberOfSeats */
  set numberOfSeats(value: number | undefined) {
    runInAction(() => {
      this.data.numberOfSeats = value;
    });
  }

  /** Получение square */
  @computed get square() {
    return this.data.square;
  }

  /** Изменение square */
  set square(value: number | undefined | null) {
    runInAction(() => {
      this.data.square = value;
    });
  }

  /** Получение responsibleId */
  @computed get responsibleId() {
    return this.data.responsibleId;
  }

  /** Изменение responsibleId */
  set responsibleId(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleId = value;
    });
  }

  /** Получение responsibleSurname */
  @computed get responsibleSurname() {
    return this.data.responsibleSurname;
  }

  /** Изменение responsibleSurname */
  set responsibleSurname(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleSurname = value;
    });
  }

  /** Получение responsibleName */
  @computed get responsibleName() {
    return this.data.responsibleName;
  }

  /** Изменение responsibleName */
  set responsibleName(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleName = value;
    });
  }

  /** Получение responsiblePatronymic */
  @computed get responsiblePatronymic() {
    return this.data.responsiblePatronymic;
  }

  /** Изменение responsiblePatronymic */
  set responsiblePatronymic(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsiblePatronymic = value;
    });
  }

  /** Получение responsibleFullName */
  @computed get responsibleFullName() {
    return joinNonEmpty([this.data.responsibleSurname, this.data.responsibleName, this.data.responsiblePatronymic], ' ');
  }

  @computed get responsible(): SelectOption | undefined {
    return this.responsibleId ? {
      label: this.responsibleFullName,
      value: this.responsibleId,
    } : undefined;
  }

  set responsible(val: SelectOption | undefined) {
    this.responsiblePatronymic = undefined;
    this.responsibleSurname = undefined;
    this.responsibleName = val?.label;
    this.responsibleId = val?.value;
  }

  /** Получение lastCleaning */
  @dateIntersection('lastCleaning', 'nextCleaning', ErrorMessages.cleaningDates)
  @computed get lastCleaning() {
    return this.data.lastCleaning ? dayjs(this.data.lastCleaning) : undefined;
  }

  /** Изменение lastCleaning */
  set lastCleaning(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.lastCleaning = value?.toISOString();
    });
  }

  /** Получение nextCleaning */
  @dateIntersection('lastCleaning', 'nextCleaning', ErrorMessages.cleaningDates)
  @computed get nextCleaning() {
    return this.data.nextCleaning ? dayjs(this.data.nextCleaning) : undefined;
  }

  /** Изменение nextCleaning */
  set nextCleaning(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.nextCleaning = value?.toISOString();
    });
  }

  /** Получение projects */
  @computed get projects() {
    return this.data.projects;
  }

  /** Изменение projects */
  set projects(value: GetPlaceByIdResponseProject[] | undefined | null) {
    runInAction(() => {
      this.data.projects = value;
    });
  }

  @computed get deletedOn() {
    return this.data.deletedOn;
  }

  /** Получение флага Архивная сущность */
  @computed get isDeleted() {
    return this.data.isDeleted ?? false;
  }
}

export default PlaceViewModel;
