import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useToggle } from 'usehooks-ts';
import { parseStyles } from '@services/utils';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import FormInput from './FormInput';
import type { FormInputsProps } from './types';
import type { BaseInputProps } from './BaseInput/types';

const passwordInputPostfixStyles = 'cursor-pointer rounded-full p-[2px] hover:bg-slate-200 duration-300 ease-in hover:ease-out';

/** Инпут только для паролей */
const PasswordFormInput: React.FC<FormInputsProps & BaseInputProps> = (props) => {
  /** Переключатель отображения пароля */
  const [showPasswordToggle, setShowPasswordToggle] = useToggle();

  /** Значок показать/скрыть текст */
  const passwordInputPostfix = useMemo(() => (
    <div className={parseStyles`pr-2 self-center text-label`}>
      {showPasswordToggle ? (
        <VscEyeClosed
          className={passwordInputPostfixStyles}
          onClick={setShowPasswordToggle}
          size={24}
        />
      ) : (
        <VscEye
          className={passwordInputPostfixStyles}
          onClick={setShowPasswordToggle}
          size={24}
        />
      )}
    </div>
  ), [setShowPasswordToggle, showPasswordToggle]);

  return (
    <FormInput
      {...props}
      className="[&>input]:mr--8 [&>input]:pr-8"
      postfix={passwordInputPostfix}
      type={showPasswordToggle ? 'text' : 'password'}
    />
  );
};

export default observer(PasswordFormInput);
