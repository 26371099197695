import type { SelectOption } from '@components/ui/Select';
import { joinNonEmpty } from '@services/utils';
import { validationRequired } from '@services/validationFunctions';
import type { GetAccountByIdResponse, PostInviteTokenResponse } from '@typesApi';
import { computed, runInAction } from 'mobx';
import BaseViewModel from '../BaseViewModel';

export interface IInviteStore extends Omit<GetAccountByIdResponse, 'employeeRoles'> {
  newEmployeeRole?: {
    roleId: string;
    roleName: string;
  };
  newAccountEmployee?: {
    employeeId: string;
    employeeName: string;
  };
  inviteToken?: PostInviteTokenResponse;
  employeeRoles?: SelectOption[];
}

class InviteViewModel extends BaseViewModel<IInviteStore> {
  @computed get id() {
    return this.data.employeeId;
  }

  /** Получить Токен приглашения пользователя */
  @computed get inviteToken() {
    return this.data.inviteToken;
  }

  /** Изменить Токен приглашения пользователя */
  public set inviteToken(token: PostInviteTokenResponse | undefined) {
    this.data.inviteToken = token;
  }

  /** Роль нового пользователя */
  @computed get newEmployeeRole() {
    return this.data.newEmployeeRole;
  }

  /** Изменить Роль нового пользователя */
  public set newEmployeeRole(role: {roleId: string; roleName: string} | undefined) {
    runInAction(() => {
      this.data.newEmployeeRole = role;
    });
  }

  /** Новый пользователь */
  @computed get newAccountEmployee() {
    return this.data.newAccountEmployee;
  }

  /** Изменить нового пользователя */
  public set newAccountEmployee(employee: { employeeId: string; employeeName: string} | undefined) {
    runInAction(() => {
      this.data.newAccountEmployee = employee;
      this.validateField('newAccountEmployee', validationRequired);
    });
  }

  /** Получение name */
  @computed get name() {
    return joinNonEmpty([this.data.surname, this.data.name, this.data.patronymic]);
  }

  /** Получение email */
  @computed get email() {
    return this.data.email;
  }

  /** Получение телефона */
  @computed get phone() {
    return this.data.phone;
  }

  /** Получение состоянии активности */
  public set isActive(isActive: boolean) {
    runInAction(() => {
      this.data.isActive = isActive;
    });
  }

  /** Активен ли аккаунт */
  @computed get isActive() {
    return this.data.isActive ?? false;
  }

  /** Получение employeeId */
  @computed get employeeId() {
    return this.data.employeeId;
  }

  /** Получение employeeRoles */
  @computed get employeeRoles() {
    return this.data.employeeRoles;
  }

  /** Изменение employeeRoles */
  set employeeRoles(value: SelectOption[] | undefined) {
    runInAction(() => {
      this.data.employeeRoles = value;
    });
  }
}

export default InviteViewModel;
