import { parseStyles } from '@services/utils';
import './CheckBoxStyles.css';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import BaseInput from '../Input/BaseInput/BaseInput';
import type { CheckBoxProps } from './CheckBoxTypes';

/** CheckBox */
const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(({
  id,
  className,
  label,
  checked,
  isDisabled,
  useLeftLabel = false,
  onChange,
}, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => innerRef.current,
  );

  useLayoutEffect(() => {
    if (innerRef.current) {
      // eslint-disable-next-line eqeqeq
      innerRef.current.indeterminate = checked == undefined;
    }
  }, [checked]);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event?.target.checked),
    [onChange],
  );

  return (
    <div className={parseStyles`
        base-checkbox
        ${className}
      `}
    >
      <label
        className={parseStyles`
          flex flex-nowrap items-center 
          border-none rounded-main p-[5px] [&>div]:flex-grow-0 [&>div>input]:px-0 accent-main
          ${useLeftLabel && 'flex-row-reverse'}
          ${isDisabled ? 'cursor-default' : 'cursor-pointer'}
        `}
        htmlFor={id}
      >
        <BaseInput
          ref={innerRef}
          backgroundColor=""
          checked={checked}
          disabled={isDisabled}
          height=""
          id={id}
          onChange={onChangeHandler}
          onClick={(e) => e.stopPropagation()}
          onEnter={() => onChange?.(innerRef.current?.checked)}
          type="checkBox"
        />
        {!!label && (<span>{label}</span>)}
      </label>
    </div>
  );
});

CheckBox.displayName = 'CheckBox';

export default React.memo(CheckBox);
