import type { GetLoadResourceInProjectsResponseItemUnavailableResource } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, runInAction } from 'mobx';
import { required } from '@services/validationFunctions';
import BaseViewModel from '../BaseViewModel';

export class UnavailablePeriodViewModel extends BaseViewModel<GetLoadResourceInProjectsResponseItemUnavailableResource> {
  /** Получить 'Ид недоступности ресурса' */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получить 'Причина' */
  @computed
  @required()
  get reasonId() {
    return this.data.reasonId;
  }

  /** Изменить 'Причина' */
  set reasonId(value: string | undefined | null) {
    runInAction(() => {
      this.data.reasonId = value;
    });
  }

  /** Получить 'Наименование причины' */
  @computed get reasonName() {
    return this.data.reasonName;
  }

  /** Изменить 'Наименование причины' */
  set reasonName(value: string | null | undefined) {
    runInAction(() => {
      this.data.reasonName = value;
    });
  }

  /** Получить 'Дата начала периода' */
  @computed
  @required()
  get startDate() {
    return this.data.startDate ? dayjs.utc(this.data.startDate) : undefined;
  }

  /** Изменить 'Дата начала периода' */
  set startDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
    });
  }

  /** Получить 'Дата окончания периода' */
  @computed
  @required()
  get finishDate() {
    return this.data.finishDate ? dayjs.utc(this.data.finishDate) : undefined;
  }

  /** Изменить 'Дата окончания периода' */
  set finishDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
    });
  }
}
