import type { ProjectsTableItem } from '@types';
import type { GetProjectsResponseItem, GetProjectsResponseResourcesInProjectItem, ProjectStatuses } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import BaseViewModel from '../BaseViewModel';

/** Модель проекта */
class ProjectsItemViewModel extends BaseViewModel<GetProjectsResponseItem> implements ProjectsTableItem {
  constructor(data: GetProjectsResponseItem) {
    super(data);
    makeObservable(this);
  }

  @observable
    selected?: boolean = false;

  /** Получение id */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получение name */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение name */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение startDate */
  @computed get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : null;
  }

  /** Изменение startDate */
  set startDate(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
    });
  }

  /** Получение finishDate */
  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : null;
  }

  /** Изменение finishDate */
  set finishDate(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
    });
  }

  /** Получение contragentId */
  @computed get contragentId() {
    return this.data.contragentId ?? '';
  }

  /** Изменение contragentId */
  set contragentId(value: string | undefined) {
    runInAction(() => {
      this.data.contragentId = value;
    });
  }

  /** Получение contragentName */
  @computed get contragentName() {
    return this.data.contragentName ?? '';
  }

  /** Изменение contragentName */
  set contragentName(value: string | undefined) {
    runInAction(() => {
      this.data.contragentName = value;
    });
  }

  /** Получение sourcesInProjectCount */
  @computed get sourcesInProjectCount() {
    return this.data.sourcesInProjectCount;
  }

  /** Изменение sourcesInProjectCount */
  set sourcesInProjectCount(value: number | undefined | null) {
    runInAction(() => {
      this.data.sourcesInProjectCount = value;
    });
  }

  /** Получение hourlyPlan */
  @computed get hourlyPlan() {
    return this.data.hourlyPlan;
  }

  /** Изменение hourlyPlan */
  set hourlyPlan(value: string | undefined | null) {
    runInAction(() => {
      this.data.hourlyPlan = value;
    });
  }

  /** Получение costPlan */
  @computed get costPlan() {
    return this.data.costPlan;
  }

  /** Изменение costPlan */
  set costPlan(value: string | undefined | null) {
    runInAction(() => {
      this.data.costPlan = value;
    });
  }

  @computed get status() {
    return this.data.status;
  }

  set status(value: ProjectStatuses | undefined) {
    this.data.status = value;
  }

  /** Получение comment */
  @computed get comment() {
    return this.data.comment ?? '';
  }

  /** Изменение comment */
  set comment(value: string | undefined) {
    runInAction(() => {
      this.data.comment = value;
    });
  }

  /** Получение deletedOn */
  @computed get deletedOn() {
    return this.data.deletedOn ? dayjs(this.data.deletedOn) : null;
  }

  /** Изменение deletedOn */
  set deletedOn(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.deletedOn = value?.toISOString();
    });
  }

  /** Получение resourcesInProject */
  @computed get resourcesInProject() {
    return this.data.resourcesInProject;
  }

  /** Изменение resourcesInProject */
  set resourcesInProject(value: GetProjectsResponseResourcesInProjectItem[] | undefined | null) {
    runInAction(() => {
      this.data.resourcesInProject = value;
    });
  }
}

export default ProjectsItemViewModel;
