import { dateIntersection, required } from '@services/validationFunctions';
import type { GetProjectByIdResponse, ProjectStatuses } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, makeObservable, runInAction } from 'mobx';
import type { SelectOption } from '@components/ui/Select';
import { joinNonEmpty } from '@services/utils';
import { RussianProjectStatuses } from 'src/enums';
import BaseViewModel from '../BaseViewModel';

class ProjectViewModel extends BaseViewModel<GetProjectByIdResponse> {
  constructor(data: GetProjectByIdResponse) {
    super(data);
    makeObservable(this);
  }

  /** Получение id */
  @computed get id() {
    return this.data.id ?? undefined;
  }

  /** Изменение id */
  set id(value: string | undefined) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** Получение name */
  @required()
  @computed get name() {
    return this.data.name;
  }

  /** Изменение name */
  set name(value: string | undefined | null) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение startDate */
  @dateIntersection('startDate', 'finishDate')
  @computed get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : undefined;
  }

  /** Изменение startDate */
  set startDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
    });
  }

  /** Получение finishDate */
  @dateIntersection('startDate', 'finishDate')
  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : undefined;
  }

  /** Изменение finishDate */
  set finishDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
    });
  }

  /** Получение contragentId */
  @computed get contragentId() {
    return this.data.contragentId;
  }

  /** Изменение contragentId */
  set contragentId(value: string | undefined | null) {
    runInAction(() => {
      this.data.contragentId = value;
    });
  }

  @computed get status() {
    return this.data.status;
  }

  set status(value: ProjectStatuses | undefined) {
    this.data.status = value;
  }

  @computed get russianStatusForSelect() {
    return this.status ? ({ label: RussianProjectStatuses[this.status], value: this.status }) : undefined;
  }

  /** Получение contragentName */
  @computed get contragentName() {
    return this.data.contragentName;
  }

  /** Изменение contragentName */
  set contragentName(value: string | undefined | null) {
    runInAction(() => {
      this.data.contragentName = value;
    });
  }

  /** Выбранный контрагент */
  @computed get selectedСontragent(): SelectOption | undefined {
    return this.contragentName && this.contragentId ? ({ label: this.contragentName, value: this.contragentId }) : undefined;
  }

  /** Получение hourlyPlan */
  @computed get hourlyPlan() {
    return this.data.hourlyPlan;
  }

  /** Изменение hourlyPlan */
  set hourlyPlan(value: string | undefined | null) {
    runInAction(() => {
      this.data.hourlyPlan = value;
    });
  }

  /** Получение costPlan */
  @computed get costPlan() {
    return this.data.costPlan;
  }

  /** Изменение costPlan */
  set costPlan(value: string | undefined | null) {
    runInAction(() => {
      this.data.costPlan = value;
    });
  }

  /** Получение comment */
  @computed get comment() {
    return this.data.comment;
  }

  /** Изменение comment */
  set comment(value: string | undefined | null) {
    runInAction(() => {
      this.data.comment = value;
    });
  }

  /** Получение deletedOn */
  @computed get deletedOn() {
    return this.data.deletedOn ? dayjs(this.data.deletedOn) : undefined;
  }

  /** Изменение deletedOn */
  set deletedOn(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.deletedOn = value?.toISOString();
    });
  }

  /** Получение флага Архивная сущность */
  @computed get isDeleted() {
    return this.data.isDeleted ?? false;
  }

  /** Получение responsibleId */
  @computed get responsibleId() {
    return this.data.responsibleId;
  }

  /** Изменение responsibleId */
  set responsibleId(value: string | undefined| null) {
    runInAction(() => {
      this.data.responsibleId = value;
    });
  }

  /** Получение responsibleSurname */
  @computed get responsibleSurname() {
    return this.data.responsibleSurname;
  }

  /** Изменение responsibleSurname */
  set responsibleSurname(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleSurname = value;
    });
  }

  /** Получение responsibleName */
  @computed get responsibleName() {
    return this.data.responsibleName;
  }

  /** Изменение responsibleName */
  set responsibleName(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleName = value;
    });
  }

  /** Получение responsiblePatronymic */
  @computed get responsiblePatronymic() {
    return this.data.responsiblePatronymic;
  }

  /** Изменение responsiblePatronymic */
  set responsiblePatronymic(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsiblePatronymic = value;
    });
  }

  @computed get responsible(): SelectOption | undefined {
    return this.responsibleId ? {
      label: joinNonEmpty([this.responsibleSurname, this.responsibleName, this.responsiblePatronymic]),
      value: this.responsibleId,
    } : undefined;
  }

  set responsible(val: SelectOption | undefined) {
    this.responsibleName = val?.label;
    this.responsibleId = val?.value;
    this.responsiblePatronymic = undefined;
    this.responsibleSurname = undefined;
  }
}

export default ProjectViewModel;
