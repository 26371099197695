import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { PeriodStore } from 'src/stores/ProjectsLoad/PeriodStore';
import { observer } from 'mobx-react-lite';
import Modal from '@components/Modal/Modal';
import Button from '@components/ui/Button';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import FormGrid from '@components/ui/Form/FormGrid';

interface DeletePeriodModalProps {
  onClose: () => void;
  startDate?: Dayjs;
  finishDate?: Dayjs;
  onDelete: (startDate: Dayjs, finishDate: Dayjs) => void;
}

const DeletePeriodModal: React.FC<DeletePeriodModalProps> = ({
  onClose, startDate, finishDate, onDelete,
}) => {
  const store = useMemo(() => new PeriodStore({
    startDate: startDate?.toISOString(),
    finishDate: finishDate?.toISOString(),
    workingDaysOfWeek: [],
    workingHoursPerDay: 1,
  }), [finishDate, startDate]);

  const { viewModel } = store;

  return (
    <Modal onClose={onClose}>
      <div className="min-w-[680px] max-w[90vw] min-h-[20vh] max-h-[90vh] flex flex-col">
        <FormGrid maxColumnsNumber={2}>
          <DatePicker
            error={viewModel.errors.startDate}
            label="Дата начала"
            onChange={(val) => {
              viewModel.startDate = val;
            }}
            value={viewModel.startDate}
            utc
          />
          <DatePicker
            error={viewModel.errors.finishDate}
            label="Дата окончания"
            onChange={(val) => {
              viewModel.finishDate = val;
            }}
            value={viewModel.finishDate}
            utc
          />
        </FormGrid>
        <div className="flex gap-5 justify-end mt-auto">
          <Button.Primary
            label="Отменить"
            onClick={() => onClose()}
            type="outline"
          />
          <Button.Primary
            label="Удалить"
            onClick={() => {
              if (viewModel.validate()) {
                onDelete(
                  dayjs(viewModel.startDate!).utc(false)!,
                  dayjs(viewModel.finishDate!).utc(false)!,
                );
              }
            }}
            type="danger"
          />
        </div>
      </div>
    </Modal>
  );
};

export default observer(DeletePeriodModal);
