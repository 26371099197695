import { Close } from '@assets';
import { parseStyles } from '@services/utils';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import type { DrawerProps } from './types';

/** Див портала */
const modalRoot = document.getElementById('modal');

/** Длительность анимации с мс */
const DrawerAnimationDuration = 400;

/** Компонент модалка */
const Drawer: React.FC<DrawerProps> = ({
  children, position = 'right', onClose, className, confirm,
}) => {
  /** Позиция drawer (слева/справа) */
  const translate = useMemo(() => (position === 'left' ? 'translate-x-[-100%]' : 'translate-x-full'), [position]);

  /** Открыта ли модалка (для анимации) */
  const [isOpen, setOpen] = useState(false);

  /** Запуск анимации открытия модалки */
  const openModal = () => {
    setTimeout(() => setOpen(true), 0);
  };

  /** Запуск анимации закрытия модалки */
  const closeModal = useCallback(async () => {
    if (isOpen && (await confirm?.() ?? true) && onClose) {
      setOpen(false);
      setTimeout(() => {
        onClose();
      }, DrawerAnimationDuration);
    }
  }, [confirm, isOpen, onClose]);

  /** Элемент drawer, который будет монтироваться */
  const element = useMemo(() => {
    /** Если контейнер уже есть, то возвращаем его же (чтобы не слетал фокус из инпутов) */
    const temp = document.createElement('div');

    return temp;
  }, []);

  useEffect(() => {
    /** Обработчик закрытия при клике вне окна модалки */
    element.onmousedown = (e) => {
      if (e.target === element) {
        closeModal();
      }
    };
  }, [closeModal, element]);

  /** Реф на окно внутри оверлея */
  const modalRef = useRef<HTMLDivElement>(null);

  /** Обработчик закрытия через Esc */
  const handler = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      const lastModal = document.getElementById('modal')?.lastChild;
      if (lastModal === element) {
        closeModal();
      }
    }
  }, [closeModal, element]);

  /** Монтирование модалки в DOM */
  useLayoutEffect(() => {
    element.className = 'fixed inset-0 flex bg-slate-400/30';
    modalRoot?.appendChild(element);
    openModal();
    return () => {
      modalRoot?.removeChild(element);
    };
  }, [element]);

  /** Добавление обработчика закрытия по нажатию на Esc */
  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [handler]);

  return ReactDOM.createPortal(
    <div
      ref={modalRef}
      className={parseStyles`
        w-full lg:w-[800px] md:w-[500px] sm:w-[400px]
        absolute bg-background h-full shadow-xl ease-in-out transition-all
        text-font-main
        ${isOpen ? 'translate-x-0' : translate}
        ${position === 'left' ? 'left-0' : 'right-0'}
        ${className}
      `}
      style={{ transitionDuration: `${DrawerAnimationDuration}ms` }}
    >
      <div className="relative w-full flex flex-col overflow-y-auto h-full">
        <Close
          className="hover:opacity-70 cursor-pointer fixed right-[25px] top-[25px] z-50"
          onClick={closeModal}
        />
        {children}
      </div>
    </div>,
    element,
  );
};

export default Drawer;
