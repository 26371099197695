import { useConfirm, useStores } from '@hooks';
import type { ProjectRoleTableItem } from '@types';
import type { GetProjectRolesResponseItem } from '@typesApi';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { ProjectActionsNames } from 'src/enums';
import { useEffectOnce } from 'usehooks-ts';
import {
  Column,
  RowCheckbox,
  Table,
  TableButtons,
} from '@components/ui/Table';
import { Accordion, Panel } from '@components/ui';
import { computed, runInAction } from 'mobx';
import InputHeader from '@components/TableHeaders/InputHeader';
import ProjectRoleForm from '../Forms/ProjectRoleForm';
import type { RoleModalInfo } from '../types';

/** Таблица со списком проектных ролей */
const ProjectRolesTable: React.FC = () => {
  const { projectRolesStore } = useStores();
  const { canCreate, canDelete } = projectRolesStore;

  /** Хук для модалки подтверждения */
  const confirm = useConfirm('Удалить выбранные проектные роли?');

  /** Информация для модалки редактирования и добавления проектной роли */
  const [projectRoleModalInfo, setProjectRoleModalInfo] = useState<RoleModalInfo>();

  /** Обработчик удаления проектных ролей */
  const deleteProjectRolesHandler = useCallback(async () => {
    const confirmed = await confirm?.();
    if (confirmed) {
      await projectRolesStore.delete();
    }
  }, [confirm, projectRolesStore]);

  useEffectOnce(() => {
    runInAction(() => {
      projectRolesStore.filter.orderBy = 'name';
      projectRolesStore.filter.isAscending = true;
    });
    projectRolesStore.fetch();
    return () => {
      projectRolesStore.clearStore();
    };
  });

  /** Хендлер нажатия на строку */
  const onRowClickHandler = useCallback((id: string) => {
    const data = projectRolesStore.viewModel.find((x) => x.id === id);
    if (data) {
      setProjectRoleModalInfo({
        roleId: data.id,
        name: data.name ?? undefined,
        description: data.description ?? undefined,
      });
    }
  }, [projectRolesStore.viewModel]);

  /** Ключи привилегий проекта */
  const actionEnumNames = (Object.keys(ProjectActionsNames) as Array<keyof typeof ProjectActionsNames>);

  /** Заблокирована ли кнопка Удалить */
  const isDeleteDisabled = computed(() => !projectRolesStore.viewModel.some((p) => p.selected)).get();

  /** Хедер колонки "Название проектной роли" */
  const projectRoleNameHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="projectRoleName"
      store={projectRolesStore}
    />
  ), [projectRolesStore]);

  return (
    <Accordion
      openedIds={['1']}
    >
      <Panel
        header="Проектные роли и уведомления"
        headerButtons={(
          <TableButtons
            isDeleteDisabled={isDeleteDisabled}
            name=""
            onAddClick={canCreate
              ? () => setProjectRoleModalInfo({})
              : undefined}
            onDeleteClick={canDelete
              ? () => deleteProjectRolesHandler()
              : undefined}
          />
        )}
        id="1"
      >
        <Table
          onRowClick={onRowClickHandler}
          rowHeight={30}
          store={projectRolesStore}
        >
          {canDelete ? (
            <Column<ProjectRoleTableItem, GetProjectRolesResponseItem>
              key="select"
              dataType="custom"
              header=""
              template={RowCheckbox}
              width={50}
              pinned
            />
          ) : null}
          <Column
            header="Название"
            sortExpr="name"
            pinned
          >
            <Column<ProjectRoleTableItem, GetProjectRolesResponseItem>
              dataType="string"
              header={projectRoleNameHeader}
              keyExpr="name"
              minWidth={250}
            />
          </Column>
          <Column
            header="Привилегии"
          >
            {actionEnumNames.map((action) => (
              <Column<ProjectRoleTableItem, GetProjectRolesResponseItem>
                key={action}
                dataType="boolean"
                header={ProjectActionsNames[action]}
                keyExpr={action}
                readonly
                sortable
              />
            ))}
          </Column>
          <Column<ProjectRoleTableItem, GetProjectRolesResponseItem>
            dataType="string"
            header="Описание"
            keyExpr="description"
            width={500}
            sortable
          />
        </Table>
        {projectRoleModalInfo && (
          <ProjectRoleForm
            onClose={() => {
              setProjectRoleModalInfo(undefined);
            }}
            roleInfo={projectRoleModalInfo}
          />
        )}
      </Panel>
    </Accordion>
  );
};

export default observer(ProjectRolesTable);
