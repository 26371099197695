import { ResourceService } from '@servicesApi';
import type { ListBaseFilter, Period, UnavailablePeriod } from '@types';
import { ViewMode } from '@types';
import type {
  GetLoadResourceInProjectsResponseItem,
  GetLoadResourceInProjectsResponseItemLoadResourceInProject,
  GetResourceInProjectsResponseUnavailableResourceItem,
  GetUnavailableResourcesResponseItem,
} from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { seedDates } from '@components/CalendarTable/helpers';
import utc from 'dayjs/plugin/utc';
import { dayOfWeekEnumToNumber, paramsSerializer } from '@services/utils';
import type { DayData } from '@components/CalendarTable/types';
import { ResourceLoadViewModel } from './ResourceLoadViewModel';
import BaseEntityListStore from '../BaseEntityListStore';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

dayjs.extend(utc);

const startOfYear = dayjs.utc().startOf('year').utc();

function getDateIndex(date: Dayjs, startDate: Date, viewMode: ViewMode) {
  switch (viewMode) {
    case ViewMode.Day: return dayjs.utc(date).utc().diff(startDate, 'day');
    case ViewMode.Week: return dayjs.utc(date).utc().diff(startDate, 'week');
    case ViewMode.Month: return dayjs.utc(date).utc().diff(startDate, 'month');
    default: return dayjs.utc(date).utc().diff(startDate, 'day');
  }
}

interface Filter extends ListBaseFilter {
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  isAscending?: boolean;
  startDate?: string;
  finishDate?: string;
  isDeleted?: boolean;
}

export class ResourceLoadStore extends BaseEntityListStore<GetLoadResourceInProjectsResponseItem, Filter, ResourceLoadViewModel> {
  @observable privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super(10);
    makeObservable(this);
    this.privilegesStore = privilegesStore;
    reaction(() => ({ period: this.period, startDate: this.startDate, id: this.id }), () => {
      this.filter.pageNumber = 1;
      this.filter.finishDate = dayjs.utc(this.endDate).endOf('day').toISOString();
      this.filter.startDate = dayjs.utc(this.startDate).startOf('day').toISOString();
      this.fetch(true);
    });
  }

  /** id */
  @observable id?: string;

  /** пока работает на половину, в будущем юнит в клетке День/Неделя/Месяц */
  @observable viewMode: ViewMode = ViewMode.Day;

  @observable unavailablePeriods: GetUnavailableResourcesResponseItem[] = [];

  // #region Размеры и скролл таблички
  @observable scrollTop = 0;

  @observable scrollLeft = 0;

  @observable columnWidth = 25;

  @observable rowHeight = 25;

  @observable width = 0;

  @observable height = 0;
  // #endregion

  @observable startDate: Date = startOfYear.utc().toDate();

  @observable period: number = 3;

  @computed get firstRenderedColumn() {
    return Math.floor((this.scrollLeft - (this.width / 2)) / this.columnWidth);
  }

  @computed get lastRenderedColumn() {
    return Math.ceil((this.scrollLeft + (this.width * 1.5)) / this.columnWidth);
  }

  @computed get firstRenderedRow() {
    return Math.floor((this.scrollTop - (this.height / 2)) / this.rowHeight);
  }

  @computed get lastRenderedRow() {
    return Math.ceil((this.scrollTop + (this.height * 1.5)) / this.rowHeight);
  }

  @computed get endDate(): Date {
    return dayjs.utc(this.startDate).add(this.period, 'month').subtract(1, 'day').toDate();
  }

  @computed get unitForDayJS() {
    return this.viewMode.toLocaleLowerCase() as 'day' | 'week' | 'month' | 'year';
  }

  @computed get seededDates() {
    return seedDates(this.startDate, this.endDate, this.viewMode);
  }

  /** массив для всех ресурсов с нагрузкой по дням на проекте */
  @computed({ requiresReaction: true }) get rowsDayToLoadMap(): Map<number, {load: number; weekend: boolean}>[] {
    const startTime = performance.now();
    const res = this.viewModel
      .map((e) => {
        const map = new Map<number, {load: number; weekend: boolean}>();
        e.loadResourceInProjects
          ?.forEach((p) => {
            const periodStartIndex = getDateIndex(dayjs.utc(p.startDate!).utc(), this.startDate, this.viewMode);
            const periodEndIndex = getDateIndex(dayjs.utc(p.finishDate!).utc(), this.startDate, this.viewMode) + 1;
            for (let index = periodStartIndex; index < periodEndIndex; index++) {
              const workingDays = (p.workingDaysOfWeek ?? e.workingDaysOfWeek)!.map(dayOfWeekEnumToNumber);
              const weekend = !p.isWorkOnWeekends && !workingDays.includes(this.seededDates[index]?.getDay());
              map.set(index, {
                load: weekend ? 0 : p.workingHoursPerDay ?? 0,
                weekend,
              });
            }
          });
        return map;
      });
    const endTime = performance.now();
    console.log(`Call1 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** нагрузка всех ресурсов по дням среди всех проектов */
  @computed({ requiresReaction: true }) get projectsLoad(): Map<number, number> {
    const startTime = performance.now();
    const map = new Map<number, number>();
    this.viewModel.forEach((e) => {
      e.loadResourceInProjects?.forEach((period) => {
        const periodStartIndex = getDateIndex(dayjs.utc(period.startDate!).utc(), this.startDate, this.viewMode);
        const periodEndIndex = getDateIndex(dayjs.utc(period.finishDate!).utc(), this.startDate, this.viewMode) + 1;
        for (let index = periodStartIndex; index < periodEndIndex; index++) {
          const workingDays = (period.workingDaysOfWeek ?? e.workingDaysOfWeek)!.map(dayOfWeekEnumToNumber);
          const isWorkDay = period.isWorkOnWeekends || workingDays.includes(this.seededDates[index]?.getDay());
          const workingHoursThatDay = isWorkDay ? period.workingHoursPerDay! : 0;
          map.set(
            index,
            (map.get(index) ?? 0) + workingHoursThatDay,
          );
        }
      });
    });
    const endTime = performance.now();
    console.log(`Call2 took ${endTime - startTime} milliseconds`);
    return map;
  }

  /** массив данных для каждой строки */
  @computed({ requiresReaction: true }) get preparedProjects(): {
    projectName: string;
    resourceInProjectId: string;
    projectId: string;
    days: DayData[];
    periods: GetLoadResourceInProjectsResponseItemLoadResourceInProject[];
    rowIndex: number;
    canEditUnavailability: boolean;
  }[] {
    const startTime = performance.now();

    const res = this.rowsDayToLoadMap.map((e, i) => {
      const project = this.viewModel.at(i)!;

      const projectPeriods = project?.loadResourceInProjects ?? [];

      const canEditUnavailability = !!project && (this.privilegesStore.resources.canUpdate);

      return {
        projectName: project.name!,
        projectId: project.resourceInProjectId!,
        resourceInProjectId: project.resourceInProjectId!,
        periods: projectPeriods,
        days: Array.from(e, ([key, val]) => ({
          index: key,
          hours: val.weekend ? 0 : val.load,
          text: this.seededDates[key]?.toLocaleDateString(),
          total: this.projectsLoad.get(key) ?? 0,
          weekend: val.weekend,
        })),
        rowIndex: i,
        canEditUnavailability,
      };
    });
    const endTime = performance.now();
    console.log(`Call3 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** только не свёрнутые и видимые на странице данные */
  @computed get visibleResources() {
    const startTime = performance.now();

    const res = this.preparedProjects
      .slice(Math.max(0, this.firstRenderedRow), this.lastRenderedRow)
      .map((e) => ({
        ...e,
        days: e.days.filter((day) => {
          const columnFits = day.index > this.firstRenderedColumn && day.index < this.lastRenderedColumn;

          return columnFits;
        }),
        canEditWorkload: this.privilegesStore.projects.canUpdate
          || this.privilegesStore.getPrivilegesInProject(e!.projectId).UpdateResource
          || this.privilegesStore.responsibleInProjects.some((p) => p.id === e!.projectId),
      }));
    const endTime = performance.now();
    console.log(`Call5 took ${endTime - startTime} milliseconds`);
    return res;
  }

  @action async addResourceLoad(
    period: Period,
    y: number,
  ) {
    const project = this.preparedProjects.at(y)!;
    await ResourceService.resourceInProjectUpdate(project.resourceInProjectId, {
      ...period,
      startDate: period.startDate.toISOString(),
      finishDate: period.finishDate.toISOString(),
    });
    this.reload();
  }

  @action async addUnavailabilityPeriod(period: UnavailablePeriod) {
    await ResourceService.unavailableReasonUpdate(this.id!, {
      ...period,
      startDate: period.startDate.toISOString(),
      finishDate: period.finishDate.toISOString(),
    });
    this.reload();
  }

  @action async removeResourceLoad(startDate: Dayjs, finishDate: Dayjs, y: number) {
    const project = this.preparedProjects.at(y)!;
    await ResourceService.loadResourceInProjectDelete(project.resourceInProjectId!, {
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
    });
    this.reload();
  }

  @action async changePeriod(y: number, period: GetLoadResourceInProjectsResponseItemLoadResourceInProject, startDate: Dayjs, finishDate: Dayjs) {
    if (finishDate.isBefore(startDate)) {
      await this.removeResourceLoad(dayjs(period.startDate), dayjs(period.finishDate), y);
      return;
    }
    const project = this.preparedProjects.at(y)!;
    await ResourceService.resourceInProjectUpdate(project.resourceInProjectId!, {
      ...period,
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
      deleteFinishDate: period.finishDate,
      deleteStartDate: period.startDate,
    });
    this.reload();
  }

  /** Перезагрузка всего что уже отображается  */
  public async reload(): Promise<void> {
    if (!this.id) {
      return;
    }
    await this.runWithStateControl(async () => {
      const [unavailablePeriods, loads] = await Promise.all([
        ResourceService.unavailableResourcesList({
          startDate: this.filter.startDate,
          finishDate: this.filter.finishDate,
          resources: [this.id!],
          pageSize: 0,
        }, { paramsSerializer }),
        await ResourceService.loadResourceInProjectsDetail(
          this.id!,
          { ...this.filter, pageSize: this.getRawData().length || this.filter.pageSize, pageNumber: 1 },
          { paramsSerializer },
        ),
      ]);
      const allUnavailablePeriods = unavailablePeriods.data.entities?.length === unavailablePeriods.data.totalCount
        ? unavailablePeriods.data.entities ?? []
        : (await ResourceService.unavailableResourcesList(
          {
            startDate: this.filter.startDate,
            finishDate: this.filter.finishDate,
            resources: [this.id!],
            pageSize: unavailablePeriods.data.totalCount,
          },
          { paramsSerializer },
        )).data.entities ?? [];
      runInAction(() => {
        this.unavailablePeriods = allUnavailablePeriods;
      });
      const { data } = loads;
      this.setData(data, ResourceLoadViewModel);
    });
  }

  /** Загрузка потребностей по фильтру или новая страница  */
  public async fetch(first = false): Promise<void> {
    if (!this.id) {
      return;
    }
    await this.runWithStateControl(async () => {
      const [unavailablePeriods, loads] = await Promise.all([
        ResourceService.unavailableResourcesList({
          startDate: this.filter.startDate,
          finishDate: this.filter.finishDate,
          resources: [this.id!],
          pageSize: 0,
        }, {
          paramsSerializer,
        }),
        ResourceService.loadResourceInProjectsDetail(
          this.id!,
          this.filter,
        ),
      ]);
      const allUnavailablePeriods = unavailablePeriods.data.entities?.length === unavailablePeriods.data.totalCount
        ? unavailablePeriods.data.entities ?? []
        : (await ResourceService.unavailableResourcesList({
          startDate: this.filter.startDate,
          finishDate: this.filter.finishDate,
          resources: [this.id!],
          pageSize: unavailablePeriods.data.totalCount,
        }, {
          paramsSerializer,
        })).data.entities ?? [];
      runInAction(() => {
        this.unavailablePeriods = allUnavailablePeriods;
      });
      const { data } = loads;
      this.setData(first ? data : { entities: [...this.getRawData(), ...data.entities ?? []], totalCount: data.totalCount }, ResourceLoadViewModel);
    });
  }

  @action async changeUnavailablePeriod(
    y: number,
    period: GetResourceInProjectsResponseUnavailableResourceItem,
    startDate: Dayjs,
    finishDate: Dayjs,
  ) {
    if (finishDate.isBefore(startDate)) {
      await this.deleteUnavailabilityPeriod(y, period);
      return;
    }
    await ResourceService.unavailableReasonUpdate(this.id!, {
      ...period,
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
      deleteFinishDate: period.finishDate,
      deleteStartDate: period.startDate,
    });

    this.reload();
  }

  @action async deleteUnavailabilityPeriod(y: number, period: GetResourceInProjectsResponseUnavailableResourceItem) {
    await ResourceService.unavailableReasonDelete(this.id!, {
      startDate: period.startDate!,
      finishDate: period.finishDate!,
    });
    this.reload();
  }
}
