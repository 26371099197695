import type { RolePrivilegeTableItem, TableItemView } from '@types';
import { computed, makeObservable, runInAction } from 'mobx';
import BaseViewModel from 'src/stores/BaseViewModel';
import type { PrivilegesData } from './types';

/** Вью модель привилегий роли */
export class RolePrivilegesItemViewModel extends BaseViewModel<PrivilegesData>
  implements TableItemView<RolePrivilegeTableItem, PrivilegesData> {
  constructor(data: PrivilegesData) {
    super(data);
    makeObservable(this);
  }

  /** Фейковый идентификатор */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Наименование сущности */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Возможность просмотра */
  @computed get canRead() {
    return this.data.readPrivilege ? this.data.store?.privileges.includes(this.data.readPrivilege) : undefined;
  }

  /** Изменение возможности просмотра */
  set canRead(value: boolean | null | undefined) {
    runInAction(() => {
      if (value && this.data.readPrivilege) {
        this.data.store?.privileges?.push(this.data.readPrivilege);
      } else if (this.data.store) {
        this.data.store.privileges = this.data.store?.privileges?.filter((x) => x !== this.data.readPrivilege);
      }
    });
  }

  /** Возможность создания */
  @computed get canCreate() {
    return this.data.createPrivilege ? this.data.store?.privileges?.includes(this.data.createPrivilege) : undefined;
  }

  /** Изменение возможности создания */
  set canCreate(value: boolean | null | undefined) {
    runInAction(() => {
      if (value && this.data.createPrivilege) {
        this.data.store?.privileges?.push(this.data.createPrivilege);
      } else if (this.data.store) {
        this.data.store.privileges = this.data.store?.privileges?.filter((x) => x !== this.data.createPrivilege);
      }
    });
  }

  /** Возможность редактирования */
  @computed get canUpdate() {
    return this.data.updatePrivilege ? this.data.store?.privileges?.includes(this.data.updatePrivilege) : undefined;
  }

  /** Изменение возможности редактирования */
  set canUpdate(value: boolean | null | undefined) {
    runInAction(() => {
      if (value && this.data.updatePrivilege) {
        this.data.store?.privileges?.push(this.data.updatePrivilege);
      } else if (this.data.store) {
        this.data.store.privileges = this.data.store?.privileges?.filter((x) => x !== this.data.updatePrivilege);
      }
    });
  }

  /** Возможность удаления */
  @computed get canDelete() {
    return this.data.deletePrivilege ? this.data.store?.privileges?.includes(this.data.deletePrivilege) : undefined;
  }

  /** Изменение возможности удаления */
  set canDelete(value: boolean | null | undefined) {
    runInAction(() => {
      if (value && this.data.deletePrivilege) {
        this.data.store?.privileges?.push(this.data.deletePrivilege);
      } else if (this.data.store) {
        this.data.store.privileges = this.data.store?.privileges?.filter((x) => x !== this.data.deletePrivilege);
      }
    });
  }
}
