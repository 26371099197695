import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { parseStyles } from '@services/utils';
import type { BaseTextareaProps } from './BaseInput/types';
import Label from '../Label/Label';
import BaseTextarea from './BaseInput/BaseTextarea';
import type { FormInputsProps } from './types';

/** Многострочный инпут */
const FormTextarea = forwardRef<HTMLTextAreaElement, FormInputsProps & BaseTextareaProps>(({
  value,
  onChange,
  error,
  placeholder,
  className,
  disabled,
  label,
  onClick,
  invalid,
  maxRows = 7,
  minRows = 1,
  onHeightChange,
  ...props
}, ref) => {
  const innerRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
    ref,
    () => innerRef.current,
  );
  return (
    <div>
      <Label>{label}</Label>
      <BaseTextarea
        ref={innerRef}
        {...props}
        backgroundColor=""
        className={parseStyles`
        border-1
        ${disabled ? 'bg-i-bg-disabled text-i-disabled' : 'bg-white'}
        ${invalid || !!error ? '!text-error border-error' : 'border-gray-200 focus-within:border-i-border-active'}
        ${className}
      `}
        disabled={disabled}
        maxRows={maxRows}
        minRows={minRows}
        onChange={onChange}
        onClick={onClick}
        onHeightChange={onHeightChange}
        placeholder={placeholder}
        value={value}
        cacheMeasurements
      />
      {error && (
        <span className="text-error break-words text-xs text-left">
          {error}
        </span>
      )}
    </div>
  );
});

FormTextarea.displayName = 'FormTextarea';

export default React.memo(FormTextarea);
