import { parseStyles } from '@services/utils';
import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import Title from '../Title/Title';
import type { WithClassName } from '../types';

const Card: FunctionComponent<WithClassName & PropsWithChildren & {
  headerLabel?: string;
  headerButtons?: ReactNode;
}> = ({
  className,
  children,
  headerLabel,
  headerButtons,
}) => (
  <div className={parseStyles`
    bg-white border-accordion-border border-1
    flex-1 flex flex-col
    ${className}
  `}
  >
    {headerLabel && (
      <div className="px-6 py-4 flex items-center border-b-1 justify-between gap-2">
        <Title
          className="max-w-fit flex-1"
          isSubtitle
        >
          {headerLabel}
        </Title>
        <div className="flex gap-2 flex-1 min-w-0 [&>*:first-child]:ml-auto">{headerButtons}</div>
      </div>
    )}
    {children && <div className="px-6 py-4 pb-6 h-full">{children}</div>}
  </div>
);

export default Card;
