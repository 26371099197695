import { required } from '@services/validationFunctions';
import type { GetAccountResponse } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import BaseViewModel from '../BaseViewModel';
import { AccountRolesStore } from './AccountRolesStore';

/** Вью модель Личного кабинета */
export class ProfileViewModel extends BaseViewModel<GetAccountResponse> {
  @observable public confirmedEmail: string = '';

  private _employeeRoles: AccountRolesStore;

  constructor(data: GetAccountResponse) {
    super(data);
    this.confirmedEmail = data.email ?? '';
    this._employeeRoles = new AccountRolesStore(data.employeeRoles ?? []);
    makeObservable(this);
  }

  /** Получение фамилии*/
  @required()
  @computed get surname() {
    return this.data.surname ?? '';
  }

  /** Изменение фамилии */
  set surname(value: string | undefined) {
    runInAction(() => {
      this.data.surname = value;
    });
  }

  /** Получение имени*/
  @required()
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение имени */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение отчества*/
  @computed get patronymic() {
    return this.data.patronymic ?? '';
  }

  /** Изменение отчества */
  set patronymic(value: string | undefined) {
    runInAction(() => {
      this.data.patronymic = value;
    });
  }

  /** Получение электронной почты */
  @required('email')
  @computed get email() {
    return this.data.email ?? '';
  }

  /** Изменение электронной почты */
  set email(value: string | undefined) {
    runInAction(() => {
      this.data.email = value;
    });
  }

  /** Получение номера телефона */
  @required('phone')
  @computed get phone() {
    return this.data.phone ?? '';
  }

  /** Изменение номера телефона  */
  set phone(value: string | undefined) {
    runInAction(() => {
      this.data.phone = value;
    });
  }

  /** Получение ролей */
  @computed get roles() {
    return this.data.employeeRoles?.map((role) => role.name).join(', ') ?? '';
  }

  /** Получение идентификатора аватарки */
  @computed get accountPictureId() {
    return this.data.accountPictureId;
  }

  /** Изменение идентификатора аватарки  */
  set accountPictureId(value: string | undefined | null) {
    runInAction(() => {
      this.data.accountPictureId = value;
    });
  }

  /** Получение идентификатора сотрудника */
  @computed get employeeId() {
    return this.data.employeeId ?? '';
  }

  /** Получение полного имени */
  @computed get fullName() {
    return `${this.data.surname} ${this.data.name} ${this.data.patronymic}`;
  }

  /** Ссылка на аватарку */
  @computed get avatarLink() {
    return this.data.accountPictureId ? `${process.env.REACT_APP_API_URL}/api/v1.0/File/${this.data.accountPictureId}/Download` : undefined;
  }

  /** Получение ролей пользователя */
  @computed get employeeRoles() {
    return this._employeeRoles;
  }
}
