module.exports = {
  theme: {
    extend: {
      colors: {
        'table-header': '#F5F4F1',
        'table-header-text': '#616161',
        'even-row': '#FFFFFF',
        'even-row-text': '#000',
        'odd-row': '#FFFFFF',
        'odd-row-text': '#000',
        'table-border-color': '#e0e0e0',
        'pag-btn-color': '#eef2f3',
        'pag-btn-hover-color': 'rgba(255,255,255,0.3)',
      },
      borderWidth: {
        table: '1px',
      },
      boxShadow: {
        'pinned-cell': '6px 0px 12px 0px rgba(34, 60, 80, 0.2)',
      },
      width: {
        'pagination-select': '80px',
      },
      fontSize: {
        'pagination-text': '12px',
        'table-header-text': ['14px', {
          lineHeight: '18px',
          fontWeight: '700',
        }],
      },
      margin: {
        'header-x': '5px',
        'header-y': '2px',
      },
    },
  },
};
