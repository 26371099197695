import Modal from '@components/Modal/Modal';
import Popup from '@components/Popup/Popup';
import Button from '@components/ui/Button';
import { useConfirm, useStores } from '@hooks';
import { ResourceService } from '@servicesApi';
import type { Period, Project } from '@types';
import type { GetLoadResourceInProjectsResponseItemResourcesInProject } from '@typesApi';
import { observer } from 'mobx-react-lite';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineMinusSquare, AiOutlinePlus, AiOutlinePlusSquare } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import TitlePopup from '@components/ui/TitlePopup/TitlePopup';
import { isCurrentWidthLessThanFullWidth } from '@services/utils';
import { WorkingPeriodModal } from '../Modals';
import AddEmployeeModal from '../Modals/AddEmployeeModal';
import DeletePeriodModal from '../Modals/DeletePeriodModal';
import graphConfig from '../graph.config';

const ProjectsListTable = forwardRef<HTMLDivElement, {
  rowWidth: string;
}>(({ rowWidth }, ref) => {
  const removeFromProject = useConfirm('Удалить сотрудника и его нагрузку с проекта?');

  const { projectsLoadStore, privilegesStore } = useStores();
  const {
    rowHeight,
    projects,
  } = projectsLoadStore;

  const navigate = useNavigate();

  const listRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const [addEmployeeProject, setAddEmployeeProject] = useState<Project>();

  // TODO убрать магические числа
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => listRef.current);

  const [target, setTarget] = useState<{
    element: HTMLDivElement;
    project?: Project;
    resource?: GetLoadResourceInProjectsResponseItemResourcesInProject;
  } | null>(null);
  const [
    workPeriodModalData,
    setWorkPeriodModalData,
  ] = useState<Partial<Period> & {row: number} | undefined>();
  const [
    deletePeriodModalData,
    setDeletePeriodModalData,
  ] = useState<{row: number} | undefined>();

  useOnClickOutside(popupRef, () => {
    setTarget(null);
  });

  /** Элемент над которым будет попап */
  const [cellTarget, setCellTarget] = useState<HTMLDivElement>();

  /** Данные для попапа с подсказкой */
  const [popupData, setPopupData] = useState<{text: string; show: boolean} | null>(null);

  /** Показывать ли попап с подсказкой */
  const [showPopup, setShowPopup] = useState(false);

  /** Обработчик наведения на ячейку с текстом */
  const onCellHover = useCallback((name: string, element: HTMLDivElement) => {
    if (!isCurrentWidthLessThanFullWidth(element)) {
      return;
    }

    setShowPopup(true);
    setPopupData({ text: name, show: false });
    setCellTarget(element);
  }, []);

  /** Скрытие/показ попапа по таймеру */
  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setPopupData((prev) => {
          if (showPopup) {
            return { text: prev?.text || '', show: true };
          }
          return null;
        });
      },
      400,
    );

    return () => clearTimeout(timeout);
  }, [showPopup, popupData]);

  const calculatedTarget = useMemo(() => {
    if (!target) {
      return null;
    }
    const canViewResource = target.resource && privilegesStore.resources.canRead;
    const canViewProject = !target.resource && target.project && privilegesStore.projects.canRead;
    const canChangePeriod = target.project && target.resource && (privilegesStore.projects.canUpdate
      || privilegesStore.getPrivilegesInProject(target.project!.id).UpdateResource
      || privilegesStore.responsibleInProjects.some((p) => p.id === target.project!.id));

    const canDeletePeriod = target.project && target.resource && (privilegesStore.projects.canUpdate
      || privilegesStore.getPrivilegesInProject(target.project.id).UpdateResource
      || privilegesStore.responsibleInProjects.some((p) => p.id === target.project!.id));

    const canDeleteResource = target.project && target.resource && (privilegesStore.projects.canUpdate
      || privilegesStore.getPrivilegesInProject(target.project.id).UpdateResource
      || privilegesStore.responsibleInProjects.some((p) => p.id === target.project!.id));

    if (!canViewResource && !canViewProject && !canChangePeriod && !canDeletePeriod && !canDeleteResource) {
      return null;
    }

    return {
      ...target,
      canViewResource,
      canViewProject,
      canChangePeriod,
      canDeletePeriod,
      canDeleteResource,
    };
  }, [privilegesStore, target]);

  return (
    <>
      <div
        ref={listRef}
        className="table border-collapse bg-white w-full"
      >
        {projects.map((t, index) => (
          <React.Fragment key={t.id}>
            <div
              key={`${t.id}row`}
              className="table-row overflow-ellipsis"
              id={`row-${t.id}`}
              style={{ height: rowHeight }}
            >

              <div
                className="table-cell align-middle border-b-graph-border border-solid pr-2"
                style={{
                  minWidth: `calc(${rowWidth} - ${graphConfig.theme.contextMenuIconWidth}px)`,
                  maxWidth: `calc(${rowWidth} - ${graphConfig.theme.contextMenuIconWidth}px)`,
                }}
              >
                <div className="flex overflow-hidden pl-2">
                  <div className="text-sm font-bold mr-5">{index + 1}</div>
                  <div
                    className="flex flex-col justify-center mr-2 cursor-pointer"
                    onClick={() => {
                      projectsLoadStore.toggle(t.id);
                    }}
                  >
                    {t.hideChildren ? <AiOutlinePlusSquare size={14} /> : <AiOutlineMinusSquare size={14} />}
                  </div>
                  <div
                    className="text-sm font-bold truncate"
                    onMouseEnter={(e) => onCellHover(t.name, e.currentTarget)}
                    onMouseLeave={() => setShowPopup(false)}
                  >
                    {t.name}
                  </div>
                </div>
              </div>
              <div
                className="table-cell align-middle border-b-graph-border border-x-graph-border border-solid cursor-pointer w-graph-context-menu"
                onClick={(e) => {
                  setTarget({ element: e.currentTarget, project: t });
                }}
                title={t.name}
              >
                <AiOutlinePlus className="m-auto" />
              </div>
            </div>
            {!t.hideChildren && t.resourcesInProject.map((resource, resourceIndex) => (
              <div
                key={`${t.id}row${resource.id}`}
                className="table-row "
                id={`row-${resource.resourceInProjectId}`}
                style={{ height: rowHeight }}
              >
                <div
                  className="table-cell align-middle border-b-graph-border border-solid "
                  style={{
                    minWidth: `calc(${rowWidth} - 25px)`,
                    maxWidth: `calc(${rowWidth} - 25px)`,
                  }}
                >
                  <div className="flex pl-2">
                    <div className="text-sm font-bold mr-7">
                      {index + 1}
                      .
                      {resourceIndex + 1}
                    </div>
                    <div
                      className="text-sm font-semibold truncate pr-2"
                      onMouseEnter={(e) => onCellHover(resource.name ?? t.name, e.currentTarget)}
                      onMouseLeave={() => setShowPopup(false)}
                    >
                      {resource.name}
                    </div>
                  </div>
                </div>
                <div
                  className="table-cell align-middle border-b-graph-border border-x-graph-border border-solid  cursor-pointer"
                  onClick={(e) => {
                    setTarget({ element: e.currentTarget, resource, project: t });
                  }}
                  style={{
                    width: 25,
                  }}
                >
                  <AiOutlinePlus className="m-auto" />
                </div>
              </div>
            ))}
            <div
              key={`row-${t.id}-add`}
              className="table-row m-auto border-b-graph-border last:border-b-0 border-r-graph-border"
              id={`row-${t.id}-add`}
              style={{ height: rowHeight }}
            >
              <Button.Outline
                className="[&>div]:justify-center text-sm table-cell align-text-bottom"
                label="Добавить сотрудника"
                onClick={() => setAddEmployeeProject(t)}
              />
              <div className="table-cell" />
            </div>
          </React.Fragment>
        ))}
      </div>
      {calculatedTarget && (
        <Popup
          ref={popupRef}
          position="right"
          targetRef={calculatedTarget.element}
        >
          <div className="flex flex-col p-3 gap-2 bg-white rounded-md border-border border-1 shadow-xl">
            {calculatedTarget.resource && (
              <>
                {calculatedTarget.canViewResource && (
                  <Button.Primary
                    label="Перейти к карточке"
                    onClick={() => {
                      setTarget(null);
                      navigate(`/resources/employees/${calculatedTarget.resource?.id}`);
                    }}
                  />
                )}
                {calculatedTarget.canChangePeriod && (
                  <Button.Primary
                    label="Редактировать период"
                    onClick={() => {
                      setTarget(null);
                      const resource = projectsLoadStore.visibleResources.find(
                        (e) => e.resourceInProjectId === calculatedTarget.resource?.resourceInProjectId,
                      );
                      if (!resource) {
                        return;
                      }
                      setWorkPeriodModalData({
                        row: resource?.rowIndex,
                      });
                    }}
                  />
                )}
                {calculatedTarget.canDeletePeriod && (
                  <Button.Primary
                    label="Удалить период"
                    onClick={() => {
                      setTarget(null);
                      const resource = projectsLoadStore.visibleResources.find(
                        (e) => e.resourceInProjectId === calculatedTarget.resource?.resourceInProjectId,
                      );
                      if (!resource) {
                        return;
                      }
                      setDeletePeriodModalData({
                        row: resource?.rowIndex,
                      });
                    }}
                    type="danger"
                  />
                )}
                {calculatedTarget.canDeleteResource && (
                  <Button.Primary
                    label="Удалить с проекта"
                    onClick={async () => {
                      setTarget(null);
                      if (await removeFromProject()) {
                        projectsLoadStore.deleteResource(calculatedTarget.resource?.resourceInProjectId);
                      }
                    }}
                    type="danger"
                  />
                )}
              </>
            )}
            {calculatedTarget.canViewProject && (
              <Button.Primary
                label="Перейти к карточке"
                onClick={() => {
                  setTarget(null);
                  navigate(`/projects/${calculatedTarget.project?.id}`);
                }}
              />
            )}

          </div>
        </Popup>
      )}
      {workPeriodModalData && (
        <WorkingPeriodModal
          {...workPeriodModalData}
          onClose={(e) => {
            if (e) {
              projectsLoadStore.addResourceLoad(
                e,
                workPeriodModalData.row,
              );
            }
            setWorkPeriodModalData(undefined);
          }}
        />
      )}
      {deletePeriodModalData && (
        <DeletePeriodModal
          {...deletePeriodModalData}
          onClose={() => {
            setDeletePeriodModalData(undefined);
          }}
          onDelete={(startDate, finishDate) => {
            projectsLoadStore.removeResourceLoad(
              startDate,
              finishDate,
              deletePeriodModalData.row,
            );
            setDeletePeriodModalData(undefined);
          }}
        />
      )}
      {addEmployeeProject && (
        <Modal
          onClose={() => setAddEmployeeProject(undefined)}
        >
          <AddEmployeeModal
            alreadySelected={addEmployeeProject.resourcesInProject.map((e) => e.id!)}
            onClose={async (employeeId) => {
              if (employeeId) {
                await ResourceService.projectUpdate(employeeId!, addEmployeeProject.id, {});
                projectsLoadStore.reload();
              }
              setAddEmployeeProject(undefined);
            }}
          />
        </Modal>
      )}
      {(cellTarget && popupData?.show && showPopup) && (
        <TitlePopup
          element={cellTarget}
          maxWidth={`calc(${rowWidth} - ${graphConfig.theme.contextMenuIconWidth}px)`}
          setShowPopup={(value) => setShowPopup(value)}
          text={popupData.text}
        />
      )}
    </>
  );
});
ProjectsListTable.displayName = 'ProjectsListTable';

export default observer(ProjectsListTable);
