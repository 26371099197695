import { Warning as warning, Success as success, ArrowUpDownWhite as arrowUpDownWhite } from '@assets';

/** Enum для статуса */
enum Status {
  /** Начальный статус */
  Initial,
  /** Статус загрузки */
  Fetching,
  /** Статус ошибки */
  Error,
  /** Статус нормального состояния */
  Success,
}

export const icons = {
  success,
  warning,
  arrowUpDownWhite,
};

/** Текста ошибок валидации полей */
export enum ErrorMessages {
  requiredField = 'Поле обязательно для заполнения',
  emailFormat = 'Введите email в формате yourmail@mail.ru',
  passwordSimilar = 'Пароли не совпадают',
  innFormat = 'Должен содержать 10-12 цифр',
  phoneFormat = 'Введите телефонный номер в формате +7(999)-999-99-99',
  maxValue = 'Превышено максимальное значение',
  fio = 'Введены запрещенные символы',
  loadPeriod = 'Дата начала не может быть больше даты окончания',
  technicalInspectionDates = 'Дата следующего ТО не может быть раньше даты последнего ТО',
  cleaningDates = 'Дата последней уборки должна быть раньше даты следующей уборки',
  captchaRequiredErrorText = 'Капча обязательна для прохождения',
}

/** Вопросы модалки подтверждения действия */
export enum ConfirmQuestions {
  confirmExit = 'Изменения не будут сохранены. Продолжить?',
  deleteEmployee = 'Удалить сотрудника и его нагрузку с проекта?',
}

/** Типы ресурсов на русском */
export enum Resources {
  Employee = 'Сотрудник',
  Equipment = 'Техника',
  Place = 'Помещение',
}

/** Названия ролей проектов */
export enum ProjectActionsNames{
  /** Обновить ресурс на проекте, изменить его проектные роли */
  updateResource = 'Изменение ресурса на проекте',

  /** Обновить проект */
  updateProject = 'Изменение проекта',

  /** Удалить проект */
  deleteProject = 'Удаление проекта',

  /** Может просматривать потребности проекта */
  readProjectRequirement = 'Просмотр потребностей проекта',

  /** Может изменять потребности проекта */
  updateProjectRequirement = 'Изменение потребностей проекта',
}

export enum Placeholders {
  startTyping = 'Начните вводить текст',
}

/** Описание типа подписки */
export enum SubscriptionDescription {
  /** Описание базовой подписки */
  base='Базовая версия подписки с ограничениями. По достижении лимита вы не сможете создавать новые записи.',
  /** Описание базовой подписки */
  online='Создавайте неограниченное количество записей и пользователей.'
}

export default Status;

export const SiteTitle = 'A2Planner';

export enum RussianProjectStatuses {
  Active = 'Активный',
  Inactive = 'Завершен'
}

export const RussianProjectStatusesForSelect = Object.entries(RussianProjectStatuses).map(([value, label]) => ({
  label, value,
}));
