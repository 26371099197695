import { useCallback, useContext } from 'react';
import { ConfirmModalContext } from 'src/contexts/ConfirmModalContext';

export const useConfirm = (question: string) => {
  const confirmContext = useContext(ConfirmModalContext);

  const callback = useCallback(() => confirmContext.confirm(question), [confirmContext, question]);

  return callback;
};
