import type { SyntheticEvent } from 'react';
import React, {
  memo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import graphConfig from './graph.config';

interface Props {
  svgWidth: number;
  taskListWidth: number;
  onScroll: (event: SyntheticEvent<HTMLDivElement>) => void;
  onLoad: () => void;
  canGoNext: boolean;
}

const HorizontalScroll = forwardRef<HTMLDivElement, Props>(({
  svgWidth, taskListWidth, onScroll, onLoad, canGoNext,
}, ref) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => scrollRef.current,
  );

  return (
    <div className="flex items-stretch">
      <div
        className="border-b-graph-border border-x-graph-border flex-shrink-0 flex items-center justify-center"
        style={{ width: `calc(${taskListWidth}px + ${graphConfig.theme.extend.borderWidth['graph-border']})` }}
      >
        {canGoNext && (
          <button
            className="rounded bg-main m-[2px] p-[1px] text-sm w-full text-white"
            onClick={onLoad}
            type="button"
          >
            Загрузить ещё
          </button>
        )}
      </div>
      <div
        ref={scrollRef}
        className="overflow-auto max-w-full h-fit"
        dir="ltr"
        id="HorizontalScroll"
        onScroll={onScroll}
        style={{
          scrollbarWidth: 'thin',
        }}
      >
        <div
          className="h-[1px]"
          style={{ width: svgWidth }}
        />
      </div>
    </div>
  );
});
HorizontalScroll.displayName = 'HorizontalScroll';

export default memo(HorizontalScroll);
