import type { Period } from '@types';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { PeriodStore } from 'src/stores/ProjectsLoad/PeriodStore';
import { observer } from 'mobx-react-lite';
import WeekPicker from '@components/ui/WeekPicker.tsx/WeekPicker';
import { DayOfWeek } from '@typesApi';
import Modal from '@components/Modal/Modal';
import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import FormGrid from '@components/ui/Form/FormGrid';

interface WorkingPeriodModalProps {
  onClose: (val?: Period) => void;
  startDate?: Dayjs;
  finishDate?: Dayjs;
  workingHoursPerDay?: number;
  workingDaysOfWeek?: DayOfWeek[];
  isWorkOnWeekends?: boolean;
  onDelete?: () => void;
}

const WorkingPeriodModal: React.FC<WorkingPeriodModalProps> = ({
  onClose, startDate, finishDate, workingHoursPerDay, onDelete, workingDaysOfWeek, isWorkOnWeekends,
}) => {
  const store = useMemo(() => new PeriodStore({
    startDate: startDate?.toISOString(),
    finishDate: finishDate?.toISOString(),
    workingHoursPerDay,
    workingDaysOfWeek: workingDaysOfWeek ?? [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
    ],
    isWorkOnWeekends,
  }), [finishDate, isWorkOnWeekends, startDate, workingDaysOfWeek, workingHoursPerDay]);

  const { viewModel } = store;

  return (
    <Modal onClose={onClose}>
      <div className="min-w-[550px]">
        <FormGrid
          maxColumnsNumber={2}
          minColumnWidth={2}
        >
          <DatePicker
            error={viewModel.errors.startDate}
            label="Дата начала"
            onChange={(val) => {
              viewModel.startDate = val;
            }}
            value={viewModel.startDate}
            utc
          />
          <DatePicker
            error={viewModel.errors.finishDate}
            label="Дата окончания"
            onChange={(val) => {
              viewModel.finishDate = val;
            }}
            value={viewModel.finishDate}
            utc
          />
          <FormInput
            error={viewModel.errors.workingHoursPerDay}
            label="Кол-во рабочих часов в день"
            onChange={(e) => {
              viewModel.workingHoursPerDay = Number(e.currentTarget.value);
            }}
            type="number"
            value={viewModel.workingHoursPerDay}
          />
          <WeekPicker
            dayClick={(e) => {
              viewModel.toggleDay(e);
            }}
            label="Рабочие дни"
            selected={viewModel.workingDaysOfWeek}
          />
        </FormGrid>
        <div className="mt-4 flex gap-5 justify-end">
          {onDelete && (
            <Button.Primary
              className="mr-auto"
              label="Удалить"
              onClick={() => onDelete()}
              type="danger"
            />
          )}
          <Button.Primary
            label="Отменить"
            onClick={() => onClose()}
            type="outline"
          />
          <Button.Primary
            label="Сохранить"
            onClick={() => {
              if (viewModel.validate()) {
                onClose({
                  startDate: dayjs(viewModel.startDate!).utc(false),
                  finishDate: dayjs(viewModel.finishDate!).utc(false),
                  workingHoursPerDay: viewModel.workingHoursPerDay!,
                  workingDaysOfWeek: viewModel.data.workingDaysOfWeek ?? [],
                  isWorkOnWeekends: viewModel.isWorkOnWeekends,
                });
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default observer(WorkingPeriodModal);
