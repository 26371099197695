import {
ProblemDetails,
ProblemDetailsResponse,
UploadFileRequest,
UploadFileResponse} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class FileService {
  static get RoutePath(): string {
    return "File";
  }

  /**
   * Загрузить файлы в хранилище
   *
   * @request POST:/api/v1.0/File
   */
  static create = async (data: { files?: File[]; request?: UploadFileRequest }, params: RequestParams = {}) =>
    httpClient.request<UploadFileResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });

  /**
   * Скачать файл из хранилища
   *
   * @request GET:/api/v1.0/File/{id}/Download
   */
  static downloadDetail = async (id: string, query?: { inline?: boolean }, params: RequestParams = {}) =>
    httpClient.request<File, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/Download`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}