import type { Period } from '@types';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import type { RefObject } from 'react';
import React, {
  useMemo,
  useRef,
  useEffect,
  useCallback,
  startTransition,
  useLayoutEffect,
  useState,
} from 'react';
import Popup from '@components/Popup/Popup';
import Button from '@components/ui/Button';
import WorkingPeriodModal from './Modals/WorkingPeriodModal';
import graphConfig from './graph.config';

const HoveredCell: React.FC<{
  data: {resourceInProjectId?: string; projectId?: string; rowIndex: number}[];
  svgRef: RefObject<SVGElement>;
  listRef: RefObject<HTMLDivElement>;
  startDate: Date;
  scrollLeft: number;
  scrollTop: number;
  removeResourceLoad: (startDate: dayjs.Dayjs, finishDate: dayjs.Dayjs, row: number) => void;
  addUnavailabilityPeriod: (startDate: dayjs.Dayjs, finishDate: dayjs.Dayjs, row: number) => void;
  addResourceLoad: (period: Period, y: number) => void;
  rowHeight: number;
  columnWidth: number;
  editableLoadRows: number[];
  editableUnavailablePeriodRows: number[];
}> = ({
  data,
  svgRef,
  listRef,
  startDate,
  scrollLeft,
  scrollTop,
  removeResourceLoad,
  addUnavailabilityPeriod,
  addResourceLoad,
  rowHeight,
  columnWidth,
  editableLoadRows,
  editableUnavailablePeriodRows,
}) => {
  const [hoveredCell, setHoveredCell] = useState<{x: number; y: number} | null>(null);
  const [selection, setSelection] = useState<{start: number; end: number; rowIndex: number} | null>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [
    workPeriodModalData,
    setWorkPeriodModalData,
  ] = useState<Partial<Period> & {row: number} | undefined>();
  const selectionRef = useRef<SVGRectElement>(null);

  const selectionMoveCallback = useCallback(
    (e: MouseEvent) => {
      startTransition(() => {
        const canvasX = (e.x - (svgRef.current?.getBoundingClientRect()?.x ?? 0));
        const colIndex = Math.floor(canvasX / columnWidth);
        setSelection((s) => ({ start: s!.start, end: colIndex, rowIndex: s!.rowIndex }));
      });
    },
    [columnWidth, svgRef],
  );

  const hoveredCellOccupied = useMemo(() => {
    if (!hoveredCell) {
      return true;
    }
    const row = data.find((e) => e.rowIndex === hoveredCell.y);
    if (row && !editableLoadRows.includes(row.rowIndex) && !editableUnavailablePeriodRows.includes(row.rowIndex)) {
      return true;
    }

    const rowIsProject = !row?.resourceInProjectId;

    return rowIsProject;
  }, [data, editableLoadRows, editableUnavailablePeriodRows, hoveredCell]);

  useLayoutEffect(() => {
    const ref = svgRef.current;
    const mouseLeaveCallback = () => setHoveredCell(() => {
      ref?.querySelectorAll('[id^=\'row\']')
        ?.forEach((el) => el.setAttribute('fill', 'transparent'));
      listRef.current
        ?.querySelectorAll('[id^=\'row\']')
        ?.forEach((el) => el.classList.remove('bg-graph-hovered-row-bg'));
      return null;
    });
    const mouseMoveCallback = (e: MouseEvent) => {
      startTransition(() => {
        const canvasX = (e.x - (ref?.getBoundingClientRect().x ?? 0));
        const canvasY = (e.y - (ref?.getBoundingClientRect().top ?? 0));
        const rowIndex = Math.floor(canvasY / rowHeight);
        const colIndex = Math.floor(canvasX / columnWidth);
        setHoveredCell((prev) => {
          if (prev?.y !== rowIndex) {
            const row = data.find((el) => el.rowIndex === rowIndex);
            const id = row
              ? row.resourceInProjectId ?? row.projectId
              : `${data.find((el) => el.rowIndex === rowIndex - 1)?.projectId}-add`;
            listRef.current
              ?.querySelectorAll('[id^=\'row\']')
              ?.forEach((el) => el.classList.remove('bg-graph-hovered-row-bg'));
            listRef.current?.querySelector(`#row-${id}`)?.classList.add('bg-graph-hovered-row-bg');
            ref?.querySelectorAll('[id^=\'row\']')
              ?.forEach((el) => el.setAttribute('fill', 'transparent'));
            ref?.querySelector(`#row${rowIndex}`)?.setAttribute('fill', graphConfig.theme.extend.colors['graph-hovered-row-bg']);
          }

          return {
            x: colIndex,
            y: rowIndex,
          };
        });
      });
    };
    const wheelCallback = () => setHoveredCell(null);

    const startSelection = (e: MouseEvent) => {
      if (e.button || hoveredCellOccupied) {
        return;
      }
      setShowContextMenu(false);
      if ((e.target as Element).classList.contains('period-resize-bar') || (e.target as Element).classList.contains('unavailable-period')) {
        setSelection(null);
        return;
      }
      const canvasX = (e.x - (ref?.getBoundingClientRect().x ?? 0));
      const canvasY = (e.y - (ref?.getBoundingClientRect().top ?? 0));
      const rowIndex = Math.floor(canvasY / rowHeight);
      const colIndex = Math.floor(canvasX / columnWidth);
      if (selection) {
        setSelection(null);
        return;
      }
      setSelection({ start: colIndex, end: colIndex, rowIndex });
      ref?.addEventListener('mousemove', selectionMoveCallback);
    };

    if (!workPeriodModalData) {
      ref?.addEventListener('mousemove', mouseMoveCallback);
      ref?.addEventListener('wheel', wheelCallback);
      ref?.addEventListener('mousedown', startSelection);
      ref?.addEventListener('mouseleave', mouseLeaveCallback);
    }

    return () => {
      ref?.removeEventListener('mouseleave', mouseLeaveCallback);
      ref?.removeEventListener('mousemove', mouseMoveCallback);
      ref?.removeEventListener('wheel', wheelCallback);
      ref?.removeEventListener('mousedown', startSelection);
    };
  }, [
    columnWidth,
    hoveredCellOccupied,
    scrollLeft,
    scrollTop,
    rowHeight,
    selection,
    selectionMoveCallback,
    svgRef,
    workPeriodModalData,
    listRef,
    data,
  ]);

  useEffect(() => {
    const ref = svgRef.current;
    const stopSelection = () => {
      ref?.removeEventListener('mousemove', selectionMoveCallback);
      if (Math.abs((selection?.end ?? 0) - (selection?.start ?? 0)) > 0) {
        setShowContextMenu(true);
      } else {
        setShowContextMenu(false);
        if (selection) {
          setSelection(null);
          setWorkPeriodModalData({
            startDate: dayjs.utc(startDate).add(selection.start, 'day'),
            finishDate: dayjs.utc(startDate).add(selection.end, 'day'),
            row: selection.rowIndex,
          });
        }
      }
    };
    window.addEventListener('mouseup', stopSelection, { once: true });
    return () => {
      window.removeEventListener('mouseup', stopSelection);
    };
  }, [startDate, selection, selectionMoveCallback, svgRef]);

  useEffect(() => {
    if (showContextMenu) {
      const cb = (e: MouseEvent) => {
        e.stopPropagation();
        setSelection(null);
        setShowContextMenu(false);
      };
      window.addEventListener('mousedown', cb);
      return () => {
        window.removeEventListener('mousedown', cb);
      };
    }
    return () => {};
  }, [showContextMenu]);

  return (
    <>
      {workPeriodModalData && (
        <WorkingPeriodModal
          {...workPeriodModalData}
          onClose={(e) => {
            if (e) {
              addResourceLoad(
                e,
                workPeriodModalData.row,
              );
            }
            setWorkPeriodModalData(undefined);
          }}
        />
      )}
      {showContextMenu && selection && selectionRef.current && (
        <Popup
          position="right"
          targetRef={selectionRef.current}
        >
          <div className="flex flex-col p-3 gap-2 bg-white rounded-md">
            {editableLoadRows.includes(selection.rowIndex) && (
              <Button.Primary
                className="h-btn-default"
                label="Добавить рабочий период"
                onClick={() => {
                  setSelection(null);
                  setShowContextMenu(false);
                  setWorkPeriodModalData({
                    startDate: dayjs.utc(startDate).add(Math.min(selection.start, selection.end), 'day'),
                    finishDate: dayjs.utc(startDate).add(Math.max(selection.start, selection.end), 'day'),
                    row: selection.rowIndex,
                  });
                }}
                onMouseDown={(e) => e.stopPropagation()}
              />
            ) }
            {editableUnavailablePeriodRows.includes(selection.rowIndex) && (
              <Button.Primary
                className="h-btn-default"
                label="Добавить период недоступности"
                onClick={() => {
                  setSelection(null);
                  setShowContextMenu(false);
                  addUnavailabilityPeriod(
                    dayjs.utc(startDate).add(Math.min(selection.start, selection.end), 'day'),
                    dayjs.utc(startDate).add(Math.max(selection.start, selection.end), 'day'),
                    selection.rowIndex,
                  );
                }}
                onMouseDown={(e) => e.stopPropagation()}
              />
            )}
            {editableLoadRows.includes(selection.rowIndex) && (
              <Button.Primary
                className="h-btn-default"
                label="Удалить"
                onClick={() => {
                  setSelection(null);
                  setShowContextMenu(false);
                  removeResourceLoad(
                    dayjs.utc(startDate).add(Math.min(selection.start, selection.end), 'day'),
                    dayjs.utc(startDate).add(Math.max(selection.start, selection.end), 'day'),
                    selection.rowIndex,
                  );
                }}
                onMouseDown={(e) => e.stopPropagation()}
                type="danger"
              />
            )}
          </div>
        </Popup>
      )}
      {selection && (
        <rect
          ref={selectionRef}
          fill="rgba(50, 50, 255, 0.4)"
          height={rowHeight}
          width={(Math.abs(selection.end - selection.start) + 1) * columnWidth}
          x={Math.min(selection.start, selection.end) * columnWidth}
          y={selection.rowIndex * rowHeight}
        />
      )}
      {hoveredCell && !hoveredCellOccupied && (
        <rect
          className="hover:cursor-pointer fill-graph-hovered-cell-bg"
          height={rowHeight}
          strokeWidth={2}
          width={columnWidth}
          x={hoveredCell.x * columnWidth}
          y={hoveredCell.y * rowHeight}
        />
      )}
    </>
  );
};

export default observer(HoveredCell);
