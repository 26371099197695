import { SalaryPeriodService } from '@servicesHr';
import type { GetSalaryPeriodByIdResponse } from '@typesHr';
import EntityBaseStore from '../BaseEntityStore';
import { SalaryPeriodViewModel } from './SalaryPeriodViewModel';

/** Стор сущности */
export class SalaryPeriodStore extends EntityBaseStore<GetSalaryPeriodByIdResponse, SalaryPeriodViewModel> {
  public viewModel = new SalaryPeriodViewModel({});

  /** Фетч сущности */
  public override async fetch(id: string): Promise<void> {
    this.runWithStateControl(async () => {
      const data = await SalaryPeriodService.detail(id);
      this.viewModel = new SalaryPeriodViewModel(data.data);
    });
  }

  /** Сохранение изменений */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await SalaryPeriodService.update(this.viewModel.id, this.viewModel.getRawData());
      });
    } else {
      await this.runWithStateControl(async () => {
        await SalaryPeriodService.create(this.viewModel.getRawData());
      });
    }
  }

  /** Удалить */
  public async delete() {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await SalaryPeriodService.delete(this.viewModel.id);
      });
    }
  }
}
