import type { Project } from '@types';
import type { GetLoadResourceInProjectsResponseItem, GetLoadResourceInProjectsResponseItemResourcesInProject } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, makeObservable, observable } from 'mobx';

export class ProjectsLoadViewModel implements Omit<Project, 'hideChildren'> {
  @observable resourcesInProject: GetLoadResourceInProjectsResponseItemResourcesInProject[];

  @observable name: string;

  @observable id: string;

  @observable startDate?: Dayjs;

  @observable finishDate?: Dayjs;

  constructor(data: GetLoadResourceInProjectsResponseItem) {
    this.resourcesInProject = data.resourcesInProject ?? [];
    this.name = data.name ?? '';
    this.id = data.id!;
    this.startDate = data.startDate ? dayjs.utc(data.startDate) : undefined;
    this.finishDate = data.finishDate ? dayjs.utc(data.finishDate) : undefined;
    makeObservable(this);
  }

  @computed get occupiesRows() {
    return (this.resourcesInProject?.length ?? 0) + 1;
  }
}
