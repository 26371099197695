import type { GetOrganizationByIdResponse } from '@typesApi';
import { computed, runInAction } from 'mobx';
import { required } from '@services/validationFunctions';
import BaseViewModel from '../BaseViewModel';

/** Вью модель организации */
export class OrganizationViewModel extends BaseViewModel<GetOrganizationByIdResponse> {
  /** Получение идентификатора организации */
  @computed get id() {
    return this.data.id;
  }

  /** Получение названия организации */
  @required()
  @computed get name() {
    return this.data.name ?? undefined;
  }

  /** Изменение названия организации */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение инн организации */
  @computed get inn() {
    return this.data.inn;
  }

  /** Изменение названия организации */
  set inn(value: string | undefined | null) {
    runInAction(() => {
      this.data.inn = value;
    });
  }

  /** Получение id логотипа  */
  @computed get logoId() {
    return this.data.logoId;
  }

  /** Изменение id логотипа организации */
  set logoId(value: string | undefined | null) {
    runInAction(() => {
      this.data.logoId = value;
    });
  }

  /** Ссылка на логотип организации */
  @computed get logoLink() {
    return this.data.logoId ? `${process.env.REACT_APP_API_URL}/api/v1.0/File/${this.data.logoId}/Download` : undefined;
  }
}
