import React from 'react';

type TopPartOfCalendarProps = {
  value: string;
  x1Line: number;
  width: number;
  y1Line: number;
  y2Line: number;
  xText: number;
  yText: number;
};

export const TopPartOfCalendar: React.FC<TopPartOfCalendarProps> = ({
  value,
  x1Line,
  width,
  y1Line,
  y2Line,
  xText,
  yText,
}) => (
  <g className="calendarTop ">
    <rect
      className="fill-graph-header"
      height={y2Line - y1Line}
      width={width}
      x={x1Line - width}
      y={y1Line}
    />
    <line
      key={`${value}line`}
      className="stroke-graph-border"
      x1={x1Line}
      x2={x1Line}
      y1={y1Line}
      y2={y2Line}
    />
    <text
      key={`${value}text`}
      className="select-none text-font-main-bold fill-font-main-bold capitalize"
      style={{
        textAnchor: 'middle',
      }}
      x={xText}
      y={yText}
    >
      {value}
    </text>
  </g>
);
