import { observer } from 'mobx-react-lite';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ProjectResourcesListHeader } from './ProjectResourcesListHeader';
import ProjectResourcesListTable from './ProjectResourcesListTable';

export type ProjectResourcesListProps = {
  headerHeight: number;
  rowWidth: string;
  gridHeight: number;
  resourcesListRef: React.RefObject<HTMLDivElement>;
  horizontalContainerRef: React.RefObject<HTMLDivElement>;
  horizontalContainerClass?: string;
};

const ProjectResourcesList = forwardRef<HTMLDivElement, ProjectResourcesListProps>(({
  headerHeight,
  rowWidth,
  gridHeight,
  resourcesListRef,
  horizontalContainerRef,
  horizontalContainerClass,
}, ref) => {
  const listRef = useRef<HTMLDivElement>(null);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => listRef.current);
  return (
    <div ref={resourcesListRef}>
      <ProjectResourcesListHeader
        headerHeight={headerHeight}
        rowWidth={rowWidth}
      />
      <div
        ref={horizontalContainerRef}
        className={horizontalContainerClass}
        style={gridHeight ? { height: gridHeight } : {}}
      >
        <ProjectResourcesListTable
          ref={listRef}
          rowWidth={rowWidth}
        />
      </div>
    </div>
  );
});

ProjectResourcesList.displayName = 'ProjectResourcesList';

export default observer(ProjectResourcesList);
