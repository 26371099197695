import { ArrowUpDown, Close } from '@assets';
import Popup from '@components/Popup/Popup';
import { BaseInput } from '@components/ui/Input';
import Label from '@components/ui/Label/Label';
import Spinner from '@components/ui/Spinner/Spinner';
import Tag from '@components/ui/Tag/Tag';
import { parseStyles } from '@services/utils';
import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import Options from '../BaseSelect/Options';
import type { SelectOption } from '../types';
import type { MultiSelectProps } from './types';

/** Множественный селект */
const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  options,
  placeholder,
  onSelectOption,
  selectedOptions,
  loading,
  onChange,
  disabled,
  clearable = true,
  error,
  invalid,
  onOptionsShow,
}) => {
  /** Состояние опций */
  const [showOptions, setShowOptions] = useState(false);
  /** Реф для инпута */
  const inputRef = useRef<HTMLInputElement>(null);
  /** Реф контейнера для ширины popup */
  const containerRef = useRef<HTMLDivElement>(null);
  /** Реф для outsideClick */
  const popupRef = useRef<HTMLDivElement>(null);
  /** Реф для контейнера выбранных опций */
  const selectedOptionsRef = useRef<HTMLDivElement>(null);
  /** Реф для кнопок */
  const arrowBtnRef = useRef<HTMLDivElement>(null);
  /** Состояние строки поиска */
  const [localValue, setLocalValue] = useState<string | undefined>();
  /** Закрыть опции при скроле */
  const closeWhenScroll = useCallback(() => setShowOptions(false), []);
  /** Возможность отображения кнопки очистки всех выбранных опций */
  const canShowClearButton = clearable && !!onSelectOption && !!selectedOptions?.length && !disabled;

  useEffect(() => {
    if (inputRef.current && showOptions) {
      inputRef.current.focus();
    }
  }, [showOptions]);

  /** Опции за исключением выбранных */
  const preparedOptions = useMemo(
    () => options?.filter((option) => !selectedOptions?.find((o) => o.value === option.value))
      ?.filter((option) => option.label?.toLowerCase().includes(localValue?.toLowerCase() ?? '')),
    [options, selectedOptions, localValue],
  );

  /** Возможность отображения опций */
  const canShowOptions = showOptions && containerRef?.current && (!!localValue || !!preparedOptions);

  /** Хэндлер открытия опций */
  const openOptionsHandler = useCallback(() => {
    if (disabled) {
      return;
    }
    inputRef?.current?.focus();
    if (!showOptions) {
      setShowOptions((() => {
        onOptionsShow?.();
        return true;
      }));
    }
  }, [disabled, showOptions, onOptionsShow]);

  /** Хэндлер очистки опций */
  const onClearOptionHandler = useCallback((option: SelectOption) => {
    if (canShowClearButton) {
      onSelectOption?.(selectedOptions.filter((o) => o.value !== option.value));
    }
  }, [canShowClearButton, onSelectOption, selectedOptions]);

  /** Хэндлер закрытия опций */
  const closeOptionsHandler = useCallback(() => {
    setShowOptions(false);
    setLocalValue(undefined);
  }, []);

  /** Изменение состояние поля ввода */
  const changeValueHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    openOptionsHandler();
    const val = e?.target.value;
    if (onChange && val) {
      onChange(val);
    }
    setLocalValue(val);
  }, [openOptionsHandler, onChange]);

  /** Обработчик нажатия клавиш */
  const keyEventHandler = useCallback((e: KeyboardEvent) => {
    if (inputRef?.current === document.activeElement) {
      if (!localValue && selectedOptions?.length && e.key === 'Backspace') {
        onSelectOption?.(selectedOptions.slice(0, -1));
      }
      if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
        openOptionsHandler();
      }
      if (document.activeElement !== inputRef.current) {
        closeOptionsHandler();
      }
    }
  }, [closeOptionsHandler, localValue, onSelectOption, openOptionsHandler, selectedOptions]);

  /** Выбрать опцию или очистить все */
  const onSelectHandler = useCallback((option?: SelectOption) => {
    setLocalValue(undefined);
    if (option) {
      onSelectOption?.(selectedOptions?.concat(option) || [option]);
    } else {
      onSelectOption?.();
    }
  }, [onSelectOption, selectedOptions]);

  useEffect(() => {
    window.addEventListener('keydown', keyEventHandler);
    return () => window.removeEventListener('keydown', keyEventHandler);
  }, [selectedOptions, localValue, showOptions, keyEventHandler]);

  useEffect(() => {
    window.addEventListener('wheel', closeWhenScroll);
    return () => window.removeEventListener('wheel', closeWhenScroll);
  }, [closeWhenScroll]);

  /** Хук для клика вне компонента */
  useOnClickOutside(popupRef, (e) => {
    const targetElement = e.target as HTMLElement;
    /** Клик вне Popup игнорируется при... */
    const isIgnoreElement = targetElement === document.activeElement
        || targetElement === arrowBtnRef.current
        || targetElement === inputRef.current
        || targetElement === selectedOptionsRef.current;

    if (isIgnoreElement) {
      return;
    }
    closeOptionsHandler();
  });

  return (
    <div>
      <Label>{label}</Label>
      <div
        ref={containerRef}
        className={parseStyles`
          flex w-full rounded-lg
          border-1 outline-none
          ${disabled ? 'bg-i-bg-disabled text-i-disabled' : 'bg-white'}
          ${(invalid || !!error) ? '!text-error border-error' : 'border-gray-200 focus-within:border-i-border-active'}
        `}
        onKeyUp={(e) => (e.key === 'Tab' ? openOptionsHandler() : undefined)}
      >
        <div
          className="flex flex-col flex-1"
          onClick={openOptionsHandler}
        >
          <div
            ref={selectedOptionsRef}
            className="flex flex-wrap gap-1 items-center h-full p-1 w-auto min-h-i-default"
          >
            {!!selectedOptions?.length && selectedOptions.map((option) => (
              <Tag
                key={option.value}
                disabled={disabled}
                onClear={onClearOptionHandler}
                option={option}
              />
            ))}
            {showOptions && (
              <BaseInput
                ref={inputRef}
                className="min-w-[50px] [&:first-child>input]:px-2"
                disabled={disabled}
                height={30}
                onChange={changeValueHandler}
                placeholder={placeholder}
                tabIndex={disabled ? undefined : 0}
                value={localValue}
              />
            )}
            {!showOptions && !selectedOptions?.length
              && <div className="px-2 text-gray-400">{placeholder}</div>}
          </div>
        </div>
        <div className="flex items-center min-h-full cursor-pointer">
          {loading && (
            <div className="flex items-center min-h-full pr-[10px]">
              <Spinner />
            </div>
          )}
          {!disabled && !!preparedOptions?.length && (
            <div
              ref={arrowBtnRef}
              className="flex items-center min-h-full w-[25px]"
              onClick={() => setShowOptions(!showOptions)}
            >
              <ArrowUpDown
                className={parseStyles`
                  transition-all duration-[400ms] pointer-events-none
                  ${showOptions ? 'rotate-0' : 'rotate-180'}
              `}
              />
            </div>
          )}
          {canShowClearButton && (
            <div className="flex items-center min-h-full w-[25px]">
              <Close onClick={() => onSelectHandler()} />
            </div>
          )}
        </div>
        {canShowOptions && (
          <Popup
            ref={popupRef}
            className="mt-1"
            targetRef={containerRef.current}
            sameWidth
          >
            <Options
              onClose={() => setShowOptions(false)}
              onSelect={onSelectHandler}
              options={preparedOptions}
            />
          </Popup>
        )}
      </div>
      <div
        className={parseStyles`
          text-left
          ${error && 'text-error break-words text-xs text-left'}
        `}
      >
        {error}
      </div>
    </div>

  );
};

export default MultiSelect;
