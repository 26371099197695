import { makeAutoObservable, observable, runInAction } from 'mobx';
import type { SelectOption } from 'src/components/ui/Select/types';
import type { ProjectRolesStore } from '../Administration/ProjectRoles/ProjectRolesStore';

/** Наименование проектных ролей */
export class ProjectRolesNamesStore {
  @observable
  private _projectRolesStore: ProjectRolesStore;

  /** Наименование проектных ролей */
  public names?: SelectOption[];

  constructor(projectRolesStore: ProjectRolesStore) {
    this._projectRolesStore = projectRolesStore;
    makeAutoObservable(this);
  }

  /** Получить список опций для селекта */
  public async fetchProjectRoles(search?: string) {
    this._projectRolesStore.filter.projectRoleName = search;
    await this._projectRolesStore.fetch();

    const names = this._projectRolesStore.viewModel?.map((role) => ({
      label: role.name,
      value: role.id ?? '',
    }));
    runInAction(() => {
      this.names = names;
    });
    return names;
  }
}
