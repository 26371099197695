import { observer } from 'mobx-react-lite';
import React from 'react';
import ResourcesListHeader from './ResourcesListHeader';
import ResourcesListTable from './ResourcesListTable';

export type ResourcesListProps = {
  headerHeight: number;
  rowWidth: string;
  gridHeight: number;
  resourcesListRef: React.RefObject<HTMLDivElement>;
  horizontalContainerRef: React.RefObject<HTMLDivElement>;
  horizontalContainerClass?: string;
};

const ResourcesList: React.FC<ResourcesListProps> = ({
  headerHeight,
  rowWidth,
  gridHeight,
  resourcesListRef,
  horizontalContainerRef,
  horizontalContainerClass,
}) => (
  <div ref={resourcesListRef}>
    <ResourcesListHeader
      headerHeight={headerHeight}
      rowWidth={rowWidth}
    />
    <div
      ref={horizontalContainerRef}
      className={horizontalContainerClass}
      style={gridHeight ? { height: gridHeight } : {}}
    >
      <ResourcesListTable rowWidth={rowWidth} />
    </div>
  </div>
);

export default observer(ResourcesList);
