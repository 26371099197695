import {
FileImportResponse,
GetEmployeeByIdResponse,
GetEmployeeNamesResponse,
GetEmployeesResponse,
PostEmployeeRequest,
PostEmployeeResponse,
ProblemDetails,
ProblemDetailsResponse,
PutEmployeeByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class EmployeeService {
  static get RoutePath(): string {
    return "Employee";
  }

  /**
   * Получение сотрудника по ид
   *
   * @request GET:/api/v1.0/Employee/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetEmployeeByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление данных о сотруднике
   *
   * @request PUT:/api/v1.0/Employee/{id}
   */
  static update = async (id: string, data: PutEmployeeByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка сотрудников
   *
   * @request GET:/api/v1.0/Employee
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      filterName?: string;
      filterProjectRoleName?: string;
      filterProjectRoleCode?: string;
      hasAccount?: boolean;
      showOnlyEditable?: boolean;
      isDeleted?: boolean;
      isProjectDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetEmployeesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание сотрудника
   *
   * @request POST:/api/v1.0/Employee
   */
  static create = async (data: PostEmployeeRequest, params: RequestParams = {}) =>
    httpClient.request<PostEmployeeResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение списка ФИО сотрудников
   *
   * @request GET:/api/v1.0/Employee/Names
   */
  static namesList = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      name?: string;
      hasAccount?: boolean;
      showOnlyEditable?: boolean;
      excludedProjectId?: string;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetEmployeeNamesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Names`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Импорт сотрудников из csv
   *
   * @request POST:/api/v1.0/Employee/Import
   */
  static importCreate = async (data: { file?: File }, params: RequestParams = {}) =>
    httpClient.request<FileImportResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Import`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
}