import { Loader } from '@components/ui';
import Button from '@components/ui/Button';
import { useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StatusCode402: React.FC = () => {
  const { privilegesStore, accountStore } = useStores();
  const { canPayOrganizationSubscription } = privilegesStore;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      {
        accountStore.state.isLoading && (
          <div className="flex justify-center items-center w-full h-full">
            <Loader size={40} />
          </div>
        )
      }
      {
        !accountStore.state.isLoading && (
          canPayOrganizationSubscription
            ? (
              <>
                <p className="text-lg">Срок подписки организации истек. Для дальнейшего использования оплатите подписку.</p>
                <div>
                  <Button.Outline
                    label="Перейти к оплате"
                    onClick={() => navigate('/settings/organization')}
                  />
                </div>
              </>
            )
            : (<p className="text-lg">Срок подписки организации истек. Обратитесь к администратору организации.</p>)
        )
      }
    </div>
  );
};

export default observer(StatusCode402);
