import React from 'react';
import Switch from 'react-switch';
import Label from '../Label/Label';
import tailwindConfig from './switcher.config.js';
import Loader from '../Loader/Loader';

interface Props {
  /** Изначальное состояние чекбокса */
  isActive?: boolean;
  /** функция обработчик клика */
  onChange?: () => void;
  /** Текст свитчера */
  label?: string;
  /** Возможность изменения */
  disabled?: boolean;
  /** Загрузка */
  loading?: boolean;
}

/** Анимированный переключатель */
const Switcher: React.FC<Props> = ({
  onChange, isActive = false, label, disabled, loading,
}) => (
  <div className="flex items-center gap-3 ">
    {loading
      ? (
        <div className="[&>svg]:inline">
          <Loader size={15} />
        </div>
      )
      : (
        <Switch
          checked={isActive}
          checkedIcon={false}
          disabled={disabled}
          height={15}
          offColor={tailwindConfig.theme.extend.colors['switch-disabled']}
          onChange={() => {
            if (onChange) {
              onChange();
            }
          }}
          onColor={tailwindConfig.theme.extend.colors['switch-active']}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onChange?.();
            }
          }}
          uncheckedIcon={false}
          width={31.5}
        />
      )}
    {
      label && (<Label>{label}</Label>)
    }
  </div>
);

export default Switcher;
