import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useConfirm, useTitle } from '@hooks';
import { useEffectOnce } from 'usehooks-ts';
import type { GetEquipmentsResponseItem } from '@typesApi';
import type { EquipmentsTableItem } from '@types';
import { LinkCell, Column, Table } from '@components/ui/Table';
import EquipmentResourceForm from '@components/ResourcesForms/EquipmentResourceForm';
import { computed, runInAction } from 'mobx';
import TablePageLayout from '@components/TablePageLayout';
import { InputHeader } from '@components/index';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '@components/ui/Button';
import RowCheckboxForResources from '@components/ui/Table/RowCheckboxForArchiveResources';
import Errors from '@components/ui/Errors/Errors';
import { archiveHash } from '@services/utils';
import ButtonIcons from '@components/ui/Button/ButtonIcons';

/** ячейка перехода к ответственному лицу */
const LinkToEmployeeCell: React.FC<{data: EquipmentsTableItem}> = ({ data }) => (
  <LinkCell<EquipmentsTableItem>
    linkId="employeeId"
    object={data}
    prefix="employees"
    value="employeeName"
  />
);

/** Таблица помещений реестра ресурсов */
const EquipmentsTable: React.FC = () => {
  useTitle('Техника');
  const { equipmentsStore } = useStores();
  const { hash } = useLocation();

  /** Привилегии  */
  const { canCreate, canDelete } = equipmentsStore;

  /** Модалка создания и редактирования ресурса */
  const [resourceModalInfo, setResourceModalInfo] = useState<{resourceId?: string} | undefined>();

  /** Отображение архивных или действующих данных */
  const [isArchive, setIsArchive] = useState(false);

  /** Хук для модалки подтверждения */
  const confirm = useConfirm(isArchive ? 'Восстановить выбранную технику?' : 'Архивировать выбранные ресурсы?');

  const { id } = useParams();
  const navigate = useNavigate();

  useEffectOnce(() => {
    runInAction(() => {
      equipmentsStore.filter.orderBy = 'name';
      equipmentsStore.filter.isAscending = true;
    });
    return () => equipmentsStore.clearStore();
  });

  useEffect(() => {
    if (hash?.includes(archiveHash)) {
      setIsArchive(true);
      runInAction(() => {
        equipmentsStore.filter.isDeleted = true;
      });
    } else {
      setIsArchive(false);
      runInAction(() => {
        equipmentsStore.filter.isDeleted = false;
      });
    }
    equipmentsStore.fetch();
  }, [hash, equipmentsStore, equipmentsStore.filter, setIsArchive]);

  /** Хэндлер добавления ресурса */
  const createResourceHandler = useCallback(() => {
    setResourceModalInfo({});
  }, []);

  useLayoutEffect(() => {
    if (id) {
      setResourceModalInfo({ resourceId: id });
    }
  }, [id]);

  const onModalClose = () => {
    if (id && resourceModalInfo) {
      navigate(isArchive ? `/resources/equipments${archiveHash}` : '/resources/equipments');
    }
    setResourceModalInfo(undefined);
  };

  const onRowClickHandler = useCallback((rowId: string) => {
    navigate(isArchive ? `${rowId}${archiveHash}` : `${rowId}`);
  }, [isArchive, navigate]);

  /** Архивация выбранных ресурсов */
  const archiveHandler = useCallback(async () => {
    const res = await confirm?.();
    if (!res) {
      return;
    }
    if (isArchive) {
      equipmentsStore.restoreSelectedEquipment();
    } else {
      equipmentsStore.archive();
    }
  }, [confirm, equipmentsStore, isArchive]);

  /** Заблокирована ли кнопка Архивировать */
  const isDeleteDisabled = computed(() => !equipmentsStore.viewModel.some((p) => p.selected)).get();

  /** Хедер колонки "Название техники" */
  const equipmentNameHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="filterName"
      store={equipmentsStore}
    />
  ), [equipmentsStore]);

  return (
    <TablePageLayout
      buttons={(
        <>
          {canCreate && (
            <Button.Primary
              icon={ButtonIcons.Add}
              isLoading={equipmentsStore.state.isLoading}
              label="Добавить"
              onClick={createResourceHandler}
            />
          )}
          <Button.Primary
            icon={isArchive ? ButtonIcons.Back : ButtonIcons.Archive}
            isLoading={equipmentsStore.state.isLoading}
            label={isArchive ? 'Вернуться в реестр' : 'Архив'}
            onClick={() => {
              runInAction(() => {
                equipmentsStore.filter.isDeleted = !isArchive;
                equipmentsStore.filter.pageNumber = 1;
              });
              navigate(isArchive ? '' : archiveHash);
            }}
          />
          {canDelete && (
            <Button.Primary
              icon={isArchive ? ButtonIcons.ArchiveOut : ButtonIcons.ArchiveIn}
              isDisabled={isDeleteDisabled}
              isLoading={equipmentsStore.state.isLoading}
              label={isArchive ? 'Вернуть в реестр' : 'Архивировать'}
              onClick={archiveHandler}
              type="danger"
            />
          )}
        </>
      )}
      title={`Техника${isArchive ? ' - Архив' : ''}`}
    >
      <Table
        onRowClick={onRowClickHandler}
        reorderSessionKey="equipmentsTable"
        rowHeight={30}
        store={equipmentsStore}
      >
        {canDelete ? (
          <Column<EquipmentsTableItem, GetEquipmentsResponseItem>
            key="checkbox"
            dataType="custom"
            header=""
            template={RowCheckboxForResources}
            width={50}
            pinned
          />
        ) : null}
        <Column
          header="Наименование"
          sortExpr="name"
          pinned
        >
          <Column<EquipmentsTableItem, GetEquipmentsResponseItem>
            dataType="string"
            header={equipmentNameHeader}
            keyExpr="name"
            minWidth={250}
          />
        </Column>
        <Column<EquipmentsTableItem, GetEquipmentsResponseItem>
          dataType="string"
          header="Тип"
          keyExpr="typeName"
          width={200}
          sortable
        />
        <Column<EquipmentsTableItem, GetEquipmentsResponseItem>
          dataType="string"
          header="Инвентарный номер"
          keyExpr="inventoryNumber"
          width={200}
          sortable
        />
        <Column<EquipmentsTableItem, GetEquipmentsResponseItem>
          dataType="string"
          header="Спецификация"
          keyExpr="parameters"
          width={200}
          sortable
        />
        <Column<EquipmentsTableItem, GetEquipmentsResponseItem>
          dataType="custom"
          header="Сотрудник"
          sortExpr="employeeName"
          template={LinkToEmployeeCell}
          width={200}
        />
        <Column<EquipmentsTableItem, GetEquipmentsResponseItem>
          dataType="string"
          header="Помещение"
          keyExpr="placeName"
          width={200}
          sortable
        />
        <Column<EquipmentsTableItem, GetEquipmentsResponseItem>
          dataType="string"
          header="Ответственный"
          keyExpr="responsibleFullName"
          width={200}
          sortable
        />
      </Table>
      {resourceModalInfo && (
        <EquipmentResourceForm
          onClose={onModalClose}
          resourceId={resourceModalInfo.resourceId}
        />
      )}
      <Errors store={equipmentsStore} />
    </TablePageLayout>
  );
};

export default observer(EquipmentsTable);
