import { ProjectService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetProjectsResponseItem } from '@typesApi';
import { makeObservable, observable, runInAction } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import ProjectsItemViewModel from './ProjectsItemViewModel';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';
import type { AccountStore } from '../Account/AccountStore';

/** Фильтр проектов */
export interface ProjectsFilter extends ListBaseFilter {
  /** Удалёны ли проекты */
  isDeleted?: boolean;
  /** Наименование проектов */
  filterName?: string;
}

/** Стор реестра проектов */
class ProjectsStore extends BaseEntityListStore<GetProjectsResponseItem, ProjectsFilter, ProjectsItemViewModel> {
  @observable private _privilegesStore: PrivilegesStore;

  @observable private _accountStore: AccountStore;

  constructor(privilegesStore: PrivilegesStore, _accountStore: AccountStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
    this._accountStore = _accountStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.projects.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.projects.canDelete
      || !!this._accountStore.responsibleInProjects.length;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.projects.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.projects.canRead;
  }

  public get canImport(): boolean {
    return this._privilegesStore.projects.canImport;
  }

  /** Запрос всех проектов */
  public async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ProjectService.list(this.filter);
      runInAction(() => {
        this.setData(data, ProjectsItemViewModel);
      });
    });
  }

  /** Удаление выбранных проектов */
  public async deleteSelectedProjects(): Promise<void> {
    await this.runWithStateControl(async () => {
      await Promise.all(this.viewModel.filter((e) => e.selected).map((e) => ProjectService.delete(e.id, { deleteRightNow: true })));
    });
    if (this.state.isSuccess) {
      await this.fetch();
    }
  }

  public async restoreSelectedProjects(): Promise<void> {
    await this.runWithStateControl(async () => {
      await Promise.all(this.viewModel.filter((e) => e.selected).map((e) => ProjectService.delete(e.id, { })));
    });
    if (this.state.isSuccess) {
      await this.fetch();
    }
  }

  /** Импорт сотрудников */
  public async importProjects(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.runWithStateControl(async () => {
      const { data } = await ProjectService.importCreate(formData as Object);

      return data;
    });
  }
}

export default ProjectsStore;
