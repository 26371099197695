import { EntityActions } from '@typesApi';
import { computed, makeObservable, observable } from 'mobx';
import type { BasePrivileges } from './types';
import type { AccountStore } from '../Account/AccountStore';

/** Стор реестра */
export class ResourcesPrivileges implements BasePrivileges {
  private _privileges: EntityActions[];

  @observable
  private _accountStore?: AccountStore;

  constructor(privileges: EntityActions[], accountStore?: AccountStore) {
    makeObservable(this);
    this._privileges = privileges;
    this._accountStore = accountStore;
  }

  @computed get canCreate() {
    return this._privileges.includes(EntityActions.CreateResources);
  }

  @computed get canUpdate() {
    return this._privileges.includes(EntityActions.UpdateResources);
  }

  @computed get canRead() {
    return this._privileges.includes(EntityActions.ReadResources);
  }

  @computed get canDelete() {
    return this._privileges.includes(EntityActions.DeleteResources);
  }

  @computed get canUpdateResponsible() {
    return this._privileges.includes(EntityActions.UpdateResponsible);
  }

  /** Проверить возможность обновления */
  public canUpdateById(id?: string) {
    if (!id) {
      return this.canUpdate;
    }

    return this.canUpdate || this.isResponsibleFor(id);
  }

  /** Проверить возможность удаления */
  public canDeleteById(id?: string) {
    if (!id) {
      return this.canDelete;
    }

    return this.canDelete || this.isResponsibleFor(id);
  }

  public isResponsibleFor(id: string) {
    return this._accountStore?.responsibleForResources.some((x) => x.id === id) ?? false;
  }
}
