import React from 'react';

/** Отложенный вызов функции */
const useDebouncedCallback = (
  callback: Function,
  delay: number,
) => {
  const timeout = React.useRef<NodeJS.Timeout>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.useCallback((...args: any) => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
};

export default useDebouncedCallback;
