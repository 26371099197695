import { ResourceService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetResourceInProjectsResponseResourceItem } from '@typesApi';
import { makeObservable, observable, runInAction } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import { ResourcesItemViewModel } from './ResourcesViewModel';

export type GetResourceInProjectsResponseResourceItemWithRequiredId = GetResourceInProjectsResponseResourceItem & {id: string}

export class ResourcesStore extends BaseEntityListStore<GetResourceInProjectsResponseResourceItemWithRequiredId,
ListBaseFilter, ResourcesItemViewModel> {
  @observable resourceLoadInProject = new ResourcesItemViewModel({});

  constructor() {
    super();
    makeObservable(this);
  }

  /** Загрузка всех потребностей  */
  public async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ResourceService.inProjectList(this.filter);
      data.entities = data.entities
        ?.map<GetResourceInProjectsResponseResourceItemWithRequiredId>(
        (item) => ({ ...item, id: item.resourceId ?? '' }),
      );
      this.setData(data as {
        entities: GetResourceInProjectsResponseResourceItemWithRequiredId[];
        totalCount: number;
      }, ResourcesItemViewModel);
    });
  }

  public async fetchResourceLoadInProject(resourceId: string, projectId: string) {
    await this.runWithStateControl(async () => {
      const { data } = await ResourceService.inProjectList({ projects: [projectId], resources: [resourceId] });
      runInAction(() => {
        if (data.entities?.length) {
          this.resourceLoadInProject = new ResourcesItemViewModel(data.entities[0]);
        }
      });
    });
  }

  /** Удалить выбранные */
  public async delete() {
    /** Выбранные ресурсы */
    const selectedIds = this.viewModel.filter((e) => e.selected).map((i) => i.id);

    await this.runWithStateControl(async () => {
      await Promise.all(selectedIds.map((id) => ResourceService.delete(id, { deleteRightNow: true })));
    });
  }
}
