import { findParentWithTransform, parseStyles } from '@services/utils';
import React, { useCallback, useRef } from 'react';
import type { ResizeBarProps } from '../types';

/** полоска для ресайза хедера */
const ResizeBar: React.FC<ResizeBarProps> = ({ isLastColumn, onResize, left }) => {
  const ref = useRef<HTMLDivElement>(null);
  // handler движения мыши после нажатия
  const mouseMoveListener = useCallback(
    (offsetX: number) => (e: MouseEvent) => {
      if (!ref.current) {
        return;
      }
      // приклеиваем к правому краю колонки и делаем бордер dashed для показа
      const boundingRect = ref.current!.getBoundingClientRect();
      ref.current!.style.position = 'fixed';
      ref.current!.style.top = `${boundingRect.top}px`;
      ref.current!.style.height = `${boundingRect.height}px`;
      ref.current!.style.left = `${e.clientX - offsetX}px`;
      ref.current!.style.border = '1px dashed black';
      ref.current!.style.width = '0';
    },
    [],
  );

  return (
    <div
      ref={ref}
      className={parseStyles`
        absolute touch-none h-full top-0 cursor-e-resize z-[1000]
        ${isLastColumn ? 'w-[5px] right-0' : 'w-[7px] right-[-5px]'}
      `}
      onClick={(e) => {
        e.stopPropagation();
      }}
      // не тригерим другие ивенты на нажатие
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();

        // отключаем селект
        const preventSelect = (event: Event) => {
          event.stopPropagation();
        };
        const cb = mouseMoveListener((ref.current && findParentWithTransform(ref.current)?.getBoundingClientRect().x) ?? 0);
        window.addEventListener('mousemove', cb);
        window.addEventListener('select', preventSelect);

        // функция при прекращении ресайза
        const stopDrag = () => {
          // убираем все listener
          window.removeEventListener('mousemove', cb);
          window.removeEventListener('mouseup', stopDrag);
          window.removeEventListener('selectStart', preventSelect);
          onResize(ref.current!.getBoundingClientRect().x);
          // убираем все стили для показа ресайза
          ref.current!.style.removeProperty('position');
          ref.current!.style.removeProperty('top');
          ref.current!.style.removeProperty('height');
          ref.current!.style.removeProperty('left');
          ref.current!.style.removeProperty('border');
          ref.current!.style.removeProperty('width');
        };
        window.addEventListener('mouseup', stopDrag);
      }}
      style={{ left }}
    />
  );
};

export default React.memo(ResizeBar);
