import { ProjectService, ResourceService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import { ViewMode } from '@types';
import type { GetLoadResourceInProjectsResponseItem, GetResourceInProjectsResponseUnavailableResourceItem } from '@typesApi';
import { ResourceTypes } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { seedDates } from '@components/CalendarTable/helpers';
import utc from 'dayjs/plugin/utc';
import { dayOfWeekEnumToNumber } from '@services/utils';
import type { DayData } from '@components/CalendarTable/types';
import { ResourcesLoadViewModel } from './ResourcesLoadViewModel';
import BaseEntityListStore from '../BaseEntityListStore';

dayjs.extend(utc);

const startOfYear = dayjs.utc().startOf('year').utc();

function getDateIndex(date: Dayjs, startDate: Date, viewMode: ViewMode) {
  switch (viewMode) {
    case ViewMode.Day: return dayjs.utc(date).utc().diff(startDate, 'day');
    case ViewMode.Week: return dayjs.utc(date).utc().diff(startDate, 'week');
    case ViewMode.Month: return dayjs.utc(date).utc().diff(startDate, 'month');
    default: return dayjs.utc(date).utc().diff(startDate, 'day');
  }
}

interface Filter extends ListBaseFilter {
  projects?: string[];
  resources?: string[];
  resourceType?: ResourceTypes;
  resourceName?: string;
  catalogValues?: string[];
  startDateInProject?: string;
  finishDateInProject?: string;
  minHoursPerDayInProject?: number;
  maxHoursPerDayInProject?: number;
  isDeleted?: boolean;
}

export class ResourcesLoadStore extends BaseEntityListStore<GetLoadResourceInProjectsResponseItem, Filter, ResourcesLoadViewModel> {
  constructor() {
    super(30);
    makeObservable(this);
    this.filter.resourceType = ResourceTypes.Employee;
    reaction(() => ({ period: this.period, startDate: this.startDate }), () => {
      this.filter.pageNumber = 1;
      this.filter.finishDateInProject = dayjs.utc(this.endDate).endOf('day').toISOString();
      this.filter.startDateInProject = dayjs.utc(this.startDate).startOf('day').toISOString();
      this.filter.orderBy = 'resourceName';
      this.filter.isAscending = true;
      this.fetch(true);
    });
  }

  /** пока работает на половину, в будущем юнит в клетке День/Неделя/Месяц */
  @observable viewMode: ViewMode = ViewMode.Day;

  // #region Размеры и скролл таблички
  @observable scrollTop = 0;

  @observable scrollLeft = 0;

  @observable columnWidth = 25;

  @observable rowHeight = 25;

  @observable width = 0;

  @observable height = 0;
  // #endregion

  @observable startDate: Date = startOfYear.utc().toDate();

  @observable period: number = 3;

  @computed get firstRenderedColumn() {
    return Math.floor((this.scrollLeft - (this.width / 2)) / this.columnWidth);
  }

  @computed get lastRenderedColumn() {
    return Math.ceil((this.scrollLeft + (this.width * 1.5)) / this.columnWidth);
  }

  @computed get firstRenderedRow() {
    return Math.floor((this.scrollTop - (this.height / 2)) / this.rowHeight);
  }

  @computed get lastRenderedRow() {
    return Math.ceil((this.scrollTop + (this.height * 1.5)) / this.rowHeight);
  }

  @computed get endDate(): Date {
    return dayjs.utc(this.startDate).add(this.period, 'month').subtract(1, 'day').toDate();
  }

  @computed get unitForDayJS() {
    return this.viewMode.toLocaleLowerCase() as 'day' | 'week' | 'month' | 'year';
  }

  @computed get seededDates() {
    return seedDates(this.startDate, this.endDate, this.viewMode);
  }

  /** массив для всех ресурсов с нагрузкой по дням на проекте */
  @computed({ requiresReaction: true }) get rowsDayToLoadMap(): Map<number, {load: number; weekend: boolean}>[] {
    const startTime = performance.now();
    const res = this.viewModel
      .map((e) => {
        const map = new Map<number, {load: number; weekend: boolean}>();
        e.resourceInProjects?.forEach((project) => project?.loadResourceInProjects
          ?.forEach((load) => {
            const periodStartIndex = getDateIndex(dayjs.utc(load.startDate!).utc(), this.startDate, this.viewMode);
            const periodEndIndex = getDateIndex(dayjs.utc(load.finishDate!).utc(), this.startDate, this.viewMode) + 1;
            for (let index = periodStartIndex; index < periodEndIndex; index++) {
              const workingDays = (load.workingDaysOfWeek ?? project.workingDaysOfWeek)!.map(dayOfWeekEnumToNumber);
              const weekend = !load.isWorkOnWeekends && !workingDays.includes(this.seededDates[index]?.getDay());
              const prevVal = map.get(index);
              map.set(index, {
                load: (prevVal?.load ?? 0) + (weekend ? 0 : load.workingHoursPerDay ?? 0),
                weekend: prevVal ? (prevVal.weekend && weekend) : weekend,
              });
            }
          }));
        return map;
      });
    const endTime = performance.now();
    console.log(`Call1 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** массив данных для каждой строки */
  @computed({ requiresReaction: true }) get preparedResources(): {
    resourceName: string;
    resourceId: string;
    rowIndex: number;
    days: DayData[];
    unavailableResources: GetResourceInProjectsResponseUnavailableResourceItem[];
  }[] {
    const startTime = performance.now();

    const res = this.rowsDayToLoadMap.map((e, i) => {
      const resource = this.viewModel.at(i)!;

      return {
        resourceName: this.viewModel[i].resourceName!,
        resourceId: this.viewModel[i].resourceId!,
        unavailableResources: resource.unavailableResources ?? [],
        rowIndex: i,
        days: Array.from(e, ([key, val]) => ({
          index: key,
          hours: val.load,
          text: this.seededDates[key]?.toLocaleDateString(),
          total: val.load,
          weekend: val.weekend,
        })),
      };
    });
    const endTime = performance.now();
    console.log(`Call2 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** только не свёрнутые и видимые на странице данные */
  @computed get visibleResources() {
    const startTime = performance.now();

    const res = this.preparedResources
      .slice(Math.max(0, this.firstRenderedRow), this.lastRenderedRow)
      .map((e) => ({
        ...e,
        days: e.days.filter((day) => {
          const columnFits = day.index > this.firstRenderedColumn && day.index < this.lastRenderedColumn;

          return columnFits;
        }),
      }));
    const endTime = performance.now();
    console.log(`Call3 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** Перезагрузка всего что уже отображается  */
  public async reload(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ProjectService.loadResourceInProjectsList(
        { ...this.filter, pageSize: this.getRawData().length || this.filter.pageSize, pageNumber: 1 },
      );
      this.setData(data, ResourcesLoadViewModel);
    });
  }

  /** Загрузка потребностей по фильтру или новая страница  */
  public async fetch(first = false): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ResourceService.inProjectList(
        this.filter,
      );
      console.log(first ? data : [...this.getRawData(), ...data.entities ?? []]);
      this.setData(first ? data : { entities: [...this.getRawData(), ...data.entities ?? []], totalCount: data.totalCount }, ResourcesLoadViewModel);
    });
  }
}
