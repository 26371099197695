import Popup from '@components/Popup/Popup';
import { FormInput } from '@components/ui';
import { parseStyles } from '@services/utils';
import type { ListBaseFilter } from '@types';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import type { InputMinMaxHeaderProps } from './types';

const InputMinMaxHeader = <EntityT, FilterT extends ListBaseFilter, VmT>({
  store, maxField, minField,
}: InputMinMaxHeaderProps<EntityT, FilterT, VmT>) => {
  const [open, setOpen] = useState<boolean>(false);
  const [rangeError, setRangeError] = useState<string | undefined>(undefined);
  /** Реф для контейнера */
  const containerRef = useMemo(() => createRef<HTMLDivElement>(), []);
  /** Реф для outsideClick */
  const popupRef = useMemo(() => createRef<HTMLDivElement>(), []);
  /** Реф для кнопок */
  const sortBtnRef = useMemo(() => createRef<HTMLDivElement>(), []);

  /** Закрыть опции при скроле */
  const closeWhenScroll = useCallback(() => {
    setOpen(false);
  }, []);

  /** Изменялись ли поля */
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    window.addEventListener('wheel', closeWhenScroll);
    return () => window.removeEventListener('wheel', closeWhenScroll);
  }, [closeWhenScroll]);

  /** Хук для клика вне компонента */
  useOnClickOutside(popupRef, (e) => {
    const targetElement = e.target as HTMLElement;

    if (targetElement === document.activeElement || targetElement === sortBtnRef?.current) {
      return;
    }
    setOpen(false);
    if (isDirty) {
      runInAction(() => {
        store.filter.pageNumber = 1;
      });
      store.fetch();
    }
    setIsDirty(false);
  });

  const onChangeInputMaxValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    runInAction(() => {
      (store.filter[maxField] as string | undefined) = event.target.value;
    });
  }, [store.filter, maxField]);

  const onChangeInputMinValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    runInAction(() => {
      (store.filter[minField] as string | undefined) = event.target.value;
    });
  }, [store.filter, minField]);

  const getLabel = useMemo((): string => {
    if (store.filter[minField] && store.filter[maxField] && store.filter[minField] > store.filter[maxField]) {
      setRangeError('text-red-500');
      return 'Неверно указан диапазон';
    }
    setRangeError(undefined);
    if (store.filter[minField] && store.filter[maxField]) {
      return `${store.filter[minField]?.toString()} - ${store.filter[maxField]?.toString()}`;
    }
    if (store.filter[minField]) {
      return `от ${store.filter[minField]?.toString()}`;
    }
    if (store.filter[maxField]) {
      return `до ${store.filter[maxField]?.toString()}`;
    }
    return 'Указать диапазон';
  }, [store.filter, minField, maxField]);

  return (
    <div
      ref={containerRef}
      className={parseStyles`
        flex flex-col items-center 
        w-full px-2 py-1 justify-center`}
    >
      <div className="self-stretch">
        <div
          ref={sortBtnRef}
          className="cursor-pointer h-[40px] rounded-main bg-white flex items-center justify-center border-1"
          onClick={() => setOpen(!open)}
        >
          <div className={parseStyles`text-center cursor-pointer pointer-events-none text-sm ${rangeError}`}>
            {getLabel}
          </div>
        </div>
        {(open) && (
          <Popup
            ref={popupRef}
            targetRef={containerRef.current!}
          >
            <div className={parseStyles`
              gap-form bg-white rounded-main 
              p-2 border-1 shadow-md pb-4
              `}
            >
              <FormInput
                backgroundColor="bg-transparent"
                className="[&>input]:max-h-[22px]"
                label="Минимальное значение"
                onChange={onChangeInputMinValue}
                type="number"
                value={store.filter[minField]?.toString()}
              />
              <FormInput
                backgroundColor="bg-transparent"
                className="[&>input]:max-h-[22px]"
                label="Максимальное значение"
                onChange={onChangeInputMaxValue}
                type="number"
                value={store.filter[maxField]?.toString()}
              />
            </div>
          </Popup>
        )}
      </div>
    </div>
  );
};

InputMinMaxHeader.displayName = 'InputMinMaxHeader';

export default observer(InputMinMaxHeader);
