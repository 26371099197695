import type { EquipmentsTableItem } from '@types';
import { joinNonEmpty } from '@services/utils';
import type { GetEquipmentsResponseItem, GetEquipmentsResponseItemProject } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import BaseViewModel from '../BaseViewModel';

/** Модель элемента массива списка помещений */
class EquipmentsViewModel extends BaseViewModel<GetEquipmentsResponseItem> implements EquipmentsTableItem {
  @observable
    selected: boolean = false;

  constructor(data: GetEquipmentsResponseItem) {
    super(data);
    makeObservable(this);
  }

  /** Получение id */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получение name */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение name */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение typeId */
  @computed get typeId() {
    return this.data.typeId ?? '';
  }

  /** Изменение typeId */
  set typeId(value: string | undefined) {
    runInAction(() => {
      this.data.typeId = value;
    });
  }

  /** Получение typeName */
  @computed get typeName() {
    return this.data.typeName ?? '';
  }

  /** Изменение typeName */
  set typeName(value: string | undefined) {
    runInAction(() => {
      this.data.typeName = value;
    });
  }

  /** Получение inventoryNumber */
  @computed get inventoryNumber() {
    return this.data.inventoryNumber ?? '';
  }

  /** Изменение inventoryNumber */
  set inventoryNumber(value: string | undefined) {
    runInAction(() => {
      this.data.inventoryNumber = value;
    });
  }

  /** Получение parameters */
  @computed get parameters() {
    return this.data.parameters ?? '';
  }

  /** Изменение parameters */
  set parameters(value: string | undefined) {
    runInAction(() => {
      this.data.parameters = value;
    });
  }

  /** Получение placeId */
  @computed get placeId() {
    return this.data.placeId ?? '';
  }

  /** Изменение placeId */
  set placeId(value: string | undefined) {
    runInAction(() => {
      this.data.placeId = value;
    });
  }

  /** Получение placeName */
  @computed get placeName() {
    return this.data.placeName ?? '';
  }

  /** Изменение placeName */
  set placeName(value: string | undefined) {
    runInAction(() => {
      this.data.placeName = value;
    });
  }

  /** Получение employeeId */
  @computed get employeeId() {
    return this.data.employeeId ?? '';
  }

  /** Изменение employeeId */
  set employeeId(value: string | undefined) {
    runInAction(() => {
      this.data.employeeId = value;
    });
  }

  /** Получение employeeName */
  @computed get employeeName() {
    return this.data.employeeName ?? '';
  }

  /** Изменение employeeName */
  set employeeName(value: string | undefined) {
    runInAction(() => {
      this.data.employeeName = value;
    });
  }

  /** Получение useStartDate */
  @computed get useStartDate() {
    return this.data.useStartDate ? dayjs(this.data.useStartDate) : undefined;
  }

  /** Изменение useStartDate */
  set useStartDate(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.useStartDate = value?.toISOString();
    });
  }

  /** Получение endOfLifeDate */
  @computed get endOfLifeDate() {
    return this.data.endOfLifeDate ? dayjs(this.data.endOfLifeDate) : undefined;
  }

  /** Изменение endOfLifeDate */
  set endOfLifeDate(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.endOfLifeDate = value?.toISOString();
    });
  }

  /** Получение lastTechnicalInspection */
  @computed get lastTechnicalInspection() {
    return this.data.lastTechnicalInspection ? dayjs(this.data.lastTechnicalInspection) : undefined;
  }

  /** Изменение lastTechnicalInspection */
  set lastTechnicalInspection(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.lastTechnicalInspection = value?.toISOString();
    });
  }

  /** Получение nextTechnicalInspection */
  @computed get nextTechnicalInspection() {
    return this.data.nextTechnicalInspection ? dayjs(this.data.nextTechnicalInspection) : undefined;
  }

  /** Изменение nextTechnicalInspection */
  set nextTechnicalInspection(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.nextTechnicalInspection = value?.toISOString();
    });
  }

  /** Получение initialCost */
  @computed get initialCost() {
    return this.data.initialCost;
  }

  /** Изменение initialCost */
  set initialCost(value: null | undefined | number) {
    runInAction(() => {
      this.data.initialCost = value;
    });
  }

  /** Получение responsibleId */
  @computed get responsibleId() {
    return this.data.responsibleId;
  }

  /** Изменение responsibleId */
  set responsibleId(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleId = value;
    });
  }

  /** Получение responsibleSurname */
  @computed get responsibleSurname() {
    return this.data.responsibleSurname ?? '';
  }

  /** Изменение responsibleSurname */
  set responsibleSurname(value: string | undefined) {
    runInAction(() => {
      this.data.responsibleSurname = value;
    });
  }

  /** Получение responsibleName */
  @computed get responsibleName() {
    return this.data.responsibleName;
  }

  /** Изменение responsibleName */
  set responsibleName(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleName = value;
    });
  }

  /** Получение responsiblePatronymic */
  @computed get responsiblePatronymic() {
    return this.data.responsiblePatronymic ?? '';
  }

  /** Изменение responsiblePatronymic */
  set responsiblePatronymic(value: string | undefined) {
    runInAction(() => {
      this.data.responsiblePatronymic = value;
    });
  }

  /** Получение ФИО ответственного */
  @computed get responsibleFullName() {
    return joinNonEmpty([this.data.responsibleSurname, this.data.responsibleName, this.data.responsiblePatronymic], ' ');
  }

  /** Получение projects */
  @computed get projects() {
    return this.data.projects ?? [];
  }

  /** Изменение projects */
  set projects(value: GetEquipmentsResponseItemProject[] | null | undefined) {
    runInAction(() => {
      this.data.projects = value;
    });
  }
}

export default EquipmentsViewModel;
