import dayjs from 'dayjs';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import React, { memo } from 'react';
import type { ReactElement } from 'react';
import { ViewMode } from '@types';
import { parseStyles } from '@services/utils';
import { TopPartOfCalendar } from './TopPartOfCalendar';
import graphConfig from '../graph.config';

dayjs.extend(WeekOfYear);

export type CalendarProps = {
  dates: readonly Date[];
  viewMode: ViewMode;
  headerHeight: number;
  columnWidth: number;
  fontSize: string;
};

const Calendar: React.FC<CalendarProps> = ({
  dates,
  viewMode,
  headerHeight,
  columnWidth,
  fontSize,
}) => {
  const getCalendarValuesForYear = () => {
    const topValues: ReactElement[] = [];
    const bottomValues: ReactElement[] = [];
    const topDefaultHeight = headerHeight * 0.5;
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      const bottomValue = date.getFullYear();
      bottomValues.push(
        <text
          key={date.getFullYear()}
          className="fill-font-main-bold select-none pointer-events-none"
          dominantBaseline="middle"
          textAnchor="middle"
          x={columnWidth * i + columnWidth * 0.5}
          y={headerHeight * 0.8}
        >
          {bottomValue}
        </text>,
      );
      if (
        i === 0
        || date.getFullYear() !== dates[i - 1].getFullYear()
      ) {
        const topValue = date.getFullYear().toString();
        const xText = (6 + i - date.getFullYear()) * columnWidth;
        topValues.push(
          <TopPartOfCalendar
            key={topValue}
            value={topValue}
            width={xText}
            x1Line={columnWidth * i}
            xText={xText}
            y1Line={0}
            y2Line={headerHeight}
            yText={topDefaultHeight * 0.7}
          />,
        );
      }
    }
    return [topValues, bottomValues];
  };

  const getCalendarValuesForMonth = () => {
    const topValues: ReactElement[] = [];
    const bottomValues: ReactElement[] = [];
    const topDefaultHeight = headerHeight * 0.5;
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      const bottomValue = dayjs(date).format('MMMM');
      bottomValues.push(
        <text
          key={bottomValue + date.getFullYear()}
          className="fill-font-main-bold select-none pointer-events-none"
          dominantBaseline="middle"
          textAnchor="middle"
          x={columnWidth * i + columnWidth * 0.5}
          y={headerHeight * 0.8}
        >
          {bottomValue}
        </text>,
      );
      if (
        i === 0
        || date.getFullYear() !== dates[i - 1].getFullYear()
      ) {
        const topValue = date.getFullYear().toString();
        const xText = (6 + i - date.getMonth()) * columnWidth;
        topValues.push(
          <TopPartOfCalendar
            key={topValue}
            value={topValue}
            width={xText}
            x1Line={columnWidth * i}
            xText={xText}
            y1Line={0}
            y2Line={topDefaultHeight}
            yText={topDefaultHeight * 0.7}
          />,
        );
      }
    }
    return [topValues, bottomValues];
  };

  const getCalendarValuesForWeek = () => {
    const topValues: ReactElement[] = [];
    const bottomValues: ReactElement[] = [];
    let weeksCount: number = 1;
    const topDefaultHeight = headerHeight * 0.5;
    for (let i = dates.length - 1; i >= 0; i--) {
      const date = dates[i];
      let topValue = '';
      if (i === 0 || date.getMonth() !== dates[i - 1].getMonth()) {
        // top
        topValue = `${dayjs(date).format('MMMM')}, ${date.getFullYear()}`;
      }
      // bottom
      const bottomValue = `W${dayjs(date).week()}`;

      bottomValues.push(
        <text
          key={date.getTime()}
          className="fill-font-main-bold select-none pointer-events-none"
          dominantBaseline="middle"
          textAnchor="middle"
          x={columnWidth * (i) + 0.5 * columnWidth}
          y={headerHeight * 0.8}
        >
          {bottomValue}
        </text>,
      );

      if (topValue) {
        // if last day is new month
        if (i !== dates.length - 1) {
          topValues.push(
            <TopPartOfCalendar
              key={topValue}
              value={topValue}
              width={columnWidth * weeksCount}
              x1Line={columnWidth * i + weeksCount * columnWidth}
              xText={columnWidth * i + columnWidth * weeksCount * 0.5}
              y1Line={0}
              y2Line={topDefaultHeight}
              yText={topDefaultHeight * 0.7}
            />,
          );
        }
        weeksCount = 0;
      }
      weeksCount++;
    }
    return [topValues, bottomValues];
  };

  const getCalendarValuesForDay = () => {
    const topValues: ReactElement[] = [];
    const bottomValues: ReactElement[] = [];
    const topDefaultHeight = headerHeight * 0.5;
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      const bottomValue = `${date
        .getDate()
        .toString()}`;

      const isWeekend = [0, 6].includes(date.getDay());
      const defaultFill = isWeekend ? 'fill-graph-weekend-bg' : 'fill-graph-header';

      bottomValues.push(
        <React.Fragment key={date.getTime()}>
          <rect
            className={parseStyles`
              stroke-graph-border
              ${defaultFill}
            `}
            height={headerHeight / 2}
            width={columnWidth}
            x={columnWidth * i}
            y={headerHeight / 2}
          />
          <text
            className="fill-font-main-bold select-none pointer-events-none"
            dominantBaseline="middle"
            fontSize={headerHeight / 4}
            textAnchor="middle"
            x={i * columnWidth + 0.5 * columnWidth}
            y={(headerHeight * 0.8)}
          >
            {bottomValue}
          </text>
        </React.Fragment>,
      );
      if (
        i + 1 !== dates.length
        && date.getMonth() !== dates[i + 1].getMonth()
      ) {
        const topValue = dayjs(date).format('MMMM');
        const days = dayjs(date).daysInMonth();

        topValues.push(
          <TopPartOfCalendar
            key={topValue + date.getFullYear()}
            value={topValue}
            width={days * columnWidth}
            x1Line={columnWidth * (i + 1)}
            xText={
              columnWidth * (i + 1)
              - days
                * columnWidth
                * 0.5
            }
            y1Line={0}
            y2Line={topDefaultHeight}
            yText={topDefaultHeight * 0.7}
          />,
        );
      }
      if (i === dates.length - 1) {
        const topValue = dayjs(date).format('MMMM');
        if (topValues.at(-1)?.props.value !== topValue) {
          const days = dayjs(date).daysInMonth();
          topValues.push(
            <TopPartOfCalendar
              key={topValue + date.getFullYear()}
              value={topValue}
              width={days * columnWidth}
              x1Line={columnWidth * (i + 1)}
              xText={
                (topValues.at(-1)?.props.x1Line ?? 0)
              + days
                * columnWidth
                * 0.5
              }
              y1Line={0}
              y2Line={topDefaultHeight}
              yText={topDefaultHeight * 0.7}
            />,
          );
        }
      }
    }
    return [topValues, bottomValues];
  };

  let topValues: ReactElement[] = [];
  let bottomValues: ReactElement[] = [];
  switch (viewMode) {
    case ViewMode.Year:
      [topValues, bottomValues] = getCalendarValuesForYear();
      break;
    case ViewMode.Month:
      [topValues, bottomValues] = getCalendarValuesForMonth();
      break;
    case ViewMode.Week:
      [topValues, bottomValues] = getCalendarValuesForWeek();
      break;
    case ViewMode.Day:
      [topValues, bottomValues] = getCalendarValuesForDay();
      break;
    default: break;
  }
  return (
    <g
      className="calendar text-font-main-bold"
      fontSize={fontSize}
    >
      {/* reverse потому что линия рисуется справа, и иначе она затирается следующим прямоугольником */}
      {topValues.reverse()}
      {bottomValues}
      <rect
        className="fill-transparent stroke-graph-border stroke-graph-border-double"
        height={headerHeight}
        width={`calc(${columnWidth * dates.length}px + ${graphConfig.theme.extend.borderWidth['graph-border']})`}
        x={`-${graphConfig.theme.extend.borderWidth['graph-border']}`}
        y={0}
      />
    </g>
  );
};

Calendar.displayName = 'Calendar';

export default memo(Calendar);
