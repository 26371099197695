import type { GetLoadResourceInProjectsResponseItemUnavailableResource } from '@typesApi';
import { makeObservable } from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import { UnavailablePeriodViewModel } from './UnavailablePeriodViewModel';

/** Стор сущности */
export class UnavailablePeriodStore extends EntityBaseStore<GetLoadResourceInProjectsResponseItemUnavailableResource, UnavailablePeriodViewModel> {
  public viewModel = new UnavailablePeriodViewModel({});

  constructor(data: Partial<GetLoadResourceInProjectsResponseItemUnavailableResource>) {
    super();
    this.viewModel = new UnavailablePeriodViewModel(data);
    makeObservable(this);
  }

  /** Фетч сущности */
  public fetch(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  public save(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
