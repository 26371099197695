import React, { useCallback } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { ArrowDown } from '@assets';
import { archiveHash } from '@services/utils';

/** Кнопка навигации назад */
const NavigateBack: React.FC<{route?: string; onClick?: () => void; onlyForPOPType?: boolean}> = ({
  route, onClick, onlyForPOPType = true,
}) => {
  const navigate = useNavigate();
  const type = useNavigationType();
  const { hash } = useLocation();

  /** Обработчик клика */
  const navigateHandler = useCallback(() => {
    const isArchive = hash?.includes(archiveHash);
    onClick?.();
    if (route && (!onlyForPOPType || type === 'POP')) {
      navigate(isArchive ? `${route}${archiveHash}` : route);
    } else {
      navigate(-1);
    }
  }, [hash, navigate, onClick, onlyForPOPType, route, type]);

  return (
    <div
      className="w-7 h-7 min-w-[1.75rem] rounded-full border-accordion-border hover:border-btn-main-bg border-1 my-auto mr-3 bg-white "
      onClick={navigateHandler}
      onKeyDown={(event) => {
        if (['Enter', ' '].includes(event.key)) {
          navigateHandler();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <ArrowDown className="rotate-90 w-[10px] h-full m-auto" />
    </div>
  );
};

export default React.memo(NavigateBack);
