import { parseStyles } from '@services/utils';
import React, {
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import ReactDom from 'react-dom';
import ModalLayout from './ModalLayout';
import type { ModalProps } from './types';

const modalRoot = document.getElementById('modal');

/** Длительность анимации с мс */
const ModalAnimationDuration = 400;

const Modal: React.FC<ModalProps> = ({ children, onClose, className }) => {
  /** Открыта ли модалка (для анимации) */
  const [isOpen, setOpen] = useState(false);

  /** Запуск анимации открытия модалки */
  const openModal = () => {
    setTimeout(() => setOpen(true), 0);
  };

  /** Запуск анимации закрытия модалки */
  const closeModal = useCallback(() => {
    if (onClose) {
      setOpen(false);
      setTimeout(() => {
        onClose();
      }, ModalAnimationDuration);
    }
  }, [onClose]);

  /** Элемент модального окна, который будет монтироваться */
  const elem = useMemo(() => {
    const temp = document.createElement('div');

    return temp;
  }, []);

  /** Реф на окно внутри оверлея */
  const modalRef = useRef<HTMLDivElement>(null);

  /** Обработчик закрытия через Esc */
  const handler = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      const lastModal = document.getElementById('modal')?.lastChild;
      if (lastModal === elem) {
        closeModal();
      }
    }
  }, [closeModal, elem]);

  /** Монтирование модалки в DOM */
  useLayoutEffect(() => {
    elem.className = 'fixed w-full h-full top-0 left-0 flex z-50';
    modalRoot?.appendChild(elem);
    openModal();
    return () => {
      modalRoot?.removeChild(elem);
    };
  }, [elem]);

  /** Добавление обработчика закрытия по нажатию на Esc */
  useEffect(() => {
    window.addEventListener('keydown', handler);
    modalRef.current?.focus();
    return () => window.removeEventListener('keydown', handler);
  }, [handler]);

  return ReactDom.createPortal(
    <>
      <div
        className="bg-slate-400/30 fixed w-full h-full top-0 left-0"
        onClick={closeModal}
      />
      <ModalLayout
        ref={modalRef}
        className={parseStyles`
        transition outline-none
        ${isOpen ? 'opacity-100' : 'opacity-0'}
        ${className}
      `}
        duration={ModalAnimationDuration}
      >
        {children}
      </ModalLayout>
    </>,
    elem,
  );
};

export default Modal;
