const menuItemHeight = 65;

module.exports = {
  theme: {
    extend: {
      height: {
        menuItemH: `${menuItemHeight}px`,
      },
      minHeight: {
        menuItemH: `${menuItemHeight}px`,
      },
      width: {
        closeMenu: '46px',
        openMenu: '240px',
      },
      maxWidth: {
        link: '170px',
      },
      screens: {
        'lm-lg': '70px',
      },
      colors: {
        'logo-border': '#c4a417',
      },
      menuItemHeight,
    },
  },
};
