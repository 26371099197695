import { parseStyles } from '@services/utils';
import React from 'react';

interface TitleProps {
  className?: string;
  children?: React.ReactNode;
  isSubtitle?: boolean;
}

const Title: React.FC<TitleProps> = ({
  children,
  className,
  isSubtitle = false,
}) => (
  <span className={parseStyles`text-left break-words
    ${isSubtitle ? 'text-font-sub-header' : 'text-font-header'}
    ${className} 
  `}
  >
    {children ?? ''}
  </span>
);

export default Title;
