import type { GetProjectRequirementEquipmentByIdResponse } from '@typesApi';
import { computed, runInAction } from 'mobx';
import { ErrorMessages } from 'src/enums';
import ProjectRequirementBaseViewModel from './ProjectRequirementBaseViewModel';

class ProjectRequirementEquipmentViewModel extends ProjectRequirementBaseViewModel<GetProjectRequirementEquipmentByIdResponse> {
  @computed get typeId() {
    return this.data.typeId ?? '';
  }

  set typeId(value: string | undefined) {
    runInAction(() => {
      this.data.typeId = value;
    });
  }

  @computed get typeName() {
    return this.data.typeName ?? '';
  }

  set typeName(value: string | undefined) {
    runInAction(() => {
      this.data.typeName = value;
      this.validateField(
        'typeName',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get parameters() {
    return this.data.parameters ?? '';
  }

  set parameters(value: string | undefined) {
    runInAction(() => {
      this.data.parameters = value;
      this.validateField(
        'parameters',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }
}

export default ProjectRequirementEquipmentViewModel;
