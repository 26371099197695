import type { SelectOption } from '@components/ui/Select/types';
import { EquipmentService, ResourceService } from '@servicesApi';
import type { GetEquipmentByIdResponse } from '@typesApi';
import { computed, observable, runInAction } from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import type EmployeeNamesStore from '../Employees/EmployeeNamesStore';
import type PlaceNamesStore from '../Place/PlaceNamesStore';
import EquipmentViewModel from './EquipmentViewModel';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

class EquipmentStore extends EntityBaseStore<GetEquipmentByIdResponse, EquipmentViewModel> {
  public viewModel = new EquipmentViewModel({});

  @observable private _privilegesStore: PrivilegesStore;

  /** Стор наименований */
  @observable private _placeNamesStore: PlaceNamesStore;

  /** Список сорудников */
  @observable private _employeeNamesStore: EmployeeNamesStore;

  /** Наименования сотрудников */
  employeeNames?: SelectOption[];

  /** Наименования помещений */
  placeNames?: SelectOption[];

  constructor(
    placeNamesStore: PlaceNamesStore,
    employeeNamesStore: EmployeeNamesStore,
    privilegesStore: PrivilegesStore,
  ) {
    super();
    this._placeNamesStore = placeNamesStore;
    this._employeeNamesStore = employeeNamesStore;
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.resources.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.resources.canDeleteById(this.viewModel.id);
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.resources.canUpdateById(this.viewModel.id);
  }

  public override get canRead(): boolean {
    return this._privilegesStore.resources.canRead;
  }

  @computed public get canUpdateResponsible(): boolean {
    return this._privilegesStore.resources.canUpdateResponsible;
  }

  @computed
  public get canReadResponsible(): boolean {
    return this.getCanReadEmployee(this.viewModel.responsibleId);
  }

  @computed
  public get canReadEmployee(): boolean {
    return this.getCanReadEmployee(this.viewModel.employeeId);
  }

  private getCanReadEmployee(id?: string | null): boolean {
    return this._privilegesStore.resources.canRead
      || (!!id && this._privilegesStore.resources.isResponsibleFor(id!));
  }

  /** Получение техники */
  public async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await EquipmentService.detail(id);
      runInAction(() => {
        this.viewModel = new EquipmentViewModel(data);
      });
    });
  }

  /** Сохранение техники */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await EquipmentService.update(this.viewModel.id!, this.viewModel.getRawData());
      });
    } else {
      await this.create();
    }
  }

  /** Создание новой техники */
  public async create(): Promise<string | undefined> {
    return this.runWithStateControl(async () => (await EquipmentService.create(this.viewModel.getRawData())).data.id);
  }

  /** Получить список опций для селекта */
  public async fetchEmployeeNames(search?: string) {
    await this.runWithStateControl(async () => {
      const result = await this._employeeNamesStore.fetchEmployeeNames(search);

      runInAction(() => {
        this.employeeNames = result;
      });
    });

    return this.employeeNames;
  }

  /** Получить список опций для селекта */
  public async fetchPlaceNames(search?: string) {
    await this.runWithStateControl(async () => {
      const result = await this._placeNamesStore.fetchNames(search);

      const names = result?.map((place) => ({
        label: place.name ?? '',
        value: place.id ?? '',
      }));
      runInAction(() => {
        this.placeNames = names;
      });
    });
    return this.placeNames;
  }

  /** Установить ответственного */
  public async setResponsible() {
    if (this.viewModel.id) {
      await ResourceService.responsibleUpdate(this.viewModel.id!, { responsibleId: this.viewModel.responsibleId });
    }
  }
}

export default EquipmentStore;
