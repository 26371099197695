import { ProjectRequirementEmployeeService, ProjectRequirementEquipmentService, ProjectRequirementPlaceService } from '@servicesApi';
import type {
  GetProjectRequirementEmployeeByIdResponse,
  GetProjectRequirementEquipmentByIdResponse,
  GetProjectRequirementPlaceByIdResponse,
} from '@typesApi';
import { ResourceTypes } from '@typesApi';
import { computed, observable, runInAction } from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import type { RequirementsResponse } from './ProjectRequirementBaseViewModel';
import ProjectRequirementBaseViewModel from './ProjectRequirementBaseViewModel';
import ProjectRequirementEmployeeViewModel from './ProjectRequirementEmployeeViewModel';
import ProjectRequirementEquipmentViewModel from './ProjectRequirementEquipmentViewModel';
import ProjectRequirementPlaceViewModel from './ProjectRequirementPlaceViewModel';

type Services = typeof ProjectRequirementEmployeeService
| typeof ProjectRequirementEquipmentService
| typeof ProjectRequirementPlaceService

type ViewModel = ProjectRequirementBaseViewModel<RequirementsResponse>
| ProjectRequirementEmployeeViewModel
| ProjectRequirementEquipmentViewModel
| ProjectRequirementPlaceViewModel

class ProjectRequirementStore extends EntityBaseStore<RequirementsResponse, ViewModel> {
  public viewModel: ViewModel = new ProjectRequirementBaseViewModel<RequirementsResponse>({});

  @observable
    resourceType?: ResourceTypes;

  @computed get service(): Services | undefined {
    switch (this.resourceType) {
      case ResourceTypes.Employee:
        return ProjectRequirementEmployeeService;
      case ResourceTypes.Equipment:
        return ProjectRequirementEquipmentService;
      case ResourceTypes.Place:
        return ProjectRequirementPlaceService;
      default:
        return undefined;
    }
  }

  private getViewModel(data:
  GetProjectRequirementEmployeeByIdResponse
  | GetProjectRequirementEquipmentByIdResponse
  | GetProjectRequirementPlaceByIdResponse): ProjectRequirementBaseViewModel<RequirementsResponse> | undefined {
    switch (this.resourceType) {
      case ResourceTypes.Employee:
        return new ProjectRequirementEmployeeViewModel(data);
      case ResourceTypes.Equipment:
        return new ProjectRequirementEquipmentViewModel(data);
      case ResourceTypes.Place:
        return new ProjectRequirementPlaceViewModel(data);
      default:
        return undefined;
    }
  }

  /** Фетч сущности */
  public override async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      if (!this.service) {
        console.error('Не выбран resourceType');
        return;
      }
      const { data } = await this.service.detail(id);
      runInAction(() => {
        this.viewModel = this.getViewModel(data)!;
      });
    });
  }

  /** Сохранение изменений потребности */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        if (!this.service) {
          console.error('Не выбран resourceType');
          return;
        }
        await this.service.update(this.viewModel.id!, this.viewModel.getRawData()!);
      });
    }
  }

  /** Добавление новой потребности */
  public async create() {
    await this.runWithStateControl(async () => {
      if (!this.service) {
        console.error('Не выбран resourceType');
        return;
      }
      await this.service.create(this.viewModel.getRawData()!);
    });
  }
}

export default ProjectRequirementStore;
