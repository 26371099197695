import {
DeleteCareerMeetingByIdRequest,
GetCareerMeetingByIdResponse,
GetCareerMeetingsResponse,
PostCareerMeetingRequest,
PostCareerMeetingResponse,
ProblemDetails,
ProblemDetailsResponse,
PutCareerMeetingByIdRequest} from "./types.hr.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class CareerMeetingService {
  static get RoutePath(): string {
    return "CareerMeeting";
  }

  /**
   * Получение карьерной встречи по ид
   *
   * @request GET:/hr/v1.0/CareerMeeting/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetCareerMeetingByIdResponse, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление карьерной встречи
   *
   * @request PUT:/hr/v1.0/CareerMeeting/{id}
   */
  static update = async (id: string, data: PutCareerMeetingByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удаление карьерной встречи по ид
   *
   * @request DELETE:/hr/v1.0/CareerMeeting/{id}
   */
  static delete = async (id: string, data: DeleteCareerMeetingByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка карьерных встреч
   *
   * @request GET:/hr/v1.0/CareerMeeting
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      employeeId?: string;
      meetingDateFrom?: string;
      meetingDateTo?: string;
      results?: string;
      isDeleted?: boolean;
      invitedEmployeesIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetCareerMeetingsResponse, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание карьерной встречи
   *
   * @request POST:/hr/v1.0/CareerMeeting
   */
  static create = async (data: PostCareerMeetingRequest, params: RequestParams = {}) =>
    httpClient.request<PostCareerMeetingResponse, ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}