import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useConfirm, useStores, useTitle } from '@hooks';
import { observer } from 'mobx-react-lite';
import Column from '@components/ui/Table/Column';
import { computed, runInAction } from 'mobx';
import Errors from '@components/ui/Errors/Errors';
import RowCheckbox from '@components/ui/Table/RowCheckbox';
import type { GetContragentsResponseItem } from '@typesApi';
import type { CounterpartiesTableItem } from '@types';
import { useEffectOnce } from 'usehooks-ts';
import CounterpartyModal from '@components/CounterpartyCard/CounterpartyModal';
import { Table } from '@components/ui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TablePageLayout from '@components/TablePageLayout';
import Button from '@components/ui/Button';
import { InputHeader } from '@components/index';
import { archiveHash } from '@services/utils';
import ButtonIcons from '@components/ui/Button/ButtonIcons';

const Counterparties: React.FC = () => {
  useTitle('Контрагенты');

  const { counterpartiesStore, privilegesStore } = useStores();

  const { canCreate, canDelete } = privilegesStore.counterparties;

  const deleteConfirm = useConfirm('Удалить выбранных контрагентов?');
  const restoreConfirm = useConfirm('Восстановить выбранных контрагентов?');

  const { id } = useParams();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [showModal, setShowModal] = useState(false);

  const [isArchive, setIsArchive] = useState(false);

  useEffectOnce(() => {
    runInAction(() => {
      counterpartiesStore.filter.orderBy = 'name';
      counterpartiesStore.filter.isAscending = true;
    });
    return () => counterpartiesStore.clearStore();
  });

  useEffect(() => {
    if (hash?.includes(archiveHash)) {
      setIsArchive(true);
      runInAction(() => {
        counterpartiesStore.filter.isDeleted = true;
      });
    } else {
      setIsArchive(false);
      runInAction(() => {
        counterpartiesStore.filter.isDeleted = false;
      });
    }
    counterpartiesStore.fetch();
  }, [hash, counterpartiesStore, counterpartiesStore.filter, setIsArchive]);

  useLayoutEffect(() => {
    if (id) {
      setShowModal(true);
    }
  }, [id]);

  const onModalClose = () => {
    if (id && showModal) {
      navigate(isArchive ? `/settings/counterparties${archiveHash}` : '/settings/counterparties');
    }
    setShowModal(false);
  };

  const onRowClickHandler = useCallback((rowId: string) => {
    navigate(isArchive ? `${rowId}${archiveHash}` : `${rowId}`);
  }, [isArchive, navigate]);

  /** Заблокирована ли кнопка Удалить/Восстановить */
  const isDeleteDisabled = computed(() => !counterpartiesStore.viewModel.filter((e) => e.selected).length).get();

  /** хедер поиска колонки Название */
  const nameSearchHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="filterName"
      store={counterpartiesStore}
    />
  ), [counterpartiesStore]);

  return (
    <TablePageLayout
      buttons={(
        <>
          {canCreate && (
            <Button.Primary
              icon={ButtonIcons.Add}
              isLoading={counterpartiesStore.state.isLoading}
              label="Добавить контрагента"
              onClick={() => setShowModal(true)}
            />
          )}
          <Button.Primary
            icon={isArchive ? ButtonIcons.Back : ButtonIcons.Archive}
            isLoading={counterpartiesStore.state.isLoading}
            label={isArchive ? 'Вернуться в реестр' : 'Архив'}
            onClick={() => {
              runInAction(() => {
                counterpartiesStore.filter.isDeleted = !isArchive;
                counterpartiesStore.filter.pageNumber = 1;
              });
              navigate(isArchive ? '' : archiveHash);
            }}
          />
          {canDelete && (
            <Button.Primary
              icon={isArchive ? ButtonIcons.ArchiveOut : ButtonIcons.Delete}
              isDisabled={isDeleteDisabled}
              isLoading={counterpartiesStore.state.isLoading}
              label={isArchive ? 'Восстановить выбранные' : 'Удалить выбранные'}
              onClick={async () => {
                if (isArchive && await restoreConfirm()) {
                  counterpartiesStore.restoreSelectedCounterparties();
                }
                if (!isArchive && await deleteConfirm()) {
                  counterpartiesStore.archiveSelectedCounterparties();
                }
              }}
              type={isArchive ? 'main' : 'danger'}
            />
          )}
        </>
      )}
      title={`Контрагенты ${isArchive ? '- Архив' : ''}`}
    >
      <Table
        onRowClick={onRowClickHandler}
        rowHeight={30}
        store={counterpartiesStore}
        withPagination
      >
        {canDelete ? (
          <Column<CounterpartiesTableItem, GetContragentsResponseItem>
            key="select"
            dataType="custom"
            header=""
            resizable={false}
            template={RowCheckbox}
            width={50}
            pinned
          />
        ) : null}
        <Column<CounterpartiesTableItem, GetContragentsResponseItem>
          header="Название"
          sortExpr="name"
          pinned
          sortable
        >
          <Column<CounterpartiesTableItem, GetContragentsResponseItem>
            dataType="string"
            header={nameSearchHeader}
            keyExpr="name"
            minWidth={250}
          />
        </Column>
        <Column<CounterpartiesTableItem, GetContragentsResponseItem>
          dataType="string"
          header="ФИО ответственного"
          keyExpr="responsibleName"
          sortable
        />
        <Column<CounterpartiesTableItem, GetContragentsResponseItem>
          dataType="string"
          header="Комментарий"
          keyExpr="comment"
        />
        {isArchive ? (
          <Column<CounterpartiesTableItem, GetContragentsResponseItem>
            dataType="date"
            header="Дата архивации"
            keyExpr="deletedOn"
          />
        ) : null}
      </Table>
      <Errors store={counterpartiesStore} />
      {(showModal) && (
        <CounterpartyModal
          id={id}
          onClose={onModalClose}
        />
      )}
    </TablePageLayout>
  );
};

export default observer(Counterparties);
