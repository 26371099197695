import type { GetContragentByIdResponseProject } from '@typesApi';
import type { ListBaseFilter } from '@types';
import { makeObservable, observable } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import type { CounterpartyProjectsItemViewModel } from './CounterpartyProjectsItemViewModel';

export class CounterpartyProjectsStore extends BaseEntityListStore<
GetContragentByIdResponseProject,
ListBaseFilter,
CounterpartyProjectsItemViewModel> {
  @observable private privateData: GetContragentByIdResponseProject[] = [];

  public async fetch(): Promise<void> {
    // this.setDataForCurrentPage();
  }

  constructor(data: GetContragentByIdResponseProject[]) {
    super(20);
    makeObservable(this);
    this.privateData = observable.array(data);
  }
}
