/** Выравнивание по вертикали */
export enum VerticalAlign {
  /** Верх */
  top = 'start',
  /** Центр */
  center = 'center',
  /** Низ */
  bottom = 'end',
}

/** Выравнивание по вертикали */
export enum HorizontalAlign {
  /** Лево */
  left = 'start',
  /** Центр */
  center = 'center',
  /** Право */
  right = 'end',
}

/** Пространство между элементами */
export enum Space {
  /**
   * Равномерно распределяет все элементы по ширине flex-блока.
   * Первый элемент вначале, последний в конце
   */
  between = 'space-between',
  /**
   * Равномерно распределяет все элементы по ширине flex-блока.
   * Все элементы имеют полноразмерное пространство с обоих концов
   */
  around = 'space-around',
  /**
   * Равномерно распределяет все элементы по ширине flex-блока.
   * Все элементы имеют равное пространство вокруг
   */
  evenly = 'space-evenly',
  /**
   * Равномерно распределяет все элементы по ширине flex-блока.
   * Все элементы имеют "авто-размер", чтобы соответствовать контейнеру
   */
  stretch = 'stretch',
}
