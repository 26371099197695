import type { TableItemView, CounterpartiesTableItem } from '@types';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ErrorMessages } from 'src/enums';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import BaseViewModel from '../BaseViewModel';
import type { GetCounterpartiesResponseItemWithRequiredId } from './CounterpartiesStore';

export class CounterpartiesItemViewModel extends BaseViewModel<GetCounterpartiesResponseItemWithRequiredId> implements TableItemView<
CounterpartiesTableItem,
GetCounterpartiesResponseItemWithRequiredId
> {
  constructor(data: GetCounterpartiesResponseItemWithRequiredId) {
    super(data);
    makeObservable(this);
  }

  @computed get id() {
    return this.data.id ?? '';
  }

  set id(value: string) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** выбран ли контрагент */
  @observable
    selected: boolean = false;

  /** ФИО ответственного */
  @computed get responsibleName() {
    return this.data.responsibleName ?? '';
  }

  /** Изменение ФИО ответственного */
  set responsibleName(value: string) {
    runInAction(() => {
      this.data.responsibleName = value;
      this.validateField(
        'responsibleName',
        (val) => {
          if (/[!@#$%^%&*()_+\-=:?"]/.test(val ?? '')) {
            return ErrorMessages.fio;
          }
          return undefined;
        },
      );
    });
  }

  /** Название контрагента */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение названия контрагента */
  set name(value: string) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Комментарий к контрагенту */
  @computed get comment() {
    return this.data.comment ?? '';
  }

  /** Изменение комментария к контрагенту */
  set comment(value: string) {
    runInAction(() => {
      this.data.comment = value;
    });
  }

  @computed get deletedOn() {
    return this.data.deletedOn ? dayjs(this.data.deletedOn) : undefined;
  }

  /** Изменить 'Дата архивации' */
  set deletedOn(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.deletedOn = value?.toISOString();
    });
  }
}
