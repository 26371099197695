import { EntityActions } from '@typesApi';
import { computed, makeObservable } from 'mobx';
import type { BasePrivileges } from './types';

/** Стор реестра */
export class CounterpartiesPrivileges implements BasePrivileges {
  private _privileges: EntityActions[];

  constructor(privileges: EntityActions[]) {
    makeObservable(this);
    this._privileges = privileges;
  }

  @computed get canCreate() {
    return this._privileges.includes(EntityActions.CreateContragents);
  }

  @computed get canUpdate() {
    return this._privileges.includes(EntityActions.UpdateContragents);
  }

  @computed get canRead() {
    return this._privileges.includes(EntityActions.ReadContragents);
  }

  @computed get canDelete() {
    return this._privileges.includes(EntityActions.DeleteContragents);
  }
}
