import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { parseStyles } from '@services/utils';
import type { BaseButtonProps, ButtonType } from './types';
import { Loader } from '..';
import buttonConfig from './button.config';

const ButtonStyles: Record<ButtonType, string> = {
  main: 'text-btn-main bg-btn-main-bg hover:bg-btn-main-bg-hover active:bg-btn-main-bg-active',
  danger: 'text-btn-main bg-btn-danger-bg hover:bg-btn-danger-bg-hover active:bg-btn-danger-bg-active',
  outline: 'text-btn-main-bg border-2 border-btn-main-bg '
  + ' hover:border-btn-outline-hover-border hover:bg-btn-outline-hover-bg '
  + ' active:border-btn-outline-hover-border active:bg-btn-outline-active-bg '
  + ' transition-color duration-200 ',
};

/** Стандартная кнопка */
const Button = forwardRef<HTMLButtonElement, BaseButtonProps >(({
  label, className, onClick, isDisabled, isLoading, type = 'main', onMouseDown, icon,
}, buttonRef) => {
  const [width, setWidth] = useState(0);
  // объект для того чтобы всегда перерендеривался даже если false -> false
  const [wrap, setWrap] = useState<{val: boolean}>({ val: false });

  const ref = useRef<HTMLButtonElement>(null);
  useImperativeHandle<HTMLButtonElement | null, HTMLButtonElement | null>(
    buttonRef,
    () => ref.current,
    [],
  );

  const buttonColor = useMemo(
    () => (isDisabled
      ? 'text-btn-main bg-btn-disabled-bg hover:bg-btn-disabled-bg active:bg-btn-disabled-bg cursor-not-allowed'
      : ButtonStyles[type]),
    [type, isDisabled],
  );

  // сбрость подсчитанную ширину до wrap'а
  const reset = useCallback(
    () => {
      setWidth(0);
      setWrap({ val: false });
    },
    [],
  );

  // при смене параметров которые могли поменять ширину контента в кнопке пересчитываем ширину для wrap'а
  useEffect(() => {
    reset();
  }, [label, className, reset]);

  useEffect(() => {
    const callback = () => {
      const curWidth = ref.current?.getBoundingClientRect().width ?? 0;
      setWrap((prev) => {
        /**
         * Если кнопка уже сжималась или до этого не сжималась, но ширина меньше - триггерим апдейт для нового подсчёта
         * влезает ли текст
         */
        if ((curWidth < width && !prev.val) || prev.val) {
          return { val: false };
        }
        return prev;
      });
    };
    const callback2 = () => {
      reset();
    };
    const resizeObserver = new ResizeObserver(callback);
    const mutationObserver = new MutationObserver(callback2);

    if (ref.current?.parentElement) {
      resizeObserver.observe(ref.current.parentElement);
      mutationObserver.observe(ref.current.parentElement, { childList: true });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [width, reset]);

  /**
   * Если ширина не установлена, значит на текущем рендере кнопка будет шириной для вмещения всего контента
   * записываем эту ширину как ширину, после которой нужно wrap'иться
   */
  useLayoutEffect(() => {
    if (!width) {
      const curWidth = ref.current?.getBoundingClientRect().width ?? 0;
      setWidth(curWidth);
    }
  });

  /**
   * Если рисуется главный лейбл кнопки и при этом он по ширине оказывается меньше width, значит кнопка "не влезла" и мы её wrap'им
   */
  useLayoutEffect(() => {
    const curWidth = ref.current?.getBoundingClientRect().width ?? 0;
    if (isLoading || !width) {
      return;
    }
    if (curWidth < width && !wrap.val) {
      setWrap({ val: true });
    }
  });

  return (
    <button
      ref={ref}
      className={parseStyles`
        flex justify-center items-center
        h-btn-default
        rounded-secondary 
        text-btn-default gap-2
        py-btn-default-y
        ${!!(!wrap.val && icon) && 'pl-btn-with-icon pr-btn-default-x'}
        ${!!(wrap.val && icon) && 'px-btn-with-icon'}
        ${!icon && 'px-btn-default-x'}
        ${(wrap.val && icon) || !width ? 'min-w-fit' : 'min-w-0'}
        ${buttonColor}
        ${isLoading && 'cursor-progress'}
        ${className}
      `}
      disabled={isDisabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
      title={label}
      type="button"
    >
      {(isLoading && width)
        ? (
          <Loader
            color={(type === 'main' || type === 'danger') ? buttonConfig.theme.extend.colors['btn-main'] : undefined}
            size={15}
          />
        )
        : (
          <>
            {icon && <span className="text-xl">{icon}</span>}
            {(!icon || !wrap.val) && <span className={(width) ? 'truncate' : 'whitespace-nowrap'}>{label}</span>}
          </>
        )}
    </button>
  );
});
Button.displayName = 'Button';

export default Button;
