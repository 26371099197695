import { required } from '@services/validationFunctions';
import type { GetSalaryPeriodByIdResponse } from '@typesHr';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, runInAction } from 'mobx';
import BaseViewModel from '../BaseViewModel';

export class SalaryPeriodViewModel extends BaseViewModel<GetSalaryPeriodByIdResponse> {
  /** Получить 'Идентификатор' */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получить 'Оклад' */
  @computed get salary() {
    return this.data.salary;
  }

  /** Изменить 'Оклад' */
  set salary(value: number | undefined | null) {
    runInAction(() => {
      this.data.salary = Number(value?.toFixed(2));
    });
  }

  /** Получить 'Ставка в час' */
  @computed get workingRate() {
    return this.data.workingRate;
  }

  /** Изменить 'Ставка в час' */
  set workingRate(value: number | null | undefined) {
    runInAction(() => {
      this.data.workingRate = Number(value?.toFixed(2));
    });
  }

  /** Получить 'Дата начала периода' */
  @required()
  @computed get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : undefined;
  }

  /** Изменить 'Дата начала периода' */
  set startDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
    });
  }

  /** Получить 'Дата окончания периода' */
  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : undefined;
  }

  /** Изменить 'Дата окончания периода' */
  set finishDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
    });
  }

  /** Получить 'Описание' */
  @computed get description() {
    return this.data.description ?? '';
  }

  /** Изменить 'Описание' */
  set description(value: string | undefined) {
    runInAction(() => {
      this.data.description = value;
    });
  }

  /** Получить 'Идентификатор сотрудника' */
  @computed get employeeId() {
    return this.data.employeeId ?? '';
  }

  /** Изменить 'Идентификатор сотрудника' */
  set employeeId(value: string | undefined) {
    runInAction(() => {
      this.data.employeeId = value;
    });
  }
}
