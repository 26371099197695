import { FormInput } from '@components/ui';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { useInterceptChanges, useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

/** Форма создания помещения */
const CreatePlaceForm: React.FC<{changeDirty: () => void}> = ({ changeDirty }) => {
  const { placeStore } = useStores();
  const { viewModel, isDirty } = useInterceptChanges(placeStore.viewModel);

  useEffect(() => {
    if (isDirty) {
      changeDirty();
    }
  }, [changeDirty, isDirty]);

  return (
    <div className="flex flex-col gap-2">
      <FormInput
        error={viewModel.errors.name}
        label="Наименование"
        onChange={(e) => {
          viewModel.name = e.target.value;
        }}
        value={viewModel.name}
      />
      <FormInput
        label="Назначение"
        onChange={(e) => {
          viewModel.appointment = e.target.value;
        }}
        value={viewModel.appointment}
      />
      <FormInput
        error={viewModel.errors.address}
        label="Адрес"
        onChange={(e) => {
          viewModel.address = e.target.value;
        }}
        value={viewModel.address}
      />
      <FormInput
        label="Общая вместимость"
        onChange={(event) => {
          viewModel.totalCapacity = Number(event.target.value);
        }}
        type="number"
        value={viewModel.totalCapacity?.toString()}
      />
      <FormInput
        label="Количество сидячих мест"
        onChange={(event) => {
          viewModel.numberOfSeats = Number(event.target.value);
        }}
        type="number"
        value={viewModel.numberOfSeats?.toString()}
      />
      <FormInput
        label="Площадь"
        onChange={(event) => {
          viewModel.square = Number(event.target.value);
        }}
        type="number"
        value={viewModel.square}
      />
      <DatePicker
        className="border-1"
        label="Последняя уборка"
        onChange={(value) => {
          viewModel.lastCleaning = value;
        }}
        value={viewModel.lastCleaning}
      />
      <DatePicker
        className="border-1"
        label="Следующая уборка"
        onChange={(value) => {
          viewModel.nextCleaning = value;
        }}
        value={viewModel.nextCleaning}
      />
    </div>
  );
};

export default observer(CreatePlaceForm);
