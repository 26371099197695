import React from 'react';
import { parseStyles } from '@services/utils';
import { useToggle } from 'usehooks-ts';
import Button from '@components/ui/Button';
import IconDescription from './IconDescription';

interface IconsDescriptionProps {
  className?: string;
  variant?: 'resources' | 'project';
  isVisible?: boolean;
}

const Column: React.FC<{children: React.ReactNode}> = ({ children }) => <div className="grid grid-rows-3 gap-1">{children}</div>;

/** Описание иконок графика */
const IconDescriptions: React.FC<IconsDescriptionProps> = ({ className, variant = 'project', isVisible = true }) => {
  const isProject = variant === 'project';
  const [showIcons, toggleShowIcons] = useToggle(isVisible);

  return (
    <div className="flex flex-col">
      <Button.Outline
        className="my-2 w-min self-end"
        label={`${showIcons ? 'Скрыть' : 'Показать'} условные обозначения`}
        onClick={toggleShowIcons}
      />
      {showIcons && (
        <div className={
          parseStyles`
            grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 bg-white
            ${className}
          `
        }
        >
          <Column>
            <IconDescription description="нагрузка за день меньше или равна 8 часам в день">
              <rect
                fill="#b3f7b3"
                height={25}
                stroke="rgba(0, 227, 0, 0.5)"
                strokeWidth={2}
                width={25}
              />
            </IconDescription>
            <IconDescription description="нагрузка за день больше 8 часов в день">
              <rect
                fill="#f7b3b3"
                height={25}
                stroke="rgba(227, 0, 0, 0.5)"
                strokeWidth={2}
                width={25}
              />
            </IconDescription>
            <IconDescription description={
              isProject
                ? 'нагрузка на проекте 4 часа в день'
                : 'нагрузка на проектах 4 часа в день'
            }
            >
              <defs>
                <linearGradient
                  id="MyGradient8-4"
                  x2="0%"
                  y2="100%"
                >
                  <stop
                    offset="0%"
                    stopColor="#e6fce6"
                  />
                  <stop
                    offset="50%"
                    stopColor="#e6fce6"
                  />
                  <stop
                    offset="50%"
                    stopColor="#b3f7b3"
                  />
                  <stop
                    offset="100%"
                    stopColor="#b3f7b3"
                  />
                </linearGradient>
              </defs>
              <rect
                fill="url(#MyGradient8-4)"
                height={25}
                stroke="rgba(0, 227, 0, 0.5)"
                strokeWidth="2"
                width={25}
              />
              <text
                className="select-none pointer-events-none text-font-main"
                dominantBaseline="middle"
                textAnchor="middle"
                x="13"
                y="13"
              >
                4
              </text>
            </IconDescription>
          </Column>
          <Column>
            <IconDescription description="нет нагрузки">
              <rect
                fill="#ffffff"
                height={25}
                stroke="#d4d4d4"
                strokeWidth={2}
                width={25}
              />
            </IconDescription>
            <IconDescription description="нерабочие дни сотрудника">
              <defs>
                <pattern
                  height="4"
                  id="diagonalHatch"
                  patternUnits="userSpaceOnUse"
                  width="4"
                >
                  <rect
                    fill="white"
                    height="4"
                    width="4"
                  />
                  <path
                    d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                    stroke="gray"
                    strokeWidth="2"
                  />
                </pattern>
              </defs>
              <rect
                fill="url(#diagonalHatch)"
                height="25"
                stroke="transparent"
                strokeWidth="2"
                width="25"
                x="0"
                y="0"
              />
            </IconDescription>
            <IconDescription description="нерабочие дни">
              <rect
                fill="#f5f9fc"
                height={25}
                stroke="rgba(235,235,235, 1)"
                strokeWidth={2}
                width={25}
              />
            </IconDescription>
          </Column>
          <Column>
            {isProject && (
              <IconDescription
                description="время активности проекта"
              >
                <defs>
                  <linearGradient
                    id="MyGradientLine"
                    x2="0%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      stopColor="#ffffff"
                    />
                    <stop
                      offset="70%"
                      stopColor="#ffffff"
                    />
                    <stop
                      offset="50%"
                      stopColor="#ffcc80"
                    />
                  </linearGradient>
                </defs>
                <rect
                  fill="url(#MyGradientLine)"
                  height={25}
                  stroke="rgba(206, 206, 206, 0.5)"
                  strokeWidth="2"
                  width={25}
                />
                {/* <div className="w-[25px] h-[25px] border-b-[5px] border-x-1 border-b-[#ffcc80]" /> */}
              </IconDescription>
            )}
            {isProject && (
              <IconDescription description="проект не начат или закончен">
                <defs>
                  <pattern
                    height="8"
                    id="unavailableProjectDes"
                    patternUnits="userSpaceOnUse"
                    width="8"
                  >
                    <rect
                      fill="rgb(255,180,180)"
                      height="8"
                      width="8"
                      x="0"
                      y="0"
                    />
                    <path
                      d="M-2,6 l4,4
            M0,0 l8,8
            M6,-2 l4,4"
                      stroke="lightgray"
                      strokeWidth="2"
                    />
                  </pattern>
                </defs>
                <rect
                  className="fill-[url(#unavailableProjectDes)] unavailable-period"
                  height="25"
                  stroke="black"
                  strokeWidth="1"
                  width="25"
                  x="0"
                  y="0"
                />
              </IconDescription>
            )}
            <IconDescription description="период недоступности ресурса">
              <defs>
                <pattern
                  height="8"
                  id="unavailableHatch"
                  patternUnits="userSpaceOnUse"
                  width="8"
                >
                  <rect
                    fill="rgb(255,150,150)"
                    height="8"
                    width="8"
                    x="0"
                    y="0"
                  />
                  <path
                    d="M-2,2 l4,-4 M0,8 l8,-8 M6,10 l4,-4"
                    stroke="red"
                    strokeWidth="2"
                  />
                </pattern>
              </defs>
              <rect
                className="fill-[url(#unavailableHatch)] unavailable-period"
                height="25"
                stroke="black"
                strokeWidth="1"
                width="25"
                x="0"
                y="0"
              />
            </IconDescription>
          </Column>
        </div>
      )}
    </div>
  );
};

export default React.memo(IconDescriptions);
