import {
GetProjectRequirementEquipmentByIdResponse,
GetProjectRequirementEquipmentsResponse,
PostProjectRequirementEquipmentRequest,
PostProjectRequirementEquipmentResponse,
ProblemDetails,
ProblemDetailsResponse,
PutProjectRequirementEquipmentByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class ProjectRequirementEquipmentService {
  static get RoutePath(): string {
    return "ProjectRequirementEquipment";
  }

  /**
   * Получение потребности проекта в технике по ид
   *
   * @request GET:/api/v1.0/ProjectRequirementEquipment/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetProjectRequirementEquipmentByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление потребности проекта в технике
   *
   * @request PUT:/api/v1.0/ProjectRequirementEquipment/{id}
   */
  static update = async (id: string, data: PutProjectRequirementEquipmentByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка потребностей проектов в технике
   *
   * @request GET:/api/v1.0/ProjectRequirementEquipment
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      types?: string[];
      parameters?: string;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetProjectRequirementEquipmentsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание потребности проекта в технике
   *
   * @request POST:/api/v1.0/ProjectRequirementEquipment
   */
  static create = async (data: PostProjectRequirementEquipmentRequest, params: RequestParams = {}) =>
    httpClient.request<PostProjectRequirementEquipmentResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}