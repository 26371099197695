import { parseStyles } from '@services/utils';
import type { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import type { PropsWithChildren } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import Select from '@components/ui/Select/Select';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { isCustomColumn } from './typeGuards';
import type { CellProps, CellWithSpan, Row } from './types';
import CheckBox from '../CheckBox/CheckBox';

/** Ячейка таблички */
const Cell = <T extends Row, EntityT>({
  column, row, onChange, className, height, index,
}: PropsWithChildren<CellProps<T, EntityT>>) => {
  const isBooleanColumn = column.dataType === 'boolean';
  const isStringColumn = column.dataType === 'string';
  const isNumberColumn = column.dataType === 'number';
  const isDateColumn = column.dataType === 'date';
  const isSelectColumn = column.dataType === 'select';
  const isArray = index !== undefined;
  const readonly = !onChange || column.readonly;
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  useOnClickOutside(inputRef, () => {
    setIsEditing(isSelectColumn);
  });

  if (!isEditing && !isBooleanColumn && !isDateColumn && !isSelectColumn && !isCustomColumn(column)) {
    const value = isArray
      ? (row[column.keyExpr] as CellWithSpan<string | number>[])[index]?.value
      : row[column.keyExpr] as string | number;

    return (
      <div
        className={parseStyles`
          h-full flex items-center justify-start text-left px-3
          ${className}
        `}
        onClick={() => setIsEditing(!column.readonly)}
        onKeyUp={() => setIsEditing(true)}
        role="textbox"
        style={{
          height: height ? `${height}px` : undefined,
        }}
        tabIndex={column.readonly ? -1 : 0}
        title={String(value)}
      >
        <span
          className={parseStyles`
            text-ellipsis overflow-hidden
            ${!!height && 'whitespace-nowrap'}
          `}
        >
          {value}
        </span>
      </div>
    );
  }

  if (isBooleanColumn) {
    return (
      <div
        className={parseStyles`
          h-full w-full flex justify-center content-center overflow-hidden text-ellipsis
          ${className}
      `}
      >
        <CheckBox
          ref={(input) => {
            if (input) {
              input.indeterminate = row[column.keyExpr] == null;
            }
          }}
          checked={isArray
            ? (row[column.keyExpr] as CellWithSpan<boolean | undefined>[])[index]?.value
            : row[column.keyExpr] as boolean | undefined ?? false}
          isDisabled={column.readonly}
          onChange={(val) => {
            if (readonly) {
              return;
            }
            onChange?.(!val);
          }}
        />
      </div>
    );
  }
  if (isStringColumn) {
    return (
      // pl-[1px] для firefox иначе левый бордер пропадает
      <div
        className={parseStyles`
          pl-[1px] overflow-hidden text-ellipsis h-full w-full absolute top-0 
          ${className}
        `}
      >
        <input
          ref={inputRef}
          className="bg-inherit w-full h-full outline-none"
          onChange={(val) => {
            onChange?.(val.currentTarget.value);
          }}
          readOnly={readonly}
          value={isArray
            ? (row[column.keyExpr] as CellWithSpan<string | undefined>[])[index]?.value
            : row[column.keyExpr] as string | undefined ?? ''}
        />
      </div>
    );
  }
  if (isNumberColumn) {
    return (
      <div
        className={parseStyles`
          overflow-hidden text-ellipsis h-full w-full absolute top-0 
          ${className}
        `}
        title={row.errors[column.keyExpr as string]}
      >
        <input
          ref={inputRef}
          className={parseStyles`
            bg-inherit w-full h-full outline-none
          `}
          onChange={(val) => {
            onChange?.(Number(val.currentTarget.value));
          }}
          readOnly={readonly}
          type="number"
          value={isArray
            ? (row[column.keyExpr] as CellWithSpan<number | undefined>[])[index]?.value
            : row[column.keyExpr] as number | undefined}
        />
      </div>
    );
  }
  if (isDateColumn) {
    const value = isArray
      ? (row[column.keyExpr] as CellWithSpan<Dayjs | undefined>[])[index]?.value
      : row[column.keyExpr] as Dayjs | undefined;

    return (
      <div
        className={parseStyles`
          overflow-hidden text-ellipsis h-full w-full absolute top-0 flex flex-col justify-center
          [&>div>div]:border-0
          [&>div>div]:bg-transparent
          [&>div>div>input]:bg-transparent
          [&>div>div>input]:text-left
          ${className}
        `}
        title={row.errors[column.keyExpr as string]}
      >
        <DatePicker
          className={parseStyles`
            ${!value && '[&>input]:text-transparent'}
            [&>input]:cursor-[inherit]
            my-auto
          `}
          height="unset"
          onChange={(val) => onChange?.(val)}
          readonly={column.readonly}
          value={value}
          withTime={column.withTime}
        />
      </div>
    );
  }
  if (isSelectColumn) {
    return (
      <div
        className={parseStyles`
          overflow-hidden text-ellipsis h-full w-full absolute top-0 flex flex-col justify-start
          [&>div]:h-full [&>div>div]:h-full [&>div>div>div]:h-full [&>div>div>div]:bg-transparent
          ${className}
        `}
        title={row.errors[column.keyExpr as string]}
      >
        <Select.Base
          height="100%"
          onSelect={(val) => {
            onChange?.(val?.value);
          }}
          options={column.options}
          selectedOption={column.options?.find((e) => e.value === (isArray
            ? (row[column.keyExpr] as CellWithSpan<string | undefined>[])[index]?.value
            : row[column.keyExpr] as string | undefined))}
        />
      </div>
    );
  }
  return (
    <div
      className={parseStyles`
        h-full w-full
        ${className}
      `}
    >
      <column.template
        data={row}
        index={index}
      />
    </div>
  );
};

export default observer(Cell);
