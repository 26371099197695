import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInterceptChanges, useStores, useConfirm } from '@hooks';
import { useEffectOnce } from 'usehooks-ts';
import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { Select } from '@components/ui/Select';
import { ConfirmQuestions } from 'src/enums';
import Errors from '@components/ui/Errors/Errors';
import { useNavigate } from 'react-router-dom';
import DrawerWithWrapper from '../DrawerResourceLayout/DrawerWithWrapper';
import type { ResourceModalProps } from './types';

/** Форма редактирования и добавления ресурса помещение */
const PlaceResourceForm: React.FC<ResourceModalProps> = ({
  resourceId,
  onClose,
}) => {
  const navigate = useNavigate();
  const {
    placeStore, placesStore,
  } = useStores();
  /** Модалка подтверждения закрытия */
  const { viewModel, isDirty, resetIsDirty } = useInterceptChanges(placeStore.viewModel);
  /** Возможность редактирования */
  const [editing, setEditing] = useState(!resourceId);
  /** Привилегии  */
  const { canUpdateResponsible, canUpdate } = placeStore;
  /** Хук для модалки подтверждения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  useEffectOnce(() => {
    if (resourceId) {
      placeStore.fetch(resourceId);
    }
    return () => placeStore.clearStore();
  });

  /** Хэндлер обработки подтверждения или отмены закрытия модалки */
  const confirmHandler = useMemo(() => {
    if (isDirty) {
      return confirm;
    }
    return undefined;
  }, [confirm, isDirty]);

  /** Обработчик отмены изменений */
  const cancelHandler = useCallback(async () => {
    if (isDirty && viewModel.id) {
      await placeStore.fetch(viewModel.id);
      resetIsDirty();
    }
    setEditing(!editing);
  }, [isDirty, placeStore, resetIsDirty, editing, viewModel.id]);

  /** Футер дровера */
  const footer = useMemo(() => {
    /** Сохранение и создание */
    const changeFormHandler = async () => {
      if (!viewModel.validate()) {
        return;
      }
      await placeStore.save();
      if (canUpdateResponsible) {
        await placeStore.setResponsible();
      }

      if (placeStore.state.isSuccess) {
        placesStore.fetch();
        onClose();
      }
    };

    return (
      <div className="flex">
        {!resourceId && (
          <Button.Primary
            isLoading={placeStore.state.isLoading}
            label="Добавить"
            onClick={changeFormHandler}
          />
        )}
        {!editing && resourceId && canUpdate && (
          <Button.Primary
            isDisabled={!canUpdate}
            isLoading={placeStore.state.isLoading}
            label="Редактировать"
            onClick={() => setEditing(true)}
          />
        )}
        {editing && resourceId && canUpdate && (
          <Button.Primary
            isLoading={placeStore.state.isLoading}
            label="Сохранить"
            onClick={changeFormHandler}
          />
        )}
        {editing && !!resourceId && (
          <Button.Primary
            className="ml-2"
            label="Отменить"
            onClick={cancelHandler}
            type="outline"
          />
        )}
      </div>
    );
  }, [resourceId, placeStore, editing, canUpdate, cancelHandler, viewModel, canUpdateResponsible, placesStore, onClose]);

  const title = useMemo(() => {
    if (!resourceId) {
      return 'Добавление помещения';
    }
    return `Помещение ${viewModel.name ?? ''}${viewModel.isDeleted ? ' (Архивный)' : ''}`;
  }, [resourceId, viewModel.isDeleted, viewModel.name]);

  return (
    <DrawerWithWrapper
      confirm={confirmHandler}
      footer={(!resourceId || canUpdate) ? footer : undefined}
      onClose={onClose}
      title={title}
    >
      <div className="flex flex-col gap-form">
        <FormInput
          disabled={!editing || !canUpdate}
          error={viewModel.errors.name}
          label="Наименование"
          onChange={(e) => {
            viewModel.name = e.target.value;
          }}
          value={viewModel.name}
        />
        <FormInput
          disabled={!editing || !canUpdate}
          label="Назначение"
          onChange={(e) => {
            viewModel.appointment = e.target.value;
          }}
          value={viewModel.appointment}
        />
        <FormInput
          disabled={!editing || !canUpdate}
          error={viewModel.errors.address}
          label="Адрес"
          onChange={(e) => {
            viewModel.address = e.target.value;
          }}
          value={viewModel.address}
        />
        <FormInput
          disabled={!editing || !canUpdate}
          error={viewModel.errors.totalCapacity}
          label="Общая вместимость"
          onChange={(event) => {
            viewModel.totalCapacity = Number(event.target.value);
          }}
          type="number"
          value={viewModel.totalCapacity?.toString()}
        />
        <FormInput
          disabled={!editing || !canUpdate}
          error={viewModel.errors.numberOfSeats}
          label="Количество сидячих мест"
          onChange={(event) => {
            viewModel.numberOfSeats = Number(event.target.value);
          }}
          type="number"
          value={viewModel.numberOfSeats?.toString()}
        />
        <FormInput
          disabled={!editing || !canUpdate}
          label="Площадь"
          onChange={(event) => {
            viewModel.square = Number(event.target.value);
          }}
          type="number"
          value={viewModel.square}
        />
        {resourceId
          ? (
            <Select.Async
              disabled={!editing || !canUpdateResponsible}
              error={viewModel.errors.responsibleFullName}
              label="Ответственный"
              onChange={(search) => placeStore.fetchEmployeeNames(search)}
              onClick={viewModel.responsibleId && placeStore.canReadResponsible
                ? () => navigate(`/resources/employees/${viewModel.responsibleId}`)
                : undefined}
              onSelect={(option) => {
                viewModel.responsible = option;
              }}
              selectedOption={viewModel.responsible}
              clearable
            />
          )
          : null}
        <DatePicker
          className="border-1"
          disabled={!editing || !canUpdate}
          error={viewModel.errors.lastCleaning}
          label="Последняя уборка"
          onChange={(value) => {
            viewModel.lastCleaning = value;
          }}
          value={viewModel.lastCleaning}
        />
        <DatePicker
          className="border-1"
          disabled={!editing || !canUpdate}
          error={viewModel.errors.nextCleaning}
          label="Следующая уборка"
          onChange={(value) => {
            viewModel.nextCleaning = value;
          }}
          value={viewModel.nextCleaning}
        />
      </div>
      <Errors store={placeStore} />
    </DrawerWithWrapper>
  );
};

export default observer(PlaceResourceForm);
