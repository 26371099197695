import { BaseInput } from '@components/ui';
import useDebouncedCallback from '@services/useDebounceCallback';
import type { ListBaseFilter } from '@types';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import type { InputHeaderProps } from './types';

const InputHeader = <EntityT, FilterT extends ListBaseFilter, VmT>({
  store, filterField, placeholder = 'Поиск...',
}: InputHeaderProps<EntityT, FilterT, VmT>) => {
  /** Обработчик ввода */
  const onChangeDebounce = useDebouncedCallback(async () => {
    runInAction(() => {
      store.filter.pageNumber = 1;
    });
    store.fetch();
  }, 400);

  const onChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    runInAction(() => {
      (store.filter[filterField] as string | undefined) = event.target.value;
    });
    onChangeDebounce();
  }, [onChangeDebounce, store.filter, filterField]);

  return (
    <div
      className="flex flex-col items-center w-full h-full justify-center py-1"
    >
      <div className="w-full px-1">
        <BaseInput
          className="border-1 bg-white py-1 mx-1 [&>input]:h-[22.5px]"
          height=""
          onChange={onChangeInput}
          placeholder={placeholder}
          value={store.filter[filterField]?.toString()}
        />
      </div>
    </div>
  );
};

InputHeader.displayName = 'InputHeader';

export default observer(InputHeader);
