import { useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import type { RefObject } from 'react';
import React, {
  useCallback,
  useMemo,
  startTransition,
  useEffect,
  useState,
} from 'react';
import type { ResourcesLoadStore } from 'src/stores/ResourcesLoad/ResourcesLoadStore';
import ResourceRow from '../Data/ResourceRow';
import type { DayData } from '../types';
import AboutDayPopup from '../Modals/AboutDayPopup';

export interface ResourcesLoadDayData {
  index: number;
  text: string;
  total: number;
  weekend: boolean;
}

export interface ResourceData {
  rowIndex: number;
  resourceName: string;
  resourceId: string;
  days: ResourcesLoadDayData[];
}

export interface DataProps {
  verticalContainerRef: RefObject<HTMLDivElement>;
}

export interface ResourcesLoadCellData {
  cell: SVGElement;
  resourceId: string;
  resourceName: string;
  day: ResourcesLoadDayData;
}

interface MemoizedResourceRowProps {
  val: (ResourcesLoadStore['visibleResources'])[number];
}

const Data: React.FC<DataProps> = () => {
  const [hoveredCellData, setHoveredCellData] = useState<ResourcesLoadCellData & { rowIndex: number; shown: boolean } | null>(null);
  const [hoveredPopup, setHoveredPopup] = useState<HTMLDivElement | null>(null);
  const [shownPopupCellData, setShownPopupCellData] = useState<ResourcesLoadCellData & { rowIndex: number; shown: boolean } | null>(null);
  const [visibleRows, setVisibleRows] = useState<ResourcesLoadStore['visibleResources']>([]);
  const { resourcesLoadStore } = useStores();

  const hoveredDayData = useMemo(
    () => {
      if (!shownPopupCellData) {
        return null;
      }
      const resource = resourcesLoadStore.preparedResources.at(shownPopupCellData.rowIndex);

      const totalLoad = resourcesLoadStore.rowsDayToLoadMap.at(shownPopupCellData.rowIndex!)!.get(shownPopupCellData.day.index);

      return {
        resource, totalLoad, day: shownPopupCellData.day,
      };
    },
    [resourcesLoadStore.preparedResources, resourcesLoadStore.rowsDayToLoadMap, shownPopupCellData],
  );

  useEffect(() => {
    startTransition(() => {
      setVisibleRows(resourcesLoadStore.visibleResources);
    });
  }, [resourcesLoadStore.visibleResources]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShownPopupCellData((prev) => {
        if (hoveredPopup) {
          return prev;
        }
        if (!hoveredCellData) {
          return null;
        }
        return { ...hoveredCellData, shown: true };
      });
    }, hoveredCellData ? 400 : 200);

    return () => clearTimeout(timeout);
  }, [hoveredCellData, hoveredPopup]);

  const periods = useMemo(() => [], []);

  const MemoizedResourceRow = useCallback<React.FC<MemoizedResourceRowProps>>(
    ({ val }) => (
      <ResourceRow
        columnWidth={resourcesLoadStore.columnWidth}
        days={val.days}
        onCellHover={(e: { cell: SVGElement; day: DayData } | null): void => setHoveredCellData(e ? {
          ...e,
          rowIndex: val.rowIndex,
          shown: false,
          resourceId: val.resourceId,
          resourceName: val.resourceName,
        } : null)}
        periods={periods}
        rowHeight={resourcesLoadStore.rowHeight}
        rowIndex={val.rowIndex}
        startDate={resourcesLoadStore.startDate}
        unavailableResources={val.unavailableResources}
        unitForDayJS={resourcesLoadStore.unitForDayJS}
      />
    ),
    [periods, resourcesLoadStore.columnWidth, resourcesLoadStore.rowHeight, resourcesLoadStore.startDate, resourcesLoadStore.unitForDayJS],
  );

  return (
    <g>
      <g className="periods">
        {visibleRows.map((el) => (
          <g
            key={`period${el.resourceId}`}
            id={`period${el.resourceId}`}
          >
            <MemoizedResourceRow val={el} />
          </g>
        ))}
      </g>

      {shownPopupCellData && shownPopupCellData.cell && shownPopupCellData.shown && hoveredDayData && (
        <AboutDayPopup
          fields={[
            { label: 'ФИО', value: hoveredDayData.resource?.resourceName ?? '' },
            { label: 'Дата', value: shownPopupCellData.day.text },
            { label: 'Всего часов в этот день', value: String(hoveredDayData.totalLoad?.load) },
          ]}
          setHoveredPopup={setHoveredPopup}
          targetRef={shownPopupCellData.cell}
        />
      )}
    </g>
  );
};

export default observer(Data);
