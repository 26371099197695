import {
ProblemDetails,
ProblemDetailsResponse} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class PaymentService {
  static get RoutePath(): string {
    return "Payment";
  }

  /**
   * Endpoint для получения статуса транзакции
   *
   * @request POST:/api/v1.0/Payment/Status
   */
  static statusCreate = async (data: any, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Status`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}