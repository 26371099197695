import React from 'react';
import type { SelectOption } from '@components/ui/Select';
import { DayOfWeek } from '@typesApi';
import CryptoJS from 'crypto-js';
import mime from 'mime';

/** Получение кастомного имени токена */
export const tokenName = process.env.REACT_APP_TOKEN_NAME || 'token';
/** Получение кастомного имени рефреш-токена */
export const refreshTokenName = process.env.REACT_APP_REFRESH_TOKEN_NAME || 'refreshToken';
/** Получение кастомного имени токена */
export const expiresAtName = process.env.REACT_APP_EXPIRES_AT_NAME || 'expiresAt';
/** Получение токена капчи */
export const captchaSecret = process.env.REACT_APP_CAPTCHA_SECRET;
/** Максимальный размер файлов */
export const MAX_FILE_SIZE = 10_485_760;
/** Хеш архива */
export const archiveHash = '#archive';

/** Функция получения токена из памяти сессии */
export const getToken = () => localStorage.getItem(tokenName);

/** Загрузка токена в память сессии */
export const setTokenToStorage = (
  token: string | null = null,
  refreshToken: string | null = null,
  expiresAt: string | null = null,
) => {
  localStorage.setItem(tokenName, JSON.stringify(token));
  localStorage.setItem(refreshTokenName, JSON.stringify(refreshToken));
  localStorage.setItem(expiresAtName, JSON.stringify(expiresAt));
  // Для хука useLocalStorage from 'usehooks-ts'
  window.dispatchEvent(new Event('local-storage'));
};

/**
 * Шаблон форматирования стилей tailwind
 *
 * @example пример использования:
 *    `
 *    w-full p-[10px] pl-[15px]
 *    cursor-pointer transition-none text-left rounded-md
 *    ${isHovered || isSelected ? 'bg-option-arrow-hover' : 'bg-option'}
 *    `
 *  при добавлении parseStyles`...`
 *  будет приведено к `w-full p-[10px] pl-[15px] cursor-pointer transition-none text-left rounded-md bg-option-arrow-hover`
 */
export function parseStyles(strings: TemplateStringsArray, ...exprResults: (string | boolean | undefined)[]) {
  const rawStyles = strings
    .flatMap((x) => x.split('\n'))
    .flatMap((x) => x.split(' '))
    .map((x) => x.trim())
    .filter(Boolean);
  const rawExprResults = exprResults.filter(Boolean);

  return [...rawStyles, ...rawExprResults].join(' ');
}

// #region generate Code verifier for OAUTH2
// https://stackoverflow.com/questions/63309409/creating-a-code-verifier-and-challenge-for-pkce-auth-on-spotify-api-in-reactjs
function dec2hex(dec: number) {
  return (`0${dec.toString(16)}`).substr(-2);
}

export function generateCodeVerifier() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}
// #endregion

// #region Generate code challenge from code verifier
export async function generateCodeChallengeFromVerifier(v: string) {
  return CryptoJS.SHA256(v).toString(CryptoJS.enc.Base64url);
}
// #endregion

/** Шаблоны RegExp */
export const RegexPatterns = {
  // это чудо-юдо отсюда: http://emailregex.com/
  // eslint-disable-next-line max-len
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^\+7\(\d{3}\)-\d{3}-\d{2}-\d{2}$/,
  inn: /^\d{10,12}$/,
};
export function getSetters(viewModel: Object) {
  return Object.entries(Object.getOwnPropertyDescriptors(Object.getPrototypeOf(viewModel)))
    ?.filter(([_, val]) => val.set);
}

/** Сериалайзер запроса для Axios */
// eslint-disable-next-line max-len
export const paramsSerializer = (request: Record<string, unknown>): string => Object.entries(request)
  .filter(([key, value]) => {
    if (value === false) {
      return !!key;
    }

    return key && value;
  })
  .map(([key, value]) => {
    if (Array.isArray(value)) {
      return value.reduce<string>((acc, el, currentIndex) => {
        if (currentIndex === 0) {
          return `${key}=${el}`;
        }
        return `${acc}&${key}=${el}`;
      }, '');
    }
    return `${key}=${value}`;
  })
  .reduce<string>((acc, el, currentIndex) => {
  if (currentIndex === 0) {
    return el;
  }
  return `${acc}&${el}`;
}, '');

/** Соединение элементов массива в строку  */
export function joinNonEmpty(arr: unknown[], separator: string = ' '): string {
  return arr.filter(Boolean).join(separator);
}

/** Выбор файла */
export function chooseFile(setFile: (file: File) => void, accept?: string[], onTypeError?: () => void): void {
  const input = document.createElement('input');
  input.type = 'file';
  if (accept) {
    input.accept = accept.join(', ');
  }
  input.onchange = (e) => {
    const file = (e.target as HTMLInputElement)?.files?.[0];
    if (file) {
      if (accept?.length) {
        const fileType = mime.getType(file.name);
        if (!fileType || !accept?.some((format) => new RegExp(format).test(fileType))) {
          onTypeError?.();
          return;
        }
      }
      setFile(file);
    }
  };
  input.click();
}

/** разные слова в зависимости от количества */
export function pluralize(number: number, one: string, two: string, five?: string): string {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five ?? two;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five ?? two;
}
const dayOfWeekEnumToIndexMap = new Map(Object.values(DayOfWeek).map((e, i) => [e, i]));

export function dayOfWeekEnumToNumber(val: DayOfWeek): number {
  return dayOfWeekEnumToIndexMap.get(val)!;
}

export const periods: SelectOption[] = [
  { label: '3 месяца', value: '3' },
  { label: '6 месяцев', value: '6' },
  { label: 'Год', value: '12' },
];

/** Функция отображения ошибки */
export function getListErrors(error?: string, splitCharacter = '. ') {
  const errors = error?.split(splitCharacter).filter(Boolean);

  if (errors?.length === 1) {
    return error;
  }

  return errors?.map((err) => (
    <li key={err}>{err}</li>
  ));
}

// для работы ресайза в модалках. т.к. bounding box для fixed является первый родитель с transform
export function findParentWithTransform(element: HTMLElement): HTMLElement | null {
  let parent = element.parentElement;

  while (parent) {
    const transformStyle = window.getComputedStyle(parent).transform;

    if (transformStyle && transformStyle !== 'none') {
      return parent;
    }

    parent = parent.parentElement;
  }

  return null;
}

export function getFocusableElements(context: HTMLElement | null | Document): HTMLElement[] {
  return Array.from(context?.querySelectorAll(
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
  ) || []).filter(
    (el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'),
  ) as HTMLElement[];
}

export function isCurrentWidthLessThanFullWidth(element: HTMLElement) {
  const elementWidth = Math.ceil(element.getBoundingClientRect().width);
  element.classList.remove('truncate');
  element.classList.add('whitespace-nowrap');
  const elementFullSizeWidth = Math.ceil(element.getBoundingClientRect().width);
  element.classList.remove('whitespace-nowrap');
  element.classList.add('truncate');
  return elementWidth < elementFullSizeWidth;
}
