import { AiOutlineEdit, AiOutlineSave, AiOutlineUserAdd } from 'react-icons/ai';
import React from 'react';
import { MdAddCircleOutline, MdDelete } from 'react-icons/md';
import {
  BiArchive,
  BiArchiveIn,
  BiArchiveOut,
  BiArrowBack,
} from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';

const ButtonIcons = {
  Add: <MdAddCircleOutline />,
  Edit: <AiOutlineEdit />,
  Delete: <MdDelete />,
  Archive: <BiArchive />,
  ArchiveIn: <BiArchiveIn />,
  ArchiveOut: <BiArchiveOut />,
  Save: <AiOutlineSave />,
  AddUser: <AiOutlineUserAdd />,
  Cancel: <TiCancel />,
  Back: <BiArrowBack />,
};

export default ButtonIcons;
