import { WorkingPeriodModal } from '@components/CalendarTable/Modals';
import { Table } from '@components/ui';
import Button from '@components/ui/Button';
import { Select } from '@components/ui/Select';
import type { SelectOption } from '@components/ui/Select/types';
import { Column } from '@components/ui/Table';
import Title from '@components/ui/Title/Title';
import {
  useConfirm,
  useInterceptChanges,
  useSelectOptions,
  useStores,
} from '@hooks';
import type { Period } from '@types';
import type { GetResourceInProjectByIdResponseLoadResourceInProject } from '@typesApi';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { MdDelete } from 'react-icons/md';
import type { LoadResourceInProjectFields } from 'src/stores/ResourceInProject/LoadResourceInProjectsItemViewModel';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { ConfirmQuestions } from 'src/enums';
import Errors from '@components/ui/Errors/Errors';
import { ResourceService } from '@servicesApi';
import DrawerWithWrapper from '../DrawerResourceLayout/DrawerWithWrapper';
import type { BaseEmployeeCardShareProps } from './types';

/** Общая информация (форма добавления проекта) */
const ProjectForm: FC<BaseEmployeeCardShareProps> = ({
  employeeId, onSave, onClose, resourceInProjectId,
}) => {
  const {
    projectNamesStore, employeeInProjectStore, projectRolesNamesStore, resourceLoadStore, employeeStore,
  } = useStores();
  const { viewModel, isDirty } = useInterceptChanges(employeeInProjectStore.viewModel);
  const { loadResourceInProjectStore } = viewModel;
  const [showAddLoad, toggleShowAddLoad] = useToggle();

  /** Модалка подтверждения выхода без сохранения */
  const confirmExit = useConfirm(ConfirmQuestions.confirmExit);

  /** Модалка подтверждения удаления нагрузки */
  const confirmDeleteEmployee = useConfirm(ConfirmQuestions.deleteEmployee);

  /** Обработчик закрытия модалки */
  const confirmCloseHandler = useMemo(() => {
    if (isDirty) {
      return confirmExit;
    }
    return undefined;
  }, [confirmExit, isDirty]);

  /** Добавление нагрузки */
  const saveLoadHandler = useCallback(async (data?: Period) => {
    if (data && resourceInProjectId) {
      await loadResourceInProjectStore.addLoad(resourceInProjectId, data);
      if (loadResourceInProjectStore.state.isSuccess && viewModel.id) {
        await Promise.all([employeeInProjectStore.fetch(viewModel.id), resourceLoadStore.reload()]);
      }
    }
    toggleShowAddLoad();
  }, [employeeInProjectStore, loadResourceInProjectStore, resourceInProjectId, resourceLoadStore, toggleShowAddLoad, viewModel.id]);

  useEffectOnce(() => {
    if (resourceInProjectId) {
      employeeInProjectStore.fetch(resourceInProjectId);
    }
    return () => employeeInProjectStore.clearStore();
  });

  /** Поиск проекта */
  const searchProjectNames = useCallback(async (search?: string): Promise<SelectOption[] | undefined> => {
    await projectNamesStore.fetchProjectsNames(search, employeeStore.viewModel.id);
    const [options] = useSelectOptions([projectNamesStore.namesList ?? []]);

    return options;
  }, [employeeStore.viewModel.id, projectNamesStore]);

  const onSaveHandler = useCallback(async () => {
    if (employeeId) {
      runInAction(() => {
        employeeInProjectStore.viewModel.resourceId = employeeId;
      });
      await employeeInProjectStore.save();
      await employeeStore.fetch(employeeId);
      await resourceLoadStore.reload();
    }
    onSave?.();
    onClose();
  }, [employeeId, onSave, onClose, employeeInProjectStore, employeeStore, resourceLoadStore]);

  /** Изменение ролей пользователя */
  const changeRolesHandler = useCallback((options?: SelectOption[]) => {
    viewModel.projectRoles = options;
  }, [viewModel]);

  /** Удаление нагрузки */
  const rowDeleteHandler = useCallback<React.FC<{data: LoadResourceInProjectFields}>>(
    ({ data }) => (
      <div
        className="flex w-full h-full items-center justify-center cursor-pointer"
        onClick={async () => {
          if (viewModel.id) {
            await loadResourceInProjectStore.deleteLoad(viewModel.id, data.startDate, data.finishDate);
            await Promise.all([employeeInProjectStore.fetch(viewModel.id), resourceLoadStore.reload()]);
          }
        }}
      >
        <MdDelete />
      </div>
    ),
    [employeeInProjectStore, loadResourceInProjectStore, resourceLoadStore, viewModel.id],
  );

  /** Хэндлер удаления сотрудника */
  const deleteEmployeeHandler = useCallback(async () => {
    const confirmResult = await confirmDeleteEmployee();
    if (confirmResult && resourceInProjectId) {
      await ResourceService.resourceInProjectDelete(resourceInProjectId);
      onClose();
      await employeeStore.fetch(employeeId);
    }
  }, [confirmDeleteEmployee, employeeId, employeeStore, onClose, resourceInProjectId]);

  return (
    <DrawerWithWrapper
      confirm={confirmCloseHandler}
      footer={(
        <div className="flex flex-1 gap-2 justify-between">
          {resourceInProjectId && (
            <Button.Primary
              label="Удалить сотрудника с проекта"
              onClick={deleteEmployeeHandler}
              type="danger"
            />
          )}
          <Button.Primary
            className="ml-auto"
            label="Сохранить"
            onClick={onSaveHandler}
          />
        </div>
      )}
      onClose={onClose}
      title="Добавление сотрудника на проект"
    >
      <div className="flex flex-col gap-form">
        <Select.Async
          disabled={!!resourceInProjectId}
          label="Проект"
          onChange={searchProjectNames}
          onSelect={(option) => {
            viewModel.projectName = option?.label;
            viewModel.projectId = option?.value;
          }}
          selectedOption={viewModel.projectAsSelectOption}
        />
        <Select.AsyncMulti
          error={viewModel.errors.projectRoles}
          label="Роли на проекте"
          onChange={(search) => projectRolesNamesStore.fetchProjectRoles(search)}
          onSelectOption={changeRolesHandler}
          placeholder="Выберите роли"
          selectedOptions={viewModel.projectRoles}
        />
        {viewModel.id && (
          <>
            <div className="flex items-center flex-row justify-between mb-4">
              <Title isSubtitle>Нагрузки на проекте</Title>
              {!!viewModel.selectedEmployee?.value && (
                <Button.Primary
                  label="Добавить нагрузку"
                  onClick={toggleShowAddLoad}
                />
              )}
            </div>
            <Table
              rowHeight={30}
              store={loadResourceInProjectStore}
            >
              <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
                dataType="date"
                header="Дата начала"
                keyExpr="startDate"
              />
              <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
                dataType="date"
                header="Дата окончания"
                keyExpr="finishDate"
              />
              <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
                dataType="number"
                header="Нагрузка"
                keyExpr="workingHoursPerDay"
              />
              <Column<LoadResourceInProjectFields, GetResourceInProjectByIdResponseLoadResourceInProject>
                dataType="custom"
                header=""
                template={rowDeleteHandler}
                width={50}
              />
            </Table>
          </>
        )}
        {showAddLoad && <WorkingPeriodModal onClose={saveLoadHandler} />}
        <Errors store={employeeInProjectStore} />
      </div>
    </DrawerWithWrapper>
  );
};

export default observer(ProjectForm);
