import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import type { SelectOption } from '@components/ui/Select';
import { Select } from '@components/ui/Select';
import { useConfirm, useInterceptChanges, useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { ConfirmQuestions } from 'src/enums';
import Errors from '@components/ui/Errors/Errors';
import { useNavigate } from 'react-router-dom';
import DrawerWithWrapper from '../DrawerResourceLayout/DrawerWithWrapper';

const undefinedOption = { label: 'Не выбрано', value: '' };
const yesOption = { label: 'Да', value: 'true' };
const noOption = { label: 'Нет', value: 'false' };

const EmployeeCreateForm: React.FC<{onClose: () => void}> = ({ onClose }) => {
  /** Стор сотрудника, сотрудников */
  const { employeeStore } = useStores();
  /** Модель, состояние измененности */
  const { viewModel, isDirty } = useInterceptChanges(employeeStore.viewModel);
  /** Реф для модалки подтверждения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  useEffectOnce(() => () => employeeStore.clearStore());

  const navigate = useNavigate();

  /** Обработчик создания сотрудника */
  const createEmployeeHandler = useCallback(async () => {
    if (viewModel.validate()) {
      const id = await employeeStore.addEmployee();

      if (employeeStore.state.isSuccess) {
        onClose();
        navigate(`/resources/employees/${id}`);
      }
    }
  }, [employeeStore, onClose, viewModel, navigate]);

  /** Кнопки футера */
  const footer = useMemo(() => (
    <Button.Primary
      isLoading={employeeStore.state.isLoading}
      label="Добавить"
      onClick={createEmployeeHandler}
    />
  ), [createEmployeeHandler, employeeStore.state.isLoading]);

  const selectedOptionForIsRemoteWork = useMemo(() => {
    switch (employeeStore.viewModel.isRemoteWork) {
      case true: return yesOption;
      case false: return noOption;
      default: return undefinedOption;
    }
  }, [employeeStore.viewModel.isRemoteWork]);

  const onSelectRemoteWorkSelect = useCallback((option: SelectOption | undefined) => {
    let val: boolean | null;

    switch (option?.value) {
      case 'true': val = true; break;
      case 'false': val = false; break;
      default: val = null;
    }

    employeeStore.viewModel.isRemoteWork = val;
  }, [employeeStore.viewModel]);

  const selectedOptionsForIsRemoteWork = useMemo(() => [undefinedOption, yesOption, noOption], []);

  return (
    <DrawerWithWrapper
      confirm={isDirty ? confirm : undefined}
      footer={footer}
      onClose={onClose}
      title="Добавление сотрудника"
    >
      <div className="flex flex-col gap-form">
        <FormInput
          error={viewModel.errors.surname}
          invalid={!!viewModel.errors.surname}
          label="Фамилия"
          onChange={(event) => {
            viewModel.surname = event.target.value;
          }}
          value={viewModel.surname}
        />
        <FormInput
          error={viewModel.errors.name}
          invalid={!!viewModel.errors.name}
          label="Имя"
          onChange={(event) => {
            viewModel.name = event.target.value;
          }}
          value={viewModel.name}
        />
        <FormInput
          label="Отчество"
          onChange={(event) => {
            viewModel.patronymic = event.target.value;
          }}
          value={viewModel.patronymic}
        />
        <DatePicker
          label="Дата рождения"
          onChange={(value) => {
            viewModel.birthdate = value;
          }}
          value={viewModel.birthdate}
        />
        <Select.Catalog
          catalogIdField="mainSpecializationId"
          catalogName="Specialization"
          catalogNameField="mainSpecializationName"
          label="Специализация"
          viewModel={viewModel}
        />
        <Select.Catalog
          catalogIdField="mainPositionId"
          catalogName="Position"
          catalogNameField="mainPositionName"
          label="Должность"
          viewModel={viewModel}
        />
        <Select.Catalog
          catalogIdField="mainQualificationId"
          catalogName="Qualification"
          catalogNameField="mainQualificationName"
          label="Грейд"
          viewModel={viewModel}
        />
        <Select.Base
          label="Удалённая работа"
          onSelect={onSelectRemoteWorkSelect}
          options={selectedOptionsForIsRemoteWork}
          selectedOption={selectedOptionForIsRemoteWork}
        />
        <Select.Async
          label="Наставник"
          onChange={(val) => employeeStore.fetchEmployeeNames(val)}
          onSelect={(option) => {
            viewModel.mentorId = option?.value;
            viewModel.mentorName = option?.label;
          }}
          selectedOption={viewModel.mentorId && viewModel.mentorName
            ? ({ label: viewModel.mentorName, value: viewModel.mentorId })
            : undefined}
        />
        <FormInput
          error={viewModel.errors.email}
          invalid={!!viewModel.errors.email}
          label="Электронная почта"
          onChange={(event) => {
            viewModel.email = event.target.value;
          }}
          value={viewModel.email}
        />
        <FormInput
          error={viewModel.errors.phone}
          invalid={!!viewModel.errors.phone}
          label="Телефон"
          mask="+7(___)-___-__-__"
          onChange={(e) => {
            viewModel.phone = e.target.value;
          }}
          value={viewModel.phone}
        />
      </div>
      <Errors store={employeeStore} />
    </DrawerWithWrapper>
  );
};

export default observer(EmployeeCreateForm);
