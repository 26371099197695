import { findParentWithTransform, parseStyles } from '@services/utils';
import type { ListBaseFilter } from '@types';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { Key, PropsWithChildren } from 'react';
import React, {
  useMemo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import ReactDom from 'react-dom';
import { isCustomColumn, isGroupColumn } from '../typeGuards';
import type { Column, HeaderProps, Row } from '../types';
import { getColumnKey } from '../utils';
import HeaderCell from './HeaderCell';
import ResizeBar from './ResizeBar';

function columnChildrenCount<T extends Row, EntityT >(column: Column<T, EntityT>): number {
  if (isGroupColumn(column)) {
    return column.columns.reduce<number>((acc, cur) => acc + columnChildrenCount(cur), 0);
  }
  return 1;
}

/** хедер таблицы */
const Header = <RowT extends Row, EntityT, FilterT extends ListBaseFilter>({
  levelsOfColumns, storeFilter, onSortChanged: sortChanged, onWidthsChanged, widths, allColumns, className, reorder,
}: PropsWithChildren<HeaderProps<RowT, EntityT, FilterT>>) => {
  const leavesColumns = useRef<(HTMLDivElement | null)[]>(Array.from({ length: allColumns.length }));
  const [resizeWidths, setResizeWidths] = useState<(number | undefined)[]>(Array.from(
    { length: allColumns.length },
    (_el, index) => allColumns[index].width,
  ));
  const [columnKeys, setColumnKeys] = useState<(Key | undefined)[]>(allColumns.map((e) => getColumnKey(e)));
  const [reorderColumn, setReorderColumn] = useState<{leafIndex: number; index: number; startX: number; startY: number} | null>(null);
  const [selectedReorder, setSelectedReorder] = useState<number| null>(null);

  const memoizedWidths = useMemo(() => [...widths], [widths]);

  const updateWidths = useCallback(
    () => {
      const curWidths = leavesColumns.current?.map((e) => e?.getBoundingClientRect().width);
      if (curWidths && (widths.some((val, index) => curWidths?.[index] !== val) || widths.length !== curWidths.length)) {
        onWidthsChanged(curWidths);
      }
    },
    [onWidthsChanged, widths],
  );

  // магия с массивом ключей колонок для того, чтобы при изменении массива колонок, уже применённые ресайзы
  // сохранялись и оставались на тех колонках, к которым были применены
  useLayoutEffect(() => {
    if (columnKeys.length === allColumns.length && allColumns.every((e, i) => getColumnKey(e) === columnKeys[i])) {
      return;
    }
    setResizeWidths((prevWidths) => Array.from(
      { length: allColumns.length },
      (_el, index) => (columnKeys[index] && (getColumnKey(allColumns[index]) === columnKeys[index])
        ? prevWidths[index]
        : allColumns[index].width),
    ));
    leavesColumns.current = leavesColumns.current.slice(0, allColumns.length);
    updateWidths();
    setColumnKeys(allColumns.map((e) => getColumnKey(e)));
  }, [allColumns, columnKeys, updateWidths]);

  const ref = useRef<HTMLDivElement>(null);
  const reorderRef = useRef<HTMLDivElement>(null);

  const ResizeBarWrapper = useCallback<React.FC< {index: number; isLastColumn: boolean}> >(
    ({ index, isLastColumn }) => {
      const resize = useCallback(
        (x: number) => {
          const newWidths = [...resizeWidths];
          newWidths[index] = Math.min(
            Math.max(
              55,
              x - (leavesColumns.current[index]?.getBoundingClientRect().x ?? 0),
              allColumns[index].minWidth ?? -Infinity,
            ),
            allColumns[index].maxWidth ?? Infinity,
          );
          setResizeWidths(newWidths);
        },
        [index],
      );
      const left = (memoizedWidths.slice(0, index + 1).reduce((a, b) => (a ?? 0) + (b ?? 0)) ?? 0) - (isLastColumn ? 5 : 3);

      return (
        <ResizeBar
          isLastColumn={isLastColumn}
          left={left}
          onResize={resize}
        />
      );
    },
    [allColumns, memoizedWidths, resizeWidths],
  );

  useEffect(() => {
    window.addEventListener('resize', updateWidths);

    return () => {
      window.removeEventListener('resize', updateWidths);
    };
  }, [updateWidths]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(updateWidths);
    resizeObserver.observe(ref.current!);
    return () => {
      resizeObserver.disconnect();
    };
  });

  /** индексы undefined колонок, которые запинены для прорисовки следующего уровня хедеров  */
  let lastUndefinedPinnedColumnsIndexes: number[] = [];

  useEffect(() => {
    const cb = (e: MouseEvent) => {
      if (!reorderRef.current) {
        return;
      }
      const boundingBoxLeft = (reorderRef.current && findParentWithTransform(reorderRef.current)?.getBoundingClientRect().x) ?? 0;
      reorderRef.current.style.left = `${e.clientX - boundingBoxLeft - 10}px`;
      reorderRef.current.style.top = `${e.clientY - 10}px`;
    };

    if (reorderColumn) {
      window.addEventListener('mousemove', cb);
    }
    return () => {
      window.removeEventListener('mousemove', cb);
    };
  }, [reorderColumn]);

  useEffect(() => {
    const cancel = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setReorderColumn(null);
      }
    };

    if (reorderColumn) {
      window.addEventListener('keydown', cancel);
    }
    return () => {
      window.removeEventListener('keydown', cancel);
    };
  }, [reorderColumn]);

  useEffect(() => {
    const removeCb = () => {
      setReorderColumn(null);
      setSelectedReorder(null);
      if (selectedReorder != null) {
        reorder?.(reorderColumn!.index, selectedReorder);
      }
    };

    if (reorderColumn) {
      window.addEventListener('mouseup', removeCb);
    }

    return () => {
      window.removeEventListener('mouseup', removeCb);
    };
  }, [reorder, reorderColumn, reorderColumn?.index, selectedReorder]);

  return (
    <div
      ref={ref}
      className={parseStyles`
        grid w-fit flex-shrink-0 sticky top-0 z-20 min-w-full
        ${className}
      `}
      style={{
        // Array.from для того чтобы при первом рендере, когда ещё не обновился стейт resizeWidths не дёргалась табличка
        gridTemplateColumns: Array.from({ length: allColumns.length }, (_, i) => resizeWidths[i])
          .map((e) => (e ? `${e}px` : 'minmax(auto, 1fr)')).join(' '),
        gridTemplateRows: `repeat(${levelsOfColumns.length},auto)`,
      }}
    >
      {levelsOfColumns.map((e, index) => {
        let undefinedColumnCount = 0;
        let nonGroupsCount = 0;
        let pinnedColumnsBefore = 0;
        const lastUndefinedPinnedColumnsIndexesCopy = [...lastUndefinedPinnedColumnsIndexes];
        lastUndefinedPinnedColumnsIndexes = [];

        return e.map((col, colIndex) => {
          const leafIndex = e.slice(0, colIndex).reduce<number>((acc, cur) => {
            const colspan = cur ? columnChildrenCount(cur) : 1;

            return acc + colspan;
          }, 0);
          if (!col) {
            if (lastUndefinedPinnedColumnsIndexesCopy.includes(undefinedColumnCount)) {
              const width = memoizedWidths.slice(leafIndex, leafIndex + 1).reduce<number>((a, b) => (a ?? 0) + (b ?? 0), 0);
              pinnedColumnsBefore += width;
              lastUndefinedPinnedColumnsIndexes.push(undefinedColumnCount + nonGroupsCount);
            }
            undefinedColumnCount++;

            return null;
          }

          const isGroup = isGroupColumn(col);
          const sortExpr = col.sortExpr ?? (isGroup || isCustomColumn(col) ? undefined : col.keyExpr);
          const sortable = col.sortable || !!col.sortExpr;
          const currentColumnSorting = sortable && storeFilter?.orderBy && sortExpr === storeFilter?.orderBy;
          const columnIndex = allColumns.findIndex((el) => (el.key ?? el.header) === (col.key ?? col.header));
          const resizable = col.resizable ?? true;
          const width = memoizedWidths.slice(leafIndex, leafIndex + columnChildrenCount(col)).reduce<number>((a, b) => (a ?? 0) + (b ?? 0), 0);

          if (sortable && !sortExpr) {
            console.warn(`Не указан sortExpr у колонки номер ${columnIndex}`);
          }

          if (col.pinned) {
            pinnedColumnsBefore += width;
            if (!isGroup) {
              lastUndefinedPinnedColumnsIndexes.push(nonGroupsCount + undefinedColumnCount);
            }
          }

          if (!isGroup) {
            nonGroupsCount++;
          }

          return (
            <React.Fragment key={getColumnKey(col) ?? colIndex}>
              <div
                ref={(r) => {
                  if (!isGroup) {
                    leavesColumns.current[columnIndex] = r;
                  }
                }}
                className={parseStyles`
                  select-none
                  flex justify-center
                  bg-table-header
                  text-table-header-text  
                  border-r-table-border-color border-b-table-border-color border-b-table
                  w-full h-auto p-0 box-border
                  text-center
                  ${sortable ? `
                    cursor-pointer
                    hover:after:content-[''] hover:after:bg-headerHovered hover:after:w-full
                    hover:after:h-full hover:after:absolute hover:after:top-0 hover:after:left-0
                  ` : undefined}
                  ${col.pinned ? 'sticky left-0' : 'relative'}
                  ${colIndex === e.length - 1 ? 'border-r-0' : 'border-r-table'}
                `}
                onClick={() => {
                  if (storeFilter && sortable) {
                    runInAction(() => {
                      if (storeFilter?.orderBy !== sortExpr) {
                        storeFilter.orderBy = sortExpr;
                        storeFilter.isAscending = true;
                      } else {
                        storeFilter.isAscending = !storeFilter?.isAscending;
                      }
                      storeFilter.pageNumber = 1;
                    });
                    sortChanged();
                  }
                }}
                style={{
                  gridColumn: `span ${columnChildrenCount(col!)}`,
                  gridRow: `span ${isGroup ? 1 : levelsOfColumns.length - index}`,
                  left: col.pinned ? pinnedColumnsBefore - width : undefined,
                  minWidth: isGroup ? undefined : col.minWidth,
                  maxWidth: isGroup ? undefined : col.maxWidth,
                  zIndex: (col.pinned ? 30 : 10) + levelsOfColumns.length - index,
                }}
              >
                <HeaderCell
                  column={col}
                  isAscending={currentColumnSorting ? storeFilter?.isAscending : undefined}
                  onFilterPress={isGroup ? undefined : col.onFilterChanged}
                  onReorderPress={(index === 0 && (col.canReorder ?? true)) ? (startX, startY) => {
                    setReorderColumn({
                      index: colIndex, leafIndex, startX, startY,
                    });
                  } : undefined}
                />
                {reorderColumn && index === 0 && (col.canReorder ?? true) && (
                  <>
                    <div
                      className="w-[51%] absolute left-0 z-[90] [&:hover>div]:border-label [&:hover>div]:border-1"
                      onMouseEnter={() => {
                        setSelectedReorder(colIndex);
                      }}
                      onMouseLeave={() => {
                        setSelectedReorder(null);
                      }}
                      style={{ height: ref.current?.clientHeight }}
                    >
                      <div className="w-0 h-full absolute left-0"> </div>
                    </div>
                    {(leafIndex !== leavesColumns.current.length - 1) && (
                      <div
                        className="w-[51%] absolute right-[-1px] z-[90] [&:hover>div]:border-label [&:hover>div]:border-1"
                        onMouseEnter={() => {
                          setSelectedReorder(colIndex + 1);
                        }}
                        onMouseLeave={() => {
                          setSelectedReorder(null);
                        }}
                        style={{ height: ref.current?.clientHeight }}
                      >
                        <div className="w-0 h-full absolute right-0"> </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {resizable && (
                <ResizeBarWrapper
                  index={leafIndex + columnChildrenCount(col) - 1}
                  isLastColumn={leafIndex === leavesColumns.current.length - 1}
                />
              )}
            </React.Fragment>
          );
        });
      })}
      {/* Портал - для того чтобы тень не была поверх драгндроп ячейки */}
      {reorderColumn && ReactDom.createPortal(
        <div
          ref={reorderRef}
          className={parseStyles`
              select-none
              flex justify-center
              bg-table-header
              text-table-header-text 
              border-r-table-border-color border-b-table-border-color border-b-table border-r-table
              p-0 box-border
              fixed z-[100] pointer-events-none
              text-center
            `}
          style={{
            top: reorderColumn.startY - 10,
            left: reorderColumn.startX - ((reorderRef.current && findParentWithTransform(reorderRef.current)?.getBoundingClientRect().x) ?? 0) - 10,
            width: memoizedWidths[reorderColumn.leafIndex],
          }}
        >
          <HeaderCell
            column={levelsOfColumns[0][reorderColumn.index]!}
            isAscending={undefined}
          />
        </div>,
        ref.current!.parentElement!,
      )}
    </div>
  );
};

export default observer(Header);
