import {
GetPaymentTransactionByIdResponse,
ProblemDetails,
ProblemDetailsResponse} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class PaymentTransactionService {
  static get RoutePath(): string {
    return "PaymentTransaction";
  }

  /**
   * Получение транзакции по ид
   *
   * @request GET:/api/v1.0/PaymentTransaction/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetPaymentTransactionByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
}