import Button from '@components/ui/Button';
import CheckBox from '@components/ui/CheckBox/CheckBox';
import { FormInput, FormTextarea } from '@components/ui/Input';
import { Column, Table } from '@components/ui/Table';
import { useConfirm, useInterceptChanges, useStores } from '@hooks';
import type { RolePrivilegeTableItem } from '@types';
import { Observer, observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import type { PrivilegesData } from 'src/stores/Administration/Role/types';
import { useEffectOnce } from 'usehooks-ts';
import { ConfirmQuestions } from 'src/enums';
import DrawerWithWrapper from '../../DrawerResourceLayout/DrawerWithWrapper';
import type { RoleModalProps } from '../types';

/** Форма изменения/добавления роли */
const RoleForm: React.FC<RoleModalProps> = ({ roleInfo, onClose }) => {
  const { roleStore, rolesStore } = useStores();

  /** Проверка на изменение данных в таблице */
  const [dirty, setDirty] = useState(false);

  /** Хук для модалки подтверждения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  /** Стор привилегий роли */
  const { rolePrivilegesStore } = roleStore;

  /** Проверка на изменение формы */
  const { viewModel, isDirty } = useInterceptChanges(roleStore.viewModel);

  /** Идентификатор роли */
  const roleId = roleInfo?.roleId;

  /** Возможность редактирования роли */
  const canUpdate = useMemo(
    () => roleStore.canUpdate && (roleInfo?.organizationId || !roleId),
    [roleInfo?.organizationId, roleId, roleStore.canUpdate],
  );

  /** Заголовок модального окна с ролью */
  const title = roleId ? `Роль ${roleInfo.name}` : 'Создание роли';

  useEffectOnce(() => {
    if (roleId) {
      roleStore.fetch(roleId);
    }
    return () => {
      rolePrivilegesStore.setPrivileges([]);
      roleStore.clearStore();
    };
  });

  /** Обработчик нажатия на кнопку Сохранить */
  const saveHandler = useCallback(async (): Promise<void> => {
    if (viewModel.id) {
      await roleStore.save();
    } else {
      await roleStore.create();
    }
  }, [roleStore, viewModel.id]);

  /** Ячейка с отметкой привилегии */
  const SelectPrivilegeCheckbox = useCallback((
    arg: keyof Omit<RolePrivilegeTableItem, 'id' | 'name' | 'entityActions'>,
  ) => observer<({ data: RolePrivilegeTableItem })>
    (
    ({ data }) => (
      data[arg] !== undefined
        ? (
          <div className="h-full w-full flex justify-center items-center">
            <CheckBox
              checked={!!data[arg]}
              isDisabled={!canUpdate}
              onChange={() => {
                data[arg] = !data[arg];
              }}
            />
          </div>
        )
        : <div className="flex w-full h-full bg-white" />
    )), [canUpdate]);

  /** Кнопка сохранения формы */
  const saveButton = useMemo(() => (
    <div>
      <Button.Primary
        key="create"
        label="Сохранить"
        onClick={async () => {
          if (viewModel.validate()) {
            viewModel.entityActions = rolePrivilegesStore.privileges;
            onClose();
            await saveHandler();
            rolesStore.fetch();
          }
        }}
      />
    </div>
  ), [onClose, rolePrivilegesStore.privileges, rolesStore, saveHandler, viewModel]);

  /** Информация о роли */
  const roleMainInfo = useMemo(() => (
    <Observer>
      {() => (
        <div className="flex flex-col gap-form">
          <FormInput
            disabled={!canUpdate}
            error={viewModel.errors.name}
            label="Название"
            onChange={(event) => {
              viewModel.name = event.target.value;
            }}
            value={viewModel.name}
          />
          <FormTextarea
            className="flex-initial"
            disabled={!canUpdate}
            label="Описание"
            onChange={(event) => {
              viewModel.description = event.target.value;
            }}
            value={viewModel.description}
          />
        </div>
      )}
    </Observer>
  ), [canUpdate, viewModel]);

  return (
    <DrawerWithWrapper
      className="min-w-[50%] max-w-[950px]"
      confirm={(isDirty || dirty) ? confirm : undefined}
      footer={canUpdate ? saveButton : undefined}
      onClose={onClose}
      title={title}
    >
      {roleMainInfo}
      <div className="mt-4">
        <Table
          className="flex"
          dirtyChanged={setDirty}
          rowHeight={30}
          store={rolePrivilegesStore}
          withPagination={false}
        >
          <Column<RolePrivilegeTableItem, PrivilegesData>
            dataType="string"
            header="Название"
            keyExpr="name"
            width={250}
          />
          <Column<RolePrivilegeTableItem, PrivilegesData>
            dataType="custom"
            header="Просмотр"
            template={SelectPrivilegeCheckbox('canRead')}
          />
          <Column<RolePrivilegeTableItem, PrivilegesData>
            dataType="custom"
            header="Создание"
            template={SelectPrivilegeCheckbox('canCreate')}
          />
          <Column<RolePrivilegeTableItem, PrivilegesData>
            dataType="custom"
            header="Редактирование"
            template={SelectPrivilegeCheckbox('canUpdate')}
          />
          <Column<RolePrivilegeTableItem, PrivilegesData>
            dataType="custom"
            header="Удаление"
            template={SelectPrivilegeCheckbox('canDelete')}
          />
        </Table>
      </div>
    </DrawerWithWrapper>
  );
};

export default observer(RoleForm);
