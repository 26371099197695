import { useEffect, useMemo } from 'react';
import { useStores } from './useStores';

// хук для получения каталогов
export const useCatalogs = (catalogCodes: string[]) => {
  const { catalogStore } = useStores();

  // при изменении catalogCodes пытаемся получить их из стора
  useEffect(() => {
    // если они уже были получены с сервера, запрос не отправится
    catalogCodes.forEach((e) => catalogStore.getCatalogValue(e));
  }, [catalogCodes, catalogStore]);

  const catalogs = useMemo(() => catalogStore.catalogs, [catalogStore.catalogs]);
  const catalogStates = useMemo(() => catalogStore.catalogStates, [catalogStore.catalogStates]);

  return catalogCodes.map((e) => ({
    data: catalogs.get(e),
    state: catalogStates.get(e),
  }));
};
