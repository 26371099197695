import {
GetCatalogsResponse,
GetSingleCatalogResponse,
PostCatalogRequest,
PostCatalogResponse,
ProblemDetails,
ProblemDetailsResponse,
PutCatalogByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class CatalogService {
  static get RoutePath(): string {
    return "Catalog";
  }

  /**
   * Получение списка справочников
   *
   * @request GET:/api/v1.0/Catalog
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      filterCatalogName?: string[];
      filterCatalogValueName?: string[];
      filterCatalogCode?: string[];
      filterCatalogValueCode?: string[];
      isDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetCatalogsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание справочника
   *
   * @request POST:/api/v1.0/Catalog
   */
  static create = async (data: PostCatalogRequest, params: RequestParams = {}) =>
    httpClient.request<PostCatalogResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение справочника
   *
   * @request GET:/api/v1.0/Catalog/Single
   */
  static singleList = async (query?: { id?: string; name?: string; code?: string }, params: RequestParams = {}) =>
    httpClient.request<GetSingleCatalogResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Single`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Обновление справочника
   *
   * @request PUT:/api/v1.0/Catalog/{id}
   */
  static update = async (id: string, data: PutCatalogByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удаление справочника по ид
   *
   * @request DELETE:/api/v1.0/Catalog/{id}
   */
  static delete = async (id: string, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      ...params,
    });
}