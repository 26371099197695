import type { SyntheticEvent } from 'react';
import React, {
  memo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

interface Props{
  gridFullHeight: number;
  gridHeight: number;
  headerHeight: number;
  onScroll: (event: SyntheticEvent<HTMLDivElement>) => void;
}

const VerticalScroll = forwardRef<HTMLDivElement, Props >(({
  gridFullHeight,
  gridHeight,
  headerHeight,
  onScroll,
}, ref) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => scrollRef.current,
  );

  return (
    <div
      ref={scrollRef}
      className="overflow-x-hidden overflow-y-auto max-h-full w-4 shrink-0"
      onScroll={onScroll}
      style={{
        marginTop: headerHeight,
        marginLeft: '-1rem',
        height: gridHeight,
        scrollbarWidth: 'thin',
      }}
    >
      <div style={{ height: gridFullHeight, width: 1 }} />
    </div>
  );
});
VerticalScroll.displayName = 'VerticalScroll';

export default memo(VerticalScroll);
