import { required } from '@services/validationFunctions';
import type { GetCareerMeetingByIdResponse, GetCareerMeetingByIdResponseInvitedEmployee } from '@typesHr';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, runInAction } from 'mobx';
import BaseViewModel from '../BaseViewModel';

export class CareerMeetingViewModel extends BaseViewModel<GetCareerMeetingByIdResponse> {
  /** Получить 'Идентификатор' */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получить 'Время встречи' */
  @required()
  @computed get meetingDate() {
    return this.data.meetingDate ? dayjs(this.data.meetingDate) : undefined;
  }

  /** Изменить 'Время встречи' */
  set meetingDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.meetingDate = value?.toISOString();
    });
  }

  /** Получить 'Описание' */
  @computed get description() {
    return this.data.description ?? '';
  }

  /** Изменить 'Описание' */
  set description(value: string | undefined) {
    runInAction(() => {
      this.data.description = value;
    });
  }

  /** Получить 'Результаты' */
  @computed get results() {
    return this.data.results ?? '';
  }

  /** Изменить 'Результаты' */
  set results(value: string | undefined) {
    runInAction(() => {
      this.data.results = value;
    });
  }

  /** Получить 'Дата удаления' */
  @computed get deletedOn() {
    return this.data.deletedOn ? dayjs(this.data.deletedOn) : undefined;
  }

  /** Изменить 'Дата удаления' */
  set deletedOn(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.deletedOn = value?.toISOString();
    });
  }

  /** Получить 'Ид сотрудника' */
  @computed get employeeId() {
    return this.data.employeeId ?? '';
  }

  /** Изменить 'Ид сотрудника' */
  set employeeId(value: string | undefined) {
    runInAction(() => {
      this.data.employeeId = value;
    });
  }

  /** Получить 'Фамилия' */
  @computed get employeeSurname() {
    return this.data.employeeSurname ?? '';
  }

  /** Изменить 'Фамилия' */
  set employeeSurname(value: string | undefined) {
    runInAction(() => {
      this.data.employeeSurname = value;
    });
  }

  /** Получить 'Имя' */
  @computed get employeeName() {
    return this.data.employeeName ?? '';
  }

  /** Изменить 'Имя' */
  set employeeName(value: string | undefined) {
    runInAction(() => {
      this.data.employeeName = value;
    });
  }

  /** Получить 'Отчество' */
  @computed get employeePatronymic() {
    return this.data.employeePatronymic ?? '';
  }

  /** Изменить 'Отчество' */
  set employeePatronymic(value: string | undefined) {
    runInAction(() => {
      this.data.employeePatronymic = value;
    });
  }

  /** Получить 'Приглашенные сотрудники' */
  @computed get invitedEmployeesIds() {
    return this.data.invitedEmployees?.flatMap((x) => x.id ?? '') ?? [];
  }

  /** Получить 'Приглашенные сотрудники' */
  @computed get invitedEmployees() {
    return this.data.invitedEmployees ?? [];
  }

  /** Изменить 'Приглашенные сотрудники' */
  set invitedEmployees(value: GetCareerMeetingByIdResponseInvitedEmployee[] | undefined) {
    runInAction(() => {
      this.data.invitedEmployees = value;
    });
  }
}
