import { ImSpinner } from 'react-icons/im';
import React from 'react';
import { theme } from 'src/theme.config';

/** Пропсы лоадера */
interface LoaderProps {
  size: number;
  color?: string;
}

/** Компонент лоадера */
const Loader: React.FC<LoaderProps> = ({ size, color }) => (
  <ImSpinner
    className="animate-spin"
    fill={color ?? theme.extend.colors.main}
    size={size}
  />
);

export default Loader;
