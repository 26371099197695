import React, { memo } from 'react';

export interface GridProps {
  rows: number;
  columns: number;
  columnWidth: number;
  rowHeight: number;
  firstDayDayOfWeek: number;
}

/** Реестр сотрудников */
const Grid: React.FC<GridProps> = ({
  rows, columns, columnWidth, rowHeight, firstDayDayOfWeek,
}) => (
  <g>
    <g className="columns">
      {Array.from({ length: columns }, (_, index) => index).map((el, index) => ([0, 6].includes((firstDayDayOfWeek + index) % 7) ? (
        <rect
          key={`column${el}`}
          className="fill-graph-weekend-bg"
          height={rows * rowHeight}
          width={columnWidth}
          x={index * columnWidth}
          y={0}
        />
      ) : null))}
    </g>
    <g
      className="rows"
      id="rows"
    >
      {Array.from({ length: rows }, (_, index) => index).map((el, index) => (
        <rect
          key={`row${el}`}
          fill="transparent"
          height={rowHeight}
          id={`row${el}`}
          width={columnWidth * columns}
          y={index * rowHeight}
        />
      ))}
    </g>
    {/* <g className="rowLines">
      {Array.from({ length: rows }, (_, index) => index).map((el, index) => (
        <line
          key={`rowLine${el}`}
          stroke="rgba(100,100,100, 1)"
          x={0}
          x2={columnWidth * columns}
          y1={index * rowHeight + rowHeight}
          y2={index * rowHeight + rowHeight}
        />
      ))}
    </g> */}
    <g className="columnLines">
      {Array.from({ length: columns }, (_, index) => index + 1).map((el) => (
        <line
          key={`lineCol${el}`}
          className="stroke-graph-border"
          x1={el * columnWidth}
          x2={el * columnWidth}
          y={0}
          y2={rowHeight * rows}
        />
      ))}
    </g>
  </g>
);

export default memo(Grid);
