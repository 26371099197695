import { parseStyles } from '@services/utils';
import type { PropsWithChildren, ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import React from 'react';
import type { WithClassName } from './ui';
import ListHeader from './ListHeader';

interface TablePageLayoutProps extends WithClassName, PropsWithChildren {
  /** Кнопки в верхней части страницы */
  buttons?: ReactElement;
  /** Наименование */
  title?: string;
}

/** лайаут страницы с табличкой */
const TablePageLayout: React.FC<TablePageLayoutProps> = ({
  className, children, buttons, title,
}) => (
  <div
    className={parseStyles`
      flex flex-col grow-0 w-full h-full
      ${className}
    `}
  >
    {(title || buttons) && (
      <ListHeader
        buttons={buttons}
        title={title}
      />
    )}
    <div className="flex-grow min-h-0">
      {children ?? <Outlet />}
    </div>
  </div>
);

export default TablePageLayout;
