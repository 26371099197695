import type {
  Column,
  ColumnProps,
  CustomColumn,
  GroupColumn,
  GroupColumnProps,
  Row,
} from './types';
import ColumnComponent from './Column';

export function isGroupColumn <T extends Row, EntityT >(c: Column<T, EntityT>): c is GroupColumn<T, EntityT> {
  return !!(c as GroupColumn<T, EntityT>).columns;
}

export function isGroupColumnProps <T extends Row, EntityT >(c: ColumnProps<T, EntityT>): c is GroupColumnProps<T, EntityT> {
  return !![(c as GroupColumnProps<T, EntityT>).children].flat().some((e) => e?.type?.name === ColumnComponent.name);
}

export function isCustomColumn <T extends Row, EntityT >(c: Column<T, EntityT>): c is CustomColumn<T, EntityT> {
  return !!(c as CustomColumn<T, EntityT>).template;
}

export function isCustomColumnProps <T extends Row, EntityT >(c: ColumnProps<T, EntityT>): c is CustomColumn<T, EntityT> {
  const { template } = c as CustomColumn<T, EntityT>;

  return !!template;
}
