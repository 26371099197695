import { Logo, Popup } from '@components';
import LoginLayout from '@components/LoginLayout';
import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import Errors from '@components/ui/Errors/Errors';
import PasswordFormInput from '@components/ui/Input/PasswordFormInput';
import { Select } from '@components/ui/Select';
import { useStores, useTitle } from '@hooks';
import {
  parseStyles,
  getListErrors,
  setTokenToStorage,
  captchaSecret,
} from '@services/utils';
import { observer } from 'mobx-react-lite';
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TfiHelpAlt } from 'react-icons/tfi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorMessages } from 'src/enums';
import {
  useEffectOnce,
  useOnClickOutside,
  useToggle,
  useWindowSize,
} from 'usehooks-ts';

/** Компонент регистрации */
const Registration: React.FC = () => {
  useTitle('Регистрация');
  const { accountStore } = useStores();
  const [isCreatingOrganization, setIsCreatingOrganization] = useState<boolean>(false);
  const [isFirstStep, setIsFirstStep] = useState<boolean>(true);
  const [captchaRequiredError, setCaptchaRequiredError] = useState<string | undefined>(undefined);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  /** Реф для подсказки */
  const containerRef = useMemo(() => createRef<HTMLDivElement>(), []);
  /** Реф для outsideClick */
  const popupRef = useMemo(() => createRef<HTMLDivElement>(), []);

  const [showTooltip, toggleShowTooltip, setShowTooltip] = useToggle(false);

  /** Параметры URL */
  const [params] = useSearchParams();

  /** Контекст для навигации */
  const navigate = useNavigate();

  const { width } = useWindowSize();

  const inviteToken = useMemo(() => params.get('inviteToken'), [params]);

  /** Хук для клика вне компонента */
  useOnClickOutside(containerRef, () => {
    setShowTooltip(false);
  });

  useEffectOnce(() => {
    accountStore.clearStore();
    if (inviteToken) {
      accountStore.viewModel.inviteToken = inviteToken;
    }
    setTokenToStorage();
  });

  useEffect(() => setIsFirstStep(!accountStore.viewModel.inviteToken), [accountStore.viewModel.inviteToken]);

  /** Обработчик регистрации */
  const handleRegister = useCallback(async () => {
    if (!accountStore.viewModel.captchaToken) {
      setCaptchaRequiredError(ErrorMessages.captchaRequiredErrorText);
      return;
    }

    if (accountStore.viewModel.validate()) {
      await accountStore.tryRegistration();

      if (accountStore.state.isSuccess) {
        localStorage.setItem('first-login', 'true');
        navigate('/confirmEmail');
      }
    }

    recaptchaRef.current?.reset();
    accountStore.viewModel.captchaToken = undefined;
  }, [accountStore, navigate, recaptchaRef]);

  return (
    <LoginLayout>
      <Logo />
      <div
        className={parseStyles`
          w-1/2 min-w-[300px] max-w-[400px]
          bg-white shadow-lg
          rounded-main
          mt-16
          px-[50px] py-[30px]
          space-y-[16px]
          self-center
          justify-self-center
          text-left
        `}
      >
        <div className="text-center text-xl font-bold mb-[20px]">Регистрация</div>
        {isFirstStep && (
          <div className="grid gap-2 justify-center">
            <Button.Primary
              label="Создать организацию"
              onClick={() => {
                setIsFirstStep(false);
                setIsCreatingOrganization(true);
              }}
            />
            <Button.Primary
              label="Присоединиться к организации"
              onClick={() => {
                setIsFirstStep(false);
                setIsCreatingOrganization(false);
              }}
              type="outline"
            />
          </div>
        )}
        {!isFirstStep && (
          <div className="grid gap-form">
            {!isCreatingOrganization && (
              <>
                <FormInput
                  label="Код приглашения"
                  onChange={(event) => {
                    accountStore.viewModel.inviteToken = event.target.value;
                  }}
                  postfix={(
                    <div
                      ref={containerRef}
                      className={parseStyles`pr-2 self-center`}
                    >
                      <TfiHelpAlt
                        className="cursor-pointer rounded-full text-label p-[2px] hover:bg-slate-200 duration-300 ease-in hover:ease-out"
                        onClick={() => toggleShowTooltip()}
                        size={24}
                      />
                    </div>
                  )}
                  value={accountStore.viewModel.inviteToken}
                />
                {showTooltip && (
                  <Popup
                    ref={popupRef}
                    className="bg-white m-2 text-sm text-slate-500 text-left w-400"
                    targetRef={containerRef.current!}
                  >
                    Чтобы присоединиться к  организации, введите код, полученный от администратора организации
                  </Popup>
                )}
              </>
            )}
            {isCreatingOrganization && (
              <>
                <FormInput
                  error={accountStore.viewModel.errors.organizationName}
                  label="Название организации"
                  onChange={(event) => {
                    accountStore.viewModel.organizationName = event.target.value;
                  }}
                  value={accountStore.viewModel.organizationName}
                />
                <FormInput
                  error={accountStore.viewModel.errors.surname}
                  label="Фамилия"
                  onChange={(event) => {
                    accountStore.viewModel.surname = event.target.value;
                  }}
                  value={accountStore.viewModel.surname}
                />
                <FormInput
                  error={accountStore.viewModel.errors.name}
                  label="Имя"
                  onChange={(event) => {
                    accountStore.viewModel.name = event.target.value;
                  }}
                  value={accountStore.viewModel.name}
                />
              </>
            )}

            <FormInput
              error={accountStore.viewModel.errors.email}
              label="Email"
              onChange={(event) => {
                accountStore.viewModel.email = event.target.value;
              }}
              type="email"
              value={accountStore.viewModel.email}
            />
            <PasswordFormInput
              error={accountStore.viewModel.errors.passwordSimilar || accountStore.viewModel.errors.password}
              label="Пароль"
              onChange={(event) => {
                accountStore.viewModel.password = event.target.value;
              }}
              value={accountStore.viewModel.password}
            />
            <PasswordFormInput
              error={accountStore.viewModel.errors.passwordSimilar || accountStore.viewModel.errors.confirmPassword}
              label="Повторите пароль"
              onChange={(event) => {
                accountStore.viewModel.confirmPassword = event.target.value;
              }}
              value={accountStore.viewModel.confirmPassword}
            />
            {isCreatingOrganization && (
              <Select.Catalog
                catalogIdField="industryId"
                catalogName="Industry"
                error={accountStore.viewModel.errors.industryId}
                label="Отрасль организации"
                viewModel={accountStore.viewModel}
              />
            )}
          </div>
        )}
        {!isFirstStep && captchaSecret && (
          <div className="flex flex-1 flex-col items-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              onChange={(token) => {
                accountStore.viewModel.captchaToken = token;
                setCaptchaRequiredError(undefined);
              }}
              onExpired={() => {
                accountStore.viewModel.captchaToken = undefined;
                setCaptchaRequiredError(ErrorMessages.captchaRequiredErrorText);
              }}
              sitekey={captchaSecret}
              size={width < 700 ? 'compact' : 'normal'}
            />
            {captchaRequiredError && (
              <div className="text-left text-error break-words text-xs">
                {captchaRequiredError}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col gap-4">
          {!isFirstStep && (
            <Button.Primary
              isDisabled={accountStore.state.isLoading}
              isLoading={accountStore.state.isLoading}
              label="Зарегистрироваться"
              onClick={handleRegister}
            />
          )}
          <div className="flex flex-row justify-around">
            {!isFirstStep && (
              <div className="flex flex-col items-center">
                <Button.Outline
                  className="w-fit"
                  isDisabled={accountStore.state.isLoading}
                  isLoading={accountStore.state.isLoading}
                  label="Назад"
                  onClick={() => {
                    setIsFirstStep(true);
                    accountStore.clearStore();
                    setCaptchaRequiredError(undefined);
                  }}
                />
              </div>
            )}
            <div className="flex flex-col items-center">
              <Button.Outline
                className="w-fit"
                isDisabled={accountStore.state.isLoading}
                isLoading={accountStore.state.isLoading}
                label="Авторизоваться"
                onClick={() => {
                  navigate('/login');
                }}
              />
            </div>
          </div>
        </div>
        <Errors
          displayFunction={getListErrors}
          store={accountStore}
        />
      </div>
    </LoginLayout>
  );
};

export default observer(Registration);
