import {
GetAuditLogResponse,
ProblemDetails,
ProblemDetailsResponse} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class AuditLogService {
  static get RoutePath(): string {
    return "AuditLog";
  }

  /**
   * Получение списка сущностей
   *
   * @request GET:/api/v1.0/AuditLog
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      tablePk?: string;
      entityType?: string[];
      usersIds?: string[];
      startDate?: string;
      finishDate?: string;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetAuditLogResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}