import {
CheckTransactionsStatusesResponse,
GetLastSubscriptionResponse,
GetOrganizationByIdResponse,
GetSubscriptionByIdResponse,
GetSubscriptionsResponse,
ProblemDetails,
ProblemDetailsResponse,
PutOrganizationByIdRequest,
PutOrganizationSubscriptionRequest,
PutOrganizationSubscriptionResponse} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class OrganizationService {
  static get RoutePath(): string {
    return "Organization";
  }

  /**
   * Получение организации по ид
   *
   * @request GET:/api/v1.0/Organization/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetOrganizationByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление данных организации
   *
   * @request PUT:/api/v1.0/Organization/{id}
   */
  static update = async (id: string, data: PutOrganizationByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Изменение подписки
   *
   * @request PUT:/api/v1.0/Organization/Subscription
   */
  static subscriptionUpdate = async (data: PutOrganizationSubscriptionRequest, params: RequestParams = {}) =>
    httpClient.request<PutOrganizationSubscriptionResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Subscription`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение подписок
   *
   * @request GET:/api/v1.0/Organization/Subscriptions
   */
  static subscriptionsList = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      subscriptionTypes?: string[];
      isActualSubscriptionType?: boolean;
      startDate?: string;
      finishDate?: string;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetSubscriptionsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Subscriptions`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Получение подписки по ид
   *
   * @request GET:/api/v1.0/Organization/Subscription/{id}
   */
  static subscriptionDetail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetSubscriptionByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Subscription/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Проверить статусы транзакций у организации
   *
   * @request PUT:/api/v1.0/Organization/StatusPaymentTransactions/{id}
   */
  static statusPaymentTransactionsUpdate = async (id: string, params: RequestParams = {}) =>
    httpClient.request<CheckTransactionsStatusesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/StatusPaymentTransactions/${id}`,
      method: "PUT",
      format: "json",
      ...params,
    });

  /**
   * Получить последнюю подписку
   *
   * @request GET:/api/v1.0/Organization/LastSubscription
   */
  static lastSubscriptionList = async (params: RequestParams = {}) =>
    httpClient.request<GetLastSubscriptionResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/LastSubscription`,
      method: "GET",
      format: "json",
      ...params,
    });
}