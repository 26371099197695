import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useConfirm, useInterceptChanges, useStores } from '@hooks';
import { useEffectOnce } from 'usehooks-ts';
import { FormInput, FormTextarea } from '@components/ui/Input';
import { useNavigate } from 'react-router-dom';
import { Select } from '@components/ui/Select';
import Button from '@components/ui/Button';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { ConfirmQuestions, RussianProjectStatusesForSelect } from 'src/enums';
import Errors from '@components/ui/Errors/Errors';
import type { ProjectStatuses } from '@typesApi';
import DrawerWithWrapper from '../DrawerResourceLayout/DrawerWithWrapper';

/** Форма создания нового проекта */
const ProjectCreateForm: React.FC<{onClose: () => void}> = ({ onClose }) => {
  const navigate = useNavigate();
  /** Стор проекта */
  const { projectStore, counterpartyStore } = useStores();

  /** Модель проекта */
  const { viewModel, isDirty } = useInterceptChanges(projectStore.viewModel);

  /** Хук для модалки подтверждения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  /** Обработчик добавления проекта */
  const createHandler = useCallback(async () => {
    if (!viewModel.validate()) {
      return;
    }
    const id = await projectStore.create();

    if (projectStore.state.isSuccess) {
      onClose();
      navigate(`/projects/${id}`);
    }
  }, [viewModel, projectStore, onClose, navigate]);

  useEffectOnce(() => {
    projectStore.fetchContragents();
    return () => projectStore.clearStore();
  });

  /** Хэндлер обработки подтверждения или отмены закрытия модалки */
  const confirmHandler = useMemo(() => {
    if (isDirty) {
      return confirm;
    }
    return undefined;
  }, [confirm, isDirty]);

  /** Компонент кнопки для отображения снизу */
  const footer = useMemo(() => (
    <Button.Primary
      isLoading={projectStore.state.isLoading}
      label="Добавить"
      onClick={createHandler}
    />
  ), [projectStore.state, createHandler]);

  return (
    <DrawerWithWrapper
      confirm={confirmHandler}
      footer={footer}
      onClose={onClose}
      title="Добавление нового проекта"
    >
      <div className="flex flex-col gap-form">
        <FormInput
          error={viewModel.errors.name}
          label="Наименование"
          onChange={(e) => {
            viewModel.name = e.target.value;
          }}
          value={viewModel.name ?? ''}
        />
        <DatePicker
          error={viewModel.errors.startDate}
          label="Дата начала"
          onChange={(value) => {
            viewModel.startDate = value;
          }}
          value={viewModel.startDate}
          utc
        />
        <DatePicker
          error={viewModel.errors.finishDate}
          label="Дата окончания"
          onChange={(value) => {
            viewModel.finishDate = value;
          }}
          value={viewModel.finishDate}
          utc
        />
        <Select.AutoComplete
          label="Статус"
          onSelect={(option) => {
            viewModel.status = option?.value as ProjectStatuses;
          }}
          options={RussianProjectStatusesForSelect}
          placeholder=""
          selectedOption={viewModel.russianStatusForSelect}
        />
        <Select.Async
          label="Контрагент"
          onChange={(query) => projectStore.fetchContragents(query)}
          onClick={counterpartyStore.canRead
            ? () => navigate(`/settings/counterparties/${viewModel.contragentId}`)
            : undefined}
          onSelect={(option) => {
            viewModel.contragentName = option?.label;
            viewModel.contragentId = option?.value;
          }}
          selectedOption={viewModel.selectedСontragent}
        />
        <FormTextarea
          label="Комментарий"
          onChange={(e) => {
            viewModel.comment = e.target.value;
          }}
          value={viewModel.comment ?? ''}
        />
      </div>
      <Errors store={projectStore} />
    </DrawerWithWrapper>
  );
};

export default observer(ProjectCreateForm);
