import type { PropsWithChildren } from 'react';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { parseStyles } from '@services/utils';
import { useEffectOnce } from 'usehooks-ts';
import LeftMenu from './LeftMenu/LeftMenu';
import type { WithClassName } from './ui';
import InfoModal from './ui/InfoModal/InfoModal';

/** Внешний лайаут */
const Layout: React.FC<WithClassName & PropsWithChildren> = ({ children, className }) => (
  <div
    className={parseStyles`
      flex flex-1 marker:bg-default overflow-auto w-[100vw] text-font-main
      ${className}
    `}
  >
    {children}
  </div>
);

/** Лайаут правого блока лаяута */
const RightBlock: React.FC<WithClassName & PropsWithChildren> = ({ children, className }) => (
  <div className={parseStyles`
      flex flex-col flex-1 overflow-auto scrollbar @container/main
      ${className}
    `}
  >
    {children}
  </div>
);

/** Основной лайаут */
const MainLayout: React.FC<{contentClassName?: string}> = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffectOnce(() => {
    if (localStorage.getItem('first-login')) {
      setOpenModal(true);
    }
  });

  const onClick = (path?: string) => {
    localStorage.removeItem('first-login');
    setOpenModal(false);
    if (path) {
      navigate(path);
    }
  };

  return (
    <>
      <Layout>
        <LeftMenu />
        <RightBlock>
          <Outlet />
        </RightBlock>
      </Layout>
      {openModal && (
        <InfoModal
          auxiliaryButton={{
            label: 'Настройки организации',
            onClick: () => onClick('/settings/organization'),
          }}
          header="Организация успешно зарегистрирована."
          onClose={() => {
            onClick();
            setOpenModal(false);
          }}
          text={`У вас активен пробный период длительностью 1 месяц.

            Перейдите в настройки организации для снятия ограничений и выбора тарифного плана.
          `}
        />
      )}
    </>

  );
};

export default MainLayout;
