import Base from './BaseSelect/BaseSelect';
import AutoComplete from './AutoComplete/AutoComplete';
import Async from './AsyncSelect/AsyncSelect';
import Catalog from './CatalogSelect/CatalogSelect';
import Multi from './MultiSelect/MultiSelect';
import AsyncMulti from './AsyncMultiSelect/AsyncMultiSelect';

export default {
  Base,
  AutoComplete,
  Async,
  Multi,
  AsyncMulti,
  Catalog,
};
