import {
DeleteProjectByIdRequest,
EventTypes,
FileImportResponse,
GetLoadResourceInProjectsByProjectIdResponse,
GetLoadResourceInProjectsResponse,
GetProjectByIdResponse,
GetProjectEmployeesResponse,
GetProjectNamesResponse,
GetProjectsResponse,
GetResourcesInProjectResponse,
PostProjectRequest,
PostProjectResponse,
ProblemDetails,
ProblemDetailsResponse,
ProjectActions,
ProjectStatuses,
PutProjectByIdRequest,
ResourceTypes,
UpdateProjectResponsibleByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class ProjectService {
  static get RoutePath(): string {
    return "Project";
  }

  /**
   * Получение списка проектов
   *
   * @request GET:/api/v1.0/Project
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      contragents?: string[];
      filterName?: string;
      startDateFrom?: string;
      startDateTo?: string;
      finishDateFrom?: string;
      finishDateTo?: string;
      status?: ProjectStatuses;
      showOnlyEditable?: boolean;
      isDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetProjectsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание проекта
   *
   * @request POST:/api/v1.0/Project
   */
  static create = async (data: PostProjectRequest, params: RequestParams = {}) =>
    httpClient.request<PostProjectResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение списка нагрузок ресурсов на проектах
   *
   * @request GET:/api/v1.0/Project/LoadResourceInProjects
   */
  static loadResourceInProjectsList = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      filterName?: string;
      startDateFrom?: string;
      startDateTo?: string;
      finishDateFrom?: string;
      finishDateTo?: string;
      status?: ProjectStatuses;
      isDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetLoadResourceInProjectsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/LoadResourceInProjects`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Получение списка нагрузок ресурсов на проекте по ид проекта
   *
   * @request GET:/api/v1.0/Project/{id}/LoadResourceInProjects
   */
  static loadResourceInProjectsDetail = async (
    id: string,
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      isDeleted?: boolean;
      resourceTypes?: ResourceTypes[];
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetLoadResourceInProjectsByProjectIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/LoadResourceInProjects`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Получение списка имен проектов
   *
   * @request GET:/api/v1.0/Project/Names
   */
  static namesList = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      filterName?: string;
      excludedEmployeeId?: string;
      status?: ProjectStatuses;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetProjectNamesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Names`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Обновление проекта
   *
   * @request PUT:/api/v1.0/Project/{id}
   */
  static update = async (id: string, data: PutProjectByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Проставления даты удаления проекта по ид
   *
   * @request DELETE:/api/v1.0/Project/{id}
   */
  static delete = async (id: string, data: DeleteProjectByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение проекта по ид
   *
   * @request GET:/api/v1.0/Project/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetProjectByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Получение ресурсов на проекте
   *
   * @request GET:/api/v1.0/Project/{id}/ResourcesInProject
   */
  static resourcesInProjectDetail = async (
    id: string,
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      filterName?: string;
      startDateFrom?: string;
      startDateTo?: string;
      finishDateFrom?: string;
      finishDateTo?: string;
      filterTypes?: ResourceTypes[];
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetResourcesInProjectResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/ResourcesInProject`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Импорт проектов из csv
   *
   * @request POST:/api/v1.0/Project/Import
   */
  static importCreate = async (data: { file?: File }, params: RequestParams = {}) =>
    httpClient.request<FileImportResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Import`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });

  /**
   * Добавление проекту ответственного
   *
   * @request PUT:/api/v1.0/Project/{id}/Responsible
   */
  static responsibleUpdate = async (
    id: string,
    data: UpdateProjectResponsibleByIdRequest,
    params: RequestParams = {},
  ) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/Responsible`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка сотрудников на проекте
   *
   * @request GET:/api/v1.0/Project/{id}/Employees
   */
  static employeesDetail = async (
    id: string,
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      employeeId?: string;
      name?: string;
      hasProjectRole?: boolean;
      projectRoleId?: string;
      hasProjectActions?: boolean;
      projectAction?: ProjectActions;
      hasNotificationSettings?: boolean;
      eventType?: EventTypes;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetProjectEmployeesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/Employees`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}