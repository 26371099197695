import type { SelectOption } from '@components/ui/Select/types';
import { PlaceService, ResourceService } from '@servicesApi';
import type { GetPlaceByIdResponse } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import PlaceViewModel from './PlaceViewModel';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';
import type EmployeeNamesStore from '../Employees/EmployeeNamesStore';

/** Стор помещения */
class PlaceStore extends EntityBaseStore<GetPlaceByIdResponse, PlaceViewModel> {
  public viewModel = new PlaceViewModel({});

  @observable private _privilegesStore: PrivilegesStore;

  /** Список сорудников */
  @observable private _employeeNamesStore: EmployeeNamesStore;

  /** Наименования сотрудников */
  employeeNames?: SelectOption[];

  constructor(privilegesStore: PrivilegesStore, employeeNamesStore: EmployeeNamesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
    this._employeeNamesStore = employeeNamesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.resources.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.resources.canDeleteById(this.viewModel.id);
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.resources.canUpdateById(this.viewModel.id);
  }

  public override get canRead(): boolean {
    return this._privilegesStore.resources.canRead;
  }

  @computed
  public get canReadResponsible(): boolean {
    return this._privilegesStore.resources.canRead
      || (!!this.viewModel.responsibleId && this._privilegesStore.resources.isResponsibleFor(this.viewModel.responsibleId!));
  }

  @computed public get canUpdateResponsible(): boolean {
    return this._privilegesStore.resources.canUpdateResponsible;
  }

  /** Загрузить помещение */
  public async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await PlaceService.detail(id);
      runInAction(() => {
        this.viewModel = new PlaceViewModel(data);
      });
    });
  }

  /** Сохранить помещение */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await PlaceService.update(this.viewModel.id!, this.viewModel.getRawData());
      });
    } else {
      await this.create();
    }
  }

  /** Создание нового помещения */
  public async create(): Promise<string | undefined> {
    return this.runWithStateControl(async () => (await PlaceService.create(this.viewModel.getRawData())).data.id);
  }

  /** Установить ответственного */
  public async setResponsible() {
    if (this.viewModel.id) {
      await ResourceService.responsibleUpdate(this.viewModel.id, { responsibleId: this.viewModel.responsibleId });
    }
  }

  /** Получить список опций для селекта */
  public async fetchEmployeeNames(search?: string) {
    const result = await this._employeeNamesStore.fetchEmployeeNames(search);

    runInAction(() => {
      this.employeeNames = result;
    });

    return result;
  }
}

export default PlaceStore;
