import type {
  GetLoadResourceInProjectsResponseItemLoadResourceInProject,
  GetLoadResourceInProjectsResponseItemUnavailableResource,
  GetResourceInProjectsResponseUnavailableResourceItem,
} from '@typesApi';
import React, { memo } from 'react';
import type { Dayjs } from 'dayjs';
import Day from './Day';
import PeriodComponent from './PeriodComponent';
import UnavailablePeriodComponent from './UnavailablePeriodComponent';
import type { DayData } from '../types';

export interface RowProps {
  days: DayData[];
  onCellHover: (day: {cell: SVGElement; day: DayData} | null) => void;
  unavailableResources: GetLoadResourceInProjectsResponseItemUnavailableResource[];
  periods: GetLoadResourceInProjectsResponseItemLoadResourceInProject[];
  rowIndex: number;
  rowHeight: number;
  columnWidth: number;
  hoveredPeriodKey?: string;
  unitForDayJS: 'day' | 'week' | 'month' | 'year';
  changePeriod?: (y: number, period: GetLoadResourceInProjectsResponseItemLoadResourceInProject, startDate: Dayjs, finishDate: Dayjs) => void;
  startDate: Date;
  deleteUnavailabilityPeriod?: (y: number, period: GetResourceInProjectsResponseUnavailableResourceItem) => void;
  changeUnavailablePeriod?: (y: number, period: GetResourceInProjectsResponseUnavailableResourceItem, startDate: Dayjs, finishDate: Dayjs) => void;
}

/** Реестр сотрудников */
const ResourceRow: React.FC<RowProps> = ({
  onCellHover,
  days,
  unavailableResources,
  periods,
  rowIndex,
  rowHeight,
  columnWidth,
  hoveredPeriodKey,
  unitForDayJS,
  changePeriod,
  startDate,
  deleteUnavailabilityPeriod,
  changeUnavailablePeriod,
}) => (
  <>
    {days.map((day) => (
      <Day
        key={`day${day.index}`}
        columnWidth={columnWidth}
        day={day}
        onCellHover={onCellHover}
        rowHeight={rowHeight}
        rowIndex={rowIndex}
      />
    ))}
    {periods.map((period) => (
      <PeriodComponent
        key={`${period.startDate}-${period.finishDate}`}
        changePeriod={changePeriod}
        columnWidth={columnWidth}
        hovered={hoveredPeriodKey === `${rowIndex}-${period.startDate}-${period.finishDate}`}
        period={period}
        rowHeight={rowHeight}
        rowIndex={rowIndex}
        startDate={startDate}
        unitForDayJS={unitForDayJS}
      />
    ))}
    {unavailableResources.map((period) => (
      <UnavailablePeriodComponent
        key={`${period.startDate}-${period.finishDate}`}
        changeUnavailablePeriod={changeUnavailablePeriod}
        columnWidth={columnWidth}
        deleteUnavailabilityPeriod={deleteUnavailabilityPeriod}
        period={period}
        rowHeight={rowHeight}
        rowIndex={rowIndex}
        startDate={startDate}
        unitForDayJS={unitForDayJS}
      />
    ))}
  </>
);

export default memo(ResourceRow);
