import Switcher from '@components/ui/Switcher/Switcher';
import type { AccountsTableItem } from '@types';
import { observer } from 'mobx-react-lite';
import React from 'react';

/** ячейка активности аккаунта  */
const AccountActiveCell: React.FC<{data: AccountsTableItem}> = (({ data }) => (
  <div
    className="flex h-full items-center justify-center text-center break-words"
    onClick={(event) => event.stopPropagation()}
  >
    <Switcher
      isActive={data.isActive}
      loading={data.state?.isLoading}
      onChange={() => data.updateAccountState()}
    />
  </div>
));

export default observer(AccountActiveCell);
