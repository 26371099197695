import {
FileImportResponse,
GetPlaceByIdResponse,
GetPlacesResponse,
PostPlaceRequest,
PostPlaceResponse,
ProblemDetails,
ProblemDetailsResponse,
PutPlaceByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class PlaceService {
  static get RoutePath(): string {
    return "Place";
  }

  /**
   * Получение помещения по ид
   *
   * @request GET:/api/v1.0/Place/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetPlaceByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление данных о помещении
   *
   * @request PUT:/api/v1.0/Place/{id}
   */
  static update = async (id: string, data: PutPlaceByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка помещений
   *
   * @request GET:/api/v1.0/Place
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      filterName?: string;
      showOnlyEditable?: boolean;
      isDeleted?: boolean;
      isProjectDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetPlacesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание помещения
   *
   * @request POST:/api/v1.0/Place
   */
  static create = async (data: PostPlaceRequest, params: RequestParams = {}) =>
    httpClient.request<PostPlaceResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Импорт помещений из csv
   *
   * @request POST:/api/v1.0/Place/Import
   */
  static importCreate = async (data: { file?: File }, params: RequestParams = {}) =>
    httpClient.request<FileImportResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Import`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
}