import type { SelectOption } from '@components/ui/Select/types';
import { RoleService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { EntityActions, GetRolesResponseItem } from '@typesApi';
import { makeObservable, observable } from 'mobx';
import type { PrivilegesStore } from 'src/stores/Privileges/PrivilegesStore';
import BaseEntityListStore from '../../BaseEntityListStore';
import { RoleViewModel } from './RolesItemViewModel';

/** Фильтр для получения списка ролей */
interface RolesFilter extends ListBaseFilter {
  /** Список ролей */
  roles?: string[];
  /** Наименование роли */
  name?: string;
  /** Код роли */
  code?: string;
  /** Список действий с сущностями */
  entityActions?: EntityActions[];
  /** Показывать удалённые роли */
  isDeleted?: boolean;
}

/** Стор для списка ролей Администрирования */
export class RolesStore extends BaseEntityListStore<GetRolesResponseItem, RolesFilter, RoleViewModel> {
  @observable private _privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.roles.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.roles.canDelete;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.roles.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.roles.canRead;
  }

  /** Получение списка ролей */
  public override async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await RoleService.list(this.filter);
      this.setData(data, RoleViewModel);
    });
  }

  /** Удаление ролей */
  public async delete() {
    const selectedIds = this.viewModel.filter((model) => model.selected).map((model) => model.id);
    await this.runWithStateControl(async () => {
      await Promise.all(selectedIds.map((id) => RoleService.delete(id)));
      await this.fetch();
    });
  }

  /** Получить список дял селекта */
  public get rolesForSelect(): SelectOption[] {
    return this.viewModel?.map((role) => ({ label: role.name ?? '', value: role.id ?? '' })) || [];
  }
}
