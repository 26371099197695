import Popup from '@components/Popup/Popup';
import { parseStyles } from '@services/utils';
import React from 'react';
import { BsFillPencilFill } from 'react-icons/bs';

const AboutDayPopup: React.FC<{
  targetRef: Element;
  setHoveredPopup: (e: HTMLDivElement | null) => void;
  fields: {
    label: string;
    value: string;
    onClick?: () => void;
  }[];
}> = ({ targetRef, setHoveredPopup, fields }) => (
  <Popup
    position="top"
    targetRef={targetRef}
  >
    <div
      className="border-1 rounded-lg bg-white flex flex-col min-w-[100px] p-2 gap-y-2 items-left"
      onMouseEnter={(e) => {
        setHoveredPopup(e.currentTarget);
      }}
      onMouseLeave={() => {
        setHoveredPopup(null);
      }}
    >
      {fields.map((e) => (
        <div
          key={e.label}
          className="flex flex-col"
        >
          <div className="flex gap-2 text-left text-label items-center ">
            {e.label}
            {e.onClick && (
              <BsFillPencilFill
                className="cursor-pointer fill-label"
                color="black"
                onClick={e.onClick}
                size={10}
              />
            )}
          </div>
          <span
            className={parseStyles`
              ${e.onClick ? 'cursor-pointer' : ''}
              text-font-main
            `}
            onClick={e.onClick}
          >
            {e.value}
          </span>
        </div>
      ))}
    </div>
  </Popup>
);

export default AboutDayPopup;
