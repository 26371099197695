import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { parseStyles } from '@services/utils';
import { Close } from '@assets';
import { BaseInput } from '../Input';
import type { WithClassName } from '../types';
import Label from '../Label/Label';

interface DatePickerProps extends WithClassName {
  label?: string;
  value?: Dayjs | null;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  onChange: (value?: Dayjs) => void;
  readonly?: boolean;
  withTime?: boolean;
  error?: string;
  invalid?: boolean;
  borderClasses?: string;
  utc?: boolean;
  clearable?: boolean;
}

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(({
  className,
  label,
  value,
  onChange,
  width,
  height,
  readonly = false,
  withTime,
  disabled,
  error,
  borderClasses,
  invalid,
  utc = false,
  clearable = true,
}, _) => {
  /** Реф до input[date] */
  const DatePickerRef = useRef<HTMLInputElement>(null);
  /** Состояние пикера даты (для обработки ручного ввода) */
  const [pickerOpened, setPickerOpened] = useState(false);
  /** Перевод значения из Dayjs в нужный для input[type="date"] */
  const showValue = useMemo(
    () => {
      const val = utc ? value : value?.local();

      return withTime
        ? val?.format('YYYY-MM-DDTHH:mm')
        : val?.format('YYYY-MM-DD');
    },
    [utc, value, withTime],
  );

  /** Открытие пикера */
  const openPicker = useCallback(() => {
    if (!disabled && !readonly && DatePickerRef.current) {
      // @ts-ignore потому что ts не хочет видеть showPicker
      DatePickerRef.current.showPicker();
      setPickerOpened(true);
    }
  }, [disabled, readonly]);

  /** Возможность отображения кнопки очищения поля */
  const canShowPostfix = value && !readonly && !disabled && clearable;

  /** Сброс значения дейтпикера */
  const clearValue = useCallback(() => {
    onChange();
  }, [onChange]);

  return (
    <div>
      {label && <Label>{label}</Label>}
      <BaseInput
        ref={DatePickerRef}
        backgroundColor=""
        borderClasses={parseStyles`
          border-1 rounded-main
          ${(error || invalid) ? 'text-error border-error' : 'border-gray-200 focus-within:border-i-border-active'}
          ${borderClasses}
        `}
        className={parseStyles`
          ${className}
          ${disabled ? 'bg-i-bg-disabled text-i-disabled' : 'bg-white'}
        `}
        disabled={disabled || readonly}
        height={height}
        onChange={(e) => {
          if (pickerOpened) {
            if (!e?.target.value) {
              clearValue();
              return;
            }
            onChange(utc ? dayjs.utc(e?.target.value, 'YYYY-MM-DD') : dayjs(e?.target.value, 'YYYY-MM-DD'));
          }
        }}
        onClick={openPicker}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        postfix={canShowPostfix && (
          <div className={`
            pr-2 self-center h-full flex items-center
            ${disabled ? 'bg-i-bg-disabled text-i-disabled' : 'bg-white'}
          `}
          >
            <Close
              className="hover:opacity-70 cursor-pointer"
              onClick={clearValue}
            />
          </div>
        )}
        readOnly={readonly}
        type={withTime ? 'datetime-local' : 'date'}
        value={showValue}
        width={width}
      />
      {error && (
        <span className="text-error break-words text-xs text-left">
          {error}
        </span>
      )}
    </div>
  );
});

DatePicker.displayName = 'DatePicker';

export default DatePicker;
