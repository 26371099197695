import Button from '@components/ui/Button';
import { useInterceptChanges, useStores, useConfirm } from '@hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { RxCopy } from 'react-icons/rx';
import { parseStyles } from '@services/utils';
import FormGrid from '@components/ui/Form/FormGrid';
import { Select } from '@components/ui/Select';
import { ConfirmQuestions } from 'src/enums';
import Errors from '@components/ui/Errors/Errors';
import DrawerWithWrapper from '../DrawerResourceLayout/DrawerWithWrapper';
import EmployeeCreateModal from './EmployeeCreateModal';

/** Форма создания нового пользователя */
const AccountCreateForm: React.FC<{onClose: () => void}> = ({ onClose }) => {
  const { inviteStore, employeeStore, rolesStore } = useStores();
  const { viewModel, isDirty, resetIsDirty } = useInterceptChanges(inviteStore.viewModel);
  const [showCreateEmployee, toggleCreateEmployee] = useToggle();
  /** Модалка подтверждения выхода без сохранения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  useEffectOnce(() => () => {
    employeeStore.clearStore();
    inviteStore.clearStore();
  });

  useEffectOnce(() => {
    rolesStore.fetch();
  });

  /** Кнопки снизу */
  const footer = useMemo(() => {
    /** Создать токен */
    const createTokenHandler = () => {
      if (viewModel.validate()) {
        inviteStore.getInviteToken();
        resetIsDirty();
      }
    };

    return (
      <Button.Primary
        isLoading={inviteStore.state.isLoading}
        label="Создать ссылку-приглашение"
        onClick={createTokenHandler}
      />
    );
  }, [inviteStore, resetIsDirty, viewModel]);

  return (
    <DrawerWithWrapper
      confirm={isDirty ? confirm : undefined}
      footer={footer}
      onClose={onClose}
      title="Создание ссылки приглашения"
    >
      <div className="flex flex-col gap-form">
        <FormGrid>
          <Select.Async
            error={viewModel.errors.newAccountEmployee}
            label="Сотрудник"
            onChange={(search) => employeeStore.fetchEmployeeNames(search, false)}
            onSelect={(option) => {
              viewModel.newAccountEmployee = {
                employeeId: option?.value ?? '',
                employeeName: option?.label ?? '',
              };
            }}
            selectedOption={viewModel.newAccountEmployee
              ? ({ label: viewModel.newAccountEmployee.employeeName, value: viewModel.newAccountEmployee.employeeId })
              : undefined}
            clearable
          />
        </FormGrid>
        <Button.Outline
          className="self-start"
          label="Создать нового сотрудника"
          onClick={toggleCreateEmployee}
        />
        <FormGrid>
          <Select.AutoComplete
            label="Cистемная роль"
            onSelect={(option) => {
              runInAction(() => {
                viewModel.newEmployeeRole = {
                  roleId: option?.value ?? '',
                  roleName: option?.label ?? '',
                };
              });
            }}
            options={rolesStore.rolesForSelect}
            selectedOption={viewModel.newEmployeeRole ? {
              label: viewModel.newEmployeeRole?.roleName,
              value: viewModel.newEmployeeRole?.roleId,
            } : undefined}
            clearable
          />
        </FormGrid>
      </div>
      {viewModel.inviteToken && (
        <div className="flex flex-col gap-form mt-2">
          <div className="font-bold text-xl">Письмо с приглашением отправлено на почту</div>
          <div className="group flex justify-between items-center flex-row break-all bg-slate-200 p-2 rounded-md relative">
            {viewModel.inviteToken.inviteToken}
            <div
              className={parseStyles`
                  cursor-pointer bg-background
                  p-[0.3rem] rounded-md border border-slate-400 w-fit 
                  opacity-0 group-hover:opacity-100
                  hover:shadow-lg hover:border-btn-main-bg-hover
                  active:shadow-inner
                  transition-all duration-200
                `}
              onClick={() => navigator.clipboard.writeText(viewModel.inviteToken?.inviteToken ?? '')}
            >
              <RxCopy size={15} />
            </div>
          </div>

          <Button.Primary
            label="Копировать ссылку"
            onClick={() => {
              const inviteLink = `${window.location.origin}/registration?inviteToken=${viewModel.inviteToken?.inviteToken ?? ''}`;
              navigator.clipboard.writeText(inviteLink);
            }}
          />
        </div>
      )}
      {showCreateEmployee && (
        <EmployeeCreateModal
          onChangeNewUser={(id, name) => {
            viewModel.newAccountEmployee = {
              employeeId: id,
              employeeName: name,
            };
          }}
          onClose={() => {
            toggleCreateEmployee();
            employeeStore.clearStore();
          }}
        />
      )}
      <Errors store={inviteStore} />
    </DrawerWithWrapper>
  );
};

export default observer(AccountCreateForm);
