import { parseStyles } from '@services/utils';
import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import type { OptionProps } from '../types';

/** Опция селекта */
const Option: React.FC<OptionProps> = ({
  label,
  value,
  isHovered,
  isSelected,
  onClick,
  onMouseMove,
}) => (
  <div
    key={value}
    className={parseStyles`
      w-full p-[10px] pl-[5px] flex items-center gap-2
      cursor-pointer transition-none text-left rounded-md text-font-main
      ${isHovered ? 'bg-option-arrow-hover' : 'bg-transparent'} 
    `}
    onMouseDown={(e) => {
      e.stopPropagation();
      onClick();
    }}
    onMouseMove={onMouseMove}
    title={label}
  >
    {isSelected ? (<IoCheckmark size={15} />) : (<div className="w-[15px] h-[15px]" />)}
    <span className="break-words">{label}</span>
  </div>
);

export default Option;
