import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import jwtDecode from 'jwt-decode';
import {
  AccountService,
  AuthenticationService,
  FileService,
  OrganizationService,
} from '@servicesApi';
import type { CheckTransactionsStatusesResponse, GetAccountByIdResponse } from '@typesApi';
import { setTokenToStorage } from '@services/utils';
import type { Nullable } from '@types';
import EntityBaseStore from '../BaseEntityStore';
import { AccountViewModel } from './AccountViewModel';
import { ProfileViewModel } from './ProfileViewModel';
import PasswordChangeViewModel from './PasswordChangeViewModel';
import { SubscriptionViewModel } from './SubscriptionViewModel';

/** Стор сущности пользователя */
export class AccountStore extends EntityBaseStore<GetAccountByIdResponse, AccountViewModel> {
  constructor() {
    super();
    makeObservable(this);
  }

  public viewModel = new AccountViewModel({});

  public profileViewModel = new ProfileViewModel({});

  /** Модель для изменения пароля */
  public passwordChangeViewModel = new PasswordChangeViewModel({});

  /** Токен пользователя */
  @observable public token?: Nullable<string> = null;

  /** Токен рефреш пользователя */
  @observable public refreshToken?: Nullable<string>;

  /** Текущая подписка */
  @observable public currentSubscription = new SubscriptionViewModel({});

  /** Статус обработки платежа банком */
  @observable public statusPaymentTransaction?: CheckTransactionsStatusesResponse;

  /** id сотрудника */
  @computed get employeeId() {
    return this.token ? jwtDecode<{ EmployeeId: string }>(this.token).EmployeeId : undefined;
  }

  /** id организации */
  @computed get organizationId() {
    return this.token ? jwtDecode<{ OrganizationId: string }>(this.token).OrganizationId : '';
  }

  /** Активен ли пользователь */
  @computed get isActive() {
    return this.token ? jwtDecode<{ IsActive: string }>(this.token).IsActive === 'true' : false;
  }

  /** Идентификатор текущей подписки */
  @computed get subscriptionId() {
    return this.token ? jwtDecode<{ SubscriptionId: string }>(this.token).SubscriptionId : undefined;
  }

  /** Фетч сущности */
  public override async fetch(): Promise<void> {
    if (!this.token) {
      return;
    }
    await this.runWithStateControl(async () => {
      const { data } = await AccountService.meList();
      runInAction(() => {
        this.viewModel = new AccountViewModel(data);
        this.profileViewModel = new ProfileViewModel(data);
      });
    });
  }

  public async deleteRoles(): Promise<void> {
    await this.runWithStateControl(async () => {
      const allRoles = this.profileViewModel.employeeRoles.viewModel;
      const allRolesIds = allRoles.map((e) => e.id);
      const rolesForDelete = allRoles.filter((e) => e.selected).map((e) => e.id);
      await AccountService.rolesUpdate(
        this.profileViewModel.employeeId,
        {
          roleIds: allRolesIds.filter((e) => !rolesForDelete.includes(e)),
        },
      );
    });
    this.fetch();
  }

  /** Регистрация пользователя */
  async tryRegistration() {
    await this.runWithStateControl(async () => {
      const { data: result } = await AuthenticationService.registrationCreate(this.viewModel.getRawData());
      runInAction(() => {
        this.viewModel = new AccountViewModel(result);
      });
    });
  }

  /** попытка получить токен */
  async tryGetToken(code: string, codeVerifier: string): Promise<boolean> {
    await this.runWithStateControl(async () => {
      const { data } = await AuthenticationService.tokenCreate({
        code,
        codeVerifier,
        redirectUri: `${window.location.origin}/redirect`,
      });
      runInAction(() => {
        if (data.accessToken) {
          setTokenToStorage(
            data.accessToken,
            data.refreshToken,
            data.expiresIn ? dayjs().add(data.expiresIn, 'seconds').toISOString() : undefined,
          );
        }
      });
    });
    return this.state.isSuccess;
  }

  /** Регистрация пользователя */
  async tryRefreshToken(refreshToken: string): Promise<boolean> {
    await this.runWithStateControl(async () => {
      const { data } = await AuthenticationService.refreshTokenCreate({
        refreshToken,
      });
      runInAction(() => {
        if (data.accessToken) {
          setTokenToStorage(
            data.accessToken,
            data.refreshToken,
            data.expiresIn ? dayjs().add(data.expiresIn, 'seconds').toISOString() : undefined,
          );
        }
      });
    });
    return this.state.isSuccess;
  }

  /** Обновление информации профиля */
  public override async save(): Promise<void> {
    await this.runWithStateControl(async () => {
      await AccountService.meUpdate({
        accountPictureId: this.viewModel.accountPictureId,
        email: this.viewModel.email,
        phone: this.viewModel.phone,
      });
    });
  }

  public async addRole(roleId: string): Promise<void> {
    const curRoles = this.profileViewModel.employeeRoles.viewModel?.map((e) => e.id);
    await this.runWithStateControl(async () => {
      await AccountService.rolesUpdate(this.profileViewModel.employeeId, { roleIds: [...curRoles, roleId] });
    });
  }

  /** Загрузка новой аватарки пользователя */
  public async uploadAvatar(file?: File): Promise<void> {
    if (!file) {
      runInAction(() => {
        this.profileViewModel.accountPictureId = undefined;
        this.viewModel.accountPictureId = undefined;
      });
      return;
    }
    await this.runWithStateControl(async () => {
      const formData = new FormData();
      formData.append('files', file);
      // @ts-ignore
      const { data } = await FileService.create(formData);
      const newImageId = Object.values(data.fileNameToFileId || [])[0];
      this.profileViewModel.accountPictureId = newImageId;
      this.viewModel.accountPictureId = newImageId;
    });
  }

  /** Изменение пароля */
  public async changePassword() {
    await this.runWithStateControl(async () => {
      await AccountService.passwordUpdate({
        currentPassword: this.passwordChangeViewModel.currentPassword,
        newPassword: this.passwordChangeViewModel.newPassword,
      });
    });
  }

  /** Получить список сотрудников ( сделано под массив потому что в последствии будет массив )*/
  public get employees(): {id: string; fullName: string}[] {
    return [{
      id: this.profileViewModel.employeeId || '',
      fullName: this.profileViewModel.fullName || '',
    }];
  }

  @computed get responsibleInProjects() {
    return this.viewModel.projects ?? [];
  }

  @computed get responsibleForResources() {
    return this.viewModel.resources ?? [];
  }

  /** Обновить текущую подписку */
  public async updateSubscription(subscriptionTypeId: string, variantId: string) {
    let paymentUrl: string | null | undefined;
    await this.runWithStateControl(async () => {
      const { data } = await OrganizationService.subscriptionUpdate({ subscriptionTypeId, variantId });

      paymentUrl = data.paymentUrl;
    });

    return paymentUrl;
  }

  /** Получить данные текущей подписки */
  public async fetchCurrentSubscription() {
    await this.runWithStateControl(async () => {
      const { data } = await OrganizationService.lastSubscriptionList();
      runInAction(() => {
        this.currentSubscription = new SubscriptionViewModel(data);
      });
    });
  }

  /** Получить данные текущей подписки */
  public async fetchStatusPaymentTransactions(id: string, refreshToken?: string) {
    await this.runWithStateControl(async () => {
      const { data } = await OrganizationService.statusPaymentTransactionsUpdate(id);
      runInAction(() => {
        this.statusPaymentTransaction = data;
      });
    });
    await this.fetchCurrentSubscription();
    if (refreshToken) {
      await this.tryRefreshToken(refreshToken);
    }
  }
}
