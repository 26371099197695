import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import CheckBox from '../CheckBox/CheckBox';
import type { RowCheckboxProps } from './types';

/** Чекбокс выбора строки таблицы */
const RowCheckbox = <T extends RowCheckboxProps>({ data }: T) => (
  <div
    className="h-full w-full flex justify-center items-center"
    onClick={(event) => event.stopPropagation()}
  >
    <CheckBox
      checked={!!data.selected}
      onChange={() => {
        runInAction(() => {
          data.selected = !data.selected;
        });
      }}
    />
  </div>
);

export default observer(RowCheckbox);
