import { ProjectRoleService } from '@servicesApi';
import type { GetProjectRoleByIdResponse, PutRoleByIdRequest } from '@typesApi';
import { makeObservable, observable, runInAction } from 'mobx';
import type { PrivilegesStore } from '../../Privileges/PrivilegesStore';
import EntityBaseStore from '../../BaseEntityStore';
import { ProjectRolesItemViewModel } from './ProjectRolesItemViewModel';

/** Стор роли */
export class ProjectRoleStore extends EntityBaseStore<GetProjectRoleByIdResponse, ProjectRolesItemViewModel> {
  public viewModel = new ProjectRolesItemViewModel({});

  @observable private _privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.roles.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.roles.canDelete;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.roles.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.roles.canRead;
  }

  /** Получение роли по id */
  public override async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ProjectRoleService.detail(id);
      runInAction(() => {
        this.viewModel = new ProjectRolesItemViewModel(data);
      });
    });
  }

  /** Сохранение изменений роли */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      const request: PutRoleByIdRequest = this.viewModel.getRawData();
      await this.runWithStateControl(async () => {
        await ProjectRoleService.update(this.viewModel.id!, request);
      });
    }
  }

  /** Создать роль */
  public async create(): Promise<void> {
    const request = this.viewModel.getRawData();
    await this.runWithStateControl(async () => {
      await ProjectRoleService.create(request);
    });
  }

  /** Очистка стора */
  public clearStore(): void {
    this.viewModel = new ProjectRolesItemViewModel({});
  }
}
