import EntityHeader from '@components/EntityHeader';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import { useStores, useTitle } from '@hooks';
import { useEffectOnce } from 'usehooks-ts';
import SubscriptionCard, { OnPremiseTariff } from '@components/Subscriptions/SubscriptionCard';
import Errors from '@components/ui/Errors/Errors';
import OrganizationCard from '@components/OrganizationCard/OrganizationCard';
import type { SubscriptionViewModel } from 'src/stores/Account/SubscriptionViewModel';
import { SubscriptionDescription } from 'src/enums';
import { STrial, SOnline } from '@assets';
import { useLocation } from 'react-router-dom';
import InfoModal from '@components/ui/InfoModal/InfoModal';

interface LineChartProps {
  className?: string;
  label?: string;
  value: number;
  limit: number;
}

interface LocationState {
  status: string;
}

const LineChart: FC<LineChartProps> = ({
  className, label, value, limit,
}) => {
  const [v, setV] = useState(0);
  const [l, setL] = useState(0);

  useLayoutEffect(() => {
    setV(value);
  }, [value]);

  useLayoutEffect(() => {
    setL(limit);
  }, [limit]);

  return (
    <div className={`flex flex-row items-center ${className}`}>
      <div className="mr-2 w-24">{label}</div>
      <div className="flex flex-1 h-4 rounded-md bg-slate-200">
        <div
          className="relative h-4 rounded-md bg-orange-500 transition-all duration-1000"
          style={{ flex: v }}
        >
          <div className="absolute whitespace-nowrap !text-white m-auto leading-4 h-4 w-10 text-label left-0 right-0">
            {`${value} из ${limit || ''}`}
          </div>
        </div>
        <div
          className="h-4 bg-transparent"
          style={{ flex: l - v }}
        />
      </div>
    </div>
  );
};

const OwnTariff: FC<{currentSubscription: SubscriptionViewModel}> = ({ currentSubscription }) => {
  const description = currentSubscription.hasLimits
    ? SubscriptionDescription.base
    : SubscriptionDescription.online;

  return (
    <div className="border-1 border-slate-200 bg-white p-4 w-72 transition-all">
      <div className="text-label">Ваша подписка</div>
      <div className="pt-5 text-lg flex flex-row items-center">
        {currentSubscription.name}
        {currentSubscription.hasLimits
          ? (
            <STrial className="pb-1 h-6 w-6" />
          )
          : (
            <SOnline className="pb-1 h-7 w-7" />
          )}
      </div>
      {currentSubscription.finishDateText && (
        <div className="text-label text-black">
          {currentSubscription.finishDateText}
        </div>
      )}
      {!currentSubscription.hasLimits && <div className="pt-5">Доступ к системе без ограничений.</div>}
      <div className="pt-5">{description}</div>
      {currentSubscription.hasLimits && (
        <>
          <LineChart
            className="pt-5"
            label="Проекты:"
            limit={currentSubscription.projectsLimit ?? 5}
            value={currentSubscription.projectsCount}
          />
          <LineChart
            className="pt-1"
            label="Ресурсы:"
            limit={currentSubscription.resourcesLimit ?? 5}
            value={currentSubscription.resourceCount}
          />
          <LineChart
            className="pt-1"
            label="Пользователи:"
            limit={currentSubscription.accountsLimit ?? 3}
            value={currentSubscription.accountsCount}
          />
        </>
      )}
    </div>
  );
};

/** Страница организации */
const Organization: FC = () => {
  useTitle('Настройки организации');
  const { subscriptionsStore, accountStore } = useStores();
  const { currentSubscription } = accountStore;

  const location = useLocation();

  /** Статус оплаты подписки при редиректе */
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  /** Обработчик закрытия модалки статуса оплаты */
  const closeModalHandler = useCallback(() => {
    setPaymentStatus(null);
    window.history.replaceState({}, document.title);
  }, []);

  useEffectOnce(() => {
    if (location.state) {
      const { status } = location.state as LocationState;
      setPaymentStatus(status);
    }
    subscriptionsStore.fetch();
    accountStore.fetchCurrentSubscription();
    return () => {
      subscriptionsStore.clearStore();
    };
  });

  useEffect(() => {
    accountStore.fetchCurrentSubscription();
  }, [accountStore, accountStore.subscriptionId]);

  const onVariantClickHandler = useCallback(async (subscriptionTypeId: string, variantId: string) => {
    const paymentUrl = await accountStore.updateSubscription(subscriptionTypeId, variantId);
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  }, [accountStore]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-col">
        <EntityHeader
          canGoBack={false}
          header="Настройки организации"
        />
        <div className="flex flex-1 flex-col gap-4 @3xl/main:flex-row">
          <OrganizationCard className="max-w-[600px]" />
          <OwnTariff
            currentSubscription={currentSubscription}
          />
        </div>
      </div>
      <div className="flex flex-col pt-4 pb-4">
        <EntityHeader
          canGoBack={false}
          header="Доступные варианты подписки"
        />
        <div className="flex flex-col md:flex-row gap-4 overflow-y-auto pb-10">
          {subscriptionsStore.viewModel
            .flatMap((subscription) => subscription.variants?.map((variant) => ({ variant, subscription })) ?? [])
            .map(
              ({ subscription, variant }) => (
                <SubscriptionCard
                  key={`${subscription.id}-${variant.id}`}
                  cost={variant.cost}
                  description={subscription.description}
                  duration={variant.term ?? 1}
                  name={subscription.name}
                  onVariantClick={onVariantClickHandler}
                  priceDescription={variant.percent}
                  subscriptionTypeId={subscription.id}
                  variantId={variant.id!}
                />
              ),
            )}
          <OnPremiseTariff />
        </div>
      </div>
      <Errors store={subscriptionsStore} />
      <Errors
        redirectIf404={false}
        store={accountStore}
      />
      {(paymentStatus != null) && (
        <InfoModal
          header="Оплата"
          onClose={closeModalHandler}
          text={paymentStatus}
        />
      )}
    </div>
  );
};

export default observer(Organization);
