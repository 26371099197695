import type { GetResourcesInProjectItem } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import type { ResourcesInProjectsTableItem } from 'src/types/ResourcesInProjectsTableItem';
import BaseViewModel from '../BaseViewModel';

class ResourcesInProjectViewModel extends BaseViewModel<GetResourcesInProjectItem> implements ResourcesInProjectsTableItem {
  /** Ресурс выбран */
  @observable selected: boolean = false;

  constructor(data: GetResourcesInProjectItem) {
    super(data);
    makeObservable(this);
  }

  /** Получение id */
  @computed get id() {
    return this.data.resourceInProjectId ?? '';
  }

  /** Изменение id */
  set id(value: string) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** Получение name */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение name */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение resourceInProjectId */
  @computed get resourceInProjectId() {
    return this.data.resourceInProjectId;
  }

  /** Изменение resourceInProjectId */
  set resourceInProjectId(value: string | undefined) {
    runInAction(() => {
      this.data.resourceInProjectId = value;
    });
  }

  /** Получение startDate */
  @computed get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : undefined;
  }

  /** Изменение startDate */
  set startDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
    });
  }

  /** Получение finishDate */
  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : undefined;
  }

  /** Изменение finishDate */
  set finishDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
    });
  }
}

export default ResourcesInProjectViewModel;
