import React, { forwardRef } from 'react';
import type { PopupContainerProps } from './types';

const PopupContainer = forwardRef<HTMLDivElement, PopupContainerProps>(({
  children, x, y, className, width,
}, ref) => (
  <div
    ref={ref}
    className={`shadow-md rounded-secondary focus-visible:outline-none ${className}`}
    onWheel={(e) => e.stopPropagation()}
    role="dialog"
    style={{
      position: 'fixed',
      left: 0,
      top: 0,
      transform: `translate3d(${x}px, ${y}px, 0)`,
      width,
      zIndex: 1000,
    }}
    tabIndex={-1}
  >
    {children}
  </div>
));

PopupContainer.displayName = 'PopupContainer';

export default PopupContainer;
