import { ProjectActions, EntityActions, ResourceTypes } from '@typesApi';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import type { AccountStore } from '../Account/AccountStore';
import { ProjectsPrivileges } from './ProjectsPrivileges';
import { SalaryPeriodsPrivileges } from './SalaryPeriodsPrivileges';
import { CounterpartiesPrivileges } from './CounterpartiesPrivileges';
import { ResourcesPrivileges } from './ResourcesPrivileges';
import RolesPrivileges from './RolesPrivileges';
import { AccountsPrivileges } from './AccountsPrivileges';

/** Стор реестра */
export class PrivilegesStore {
  @observable private _accountStore: AccountStore;

  @observable
    roles: RolesPrivileges = new RolesPrivileges([]);

  @observable
    resources: ResourcesPrivileges = new ResourcesPrivileges([]);

  @observable
    projects: ProjectsPrivileges = new ProjectsPrivileges([]);

  @observable
    salaryPeriods: SalaryPeriodsPrivileges = new SalaryPeriodsPrivileges([]);

  @observable
    counterparties: CounterpartiesPrivileges = new CounterpartiesPrivileges([]);

  @observable
    accounts: AccountsPrivileges = new AccountsPrivileges([]);

  constructor(accountStore: AccountStore) {
    makeObservable(this);
    this._accountStore = accountStore;
    reaction(() => ({
      id: this._accountStore.employeeId,
      privileges: this.privileges,
    }), async ({ id, privileges }) => {
      this.setSubStores((id && privileges) || []);
    });
  }

  @computed get employeeRoles() {
    return this._accountStore.viewModel.employeeRoles;
  }

  @computed get privileges(): EntityActions[] | undefined {
    if (!this.employeeRoles) {
      return undefined;
    }
    const privileges = new Map(this.employeeRoles?.map((e) => ([e.employeeRoleId, e.entityActions ?? []])));

    return Array.from(privileges.values()).flat();
  }

  /** Массив проектов в которых пользователь задействован в качестве ресурса */
  @computed get resourceInProjects() {
    return this._accountStore.viewModel.resourceInProjects;
  }

  /** Наличие привилегии */
  public hasPrivilege(privilege: EntityActions | EntityActions[]) {
    if (Array.isArray(privilege)) {
      return !!this.privileges?.some((priv) => privilege.includes(priv));
    }
    return !!this.privileges?.find((priv) => priv === privilege);
  }

  /** Проектные привилегии пользователя */
  public getPrivilegesInProject(id?: string) {
    const projectRoles = this.resourceInProjects?.find((e) => e.projectId === id)?.projectRoles ?? [];
    const privileges = new Map(projectRoles.map((e) => ([e.id, e.projectActions ?? []])));
    const privilegesArray = Array.from(privileges.values()).flat();

    const projectPrivileges: {[key in ProjectActions]: boolean} = {
      UpdateProject: privilegesArray.includes(ProjectActions.UpdateProject),
      DeleteProject: privilegesArray.includes(ProjectActions.DeleteProject),
      UpdateProjectRequirement: privilegesArray.includes(ProjectActions.UpdateProjectRequirement),
      UpdateResource: privilegesArray.includes(ProjectActions.UpdateResource),
      ReadProjectRequirement: privilegesArray.includes(ProjectActions.ReadProjectRequirement),
    };

    return projectPrivileges;
  }

  /** Пользователь является ответственным в проекте по id */
  isResponsibleForProject(projectId: string) {
    return !!this._accountStore.viewModel.projects?.find((p) => p.id === projectId);
  }

  @computed get responsibleInProjects() {
    return this._accountStore.responsibleInProjects;
  }

  @computed get responsibleForResources() {
    return this._accountStore.responsibleForResources;
  }

  /** Имеются проекты где пользователь является ресурсом */
  @computed get isResourceInSomeProjects() {
    return !!this._accountStore.viewModel.resourceInProjects?.length;
  }

  /** Имеются проекты где пользователь является ответственным */
  @computed get isResponsibleForSomeProjects() {
    return !!this._accountStore.viewModel.projects?.length;
  }

  /** Привилегия на просмотр реестра сотрудников */
  @computed get canViewEmployees() {
    return this.resources.canRead || this._accountStore.responsibleForResources.some((e) => e.resourceType === ResourceTypes.Employee);
  }

  /** Привилегия на просмотр реестра потребностей */
  @computed get canViewRequirements() {
    return !!this.privileges && this.hasPrivilege(EntityActions.UpdateProjects);
  }

  /** Привилегия на просмотр реестра контрагентов */
  @computed get canViewContragents() {
    return !!this.privileges && this.hasPrivilege(EntityActions.ReadContragents);
  }

  /** Привилегия на просмотр реестра пользователей */
  @computed get canViewAccounts() {
    return !!this.privileges && this.hasPrivilege(EntityActions.ReadAccounts);
  }

  /** Привилегия на просмотр помещений */
  @computed get canViewPlaces() {
    return this.resources.canRead || this._accountStore.responsibleForResources.some((e) => e.resourceType === ResourceTypes.Place);
  }

  /** Привилегия "Оплатить подписку организации" */
  @computed get canPayOrganizationSubscription() {
    return this.hasPrivilege(EntityActions.PayOrganizationSubscription);
  }

  /** Привилегия на просмотр техники */
  @computed get canViewEquipments() {
    return this.resources.canRead || this._accountStore.responsibleForResources.some((e) => e.resourceType === ResourceTypes.Equipment);
  }

  /** Привилегия на просмотр */
  @computed get canViewProjects() {
    return !!this.privileges && (this.projects.canRead || this.isResourceInSomeProjects || this.isResponsibleForSomeProjects);
  }

  /** Привилегия на просмотр раздела администрирование */
  @computed get canViewRoles() {
    return !!this.privileges && (this.roles.canReadProjectRoles || this.roles.canRead);
  }

  @action async setSubStores(privileges: EntityActions[]) {
    this.resources = new ResourcesPrivileges(privileges, this._accountStore);
    this.projects = new ProjectsPrivileges(privileges, this._accountStore);
    this.salaryPeriods = new SalaryPeriodsPrivileges(privileges);
    this.roles = new RolesPrivileges(privileges);
    this.counterparties = new CounterpartiesPrivileges(privileges);
    this.accounts = new AccountsPrivileges(privileges);
  }
}
