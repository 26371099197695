import { Modal } from '@components';
import Button from '@components/ui/Button';
import React, { useState } from 'react';

interface TestModalProps {
}

const TestModal: React.FC<TestModalProps> = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  return (
    <div>
      <Button.Primary
        label="Показать"
        onClick={() => {
          setShowModal(true);
        }}
      />
      {showModal
      && (
        <Modal onClose={() => setShowModal(false)}>
          <div>
            Текст в модальном окне
            <Button.Primary
              label="Вторая модалка"
              onClick={() => {
                setShowModal2(true);
              }}
            />
            {showModal2 && (
              <Modal onClose={() => setShowModal2(false)}>
                <div>second modal</div>
              </Modal>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TestModal;
