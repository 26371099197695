import { PlaceService, ResourceService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetPlacesResponseItem } from '@typesApi';
import { ResourceTypes } from '@typesApi';
import { makeObservable, observable, runInAction } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';
import PlacesViewModel from './PlacesViewModel';

/** Фильтр помещений */
interface PlacesFilter extends ListBaseFilter{
  filterName?: string;
  /** Отображать удаленные? */
  isDeleted?: boolean;
}

/** Стор помещений */
class PlacesStore extends BaseEntityListStore<GetPlacesResponseItem, PlacesFilter, PlacesViewModel> {
  @observable
  private _privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super(20);
    makeObservable(this);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.resources.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.resources.canDelete
      || this._privilegesStore.responsibleForResources.some((x) => x.resourceType === ResourceTypes.Place);
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.resources.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.resources.canRead;
  }

  public async fetch(): Promise<void> {
    this.runWithStateControl(async () => {
      const { data } = await PlaceService.list(this.filter);
      runInAction(() => {
        this.setData(data, PlacesViewModel);
      });
    });
  }

  /** Архивирование */
  public async archive() {
    const selectedPlaces = this.viewModel.filter((p) => p.selected);
    await this.runWithStateControl(async () => {
      await Promise.all(selectedPlaces.map((p) => ResourceService.delete(p.id, { deleteRightNow: true })));
    });
    if (this.state.isSuccess) {
      await this.fetch();
    }
  }

  /** Восстановление выбранных помещений */
  public async restoreSelectedPlaces(): Promise<void> {
    await this.runWithStateControl(async () => {
      await Promise.all(this.viewModel.filter((e) => e.selected).map((e) => ResourceService.delete(e.id, {})));
    });
    await this.fetch();
  }
}

export default PlacesStore;
