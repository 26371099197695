module.exports = {
  theme: {
    extend: {
      colors: {
        'subscription-card-bg': '#f8f8f8',
        'subscription-card-border': 'black',
        'subscription-card-button-bg': 'rgba(0,0,0,0.2)',
        'subscription-card-button-border': '#979E9F',
        'subscription-card-button-border-last': 'black',
        'subscription-card-button-text': 'black',
      },
      fontSize: {
        'subscription-card-header': ['1rem', {
          lineHeight: '1.5rem',
          fontWeight: '700',
        }],
        'subscription-card-subheader': ['1.5rem', {
          lineHeight: '2rem',
          fontWeight: '700',
        }],
        'subscription-card-cost': ['1rem', {
          lineHeight: '1.5rem',
          fontWeight: '600',
        }],
      },
      borderWidth: {
        'subscription-card-button-border': '1px',
        'subscription-card-button-border-last': '1px',
        'subscription-card-border': '1px',
      },
      width: {
        'subscription-card': '250px',
      },
      height: {
        'subscription-card': '400px',
      },
      padding: {
        'subscription-card-y': '2rem',
        'subscription-card-x': '1.5rem',
      },
    },
  },
};
