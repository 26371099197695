import useDebouncedCallback from '@services/useDebounceCallback';
import React, { useCallback, useEffect, useState } from 'react';
import { Placeholders } from 'src/enums';
import MultiSelect from '../MultiSelect/MultiSelect';
import type { AsyncMultiSelectPorps } from '../MultiSelect/types';
import type { SelectOption } from '../types';

/** Асинхронный множественный селект */
const MultiAsyncSelect: React.FC<AsyncMultiSelectPorps> = (props) => {
  const {
    onSelectOption,
    loading,
    onChange,
    defaultOptions,
    cacheOptions = true,
    onBlur,
    placeholder = Placeholders.startTyping,
  } = props;

  /** Опции (меняются при асинхронном вызове onChangeHandler)  */
  const [filteredOptions, setFilteredOptions] = useState(defaultOptions);
  /** Состояние загрузки результатов */
  const [isLoading, setIsLoading] = useState(false);

  /** Обработчик ввода */
  const onChangeDebounce = useDebouncedCallback(async (value?: string) => {
    setIsLoading(true);
    const result = await onChange?.(value);

    setIsLoading(false);
    if (result) {
      setFilteredOptions(result);
    }
  }, 400);

  const onChangeCallback = useCallback(
    async (value?: string) => {
      setIsLoading(true);
      await onChangeDebounce(value);
    },
    [onChangeDebounce],
  );

  useEffect(() => {
    setFilteredOptions(defaultOptions);
  }, [defaultOptions]);

  /** Возвращение опций по умолчанию */
  const returnDefaultOptions = useCallback(() => {
    /** Только в случае отсутвия флага кэширования предыдущего результата */
    if (!cacheOptions) {
      setFilteredOptions(defaultOptions);
    }
  }, [cacheOptions, defaultOptions]);

  /** Выбор опции */
  const onSelectHandler = useCallback((option?: SelectOption[]) => {
    returnDefaultOptions();
    if (onSelectOption) {
      onSelectOption(option);
    }
  }, [onSelectOption, returnDefaultOptions]);

  /** Возврат опций на onBlur если по введенному запросу ничего не найдено */
  const onBlurHandler = useCallback(() => {
    if (!filteredOptions?.length) {
      returnDefaultOptions();
    }
    if (onBlur) {
      onBlur();
    }
  }, [filteredOptions?.length, onBlur, returnDefaultOptions]);

  return (
    <MultiSelect
      {...props}
      loading={loading || isLoading}
      onBlur={onBlurHandler}
      onChange={onChangeDebounce}
      onOptionsShow={defaultOptions?.length ? undefined : onChangeCallback}
      onSelectOption={onSelectHandler}
      options={filteredOptions}
      placeholder={placeholder}
    />
  );
};

export default MultiAsyncSelect;
