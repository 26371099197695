import {
GetAccountByIdResponse,
GetAccountResponse,
GetAccountsResponse,
PostInviteTokenRequest,
PostInviteTokenResponse,
ProblemDetails,
ProblemDetailsResponse,
PutAccountRequest,
PutAccountRolesRequest,
PutPasswordRequest,
SetAccountStateRequest,
TokenRequest,
TokenResponse} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class AccountService {
  static get RoutePath(): string {
    return "Account";
  }

  /**
   * Получение информации об аккаунте
   *
   * @request GET:/api/v1.0/Account/Me
   */
  static meList = async (params: RequestParams = {}) =>
    httpClient.request<GetAccountResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Me`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление данных аккаунта
   *
   * @request PUT:/api/v1.0/Account/Me
   */
  static meUpdate = async (data: PutAccountRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Me`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка аккаунтов
   *
   * @request GET:/api/v1.0/Account
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      name?: string;
      roles?: string[];
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetAccountsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Получить токен для приглашения нового сотрудника к себе в организацию
   *
   * @request POST:/api/v1.0/Account/InviteToken
   */
  static inviteTokenCreate = async (data: PostInviteTokenRequest, params: RequestParams = {}) =>
    httpClient.request<PostInviteTokenResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/InviteToken`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Запрос получения аккаунта со списком глобальных ролей
   *
   * @request GET:/api/v1.0/Account/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetAccountByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление списка глобальных ролей аккаунта
   *
   * @request PUT:/api/v1.0/Account/{id}/Roles
   */
  static rolesUpdate = async (id: string, data: PutAccountRolesRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/Roles`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Установка состояния аккаунта
   *
   * @request PUT:/api/v1.0/Account/{id}/State
   */
  static stateUpdate = async (id: string, data: SetAccountStateRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/State`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Изменение пароля
   *
   * @request PUT:/api/v1.0/Account/Password
   */
  static passwordUpdate = async (data: PutPasswordRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Password`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}