import { RoleService } from '@servicesApi';
import type { GetRoleByIdResponse, PutRoleByIdRequest } from '@typesApi';
import { makeObservable, observable, runInAction } from 'mobx';
import EntityBaseStore from '../../BaseEntityStore';
import type { RolePrivilegesStore } from './RolePrivilegesStore';
import { RoleViewModel } from '../Roles/RolesItemViewModel';
import type { PrivilegesStore } from '../../Privileges/PrivilegesStore';

/** Стор роли */
export class RoleStore extends EntityBaseStore<GetRoleByIdResponse, RoleViewModel> {
  public viewModel = new RoleViewModel({});

  @observable private _privilegesStore: PrivilegesStore;

  public rolePrivilegesStore: RolePrivilegesStore;

  constructor(rolePrivilegesStore: RolePrivilegesStore, privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this.rolePrivilegesStore = rolePrivilegesStore;
    this.rolePrivilegesStore.setPrivileges([]);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.roles.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.roles.canDelete;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.roles.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.roles.canRead;
  }

  /** Получение роли по id */
  public override async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await RoleService.detail(id);
      runInAction(() => {
        this.viewModel = new RoleViewModel(data);
        this.rolePrivilegesStore.setPrivileges((this.viewModel.entityActions ?? []));
      });
    });
  }

  /** Сохранение изменений роли */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      const request: PutRoleByIdRequest = this.viewModel.getRawData();
      await this.runWithStateControl(async () => {
        await RoleService.update(this.viewModel.id!, request);
      });
    }
  }

  /** Создать роль */
  public async create(): Promise<void> {
    const request = this.viewModel.getRawData();
    await this.runWithStateControl(async () => {
      await RoleService.create(request);
    });
  }

  /** Очистка стора */
  public clearStore(): void {
    this.viewModel = new RoleViewModel({});
  }
}
