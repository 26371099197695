import {
EventTypes,
GetProjectRoleByIdResponse,
GetProjectRolesResponse,
PostProjectRoleRequest,
PostProjectRoleResponse,
ProblemDetails,
ProblemDetailsResponse,
ProjectActions,
PutProjectRoleByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class ProjectRoleService {
  static get RoutePath(): string {
    return "ProjectRole";
  }

  /**
   * Создание проектной роли
   *
   * @request POST:/api/v1.0/ProjectRole
   */
  static create = async (data: PostProjectRoleRequest, params: RequestParams = {}) =>
    httpClient.request<PostProjectRoleResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение списка проектных ролей
   *
   * @request GET:/api/v1.0/ProjectRole
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      projectRoles?: string[];
      projectRoleName?: string;
      projectActions?: ProjectActions[];
      eventTypes?: EventTypes[];
      isDeleted?: boolean;
      projectAction?: ProjectActions;
      hasProjectAction?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetProjectRolesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Обновление проектной роли
   *
   * @request PUT:/api/v1.0/ProjectRole/{id}
   */
  static update = async (id: string, data: PutProjectRoleByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение проектной роли по ид
   *
   * @request GET:/api/v1.0/ProjectRole/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetProjectRoleByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Удаление проектной роли по ид
   *
   * @request DELETE:/api/v1.0/ProjectRole/{id}
   */
  static delete = async (id: string, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      ...params,
    });
}