import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, runInAction } from 'mobx';
import { DateTypeFormat } from '@types';
import type { GetEmployeeByIdResponse } from '@typesApi';
import { required } from '@services/validationFunctions';
import { joinNonEmpty } from '@services/utils';
import type { SelectOption } from '@components/ui/Select';
import BaseViewModel from '../BaseViewModel';
import { EmployeeProjectsStore } from './EmployeeProjectsStore';

/** Вью модель сущности сотрудника */
export class EmployeeViewModel extends BaseViewModel<GetEmployeeByIdResponse> {
  public projects: EmployeeProjectsStore;

  constructor(data: GetEmployeeByIdResponse) {
    super(data);
    this.projects = new EmployeeProjectsStore(data.resourceInProjects ?? []);
  }

  /** Получение  ИД*/
  @computed get id() {
    return this.data.id;
  }

  set id(value: string | undefined) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** Получение имени */
  @required()
  @computed get name() {
    return this.data.name;
  }

  /** Изменение имени */
  set name(value: string | undefined | null) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение фамилии */
  @required()
  @computed get surname() {
    return this.data.surname;
  }

  /** Изменение фамилии */
  set surname(value: string | undefined | null) {
    runInAction(() => {
      this.data.surname = value;
    });
  }

  /** Получение отчества */
  @computed get patronymic() {
    return this.data.patronymic;
  }

  /** Изменение отчества */
  set patronymic(value: string | undefined | null) {
    runInAction(() => {
      this.data.patronymic = value;
    });
  }

  /** Получение Электронная почта */
  @required('email')
  @computed get email() {
    return this.data.email;
  }

  /** Изменение Электронная почта */
  set email(value: string | undefined | null) {
    runInAction(() => {
      this.data.email = value;
    });
  }

  /** Получение Телефон */
  @required('phone')
  @computed get phone() {
    return this.data.phone;
  }

  /** Изменение Телефон */
  set phone(value: string | undefined | null) {
    runInAction(() => {
      this.data.phone = value;
    });
  }

  /** Получение даты рождения */
  @computed get birthdate() {
    return this.data.birthdate ? dayjs(this.data.birthdate) : null;
  }

  /** Изменение даты рождения */
  set birthdate(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.birthdate = value?.toISOString();
    });
  }

  /** Получение наименования специализации*/
  @computed get mainSpecializationName() {
    return this.data.mainSpecializationName;
  }

  /** Изменение наименования специализации*/
  set mainSpecializationName(value: string | undefined | null) {
    runInAction(() => {
      this.data.mainSpecializationName = value;
    });
  }

  /** Получение ИД специализации */
  @computed get mainSpecializationId() {
    return this.data.mainSpecializationId;
  }

  /** Изменение ИД специализации */
  set mainSpecializationId(value: string | undefined | null) {
    runInAction(() => {
      this.data.mainSpecializationId = value;
    });
  }

  /** Получение наименования должности */
  @computed get mainQualificationId() {
    return this.data.mainQualificationId;
  }

  /** Изменение наименования должности */
  set mainQualificationId(value: string | undefined | null) {
    runInAction(() => {
      this.data.mainQualificationId = value;
    });
  }

  /** Получение наименования должности */
  @computed get mainQualificationName() {
    return this.data.mainQualificationName;
  }

  /** Изменение наименования должности */
  set mainQualificationName(value: string | undefined | null) {
    runInAction(() => {
      this.data.mainQualificationName = value;
    });
  }

  /** Получение ИД должности */
  @computed get mainPositionId() {
    return this.data.positionId;
  }

  /** Изменение ИД должности */
  set mainPositionId(value: string | undefined | null) {
    runInAction(() => {
      this.data.positionId = value;
    });
  }

  /** Получение ИД должности */
  @computed get mainPositionName() {
    return this.data.positionName;
  }

  /** Изменение ИД должности */
  set mainPositionName(value: string | undefined | null) {
    runInAction(() => {
      this.data.positionName = value;
    });
  }

  /** Получение удаленная работа */
  @computed get isRemoteWork() {
    return this.data.isRemoteWork;
  }

  /** Изменение удаленная работа */
  set isRemoteWork(value: boolean | undefined | null) {
    runInAction(() => {
      this.data.isRemoteWork = value;
    });
  }

  /** Получение имя ментора */
  @computed get mentorName() {
    return this.data.mentorName;
  }

  /** Изменение имя ментора */
  set mentorName(value: string | undefined | null) {
    runInAction(() => {
      this.data.mentorName = value;
    });
  }

  /** Получение ИД ментора */
  @computed get mentorId() {
    return this.data.mentorId;
  }

  /** Изменение ИД ментора */
  set mentorId(value: string | null | undefined) {
    runInAction(() => {
      this.data.mentorId = value;
    });
  }

  /** Получить ФИО */
  @computed
  get fullName(): string {
    return joinNonEmpty([this.surname, this.name, this.patronymic]);
  }

  /** Получить "Актуальный оклад" */
  @computed get actualSalary() {
    return this.data.actualSalary;
  }

  /** Изменить "Актуальный оклад" */
  set actualSalary(value: number | null | undefined) {
    runInAction(() => {
      this.data.actualSalary = value;
    });
  }

  /** Получить "Актуальная ставка в час" */
  @computed get actualWorkingRate() {
    return this.data.actualWorkingRate;
  }

  /** Изменить "Актуальная ставка в час" */
  set actualWorkingRate(value: number | null | undefined) {
    runInAction(() => {
      this.data.actualWorkingRate = value;
    });
  }

  /** Получение отформатированной "Ближайшая карьерная встреча" */
  @computed get nextCareerMeetingFormatted() {
    return this.data.nextCareerMeeting ? dayjs(this.data.nextCareerMeeting).format(DateTypeFormat) : '';
  }

  /** Получение "Ближайшая карьерная встреча"*/
  @computed get nextCareerMeeting() {
    return this.data.nextCareerMeeting ? dayjs(this.data.nextCareerMeeting) : null;
  }

  /** Изменение "Ближайшая карьерная встреча" */
  set nextCareerMeeting(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.nextCareerMeeting = value?.toISOString();
    });
  }

  @computed get resourceInProjects() {
    return this.data.resourceInProjects;
  }

  /** Получение responsibleId */
  @computed get responsibleId() {
    return this.data.responsibleId;
  }

  /** Изменение responsibleId */
  set responsibleId(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleId = value;
    });
  }

  /** Получение responsibleSurname */
  @computed get responsibleSurname() {
    return this.data.responsibleSurname;
  }

  /** Изменение responsibleSurname */
  set responsibleSurname(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleSurname = value;
    });
  }

  /** Получение responsibleName */
  @computed get responsibleName() {
    return this.data.responsibleName;
  }

  /** Изменение responsibleName */
  set responsibleName(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleName = value;
    });
  }

  /** Получение responsiblePatronymic */
  @computed get responsiblePatronymic() {
    return this.data.responsiblePatronymic;
  }

  /** Изменение responsiblePatronymic */
  set responsiblePatronymic(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsiblePatronymic = value;
    });
  }

  @computed get responsible(): SelectOption | undefined {
    return this.responsibleId ? {
      label: joinNonEmpty([this.responsibleSurname, this.responsibleName, this.responsiblePatronymic]),
      value: this.responsibleId,
    } : undefined;
  }

  set responsible(val: SelectOption | undefined) {
    this.responsibleName = val?.label;
    this.responsibleId = val?.value;
    this.responsiblePatronymic = undefined;
    this.responsibleSurname = undefined;
  }

  @computed get deletedOn() {
    return this.data.deletedOn ? dayjs(this.data.deletedOn) : null;
  }

  /** Получение флага Архивная сущность */
  @computed get isDeleted() {
    return this.data.isDeleted ?? false;
  }
}
