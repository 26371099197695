import Popup from '@components/Popup/Popup';
import Button from '@components/ui/Button';
import { useStores } from '@hooks';
import { isCurrentWidthLessThanFullWidth, parseStyles } from '@services/utils';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import TitlePopup from '@components/ui/TitlePopup/TitlePopup';
import graphConfig from '../graph.config';

const ResourcesListTable: React.FC<{
  rowWidth: string;
}> = ({ rowWidth }) => {
  const { resourcesLoadStore, privilegesStore } = useStores();
  const {
    rowHeight,
    viewModel,
  } = resourcesLoadStore;

  const navigate = useNavigate();

  const popupRef = useRef<HTMLDivElement>(null);

  const [target, setTarget] = useState<{
    element: HTMLDivElement;
    id: string;
  } | null>(null);

  useOnClickOutside(popupRef, () => {
    setTarget(null);
  });

  /** Элемент над которым будет попап */
  const [cellTarget, setCellTarget] = useState<HTMLDivElement>();

  /** Данные для попапа с подсказкой */
  const [popupData, setPopupData] = useState<{text: string; show: boolean} | null>(null);

  /** Показывать ли попап с подсказкой */
  const [showPopup, setShowPopup] = useState(false);

  /** Обработчик наведения на ячейку с текстом */
  const onCellHover = useCallback((name: string, element: HTMLDivElement) => {
    if (!isCurrentWidthLessThanFullWidth(element)) {
      return;
    }

    setShowPopup(true);
    setPopupData({ text: name, show: false });
    setCellTarget(element);
  }, []);

  /** Скрытие/показ попапа по таймеру */
  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setPopupData((prev) => {
          if (showPopup) {
            return { text: prev?.text || '', show: true };
          }
          return null;
        });
      },
      400,
    );

    return () => clearTimeout(timeout);
  }, [showPopup, popupData]);

  return (
    <>
      <div
        className="table bg-white"
      >
        {viewModel.map((resource) => (
          <div
            key={`${resource.resourceId}`}
            className="table-row overflow-ellipsis [&:last-child>*]:border-b-0"
            style={{ height: rowHeight }}
          >
            <div
              className={parseStyles`
              table-cell align-middle whitespace-nowrap overflow-hidden overflow-ellipsis border-b-graph-border border-r-graph-border 
            `}
              style={{
                minWidth: `calc(${rowWidth} - ${graphConfig.theme.contextMenuIconWidth}px)`,
                maxWidth: `calc(${rowWidth} - ${graphConfig.theme.contextMenuIconWidth}px)`,
              }}
              title={resource.resourceName ?? undefined}
            >
              <div className="flex">
                <div
                  className="pl-2 text-sm font-semibold truncate"
                  onMouseEnter={(e) => onCellHover(resource.resourceName || '', e.currentTarget)}
                  onMouseLeave={() => setShowPopup(false)}
                >
                  {resource.resourceName}

                </div>
              </div>
            </div>
            <div
              className="table-cell align-middle border-b-graph-border border-x-graph-border border-solid cursor-pointer w-graph-context-menu"
              onClick={(e) => {
                setTarget({ element: e.currentTarget, id: resource.resourceId ?? '' });
              }}
              title={resource.resourceName ?? ''}
            >
              <AiOutlinePlus className="m-auto" />
            </div>
          </div>
        ))}
      </div>
      {(target && privilegesStore.resources.canRead) && (
        <Popup
          ref={popupRef}
          position="right"
          targetRef={target.element}
        >
          <div className="flex flex-col p-3 gap-2 bg-white rounded-md border-border border-1 shadow-xl">
            <Button.Primary
              label="Перейти к карточке"
              onClick={() => {
                setTarget(null);
                navigate(`/resources/employees/${target.id}`);
              }}
            />
          </div>
        </Popup>
      )}
      {(cellTarget && popupData?.show && showPopup) && (
        <TitlePopup
          element={cellTarget}
          maxWidth={`calc(${rowWidth} - ${graphConfig.theme.contextMenuIconWidth}px)`}
          setShowPopup={(value) => setShowPopup(value)}
          text={popupData.text}
        />
      )}
    </>

  );
};

export default observer(ResourcesListTable);
