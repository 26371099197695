import { ProjectService, ResourceService } from '@servicesApi';
import type { ListBaseFilter, Period, UnavailablePeriod } from '@types';
import { ViewMode } from '@types';
import type {
  GetLoadResourceInProjectsResponseItem,
  GetLoadResourceInProjectsResponseItemLoadResourceInProject,
  GetLoadResourceInProjectsResponseItemUnavailableResource,
  GetResourceInProjectsResponseUnavailableResourceItem,
  ResourceTypes,
} from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { seedDates } from '@components/CalendarTable/helpers';
import utc from 'dayjs/plugin/utc';
import { dayOfWeekEnumToNumber, paramsSerializer } from '@services/utils';
import type { DayData } from '@components/CalendarTable/types';
import { ProjectLoadViewModel } from './ProjectLoadViewModel';
import BaseEntityListStore from '../BaseEntityListStore';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

dayjs.extend(utc);

const startOfYear = dayjs.utc().startOf('year').utc();

function getDateIndex(date: Dayjs, startDate: Date, viewMode: ViewMode) {
  switch (viewMode) {
    case ViewMode.Day: return dayjs.utc(date).utc().diff(startDate, 'day');
    case ViewMode.Week: return dayjs.utc(date).utc().diff(startDate, 'week');
    case ViewMode.Month: return dayjs.utc(date).utc().diff(startDate, 'month');
    default: return dayjs.utc(date).utc().diff(startDate, 'day');
  }
}

interface Filter extends ListBaseFilter {
  filterName?: string;
  startDateFrom?: string;
  startDateTo?: string;
  finishDateFrom?: string;
  finishDateTo?: string;
  isDeleted?: boolean;
  resourceTypes?: ResourceTypes[];
}

export class ProjectLoadStore extends BaseEntityListStore<GetLoadResourceInProjectsResponseItem, Filter, ProjectLoadViewModel> {
  @observable privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super(10);
    makeObservable(this);
    this.privilegesStore = privilegesStore;
    reaction(() => ({ period: this.period, startDate: this.startDate, id: this.id }), () => {
      this.filter.pageNumber = 1;
      this.filter.startDateTo = dayjs.utc(this.endDate).endOf('day').toISOString();
      this.filter.finishDateFrom = dayjs.utc(this.startDate).startOf('day').toISOString();
      this.fetch(true);
    });
  }

  /** id */
  @observable id?: string;

  /** пока работает на половину, в будущем юнит в клетке День/Неделя/Месяц */
  @observable viewMode: ViewMode = ViewMode.Day;

  // #region Размеры и скролл таблички
  @observable scrollTop = 0;

  @observable scrollLeft = 0;

  @observable columnWidth = 25;

  @observable rowHeight = 25;

  @observable width = 0;

  @observable height = 0;
  // #endregion

  @observable startDate: Date = startOfYear.utc().toDate();

  @observable period: number = 3;

  @computed get firstRenderedColumn() {
    return Math.floor((this.scrollLeft - (this.width / 2)) / this.columnWidth);
  }

  @computed get lastRenderedColumn() {
    return Math.ceil((this.scrollLeft + (this.width * 1.5)) / this.columnWidth);
  }

  @computed get firstRenderedRow() {
    return Math.floor((this.scrollTop - (this.height / 2)) / this.rowHeight);
  }

  @computed get lastRenderedRow() {
    return Math.ceil((this.scrollTop + (this.height * 1.5)) / this.rowHeight);
  }

  @computed get endDate(): Date {
    return dayjs.utc(this.startDate).add(this.period, 'month').subtract(1, 'day').toDate();
  }

  @computed get unitForDayJS() {
    return this.viewMode.toLocaleLowerCase() as 'day' | 'week' | 'month' | 'year';
  }

  @computed get seededDates() {
    return seedDates(this.startDate, this.endDate, this.viewMode);
  }

  /** массив для всех ресурсов с нагрузкой по дням на проекте */
  @computed({ requiresReaction: true }) get rowsDayToLoadMap(): Map<number, {load: number; weekend: boolean}>[] {
    const startTime = performance.now();
    const res = this.viewModel
      .map((e) => {
        const map = new Map<number, {load: number; weekend: boolean}>();
        const currentProject = e.projects?.find((el) => el.projectId === this.id);
        currentProject?.loadResourceInProjects
          ?.forEach((p) => {
            const periodStartIndex = getDateIndex(dayjs.utc(p.startDate!).utc(), this.startDate, this.viewMode);
            const periodEndIndex = getDateIndex(dayjs.utc(p.finishDate!).utc(), this.startDate, this.viewMode) + 1;
            for (let index = periodStartIndex; index < periodEndIndex; index++) {
              const workingDays = (p.workingDaysOfWeek ?? currentProject.workingDaysOfWeek)!.map(dayOfWeekEnumToNumber);
              const weekend = !p.isWorkOnWeekends && !workingDays.includes(this.seededDates[index]?.getDay());
              map.set(index, {
                load: weekend ? 0 : p.workingHoursPerDay ?? 0,
                weekend,
              });
            }
          });
        return map;
      });
    const endTime = performance.now();
    console.log(`Call1 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** нагрузка всех ресурсов по дням среди всех проектов */
  @computed({ requiresReaction: true }) get resourcesLoad(): Map<string, Map<number, number>> {
    const startTime = performance.now();
    const map = new Map<string, Map<number, number>>();
    this.viewModel.forEach((e) => {
      const resourceId = e.id!;
      if (map.has(resourceId)) {
        return;
      }
      map.set(resourceId, new Map<number, number>());
      const insideMap = map.get(resourceId)!;
      e.projects
        ?.forEach((project) => {
          project.loadResourceInProjects?.forEach((period) => {
            const periodStartIndex = getDateIndex(dayjs.utc(period.startDate!).utc(), this.startDate, this.viewMode);
            const periodEndIndex = getDateIndex(dayjs.utc(period.finishDate!).utc(), this.startDate, this.viewMode) + 1;
            for (let index = periodStartIndex; index < periodEndIndex; index++) {
              const workingDays = (period.workingDaysOfWeek ?? project.workingDaysOfWeek)!.map(dayOfWeekEnumToNumber);
              const isWorkDay = period.isWorkOnWeekends || workingDays.includes(this.seededDates[index]?.getDay());
              const workingHoursThatDay = isWorkDay ? period.workingHoursPerDay! : 0;
              insideMap.set(
                index,
                (insideMap.get(index) ?? 0) + workingHoursThatDay,
              );
            }
          });
        });
    });
    const endTime = performance.now();
    console.log(`Call2 took ${endTime - startTime} milliseconds`);
    return map;
  }

  /** массив данных для каждой строки */
  @computed({ requiresReaction: true }) get preparedResources(): {
    resourceName: string;
    resourceInProjectId: string;
    resourceId: string;
    days: DayData[];
    unavailableResources: GetLoadResourceInProjectsResponseItemUnavailableResource[];
    periods: GetLoadResourceInProjectsResponseItemLoadResourceInProject[];
    rowIndex: number;
    canEditWorkload: boolean;
    canEditUnavailability: boolean;
  }[] {
    const startTime = performance.now();

    const res = this.rowsDayToLoadMap.map((e, i) => {
      const resource = this.viewModel.at(i)!;
      const project = resource.projects?.find((el) => el.projectId === this.id);

      const resourcePeriods = project?.loadResourceInProjects ?? [];

      return {
        resourceName: resource.name!,
        resourceId: resource.id!,
        resourceInProjectId: resource.resourceInProjectId!,
        unavailableResources: resource.unavailableResources ?? [],
        periods: resourcePeriods,
        days: Array.from(e, ([key, val]) => ({
          index: key,
          hours: val.weekend ? 0 : val.load,
          text: this.seededDates[key]?.toLocaleDateString(),
          total: this.resourcesLoad.get(resource.id!)!.get(key)!,
          weekend: val.weekend,
        })),
        canEditWorkload: this.privilegesStore.projects.canUpdate
          || this.privilegesStore.getPrivilegesInProject(project!.projectId).UpdateResource
          || this.privilegesStore.responsibleInProjects.some((p) => p.id === project!.projectId),
        canEditUnavailability: this.privilegesStore.resources.canUpdate
          || this.privilegesStore.responsibleForResources.some((r) => r.id === resource!.id),
        rowIndex: i,
      };
    });
    const endTime = performance.now();
    console.log(`Call3 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** только не свёрнутые и видимые на странице данные */
  @computed get visibleResources() {
    const startTime = performance.now();

    const res = this.preparedResources
      .slice(Math.max(0, this.firstRenderedRow), this.lastRenderedRow)
      .map((e) => ({
        ...e,
        days: e.days.filter((day) => {
          const columnFits = day.index > this.firstRenderedColumn && day.index < this.lastRenderedColumn;

          return columnFits;
        }),
      }));
    const endTime = performance.now();
    console.log(`Call5 took ${endTime - startTime} milliseconds`);
    return res;
  }

  @action async addResourceLoad(
    period: Period,
    y: number,
  ) {
    const resource = this.preparedResources.at(y)!;
    await ResourceService.resourceInProjectUpdate(resource.resourceInProjectId, {
      ...period,
      startDate: period.startDate.toISOString(),
      finishDate: period.finishDate.toISOString(),
    });
    this.reload();
  }

  @action async addUnavailabilityPeriod(period: UnavailablePeriod, y: number) {
    const resource = this.preparedResources.at(y)!;
    await ResourceService.unavailableReasonUpdate(resource.resourceId!, {
      ...period,
      startDate: period.startDate.toISOString(),
      finishDate: period.finishDate.toISOString(),
    });
    this.reload();
  }

  @action async removeResourceLoad(startDate: Dayjs, finishDate: Dayjs, y: number) {
    const resource = this.preparedResources.at(y)!;
    await ResourceService.loadResourceInProjectDelete(resource.resourceInProjectId!, {
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
    });
    this.reload();
  }

  @action async changePeriod(
    y: number,
    period: GetLoadResourceInProjectsResponseItemLoadResourceInProject,
    startDate: Dayjs,
    finishDate: Dayjs,
  ) {
    if (finishDate.isBefore(startDate)) {
      await this.removeResourceLoad(dayjs(period.startDate), dayjs(period.finishDate), y);
      return;
    }
    const resource = this.preparedResources.at(y)!;
    await ResourceService.resourceInProjectUpdate(resource.resourceInProjectId!, {
      ...period,
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
      deleteFinishDate: period.finishDate,
      deleteStartDate: period.startDate,
    });

    this.reload();
  }

  @action async changeUnavailablePeriod(
    y: number,
    period: GetResourceInProjectsResponseUnavailableResourceItem,
    startDate: Dayjs,
    finishDate: Dayjs,
  ) {
    if (finishDate.isBefore(startDate)) {
      await this.deleteUnavailabilityPeriod(y, period);
      return;
    }
    const resource = this.preparedResources.at(y)!;
    await ResourceService.unavailableReasonUpdate(resource.resourceId!, {
      ...period,
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
      deleteFinishDate: period.finishDate,
      deleteStartDate: period.startDate,
    });

    this.reload();
  }

  @action async deleteUnavailabilityPeriod(y: number, period: GetResourceInProjectsResponseUnavailableResourceItem) {
    const resource = this.preparedResources.at(y)!;
    await ResourceService.unavailableReasonDelete(resource.resourceId!, {
      startDate: period.startDate!,
      finishDate: period.finishDate!,
    });
    this.reload();
  }

  /** Перезагрузка всего что уже отображается  */
  public async reload(): Promise<void> {
    if (!this.id) {
      return;
    }
    await this.runWithStateControl(async () => {
      const { data } = await ProjectService.loadResourceInProjectsDetail(
        this.id!,
        { ...this.filter, pageSize: this.getRawData().length || this.filter.pageSize, pageNumber: 1 },
        { paramsSerializer },
      );
      this.setData(data, ProjectLoadViewModel);
      this.fetch(true);
    });
  }

  /** Загрузка потребностей по фильтру или новая страница  */
  public async fetch(first = false): Promise<void> {
    if (!this.id) {
      return;
    }
    await this.runWithStateControl(async () => {
      const { data } = await ProjectService.loadResourceInProjectsDetail(
        this.id!,
        this.filter,
        { paramsSerializer },
      );

      this.setData(first ? data : {
        entities: [...this.getRawData(), ...data.entities ?? []],
        totalCount: data.totalCount,
      }, ProjectLoadViewModel);
    });
  }
}
