import type { SelectOption } from '@components/ui/Select';
import { ResourceService } from '@servicesApi';
import type { GetResourceInProjectByIdResponse } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import type EmployeeNamesStore from '../Employees/EmployeeNamesStore';
import EmployeeInProjectViewModel from './EmployeeInProjectViewModel';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

class EmployeeInProjectStore extends EntityBaseStore<GetResourceInProjectByIdResponse, EmployeeInProjectViewModel> {
  public viewModel = new EmployeeInProjectViewModel({});

  /** Стор пользователей */
  @observable private _employeeNamesStore: EmployeeNamesStore;

  @observable private _privilegesStore: PrivilegesStore;

  /** Наименования пользователей */
  public employeeNames?: SelectOption[];

  constructor(employeeNamesStore: EmployeeNamesStore, privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this._employeeNamesStore = employeeNamesStore;
    this._privilegesStore = privilegesStore;
  }

  @computed public get canDeleteEmployee(): boolean {
    return this._privilegesStore.projects.canUpdate
      || this._privilegesStore.resources.canUpdate
      || this._privilegesStore.isResponsibleForProject(this.viewModel.projectId ?? '');
  }

  /** Запрос ресурса по id */
  public async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ResourceService.resourceInProjectDetail(id);
      runInAction(() => {
        this.viewModel = new EmployeeInProjectViewModel(data);
      });
    });
  }

  public async save(): Promise<void> {
    if (this.viewModel.resourceId && this.viewModel.projectId) {
      await this.runWithStateControl(async () => {
        const { data } = await ResourceService.projectUpdate(this.viewModel.resourceId!, this.viewModel.projectId!, {
          workingRateInProject: this.viewModel.workingRateInProject,
          projectRoles: this.viewModel.projectRoles?.map((role) => role.value ?? ''),
        });
        runInAction(() => {
          this.viewModel.id = data.id;
        });
      });
      if (this.viewModel.id) {
        await this.fetch(this.viewModel.id);
      }
    }
  }

  /** Получить список опций для селекта */
  public async fetchEmployeeNames(search?: string) {
    const names = await this._employeeNamesStore.fetchEmployeeNames(search, undefined, true, this.viewModel.projectId);

    runInAction(() => {
      this.employeeNames = names;
    });
    return this.employeeNames;
  }
}

export default EmployeeInProjectStore;
