import type { GetContragentByIdResponse } from '@typesApi';
import { makeObservable, observable, runInAction } from 'mobx';
import { ContragentService } from '@servicesApi';
import EntityBaseStore from '../BaseEntityStore';
import { CounterpartyViewModel } from './CounterpartyViewModel';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

export class CounterpartyStore extends EntityBaseStore<GetContragentByIdResponse, CounterpartyViewModel> {
  public viewModel = new CounterpartyViewModel({});

  @observable private _privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.counterparties.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.counterparties.canDelete;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.counterparties.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.counterparties.canRead;
  }

  /** Фетч сущности */
  public override async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ContragentService.detail(id);
      runInAction(() => {
        this.viewModel = new CounterpartyViewModel(data);
      });
    });
  }

  /** Сохранение изменений контрагента */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await ContragentService.update(this.viewModel.id!, this.viewModel.getRawData());
      });
    } else {
      await this.runWithStateControl(async () => {
        await ContragentService.create(this.viewModel.getRawData());
      });
    }
  }

  /** Добавление контрагента */
  public async add(): Promise<string | undefined> {
    let newEmployeeId: string | undefined;
    await this.runWithStateControl(async () => {
      const { data } = await ContragentService.create(this.viewModel.getRawData());
      newEmployeeId = data?.id;
    });

    return newEmployeeId;
  }

  /** Архивировать */
  public async archive(): Promise<void> {
    await this.runWithStateControl(async () => {
      await ContragentService.delete(this.viewModel.id!, {
        deleteRightNow: true,
      });
    });
  }
}
