import type { PropsWithChildren } from 'react';
import React from 'react';
import { ImFilter, ImSortAlphaAsc, ImSortAlphaDesc } from 'react-icons/im';
import { RxDragHandleDots2 } from 'react-icons/rx';
import type { HeaderCellProps, Row } from '../types';

/** Ячейка хедера таблицы */
const HeaderCell = <T extends Row, EntityT >({
  column, isAscending, onFilterPress, onReorderPress,
}: PropsWithChildren<HeaderCellProps<T, EntityT>>) => {
  if (typeof column.header === 'string') {
    return (
      <div className="flex gap-1 items-center justify-center max-w-full mx-header-x my-header-y select-none">
        {onReorderPress && (
          <RxDragHandleDots2
            className="z-10 cursor-pointer shrink-0"
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onReorderPress?.(e.clientX, e.clientY);
            }}
          />
        )}
        <span className="text-ellipsis overflow-hidden">{column.header}</span>
        {isAscending && (
          <ImSortAlphaAsc
            className="shrink-0"
            height={20}
            width={20}
          />
        )}
        {isAscending === false && (
          <ImSortAlphaDesc
            className="shrink-0"
            height={20}
            width={20}
          />
        )}
        {onFilterPress && (
          <ImFilter
            className="cursor-pointer z-30 hover:scale-125"
            onClick={(e) => {
              e.stopPropagation();
              onFilterPress(e.target);
            }}
          />
        )}
      </div>
    );
  }
  return <column.header />;
};

export default React.memo(HeaderCell) as typeof HeaderCell;
