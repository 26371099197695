import React from 'react';
import { Logo as LogoSVG } from '@assets';
import type { WithClassName } from './ui';

interface LogoProps extends WithClassName {
}

/** Аватарка проекта */
const Logo: React.FC<LogoProps> = ({ className }) => (<LogoSVG className={`w-24 h-24 ${className}`} />);

export default Logo;
