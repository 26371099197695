import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import type { IViewModel } from '@types';

/** Вью модель */
class BaseViewModel<T> implements IViewModel<T> {
  /** Данные респонса */
  @observable private _data: Partial<T>;

  /** Флаг валидации только через метод validation
   *  Устанавливается в true при вызове метода validation,
   *  в конце метода устанавливается в false
   */
  @observable protected _validationInProgress: boolean = false;

  /** ошибки валидации */
  @observable protected _errors = observable.map<string, string>();

  /** Данные респонса */
  @computed get data() {
    return this._data;
  }

  /** получить ошибки */
  @computed get errors() {
    return Object.fromEntries(this._errors?.entries());
  }

  constructor(data: Partial<T>) {
    this._data = data;
    try {
      makeObservable(this);
    } catch (error) {
      console.warn('error', error);
    }
  }

  /** Получить объект с данными */
  public getRawData() {
    return toJS(this._data);
  }

  /** Очистить вью модель */
  public clear() {
    runInAction(() => {
      this._data = {};
      this._errors.clear();
    });
  }

  /** Провалидировать поле */
  @action
  public validateField<TKey extends(keyof this & string)>(field: TKey, errorMessageFunc: (value: this[TKey]) => string | undefined) {
    const message = errorMessageFunc(this[field]);
    if (message) {
      this._errors.set(field, message);
    } else {
      this._errors.delete(field);
    }
  }

  /** Кастомная валидация */
  public validateCustom(errorKey: string, errorMessageFunc: () => string | undefined) {
    const message = errorMessageFunc();
    if (message) {
      this._errors.set(errorKey, message);
    } else {
      this._errors.delete(errorKey);
    }
  }

  /**  */
  @action
  public validate(): boolean {
    this._validationInProgress = true;
    const editableFields: [keyof this, TypedPropertyDescriptor<unknown> & PropertyDescriptor][] = [];
    for (
      let obj = Object.getPrototypeOf(this);
      obj.constructor.name !== BaseViewModel.name;
      obj = Object.getPrototypeOf(obj)
    ) {
      editableFields.push(
        ...Object
          .entries(Object.getOwnPropertyDescriptors(obj))
          .filter(([_, val]) => val.set) as [keyof this, TypedPropertyDescriptor<unknown> & PropertyDescriptor][],
      );
      if (Object.getPrototypeOf(obj).constructor.name === Object.name) {
        throw new Error('this не является потомком BaseViewModel');
      }
    }
    editableFields.forEach(([key]) => {
      // eslint-disable-next-line no-self-assign
      this[key] = this[key];
    });
    this._validationInProgress = false;
    return !Object.entries(this.errors).length;
  }
}

export default BaseViewModel;
