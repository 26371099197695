import type {
  GetProjectRequirementByIdResponse,
  GetProjectRequirementEmployeeByIdResponse,
  GetProjectRequirementEquipmentByIdResponse,
  GetProjectRequirementPlaceByIdResponse,
} from '@typesApi';
import { computed, runInAction } from 'mobx';
import { ErrorMessages } from 'src/enums';
import ProjectRequirementBaseViewModel from './ProjectRequirementBaseViewModel';

export type RequirementsResponse = GetProjectRequirementByIdResponse
& GetProjectRequirementEmployeeByIdResponse
& GetProjectRequirementEquipmentByIdResponse
& GetProjectRequirementPlaceByIdResponse

class ProjectRequirementPlaceViewModel extends ProjectRequirementBaseViewModel<GetProjectRequirementPlaceByIdResponse> {
  @computed get appointment() {
    return this.data.appointment ?? '';
  }

  set appointment(value: string | undefined) {
    runInAction(() => {
      this.data.appointment = value;
      this.validateField(
        'appointment',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get totalCapacity() {
    return this.data.totalCapacity ?? undefined;
  }

  set totalCapacity(value: number | undefined) {
    runInAction(() => {
      this.data.totalCapacity = value;
      this.validateField(
        'totalCapacity',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  @computed get numberOfSeats() {
    return this.data.numberOfSeats ?? null;
  }

  set numberOfSeats(value: number | null) {
    runInAction(() => {
      this.data.numberOfSeats = value;
      this.validateField(
        'numberOfSeats',
        (val) => {
          if (this._validationInProgress && !val) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }
}

export default ProjectRequirementPlaceViewModel;
