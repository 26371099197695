import { FormInput } from '@components/ui';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { useStores } from '@hooks';
import { ResourceTypes } from '@typesApi';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import type ProjectRequirementPlaceViewModel from 'src/stores/ProjectRequirement/ProjectRequirementPlaceViewModel';
import type { ProjectRequirementModalProps } from './types';

const ProjectRequirementPlace: React.FC<ProjectRequirementModalProps> = ({
  projectRequirementId,
  disabled,
}) => {
  const { projectRequirementStore } = useStores();
  const viewModel = projectRequirementStore.viewModel as ProjectRequirementPlaceViewModel;

  useEffect(() => {
    projectRequirementStore.resourceType = ResourceTypes.Place;
    if (projectRequirementId) {
      projectRequirementStore.fetch(projectRequirementId);
    }
  }, [projectRequirementId, projectRequirementStore]);

  return (
    <>
      <FormInput
        disabled={disabled}
        error={viewModel.errors.name}
        label="Наименование"
        onChange={(event) => {
          viewModel.name = event.target.value;
        }}
        value={viewModel.name}
      />
      <DatePicker
        borderClasses="border-1 rounded-main"
        disabled={disabled}
        error={viewModel.errors.startDate}
        label="Начало периода"
        onChange={(value) => {
          viewModel.startDate = value;
        }}
        value={viewModel.startDate}
      />
      <DatePicker
        borderClasses="border-1 rounded-main"
        disabled={disabled}
        error={viewModel.errors.finishDate}
        label="Окончание периода"
        onChange={(value) => {
          viewModel.finishDate = value;
        }}
        value={viewModel.finishDate}
      />
      <FormInput
        disabled={disabled}
        error={viewModel.errors.totalLoad}
        label="Нагрузка в день"
        onChange={(event) => {
          viewModel.totalLoad = Number(event.target.value);
        }}
        type="number"
        value={viewModel.totalLoad?.toString()}
      />
      <FormInput
        disabled={disabled}
        error={viewModel.errors.appointment}
        label="Назначение"
        onChange={(event) => {
          viewModel.appointment = event.target.value;
        }}
        value={viewModel.appointment}
      />
      <FormInput
        disabled={disabled}
        error={viewModel.errors.totalCapacity}
        label="Общая вместимость"
        onChange={(event) => {
          viewModel.totalCapacity = Number(event.target.value);
        }}
        type="number"
        value={viewModel.totalCapacity?.toString()}
      />
      <FormInput
        disabled={disabled}
        error={viewModel.errors.numberOfSeats}
        label="Количество сидячих мест"
        onChange={(event) => {
          viewModel.numberOfSeats = Number(event.target.value);
        }}
        type="number"
        value={viewModel.numberOfSeats?.toString()}
      />
    </>
  );
};

export default observer(ProjectRequirementPlace);
