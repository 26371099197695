import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks';
import { joinNonEmpty } from '@services/utils';
import type { SelectOption } from '@components/ui/Select/types';
import { ErrorMessages } from 'src/enums';
import { Select } from '@components/ui/Select';
import Button from '@components/ui/Button';

interface AddEmployeeModalProps {
  onClose: (val?: string) => void;
  alreadySelected: string[];
}

const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({
  onClose, alreadySelected,
}) => {
  const [error, setError] = useState<string>();

  const [selected, setSelected] = useState<SelectOption>();

  const { employeeNamesStore } = useStores();

  const searchEmployeeNames = useCallback(async (search?: string): Promise<SelectOption[] | undefined> => {
    await employeeNamesStore.fetchNames(search);
    return employeeNamesStore.namesList
      ?.filter((e) => e.id && !alreadySelected.includes(e.id))
      ?.map((x) => ({
        label: joinNonEmpty([x.surname, x.personName, x.patronymic]),
        value: x.id ?? '',
      }));
  }, [alreadySelected, employeeNamesStore]);

  return (
    <div className="min-w-[20vw] max-w[90vw] min-h-[15vh] max-h-[90vh] flex flex-col">
      <div className="font-bold text-left text-xl pb-3">Добавление сотрудника на проект</div>
      <Select.Async
        error={error}
        label="Сотрудник"
        onChange={searchEmployeeNames}
        onSelect={setSelected}
        selectedOption={selected}
      />
      <div className="flex gap-5 justify-end mt-auto">
        <Button.Primary
          label="Отменить"
          onClick={() => onClose(undefined)}
          type="outline"
        />
        <Button.Primary
          label="Сохранить"
          onClick={() => {
            if (!selected) {
              setError(ErrorMessages.requiredField);
            }
            if (selected) {
              onClose(selected.value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default observer(AddEmployeeModal);
