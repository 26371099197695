import React, { useCallback, useMemo } from 'react';
import { computed } from 'mobx';
import { ArrowDown } from '@assets';
import { parseStyles } from '@services/utils';
import { observer } from 'mobx-react-lite';
import type {
  RowProps,
  Row,
  CustomColumn,
  TypedColumn,
  CellWithSpan,
} from './types';
import Cell from './Cell';
import { isCustomColumn } from './typeGuards';
import { getCellKey } from './utils';

/** Строка таблицы */
const TableRow = <RowT extends Row, EntityT>({
  row, columns, expandOrCollapseRow, treeLike, onChange, even, height, maxSpan, startIndex, widths, onRowClick,
}: RowProps<RowT, EntityT>) => {
  const rowData = computed(() => row).get();

  const MemoizedCell: React.FC<{col: TypedColumn<RowT, EntityT> | CustomColumn<RowT, EntityT>; index?: number}> = useCallback(({ col, index }) => (
    <Cell
      column={col}
      height={height}
      index={index}
      maxSpan={maxSpan}
      onChange={(val) => {
        if (!isCustomColumn(col)) {
          onChange?.(rowData.id, col.keyExpr, val as RowT[typeof col.keyExpr]);
        }
      }}
      row={rowData}
    />
  ), [height, maxSpan, rowData, onChange]);

  const clickCallback = useMemo(
    () => () => (onRowClick ? onRowClick(row.id) : undefined),
    [onRowClick, row.id],
  );

  const pinnedColumnsIndexes: number[] = [];

  return (
    <div
      className={parseStyles`
        contents
        ${even ? 'bg-even-row text-even-row-text' : 'bg-odd-row text-odd-row-text'}
        ${onRowClick ? `
          hover:bg-slate-300 hover:cursor-pointer scale-110
        ` : undefined}
      `}
      onClick={clickCallback}
    >
      {columns?.map((col, colIndex) => {
        const isFirstCell = colIndex === 0;
        const isCustomCell = isCustomColumn(col);
        const hasErrors = !isCustomCell && rowData.errors[col.keyExpr as string];
        const nonErrorStyles = (isCustomCell && col.cellStyle?.(rowData))
          || `
            w-full h-full p-0 break-normal
            bg-inherit
          `;
        const isArray = col.keyExpr && Array.isArray(row[col.keyExpr]);
        if (col.pinned) {
          pinnedColumnsIndexes.push(colIndex);
        }
        if (!isArray) {
          return (
            <div
              key={getCellKey(rowData, col)}
              className={parseStyles`
                border-r-table-border-color border-b-table-border-color border-b-table border-r-table box-border last:border-r-0
                ${hasErrors ? 'bg-red-400' : nonErrorStyles}
                ${col.pinned ? 'sticky z-10' : 'relative'}
              `}
              style={
                {
                  left: col.pinned ? pinnedColumnsIndexes.slice(0, -1).reduce<number>((acc, cur) => acc + (widths[cur] ?? 0), 0) : undefined,
                  gridRow: `${startIndex} / span ${maxSpan}`,
                }
              }
            >
              {isFirstCell && treeLike
                ? (
                  <div
                    className={parseStyles`
                      w-2
                      h-full
                      self-center
                      float-left
                      outline-none
                      transition-all duration-300 ease-out
                      ${rowData.children?.length ? 'cursor-pointer' : 'opacity-0 cursor-auto'}
                      ${!rowData.expanded ? '-rotate-90' : undefined}
                    `}
                    onClick={() => expandOrCollapseRow(rowData.id)}
                    onKeyDown={() => expandOrCollapseRow(rowData.id)}
                    role="button"
                    style={
                      { marginLeft: (rowData.depth ?? 0) * 8 }
                    }
                    tabIndex={0}
                  >
                    <ArrowDown
                      className="inline-block"
                      height="100%"
                      width="100%"
                    />
                  </div>
                )
                : null}
              <MemoizedCell col={col} />
            </div>
          );
        }
        const rowValues = rowData[col.keyExpr!] as CellWithSpan<unknown | undefined>[];

        return (
          <React.Fragment key={`array${col?.keyExpr}`}>
            {(rowValues.length ? rowValues : [{ value: undefined, span: 1 }]).map((e, index) => (
              <div
                key={getCellKey(rowData, col) + (rowValues?.[index]?.key ?? '_').toString()}
                className={parseStyles`
                  border-r-table-border-color border-b-table-border-color border-b-table border-r-table box-border
                  ${hasErrors ? 'bg-red-400' : nonErrorStyles}
                  ${col.pinned ? 'sticky z-10' : 'relative'}
                `}
                style={{
                  left: col.pinned ? pinnedColumnsIndexes.slice(0, -1).reduce<number>((acc, cur) => acc + (widths[cur] ?? 0), 0) : undefined,
                  gridRow: `${startIndex + index} / span ${e.span}`,
                }}
              >
                {isFirstCell && treeLike
                  ? (
                    <div
                      className={parseStyles`
                        w-2
                        h-full
                        self-center
                        float-left
                        outline-none
                        transition-all duration-300 ease-out
                        ${rowData.children?.length ? 'cursor-pointer' : 'opacity-0 cursor-auto'}
                        ${!rowData.expanded ? '-rotate-90' : undefined}
                      `}
                      onClick={() => expandOrCollapseRow(rowData.id)}
                      onKeyDown={() => expandOrCollapseRow(rowData.id)}
                      role="button"
                      style={
                        { marginLeft: (rowData.depth ?? 0) * 8 }
                      }
                      tabIndex={0}
                    >
                      <ArrowDown
                        className="inline-block"
                        height="100%"
                        width="100%"
                      />
                    </div>
                  )
                  : null}
                <MemoizedCell
                  col={col}
                  index={index}
                />
              </div>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default observer(TableRow);
