import {
FileImportResponse,
GetEquipmentByIdResponse,
GetEquipmentsResponse,
PostEquipmentsRequest,
PostEquipmentsResponse,
ProblemDetails,
ProblemDetailsResponse,
PutEquipmentByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class EquipmentService {
  static get RoutePath(): string {
    return "Equipment";
  }

  /**
   * Получение техники по ид
   *
   * @request GET:/api/v1.0/Equipment/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetEquipmentByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление данных техники
   *
   * @request PUT:/api/v1.0/Equipment/{id}
   */
  static update = async (id: string, data: PutEquipmentByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка техники
   *
   * @request GET:/api/v1.0/Equipment
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      filterName?: string;
      types?: string[];
      showOnlyEditable?: boolean;
      isDeleted?: boolean;
      isProjectDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetEquipmentsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание техники
   *
   * @request POST:/api/v1.0/Equipment
   */
  static create = async (data: PostEquipmentsRequest, params: RequestParams = {}) =>
    httpClient.request<PostEquipmentsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Импорт техники из csv
   *
   * @request POST:/api/v1.0/Equipment/Import
   */
  static importCreate = async (data: { file?: File }, params: RequestParams = {}) =>
    httpClient.request<FileImportResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Import`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
}