import { User } from '@assets';
import Image from '@components/ui/Image/Image';
import { useStores } from '@hooks';
import { parseStyles, setTokenToStorage } from '@services/utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { useReadLocalStorage } from 'usehooks-ts';

/** Элемент меню (LeftMenu) для Личного кабинета  */
const ProfileMenu: React.FC = () => {
  const { accountStore } = useStores();
  const menuIsOpen: string | null = useReadLocalStorage(process.env.REACT_APP_MENU_OPEN || 'userMenuIsOpen');

  return (
    <NavLink
      className={(navData) => parseStyles`
        flex flex-1 p-2 text-white opacity-80 gap-4
        hover:bg-white hover:bg-opacity-20 items-center
        min-h-menuItemH cursor-pointer duration-300 ease-in hover:ease-out
        ${navData.isActive && 'bg-white bg-opacity-20 border-r-4 border-white'}
      `}
      to="profile"
    >
      {accountStore.viewModel.avatarLink
        ? (
          <Image
            alt="leftMenuIcon"
            className="rounded-full object-cover h-[30px] w-[30px] max-w-[30px]"
            imageLink={accountStore.viewModel.avatarLink ?? ''}
          />
        )
        : (
          <User
            className="h-[30px] w-[30px]"
            color="white"
          />
        )}
      {menuIsOpen && (
        <>
          <div className="grid flex-1">
            <div className="max-w-link whitespace-nowrap">Личный кабинет</div>
            <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">{accountStore.viewModel.email}</div>
          </div>
          <FiLogOut
            className="hover:scale-125 transition-all flex-shrink-0"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setTokenToStorage();
            }}
            size={20}
            title="Выйти из учетной записи"
          />
        </>
      )}
    </NavLink>
  );
};

export default observer(ProfileMenu);
