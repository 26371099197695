import React from 'react';
import type { TableButtonsProps } from '../../Administration/types';
import Button from '../Button';
import ButtonIcons from '../Button/ButtonIcons';

const TableButtons: React.FC<TableButtonsProps> = ({
  name, onAddClick, onDeleteClick, isDeleteDisabled,
}) => (
  <>
    {onAddClick && (
      <Button.Primary
        icon={ButtonIcons.Add}
        label={`Добавить ${name}`}
        onClick={onAddClick}
      />
    )}
    {onDeleteClick && (
      <Button.Primary
        icon={ButtonIcons.Delete}
        isDisabled={isDeleteDisabled}
        label={`Удалить ${name}`}
        onClick={onDeleteClick}
        type="danger"
      />
    )}
  </>
);

export default React.memo(TableButtons);
