import Title from '@components/ui/Title/Title';
import NavigateBack from '@components/ui/NavigateBack/NavigateBack';
import React from 'react';
import { HiLockClosed } from 'react-icons/hi';
import { useEffectOnce } from 'usehooks-ts';
import { useTitle } from '@hooks';

/** Страница отсутствия прав */
const NoAccess: React.FC<{prevPath: string}> = ({ prevPath }) => {
  useTitle('Ошибка');
  useEffectOnce(() => {
    window.history.replaceState(null, '', prevPath);
  });

  return (
    <div className="h-full w-full flex flex-col">
      <div className="pl-5 pt-5 flex-grow-0">
        <NavigateBack />
      </div>
      <div className="flex flex-1 flex-col items-center pt-40">
        <HiLockClosed
          className="text-btn-outline"
          size={50}
        />
        <Title className="text-btn-outline text-center p-5">Страница не найдена или у вас недостаточно прав для её просмотра.</Title>
      </div>
    </div>
  );
};

export default NoAccess;
