import { parseStyles } from '@services/utils';
import React, { memo, useMemo } from 'react';
import type { DayData } from '../types';
import graphConfig from '../graph.config';

interface DayProps {
  day: DayData;
  onCellHover: (day: {cell: SVGElement; day: DayData} | null) => void;
  rowHeight: number;
  columnWidth: number;
  rowIndex: number;
}

const overtimePaleColor = graphConfig.theme.extend.colors['graph-overtime-pale'];
const notOvertimePaleColor = graphConfig.theme.extend.colors['graph-not-overtime-pale'];
const overtimeColor = graphConfig.theme.extend.colors['graph-overtime'];
const notOvertimeColor = graphConfig.theme.extend.colors['graph-not-overtime'];

const Day: React.FC<DayProps> = ({
  day, onCellHover, rowHeight, columnWidth, rowIndex,
}) => {
  const stroke = useMemo(() => {
    if (day.weekend) {
      return 'transparent';
    }
    return day.total > 8 ? 'rgba(227, 0, 0, 0.5)' : 'rgba(0, 227, 0, 0.5)';
  }, [day.total, day.weekend]);

  return (
    <g className="cursor-pointer">
      <defs>
        <linearGradient
          id={`MyGradient${rowIndex}-${day.index}`}
          x2="0%"
          y2="100%"
        >
          <stop
            offset="0%"
            stopColor={day.total > 8 ? overtimePaleColor : notOvertimePaleColor}
          />
          <stop
            offset={1 - Math.min(day.hours / 8, 1)}
            stopColor={day.total > 8 ? overtimePaleColor : notOvertimePaleColor}
          />
          <stop
            offset={1 - Math.min(day.hours / 8, 1)}
            stopColor={day.total > 8 ? overtimeColor : notOvertimeColor}
          />
          <stop
            offset="100%"
            stopColor={day.total > 8 ? overtimeColor : notOvertimeColor}
          />
        </linearGradient>
        <pattern
          height="4"
          id="diagonalHatch"
          patternUnits="userSpaceOnUse"
          width="4"
        >
          <rect
            fill="white"
            height={4}
            width={4}
          />
          <path
            d="M-1,1 l2,-2
              M0,4 l4,-4
              M3,5 l2,-2"
            stroke="gray"
            strokeWidth={2}
          />
        </pattern>
      </defs>
      <rect
        className={!day.weekend ? parseStyles`
          ${day.total > 8 ? 'hover:fill-graph-overtime-hover' : 'hover:fill-graph-not-overtime-hover'}
        ` : undefined}
        fill={day.weekend ? 'url(#diagonalHatch)' : `url(#MyGradient${rowIndex}-${day.index})`}
        height={day.weekend ? rowHeight : rowHeight - 2}
        onMouseEnter={(e) => {
          onCellHover({
            cell: e.currentTarget, day,
          });
        }}
        onMouseLeave={() => {
          onCellHover(null);
        }}
        stroke={stroke}
        strokeWidth={2}
        width={columnWidth}
        x={day.index * columnWidth}
        y={day.weekend ? (rowIndex * rowHeight) : rowIndex * rowHeight + 1}
      />

      {!day.weekend && (
        <text
          className="select-none pointer-events-none text-font-main"
          dominantBaseline="middle"
          textAnchor="middle"
          x={day.index * columnWidth + 0.5 * columnWidth}
          y={(rowIndex + 0.55) * rowHeight}
        >
          {day.hours}
        </text>
      )}
    </g>
  );
};

export default memo(Day);
