module.exports = {
  theme: {
    extend: {
      colors: {
        'accordion-bg': '#FFFFFF',
        'accordion-content-bg': '#FFFFFF',
        'accordion-border': '#E0E8EA',
      },
      borderRadius: {
        'accordion-round': '0.5rem',
      },
      transitionProperty: {
        mh: 'max-height',
      },
      transitionTimingFunction: {
        'open-accordion': 'ease-in-out',
        'close-accordion': 'cubic-bezier(0, 1, 0, 1)',
      },
    },
  },
};
