module.exports = {
  theme: {
    extend: {
      colors: {
        'switch-active': '#E97224',
        'switch-disabled': '#B1ADAB',
      },
    },
  },
};
