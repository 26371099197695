import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useEffectOnce, useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
  refreshTokenName,
  tokenName,
} from '@services/utils';
import { useNavigate } from 'react-router-dom';
import LoginLayout from '@components/LoginLayout';
import { Loader } from '@components/ui';

/** Компонент логинки */
const Login: React.FC = () => {
  const [, setCodeVerifier] = useLocalStorage<string | undefined>('code_verifier', undefined);
  const token = useReadLocalStorage<string | undefined>(tokenName);
  const refreshToken = useReadLocalStorage<string | undefined>(refreshTokenName || 'refreshToken');
  const navigate = useNavigate();

  const loginOauth = useCallback(
    async () => {
      const code = generateCodeVerifier();
      setCodeVerifier(code);
      const challenge = await generateCodeChallengeFromVerifier(code!);
      window.location.href = `${
        process.env.REACT_APP_OAUTH_URL
      // eslint-disable-next-line max-len
      }/connect/authorize?client_id=a2planner-client&response_type=code&redirect_uri=${window.location.origin}/redirect&scope=offline_access&code_challenge=${
        challenge
      }&code_challenge_method=S256`;
    },
    [setCodeVerifier],
  );

  useEffectOnce(() => {
    if (token || refreshToken) {
      navigate('/');
    } else {
      loginOauth();
    }
  });

  return (
    <LoginLayout>
      <div className="w-full h-full flex items-center justify-center">
        <Loader size={40} />
      </div>
    </LoginLayout>
  );
};

export default observer(Login);
