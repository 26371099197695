import {
EntityActions,
GetRoleByIdResponse,
GetRolesResponse,
PostRoleRequest,
PostRoleResponse,
ProblemDetails,
ProblemDetailsResponse,
PutRoleByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class RoleService {
  static get RoutePath(): string {
    return "Role";
  }

  /**
   * Получение списка глобальных ролей
   *
   * @request GET:/api/v1.0/Role
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      roles?: string[];
      name?: string;
      code?: string;
      entityActions?: EntityActions[];
      isDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetRolesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание глобальной роли
   *
   * @request POST:/api/v1.0/Role
   */
  static create = async (data: PostRoleRequest, params: RequestParams = {}) =>
    httpClient.request<PostRoleResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение глобальной роли по ид
   *
   * @request GET:/api/v1.0/Role/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetRoleByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление глобальной роли
   *
   * @request PUT:/api/v1.0/Role/{id}
   */
  static update = async (id: string, data: PutRoleByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удаление глобальной роли по ид
   *
   * @request DELETE:/api/v1.0/Role/{id}
   */
  static delete = async (id: string, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      ...params,
    });
}