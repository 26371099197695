import { useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import type { SyntheticEvent } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { runInAction } from 'mobx';
import type { WithClassName } from '@components/ui';
import { useEffectOnce } from 'usehooks-ts';
import type { CalendarProps } from '../Calendar/Calendar';
import { GridWithData } from './GridWithData';
import HorizontalScroll from '../HorizontalScroll';
import { AnalyticsTableContext } from '../AnalyticsTableContext';
import ProjectResourcesList from './ProjectResourcesList';

export interface TableProps extends WithClassName {
  rowHeight: number;
  columnWidth: number;
  listCellWidth: number;
}

/** Реестр сотрудников */
const CalendarTable: React.FC<TableProps> = ({
  rowHeight, columnWidth, listCellWidth, className,
}) => {
  const { projectLoadStore } = useStores();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const verticalContainerRef = useRef<HTMLDivElement>(null);
  const horizontalScrollRef = useRef<HTMLDivElement>(null);
  const horizontalContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const leftMenuRef = useRef<HTMLDivElement>(null);
  const resourcesListRef = useRef<HTMLDivElement>(null);
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);

  const scrollX = useCallback(
    (scroll: number) => {
      verticalContainerRef.current?.scrollTo({ left: scroll });
      horizontalScrollRef.current?.scrollTo({ left: scroll });
      runInAction(() => {
        projectLoadStore.scrollLeft = scroll;
      });
    },
    [projectLoadStore],
  );

  const calendarProps: CalendarProps = useMemo(() => ({
    dates: projectLoadStore.seededDates,
    viewMode: projectLoadStore.viewMode,
    headerHeight: 50,
    columnWidth,
    fontSize: '1rem',
  }), [projectLoadStore.seededDates, projectLoadStore.viewMode, columnWidth]);
  const svgWidth = calendarProps.dates.length * columnWidth;
  const gridFullHeight = projectLoadStore.viewModel.length * rowHeight;

  const handleScrollX = (event: SyntheticEvent<HTMLDivElement>) => {
    scrollX(event.currentTarget.scrollLeft);
  };

  const handleWheel = useCallback((event: WheelEvent) => {
    if (event.shiftKey || event.deltaX) {
      const scrollMove = event.deltaX ? event.deltaX : event.deltaY;
      let newScrollX = verticalContainerRef.current!.scrollLeft + scrollMove;
      if (newScrollX < 0) {
        newScrollX = 0;
      } else if (newScrollX > svgWidth - (boundingRect?.width ?? 0)) {
        newScrollX = svgWidth - (boundingRect?.width ?? 0);
      }
      horizontalScrollRef.current?.scrollTo({ left: newScrollX });
      event.preventDefault();
    }
  }, [svgWidth, boundingRect?.width]);

  useEffect(() => {
    const element = tableRef.current;
    // subscribe if scroll is necessary
    element?.addEventListener('wheel', handleWheel, {
      passive: false,
    });
    return () => {
      element?.removeEventListener('wheel', handleWheel);
    };
  }, [wrapperRef, svgWidth, gridFullHeight, boundingRect, handleWheel]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const possibleKeys = [
      'Left',
      'ArrowLeft',
      'Right',
      'ArrowRight',
    ];
    if (event.shiftKey || !possibleKeys.includes(event.key)) {
      return;
    }
    event.preventDefault();
    let newScrollX = verticalContainerRef.current!.scrollLeft ?? 0;
    const isX = true;
    switch (event.key) {
      case 'Left':
      case 'ArrowLeft':
        newScrollX -= columnWidth;
        break;
      case 'Right': // IE/Edge specific value
      case 'ArrowRight':
        newScrollX += columnWidth;
        break;
      default: break;
    }
    if (isX) {
      if (newScrollX < 0) {
        newScrollX = 0;
      } else if (newScrollX > svgWidth) {
        newScrollX = svgWidth;
      }
      scrollX(newScrollX);
    }
  };

  useEffect(() => {
    runInAction(() => {
      projectLoadStore.width = boundingRect?.width ?? 0;
      projectLoadStore.height = boundingRect?.height ?? 0;
      projectLoadStore.rowHeight = rowHeight;
      projectLoadStore.columnWidth = columnWidth;
    });
  }, [boundingRect?.height, boundingRect?.width, columnWidth, projectLoadStore, rowHeight]);

  const tableProviderValue = useMemo(
    () => ({
      boundingRect,
      setBoundingRect,
    }),
    [boundingRect],
  );

  const loadNextCallback = useCallback(
    () => {
      runInAction(() => {
        projectLoadStore.filter.pageNumber = (projectLoadStore.filter.pageNumber ?? 1) + 1;
      });
      projectLoadStore.fetch();
    },
    [projectLoadStore],
  );

  useEffectOnce(() => () => projectLoadStore.clearStore());

  const listRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={tableRef}
      className={className}
    >
      <AnalyticsTableContext.Provider value={tableProviderValue}>
        <div
          ref={wrapperRef}
          className="flex p-0 m-0 list-none list-outside outline-none relative border-b-graph-border border-l-graph-border"
          onKeyDown={handleKeyDown}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          <ProjectResourcesList
            ref={listRef}
            gridHeight={gridFullHeight}
            headerHeight={calendarProps.headerHeight}
            horizontalContainerClass="m-o p-0 overflow-hidden"
            horizontalContainerRef={leftMenuRef}
            resourcesListRef={resourcesListRef}
            rowWidth={`${listCellWidth}px`}
          />
          <GridWithData
            calendarProps={calendarProps}
            columnWidth={columnWidth}
            columns={calendarProps.dates.length}
            firstDayDayOfWeek={projectLoadStore.startDate.getDay()}
            gridHeight={gridFullHeight}
            horizontalContainerRef={horizontalContainerRef}
            listRef={listRef}
            rowHeight={rowHeight}
            rows={projectLoadStore.viewModel.length}
            verticalContainerRef={verticalContainerRef}
          />
        </div>
        <HorizontalScroll
          ref={horizontalScrollRef}
          canGoNext={projectLoadStore.canGoNext}
          onLoad={loadNextCallback}
          onScroll={handleScrollX}
          svgWidth={svgWidth}
          taskListWidth={listCellWidth}
        />
      </AnalyticsTableContext.Provider>
    </div>
  );
};

export default observer(CalendarTable);
