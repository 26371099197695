import {
GetProjectRequirementByIdResponse,
GetProjectRequirementsResponse,
ProblemDetails,
ProblemDetailsResponse,
ResourceTypes} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class ProjectRequirementService {
  static get RoutePath(): string {
    return "ProjectRequirement";
  }

  /**
   * Получение списка потребностей проектов
   *
   * @request GET:/api/v1.0/ProjectRequirement
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      projects?: string[];
      resourceType?: ResourceTypes;
      projectRequirement?: string;
      catalogValues?: string[];
      startDate?: string;
      finishDate?: string;
      minTotalLoad?: number;
      maxTotalLoad?: number;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetProjectRequirementsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Удаление потребности проекта по ид
   *
   * @request DELETE:/api/v1.0/ProjectRequirement/{id}
   */
  static delete = async (id: string, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      ...params,
    });

  /**
   * Получение потребности проекта по ид
   *
   * @request GET:/api/v1.0/ProjectRequirement/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetProjectRequirementByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
}