module.exports = {
  theme: {
    extend: {
      height: {
        'i-default': '35px',
      },
      minHeight: ({ theme }) => ({
        'i-default': theme('height.i-default'),
      }),
      colors: {
        'i-disabled': '#868686',
        'i-bg-disabled': '#F8F8F8',
        'i-border-active': '#F7A46E',
      },
      fontSize: {
        'input-main': ['14px', {
          lineHeight: '14px',
          fontWeight: '600',
        }],
      },
    },
  },
};
