import type { PropsWithChildren } from 'react';
import React from 'react';

/** Разметка для страницы логина и регистрации */
const LoginLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex gap-form flex-1 flex-col items-center py-16 bg-background">
    {children}
  </div>
);

export default LoginLayout;
