import type {
  DayOfWeek,
  GetLoadResourceInProjectsByResourceIdResponseItem,
  GetLoadResourceInProjectsByResourceIdResponseLoadResourceInProject,
} from '@typesApi';
import { makeObservable, observable } from 'mobx';

export class ResourceLoadViewModel implements GetLoadResourceInProjectsByResourceIdResponseItem {
  /**
   * Ид ресурса на проекте
   * @format uuid
   */
  @observable
    resourceInProjectId?: string;

  /**
   * Ид проекта
   * @format uuid
   */
  @observable
    projectId?: string;

  /** Название проекта */
  @observable
    name: string;

  /** Рабочие дни недели сотрудника на проекте */
  @observable
    workingDaysOfWeek?: DayOfWeek[] | null;

  /** Нагрузка ресурсов на проекте */
  @observable
    loadResourceInProjects: GetLoadResourceInProjectsByResourceIdResponseLoadResourceInProject[];

  constructor(data: GetLoadResourceInProjectsByResourceIdResponseItem) {
    this.resourceInProjectId = data.resourceInProjectId!;
    this.projectId = data.projectId;
    this.name = data.name ?? '';
    this.workingDaysOfWeek = data.workingDaysOfWeek;
    this.loadResourceInProjects = data.loadResourceInProjects ?? [];
    makeObservable(this);
  }
}
