import { EntityActions } from '@typesApi';
import { computed, makeObservable } from 'mobx';
import type { BasePrivileges } from './types';

/** Стор реестра */
export class SalaryPeriodsPrivileges implements BasePrivileges {
  private _privileges: EntityActions[];

  constructor(privileges: EntityActions[]) {
    makeObservable(this);
    this._privileges = privileges;
  }

  @computed get canCreate() {
    return this._privileges.includes(EntityActions.CreateSalaryPeriods);
  }

  @computed get canUpdate() {
    return this._privileges.includes(EntityActions.UpdateSalaryPeriods);
  }

  @computed get canRead() {
    return this._privileges.includes(EntityActions.ReadSalaryPeriods);
  }

  @computed get canDelete() {
    return this._privileges.includes(EntityActions.DeleteSalaryPeriods);
  }
}
