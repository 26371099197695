import type { SelectOption } from '@components/ui/Select';
import { dayOfWeekEnumToNumber } from '@services/utils';
import { required } from '@services/validationFunctions';
import type { GetResourceInProjectByIdResponse, GetResourceInProjectByIdResponseLoadResourceInProject } from '@typesApi';
import dayjs from 'dayjs';
import { computed, makeObservable, runInAction } from 'mobx';
import BaseViewModel from '../BaseViewModel';
import { LoadResourceInProjectStore } from '../ResourceInProject/LoadResourceInProjectsStore';

class EmployeeInProjectViewModel extends BaseViewModel<GetResourceInProjectByIdResponse> {
  public loadResourceInProjectStore: LoadResourceInProjectStore;

  constructor(data: GetResourceInProjectByIdResponse) {
    super(data);
    makeObservable(this);
    this.loadResourceInProjectStore = new LoadResourceInProjectStore(data.loadResourceInProjects ?? []);
  }

  /** Получение id */
  @computed get id() {
    return this.data.id;
  }

  /** Получение id */
  set id(value: string | undefined) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** Получение projectId */
  @computed get projectId() {
    return this.data.projectId;
  }

  /** Изменение projectId */
  set projectId(value: string | undefined) {
    runInAction(() => {
      this.data.projectId = value;
    });
  }

  /** Получение projectName */
  @computed get projectName() {
    return this.data.projectName;
  }

  /** Изменение projectName */
  set projectName(value: string | undefined | null) {
    runInAction(() => {
      this.data.projectName = value;
    });
  }

  @computed get projectAsSelectOption(): SelectOption | undefined {
    return (this.projectName && this.projectId) ? ({ label: this.projectName, value: this.projectId }) : undefined;
  }

  /** Получение resourceId */
  @required()
  @computed get resourceId() {
    return this.data.resourceId;
  }

  /** Изменение resourceId */
  set resourceId(value: string | undefined) {
    runInAction(() => {
      this.data.resourceId = value;
    });
  }

  /** Получение resourceName */
  @computed get resourceName() {
    return this.data.resourceName;
  }

  /** Изменение resourceName */
  set resourceName(value: string | undefined | null) {
    runInAction(() => {
      this.data.resourceName = value;
    });
  }

  /** Выбранный сотрудник */
  @computed get selectedEmployee(): SelectOption | undefined {
    return this.resourceId ? ({ label: this.resourceName ?? '', value: this.resourceId ?? '' }) : undefined;
  }

  /** Получение workingRateInProject */
  @computed get workingRateInProject() {
    return this.data.workingRateInProject;
  }

  /** Изменение workingRateInProject */
  set workingRateInProject(value: number | undefined | null) {
    runInAction(() => {
      this.data.workingRateInProject = value;
    });
  }

  /** Получение loadResourceInProjects */
  @computed get loadResourceInProjects() {
    return this.data.loadResourceInProjects;
  }

  /** Изменение loadResourceInProjects */
  set loadResourceInProjects(value: GetResourceInProjectByIdResponseLoadResourceInProject[] | undefined | null) {
    runInAction(() => {
      this.data.loadResourceInProjects = value;
    });
  }

  @required()
  @computed get projectRoles() {
    return this.data.projectRoles?.map((role) => ({ label: role.name ?? '', value: role.id ?? '' })) ?? [];
  }

  set projectRoles(value: SelectOption[] | undefined) {
    runInAction(() => {
      this.data.projectRoles = value?.map(((role) => ({ id: role.value, name: role.label })));
    });
  }

  @computed get workingDaysOfWeek() {
    return this.data.workingDaysOfWeek?.map((day) => dayjs().day(dayOfWeekEnumToNumber(day)).format('dd'));
  }
}

export default EmployeeInProjectViewModel;
