import type { ListBaseFilter } from '@types';
import type { GetEmployeeByIdResponseResourceInProject } from '@typesApi';
import { action, makeObservable, observable } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import { EmployeeProjectsItemViewModel } from './EmployeeProjectsItemViewModel';

/** Стор сущности */
export class EmployeeProjectsStore extends BaseEntityListStore<
GetEmployeeByIdResponseResourceInProject,
ListBaseFilter & {
  orderBy: keyof GetEmployeeByIdResponseResourceInProject;
  projectName: string | undefined;
  workingRateInProject: string | undefined;
},
EmployeeProjectsItemViewModel> {
  @observable private privateData: GetEmployeeByIdResponseResourceInProject[] = [];

  public async fetch(): Promise<void> {
    this.setDataForCurrentPage();
  }

  constructor(data: GetEmployeeByIdResponseResourceInProject[]) {
    super(20);
    makeObservable(this);
    this.privateData = observable.array(data);
    this.setDataForCurrentPage();
  }

  /** Установить данные для текущей страницы */
  @action private setDataForCurrentPage() {
    let data = this.privateData
      .filter((x) => !this.filter.projectName || x.projectName?.toLowerCase().includes(this.filter.projectName.toLowerCase()))
      .filter((x) => !this.filter.workingRateInProject
        || x.workingRateInProject?.toString().toLowerCase().includes(this.filter.workingRateInProject.toLowerCase()));

    if (this.filter.orderBy) {
      data = data.sort((a, b) => {
        if (!this.filter.orderBy || !a || !b || !a[this.filter.orderBy!] || !b[this.filter.orderBy!]) {
          return 0;
        }
        if (a[this.filter.orderBy]!.toString() > b[this.filter.orderBy]!.toString()) {
          return this.filter.isAscending ? 1 : -1;
        }

        return this.filter.isAscending ? -1 : 1;
      });
    }

    this.setData({
      entities: data.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize),
      totalCount: data.length,
    } as {
      entities: GetEmployeeByIdResponseResourceInProject[];
      totalCount: number;
    }, EmployeeProjectsItemViewModel);
  }
}
