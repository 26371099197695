import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useConfirm, useTitle } from '@hooks';
import type { GetPlacesResponseItem } from '@typesApi';
import { useEffectOnce } from 'usehooks-ts';
import type { PlacesTableItem } from '@types';
import { Column, RowCheckboxForResources, Table } from '@components/ui/Table';
import PlaceResourceForm from '@components/ResourcesForms/PlaceResourceForm';
import { computed, runInAction } from 'mobx';
import TablePageLayout from '@components/TablePageLayout';
import { InputHeader } from '@components/index';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '@components/ui/Button';
import Errors from '@components/ui/Errors/Errors';
import { archiveHash } from '@services/utils';
import ButtonIcons from '@components/ui/Button/ButtonIcons';

/** Таблица помещений реестра ресурсов */
const PlaceTable: React.FC = () => {
  useTitle('Помещения');
  const { placesStore } = useStores();
  const { hash } = useLocation();
  /** Привилегии  */
  const { canCreate, canDelete } = placesStore;

  /** Модалка создания и редактирования помещения */
  const [resourceModalInfo, setResourceModalInfo] = useState<{resourceId?: string} | undefined>();

  /** Отображение архивных или действующих данных */
  const [isArchive, setIsArchive] = useState(false);

  /** Хук для модалки подтверждения */
  const confirm = useConfirm(isArchive ? 'Восстановить выбранные ресурсы?' : 'Архивировать выбранные ресурсы?');

  const { id } = useParams();
  const navigate = useNavigate();

  useEffectOnce(() => {
    runInAction(() => {
      placesStore.filter.orderBy = 'name';
      placesStore.filter.isAscending = true;
    });
    return () => placesStore.clearStore();
  });

  useEffect(() => {
    if (hash?.includes(archiveHash)) {
      setIsArchive(true);
      runInAction(() => {
        placesStore.filter.isDeleted = true;
      });
    } else {
      setIsArchive(false);
      runInAction(() => {
        placesStore.filter.isDeleted = false;
      });
    }
    placesStore.fetch();
  }, [hash, placesStore, placesStore.filter, setIsArchive]);

  /** Хэндлер добавления ресурса */
  const createResourceHandler = useCallback(() => {
    setResourceModalInfo({});
  }, []);

  useLayoutEffect(() => {
    if (id) {
      setResourceModalInfo({ resourceId: id });
    }
  }, [id]);

  const onModalClose = () => {
    if (id && resourceModalInfo) {
      navigate(isArchive ? `/resources/places${archiveHash}` : '/resources/places');
    }
    setResourceModalInfo(undefined);
  };

  const onRowClickHandler = useCallback((rowId: string) => {
    navigate(isArchive ? `${rowId}${archiveHash}` : `${rowId}`);
  }, [isArchive, navigate]);

  /** Архивация выбранных ресурсов */
  const archiveHandler = useCallback(async () => {
    const res = await confirm?.();
    if (!res) {
      return;
    }
    if (isArchive) {
      placesStore.restoreSelectedPlaces();
    } else {
      placesStore.archive();
    }
  }, [confirm, isArchive, placesStore]);

  /** Хедер колонки "Наименование помещения" */
  const placeNameHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="filterName"
      store={placesStore}
    />
  ), [placesStore]);

  /** Заблокирована ли кнопка Удалить */
  const isDeleteDisabled = computed(() => !placesStore.viewModel.some((p) => p.selected)).get();

  return (
    <TablePageLayout
      buttons={(
        <>
          {canCreate && (
            <Button.Primary
              icon={ButtonIcons.Add}
              isLoading={placesStore.state.isLoading}
              label="Добавить"
              onClick={createResourceHandler}
            />
          )}
          <Button.Primary
            icon={isArchive ? ButtonIcons.Back : ButtonIcons.Archive}
            isLoading={placesStore.state.isLoading}
            label={isArchive ? 'Вернуться в реестр' : 'Архив'}
            onClick={() => {
              runInAction(() => {
                placesStore.filter.isDeleted = !isArchive;
                placesStore.filter.pageNumber = 1;
              });
              navigate(isArchive ? '' : archiveHash);
            }}
          />
          {canDelete && (
            <Button.Primary
              icon={isArchive ? ButtonIcons.ArchiveOut : ButtonIcons.ArchiveIn}
              isDisabled={isDeleteDisabled}
              isLoading={placesStore.state.isLoading}
              label={isArchive ? 'Вернуть в реестр' : 'Архивировать'}
              onClick={archiveHandler}
              type="danger"
            />
          )}
        </>
      )}
      title={`Помещения${isArchive ? ' - Архив' : ''}`}
    >
      <Table
        onRowClick={onRowClickHandler}
        reorderSessionKey="placesTable"
        rowHeight={30}
        store={placesStore}
      >
        {canDelete ? (
          <Column<PlacesTableItem, GetPlacesResponseItem>
            key="checkbox"
            dataType="custom"
            header=""
            template={RowCheckboxForResources}
            width={50}
            pinned
          />
        ) : null}
        <Column
          header="Наименование"
          sortExpr="name"
          pinned
        >
          <Column<PlacesTableItem, GetPlacesResponseItem>
            dataType="string"
            header={placeNameHeader}
            keyExpr="name"
            minWidth={250}
          />
        </Column>
        <Column<PlacesTableItem, GetPlacesResponseItem>
          dataType="string"
          header="Адрес"
          keyExpr="address"
          minWidth={300}
          sortable
        />
        <Column<PlacesTableItem, GetPlacesResponseItem>
          dataType="string"
          header="Назначение"
          keyExpr="appointment"
          minWidth={200}
          sortable
        />

        <Column<PlacesTableItem, GetPlacesResponseItem>
          dataType="number"
          header="Площадь"
          keyExpr="square"
          minWidth={120}
          sortable
        />
        <Column<PlacesTableItem, GetPlacesResponseItem>
          dataType="string"
          header="Ответственный"
          keyExpr="responsibleFullName"
          minWidth={250}
          sortable
        />
      </Table>
      {resourceModalInfo && (
        <PlaceResourceForm
          onClose={onModalClose}
          resourceId={resourceModalInfo.resourceId}
        />
      )}
      <Errors store={placesStore} />
    </TablePageLayout>
  );
};

export default observer(PlaceTable);
