import Drawer from '@components/ui/Drawer/Drawer';
import React from 'react';
import type { DrawerWithWrapperProps } from './types';

/** Обертка с хедером с кнопкой закрытия и нижним бордером (опционально футер также с бордером) */
const DrawerWithWrapper: React.FC<DrawerWithWrapperProps> = ({
  children,
  footer,
  onClose,
  className,
  confirm,
  title,
}) => (
  <Drawer
    className={className}
    confirm={confirm}
    onClose={onClose}
  >
    <div className="flex flex-col h-full transition-all">
      {title && (
        <div className="pl-6 text-2xl font-bold py-4 border-b-[1px] bg-background pr-16">
          {title}
        </div>
      )}
      <div className="p-4 px-6 flex flex-col overflow-auto flex-1">{children}</div>
      {footer && (
        <div className="bg-background py-4 px-10 border-t-[1px] w-full flex justify-end">
          {footer}
        </div>
      )}
    </div>
  </Drawer>
);

export default DrawerWithWrapper;
