import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import Errors from '@components/ui/Errors/Errors';
import { Select } from '@components/ui/Select';
import type { SelectOption } from '@components/ui/Select/types';
import { useInterceptChanges, useStores } from '@hooks';
import { joinNonEmpty } from '@services/utils';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useConfirm } from 'src/hooks/useConfirm';
import { useEffectOnce } from 'usehooks-ts';
import { ConfirmQuestions } from 'src/enums';
import DrawerWithWrapper from '../DrawerResourceLayout/DrawerWithWrapper';
import type { EmployeeCardShareWithIdProp } from './types';

/** Карьерные встречи (Форма добавления/редактирования) */
const CareerMeetingForm: FC<EmployeeCardShareWithIdProp> = ({
  employeeId, onSave, onClose, id,
}) => {
  const { careerMeetingStore, employeeNamesStore, accountStore } = useStores();
  const { viewModel, isDirty, resetIsDirty } = useInterceptChanges(careerMeetingStore.viewModel);
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  const [editing, setEditing] = useState<boolean>(!id);

  /** Выбранные сотрудники */
  const [invitedEmployees, setInvitedEmployees] = useState<SelectOption[] | undefined>();

  useEffectOnce(() => {
    if (id) {
      careerMeetingStore.fetch(id);
    } else if (employeeId) {
      setEditing(true);
      careerMeetingStore.viewModel.employeeId = employeeId;
    }
    employeeNamesStore.fetchNames();
  });

  /** Данные текущего пользователя */
  const currentUser = useMemo(() => ({
    id: accountStore.employeeId,
    surname: accountStore.viewModel.surname,
    name: accountStore.viewModel.name,
    patronymic: accountStore.viewModel.patronymic,
  }), [accountStore.employeeId, accountStore.viewModel.name, accountStore.viewModel.patronymic, accountStore.viewModel.surname]);

  useEffect(() => {
    if (invitedEmployees?.length) {
      return;
    }

    /** Если это новая карьерная встреча */
    if (!id) {
      viewModel.invitedEmployees = [currentUser];
      resetIsDirty();
    }
    setInvitedEmployees(viewModel.invitedEmployees?.map((x) => ({
      label: joinNonEmpty([x.surname, x.name, x.patronymic]),
      value: x.id ?? '',
    })));
  }, [viewModel.invitedEmployees?.length, currentUser, invitedEmployees?.length, id, viewModel, resetIsDirty]);

  /** Поиск сотрудников */
  const searchEmployeeNames = useCallback(async (search?: string): Promise<SelectOption[] | undefined> => {
    await employeeNamesStore.fetchNames(search);
    return employeeNamesStore.namesList
      ?.filter((x) => !invitedEmployees?.some((el) => el.value === x.id))
      .filter((x) => x.id !== employeeId)
      .map((x) => ({ label: joinNonEmpty([x.surname, x.personName, x.patronymic]), value: x.id ?? '' }));
  }, [employeeId, employeeNamesStore, invitedEmployees]);

  /** Хэндлер обработки подтверждения или отмены закрытия модалки */
  const confirmHandler = useMemo(() => {
    if (isDirty) {
      return confirm;
    }
    return undefined;
  }, [confirm, isDirty]);

  const onCloseHandler = useCallback(() => {
    careerMeetingStore.clearStore();
    onClose?.();
  }, [careerMeetingStore, onClose]);

  const onSaveOrEditHandler = useCallback(async () => {
    if (editing && careerMeetingStore.viewModel.validate()) {
      await careerMeetingStore.save();
      await onSave?.();
      if (!id) {
        onCloseHandler();
      }
    } else {
      setEditing(true);
    }
  }, [editing, careerMeetingStore, onSave, id, onCloseHandler]);

  return (
    <DrawerWithWrapper
      className="min-w-[50%] max-w-[100%]"
      confirm={confirmHandler}
      footer={(
        <Button.Primary
          label={editing ? 'Сохранить' : 'Редактировать'}
          onClick={onSaveOrEditHandler}
        />
      )}
      onClose={onCloseHandler}
      title="Карьерная встреча"
    >
      <div>
        <DatePicker
          disabled={!editing}
          error={viewModel.errors.meetingDate}
          label="Время встречи"
          onChange={(value) => {
            viewModel.meetingDate = value;
          }}
          value={viewModel.meetingDate}
          withTime
        />
        <FormInput
          disabled={!editing}
          error={viewModel.errors.description}
          label="Описание"
          onChange={(event) => {
            viewModel.description = event.target.value;
          }}
          value={viewModel.description}
        />
        <FormInput
          disabled={!editing}
          error={viewModel.errors.results}
          label="Результаты"
          onChange={(event) => {
            viewModel.results = event.target.value;
          }}
          value={viewModel.results}
        />
        <Select.AsyncMulti
          disabled={!editing}
          label="Приглашенные сотрудники"
          onChange={searchEmployeeNames}
          onSelectOption={(values) => {
            viewModel.invitedEmployees = values?.map((x) => ({ id: x.value })) ?? [];
            setInvitedEmployees(values);
          }}
          selectedOptions={invitedEmployees}
        />
        <Errors store={careerMeetingStore} />
      </div>
    </DrawerWithWrapper>
  );
};

export default observer(CareerMeetingForm);
