import { Close } from '@assets';
import Title from '@components/ui/Title/Title';
import React from 'react';
import type { FileImportResponse } from '@typesApi';
import { Modal } from '..';

interface ImportEmployeeResultModalProps {
  /** Имя сущности в родительном падеже c маленькой буквы */
  entityName: string;
  /** Результаты */
  result: FileImportResponse;
  /** Закрыть модалку */
  onClose: () => void;
}

/** Модалка отображения результатов импорта сотрудников */
const ImportResultModal: React.FC<ImportEmployeeResultModalProps> = ({ onClose, result, entityName }) => (
  <Modal
    className="w-96 max-h-96"
    onClose={onClose}
  >
    <div className="flex justify-between">
      <Title isSubtitle>Импорт завершен</Title>
      <Close
        className="cursor-pointer"
        onClick={onClose}
      />
    </div>
    <div>
      {`Загружено ${entityName}:`}
      <span className="ml-2">{result.loadedRowsCount}</span>
    </div>
    <div className="flex gap-2">
      <div className="first-letter:uppercase">{entityName}</div>
      <span>с ошибками:</span>
      <span>{result.rowsWithErrorCount}</span>
    </div>
    <div>
      Ошибки:
      {!result.errors?.length && <span> нет </span>}
    </div>
    <div className="flex flex-col gap-1 max-h-60 overflow-auto">
      {result.errors?.map((err, index) => (
        <div
          key={`err_${index.toString()}`}
          className="text-error"
        >
          {err}
        </div>
      ))}
    </div>
  </Modal>
);

export default ImportResultModal;
