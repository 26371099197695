import { parseStyles } from '@services/utils';
import type { FunctionComponent } from 'react';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Label from '../Label/Label';
import type { FormElementProps, FormGridProps } from './types';

const FormGrid: FunctionComponent<FormGridProps> = ({
  children, minColumnWidth = 340, maxColumnsNumber, className,
}) => {
  const filteredChildren = React.Children.toArray(children).filter(Boolean) as React.ReactElement<FormElementProps>[];

  const [columns, setColumns] = useState(1);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver(() => {
        setColumns(Math.min(
          Math.floor(ref.current!.getBoundingClientRect().width / minColumnWidth),
          filteredChildren.length,
          maxColumnsNumber ?? Infinity,
        ));
      });
      resizeObserver.observe(ref.current!);
      return () => {
        resizeObserver.disconnect();
      };
    }
    return () => {};
  });

  return (
    <div
      ref={ref}
      className={parseStyles`
        grid gap-x-form
        ${className}
      `}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridTemplateRows: `repeat(${Math.ceil(filteredChildren.length / columns)}, [label] auto [input] auto [error] auto)`,
      }}
    >
      {filteredChildren.map((child, index) => (
        <React.Fragment
          key={child.key}
        >
          <div
            className="self-end"
            style={{ gridRow: `label ${Math.floor(index / columns) + 1} / span 1` }}
          >
            <Label>
              {child.props.label}
            </Label>
          </div>
          <div style={{ gridRow: `input ${Math.floor(index / columns) + 1} / span 1` }}>
            {React.cloneElement(child, {
              error: undefined,
              label: undefined,
              invalid: !!child.props.error,
            })}
          </div>
          <span
            className={
              parseStyles`
                text-error break-words text-xs text-left self-start
                ${filteredChildren.length - (filteredChildren.length % columns) <= (index + 1) ? 'pb-0' : 'pb-2'}
              `
            }
            style={{ gridRow: `error ${Math.floor(index / columns) + 1} / span 1` }}
          >
            {child.props.error}
          </span>
        </React.Fragment>
      ))}

    </div>
  );
};

export default FormGrid;
