import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';

/** Типизация для применения ячейки в компоненте */
export type LinkCellData<T extends Object> = React.FC<{data: T}>;

/** Пропсы ячейки со ссылкой */
interface LinkCellProps<T extends Object> {
  /** Объект в котором содержится id для переход и наименование для отображения */
  object?: T | T[] | null;
  /** Текст ячейки */
  value?: keyof T;
  /** Id для перехода по ссылке */
  linkId: keyof T;
  /** Префикс перед основным id для ссылки */
  prefix?: string;
  /** Иконка если тип ячейки не текстовый */
  icon?: React.ReactNode;
}

/** Ячейки ссылочного типа для таблицы */
const TableLinkCell = <T extends object | object[]>({
  object, value, linkId, prefix, icon,
}: LinkCellProps<T>) => (
  <div
    className="h-full items-center justify-start text-left overflow-hidden"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {Array.isArray(object)
      ? (
        <div className="flex flex-nowrap">
          {object?.map((item, index, array) => {
            if (!item[linkId] && !item[value]) {
              return null;
            }
            if (!item[linkId]) {
              return (
                <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                  {array.length - 1 === index ? item[value] : `${item[value]}, `}
                </div>
              );
            }
            return (
              <Link
                key={item[linkId] ?? ''}
                className="
                  underline decoration-dotted
                  hover:decoration-solid hover:text-btn-main-bg-hover
                  text-ellipsis overflow-hidden whitespace-nowrap
                "
                title={item[value]}
                to={prefix ? `/${prefix}/${item[linkId]}` : String(item[linkId])}
              >
                {array.length - 1 === index ? item[value] : `${item[value]}, `}
              </Link>
            );
          })}
        </div>
      )
      : !!object && object[linkId] && (
        <Link
          className="underline decoration-dotted hover:decoration-solid hover:text-btn-main-bg-hover
          h-full w-full flex items-center justify-center text-left "
          to={prefix ? `/${prefix}/${object[linkId]}` : String(object[linkId])}
        >
          {icon}
          {value && (
            <div
              className="w-full px-3 overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {object[value] ? String(object[value]) : ''}
            </div>
          )}
        </Link>
      )}
  </div>
);

const LinkCell = observer(TableLinkCell);
LinkCell.displayName = 'TableLinkCell';

export default LinkCell;
