import { ProjectService, ResourceService } from '@servicesApi';
import type {
  ListBaseFilter,
  Period,
  Project,
  UnavailablePeriod,
} from '@types';
import { ViewMode } from '@types';
import type {
  GetLoadResourceInProjectsResponseItem,
  GetLoadResourceInProjectsResponseItemLoadResourceInProject,
  GetLoadResourceInProjectsResponseItemResourcesInProject,
  GetLoadResourceInProjectsResponseItemUnavailableResource,
  GetResourceInProjectsResponseUnavailableResourceItem,
} from '@typesApi';
import { ProjectStatuses } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { seedDates } from '@components/CalendarTable/helpers';
import utc from 'dayjs/plugin/utc';
import { dayOfWeekEnumToNumber, paramsSerializer } from '@services/utils';
import type { DayData } from '@components/CalendarTable/types';
import { ProjectsLoadViewModel } from './ProjectsLoadViewModel';
import BaseEntityListStore from '../BaseEntityListStore';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

dayjs.extend(utc);

const startOfYear = dayjs.utc().startOf('year').utc();

function getDateIndex(date: Dayjs, startDate: Date, viewMode: ViewMode) {
  switch (viewMode) {
    case ViewMode.Day: return dayjs.utc(date).utc().diff(startDate, 'day');
    case ViewMode.Week: return dayjs.utc(date).utc().diff(startDate, 'week');
    case ViewMode.Month: return dayjs.utc(date).utc().diff(startDate, 'month');
    default: return dayjs.utc(date).utc().diff(startDate, 'day');
  }
}

interface Filter extends ListBaseFilter {
  filterName?: string;
  startDateFrom?: string;
  startDateTo?: string;
  finishDateFrom?: string;
  finishDateTo?: string;
  isDeleted?: boolean;
  status?: ProjectStatuses;
}

export class ProjectsLoadStore extends BaseEntityListStore<GetLoadResourceInProjectsResponseItem, Filter, ProjectsLoadViewModel> {
  @observable privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super(10);
    makeObservable(this);
    this.privilegesStore = privilegesStore;
    reaction(() => ({ period: this.period, startDate: this.startDate }), () => {
      this.filter.pageNumber = 1;
      this.filter.startDateTo = dayjs.utc(this.endDate).endOf('day').toISOString();
      this.filter.finishDateFrom = dayjs.utc(this.startDate).startOf('day').toISOString();
      this.filter.orderBy = 'name';
      this.filter.isAscending = true;
      this.filter.status = ProjectStatuses.Active;
      this.fetch(true);
    });
  }

  /** развёрнутые проекты */
  @observable visibleChildren: string[] = [];

  /** пока работает на половину, в будущем юнит в клетке День/Неделя/Месяц */
  @observable viewMode: ViewMode = ViewMode.Day;

  // #region Размеры и скролл таблички
  @observable scrollTop = 0;

  @observable scrollLeft = 0;

  @observable columnWidth = 25;

  @observable rowHeight = 25;

  @observable width = 0;

  @observable height = 0;
  // #endregion

  @observable startDate: Date = startOfYear.utc().toDate();

  @observable period: number = 3;

  @computed get firstRenderedColumn() {
    return Math.floor((this.scrollLeft - (this.width / 2)) / this.columnWidth);
  }

  @computed get lastRenderedColumn() {
    return Math.ceil((this.scrollLeft + (this.width * 1.5)) / this.columnWidth);
  }

  @computed get firstRenderedRow() {
    return Math.floor((this.scrollTop - (this.height / 2)) / this.rowHeight);
  }

  @computed get lastRenderedRow() {
    return Math.ceil((this.scrollTop + (this.height * 1.5)) / this.rowHeight);
  }

  @computed get endDate(): Date {
    return dayjs.utc(this.startDate).add(this.period, 'month').subtract(1, 'day').toDate();
  }

  @computed get unitForDayJS() {
    return this.viewMode.toLocaleLowerCase() as 'day' | 'week' | 'month' | 'year';
  }

  @computed get seededDates() {
    return seedDates(this.startDate, this.endDate, this.viewMode);
  }

  @computed get seededDaysOfWeek() {
    return this.seededDates.map((date: Date | undefined) => date?.getDay());
  }

  @computed
  public get visibleChildrenCount() {
    return this.viewModel.reduce<number>((acc, cur) => acc + (this.visibleChildren.includes(cur.id) ? cur.resourcesInProject.length + 1 : 1), 0);
  }

  @computed
  public get projects(): Project[] {
    return this.viewModel.map((e) => ({ ...e, hideChildren: !this.visibleChildren.includes(e.id) }));
  }

  @computed({ requiresReaction: true })
  get expandedProjects(): (GetLoadResourceInProjectsResponseItemResourcesInProject & {projectId: string; projectName: string})[] {
    return this.viewModel
      .flatMap((e) => [
        <GetLoadResourceInProjectsResponseItemResourcesInProject & {projectId: string; projectName: string}>{
          periods: [], name: '', id: '', projectName: e.name, projectId: e.id,
        },
        ...e.resourcesInProject.map((el) => ({ ...el, projectName: e.name, projectId: e.id })),
      ]);
  }

  /** массив для всех ресурсов с нагрузкой по дням на проекте */
  @computed({ requiresReaction: true }) get rowsDayToLoadMap(): Map<number, {load: number; weekend: boolean}>[] {
    const startTime = performance.now();
    const res = this.expandedProjects
      .map((e) => {
        const map = new Map<number, {load: number; weekend: boolean}>();
        const currentProject = e.projects?.find((el) => el.projectId === e.projectId);

        currentProject?.loadResourceInProjects
          ?.forEach((p) => {
            const {
              startDate, finishDate, workingDaysOfWeek, isWorkOnWeekends, workingHoursPerDay,
            } = p;
            const { workingDaysOfWeek: currentWorkingDaysOfWeek } = currentProject ?? {};
            const periodStartIndex = getDateIndex(dayjs.utc(startDate).utc(), this.startDate, this.viewMode);
            const periodEndIndex = getDateIndex(dayjs.utc(finishDate).utc(), this.startDate, this.viewMode) + 1;
            for (let index = periodStartIndex; index < periodEndIndex; index++) {
              const workingDays = (workingDaysOfWeek ?? currentWorkingDaysOfWeek)?.map(dayOfWeekEnumToNumber);
              const weekend = !isWorkOnWeekends && !workingDays?.includes(this.seededDaysOfWeek[index] as number);
              map.set(index, {
                load: weekend ? 0 : workingHoursPerDay ?? 0,
                weekend,
              });
            }
          });
        return map;
      });
    const endTime = performance.now();
    console.log(`Call1 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** нагрузка всех ресурсов по дням среди всех проектов */
  @computed({ requiresReaction: true }) get resourcesLoad(): Map<string, Map<number, number>> {
    const startTime = performance.now();
    const map = new Map<string, Map<number, number>>();
    const lastDateIndex = dayjs(this.endDate).diff(this.startDate, 'day') + 1;
    this.expandedProjects.forEach((e) => {
      const resourceId = e.id!;
      if (map.has(resourceId)) {
        return;
      }
      map.set(resourceId, new Map<number, number>());
      const insideMap = map.get(resourceId)!;
      e.projects
        ?.forEach((project) => {
          project.loadResourceInProjects?.forEach((period) => {
            const {
              startDate, finishDate, workingDaysOfWeek, isWorkOnWeekends, workingHoursPerDay,
            } = period;
            const { workingDaysOfWeek: projectWorkingDaysOfWeek } = project ?? {};
            const periodStartIndex = Math.max(getDateIndex(dayjs.utc(startDate).utc(), this.startDate, this.viewMode), 0);
            const periodEndIndex = Math.min(getDateIndex(dayjs.utc(finishDate).utc(), this.startDate, this.viewMode) + 1, lastDateIndex);
            for (let index = periodStartIndex; index < periodEndIndex; index++) {
              const workingDays = (workingDaysOfWeek ?? projectWorkingDaysOfWeek)!.map(dayOfWeekEnumToNumber);
              const isWorkDay = isWorkOnWeekends || workingDays.includes(this.seededDaysOfWeek[index] as number);
              const workingHoursThatDay = isWorkDay ? workingHoursPerDay! : 0;
              insideMap.set(
                index,
                (insideMap.get(index) ?? 0) + workingHoursThatDay,
              );
            }
          });
        });
    });
    const endTime = performance.now();
    console.log(`Call2 took ${endTime - startTime} milliseconds`);
    return map;
  }

  /** массив данных для каждой строки */
  @computed({ requiresReaction: true }) get preparedResources(): {
    resourceName: string;
    projectName: string;
    canEditWorkload: boolean;
    canEditUnavailability: boolean;
    projectId: string;
    resourceInProjectId: string;
    resourceId: string;
    days: DayData[];
    unavailableResources: GetLoadResourceInProjectsResponseItemUnavailableResource[];
    periods: GetLoadResourceInProjectsResponseItemLoadResourceInProject[];
  }[] {
    const startTime = performance.now();

    const res = this.rowsDayToLoadMap.map((e, i) => {
      const resource = this.expandedProjects.at(i)!;
      const project = resource.projects?.find((el) => el.projectId === resource.projectId);
      const resourcePeriods = project?.loadResourceInProjects ?? [];
      const canEditWorkload = !!project && (this.privilegesStore.projects.canUpdate
        || this.privilegesStore.getPrivilegesInProject(project!.projectId).UpdateResource
        || this.privilegesStore.responsibleInProjects.some((p) => p.id === project!.projectId));

      const canEditUnavailability = !!project && (this.privilegesStore.resources.canUpdate
        || this.privilegesStore.responsibleForResources.some((r) => r.id === resource!.id));

      return {
        resourceName: resource.name!,
        resourceId: resource.id!,
        resourceInProjectId: resource.resourceInProjectId!,
        projectName: resource.projectName,
        projectId: resource.projectId,
        unavailableResources: resource.unavailableResources ?? [],
        periods: resourcePeriods,
        days: Array.from(e, ([key, val]) => ({
          index: key,
          hours: val.load,
          text: this.seededDates[key]?.toLocaleDateString(),
          total: this.resourcesLoad.get(resource.id!)!.get(key)!,
          weekend: val.weekend,
        })),
        canEditWorkload,
        canEditUnavailability,
      };
    });
    const endTime = performance.now();
    console.log(`Call3 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** только не свёрнутые данные */
  @computed get preparedResourcesWithVisibility() {
    const startTime = performance.now();
    const projectsIds = this.projects.map((e) => e.id);
    const visibilitySet = new Set(this.visibleChildren);

    const res = this.preparedResources
      .filter((e) => !e.resourceId || visibilitySet.has(e.projectId))
      .map((e, rowIndex) => ({ ...e, rowIndex: rowIndex + projectsIds.indexOf(e.projectId) }));
    const endTime = performance.now();
    console.log(`Call4 took ${endTime - startTime} milliseconds`);
    return res;
  }

  /** только не свёрнутые и видимые на странице данные */
  @computed get visibleResources() {
    const startTime = performance.now();

    const res = this.preparedResourcesWithVisibility
      .slice(Math.max(0, this.firstRenderedRow), this.lastRenderedRow)
      .map((e) => ({
        ...e,
        days: e.days.filter((day) => {
          const columnFits = day.index > this.firstRenderedColumn && day.index < this.lastRenderedColumn;

          return columnFits;
        }),
      }));
    const endTime = performance.now();
    console.log(`Call5 took ${endTime - startTime} milliseconds`);
    return res;
  }

  @action toggle(id: string) {
    if (this.visibleChildren.includes(id)) {
      this.visibleChildren = this.visibleChildren.filter((e) => e !== id);
    } else {
      this.visibleChildren = [...this.visibleChildren, id];
    }
  }

  @action async addResourceLoad(period: Period, y: number) {
    const resource = this.visibleResources.find((e) => e.rowIndex === y)!;
    await ResourceService.resourceInProjectUpdate(resource.resourceInProjectId, {
      ...period,
      startDate: period.startDate.toISOString(),
      finishDate: period.finishDate.toISOString(),
    });
    this.reload();
  }

  @action async addUnavailabilityPeriod(period: UnavailablePeriod, y: number) {
    const resource = this.visibleResources.find((e) => e.rowIndex === y)!;
    await ResourceService.unavailableReasonUpdate(resource.resourceId!, {
      ...period,
      startDate: period.startDate.toISOString(),
      finishDate: period.finishDate.toISOString(),
    });
    this.reload();
  }

  @action async removeResourceLoad(startDate: Dayjs, finishDate: Dayjs, y: number) {
    const resource = this.visibleResources.find((e) => e.rowIndex === y)!;
    await ResourceService.loadResourceInProjectDelete(resource.resourceInProjectId!, {
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
    });
    this.reload();
  }

  @action async changePeriod(y: number, period: GetLoadResourceInProjectsResponseItemLoadResourceInProject, startDate: Dayjs, finishDate: Dayjs) {
    if (finishDate.isBefore(startDate)) {
      await this.removeResourceLoad(dayjs(period.startDate), dayjs(period.finishDate), y);
      return;
    }
    const resource = this.visibleResources.find((e) => e.rowIndex === y)!;
    const project = this.viewModel.find((e) => e.id === resource.projectId);
    if (!project) {
      throw new Error('Не найден проект');
    }
    const projectResources = [...project.resourcesInProject];
    const foundResource = projectResources.find((e) => e.id === resource.resourceId);
    if (!foundResource) {
      throw new Error('Не найден ресурс в проекте');
    }
    await ResourceService.resourceInProjectUpdate(foundResource.resourceInProjectId!, {
      ...period,
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
      deleteFinishDate: period.finishDate,
      deleteStartDate: period.startDate,
    });
    this.reload();
  }

  @action async changeUnavailablePeriod(
    y: number,
    period: GetResourceInProjectsResponseUnavailableResourceItem,
    startDate: Dayjs,
    finishDate: Dayjs,
  ) {
    if (finishDate.isBefore(startDate)) {
      await this.deleteUnavailabilityPeriod(y, period);
      return;
    }
    const resource = this.visibleResources.find((e) => e.rowIndex === y)!;
    await ResourceService.unavailableReasonUpdate(resource.resourceId!, {
      ...period,
      startDate: startDate.toISOString(),
      finishDate: finishDate.toISOString(),
      deleteFinishDate: period.finishDate,
      deleteStartDate: period.startDate,
    });

    this.reload();
  }

  @action async deleteUnavailabilityPeriod(y: number, period: GetResourceInProjectsResponseUnavailableResourceItem) {
    const resource = this.visibleResources.find((e) => e.rowIndex === y)!;
    await ResourceService.unavailableReasonDelete(resource.resourceId!, {
      startDate: period.startDate!,
      finishDate: period.finishDate!,
    });
    this.reload();
  }

  @action public async deleteResource(id?: string) {
    if (!id) {
      return;
    }
    await this.runWithStateControl(async () => (await ResourceService.resourceInProjectDelete(id)).data);
    this.reload();
  }

  /** Перезагрузка всего что уже отображается  */
  public async reload(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ProjectService.loadResourceInProjectsList(
        { ...this.filter, pageSize: this.getRawData().length || this.filter.pageSize, pageNumber: 1 },
        { paramsSerializer },
      );
      this.setData(data, ProjectsLoadViewModel);
    });
  }

  /** Загрузка потребностей по фильтру или новая страница  */
  public async fetch(first = false): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ProjectService.loadResourceInProjectsList(
        this.filter,
        { paramsSerializer },
      );
      this.setData(first ? data : { entities: [...this.getRawData(), ...data.entities ?? []], totalCount: data.totalCount }, ProjectsLoadViewModel);

      runInAction(() => {
        this.visibleChildren = observable.array(
          first
            ? data.entities?.map((e) => e.id!)
            : [...this.visibleChildren, ...data.entities?.map((e) => e.id!) ?? []],
        );
      });
    });
  }
}
