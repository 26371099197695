import type { GetContragentByIdResponse } from '@typesApi';
import { computed, makeObservable, runInAction } from 'mobx';
import { validationRequired } from '@services/validationFunctions';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import BaseViewModel from '../BaseViewModel';
import { CounterpartyProjectsStore } from './CounterpartyProjectsStore';

export class CounterpartyViewModel extends BaseViewModel<GetContragentByIdResponse> {
  public projects: CounterpartyProjectsStore;

  constructor(data: GetContragentByIdResponse) {
    super(data);
    this.projects = new CounterpartyProjectsStore(data.projects ?? []);
    makeObservable(this);
  }

  /** Получение  ИД*/
  @computed get id() {
    return this.data.id;
  }

  set id(value: string | undefined) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** Получение названия */
  @computed get name() {
    return this.data.name || '';
  }

  /** Изменение названия */
  set name(value: string | undefined | null) {
    runInAction(() => {
      this.data.name = value;
      this.validateField('name', validationRequired);
    });
  }

  /** Получение ФИО ответственного */
  @computed get responsibleName() {
    return this.data.responsibleName || '';
  }

  /** Изменение ФИО ответственного */
  set responsibleName(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleName = value;
    });
  }

  /** Получение комментария */
  @computed get comment() {
    return this.data.comment || '';
  }

  /** Изменение комментария */
  set comment(value: string | undefined | null) {
    runInAction(() => {
      this.data.comment = value;
    });
  }

  /** Получение даты архивации */
  @computed get deletedOn() {
    return this.data.deletedOn ? dayjs(this.data.deletedOn) : undefined;
  }

  /** Изменить 'Дата архивации' */
  set deletedOn(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.deletedOn = value?.toISOString();
    });
  }

  /** Получение флага Архивная сущность */
  @computed get isDeleted() {
    return this.data.isDeleted ?? false;
  }
}
