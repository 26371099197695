import { ResourceService } from '@servicesApi';
import type { GetResourceInProjectByIdResponse } from '@typesApi';
import { runInAction } from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import ResourceInProjectViewModel from './ResourceInProjectViewModel';

/** Стор сущности */
export class ResourceInProjectStore extends EntityBaseStore<GetResourceInProjectByIdResponse, ResourceInProjectViewModel> {
  public viewModel = new ResourceInProjectViewModel({});

  /** Фетч сущности */
  public override async fetch(id: string): Promise<void> {
    this.runWithStateControl(async () => {
      const { data } = await ResourceService.resourceInProjectDetail(id);
      runInAction(() => {
        this.viewModel = new ResourceInProjectViewModel(data);
      });
    });
  }

  /** Удалить ресурс с проекта */
  public async deleteResourceFromProject(resourceInProjectId: string) {
    await this.runWithStateControl(async () => {
      await ResourceService.resourceInProjectDelete(resourceInProjectId);
    });
  }

  public save(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
