import React, { useState } from 'react';
import { parseStyles } from '@services/utils';
import type { WithClassName } from '../types';
import Loader from '../Loader/Loader';

interface ImageProps extends WithClassName {
  /** Alt */
  alt: string;
  /** Ссылка на изображение */
  imageLink: string;
  /** Нативный тайтл */
  title?: string;
}

/** Компонент с картинкой */
const Image: React.FC<ImageProps> = ({
  className, alt, imageLink, title,
}) => {
  /** Загрузилась ли целиком картинка  */
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <img
        alt={alt}
        className={
          parseStyles`
            max-w-fit
            ${isImageLoaded ? `${className}` : 'hidden'}
          `
        }
        onLoad={() => setImageLoaded(true)}
        src={imageLink}
        title={title}
      />
      {!isImageLoaded
      && (
        <div className={parseStyles`
          w-full aspect-square flex items-center justify-center
          ${className}
        `}
        >
          <Loader size={15} />
        </div>
      )}
    </>
  );
};

export default React.memo(Image);
