import type { SelectOption } from '@components/ui/Select/types';
import { ContragentService, ProjectService, ResourceService } from '@servicesApi';
import type { GetProjectByIdResponse } from '@typesApi';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';
import ProjectViewModel from './ProjectViewModel';
import type EmployeeNamesStore from '../Employees/EmployeeNamesStore';

class ProjectStore extends EntityBaseStore<GetProjectByIdResponse, ProjectViewModel> {
  public viewModel = new ProjectViewModel({});

  /** Имена сотрудников */
  public employeeNames?: SelectOption[];

  /** Стор привилегий */
  @observable private _privilegesStore: PrivilegesStore;

  /** Стор сотрудников */
  @observable private _employeeNamesStore: EmployeeNamesStore;

  constructor(privilegesStore: PrivilegesStore, employeeNamesStore: EmployeeNamesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
    this._employeeNamesStore = employeeNamesStore;
  }

  @computed
  public get canUpdateResource() {
    return this._privilegesStore.projects.canUpdate
      || this.projectPrivileges.UpdateResource
      || this.isResponsibleForProject;
  }

  @computed
  public get canReadResponsible(): boolean {
    return this._privilegesStore.resources.canRead
      || (!!this.viewModel.responsibleId && this._privilegesStore.resources.isResponsibleFor(this.viewModel.responsibleId!));
  }

  @computed
  public get canUpdateResponsible() {
    return this._privilegesStore.projects.canUpdateResponsible;
  }

  @computed
  public get canEdit() {
    return this.canUpdate || this.canUpdateResponsible;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.roles.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.projects.canDelete
      || this.projectPrivileges.DeleteProject
      || this.isResponsibleForProject;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.projects.canUpdate
      || this.projectPrivileges.UpdateProject
      || this.isResponsibleForProject;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.roles.canRead;
  }

  /** Получение проекта по id */
  public async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ProjectService.detail(
        id,
      );
      runInAction(() => {
        this.viewModel = new ProjectViewModel(data);
      });
    });
  }

  /** Сохранение изменений в проекте */
  @action
  public async save(): Promise<void> {
    if (this.canUpdateResponsible) {
      await this.setResponsible();
    }
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await ProjectService.update(this.viewModel.id!, this.viewModel.getRawData());
      });
    }
  }

  /** Создание нового проекта */
  public async create() {
    return this.runWithStateControl(async () => {
      const { data } = await ProjectService.create(this.viewModel.getRawData());

      return data.id;
    });
  }

  /** Запрос списка контрагентов */
  public async fetchContragents(filterName?: string): Promise<SelectOption[]> {
    const { data: { entities } } = await ContragentService.list({ filterName });

    return entities?.map((contragent) => ({ label: contragent.name ?? '', value: contragent.id ?? '' })) ?? [];
  }

  /** Получить список опций для селекта */
  public async fetchEmployeeNames(search?: string) {
    const result = await this._employeeNamesStore.fetchEmployeeNames(search);

    runInAction(() => {
      this.employeeNames = result;
    });

    return result;
  }

  /** Добавить ресурс на проект */
  public async addResource(resourceId: string) {
    const { id } = this.viewModel;
    if (id) {
      await this.runWithStateControl(async () => {
        await ResourceService.projectUpdate(resourceId, id, {});
      });
    }
  }

  /** Установить ответственного */
  public async setResponsible() {
    if (this.viewModel.id) {
      this.runWithStateControl(async () => {
        await ProjectService.responsibleUpdate(this.viewModel.id!, { responsibleId: this.viewModel.responsibleId });
      });
    }
  }

  /** Архивирование проекта */
  public async archive() {
    await this.swithArchive(true);
  }

  /** Разархивирование проекта */
  public async unarchive() {
    await this.swithArchive();
  }

  private async swithArchive(deleteRightNow?: boolean) {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await ProjectService.delete(this.viewModel.id!, { deleteRightNow });
      });
    }
  }

  @computed
  public get isResponsibleForProject() {
    return this._privilegesStore.responsibleInProjects?.some((e) => e.id === this.viewModel.id);
  }

  @computed
  private get projectPrivileges() {
    return this._privilegesStore.getPrivilegesInProject(this.viewModel.id);
  }
}

export default ProjectStore;
