import type { DayOfWeek, GetLoadResourceInProjectsResponseItemResourcesInProject } from '@typesApi';
import type { Dayjs } from 'dayjs';

/** Словарь */
export interface Dictionary<T> {
  [key: string]: T;
}

/** Добавление null к типу */
export type Nullable<T> = T | null;

/** Форма даты */
export const DateTypeFormat = 'DD-MM-YYYY';

/** Вью модель сущности */
export interface IViewModel<T> {
  /** Объект с данными */
  get data(): Partial<T>;
  /** Получить чистый объект с данными */
  getRawData: () => Partial<T>;
  /** Очистить вью модель */
  clear(): void;
}

/** Список сущностей */
export interface EntityList<T> {
  /** массив сущностей */
  entities?: Nullable<Array<T>>;
  /** кол-во сущностей всего */
  totalCount?: number;
}

/** Общий фильтр для списка сущностей */
export interface ListBaseFilter {
  /** Размер страницы */
  pageSize?: number;
  /** Номер страницы */
  pageNumber?: number;
  /** Поле для сортировки */
  orderBy?: string;
  /** Сортировать по возрастанию */
  isAscending?: boolean;
}

/** базовые поля view для таблички */
interface BaseTableItemView<P> {
  /** Получить чистый объект с данными */
  getRawData: () => Partial<P>;
  errors: Record<string, string>;
}

/**
 * ключи объекта подходящие под тип
 * @template T тип объекта
 * @template V искомый тип
*/
export type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

/**
 * view для таблички
 * @template T тип объекта в табличке
 * @template P тип сущности с сервера
 *
*/
export type TableItemView<T, P> = BaseTableItemView<P> & T;

export type ArrayOrSingle<T> = T | T[];

export enum ViewMode {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export interface Project {
  resourcesInProject: GetLoadResourceInProjectsResponseItemResourcesInProject[];
  name: string;
  hideChildren: boolean;
  id: string;
  startDate?: Dayjs;
  finishDate?: Dayjs;
}

export type Period = {
  startDate: Dayjs;
  finishDate: Dayjs;
  workingHoursPerDay: number;
  workingDaysOfWeek: DayOfWeek[];
  isWorkOnWeekends: boolean;
}

export type UnavailablePeriod = {
  startDate: Dayjs;
  finishDate: Dayjs;
  reasonId: string;
  reasonName: string;
}
