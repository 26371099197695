import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks';
import { useEffectOnce, useReadLocalStorage } from 'usehooks-ts';
import { refreshTokenName } from '@services/utils';
import { Loader } from '@components/ui';
import { useNavigate, useParams } from 'react-router-dom';

/** Страница уведомления пользователя об обработке платежа */
const PaymentRedirect: React.FC = () => {
  const { accountStore } = useStores();
  const { id } = useParams();
  const refreshToken = useReadLocalStorage<string>(refreshTokenName || 'refreshToken');
  const navigate = useNavigate();

  useEffectOnce(() => {
    const redirect = async () => {
      if (!id) {
        return;
      }
      await accountStore.fetchStatusPaymentTransactions(id, refreshToken || '');
      if (!accountStore.statusPaymentTransaction?.isFinished) {
        navigate('/settings/organization', {
          state: {
            status: 'Запрос обрабатывается банком. Подписка активируется когда банк обработает запрос.',
          },
        });
      }
      if (accountStore.statusPaymentTransaction?.isFinished && accountStore.statusPaymentTransaction?.isConfirmed) {
        navigate('/settings/organization', {
          state: {
            status: 'Подписка успешно оплачена!',
          },
        });
      }
      if (accountStore.statusPaymentTransaction?.isFinished && !accountStore.statusPaymentTransaction?.isConfirmed) {
        navigate('/settings/organization', {
          state: {
            status: 'Транзакция не подтверждена.',
          },
        });
      }
    };
    redirect();
  });

  return (
    <div className="w-full h-full flex items-center justify-center">
      <Loader size={50} />
    </div>
  );
};

export default observer(PaymentRedirect);
