import NavigateBack from '@components/ui/NavigateBack/NavigateBack';
import { setTokenToStorage } from '@services/utils';
import React from 'react';

const AccountNotActive: React.FC = () => (
  <div className="flex w-full h-full items-center justify-center">
    <NavigateBack
      onClick={() => setTokenToStorage()}
      onlyForPOPType={false}
      route="/login"
    />
    <p className="text-lg">Пользователь неактивен</p>
  </div>
);

export default AccountNotActive;
