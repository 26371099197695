import TitlePopup from '@components/ui/TitlePopup/TitlePopup';
import { useStores } from '@hooks';
import { isCurrentWidthLessThanFullWidth, parseStyles } from '@services/utils';
import { observer } from 'mobx-react-lite';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const ProjectResourcesListTable = forwardRef<HTMLDivElement, {
  rowWidth: string;
}>(({ rowWidth }, ref) => {
  const { projectLoadStore } = useStores();
  const {
    rowHeight,
    viewModel,
  } = projectLoadStore;

  const listRef = useRef<HTMLDivElement>(null);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => listRef.current);

  /** Элемент над которым будет попап */
  const [cellTarget, setCellTarget] = useState<HTMLDivElement>();

  /** Данные для попапа с подсказкой */
  const [popupData, setPopupData] = useState<{text: string; show: boolean} | null>(null);

  /** Показывать ли попап с подсказкой */
  const [showPopup, setShowPopup] = useState(false);

  /** Обработчик наведения на ячейку с текстом */
  const onCellHover = useCallback((name: string, element: HTMLDivElement) => {
    if (!isCurrentWidthLessThanFullWidth(element)) {
      return;
    }

    setShowPopup(true);
    setPopupData({ text: name, show: false });
    setCellTarget(element);
  }, []);

  /** Скрытие/показ попапа по таймеру */
  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setPopupData((prev) => {
          if (showPopup) {
            return { text: prev?.text || '', show: true };
          }
          return null;
        });
      },
      400,
    );

    return () => clearTimeout(timeout);
  }, [showPopup, popupData]);

  return (
    <div
      ref={listRef}
      className="table bg-white"
    >
      {viewModel.map((resource) => (
        <div
          key={`${resource.resourceInProjectId}`}
          className="table-row overflow-ellipsis  [&:last-child>*]:border-b-0 "
          id={`row-${resource.resourceInProjectId}`}
          style={{ height: rowHeight }}
        >
          <div
            className={parseStyles`
              table-cell
              align-middle whitespace-nowrap overflow-hidden overflow-ellipsis
              border-b-graph-border border-r-graph-border
            `}
            style={{
              minWidth: rowWidth,
              maxWidth: rowWidth,
            }}
          >
            <div className="flex">
              <div
                className="text-sm font-semibold truncate pl-2"
                onMouseEnter={(e) => onCellHover(resource.name || '', e.currentTarget)}
                onMouseLeave={() => setShowPopup(false)}
              >
                {resource.name}
              </div>
            </div>
          </div>
        </div>
      ))}
      {(cellTarget && popupData?.show && showPopup) && (
        <TitlePopup
          element={cellTarget}
          maxWidth={rowWidth}
          setShowPopup={(value) => setShowPopup(value)}
          text={popupData.text}
        />
      )}
    </div>
  );
});
ProjectResourcesListTable.displayName = 'ProjectResourcesListTable';

export default observer(ProjectResourcesListTable);
