import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { parseStyles } from '@services/utils';
import { ArrowDown } from '@assets';
import type { AccordionProps, PanelChild } from './types';

/** Аккордеон */
const Accordion: React.FC<AccordionProps> = ({
  single = false, children, onChange, className, headerClassName, contentClassName, iconClassName, clickAnywhere = false, openedIds,
}) => {
  const [opened, setOpened] = useState<string[]>(openedIds ?? []);

  useEffect(() => {
    onChange?.(opened);
  }, [onChange, opened]);

  const parsedChildren = useMemo(
    () => React.Children.toArray(children) as PanelChild[],
    [children],
  );

  const clickHandler = useCallback(
    (id: string) => {
      if (opened.includes(id)) {
        setOpened(opened.filter((e) => e !== id));
      } else {
        setOpened([...(single ? [] : opened), id]);
      }
    },
    [opened, single],
  );

  const toggleClasses = useCallback(
    (el: HTMLDivElement, collapsed: boolean) => {
      el.parentElement?.classList.toggle('[&:last-child>.content]:border-0', collapsed);
      el.parentElement?.classList.toggle('[&:last-child>.content]:border-1', !collapsed);
      el.parentElement?.classList.toggle('[&:last-child>.content]:border-t-0', !collapsed);
    },
    [],
  );

  return (
    <div className={parseStyles`
      flex flex-col
      ${className}
    `}
    >
      {parsedChildren.map((e) => {
        const collapsed = !opened.includes(e.props.id);
        const animationCLasses = collapsed
          ? 'max-h-0 transition-mh duration-300 ease-close-accordion'
          : 'transition-mh duration-1000 ease-open-accordion max-h-[10000px]';

        return (
          <div
            key={e.key}
            className={parseStyles`
              wrapper group 
              [&:last-child>.content]:border-0
              ${e.props.className}
            `}
          >
            <div
              className={parseStyles`
                relative z-[5] header border-1 border-accordion-border
                ${clickAnywhere && 'cursor-pointer'}
                ${headerClassName ?? 'bg-accordion-bg px-6 py-4 flex items-center'}
              `}
              onClick={clickAnywhere ? () => clickHandler(e.props.id) : undefined}
            >
              <div className="text-font-sub-header pr-2 max-w-fit flex-1">
                {typeof e.props.header === 'string' ? e.props.header : <e.props.header />}
              </div>
              {!collapsed && e.props.headerButtons && (
                <div className="flex gap-form flex-1 min-w-0 [&>*:first-child]:ml-auto overflow-hidden">{e.props.headerButtons}</div>
              )}
              {e.props.icon
                ? (
                  <e.props.icon
                    collapsed={collapsed}
                    onClick={() => clickHandler(e.props.id)}
                  />
                )
                : (
                  <div
                    className={parseStyles`border-1 border-accordion-border w-[30px] h-[30px] rounded-full min-w-[30px]
                    ${!collapsed && e.props.headerButtons ? 'ml-2' : 'ml-auto'}`}
                    onClick={() => clickHandler(e.props.id)}
                    onKeyDown={(event) => {
                      if (['Enter', ' '].includes(event.key)) {
                        clickHandler(e.props.id);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <ArrowDown
                      className={parseStyles`
                      m-auto h-full
                      transition-transform
                      duration-300 
                      ${collapsed ? 'rotate-180' : ''}
                      ${iconClassName ?? 'cursor-pointer'}
                    `}
                    />
                  </div>
                )}
            </div>
            <div
              ref={(ref) => {
                ref?.addEventListener('transitionstart', () => {
                  if (!collapsed) {
                    toggleClasses(ref, collapsed);
                  }
                });
                if (!collapsed) {
                  ref?.dispatchEvent(new Event('transitionstart'));
                }
              }}
              className={parseStyles`
                content
                overflow-hidden
                mt-[-1px]
                border-accordion-border
                border-1
                border-y-0
                ${animationCLasses}
                ${contentClassName ?? 'bg-accordion-content-bg'}
              `}
              onTransitionEnd={(div) => {
                const target = div.target as HTMLDivElement;
                if (collapsed) {
                  toggleClasses(target, collapsed);
                }
              }}
            >
              <div className="pt-4 p-6">
                {e.props.children}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
