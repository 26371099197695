import { FormInput } from '@components/ui';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { Select } from '@components/ui/Select';
import { useStores } from '@hooks';
import { ResourceTypes } from '@typesApi';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import type ProjectRequirementEmployeeViewModel from 'src/stores/ProjectRequirement/ProjectRequirementEmployeeViewModel';
import type { ProjectRequirementModalProps } from './types';

/** Форма заполнения данных потребности "Сотрудник" */
const ProjectRequirementEmployee: React.FC<ProjectRequirementModalProps> = ({
  projectRequirementId, disabled,
}) => {
  /** Стор */
  const { projectRequirementStore } = useStores();
  /** Модель */
  const viewModel = projectRequirementStore.viewModel as ProjectRequirementEmployeeViewModel;

  useEffect(() => {
    projectRequirementStore.resourceType = ResourceTypes.Employee;
    if (projectRequirementId) {
      projectRequirementStore.fetch(projectRequirementId);
    }
  }, [projectRequirementId, projectRequirementStore]);

  return (
    <>
      <FormInput
        disabled={disabled}
        error={viewModel.errors.name}
        label="Наименование"
        onChange={(event) => {
          viewModel.name = event.target.value;
        }}
        value={viewModel.name}
      />
      <DatePicker
        borderClasses="border-1 rounded-main"
        disabled={disabled}
        error={viewModel.errors.startDate}
        label="Начало периода"
        onChange={(value) => {
          viewModel.startDate = value;
        }}
        value={viewModel.startDate}
      />
      <DatePicker
        borderClasses="border-1 rounded-main"
        disabled={disabled}
        error={viewModel.errors.finishDate}
        label="Окончание периода"
        onChange={(value) => {
          viewModel.finishDate = value;
        }}
        value={viewModel.finishDate}
      />
      <FormInput
        disabled={disabled}
        error={viewModel.errors.totalLoad}
        label="Нагрузка в день"
        onChange={(event) => {
          viewModel.totalLoad = Number(event.target.value);
        }}
        type="number"
        value={viewModel.totalLoad?.toString()}
      />
      <FormInput
        disabled={disabled}
        error={viewModel.errors.maxWorkingRate}
        label="Макс. ставка"
        onChange={(event) => {
          viewModel.maxWorkingRate = Number(event.target.value);
        }}
        type="number"
        value={viewModel.maxWorkingRate?.toString()}
      />
      <Select.Catalog
        catalogIdField="specializationId"
        catalogName="Specialization"
        catalogNameField="specializationName"
        disabled={disabled}
        error={viewModel.errors.specializationId}
        label="Специализация"
        viewModel={viewModel}
      />
      <Select.Catalog
        catalogIdField="positionId"
        catalogName="Position"
        catalogNameField="positionName"
        disabled={disabled}
        error={viewModel.errors.positionId}
        label="Должность"
        viewModel={viewModel}
      />
      <Select.Catalog
        catalogIdField="qualificationId"
        catalogName="Qualification"
        catalogNameField="qualificationName"
        disabled={disabled}
        error={viewModel.errors.qualificationId}
        label="Грейд"
        viewModel={viewModel}
      />
    </>
  );
};

export default observer(ProjectRequirementEmployee);
