import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { parseStyles } from '@services/utils';
import tailwindConfig from '@components/ui/Input/input.config';
import type { BaseInputProps } from './types';

/** Базовый Input */
const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(({
  postfix,
  pattern,
  mask,
  onEnter,
  width,
  height = tailwindConfig.theme.extend.height['i-default'],
  backgroundColor = 'transparent',
  borderClasses,
  disabled,
  ...HTMLInputProps
}, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => innerRef.current,
  );

  // https://stackoverflow.com/questions/12578507/implement-an-input-with-a-mask
  useEffect(() => {
    if (!mask) {
      return () => {};
    }
    if (!innerRef.current) {
      return () => {};
    }
    const slots = new Set(innerRef.current.dataset.slots || '_');
    // eslint-disable-next-line no-return-assign
    const prev = ((j) => Array.from(mask, (c, i) => (slots.has(c) ? j = i + 1 : j)))(0);
    const first = mask.split('').findIndex((c) => slots.has(c));
    const accept = new RegExp(innerRef.current.dataset.accept || '\\d', 'g');
    const clean = (input: string) => {
      const match: RegExpMatchArray | [] = input.match(accept) || [];

      return Array.from(mask, (c) => (match[0] === c || slots.has(c) ? match.shift() || c : c));
    };
    let back = false;
    const format = (e: Event) => {
      const [i, j] = [innerRef.current!.selectionStart, innerRef.current?.selectionEnd].map((k) => {
        k = clean(innerRef.current!.value.slice(0, k ?? undefined)).findIndex((c) => slots.has(c));
        // eslint-disable-next-line no-nested-ternary
        return k < 0 ? prev[prev.length - 1] : back ? prev[k - 1] || first : k;
      });
      innerRef.current!.value = clean(innerRef.current!.value).join('');
      innerRef.current?.setSelectionRange(i, j);
      HTMLInputProps.onChange?.({
        ...e,
        target: innerRef.current!,
        currentTarget: innerRef.current!,
        nativeEvent: e,
        isDefaultPrevented: () => false,
        isPropagationStopped: () => false,
        persist: () => {},
      });
      back = false;
    };
    const keyDownListener = (e: KeyboardEvent) => {
      back = e.key === 'Backspace';
    };
    const blurListener = (e: Event) => {
      if (innerRef.current!.value === mask) {
        innerRef.current!.value = '';
        HTMLInputProps.onChange?.({
          ...e,
          target: innerRef.current!,
          currentTarget: innerRef.current!,
          nativeEvent: e,
          isDefaultPrevented: () => false,
          isPropagationStopped: () => false,
          persist: () => {},
        });
      }
    };
    innerRef.current.addEventListener('keydown', keyDownListener);
    innerRef.current.addEventListener('input', format);
    innerRef.current.addEventListener('focus', format);
    innerRef.current.addEventListener('blur', blurListener);
    return () => {
      innerRef.current?.removeEventListener('keydown', keyDownListener);
      innerRef.current?.removeEventListener('input', format);
      innerRef.current?.removeEventListener('focus', format);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      innerRef.current?.removeEventListener('blur', blurListener);
    };
  }, [pattern, mask, HTMLInputProps.onChange, HTMLInputProps]);

  return (
    <div className={parseStyles`
        flex flex-1
        rounded-main 
        ${HTMLInputProps.className}
        ${borderClasses}
      `}
    >
      <input
        {...HTMLInputProps}
        ref={innerRef}
        checked={HTMLInputProps.checked ?? undefined}
        className={parseStyles`
          outline-none px-2
          rounded-main
          w-full
          text-input-main
          ${disabled && 'pointer-events-none'}
          ${!HTMLInputProps.value && 'empty'}
        `}
        data-slots="_"
        disabled={disabled}
        height={height}
        onKeyDown={(e) => {
          if (HTMLInputProps.onKeyDown) {
            HTMLInputProps.onKeyDown(e);
          }
          if (onEnter && e.key === 'Enter') {
            onEnter();
          }
        }}
        placeholder={disabled ? undefined : HTMLInputProps.placeholder}
        style={{
          width,
          height,
          backgroundColor,
        }}
        value={HTMLInputProps.value ?? ''}
      />
      {postfix}
    </div>
  );
});

BaseInput.displayName = 'BaseInput';

export default React.memo(BaseInput);
