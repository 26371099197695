import { useStores, useTitle } from '@hooks';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import ProjectsTable from '@components/CalendarTable/ProjectsList/ProjectsTable';
import type { SelectOption } from 'src/components/ui/Select/types';
import { useEffectOnce } from 'usehooks-ts';
import { periods } from '@services/utils';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { Select } from '@components/ui/Select';
import IconDescriptions from '@components/CalendarTable/IconDescriptions';
import ListHeader from '@components/ListHeader';

/** Реестр ресурсов */
const ProjectsLoad: React.FC = () => {
  const { projectsLoadStore } = useStores();
  useTitle('Распределение проектов');

  const selectCallback = useCallback(
    (val?: SelectOption) => {
      if (val) {
        runInAction(() => {
          projectsLoadStore.period = Number(val.value);
        });
      }
    },
    [projectsLoadStore],
  );

  useEffectOnce(() => {
    runInAction(() => {
      projectsLoadStore.startDate = dayjs.utc().startOf('day').toDate();
    });
  });

  return (
    <div className="w-full flex flex-col">
      <ListHeader
        buttons={(
          <>
            <div className="flex justify-end gap-2 items-center">
              <div>С даты:</div>
              <DatePicker
                clearable={false}
                height={30}
                onChange={(value) => {
                  runInAction(() => {
                    if (value) {
                      runInAction(() => {
                        projectsLoadStore.startDate = value?.utc().toDate();
                      });
                    }
                  });
                }}
                value={dayjs(projectsLoadStore.startDate)}
                utc
              />
            </div>
            <Select.Base
              clearable={false}
              height={30}
              onSelect={selectCallback}
              options={periods}
              selectedOption={periods.find((e) => e.value === String(projectsLoadStore.period))}
            />
          </>
        )}
        title="Распределение нагрузки по проектам"
      />
      <ProjectsTable
        className="flex-1"
        columnWidth={25}
        listCellWidth={300}
        rowHeight={25}
      />
      <IconDescriptions className="p-2 border-1" />
    </div>
  );
};

export default observer(ProjectsLoad);
