import {
DeleteContragentByIdRequest,
GetContragentByIdResponse,
GetContragentsResponse,
PostContragentRequest,
PostContragentResponse,
ProblemDetails,
ProblemDetailsResponse,
PutContragentByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class ContragentService {
  static get RoutePath(): string {
    return "Contragent";
  }

  /**
   * Получение списка заказчиков
   *
   * @request GET:/api/v1.0/Contragent
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      filterName?: string;
      isDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetContragentsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание заказчика
   *
   * @request POST:/api/v1.0/Contragent
   */
  static create = async (data: PostContragentRequest, params: RequestParams = {}) =>
    httpClient.request<PostContragentResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение контрагента по ид
   *
   * @request GET:/api/v1.0/Contragent/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetContragentByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление контрагента
   *
   * @request PUT:/api/v1.0/Contragent/{id}
   */
  static update = async (id: string, data: PutContragentByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удаление контрагента по ид
   *
   * @request DELETE:/api/v1.0/Contragent/{id}
   */
  static delete = async (id: string, data: DeleteContragentByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}