import type { GetLoadResourceInProjectsResponseItemLoadResourceInProject } from '@typesApi';
import { DayOfWeek } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  action,
  computed,
  makeObservable,
  runInAction,
} from 'mobx';
import { dateIntersection, required } from '@services/validationFunctions';
import { dayOfWeekEnumToNumber } from '@services/utils';
import { ErrorMessages } from 'src/enums';
import BaseViewModel from '../BaseViewModel';

export class PeriodViewModel extends BaseViewModel<GetLoadResourceInProjectsResponseItemLoadResourceInProject> {
  constructor(data: Partial<GetLoadResourceInProjectsResponseItemLoadResourceInProject>) {
    super(data);
    makeObservable(this);
  }

  /** Получить 'Ид недоступности ресурса' */
  @computed get id() {
    return this.data.loadResourceInProjectId ?? '';
  }

  /** Получить 'Наименование причины' */
  @computed
  @required()
  get workingHoursPerDay() {
    return this.data.workingHoursPerDay;
  }

  /** Изменить 'Наименование причины' */
  set workingHoursPerDay(value: number | undefined) {
    runInAction(() => {
      this.data.workingHoursPerDay = value;
    });
  }

  /** Получить 'Дата начала периода' */
  @computed
  @dateIntersection('startDate', 'finishDate', ErrorMessages.loadPeriod, true)
  get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : undefined;
  }

  /** Изменить 'Дата начала периода' */
  set startDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
    });
  }

  /** Получить 'Дата окончания периода' */
  @computed
  @dateIntersection('startDate', 'finishDate', ErrorMessages.loadPeriod, true)
  get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : undefined;
  }

  /** Изменить 'Дата окончания периода' */
  set finishDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
    });
  }

  @computed
  get workingDaysOfWeek() {
    return this.data.workingDaysOfWeek?.map(dayOfWeekEnumToNumber) ?? [];
  }

  set workingDaysOfWeek(value: number[]) {
    runInAction(() => {
      this.data.workingDaysOfWeek = value.map((e) => Object.values(DayOfWeek)[e]);
    });
  }

  @computed
  get isWorkOnWeekends() {
    return this.data.isWorkOnWeekends ?? false;
  }

  set isWorkOnWeekends(value: boolean) {
    runInAction(() => {
      this.data.isWorkOnWeekends = value;
    });
  }

  @action
  toggleDay(day: number) {
    this.workingDaysOfWeek = this.workingDaysOfWeek.includes(day)
      ? this.workingDaysOfWeek.filter((e) => e !== day)
      : [...this.workingDaysOfWeek, day];
  }
}
