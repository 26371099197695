import {
ProblemDetails,
ProblemDetailsResponse,
RefreshTokenRequest,
RegistrationRequest,
RegistrationResponse,
TokenRequest,
TokenResponse} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class AuthenticationService {
  static get RoutePath(): string {
    return "Authentication";
  }

  /**
   * Регистрация
   *
   * @request POST:/api/v1.0/Authentication/Registration
   */
  static registrationCreate = async (data: RegistrationRequest, params: RequestParams = {}) =>
    httpClient.request<RegistrationResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Registration`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получение токена
   *
   * @request POST:/api/v1.0/Authentication/Token
   */
  static tokenCreate = async (data: TokenRequest, params: RequestParams = {}) =>
    httpClient.request<TokenResponse, ProblemDetails | ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/Token`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Обновление токена
   *
   * @request POST:/api/v1.0/Authentication/RefreshToken
   */
  static refreshTokenCreate = async (data: RefreshTokenRequest, params: RequestParams = {}) =>
    httpClient.request<TokenResponse, ProblemDetails | ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/RefreshToken`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}