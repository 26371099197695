import {
GetSalaryPeriodByIdResponse,
GetSalaryPeriodsResponse,
PostSalaryPeriodRequest,
PostSalaryPeriodResponse,
ProblemDetails,
ProblemDetailsResponse,
PutSalaryPeriodRequest} from "./types.hr.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class SalaryPeriodService {
  static get RoutePath(): string {
    return "SalaryPeriod";
  }

  /**
   * Получить список сущностей по фильтру
   *
   * @request GET:/hr/v1.0/SalaryPeriod
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      employeeIds?: string[];
      minSalary?: number;
      maxSalary?: number;
      workingRate?: number;
      startDateFrom?: string;
      startDateTo?: string;
      finishDateFrom?: string;
      finishDateTo?: string;
      isDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetSalaryPeriodsResponse, ProblemDetails | ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание новой записи
   *
   * @request POST:/hr/v1.0/SalaryPeriod
   */
  static create = async (data: PostSalaryPeriodRequest, params: RequestParams = {}) =>
    httpClient.request<PostSalaryPeriodResponse, ProblemDetails | ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Получить сущность по идентификатору
   *
   * @request GET:/hr/v1.0/SalaryPeriod/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetSalaryPeriodByIdResponse, ProblemDetails | ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление записи
   *
   * @request PUT:/hr/v1.0/SalaryPeriod/{id}
   */
  static update = async (id: string, data: PutSalaryPeriodRequest, params: RequestParams = {}) =>
    httpClient.request<any, ProblemDetails | ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удалить сущность по идентификатору
   *
   * @request DELETE:/hr/v1.0/SalaryPeriod/{id}
   */
  static delete = async (id: string, params: RequestParams = {}) =>
    httpClient.request<any, ProblemDetails | ProblemDetailsResponse>({
      path: "hr/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      ...params,
    });
}