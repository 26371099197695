import type { UnavailablePeriod } from '@types';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { UnavailablePeriodStore } from 'src/stores/ProjectsLoad/UnavailablePeriodStore';
import Modal from '@components/Modal/Modal';
import Button from '@components/ui/Button';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import FormGrid from '@components/ui/Form/FormGrid';
import { Select } from '@components/ui/Select';

interface AnalyticsPopupProps {
  onClose: (val?: UnavailablePeriod) => void;
  onDelete?: () => void;
  startDate?: Dayjs;
  finishDate?: Dayjs;
  reasonName?: string | null;
  reasonId?: string | null;
}

const UnavailablePeriodModal: React.FC<AnalyticsPopupProps> = ({
  onClose, startDate, finishDate, reasonName, reasonId, onDelete,
}) => {
  const store = useMemo(() => new UnavailablePeriodStore({
    startDate: startDate?.toISOString(), finishDate: finishDate?.toISOString(), reasonName, reasonId,
  }), [finishDate, reasonName, reasonId, startDate]);

  const { viewModel } = store;

  return (
    <Modal onClose={onClose}>
      <div className="min-w-[680px] max-w[90vw] min-h-[30vh] max-h-[90vh] flex flex-col">
        <FormGrid maxColumnsNumber={2}>
          <DatePicker
            error={viewModel.errors.startDate}
            label="Дата начала"
            onChange={(val) => {
              viewModel.startDate = val;
            }}
            value={viewModel.startDate}
            utc
          />
          <DatePicker
            error={viewModel.errors.finishDate}
            label="Дата окончания"
            onChange={(val) => {
              viewModel.finishDate = val;
            }}
            value={viewModel.finishDate}
            utc
          />
        </FormGrid>
        <Select.Catalog
          catalogIdField="reasonId"
          catalogName="EmployeeUnavailableReasons"
          catalogNameField="reasonName"
          error={viewModel.errors.reasonId}
          label="Причина"
          viewModel={viewModel}
        />
        <div className="flex gap-5 justify-end mt-auto">
          {onDelete && (
            <Button.Primary
              className="mr-auto"
              label="Удалить"
              onClick={() => onDelete()}
              type="danger"
            />
          )}
          <Button.Primary
            label="Отменить"
            onClick={() => onClose()}
            type="outline"
          />
          <Button.Primary
            label="Сохранить"
            onClick={() => {
              if (viewModel.validate()) {
                onClose({
                  startDate: dayjs(viewModel.startDate!).utc(true),
                  finishDate: dayjs(viewModel.finishDate!).utc(true),
                  reasonId: viewModel.reasonId!,
                  reasonName: viewModel.reasonName!,
                });
              }
            }}
          />
        </div>
      </div>

    </Modal>
  );
};

export default observer(UnavailablePeriodModal);
