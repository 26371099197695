/**
 * Ответ на запрос на проверку статусов транзакций у организации
 */
export interface CheckTransactionsStatusesResponse {
  /** Транзакция завершена */
  isFinished?: boolean;

  /** Транзакция оплачена */
  isConfirmed?: boolean;

  /**
   * Идентификатор подписки транзакции
   * @format uuid
   */
  subscriptionId?: string;
}

export enum DayOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

/**
 * Запрос для удаления контрагента
 */
export interface DeleteContragentByIdRequest {
  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Удалить сейчас */
  deleteRightNow?: boolean;
}

/**
 * Запрос на удаление нагрузки запланированному на проект ресурсу
 */
export interface DeleteLoadResourceInProjectByIdRequest {
  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string;
}

/**
 * Запрос на удаление нагрузок ресурсов на проекте по ид нагрузок
 */
export interface DeleteLoadResourceInProjectsRequest {
  /** Список ид нагрузок на ресурс проекта */
  ids?: string[] | null;
}

/**
 * Запрос для удаления проекта
 */
export interface DeleteProjectByIdRequest {
  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Удалить сейчас */
  deleteRightNow?: boolean;
}

/**
 * Запрос для удаления ресурса
 */
export interface DeleteResourceByIdRequest {
  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Удалить сейчас */
  deleteRightNow?: boolean;
}

/**
 * Запрос на удаление недоступности ресурса
 */
export interface DeleteUnavailableReasonByResourceIdRequest {
  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string;
}

/**
* Действия с сущностями<ul>
<li><b>1 - ReadAuditLog</b>: Просмотр списка аудита</li>
<li><b>2 - ReadCatalogs</b>: Может просматривать справочники внутри организации</li>
<li><b>3 - CreateCatalogs</b>: Может создавать справочники внутри организации</li>
<li><b>4 - UpdateCatalogs</b>: Может изменять справочники</li>
<li><b>5 - DeleteCatalogs</b>: Проставление даты удаления справочникам</li>
<li><b>6 - ReadContragents</b>: Может просматривать контрагентов</li>
<li><b>7 - CreateContragents</b>: Может создавать контрагентов</li>
<li><b>8 - UpdateContragents</b>: Может изменять контрагентов</li>
<li><b>9 - DeleteContragents</b>: Может удалять контрагентов</li>
<li><b>10 - UpdateOrganization</b>: Может изменять данные организации</li>
<li><b>11 - ReadProjects</b>: Может просматривать проекты, ресурсы на проектах, нагрузку ресурсов на проекте</li>
<li><b>12 - CreateProjects</b>: Может создавать проекты</li>
<li><b>13 - UpdateProjects</b>: Может изменять проекты, добавлять ресурсы на проект, изменять ресурсы на проекте, добавлять проектные роли ресурсу на проекте, добавлять и удалять нагрузку на ресурс</li>
<li><b>14 - DeleteProjects</b>: Может проставлять дату удаления проекту, удалять ресурс с проекта</li>
<li><b>15 - ReadResources</b>: Может просматривать ресурсы</li>
<li><b>16 - CreateResources</b>: Может создавать ресурсы</li>
<li><b>17 - UpdateResources</b>: Может изменять ресурсы</li>
<li><b>18 - DeleteResources</b>: Может проставлять дату удаления ресурсам</li>
<li><b>19 - ReadRoles</b>: Может просматривать глобальные роли</li>
<li><b>20 - CreateRoles</b>: Может создавать глобальные роли</li>
<li><b>21 - UpdateRoles</b>: Может изменять глобальные роли</li>
<li><b>22 - DeleteRoles</b>: Может проставлять дату удаления глобальным ролям</li>
<li><b>23 - ReadProjectRoles</b>: Может просматривать проектные роли</li>
<li><b>24 - CreateProjectRoles</b>: Может создавать проектные роли</li>
<li><b>25 - UpdateProjectRoles</b>: Может изменять проектные роли</li>
<li><b>26 - DeleteProjectRoles</b>: Может проставлять дату удаления проектной роли</li>
<li><b>27 - UpdateAccounts</b>: Может обновлять аккаунты</li>
<li><b>28 - InviteEmployees</b>: Может приглашать сотрудников в организацию</li>
<li><b>29 - ReadPositionSettings</b>: Может просматривать настройки профессии</li>
<li><b>30 - CreatePositionSettings</b>: Может создавать настройки профессии</li>
<li><b>31 - UpdatePositionSettings</b>: Может изменять настройки профессии</li>
<li><b>32 - DeletePositionSettings</b>: Может удалять настройки профессии</li>
<li><b>33 - ReadSalaryPeriods</b>: Просмотр заработных периодов</li>
<li><b>34 - CreateSalaryPeriods</b>: Создание заработных периодов</li>
<li><b>35 - UpdateSalaryPeriods</b>: Обновление заработных периодов</li>
<li><b>36 - DeleteSalaryPeriods</b>: Удаление заработных периодов</li>
<li><b>37 - ReadCareerMeetings</b>: Просмотр карьерных встреч</li>
<li><b>38 - UpdateCareerMeetings</b>: Обновление карьерных встреч</li>
<li><b>39 - UpdateResponsible</b>: Создание, обновление, удаление ответственного сотрудника у ресурсов и проектов</li>
<li><b>40 - SetAccountState</b>: Установить состояние аккаунта</li>
<li><b>41 - ReadAccounts</b>: Может просматривать аккаунты</li>
<li><b>42 - ReadProjectRequirements</b>: Может просматривать потребности проекта</li>
<li><b>43 - CreateProjectRequirements</b>: Может создавать потребности проекта</li>
<li><b>44 - UpdateProjectRequirements</b>: Может изменять потребности проекта</li>
<li><b>45 - DeleteProjectRequirements</b>: Может удалять потребности проекта</li>
<li><b>46 - ImportEntities</b>: Импорт сущностей</li>
<li><b>47 - PayOrganizationSubscription</b>: Оплатить подписку организации</li>
</ul>
*/
export enum EntityActions {
  ReadAuditLog = "ReadAuditLog",
  ReadCatalogs = "ReadCatalogs",
  CreateCatalogs = "CreateCatalogs",
  UpdateCatalogs = "UpdateCatalogs",
  DeleteCatalogs = "DeleteCatalogs",
  ReadContragents = "ReadContragents",
  CreateContragents = "CreateContragents",
  UpdateContragents = "UpdateContragents",
  DeleteContragents = "DeleteContragents",
  UpdateOrganization = "UpdateOrganization",
  ReadProjects = "ReadProjects",
  CreateProjects = "CreateProjects",
  UpdateProjects = "UpdateProjects",
  DeleteProjects = "DeleteProjects",
  ReadResources = "ReadResources",
  CreateResources = "CreateResources",
  UpdateResources = "UpdateResources",
  DeleteResources = "DeleteResources",
  ReadRoles = "ReadRoles",
  CreateRoles = "CreateRoles",
  UpdateRoles = "UpdateRoles",
  DeleteRoles = "DeleteRoles",
  ReadProjectRoles = "ReadProjectRoles",
  CreateProjectRoles = "CreateProjectRoles",
  UpdateProjectRoles = "UpdateProjectRoles",
  DeleteProjectRoles = "DeleteProjectRoles",
  UpdateAccounts = "UpdateAccounts",
  InviteEmployees = "InviteEmployees",
  ReadPositionSettings = "ReadPositionSettings",
  CreatePositionSettings = "CreatePositionSettings",
  UpdatePositionSettings = "UpdatePositionSettings",
  DeletePositionSettings = "DeletePositionSettings",
  ReadSalaryPeriods = "ReadSalaryPeriods",
  CreateSalaryPeriods = "CreateSalaryPeriods",
  UpdateSalaryPeriods = "UpdateSalaryPeriods",
  DeleteSalaryPeriods = "DeleteSalaryPeriods",
  ReadCareerMeetings = "ReadCareerMeetings",
  UpdateCareerMeetings = "UpdateCareerMeetings",
  UpdateResponsible = "UpdateResponsible",
  SetAccountState = "SetAccountState",
  ReadAccounts = "ReadAccounts",
  ReadProjectRequirements = "ReadProjectRequirements",
  CreateProjectRequirements = "CreateProjectRequirements",
  UpdateProjectRequirements = "UpdateProjectRequirements",
  DeleteProjectRequirements = "DeleteProjectRequirements",
  ImportEntities = "ImportEntities",
  PayOrganizationSubscription = "PayOrganizationSubscription",
}

/**
* Тип события<ul>
<li><b>1 - StartProject</b>: Начало проекта</li>
<li><b>2 - FinishProject</b>: Окончание проекта</li>
<li><b>3 - UpdateProject</b>: Обновление проекта</li>
<li><b>4 - DeleteProject</b>: Удаление проекта</li>
<li><b>5 - UpdateResource</b>: Обновление ресурса</li>
<li><b>6 - DeleteResource</b>: Удаление ресурса</li>
<li><b>7 - UnavailableResource</b>: Выбытие ресурса</li>
<li><b>8 - StartResourceInProject</b>: Назначение ресурса на проекте</li>
<li><b>9 - FinishResourceInProject</b>: Окончание ресурса (удаление) с проекта</li>
<li><b>10 - StartLoadResourceInProject</b>: Начало планирования ресурса на проекте</li>
<li><b>11 - FinishLoadResourceInProject</b>: Окончание планирования ресурса на проекте</li>
<li><b>12 - StartWorkWeek</b>: Начало рабочей недели</li>
<li><b>13 - SubscriptionExpiration</b>: Окончание активной подписки</li>
</ul>
*/
export enum EventTypes {
  StartProject = "StartProject",
  FinishProject = "FinishProject",
  UpdateProject = "UpdateProject",
  DeleteProject = "DeleteProject",
  UpdateResource = "UpdateResource",
  DeleteResource = "DeleteResource",
  UnavailableResource = "UnavailableResource",
  StartResourceInProject = "StartResourceInProject",
  FinishResourceInProject = "FinishResourceInProject",
  StartLoadResourceInProject = "StartLoadResourceInProject",
  FinishLoadResourceInProject = "FinishLoadResourceInProject",
  StartWorkWeek = "StartWorkWeek",
  SubscriptionExpiration = "SubscriptionExpiration",
}

/**
 * Ответ на запрос импорта из csv-файла
 */
export interface FileImportResponse {
  /**
   * Загружено строк
   * @format int32
   */
  loadedRowsCount?: number;

  /**
   * Количество строк с ошибками
   * @format int32
   */
  rowsWithErrorCount?: number;

  /** Список ошибок */
  errors?: string[] | null;
}

/**
 * Ответ на запрос получения аккаунта по ид и его списка глобальных ролей
 */
export interface GetAccountByIdResponse {
  /** Фамилия */
  surname?: string | null;

  /** Имя */
  name?: string | null;

  /** Логин - email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /** Активен ли аккаунт */
  isActive?: boolean | null;

  /**
   * Ссылка на сотрудника
   * @format uuid
   */
  employeeId?: string;

  /** Глобальные роли сотрудника */
  employeeRoles?: GetAccountByIdResponseEmployeeRoleItem[] | null;
}

/**
 * Глобальная роль сотрудника для A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccountById.GetAccountByIdResponse
 */
export interface GetAccountByIdResponseEmployeeRoleItem {
  /**
   * Ид глобальной роли сотрудника
   * @format uuid
   */
  employeeRoleId?: string;

  /**
   * Ид глобальной роли
   * @format uuid
   */
  id?: string;

  /** Название глобальной роли */
  name?: string | null;

  /** Действия с сущностями */
  entityActions?: EntityActions[] | null;
}

/**
 * Ответ на запрос получения информации об аккаунте
 */
export interface GetAccountResponse {
  /** Фамилия */
  surname?: string | null;

  /** Имя */
  name?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /** Логин - email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /** Организация */
  organizationName?: string | null;

  /**
   * Аватарка
   * @format uuid
   */
  accountPictureId?: string | null;

  /**
   * Ссылка на сотрудника
   * @format uuid
   */
  employeeId?: string;

  /** Глобальные роли сотрудника */
  employeeRoles?: GetAccountResponseEmployeeRoleItem[] | null;

  /** Ресурс на проектах */
  resourceInProjects?: GetAccountResponseResourceInProject[] | null;

  /** Проекты за которые отвечает сотрудник */
  projects?: GetAccountResponseProject[] | null;

  /** Ресурсы за которые отвечает сотрудник */
  resources?: GetAccountResponseResource[] | null;
}

/**
 * Глобальная роль сотрудника для A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccount.GetAccountResponse
 */
export interface GetAccountResponseEmployeeRoleItem {
  /**
   * Ид глобальной роли сотрудника
   * @format uuid
   */
  employeeRoleId?: string;

  /**
   * Ид глобальной роли
   * @format uuid
   */
  id?: string;

  /** Название глобальной роли */
  name?: string | null;

  /** Действия с сущностями */
  entityActions?: EntityActions[] | null;
}

/**
 * Проекты за которые отвечает сотрудник A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccount.GetAccountResponse
 */
export interface GetAccountResponseProject {
  /**
   * Ид проекта
   * @format uuid
   */
  id?: string;

  /** Наименование проекта */
  name?: string | null;
}

/**
 * Ресурсы за которые отвечает сотрудник A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccount.GetAccountResponse
 */
export interface GetAccountResponseResource {
  /**
   * Ид ресурса
   * @format uuid
   */
  id?: string;

  /** Наименование ресурса */
  name?: string | null;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;
}

/**
 * Ресурс на проекте для A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccount.GetAccountResponse
 */
export interface GetAccountResponseResourceInProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  projectName?: string | null;

  /**
   * Ресурс на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /** Проектные роли ресурса на проекте */
  projectRoles?: GetAccountResponseResourceInProjectRoles[] | null;
}

/**
 * Проектные роли ресурса на проекте для A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccount.GetAccountResponse
 */
export interface GetAccountResponseResourceInProjectRoles {
  /**
   * Ид проектной роли
   * @format uuid
   */
  id?: string;

  /** Название проектной роли */
  name?: string | null;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccounts.GetAccountsRequest
 */
export interface GetAccountsResponse {
  /** Список сущностей */
  entities?: GetAccountsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Глобальная роль для A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccounts.GetAccountsResponse
 */
export interface GetAccountsResponseEmployeeRoleItem {
  /**
   * Ид глобальной роли сотрудника
   * @format uuid
   */
  employeeRoleId?: string;

  /**
   * Ид глобальной роли
   * @format uuid
   */
  id?: string;

  /** Название глобальной роли */
  name?: string | null;

  /** Действия с сущностями */
  entityActions?: EntityActions[] | null;
}

/**
 * Пользователь для A2Soft.A2Planner.Api.Contracts.Requests.AccountRequests.GetAccounts.GetAccountsResponse
 */
export interface GetAccountsResponseItem {
  /** Фамилия */
  surname?: string | null;

  /** Имя */
  name?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /** ФИО */
  fullName?: string | null;

  /** Логин - email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /** Активен ли пользователь */
  isActive?: boolean | null;

  /**
   * Ссылка на сотрудника
   * @format uuid
   */
  employeeId?: string | null;

  /** Глобальные роли сотрудника */
  employeeRoles?: GetAccountsResponseEmployeeRoleItem[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.AuditLogRequests.GetAuditLog.GetAuditLogRequest
 */
export interface GetAuditLogResponse {
  /** Список сущностей */
  entities?: GetAuditLogResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Справочик для A2Soft.A2Planner.Api.Contracts.Requests.AuditLogRequests.GetAuditLog.GetAuditLogResponse
 */
export interface GetAuditLogResponseItem {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Идентификатор изменяемой сущности */
  tablePk?: string | null;

  /**
   * Дата создания сущности
   * @format date-time
   */
  createdOn?: string;

  /** Наименование сущности */
  entityType?: string | null;

  /** Действие */
  action?: string | null;

  /**
   * Идентификатор сотрудника
   * @format uuid
   */
  employeeId?: string | null;

  /**
   * Идентификатор пользователя в идентити
   * @format uuid
   */
  userId?: string;

  /** ФИО сотрудника */
  employeeName?: string | null;

  /** Изменения */
  data?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.CatalogRequests.GetCatalogs.GetCatalogsRequest
 */
export interface GetCatalogsResponse {
  /** Список сущностей */
  entities?: GetCatalogsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Справочик для A2Soft.A2Planner.Api.Contracts.Requests.CatalogRequests.GetCatalogs.GetCatalogsResponse
 */
export interface GetCatalogsResponseItem {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Код */
  code?: string | null;

  /** Имя */
  name?: string | null;

  /**
   * Организация
   * @format uuid
   */
  organizationId?: string | null;

  /** Описание */
  description?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Значения справочника */
  catalogValues?: GetCatalogsResponseItemCatalogValue[] | null;
}

/**
 * Значения справочника для A2Soft.A2Planner.Api.Contracts.Requests.CatalogRequests.GetCatalogs.GetCatalogsResponse
 */
export interface GetCatalogsResponseItemCatalogValue {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Код */
  code?: string | null;

  /** Имя */
  name?: string | null;

  /** Описание */
  description?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;
}

/**
 * Ответ на запрос поиск контрагента по ид
 */
export interface GetContragentByIdResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Имя */
  name?: string | null;

  /** ФИО ответственного */
  responsibleName?: string | null;

  /** Комментарий */
  comment?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Архивная сущность */
  isDeleted?: boolean;

  /** Проекты */
  projects?: GetContragentByIdResponseProject[] | null;
}

/**
 * Проекта для A2Soft.A2Planner.Api.Contracts.Requests.ContragentRequests.GetContragentById.GetContragentByIdResponse
 */
export interface GetContragentByIdResponseProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  projectName?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ContragentRequests.GetContragents.GetContragentsRequest
 */
export interface GetContragentsResponse {
  /** Список сущностей */
  entities?: GetContragentsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Заказчик для A2Soft.A2Planner.Api.Contracts.Requests.ContragentRequests.GetContragents.GetContragentsResponse
 */
export interface GetContragentsResponseItem {
  /**
   * ИД сущности
   * @format uuid
   */
  id?: string;

  /** Название */
  name?: string | null;

  /** ФИО ответственного */
  responsibleName?: string | null;

  /** Комментарий */
  comment?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;
}

/**
 * Ответ на запрос поиск сотрудника по ид
 */
export interface GetEmployeeByIdResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** ФИО */
  fullName?: string | null;

  /** Фамилия */
  surname?: string | null;

  /** Имя */
  name?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;

  /** Фамилия ответственного */
  responsibleSurname?: string | null;

  /** Имя ответственного */
  responsibleName?: string | null;

  /** Отчество ответственного */
  responsiblePatronymic?: string | null;

  /**
   * Актуальная ставка в час
   * @format double
   */
  actualWorkingRate?: number | null;

  /**
   * Актуальный оклад
   * @format double
   */
  actualSalary?: number | null;

  /**
   * Ближайшая карьерная встреча
   * @format date-time
   */
  nextCareerMeeting?: string | null;

  /**
   * Дата рождения
   * @format date-time
   */
  birthdate?: string | null;

  /** Дополнительные компетенции сотрудника */
  additionalCompetences?: GetEmployeeByIdResponseAdditionalCompetence[] | null;

  /** Удаленная работа */
  isRemoteWork?: boolean | null;

  /**
   * Наставник
   * @format uuid
   */
  mentorId?: string | null;

  /** Наставник ФИО */
  mentorName?: string | null;

  /**
   * Основная специализация
   * @format uuid
   */
  mainSpecializationId?: string | null;

  /** Наименование основной специализации */
  mainSpecializationName?: string | null;

  /**
   * Основная квалификация
   * @format uuid
   */
  mainQualificationId?: string | null;

  /** Наименование основной квалификации */
  mainQualificationName?: string | null;

  /**
   * Должность по трудовому договору
   * @format uuid
   */
  positionId?: string | null;

  /** Наименование должности по трудовому договору */
  positionName?: string | null;

  /** Email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Архивная сущность */
  isDeleted?: boolean;

  /** Ресурс на проектах */
  resourceInProjects?: GetEmployeeByIdResponseResourceInProject[] | null;
}

/**
 * Дополнительные компетенции сотрудника для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployeeById.GetEmployeeByIdResponse
 */
export interface GetEmployeeByIdResponseAdditionalCompetence {
  /**
   * Дополнительные компетенции сотрудника
   * @format uuid
   */
  additionalCompetenceId?: string | null;

  /**
   * Специализация
   * @format uuid
   */
  specializationId?: string | null;

  /** Наименование специализации */
  specializationName?: string | null;

  /**
   * Квалификация
   * @format uuid
   */
  qualificationId?: string | null;

  /** Наименование квалификации */
  qualificationName?: string | null;
}

/**
 * Ресурс на проекте для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployeeById.GetEmployeeByIdResponse
 */
export interface GetEmployeeByIdResponseResourceInProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  projectName?: string | null;

  /**
   * Ресурс на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /**
   * Ставка на проекте (р/ч)
   * @format int32
   */
  workingRateInProject?: number | null;

  /** Проектные роли ресурса на проекте */
  projectRoles?: GetEmployeeByIdResponseResourceInProjectRoles[] | null;
}

/**
 * Проектные роли ресурса на проекте для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployeeById.GetEmployeeByIdResponse
 */
export interface GetEmployeeByIdResponseResourceInProjectRoles {
  /**
   * Ид проектной роли
   * @format uuid
   */
  projectRoleId?: string;

  /** Название проектной роли */
  projectRoleName?: string | null;

  /** Код проектной роли */
  projectRoleCode?: string | null;

  /** Описание */
  description?: string | null;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployeeNames.GetEmployeeNamesRequest
 */
export interface GetEmployeeNamesResponse {
  /** Список сущностей */
  entities?: GetEmployeeNamesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Элемент ответа A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployeeNames.GetEmployeeNamesResponse
 */
export interface GetEmployeeNamesResponseItem {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Фамилия */
  surname?: string | null;

  /** Имя */
  personName?: string | null;

  /** Отчество */
  patronymic?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployees.GetEmployeesRequest
 */
export interface GetEmployeesResponse {
  /** Список сущностей */
  entities?: GetEmployeesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Сотрудник для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployees.GetEmployeesResponse
 */
export interface GetEmployeesResponseItem {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** ФИО */
  fullName?: string | null;

  /** Фамилия */
  surname?: string | null;

  /** Имя */
  name?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;

  /** ФИО ответственного */
  responsibleFullName?: string | null;

  /** Фамилия ответственного */
  responsibleSurname?: string | null;

  /** Имя ответственного */
  responsibleName?: string | null;

  /** Отчество ответственного */
  responsiblePatronymic?: string | null;

  /**
   * Дата рождения
   * @format date-time
   */
  birthdate?: string | null;

  /**
   * Актуальная ставка в час
   * @format double
   */
  actualWorkingRate?: number | null;

  /**
   * Актуальный оклад
   * @format double
   */
  actualSalary?: number | null;

  /**
   * Ближайшая карьерная встреча
   * @format date-time
   */
  nextCareerMeeting?: string | null;

  /** Дополнительные компетенции сотрудника */
  additionalCompetences?: GetEmployeesResponseItemAdditionalCompetence[] | null;

  /** Удаленная работа */
  isRemoteWork?: boolean | null;

  /**
   * Наставник
   * @format uuid
   */
  mentorId?: string | null;

  /** Наставник ФИО */
  mentorName?: string | null;

  /**
   * Основная специализация
   * @format uuid
   */
  mainSpecializationId?: string | null;

  /** Наименование основной специализации */
  mainSpecializationName?: string | null;

  /**
   * Основная квалификация
   * @format uuid
   */
  mainQualificationId?: string | null;

  /** Наименование основной квалификации */
  mainQualificationName?: string | null;

  /**
   * Должность по трудовому договору
   * @format uuid
   */
  positionId?: string | null;

  /** Наименование должности по трудовому договору */
  positionName?: string | null;

  /** Email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Ресурс на проектах */
  resourceInProjects?: GetEmployeesResponseItemResourceInProject[] | null;
}

/**
 * Дополнительные компетенции сотрудника для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployees.GetEmployeesResponse
 */
export interface GetEmployeesResponseItemAdditionalCompetence {
  /**
   * Дополнительные компетенции сотрудника
   * @format uuid
   */
  additionalCompetenceId?: string | null;

  /**
   * Специализация
   * @format uuid
   */
  specializationId?: string | null;

  /** Наименование специализации */
  specializationName?: string | null;

  /**
   * Квалификация
   * @format uuid
   */
  qualificationId?: string | null;

  /** Наименование квалификации */
  qualificationName?: string | null;
}

/**
 * Проектные роли ресурса на проектах для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployees.GetEmployeesResponse
 */
export interface GetEmployeesResponseItemProjectRoles {
  /**
   * Ид проектной роли
   * @format uuid
   */
  projectRoleId?: string;

  /** Название проектной роли */
  projectRoleName?: string | null;

  /** Код проектной роли */
  projectRoleCode?: string | null;

  /** Описание */
  description?: string | null;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;
}

/**
 * Ресурс на проектах для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.GetEmployees.GetEmployeesResponse
 */
export interface GetEmployeesResponseItemResourceInProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  projectName?: string | null;

  /**
   * Ресурс на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /**
   * Ставка на проекте (р/ч)
   * @format int32
   */
  workingRateInProject?: number | null;

  /** Проектные роли ресурса на проекте */
  projectRoles?: GetEmployeesResponseItemProjectRoles[] | null;
}

/**
 * Ответ на запрос поиск техники по ид
 */
export interface GetEquipmentByIdResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Наименование */
  name?: string | null;

  /**
   * Тип
   * @format uuid
   */
  typeId?: string | null;

  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;

  /** Фамилия ответственного */
  responsibleSurname?: string | null;

  /** Имя ответственного */
  responsibleName?: string | null;

  /** Отчество ответственного */
  responsiblePatronymic?: string | null;

  /** Наименование типа */
  typeName?: string | null;

  /** Инвентарный номер */
  inventoryNumber?: string | null;

  /** Параметры */
  parameters?: string | null;

  /**
   * Ид помещения
   * @format uuid
   */
  placeId?: string | null;

  /** Наименование помещения */
  placeName?: string | null;

  /**
   * Ид сотрудника
   * @format uuid
   */
  employeeId?: string | null;

  /** ФИО сотрудника */
  employeeName?: string | null;

  /**
   * Дата начала использования
   * @format date-time
   */
  useStartDate?: string | null;

  /**
   * Дата окончания срока службы
   * @format date-time
   */
  endOfLifeDate?: string | null;

  /**
   * Последнее ТО
   * @format date-time
   */
  lastTechnicalInspection?: string | null;

  /**
   * Следующее ТО
   * @format date-time
   */
  nextTechnicalInspection?: string | null;

  /**
   * Начальная стоимость
   * @format double
   */
  initialCost?: number | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Архивная сущность */
  isDeleted?: boolean;

  /** Проекты */
  projects?: GetEquipmentByIdResponseProject[] | null;
}

/**
 * Проекта для A2Soft.A2Planner.Api.Contracts.Requests.EquipmentRequests.GetEquipmentById.GetEquipmentByIdResponse
 */
export interface GetEquipmentByIdResponseProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  projectName?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.EquipmentRequests.GetEquipments.GetEquipmentsRequest
 */
export interface GetEquipmentsResponse {
  /** Список сущностей */
  entities?: GetEquipmentsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Техника для A2Soft.A2Planner.Api.Contracts.Requests.EquipmentRequests.GetEquipments.GetEquipmentsResponse
 */
export interface GetEquipmentsResponseItem {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Наименование */
  name?: string | null;

  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;

  /** ФИО ответственного */
  responsibleFullName?: string | null;

  /** Фамилия ответственного */
  responsibleSurname?: string | null;

  /** Имя ответственного */
  responsibleName?: string | null;

  /** Отчество ответственного */
  responsiblePatronymic?: string | null;

  /**
   * Тип
   * @format uuid
   */
  typeId?: string | null;

  /** Наименование типа */
  typeName?: string | null;

  /** Инвентарный номер */
  inventoryNumber?: string | null;

  /** Параметры */
  parameters?: string | null;

  /**
   * Помещение
   * @format uuid
   */
  placeId?: string | null;

  /** Наименование помещения */
  placeName?: string | null;

  /**
   * Сотрудник
   * @format uuid
   */
  employeeId?: string | null;

  /** ФИО сотрудника */
  employeeName?: string | null;

  /**
   * Дата начала использования
   * @format date-time
   */
  useStartDate?: string | null;

  /**
   * Дата окончания срока службы
   * @format date-time
   */
  endOfLifeDate?: string | null;

  /**
   * Последнее ТО
   * @format date-time
   */
  lastTechnicalInspection?: string | null;

  /**
   * Следующее ТО
   * @format date-time
   */
  nextTechnicalInspection?: string | null;

  /**
   * Начальная стоимость
   * @format double
   */
  initialCost?: number | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Проекты */
  projects?: GetEquipmentsResponseItemProject[] | null;
}

/**
 * Проекта для A2Soft.A2Planner.Api.Contracts.Requests.EquipmentRequests.GetEquipments.GetEquipmentsResponse
 */
export interface GetEquipmentsResponseItemProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  projectName?: string | null;
}

/**
 * Транзакция на оплату
 */
export interface GetLastSubscriptionPaymentTransactionResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /**
   * Сумма к оплате
   * @format double
   */
  amount?: number;

  /** Статус транзакции */
  transactionStatus?: string | null;

  /** Описание */
  description?: string | null;

  /** Флаг успешного завершения */
  successfulFlag?: boolean | null;
  duration?: TimeSpan;

  /**
   * Дата оплаты
   * @format date-time
   */
  paymentDate?: string | null;
}

/**
 * Ответ на запрос на получение последней подписки организации
 */
export interface GetLastSubscriptionResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /**
   * ИД организации
   * @format uuid
   */
  organizationId?: string | null;

  /**
   * Использованный лимит на аккаунтов
   * @format int32
   */
  accountsCount?: number;

  /**
   * Использованный лимит на проекты
   * @format int32
   */
  projectsCount?: number;

  /**
   * Использованный лимит на ресурсы
   * @format int32
   */
  resourcesCount?: number;

  /**
   * Дата С
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата ПО
   * @format date-time
   */
  finishDate?: string | null;

  /** Подписка актуальна */
  isActual?: boolean;

  /** Тип подписки */
  subscriptionType?: GetLastSubscriptionSubscriptionTypeResponse;

  /** Транзакции на оплату */
  paymentTransactions?: GetLastSubscriptionPaymentTransactionResponse[] | null;
}

/**
 * Тип подписки
 */
export interface GetLastSubscriptionSubscriptionTypeResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Наименование */
  name?: string | null;

  /** Признак актуальности */
  isActual?: boolean;

  /** Описание */
  description?: string | null;

  /**
   * Количество аккаунтов
   * @format int32
   */
  accounts?: number | null;

  /**
   * Количество проектов
   * @format int32
   */
  projects?: number | null;

  /**
   * Количество ресурсов
   * @format int32
   */
  resources?: number | null;

  /** Продляемая */
  isRenewable?: boolean;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjectsByProjectId.GetLoadResourceInProjectsByProjectIdRequest
 */
export interface GetLoadResourceInProjectsByProjectIdResponse {
  /** Список сущностей */
  entities?: GetLoadResourceInProjectsByProjectIdResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Ресурс в запросе A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjectsByProjectId.GetLoadResourceInProjectsByProjectIdResponse
 */
export interface GetLoadResourceInProjectsByProjectIdResponseItem {
  /**
   * Ид ресурса на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /**
   * Ид ресурса
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  type?: ResourceTypes;

  /** Название ресурса */
  name?: string | null;

  /** Список ресурсов проектов */
  projects?: GetLoadResourceInProjectsByProjectIdResponseItemProject[] | null;

  /** Список недоступности ресурсов */
  unavailableResources?: GetLoadResourceInProjectsByProjectIdResponseItemUnavailableResource[] | null;
}

/**
 * Нагрузка ресурса на проекте A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjectsByProjectId.GetLoadResourceInProjectsByProjectIdResponse
 */
export interface GetLoadResourceInProjectsByProjectIdResponseItemLoadResourceInProject {
  /**
   * Ид нагрузки ресурса
   * @format uuid
   */
  loadResourceInProjectId?: string;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Количество рабочих часов в день
   * @format int32
   */
  workingHoursPerDay?: number;

  /** Работает ли сотрудник в выходные */
  isWorkOnWeekends?: boolean;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;
}

/**
 * Проект для ресурса A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjectsByProjectId.GetLoadResourceInProjectsByProjectIdResponse
 */
export interface GetLoadResourceInProjectsByProjectIdResponseItemProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /**
   * Ресурс
   * @format uuid
   */
  resourceInProjectId?: string;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;

  /** Нагрузка ресурсов на проекте */
  loadResourceInProjects?: GetLoadResourceInProjectsByProjectIdResponseItemLoadResourceInProject[] | null;
}

/**
 * Недоступность ресурса для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjectsByProjectId.GetLoadResourceInProjectsByProjectIdResponse
 */
export interface GetLoadResourceInProjectsByProjectIdResponseItemUnavailableResource {
  /**
   * Ид недоступности ресурса
   * @format uuid
   */
  id?: string;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Причина
   * @format uuid
   */
  reasonId?: string | null;

  /** Наименование причины */
  reasonName?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetLoadResourceInProjectsByResourceId.GetLoadResourceInProjectsByResourceIdRequest
 */
export interface GetLoadResourceInProjectsByResourceIdResponse {
  /** Список сущностей */
  entities?: GetLoadResourceInProjectsByResourceIdResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Проект в запросе A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjectsByProjectId.GetLoadResourceInProjectsByProjectIdResponse
 */
export interface GetLoadResourceInProjectsByResourceIdResponseItem {
  /**
   * Ид ресурса на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  name?: string | null;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;

  /** Нагрузка ресурсов на проекте */
  loadResourceInProjects?: GetLoadResourceInProjectsByResourceIdResponseLoadResourceInProject[] | null;
}

/**
 * Нагрузка ресурса на проекте A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetLoadResourceInProjectsByResourceId.GetLoadResourceInProjectsByResourceIdResponse
 */
export interface GetLoadResourceInProjectsByResourceIdResponseLoadResourceInProject {
  /**
   * Ид нагрузки ресурса
   * @format uuid
   */
  loadResourceInProjectId?: string;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Количество рабочих часов в день
   * @format int32
   */
  workingHoursPerDay?: number;

  /** Работает ли сотрудник в выходные */
  isWorkOnWeekends?: boolean;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjects.GetLoadResourceInProjectsRequest
 */
export interface GetLoadResourceInProjectsResponse {
  /** Список сущностей */
  entities?: GetLoadResourceInProjectsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Проект для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjects.GetLoadResourceInProjectsResponse
 */
export interface GetLoadResourceInProjectsResponseItem {
  /**
   * ИД сущности
   * @format uuid
   */
  id?: string;

  /** Название */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /**
   * Статусы проекта<ul>
   * <li><b>1 - Active</b>: Активный</li>
   * <li><b>2 - Inactive</b>: Неактивный</li>
   * </ul>
   *
   */
  status?: ProjectStatuses;

  /** Список ресурсов проекта */
  resourcesInProject?: GetLoadResourceInProjectsResponseItemResourcesInProject[] | null;
}

/**
 * Нагрузка ресурса на проекте A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjects.GetLoadResourceInProjectsResponse
 */
export interface GetLoadResourceInProjectsResponseItemLoadResourceInProject {
  /**
   * Ид нагрузки ресурса
   * @format uuid
   */
  loadResourceInProjectId?: string;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Количество рабочих часов в день
   * @format int32
   */
  workingHoursPerDay?: number;

  /** Работает ли сотрудник в выходные */
  isWorkOnWeekends?: boolean;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;
}

/**
 * Проект для ресурса A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjects.GetLoadResourceInProjectsResponse
 */
export interface GetLoadResourceInProjectsResponseItemProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /**
   * Ресурс
   * @format uuid
   */
  resourceInProjectId?: string;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;

  /** Нагрузка ресурсов на проекте */
  loadResourceInProjects?: GetLoadResourceInProjectsResponseItemLoadResourceInProject[] | null;
}

/**
 * Ресурс в запросе A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjects.GetLoadResourceInProjectsResponse
 */
export interface GetLoadResourceInProjectsResponseItemResourcesInProject {
  /**
   * Ид ресурса на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /**
   * Ид ресурса
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  type?: ResourceTypes;

  /** Название ресурса */
  name?: string | null;

  /** Список ресурсов проектов */
  projects?: GetLoadResourceInProjectsResponseItemProject[] | null;

  /** Список недоступности ресурсов */
  unavailableResources?: GetLoadResourceInProjectsResponseItemUnavailableResource[] | null;
}

/**
 * Недоступность ресурса для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetLoadResourceInProjects.GetLoadResourceInProjectsResponse
 */
export interface GetLoadResourceInProjectsResponseItemUnavailableResource {
  /**
   * Ид недоступности ресурса
   * @format uuid
   */
  id?: string;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Причина
   * @format uuid
   */
  reasonId?: string | null;

  /** Наименование причины */
  reasonName?: string | null;
}

/**
 * Ответ на запрос получение организации по ид
 */
export interface GetOrganizationByIdResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /**
   * Отрасль
   * @format uuid
   */
  industryId?: string | null;

  /** Наименование отрасли */
  industryName?: string | null;

  /** Название организации */
  name?: string | null;

  /** ИНН */
  inn?: string | null;

  /**
   * Количество аккаунтов
   * @format int32
   */
  numberOfAccounts?: number;

  /**
   * Идентификатор актуальной подписки
   * @format uuid
   */
  actualSubscriptionId?: string | null;

  /**
   * Дата С
   * @format date-time
   */
  subscriptionStartDate?: string | null;

  /**
   * Дата ПО
   * @format date-time
   */
  subscriptionFinishDate?: string | null;

  /**
   * Идентификатор актуальной подписки
   * @format uuid
   */
  subscriptionTypeId?: string | null;

  /** Наименование актуальной подписки */
  subscriptionTypeName?: string | null;

  /**
   * Ид логотипа
   * @format uuid
   */
  logoId?: string | null;
}

/**
 * Ответ на запрос получение транзакции по ид
 */
export interface GetPaymentTransactionByIdResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /**
   * ИД организации
   * @format uuid
   */
  organizationId?: string | null;

  /** Наименование организации */
  organizationName?: string | null;

  /** Наименование типа подписки */
  subscriptionTypeName?: string | null;

  /**
   * ИД подписки
   * @format uuid
   */
  subscriptionId?: string | null;

  /**
   * Сумма к оплате
   * @format double
   */
  amount?: number;

  /** Статус транзакции */
  transactionStatus?: string | null;

  /** Описание */
  description?: string | null;

  /** Флаг успешного завершения */
  successfulFlag?: boolean | null;
}

/**
 * Ответ на запрос поиск помещения по ид
 */
export interface GetPlaceByIdResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Наименование */
  name?: string | null;

  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;

  /** Фамилия ответственного */
  responsibleSurname?: string | null;

  /** Имя ответственного */
  responsibleName?: string | null;

  /** Отчество ответственного */
  responsiblePatronymic?: string | null;

  /** Назначение */
  appointment?: string | null;

  /** Адрес */
  address?: string | null;

  /**
   * Общая вместимость
   * @format int32
   */
  totalCapacity?: number | null;

  /**
   * Количество сидячих мест
   * @format int32
   */
  numberOfSeats?: number | null;

  /**
   * Площадь
   * @format double
   */
  square?: number | null;

  /**
   * Последняя уборка
   * @format date-time
   */
  lastCleaning?: string | null;

  /**
   * Следующая уборка
   * @format date-time
   */
  nextCleaning?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Архивная сущность */
  isDeleted?: boolean;

  /** Проекты */
  projects?: GetPlaceByIdResponseProject[] | null;
}

/**
 * Проекта для A2Soft.A2Planner.Api.Contracts.Requests.PlaceRequests.GetPlaceById.GetPlaceByIdResponse
 */
export interface GetPlaceByIdResponseProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  projectName?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.PlaceRequests.GetPlaces.GetPlacesRequest
 */
export interface GetPlacesResponse {
  /** Список сущностей */
  entities?: GetPlacesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Техника для A2Soft.A2Planner.Api.Contracts.Requests.PlaceRequests.GetPlaces.GetPlacesResponse
 */
export interface GetPlacesResponseItem {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Наименование */
  name?: string | null;

  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;

  /** ФИО ответственного */
  responsibleFullName?: string | null;

  /** Фамилия ответственного */
  responsibleSurname?: string | null;

  /** Имя ответственного */
  responsibleName?: string | null;

  /** Отчество ответственного */
  responsiblePatronymic?: string | null;

  /** Назначение */
  appointment?: string | null;

  /** Адрес */
  address?: string | null;

  /**
   * Общая вместимость
   * @format int32
   */
  totalCapacity?: number | null;

  /**
   * Количество сидячих мест
   * @format int32
   */
  numberOfSeats?: number | null;

  /**
   * Площадь
   * @format double
   */
  square?: number | null;

  /**
   * Последняя уборка
   * @format date-time
   */
  lastCleaning?: string | null;

  /**
   * Следующая уборка
   * @format date-time
   */
  nextCleaning?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Проекты */
  projects?: GetPlacesResponseItemProject[] | null;
}

/**
 * Проекта для A2Soft.A2Planner.Api.Contracts.Requests.PlaceRequests.GetPlaces.GetPlacesResponse
 */
export interface GetPlacesResponseItemProject {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Название проекта */
  projectName?: string | null;
}

/**
 * Ответ на запрос поиск проекта по ид
 */
export interface GetProjectByIdResponse {
  /**
   * ИД сущности
   * @format uuid
   */
  id?: string;

  /** Название */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;

  /** Фамилия ответственного */
  responsibleSurname?: string | null;

  /** Имя ответственного */
  responsibleName?: string | null;

  /** Отчество ответственного */
  responsiblePatronymic?: string | null;

  /**
   * Контрагент
   * @format uuid
   */
  contragentId?: string | null;

  /** Название контрагента */
  contragentName?: string | null;

  /** План по часам */
  hourlyPlan?: string | null;

  /** План по стоимости */
  costPlan?: string | null;

  /** Комментарий */
  comment?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Архивная сущность */
  isDeleted?: boolean;

  /**
   * Статусы проекта<ul>
   * <li><b>1 - Active</b>: Активный</li>
   * <li><b>2 - Inactive</b>: Неактивный</li>
   * </ul>
   *
   */
  status?: ProjectStatuses;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetProjectEmployees.GetProjectEmployeesRequest
 */
export interface GetProjectEmployeesResponse {
  /** Список сущностей */
  entities?: GetProjectEmployeesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Проект для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetProjectEmployees.GetProjectEmployeesResponse
 */
export interface GetProjectEmployeesResponseItem {
  /**
   * ИД сущности
   * @format uuid
   */
  id?: string;

  /** Фамилия */
  surname?: string | null;

  /** Имя */
  personName?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /**
   * Ид основной специализации
   * @format uuid
   */
  mainSpecializationId?: string | null;

  /** Основная специализация */
  mainSpecializationName?: string | null;

  /**
   * Ид основной квалификации
   * @format uuid
   */
  mainQualificationId?: string | null;

  /** Основная квалификация */
  mainQualificationName?: string | null;

  /**
   * Ид должности по трудовому договору
   * @format uuid
   */
  positionId?: string | null;

  /** Должность по трудовому договору */
  positionName?: string | null;

  /** Email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /**
   * Id ресурса на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /** Проектные роли */
  projectRoles?: GetProjectEmployeesResponseProjectRoles[] | null;
}

/**
 * Проектные роли в запросе A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetProjectEmployees.GetProjectEmployeesResponseItem
 */
export interface GetProjectEmployeesResponseProjectRoles {
  /**
   * Ид проектной роли
   * @format uuid
   */
  id?: string;

  /** Название */
  name?: string | null;

  /** Код */
  code?: string | null;

  /** Описание */
  description?: string | null;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;

  /** Типы событий в уведомлениях для этой проектной роли */
  eventTypes?: EventTypes[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetProjectNames.GetProjectNamesRequest
 */
export interface GetProjectNamesResponse {
  /** Список сущностей */
  entities?: GetProjectNamesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Проект для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetProjectNames.GetProjectNamesResponse
 */
export interface GetProjectNamesResponseItem {
  /**
   * ИД сущности
   * @format uuid
   */
  id?: string;

  /** Название */
  name?: string | null;
}

/**
 * Ответ на запрос поиск потребности проекта по ид
 */
export interface GetProjectRequirementByIdResponse {
  /**
   * Ид потребности проекта
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название потребности */
  name?: string | null;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;
}

/**
 * Ответ на запрос потребности проекта в сотруднике по ид
 */
export interface GetProjectRequirementEmployeeByIdResponse {
  /**
   * Ид потребности проекта в сотруднике
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название потребности проекта в сотруднике */
  name?: string | null;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /**
   * Идентификатор должности
   * @format uuid
   */
  positionId?: string | null;

  /** Наименование должности */
  positionName?: string | null;

  /**
   * Идентификатор специализации
   * @format uuid
   */
  specializationId?: string | null;

  /** Наименование специализации */
  specializationName?: string | null;

  /**
   * Идентификатор квалификации
   * @format uuid
   */
  qualificationId?: string | null;

  /** Наименование квалификации */
  qualificationName?: string | null;

  /**
   * Максимальная ставка в час
   * @format double
   */
  maxWorkingRate?: number | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementEmployeeRequests.GetProjectRequirementEmployees.GetProjectRequirementEmployeesRequest
 */
export interface GetProjectRequirementEmployeesResponse {
  /** Список сущностей */
  entities?: GetProjectRequirementEmployeesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Элемент ответа на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementEmployeeRequests.GetProjectRequirementEmployees.GetProjectRequirementEmployeesRequest
 */
export interface GetProjectRequirementEmployeesResponseItem {
  /**
   * Ид потребности проекта в сотруднике
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название потребности в сотруднике */
  name?: string | null;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /**
   * Идентификатор должности
   * @format uuid
   */
  positionId?: string | null;

  /** Наименование должности */
  positionName?: string | null;

  /**
   * Идентификатор специализации
   * @format uuid
   */
  specializationId?: string | null;

  /** Наименование специализации */
  specializationName?: string | null;

  /**
   * Идентификатор квалификации
   * @format uuid
   */
  qualificationId?: string | null;

  /** Наименование квалификации */
  qualificationName?: string | null;

  /**
   * Максимальная ставка в час
   * @format double
   */
  maxWorkingRate?: number | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;
}

/**
 * Ответ на запрос поиска потребности проекта в технике по ид
 */
export interface GetProjectRequirementEquipmentByIdResponse {
  /**
   * Ид потребности проекта
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название потребности в технике */
  name?: string | null;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;

  /**
   * Ид типа
   * @format uuid
   */
  typeId?: string | null;

  /** Наименование типа */
  typeName?: string | null;

  /** Параметры */
  parameters?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementEquipmentRequests.GetProjectRequirementEquipments.GetProjectRequirementEquipmentsRequest
 */
export interface GetProjectRequirementEquipmentsResponse {
  /** Список сущностей */
  entities?: GetProjectRequirementEquipmentsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Элемент ответа на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementEquipmentRequests.GetProjectRequirementEquipments.GetProjectRequirementEquipmentsRequest
 */
export interface GetProjectRequirementEquipmentsResponseItem {
  /**
   * Ид потребности проекта в технике
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название потребности в технике */
  name?: string | null;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;

  /**
   * Идентификатор типа
   * @format uuid
   */
  typeId?: string | null;

  /** Наименование типа */
  typeName?: string | null;

  /** Параметры */
  parameters?: string | null;
}

/**
 * Ответ на запрос поиска потребности проекта в помещении по ид
 */
export interface GetProjectRequirementPlaceByIdResponse {
  /**
   * Ид потребности проекта
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название потребности в помещении */
  name?: string | null;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;

  /** Назначение */
  appointment?: string | null;

  /**
   * Общая вместимость
   * @format int32
   */
  totalCapacity?: number | null;

  /**
   * Количество сидячих мест
   * @format int32
   */
  numberOfSeats?: number | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementPlaceRequests.GetProjectRequirementPlaces.GetProjectRequirementPlacesRequest
 */
export interface GetProjectRequirementPlacesResponse {
  /** Список сущностей */
  entities?: GetProjectRequirementPlacesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Элемент ответа на запрос потребности проекта в помещении A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementPlaceRequests.GetProjectRequirementPlaces.GetProjectRequirementPlacesRequest
 */
export interface GetProjectRequirementPlacesResponseItem {
  /**
   * Ид потребности проекта в помещении
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название потребности в помещении */
  name?: string | null;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;

  /** Назначение */
  appointment?: string | null;

  /**
   * Общая вместимость
   * @format int32
   */
  totalCapacity?: number | null;

  /**
   * Количество сидячих мест
   * @format int32
   */
  numberOfSeats?: number | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementRequests.GetProjectRequirements.GetProjectRequirementsRequest
 */
export interface GetProjectRequirementsResponse {
  /** Список сущностей */
  entities?: GetProjectRequirementsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Потребность проекта в запросе A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementRequests.GetProjectRequirements.GetProjectRequirementsRequest
 */
export interface GetProjectRequirementsResponseItem {
  /**
   * Ид потребности проекта
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название потребности */
  name?: string | null;

  /**
   * Ид проекта
   * @format uuid
   */
  projectId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;
}

/**
 * Ответ на запрос получения проектной роли по ид
 */
export interface GetProjectRoleByIdResponse {
  /**
   * Ид проектной роли
   * @format uuid
   */
  projectRoleId?: string;

  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /**
   * Организация
   * @format uuid
   */
  organizationId?: string | null;

  /** Описание */
  description?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Архивная сущность */
  isDeleted?: boolean;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;

  /** Настройки уведомлений */
  notificationSettings?: GetProjectRoleByIdResponseNotificationSettings[] | null;
}

/**
 * Настройки уведомлений для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRoleRequests.GetProjectRoleById.GetProjectRoleByIdResponse
 */
export interface GetProjectRoleByIdResponseNotificationSettings {
  /**
   * Ид настройки уведомлений
   * @format uuid
   */
  notificationSettingsId?: string;

  /**
   * Тип события<ul>
   * <li><b>1 - StartProject</b>: Начало проекта</li>
   * <li><b>2 - FinishProject</b>: Окончание проекта</li>
   * <li><b>3 - UpdateProject</b>: Обновление проекта</li>
   * <li><b>4 - DeleteProject</b>: Удаление проекта</li>
   * <li><b>5 - UpdateResource</b>: Обновление ресурса</li>
   * <li><b>6 - DeleteResource</b>: Удаление ресурса</li>
   * <li><b>7 - UnavailableResource</b>: Выбытие ресурса</li>
   * <li><b>8 - StartResourceInProject</b>: Назначение ресурса на проекте</li>
   * <li><b>9 - FinishResourceInProject</b>: Окончание ресурса (удаление) с проекта</li>
   * <li><b>10 - StartLoadResourceInProject</b>: Начало планирования ресурса на проекте</li>
   * <li><b>11 - FinishLoadResourceInProject</b>: Окончание планирования ресурса на проекте</li>
   * <li><b>12 - StartWorkWeek</b>: Начало рабочей недели</li>
   * <li><b>13 - SubscriptionExpiration</b>: Окончание активной подписки</li>
   * </ul>
   *
   */
  eventType?: EventTypes;

  /**
   * Сколько дней до отправления уведомления
   * @format int32
   */
  daysToSend?: number;

  /** Отправлять по почте */
  hasEmailNotification?: boolean;

  /** Отправлять по пушу */
  hasPushNotification?: boolean;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRoleRequests.GetProjectRoles.GetProjectRolesRequest
 */
export interface GetProjectRolesResponse {
  /** Список сущностей */
  entities?: GetProjectRolesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Проектная роль для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRoleRequests.GetProjectRoles.GetProjectRolesResponse
 */
export interface GetProjectRolesResponseItem {
  /**
   * Ид проектной роли
   * @format uuid
   */
  projectRoleId?: string;

  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /**
   * Организация
   * @format uuid
   */
  organizationId?: string | null;

  /** Описание */
  description?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;

  /** Настройки уведомлений */
  notificationSettings?: GetProjectRolesResponseItemNotificationSettings[] | null;
}

/**
 * Настройки уведомлений для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRoleRequests.GetProjectRoles.GetProjectRolesResponse
 */
export interface GetProjectRolesResponseItemNotificationSettings {
  /**
   * Ид настройки уведомлений
   * @format uuid
   */
  notificationSettingsId?: string;

  /**
   * Тип события<ul>
   * <li><b>1 - StartProject</b>: Начало проекта</li>
   * <li><b>2 - FinishProject</b>: Окончание проекта</li>
   * <li><b>3 - UpdateProject</b>: Обновление проекта</li>
   * <li><b>4 - DeleteProject</b>: Удаление проекта</li>
   * <li><b>5 - UpdateResource</b>: Обновление ресурса</li>
   * <li><b>6 - DeleteResource</b>: Удаление ресурса</li>
   * <li><b>7 - UnavailableResource</b>: Выбытие ресурса</li>
   * <li><b>8 - StartResourceInProject</b>: Назначение ресурса на проекте</li>
   * <li><b>9 - FinishResourceInProject</b>: Окончание ресурса (удаление) с проекта</li>
   * <li><b>10 - StartLoadResourceInProject</b>: Начало планирования ресурса на проекте</li>
   * <li><b>11 - FinishLoadResourceInProject</b>: Окончание планирования ресурса на проекте</li>
   * <li><b>12 - StartWorkWeek</b>: Начало рабочей недели</li>
   * <li><b>13 - SubscriptionExpiration</b>: Окончание активной подписки</li>
   * </ul>
   *
   */
  eventType?: EventTypes;

  /**
   * Сколько дней до отправления уведомления
   * @format int32
   */
  daysToSend?: number;

  /** Отправлять по почте */
  hasEmailNotification?: boolean;

  /** Отправлять по пушу */
  hasPushNotification?: boolean;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetProjects.GetProjectsRequest
 */
export interface GetProjectsResponse {
  /** Список сущностей */
  entities?: GetProjectsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Проект для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetProjects.GetProjectsResponse
 */
export interface GetProjectsResponseItem {
  /**
   * ИД сущности
   * @format uuid
   */
  id?: string;

  /** Название */
  name?: string | null;

  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;

  /** ФИО ответственного */
  responsibleFullName?: string | null;

  /** Фамилия ответственного */
  responsibleSurname?: string | null;

  /** Имя ответственного */
  responsibleName?: string | null;

  /** Отчество ответственного */
  responsiblePatronymic?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Контрагент
   * @format uuid
   */
  contragentId?: string | null;

  /** Название контрагента */
  contragentName?: string | null;

  /**
   * Количество уникальных ресурсов на проекте
   * @format int32
   */
  sourcesInProjectCount?: number | null;

  /** План по часам */
  hourlyPlan?: string | null;

  /** План по стоимости */
  costPlan?: string | null;

  /** Комментарий */
  comment?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /**
   * Статусы проекта<ul>
   * <li><b>1 - Active</b>: Активный</li>
   * <li><b>2 - Inactive</b>: Неактивный</li>
   * </ul>
   *
   */
  status?: ProjectStatuses;

  /** Список ресурсов проекта */
  resourcesInProject?: GetProjectsResponseResourcesInProjectItem[] | null;
}

/**
 * Ресурс в запросе A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetProjects.GetProjectsResponseItem
 */
export interface GetProjectsResponseResourcesInProjectItem {
  /**
   * Ид ресурса на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /**
   * Ид ресурса
   * @format uuid
   */
  resourceId?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название ресурса */
  resourceName?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;
}

/**
 * Проектные роли ресурса на проекте для A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetResourceInProjectById.GetResourceInProjectByIdResponse
 */
export interface GetResourceInProjectByIdProjectRole {
  /**
   * Ид проектной роли
   * @format uuid
   */
  id?: string;

  /** Название проектной роли */
  name?: string | null;

  /** Код проектной роли */
  code?: string | null;

  /** Описание */
  description?: string | null;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;
}

/**
 * Ответ на запрос поиск ресурса на проекте по ид
 */
export interface GetResourceInProjectByIdResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /**
   * Ресурс
   * @format uuid
   */
  resourceId?: string;

  /** Наименование проекта */
  projectName?: string | null;

  /** Наименование ресурса */
  resourceName?: string | null;

  /**
   * Ставка на проекте (р/ч)
   * @format int32
   */
  workingRateInProject?: number | null;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;

  /** Проектные роли ресурса на проекте */
  projectRoles?: GetResourceInProjectByIdProjectRole[] | null;

  /** Список ресурсов проектов */
  loadResourceInProjects?: GetResourceInProjectByIdResponseLoadResourceInProject[] | null;
}

/**
 * Нагрузка ресурса проекта для A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetResourceInProjectById.GetResourceInProjectByIdResponse
 */
export interface GetResourceInProjectByIdResponseLoadResourceInProject {
  /**
   * Ид нагрузки на ресурс проекта
   * @format uuid
   */
  id?: string;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Количество рабочих часов в день
   * @format int32
   */
  workingHoursPerDay?: number;

  /** Работает ли сотрудник в выходные */
  isWorkOnWeekends?: boolean;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetResourceInProjects.GetResourceInProjectsRequest
 */
export interface GetResourceInProjectsResponse {
  /** Список сущностей */
  entities?: GetResourceInProjectsResponseResourceItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Нагрузка ресурса проекта для A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetResourceInProjects.GetResourceInProjectsResponseResourceItem
 */
export interface GetResourceInProjectsResponseLoadResourceInProject {
  /**
   * Ид нагрузки ресурса
   * @format uuid
   */
  loadResourceInProjectId?: string;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Количество рабочих часов в день
   * @format int32
   */
  workingHoursPerDay?: number;

  /** Работает ли сотрудник в выходные */
  isWorkOnWeekends?: boolean;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;
}

/**
 * Ресурс проекта для A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetResourceInProjects.GetResourceInProjectsResponseResourceItem
 */
export interface GetResourceInProjectsResponseProjectResponseItem {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /**
   * Ресурс
   * @format uuid
   */
  resourceInProjectId?: string;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;

  /** Нагрузка ресурсов на проекте */
  loadResourceInProjects?: GetResourceInProjectsResponseLoadResourceInProject[] | null;
}

/**
 * Ресурс в запросе A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetResourceInProjects.GetResourceInProjectsRequest
 */
export interface GetResourceInProjectsResponseResourceItem {
  /**
   * Ид ресурса
   * @format uuid
   */
  resourceId?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название ресурса */
  resourceName?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Список ресурсов проектов */
  resourceInProjects?: GetResourceInProjectsResponseProjectResponseItem[] | null;

  /** Список недоступности ресурсов */
  unavailableResources?: GetResourceInProjectsResponseUnavailableResourceItem[] | null;

  /** Список идентификаторов значений справочников */
  catalogValues?: string[] | null;
}

/**
 * Недоступность ресурса для A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetResourceInProjects.GetResourceInProjectsResponseResourceItem
 */
export interface GetResourceInProjectsResponseUnavailableResourceItem {
  /**
   * Ид недоступности ресурса
   * @format uuid
   */
  id?: string;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Причина
   * @format uuid
   */
  reasonId?: string | null;

  /** Наименование причины */
  reasonName?: string | null;
}

/**
 * Ресурсы проекта для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetResourcesInProject.GetResourcesInProjectRequest
 */
export interface GetResourcesInProjectItem {
  /**
   * Ид ресурса
   * @format uuid
   */
  id?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Наименование */
  name?: string | null;

  /**
   * Ид ресурса на проекте
   * @format uuid
   */
  resourceInProjectId?: string;

  /**
   * Дата старта первого периода нагрузки ресурса на проекте
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания последнего периода нагрузки
   * @format date-time
   */
  finishDate?: string | null;
}

/**
 * Ответ на A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.GetResourcesInProject.GetResourcesInProjectRequest
 */
export interface GetResourcesInProjectResponse {
  /** Список ресурсов проекта */
  entities?: GetResourcesInProjectItem[] | null;

  /**
   * Количество ресурсов
   * @format int32
   */
  totalCount?: number;
}

/**
 * Ответ на запрос получения глобальной роли по ид
 */
export interface GetRoleByIdResponse {
  /**
   * Ид глобальной роли
   * @format uuid
   */
  roleId?: string;

  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /**
   * Организация
   * @format uuid
   */
  organizationId?: string | null;

  /** Описание */
  description?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Архивная сущность */
  isDeleted?: boolean;

  /** Действия с сущностями */
  entityActions?: EntityActions[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.RoleRequests.GetRoles.GetRolesRequest
 */
export interface GetRolesResponse {
  /** Список сущностей */
  entities?: GetRolesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Глобальная роль для A2Soft.A2Planner.Api.Contracts.Requests.RoleRequests.GetRoles.GetRolesResponse
 */
export interface GetRolesResponseItem {
  /**
   * Ид глобальной роли
   * @format uuid
   */
  roleId?: string;

  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /**
   * Организация
   * @format uuid
   */
  organizationId?: string | null;

  /** Описание */
  description?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Список действий с сущностями */
  entityActions?: EntityActions[] | null;
}

/**
 * Запрос получение справочника по ид
 */
export interface GetSingleCatalogResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Код */
  code?: string | null;

  /** Имя */
  name?: string | null;

  /**
   * Организация
   * @format uuid
   */
  organizationId?: string | null;

  /** Описание */
  description?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;

  /** Значения справочника */
  catalogValues?: GetSingleCatalogResponseCatalogValue[] | null;
}

/**
 * Значения справочника для A2Soft.A2Planner.Api.Contracts.Requests.CatalogRequests.GetSingleCatalog.GetSingleCatalogResponse
 */
export interface GetSingleCatalogResponseCatalogValue {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Код */
  code?: string | null;

  /** Имя */
  name?: string | null;

  /** Описание */
  description?: string | null;

  /**
   * Дата удаления
   * @format date-time
   */
  deletedOn?: string | null;
}

/**
 * Транзакция на оплату
 */
export interface GetSubscriptionByIdPaymentTransactionResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /**
   * Сумма к оплате
   * @format double
   */
  amount?: number;

  /** Статус транзакции */
  transactionStatus?: string | null;

  /** Описание */
  description?: string | null;

  /** Флаг успешного завершения */
  successfulFlag?: boolean | null;
  duration?: TimeSpan;

  /**
   * Дата оплаты
   * @format date-time
   */
  paymentDate?: string | null;
}

/**
 * Ответ на запрос получение подписки по ид
 */
export interface GetSubscriptionByIdResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /**
   * ИД организации
   * @format uuid
   */
  organizationId?: string | null;

  /**
   * Использованный лимит на аккаунтов
   * @format int32
   */
  accountsCount?: number;

  /**
   * Использованный лимит на проекты
   * @format int32
   */
  projectsCount?: number;

  /**
   * Использованный лимит на ресурсы
   * @format int32
   */
  resourcesCount?: number;

  /**
   * Дата С
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата ПО
   * @format date-time
   */
  finishDate?: string | null;

  /** Тип подписки */
  subscriptionType?: GetSubscriptionByIdSubscriptionTypeResponse;

  /** Транзакции на оплату */
  paymentTransactions?: GetSubscriptionByIdPaymentTransactionResponse[] | null;
}

/**
 * Тип подписки
 */
export interface GetSubscriptionByIdSubscriptionTypeResponse {
  /**
   * Идентификатор
   * @format uuid
   */
  id?: string;

  /** Наименование */
  name?: string | null;

  /** Признак актуальности */
  isActual?: boolean;

  /** Описание */
  description?: string | null;

  /**
   * Количество аккаунтов
   * @format int32
   */
  accounts?: number | null;

  /**
   * Количество проектов
   * @format int32
   */
  projects?: number | null;

  /**
   * Количество ресурсов
   * @format int32
   */
  resources?: number | null;

  /** Продляемая */
  isRenewable?: boolean;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.OrganizationRequests.GetSubscriptions.GetSubscriptionsRequest
 */
export interface GetSubscriptionsResponse {
  /** Список сущностей */
  entities?: GetSubscriptionsResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Подписка для A2Soft.A2Planner.Api.Contracts.Requests.OrganizationRequests.GetSubscriptions.GetSubscriptionsResponse
 */
export interface GetSubscriptionsResponseItem {
  /**
   * Идентификатор подписки
   * @format uuid
   */
  subscriptionId?: string;

  /**
   * ИД организации
   * @format uuid
   */
  organizationId?: string | null;

  /** Наименование организации */
  organizationName?: string | null;

  /**
   * ИД типа подписки
   * @format uuid
   */
  subscriptionTypeId?: string | null;

  /** Наименование типа подписки */
  subscriptionTypeName?: string | null;

  /** Признак актуальности типа подписки */
  isActual?: boolean | null;

  /**
   * Количество активных аккаунтов
   * @format int32
   */
  accounts?: number | null;

  /**
   * Количество ресурсов
   * @format int32
   */
  resources?: number | null;

  /**
   * Количество проектов
   * @format int32
   */
  projects?: number | null;

  /** Описание типа подписки */
  descriptionSubscriptionType?: string | null;

  /**
   * Дата С
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата ПО
   * @format date-time
   */
  finishDate?: string | null;

  /** Список транзакций */
  paymentTransactions?: GetSubscriptionsResponseItemTransaction[] | null;
}

/**
 * Модель транзакции на оплату для A2Soft.A2Planner.Api.Contracts.Requests.OrganizationRequests.GetSubscriptions.GetSubscriptionsResponseItem
 */
export interface GetSubscriptionsResponseItemTransaction {
  /**
   * ИД транзакции на оплату
   * @format uuid
   */
  id?: string;

  /**
   * Сумма к оплате
   * @format double
   */
  amount?: number | null;

  /** Статус транзакции */
  transactionStatus?: string | null;

  /** Описание транзакции */
  descriptionPaymentTransaction?: string | null;

  /** Флаг успешного завершения транзакции */
  successfulFlag?: boolean | null;
  duration?: TimeSpan;

  /**
   * Дата оплаты
   * @format date-time
   */
  paymentDate?: string | null;
}

/**
 * Запрос на получение типа подписки по ид
 */
export interface GetSubscriptionTypeByIdResponse {
  /**
   * Ид
   * @format uuid
   */
  id?: string;

  /** Название */
  name?: string | null;

  /** Признак актуальности */
  isActual?: boolean;

  /** Описание */
  description?: string | null;

  /**
   * Количество аккаунтов
   * @format int32
   */
  accounts?: number | null;

  /**
   * Количество ресурсов
   * @format int32
   */
  resources?: number | null;

  /**
   * Количество проектов
   * @format int32
   */
  projects?: number | null;

  /** Варианты */
  variants?: GetSubscriptionTypeByIdResponseVariant[] | null;
}

/**
 * Модель варианта для типа подписки для A2Soft.A2Planner.Api.Contracts.Requests.SubscriptionTypeRequests.GetSubscriptionTypeById.GetSubscriptionTypeByIdResponse
 */
export interface GetSubscriptionTypeByIdResponseVariant {
  /**
   * Ид варианта
   * @format uuid
   */
  id?: string;

  /**
   * Срок оформления подписки в месяцах
   * @format int32
   */
  term?: number;

  /**
   * Цена
   * @format int32
   */
  cost?: number;

  /** Процент скидки на подписку */
  percent?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.SubscriptionTypeRequests.GetSubscriptionTypes.GetSubscriptionTypesRequest
 */
export interface GetSubscriptionTypesResponse {
  /** Список сущностей */
  entities?: GetSubscriptionTypesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Тип подписки для A2Soft.A2Planner.Api.Contracts.Requests.SubscriptionTypeRequests.GetSubscriptionTypes.GetSubscriptionTypesResponse
 */
export interface GetSubscriptionTypesResponseItem {
  /**
   * Ид
   * @format uuid
   */
  id?: string;

  /** Название */
  name?: string | null;

  /** Признак актуальности */
  isActual?: boolean;

  /** Описание */
  description?: string | null;

  /**
   * Количество аккаунтов
   * @format int32
   */
  accounts?: number | null;

  /**
   * Количество ресурсов
   * @format int32
   */
  resources?: number | null;

  /**
   * Количество проектов
   * @format int32
   */
  projects?: number | null;

  /** Варианты */
  variants?: GetSubscriptionTypesResponseItemVariant[] | null;
}

/**
 * Модель варианта для типа подписки для A2Soft.A2Planner.Api.Contracts.Requests.SubscriptionTypeRequests.GetSubscriptionTypes.GetSubscriptionTypesResponseItem
 */
export interface GetSubscriptionTypesResponseItemVariant {
  /**
   * Ид варианта
   * @format uuid
   */
  id?: string;

  /**
   * Срок оформления подписки в месяцах
   * @format int32
   */
  term?: number;

  /**
   * Цена
   * @format int32
   */
  cost?: number;

  /** Процент скидки на подписку */
  percent?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetUnavailableResources.GetUnavailableResourcesRequest
 */
export interface GetUnavailableResourcesResponse {
  /** Список сущностей */
  entities?: GetUnavailableResourcesResponseItem[] | null;

  /**
   * Общее количество сущностей
   * @format int32
   */
  totalCount?: number;
}

/**
 * Недоступность ресурса в запросе A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.GetUnavailableResources.GetUnavailableResourcesResponse
 */
export interface GetUnavailableResourcesResponseItem {
  /**
   * Ид недоступности ресурса
   * @format uuid
   */
  id?: string;

  /**
   * Ид ресурса
   * @format uuid
   */
  resourceId?: string;

  /**
   * Типы ресурсов<ul>
   * <li><b>1 - Employee</b>: Сотрудник</li>
   * <li><b>2 - Equipment</b>: Техника</li>
   * <li><b>3 - Place</b>: Помещение</li>
   * </ul>
   *
   */
  resourceType?: ResourceTypes;

  /** Название ресурса */
  resourceName?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Причина
   * @format uuid
   */
  reasonId?: string | null;

  /** Наименование причины */
  reasonName?: string | null;
}

/**
 * Запрос на создание справочника
 */
export interface PostCatalogRequest {
  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /** Описание */
  description?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.CatalogRequests.PostCatalog.PostCatalogRequest
 */
export interface PostCatalogResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на создание заказчика
 */
export interface PostContragentRequest {
  /** Название */
  name?: string | null;

  /** ФИО ответственного */
  responsibleName?: string | null;

  /** Комментарий */
  comment?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ContragentRequests.PostContragent.PostContragentRequest
 */
export interface PostContragentResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на создание сотрудника
 */
export interface PostEmployeeRequest {
  /** Фамилия */
  surname?: string | null;

  /** Имя */
  name?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /**
   * Дата рождения
   * @format date-time
   */
  birthdate?: string | null;

  /** Дополнительные компетенции сотрудника */
  additionalCompetences?: PostEmployeeRequestAdditionalCompetence[] | null;

  /** Удаленная работа */
  isRemoteWork?: boolean | null;

  /**
   * Наставник
   * @format uuid
   */
  mentorId?: string | null;

  /**
   * Основная специализация
   * @format uuid
   */
  mainSpecializationId?: string | null;

  /**
   * Основная квалификация
   * @format uuid
   */
  mainQualificationId?: string | null;

  /**
   * Должность
   * @format uuid
   */
  positionId?: string | null;

  /**
   * Идентификатор аватарки
   * @format uuid
   */
  accountPictureId?: string | null;

  /** Email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /** Значения справочника */
  catalogValuesId?: string[] | null;
}

/**
 * Дополнительные компетенции сотрудника для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.PostEmployee.PostEmployeeRequest
 */
export interface PostEmployeeRequestAdditionalCompetence {
  /**
   * Специализация
   * @format uuid
   */
  specializationId?: string | null;

  /**
   * Квалификация
   * @format uuid
   */
  qualificationId?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.PostEmployee.PostEmployeeRequest
 */
export interface PostEmployeeResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на создание техники
 */
export interface PostEquipmentsRequest {
  /** Наименование */
  name?: string | null;

  /**
   * Тип
   * @format uuid
   */
  typeId?: string | null;

  /** Инвентарный номер */
  inventoryNumber?: string | null;

  /** Параметры */
  parameters?: string | null;

  /**
   * Помещение
   * @format uuid
   */
  placeId?: string | null;

  /**
   * Сотрудник
   * @format uuid
   */
  employeeId?: string | null;

  /**
   * Дата начала использования
   * @format date-time
   */
  useStartDate?: string | null;

  /**
   * Дата окончания срока службы
   * @format date-time
   */
  endOfLifeDate?: string | null;

  /**
   * Последнее ТО
   * @format date-time
   */
  lastTechnicalInspection?: string | null;

  /**
   * Следующее ТО
   * @format date-time
   */
  nextTechnicalInspection?: string | null;

  /**
   * Начальная стоимость
   * @format double
   */
  initialCost?: number | null;

  /** Значения справочника */
  catalogValuesId?: string[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.EquipmentRequests.PostEquipments.PostEquipmentsRequest
 */
export interface PostEquipmentsResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на получение инвайт токена
 */
export interface PostInviteTokenRequest {
  /**
   * Сотрудник
   * @format uuid
   */
  employeeId?: string;

  /**
   * Ид глобальной роли
   * @format uuid
   */
  roleId?: string | null;
}

/**
 * Ответ на запрос для получения токена приглашения
 */
export interface PostInviteTokenResponse {
  /** Токен приглашения */
  inviteToken?: string | null;

  /**
   * Идентификатор типа токена.
   * @format int32
   */
  tokenTypeId?: number;

  /**
   * Время создания токена.
   * @format date-time
   */
  createdOn?: string;
}

/**
 * Запрос на создание помещения
 */
export interface PostPlaceRequest {
  /** Наименование */
  name?: string | null;

  /** Назначение */
  appointment?: string | null;

  /** Адрес */
  address?: string | null;

  /**
   * Общая вместимость
   * @format int32
   */
  totalCapacity?: number | null;

  /**
   * Количество сидячих мест
   * @format int32
   */
  numberOfSeats?: number | null;

  /**
   * Площадь
   * @format double
   */
  square?: number | null;

  /**
   * Последняя уборка
   * @format date-time
   */
  lastCleaning?: string | null;

  /**
   * Следующая уборка
   * @format date-time
   */
  nextCleaning?: string | null;

  /** Значения справочника */
  catalogValuesId?: string[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.PlaceRequests.PostPlace.PostPlaceRequest
 */
export interface PostPlaceResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на создание проекта
 */
export interface PostProjectRequest {
  /** Наименование */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Контрагент
   * @format uuid
   */
  contragentId?: string | null;

  /** План по часам */
  hourlyPlan?: string | null;

  /** План по стоимости */
  costPlan?: string | null;

  /** Комментарий */
  comment?: string | null;

  /**
   * Статусы проекта<ul>
   * <li><b>1 - Active</b>: Активный</li>
   * <li><b>2 - Inactive</b>: Неактивный</li>
   * </ul>
   *
   */
  status?: ProjectStatuses;
}

/**
 * Запрос на создание потребности проекта в сотруднике
 */
export interface PostProjectRequirementEmployeeRequest {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Наименование */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /**
   * Идентификатор должности
   * @format uuid
   */
  positionId?: string | null;

  /**
   * Идентификатор специализации
   * @format uuid
   */
  specializationId?: string | null;

  /**
   * Идентификатор квалификации
   * @format uuid
   */
  qualificationId?: string | null;

  /**
   * Максимальная ставка в час
   * @format double
   */
  maxWorkingRate?: number | null;

  /** Значения справочника */
  catalogValues?: string[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementEmployeeRequests.PostProjectRequirementEmployee.PostProjectRequirementEmployeeRequest
 */
export interface PostProjectRequirementEmployeeResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на создание потребности проекта в технике
 */
export interface PostProjectRequirementEquipmentRequest {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Наименование */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Значения справочника */
  catalogValues?: string[] | null;

  /**
   * Ид типа
   * @format uuid
   */
  typeId?: string | null;

  /** Параметры */
  parameters?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementEquipmentRequests.PostProjectRequirementEquipment.PostProjectRequirementEquipmentRequest
 */
export interface PostProjectRequirementEquipmentResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на создание потребности проекта в помещении
 */
export interface PostProjectRequirementPlaceRequest {
  /**
   * Проект
   * @format uuid
   */
  projectId?: string;

  /** Наименование */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Назначение */
  appointment?: string | null;

  /**
   * Общая вместимость
   * @format int32
   */
  totalCapacity?: number | null;

  /**
   * Количество сидячих мест
   * @format int32
   */
  numberOfSeats?: number | null;

  /** Значения справочника */
  catalogValues?: string[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequirementPlaceRequests.PostProjectRequirementPlace.PostProjectRequirementPlaceRequest
 */
export interface PostProjectRequirementPlaceResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRequests.PostProject.PostProjectRequest
 */
export interface PostProjectResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на создание проектной роли
 */
export interface PostProjectRoleRequest {
  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /** Описание */
  description?: string | null;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;

  /** Настройки уведомлений */
  notificationSettings?: PostProjectRoleRequestNotificationSettings[] | null;
}

/**
 * Настройки уведомлений для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRoleRequests.PostProjectRole.PostProjectRoleRequest
 */
export interface PostProjectRoleRequestNotificationSettings {
  /**
   * Тип события<ul>
   * <li><b>1 - StartProject</b>: Начало проекта</li>
   * <li><b>2 - FinishProject</b>: Окончание проекта</li>
   * <li><b>3 - UpdateProject</b>: Обновление проекта</li>
   * <li><b>4 - DeleteProject</b>: Удаление проекта</li>
   * <li><b>5 - UpdateResource</b>: Обновление ресурса</li>
   * <li><b>6 - DeleteResource</b>: Удаление ресурса</li>
   * <li><b>7 - UnavailableResource</b>: Выбытие ресурса</li>
   * <li><b>8 - StartResourceInProject</b>: Назначение ресурса на проекте</li>
   * <li><b>9 - FinishResourceInProject</b>: Окончание ресурса (удаление) с проекта</li>
   * <li><b>10 - StartLoadResourceInProject</b>: Начало планирования ресурса на проекте</li>
   * <li><b>11 - FinishLoadResourceInProject</b>: Окончание планирования ресурса на проекте</li>
   * <li><b>12 - StartWorkWeek</b>: Начало рабочей недели</li>
   * <li><b>13 - SubscriptionExpiration</b>: Окончание активной подписки</li>
   * </ul>
   *
   */
  eventType?: EventTypes;

  /**
   * Сколько дней до отправления уведомления
   * @format int32
   */
  daysToSend?: number;

  /** Отправлять по почте */
  hasEmailNotification?: boolean;

  /** Отправлять по пушу */
  hasPushNotification?: boolean;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ProjectRoleRequests.PostProjectRole.PostProjectRoleRequest
 */
export interface PostProjectRoleResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на создание глобальной роли
 */
export interface PostRoleRequest {
  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /** Описание */
  description?: string | null;

  /** Список действий с сущностями */
  entityActions?: EntityActions[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.RoleRequests.PostRole.PostRoleRequest
 */
export interface PostRoleResponse {
  /**
   * Идентификатор созданной записи
   * @format uuid
   */
  id?: string;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

/**
 * Сообщение с проблемой
 */
export interface ProblemDetailsResponse {
  /** ИД исключения для корреляции */
  errorId?: string | null;

  /**
   * A URI reference [RFC3986] that identifies the problem type. This specification
   *  encourages that, when dereferenced, it provide human-readable documentation for
   *  the problem type (e.g., using HTML [W3C.REC-html5-20141028]). When this member
   *  is not present, its value is assumed to be "about:blank".
   */
  type?: string | null;

  /**
   * A short, human-readable summary of the problem type.It SHOULD NOT change from
   *     occurrence to occurrence of the problem, except for purposes of localization(e.g.,
   *     using proactive content negotiation; see[RFC7231], Section 3.4).
   */
  title?: string | null;

  /**
   * The HTTP status code([RFC7231], Section 6) generated by the origin server for
   *     this occurrence of the problem.
   * @format int32
   */
  status?: number | null;

  /** A human-readable explanation specific to this occurrence of the problem. */
  detail?: string | null;

  /**
   * A URI reference that identifies the specific occurrence of the problem.It may
   *     or may not yield further information if dereferenced.
   */
  instance?: string | null;

  /**
   * Gets the System.Collections.Generic.IDictionary`2 for extension members.
   * Problem type definitions MAY extend the problem details object with additional
   * members. Extension members appear in the same namespace as other members of a
   * problem type.
   */
  extensions?: Record<string, any>;
}

/**
* Действия на проекте<ul>
<li><b>1 - UpdateResource</b>: Добавить ресурс на проект</li>
<li><b>2 - UpdateProject</b>: Обновить проект</li>
<li><b>3 - DeleteProject</b>: Удалить проект</li>
<li><b>4 - ReadProjectRequirement</b>: Может просматривать потребности проекта</li>
<li><b>5 - UpdateProjectRequirement</b>: Может изменять потребности проекта</li>
</ul>
*/
export enum ProjectActions {
  UpdateResource = "UpdateResource",
  UpdateProject = "UpdateProject",
  DeleteProject = "DeleteProject",
  ReadProjectRequirement = "ReadProjectRequirement",
  UpdateProjectRequirement = "UpdateProjectRequirement",
}

/**
* Статусы проекта<ul>
<li><b>1 - Active</b>: Активный</li>
<li><b>2 - Inactive</b>: Неактивный</li>
</ul>
*/
export enum ProjectStatuses {
  Active = "Active",
  Inactive = "Inactive",
}

/**
 * Запрос на обновление аккаунта
 */
export interface PutAccountRequest {
  /** Email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /**
   * Аватарка
   * @format uuid
   */
  accountPictureId?: string | null;
}

/**
 * Команда обновления списка глобальных ролей аккаунта
 */
export interface PutAccountRolesRequest {
  /** Список глобальных ролей */
  roleIds?: string[] | null;
}

/**
 * Значения справочника
 */
export interface PutCatalogByIdCatalogValue {
  /**
   * Ид
   * @format uuid
   */
  id?: string | null;

  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /** Описание */
  description?: string | null;
}

/**
 * Запрос на обновление справочника
 */
export interface PutCatalogByIdRequest {
  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /** Описание */
  description?: string | null;

  /** Удалить */
  isDeleted?: boolean;

  /** Значения справочника */
  catalogValues?: PutCatalogByIdCatalogValue[] | null;
}

/**
 * Запрос для обновления контрагента
 */
export interface PutContragentByIdRequest {
  /** Наименование */
  name?: string | null;

  /** ФИО ответственного */
  responsibleName?: string | null;

  /** Комментарий */
  comment?: string | null;
}

/**
 * Запрос на обновление сотрудника
 */
export interface PutEmployeeByIdRequest {
  /** Фамилия */
  surname?: string | null;

  /** Имя */
  name?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /**
   * Дата рождения
   * @format date-time
   */
  birthdate?: string | null;

  /** Дополнительные компетенции сотрудника */
  additionalCompetences?: PutEmployeeByIdRequestAdditionalCompetence[] | null;

  /** Удаленная работа */
  isRemoteWork?: boolean | null;

  /**
   * Наставник
   * @format uuid
   */
  mentorId?: string | null;

  /**
   * Основная специализация
   * @format uuid
   */
  mainSpecializationId?: string | null;

  /**
   * Основная квалификация
   * @format uuid
   */
  mainQualificationId?: string | null;

  /**
   * Должность по трудовому договору
   * @format uuid
   */
  positionId?: string | null;

  /**
   * Идентификатор аватарки
   * @format uuid
   */
  accountPictureId?: string | null;

  /** Email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /** Значения справочника */
  catalogValuesId?: string[] | null;
}

/**
 * Дополнительные компетенции сотрудника для A2Soft.A2Planner.Api.Contracts.Requests.EmployeeRequests.PutEmployeeById.PutEmployeeByIdRequest
 */
export interface PutEmployeeByIdRequestAdditionalCompetence {
  /**
   * Дополнительные компетенции сотрудника
   * @format uuid
   */
  additionalCompetenceId?: string | null;

  /**
   * Специализация
   * @format uuid
   */
  specializationId?: string | null;

  /**
   * Квалификация
   * @format uuid
   */
  qualificationId?: string | null;
}

/**
 * Запрос на обновление техники
 */
export interface PutEquipmentByIdRequest {
  /** Наименование */
  name?: string | null;

  /**
   * Тип
   * @format uuid
   */
  typeId?: string | null;

  /** Инвентарный номер */
  inventoryNumber?: string | null;

  /** Параметры */
  parameters?: string | null;

  /**
   * Помещение
   * @format uuid
   */
  placeId?: string | null;

  /**
   * Сотрудник
   * @format uuid
   */
  employeeId?: string | null;

  /**
   * Дата начала использования
   * @format date-time
   */
  useStartDate?: string | null;

  /**
   * Дата окончания срока службы
   * @format date-time
   */
  endOfLifeDate?: string | null;

  /**
   * Последнее ТО
   * @format date-time
   */
  lastTechnicalInspection?: string | null;

  /**
   * Следующее ТО
   * @format date-time
   */
  nextTechnicalInspection?: string | null;

  /**
   * Начальная стоимость
   * @format double
   */
  initialCost?: number | null;

  /** Значения справочника */
  catalogValuesId?: string[] | null;
}

/**
 * Запрос на добавление нагрузки запланированному на проект ресурсу
 */
export interface PutLoadResourceInProjectByIdRequest {
  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string;

  /**
   * Количество рабочих часов в день
   * @format int32
   */
  workingHoursPerDay?: number;

  /** Работает ли сотрудник в выходные */
  isWorkOnWeekends?: boolean | null;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;

  /**
   * Дата начала периода для удаления
   * @format date-time
   */
  deleteStartDate?: string | null;

  /**
   * Дата окончания периода для удаления
   * @format date-time
   */
  deleteFinishDate?: string | null;
}

/**
 * Запрос на изменение нагрузок ресурсов на проекте по ид нагрузок
 */
export interface PutLoadResourceInProjectsRequest {
  /** Список нагрузок на ресурс проекта */
  entities?: PutLoadResourceInProjectsRequestItem[] | null;
}

/**
 * Нагрузка на ресурс в запросе A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.PutLoadResourceInProjects.PutLoadResourceInProjectsRequest
 */
export interface PutLoadResourceInProjectsRequestItem {
  /**
   * Ид нагрузки на ресурс проекта
   * @format uuid
   */
  id?: string;

  /**
   * Количество рабочих часов в день
   * @format int32
   */
  workingHoursPerDay?: number;
}

/**
 * Запрос изменение организации по ид
 */
export interface PutOrganizationByIdRequest {
  /** Название организации */
  name?: string | null;

  /** ИНН */
  inn?: string | null;

  /**
   * Ид логотипа
   * @format uuid
   */
  logoId?: string | null;
}

/**
 * Запрос на изменение подписки организации
 */
export interface PutOrganizationSubscriptionRequest {
  /**
   * ИД типа подписки
   * @format uuid
   */
  subscriptionTypeId?: string;

  /**
   * Идентификатор варианта
   * @format uuid
   */
  variantId?: string;

  /** Описание транзакции на оплату */
  description?: string | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.OrganizationRequests.PutOrganizationSubscription.PutOrganizationSubscriptionRequest
 */
export interface PutOrganizationSubscriptionResponse {
  /**
   * Идентификатор подписки
   * @format uuid
   */
  subscriptionId?: string;

  /**
   * Идентификатор транзакции на оплату
   * @format uuid
   */
  paymentTransactionId?: string | null;

  /** URL для оплаты транзакции */
  paymentUrl?: string | null;
}

/**
 * Запрос на обновление пароля
 */
export interface PutPasswordRequest {
  /** Старый пароль */
  currentPassword?: string | null;

  /** Новый пароль */
  newPassword?: string | null;
}

/**
 * Запрос на обновление помещения
 */
export interface PutPlaceByIdRequest {
  /** Наименование */
  name?: string | null;

  /** Назначение */
  appointment?: string | null;

  /** Адрес */
  address?: string | null;

  /**
   * Общая вместимость
   * @format int32
   */
  totalCapacity?: number | null;

  /**
   * Количество сидячих мест
   * @format int32
   */
  numberOfSeats?: number | null;

  /**
   * Площадь
   * @format double
   */
  square?: number | null;

  /**
   * Последняя уборка
   * @format date-time
   */
  lastCleaning?: string | null;

  /**
   * Следующая уборка
   * @format date-time
   */
  nextCleaning?: string | null;

  /** Значения справочника */
  catalogValuesId?: string[] | null;
}

/**
 * Запрос на обновление проекта
 */
export interface PutProjectByIdRequest {
  /** Наименование */
  name?: string | null;

  /**
   * Статусы проекта<ul>
   * <li><b>1 - Active</b>: Активный</li>
   * <li><b>2 - Inactive</b>: Неактивный</li>
   * </ul>
   *
   */
  status?: ProjectStatuses;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Контрагент
   * @format uuid
   */
  contragentId?: string | null;

  /** План по часам */
  hourlyPlan?: string | null;

  /** План по стоимости */
  costPlan?: string | null;

  /** Комментарий */
  comment?: string | null;
}

/**
 * Запрос на обновление потребности проекта в сотруднике
 */
export interface PutProjectRequirementEmployeeByIdRequest {
  /** Наименование */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /**
   * Идентификатор должности
   * @format uuid
   */
  positionId?: string | null;

  /**
   * Идентификатор специализации
   * @format uuid
   */
  specializationId?: string | null;

  /**
   * Идентификатор квалификации
   * @format uuid
   */
  qualificationId?: string | null;

  /**
   * Максимальная ставка в час
   * @format double
   */
  maxWorkingRate?: number | null;

  /** Значения справочника */
  catalogValues?: string[] | null;
}

/**
 * Запрос на обновление потребности проекта в технике
 */
export interface PutProjectRequirementEquipmentByIdRequest {
  /** Наименование */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Значения справочника */
  catalogValues?: string[] | null;

  /**
   * Идентификатор типа
   * @format uuid
   */
  typeId?: string | null;

  /** Параметры */
  parameters?: string | null;
}

/**
 * Запрос на обновление потребности проекта в помещениях
 */
export interface PutProjectRequirementPlaceByIdRequest {
  /** Наименование */
  name?: string | null;

  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string | null;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string | null;

  /**
   * Суммарная нагрузка
   * @format int32
   */
  totalLoad?: number | null;

  /** Описание */
  description?: string | null;

  /** Назначение */
  appointment?: string | null;

  /**
   * Общая вместимость
   * @format int32
   */
  totalCapacity?: number | null;

  /**
   * Количество сидячих мест
   * @format int32
   */
  numberOfSeats?: number | null;

  /** Значения справочника */
  catalogValues?: string[] | null;
}

/**
 * Запрос на обновление проектной роли
 */
export interface PutProjectRoleByIdRequest {
  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /** Описание */
  description?: string | null;

  /** Удалить */
  isDeleted?: boolean;

  /** Действия на проекте */
  projectActions?: ProjectActions[] | null;

  /** Настройки уведомлений */
  notificationSettings?: PutProjectRoleByIdRequestNotificationSettings[] | null;
}

/**
 * Настройки уведомлений для A2Soft.A2Planner.Api.Contracts.Requests.ProjectRoleRequests.PutProjectRoleById.PutProjectRoleByIdRequest
 */
export interface PutProjectRoleByIdRequestNotificationSettings {
  /**
   * Ид настройки уведомлений
   * @format uuid
   */
  notificationSettingsId?: string | null;

  /**
   * Тип события<ul>
   * <li><b>1 - StartProject</b>: Начало проекта</li>
   * <li><b>2 - FinishProject</b>: Окончание проекта</li>
   * <li><b>3 - UpdateProject</b>: Обновление проекта</li>
   * <li><b>4 - DeleteProject</b>: Удаление проекта</li>
   * <li><b>5 - UpdateResource</b>: Обновление ресурса</li>
   * <li><b>6 - DeleteResource</b>: Удаление ресурса</li>
   * <li><b>7 - UnavailableResource</b>: Выбытие ресурса</li>
   * <li><b>8 - StartResourceInProject</b>: Назначение ресурса на проекте</li>
   * <li><b>9 - FinishResourceInProject</b>: Окончание ресурса (удаление) с проекта</li>
   * <li><b>10 - StartLoadResourceInProject</b>: Начало планирования ресурса на проекте</li>
   * <li><b>11 - FinishLoadResourceInProject</b>: Окончание планирования ресурса на проекте</li>
   * <li><b>12 - StartWorkWeek</b>: Начало рабочей недели</li>
   * <li><b>13 - SubscriptionExpiration</b>: Окончание активной подписки</li>
   * </ul>
   *
   */
  eventType?: EventTypes;

  /**
   * Сколько дней до отправления уведомления
   * @format int32
   */
  daysToSend?: number;

  /** Отправлять по почте */
  hasEmailNotification?: boolean;

  /** Отправлять по пушу */
  hasPushNotification?: boolean;
}

/**
 * Запрос на добавление ресурса к проекту
 */
export interface PutResourceInProjectByIdRequest {
  /**
   * Ставка на проекте (р/ч)
   * @format int32
   */
  workingRateInProject?: number | null;

  /** Проектные роли ресурса на проекте */
  projectRoles?: string[] | null;

  /** Рабочие дни недели сотрудника на проекте */
  workingDaysOfWeek?: DayOfWeek[] | null;
}

/**
 * Ответ на запрос A2Soft.A2Planner.Api.Contracts.Requests.ResourceRequests.PutResourceInProjectById.PutResourceInProjectByIdRequest
 */
export interface PutResourceInProjectByIdResponse {
  /**
   * Ид ресурса на проекте
   * @format uuid
   */
  id?: string;
}

/**
 * Запрос на обновление глобальной роли
 */
export interface PutRoleByIdRequest {
  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /** Описание */
  description?: string | null;

  /** Список действий с сущностями */
  entityActions?: EntityActions[] | null;
}

/**
 * Запрос на добавление недоступности ресурса
 */
export interface PutUnavailableReasonByResourceIdRequest {
  /**
   * Дата начала
   * @format date-time
   */
  startDate?: string;

  /**
   * Дата окончания
   * @format date-time
   */
  finishDate?: string;

  /**
   * Причина
   * @format uuid
   */
  reasonId?: string | null;

  /**
   * Дата начала периода для удаления
   * @format date-time
   */
  deleteStartDate?: string | null;

  /**
   * Дата окончания периода для удаления
   * @format date-time
   */
  deleteFinishDate?: string | null;
}

/**
 * Запрос обмен токена
 */
export interface RefreshTokenRequest {
  /** Refresh token */
  refreshToken?: string | null;
}

/**
 * Запрос на регистрацию
 */
export interface RegistrationRequest {
  /** Токен приглашения */
  inviteToken?: string | null;

  /** Логин - email */
  email?: string | null;

  /** Телефон */
  phone?: string | null;

  /** Имя пользователя */
  name?: string | null;

  /** Фамилия пользователя */
  surname?: string | null;

  /** Отчество */
  patronymic?: string | null;

  /** Пароль */
  password?: string | null;

  /**
   * Отрасль организации
   * @format uuid
   */
  industryId?: string | null;

  /** Название организации пользователя */
  organizationName?: string | null;

  /** Инн организации пользователя */
  organizationInn?: string | null;

  /**
   * Аватарка
   * @format uuid
   */
  accountPictureId?: string | null;

  /** Токен ответа пользователя на капчу */
  captchaToken?: string | null;
}

/**
 * Данные о созданном сотруднике
 */
export interface RegistrationResponse {
  /**
   * Идентификатор сотрудника
   * @format uuid
   */
  employeeId?: string;
}

/**
* Типы ресурсов<ul>
<li><b>1 - Employee</b>: Сотрудник</li>
<li><b>2 - Equipment</b>: Техника</li>
<li><b>3 - Place</b>: Помещение</li>
</ul>
*/
export enum ResourceTypes {
  Employee = "Employee",
  Equipment = "Equipment",
  Place = "Place",
}

/**
 * Запрос установки состояния аккаунта
 */
export interface SetAccountStateRequest {
  /** Активен ли аккаунт */
  isActive?: boolean;
}

export interface TimeSpan {
  /** @format int64 */
  ticks?: number;

  /** @format int32 */
  days?: number;

  /** @format int32 */
  hours?: number;

  /** @format int32 */
  milliseconds?: number;

  /** @format int32 */
  minutes?: number;

  /** @format int32 */
  seconds?: number;

  /** @format double */
  totalDays?: number;

  /** @format double */
  totalHours?: number;

  /** @format double */
  totalMilliseconds?: number;

  /** @format double */
  totalMinutes?: number;

  /** @format double */
  totalSeconds?: number;
}

/**
 * Запрос на получпние токена
 */
export interface TokenRequest {
  /** Код авторизации */
  code?: string | null;

  /** PKCE код */
  codeVerifier?: string | null;

  /** Ссылка возврата */
  redirectUri?: string | null;
}

/**
 * Ответ на запрос получение токена
 */
export interface TokenResponse {
  /** Access token */
  accessToken?: string | null;

  /**
   * Время жизни токена
   * @format int64
   */
  expiresIn?: number | null;

  /** Id token */
  idToken?: string | null;

  /** Кем выпущен */
  iss?: string | null;

  /** Refresh token */
  refreshToken?: string | null;

  /** Скоупы доступа */
  scope?: string | null;

  /** Тип токена */
  tokenType?: string | null;
}

/**
 * Запрос на добавление\удаление ответственного
 */
export interface UpdateProjectResponsibleByIdRequest {
  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;
}

/**
 * Запрос на добавление\удаление ответственного
 */
export interface UpdateResourceResponsibleByIdRequest {
  /**
   * Ответственный
   * @format uuid
   */
  responsibleId?: string | null;
}

/**
 * Запрос на загрузку файлов
 */
export type UploadFileRequest = object;

/**
 * Ответ на запрос сохранения файлов
 */
export interface UploadFileResponse {
  /** Словарь {название файла = ид сохраненного файла} */
  fileNameToFileId?: Record<string, string>;
}
