import type { GetLoadResourceInProjectsResponseItemLoadResourceInProject } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface PeriodProps {
  period: GetLoadResourceInProjectsResponseItemLoadResourceInProject;
  rowIndex: number;
  hovered: boolean;
  columnWidth: number;
  rowHeight: number;
  startDate: Date;
  unitForDayJS: 'day' | 'week' | 'month' | 'year';
  changePeriod?: (y: number, period: GetLoadResourceInProjectsResponseItemLoadResourceInProject, startDate: Dayjs, finishDate: Dayjs) => void;
}

const PeriodComponent: React.FC<PeriodProps> = ({
  period, rowIndex, hovered, columnWidth, rowHeight, startDate, unitForDayJS, changePeriod,
}) => {
  const startX = useMemo(
    () => dayjs.utc(period.startDate).diff(startDate, unitForDayJS),
    [period.startDate, startDate, unitForDayJS],
  );

  const width = useMemo(
    () => dayjs.utc(period.finishDate).diff(startDate, unitForDayJS) - startX + 1,
    [period.finishDate, startDate, unitForDayJS, startX],
  );

  const [dragX, setDragX] = useState(0);
  const [dragged, setDragged] = useState<'start' |'finish' | undefined>(undefined);

  const listener = useCallback((e: MouseEvent) => {
    setDragX((x) => x + e.movementX);
  }, []);

  const stopDragging = useCallback(
    () => {
      const dayCount = Math.round(dragX / columnWidth);
      const start = dragged === 'start' ? dayjs(period.startDate).add(dayCount, 'day') : dayjs(period.startDate);
      const finish = dragged === 'finish' ? dayjs(period.finishDate).add(dayCount, 'day') : dayjs(period.finishDate);
      changePeriod!(rowIndex, period, start, finish);
      setDragged(undefined);
      document.removeEventListener('mousemove', listener);
      document.removeEventListener('mouseup', stopDragging);
    },
    [changePeriod, rowIndex, period, dragX, columnWidth, dragged, listener],
  );

  useEffect(() => {
    if (dragged) {
      document.addEventListener('mousemove', listener);
      document.addEventListener('mouseup', stopDragging);

      return () => {
        document.removeEventListener('mousemove', listener);
        document.removeEventListener('mouseup', stopDragging);
      };
    }
    return () => {};
  }, [dragged, stopDragging, listener]);

  const rectWidth = useMemo(
    () => {
      if (dragged === 'finish') {
        return columnWidth * (width + Math.round(dragX / columnWidth));
      }
      if (dragged === 'start') {
        return columnWidth * (width - Math.round(dragX / columnWidth));
      }
      return columnWidth * width;
    },
    [columnWidth, width, dragged, dragX],
  );

  return (
    <g>
      {changePeriod && (
        <rect
          className="cursor-w-resize period-resize-bar"
          fill="transparent"
          height={rowHeight}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragged('start');
            const svgX = e.clientX - ((e.target as SVGRectElement).ownerSVGElement?.getBoundingClientRect().left ?? 0);
            setDragX(svgX - startX * columnWidth);
          }}
          stroke="transparent"
          width={5}
          x={startX * columnWidth}
          y={rowIndex * rowHeight}
        />
      )}
      {hovered && (
        <g>
          <rect
            className="pointer-events-none z-50"
            fill="transparent"
            height={rowHeight}
            stroke="yellow"
            strokeWidth={2}
            width={rectWidth}
            x={(startX + (dragged === 'start' ? Math.round(dragX / columnWidth) : 0)) * columnWidth}
            y={rowIndex * rowHeight}
          />
          <rect
            className="pointer-events-none z-50"
            fill="transparent"
            height={rowHeight}
            stroke="black"
            strokeDasharray="5,5"
            strokeWidth={2}
            width={rectWidth}
            x={(startX + (dragged === 'start' ? Math.round(dragX / columnWidth) : 0)) * columnWidth}
            y={rowIndex * rowHeight}
          />
        </g>
      )}
      {changePeriod && (
        <rect
          className="cursor-e-resize period-resize-bar"
          fill="transparent"
          height={rowHeight}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragged('finish');
            const svgX = e.clientX - ((e.target as SVGRectElement).ownerSVGElement?.getBoundingClientRect().left ?? 0);
            setDragX(svgX - (startX + width) * columnWidth);
          }}
          stroke="transparent"
          width={5}
          x={(startX + width) * columnWidth - 5}
          y={rowIndex * rowHeight}
        />
      )}
      {dragged && (
        <line
          stroke="blue"
          strokeWidth={2}
          x1={(startX + (dragged === 'finish' ? width : 0)) * columnWidth + dragX}
          x2={(startX + (dragged === 'finish' ? width : 0)) * columnWidth + dragX}
          y1={0}
          y2="100%"
        />
      )}
    </g>
  );
};

export default memo(PeriodComponent);
