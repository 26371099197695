import { joinNonEmpty } from '@services/utils';
import type { GetAccountsResponseItem } from '@typesApi';
import {
  computed,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import type { AccountsTableItem } from '@types';
import { AccountService } from '@servicesApi';
import BaseViewModel from '../BaseViewModel';
import {
  BaseState,
  ErrorState,
  FetchingState,
  SuccessState,
} from '../StateStores';

/** Модель проекта */
export class AccountsItemViewModel extends BaseViewModel<GetAccountsResponseItem> implements AccountsTableItem {
  @observable state?: BaseState = new BaseState();

  constructor(data: GetAccountsResponseItem) {
    super(data);
    makeObservable(this);
  }

  public async updateAccountState() {
    runInAction(() => {
      this.state = new FetchingState();
    });

    try {
      await AccountService.stateUpdate(this.id, { isActive: !this.isActive });
      const { data } = await AccountService.detail(this.id);
      runInAction(() => {
        this.isActive = !!data.isActive;
        this.state = new SuccessState();
      });
    } catch (error) {
      runInAction(() => {
        this.state = new ErrorState(error);
      });
    }
  }

  public clearError() {
    this.state = new BaseState();
  }

  /** Получить 'Ид пользователя' */
  @computed get id() {
    return this.data.employeeId ?? '';
  }

  /** Получить 'Фамилия' */
  @computed get surname() {
    return this.data.surname ?? '';
  }

  /** Изменить 'Фамилия' */
  set surname(value: string | undefined) {
    runInAction(() => {
      this.data.surname = value;
    });
  }

  /** Получить 'Имя' */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменить 'Имя' */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получить 'Отчество' */
  @computed get patronymic() {
    return this.data.patronymic ?? '';
  }

  /** Изменить 'Отчество' */
  set patronymic(value: string | undefined) {
    runInAction(() => {
      this.data.patronymic = value;
    });
  }

  /** Получить 'Логин - email' */
  @computed get email() {
    return this.data.email ?? '';
  }

  /** Изменить 'Логин - email' */
  set email(value: string | undefined) {
    runInAction(() => {
      this.data.email = value;
    });
  }

  /** Получить 'Телефон' */
  @computed get phone() {
    return this.data.phone ?? '';
  }

  /** Изменить 'Телефон' */
  set phone(value: string | undefined) {
    runInAction(() => {
      this.data.phone = value;
    });
  }

  /** Активность пользователя */
  @computed get isActive() {
    return this.data.isActive || false;
  }

  /** Изменить активность пользователя */
  set isActive(value: boolean) {
    runInAction(() => {
      this.data.isActive = value;
    });
  }

  /** Получить роли пользователя */
  @computed get employeeRoles() {
    return this.data.employeeRoles?.map(((role) => role.name)).join();
  }

  /** Получение полного имени аккаунта */
  @computed get fullName() {
    return joinNonEmpty([this.data.surname, this.data.name, this.data.patronymic]);
  }

  /** Получить идентификатор сотрудника */
  @computed get employeeId() {
    return this.data.employeeId;
  }
}
