import Spinner from '@components/ui/Spinner/Spinner';
import { useStores } from '@hooks';
import React from 'react';
import type { FunctionComponent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffectOnce, useReadLocalStorage } from 'usehooks-ts';

export const Redirect: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const codeVerifier = useReadLocalStorage<string>('code_verifier');
  const { accountStore } = useStores();
  const navigate = useNavigate();

  useEffectOnce(() => {
    if (!codeVerifier || !searchParams.get('code')) {
      return;
    }
    accountStore.tryGetToken(searchParams.get('code')!, codeVerifier!).then((success) => {
      if (success) {
        navigate('/');
      } else {
        navigate('/login');
      }
    });
  });

  return (
    <div className="w-full h-full flex justify-center items-center bg-gradient-to-br from-main to-green-200 gap-3 text-slate-100">
      <Spinner
        height={30}
        width={30}
      />
      <p className="text-2xl font-semibold">Ожидание</p>
    </div>
  );
};
