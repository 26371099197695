import Popup from '@components/Popup/Popup';
import React from 'react';

/** Пропсы для попапа */
interface TitlePopupProps {
  /** Элемент над которым будет подсказка */
  element: HTMLDivElement;
  /** Текст подсказки */
  text: string;
  /** Максимальная ширина попапа */
  maxWidth: string;
  /** Показывать/убирать подсказку при наведении/вне наведения  */
  setShowPopup: (value: boolean) => void;
}

/** Компонент подсказки для текста */
const TitlePopup: React.FC<TitlePopupProps> = ({
  element, maxWidth, text, setShowPopup,
}) => (
  <Popup
    position="center"
    targetRef={element}
  >
    <div
      className="flex flex-col p-3 text-sm bg-white rounded-md border-border border-1 shadow-xl"
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
      style={{
        maxWidth,
      }}
    >
      {text}
    </div>
  </Popup>
);

export default TitlePopup;
