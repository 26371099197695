import { AccountService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetAccountsResponseItem } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import { AccountsItemViewModel } from './AccountsItemViewModel';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

/** Фильтр пользователя */
interface AccountFilter extends ListBaseFilter {
  /** ФИО пользователя */
  name?: string;
}

/** Стор пользователей */
export class AccountsStore extends BaseEntityListStore<GetAccountsResponseItem, AccountFilter, AccountsItemViewModel> {
  @observable private _privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.accounts.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.accounts.canDelete;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.accounts.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.accounts.canRead;
  }

  @computed public get canChangeState(): boolean {
    return this._privilegesStore.accounts.canChangeState;
  }

  /** Фетч пользователей */
  public override async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await AccountService.list(this.filter);
      runInAction(() => {
        this.setData(data, AccountsItemViewModel);
      });
    });
  }
}
