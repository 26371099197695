import { dayOfWeekEnumToNumber } from '@services/utils';
import type { GetResourceInProjectByIdResponse } from '@typesApi';
import dayjs from 'dayjs';
import { computed } from 'mobx';
import BaseViewModel from '../BaseViewModel';
import { LoadResourceInProjectStore } from './LoadResourceInProjectsStore';

export default class ResourceInProjectViewModel extends BaseViewModel<GetResourceInProjectByIdResponse> {
  public loadResourceInProjectStore: LoadResourceInProjectStore;

  constructor(data: GetResourceInProjectByIdResponse) {
    super(data);
    this.loadResourceInProjectStore = new LoadResourceInProjectStore(data.loadResourceInProjects ?? []);
  }

  /** Получить 'Идентификатор' */
  @computed get id() {
    return this.data.id;
  }

  /** Получить 'Идентификатор' */
  @computed get resourceName() {
    return this.data.resourceName;
  }

  /** Наименование проекта */
  @computed get projectName() {
    return this.data.projectName;
  }

  /** Идентификатор проекта */
  @computed get projectId() {
    return this.data.projectId;
  }

  /** Получить 'Нагрузка ресурса на проекте' */
  @computed get loadResourceInProject() {
    return this.data.loadResourceInProjects;
  }

  @computed get workingDaysOfWeek() {
    return this.data.workingDaysOfWeek?.map((day) => dayjs().day(dayOfWeekEnumToNumber(day)).format('dd'));
  }
}
