import Modal from '@components/Modal/Modal';
import React from 'react';
import Button from '../Button';
import type { BaseButtonProps } from '../Button/types';

/** Пропсы для модалки */
interface InfoModalProps {
  header: string;
  text: string | React.ReactNode;
  onClose: () => void;
  /** Вспомогательная кнопка */
  auxiliaryButton?: BaseButtonProps;
}

/** Модалка с информационным сообщением */
const InfoModal: React.FC<InfoModalProps> = ({
  header, onClose, text, auxiliaryButton,
}) => (
  <Modal className="px-0 flex flex-col">
    <div className="text-font-main text-main px-4">
      {header}
    </div>
    <div className="h-[1px] bg-accordion-border mt-2 mb-3" />
    <div className="w-[350px] text-font-main px-4 whitespace-pre-line">
      {text}
      <div className="mt-4 flex gap-form justify-between">
        {auxiliaryButton && (<Button.Primary {...auxiliaryButton} />)}
        <Button.Primary
          className="ml-auto"
          label="ОК"
          onClick={onClose}
          type="outline"
        />
      </div>
    </div>
  </Modal>
);

export default InfoModal;
