import { useStores } from '@hooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

export interface ProjectRowProps {
  rowIndex: number;
  projectId: string;
}

const ProjectRow: React.FC<ProjectRowProps> = ({
  rowIndex, projectId,
}) => {
  const { projectsLoadStore } = useStores();

  const project = useMemo(
    () => projectsLoadStore.projects.find((e) => e.id === projectId),
    [projectId, projectsLoadStore.projects],
  );

  const startIndex = useMemo(
    () => Math.max(project?.startDate?.diff(projectsLoadStore.startDate, projectsLoadStore.unitForDayJS) ?? 0, 0),
    [project?.startDate, projectsLoadStore.startDate, projectsLoadStore.unitForDayJS],
  );

  const finishIndex = useMemo(
    () => Math.min(
      project?.finishDate?.diff(projectsLoadStore.startDate, projectsLoadStore.unitForDayJS) ?? Infinity,
      dayjs.utc(projectsLoadStore.endDate).diff(projectsLoadStore.startDate, projectsLoadStore.unitForDayJS),
    ) + 1,
    [project?.finishDate, projectsLoadStore.endDate, projectsLoadStore.startDate, projectsLoadStore.unitForDayJS],
  );

  return (
    <g>
      <defs>
        <pattern
          height="8"
          id="unavailableProject"
          patternUnits="userSpaceOnUse"
          width="8"
        >
          <rect
            fill="rgb(255,180,180)"
            height={8}
            width={8}
            x={0}
            y={0}
          />
          <path
            d="M-2,6 l4,4
              M0,0 l8,8
              M6,-2 l4,4"
            stroke="lightgray"
            strokeWidth={2}
          />
        </pattern>
      </defs>
      <rect
        className="pointer-events-none"
        fill="url(#unavailableProject)"
        height={projectsLoadStore.rowHeight}
        width={startIndex * projectsLoadStore.columnWidth}
        x={0}
        y={rowIndex * projectsLoadStore.rowHeight}
      />
      <rect
        className="pointer-events-none fill-graph-project-underline"
        height={projectsLoadStore.rowHeight * 0.2}
        width={(finishIndex - startIndex) * projectsLoadStore.columnWidth}
        x={startIndex * projectsLoadStore.columnWidth}
        y={(rowIndex + 0.8) * projectsLoadStore.rowHeight}
      />
      <foreignObject
        height={projectsLoadStore.rowHeight}
        width={(finishIndex - startIndex) * projectsLoadStore.columnWidth}
        x={startIndex * projectsLoadStore.columnWidth}
        y={rowIndex * projectsLoadStore.rowHeight}
      >
        <div
          className="left-0 sticky flex items-center gap-1"
          title={project?.name}
          // @ts-ignore
          xmlns="http://www.w3.org/1999/xhtml"
        >
          <p className="text-xs font-semibold text-gray text-start truncate flex-shrink-0">
            {project?.startDate?.format('DD.MM.YYYY')}
            -
            {project?.finishDate?.format('DD.MM.YYYY')}
          </p>
        </div>
      </foreignObject>
      <rect
        className="pointer-events-none"
        fill="url(#unavailableProject)"
        height={projectsLoadStore.rowHeight}
        width={(projectsLoadStore.seededDates.length - finishIndex) * projectsLoadStore.columnWidth}
        x={finishIndex * projectsLoadStore.columnWidth}
        y={rowIndex * projectsLoadStore.rowHeight}
      />
    </g>
  );
};

export default observer(ProjectRow);
