import { PlaceService } from '@servicesApi';
import type { GetPlacesResponseItem } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

class PlaceNamesStore {
  @observable
  private _placesNames?: GetPlacesResponseItem[] | null;

  constructor() {
    makeObservable(this);
  }

  /** Наименования помещений */
  @computed get placesNames() {
    return this._placesNames?.map((place) => ({
      id: place.id ?? '',
      name: place.name ?? '',
    }));
  }

  /** Получить список наименований помещений */
  public async fetchNames(search?: string, showOnlyEditable?: boolean) {
    const { data } = await PlaceService.list({ filterName: search, showOnlyEditable });
    runInAction(() => {
      this._placesNames = data.entities;
    });
    return data.entities;
  }
}

export default PlaceNamesStore;
