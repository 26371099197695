import type { CellWithSpan } from '@components/ui/Table';
import type { Nullable, ResourcesTableItem } from '@types';
import type { GetResourceInProjectsResponseResourceItem, ResourceTypes } from '@typesApi';
import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { v4 } from 'uuid';
import BaseViewModel from '../BaseViewModel';

export class ResourcesItemViewModel extends BaseViewModel<GetResourceInProjectsResponseResourceItem> implements ResourcesTableItem {
  constructor(data: GetResourceInProjectsResponseResourceItem) {
    super(data);
    makeObservable(this);
  }

  /** выбран ли ресурс */
  @observable
    selected: boolean = false;

  @computed get id() {
    return this.data.resourceId ?? '';
  }

  @computed get resourceType() {
    return this.data.resourceType;
  }

  /** Тип ресурса */
  set resourceType(value: ResourceTypes | undefined) {
    runInAction(() => {
      this.data.resourceType = value;
    });
  }

  /** Наименование ресурса */
  @computed get resourceName() {
    return this.data.resourceName ?? '';
  }

  /** Изменение наименования ресурса */
  set resourceName(value: string | undefined) {
    runInAction(() => {
      this.data.resourceName = value;
    });
  }

  /** Массив ресурсов в проекте */
  @computed get resourceInProjects(): CellWithSpan<Nullable<string> | undefined>[] {
    return (this.data.resourceInProjects ?? []).map((project) => ({
      value: '',
      span: project.loadResourceInProjects?.length || 1,
      key: project.projectId ?? '',
      id: project.projectId,
    }));
  }

  /** Массив всех занятостей по всем проектов */
  @computed get loadResourceInProjects() {
    return (this.data.resourceInProjects ?? []).flatMap(
      (e) => (e.loadResourceInProjects?.length ? e.loadResourceInProjects : [undefined]),
    );
  }

  /** Массив количества рабочих часов по всем проектам */
  @computed get workingHoursPerDay(): CellWithSpan<Nullable<number> | undefined>[] {
    return this.loadResourceInProjects.map((el) => ({
      value: el?.workingHoursPerDay,
      span: 1,
      key: v4(),
    }));
  }

  /** Массив начал занятостей по всем проектам */
  @computed get finishDate() {
    return this.loadResourceInProjects.map((el) => ({
      value: el?.finishDate ? dayjs(el.finishDate) : undefined,
      span: 1,
      key: v4(),
    }));
  }

  /** Массив концов занятостей по всем проектам */
  @computed get startDate() {
    return this.loadResourceInProjects.map((el) => ({
      value: el?.startDate ? dayjs(el.startDate) : undefined,
      span: 1,
      key: v4(),
    }));
  }

  @computed get catalogValues() {
    return this.data.catalogValues || [];
  }

  set catalogValues(value: string[] | undefined) {
    runInAction(() => {
      this.data.catalogValues = value;
    });
  }
}
