import ResourceTable from '@components/CalendarTable/ResourceList/ResourceTable';
import InputHeader from '@components/TableHeaders/InputHeader';
import { FormInput, Accordion, Panel } from '@components/ui';
import Button from '@components/ui/Button';
import Card from '@components/ui/Card/Card';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import Errors from '@components/ui/Errors/Errors';
import FormGrid from '@components/ui/Form/FormGrid';
import type { SelectOption } from '@components/ui/Select';
import { Select } from '@components/ui/Select';
import { Column, Table } from '@components/ui/Table';
import {
  useConfirm,
  useInterceptChanges,
  useStores,
  useTitle,
} from '@hooks';
import { parseStyles, periods } from '@services/utils';
import { ResourceService } from '@servicesApi';
import type { GetEmployeeByIdResponseResourceInProject } from '@typesApi';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useMemo, useCallback, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import type { EmployeeProjectFields } from 'src/stores/Employee/EmployeeProjectsItemViewModel';
import { useEffectOnce } from 'usehooks-ts';
import { ConfirmQuestions } from 'src/enums';
import { useNavigate } from 'react-router-dom';
import IconDescriptions from '@components/CalendarTable/IconDescriptions';
import ButtonIcons from '@components/ui/Button/ButtonIcons';
import ProjectForm from './ProjectForm';

const undefinedOption = { label: 'Не выбрано', value: '' };
const yesOption = { label: 'Да', value: 'true' };
const noOption = { label: 'Нет', value: 'false' };

/** Общая информация (компонент карточки сотрудника) */
const MainInfo: FC<{
  /** Идентификатор сотрудника */
  employeeId: string;
}> = ({ employeeId }) => {
  const navigate = useNavigate();
  const {
    employeeStore, employeesStore, resourceLoadStore, projectStore,
  } = useStores();
  const [employeeInProjectModalInfo, setEmployeeInProjectModalInfo] = useState<{id?: string}>();
  const [editing, setEditing] = useState(false);
  /** Модалка подтверждения архивирования */
  const confirmArchive = useConfirm('Архивировать сотрудника?');
  /** Модалка подтверждения возвращения из архива */
  const confirmUnarchive = useConfirm('Вернуть сотрудника из архива?');
  /** Модалка подтверждения удаления Проекта */
  const confirmDeleteProject = useConfirm(ConfirmQuestions.deleteEmployee);

  /** Модель основной информации о сотруднике */
  const { viewModel, isDirty, resetIsDirty } = useInterceptChanges(employeeStore.viewModel);

  const { canUpdate, canDelete, canUpdateResponsible } = employeeStore;
  const showLoads = useMemo(() => !!resourceLoadStore.viewModel.length, [resourceLoadStore.viewModel.length]);

  useEffectOnce(() => {
    if (!employeeId) {
      return () => {};
    }
    employeeStore.fetch(employeeId);
    runInAction(() => {
      resourceLoadStore.id = employeeId;
      resourceLoadStore.startDate = dayjs.utc().startOf('day').toDate();
    });
    return () => {
      employeesStore.clearStore();
      employeeStore.clearStore();
    };
  });

  useTitle(viewModel.fullName ?? 'Сотрудник');

  const onSaveHandler = useCallback(async () => {
    if (!employeeId) {
      return;
    }

    if (viewModel.validate()) {
      await employeeStore.save();
      await employeeStore.fetch(employeeId);
    }
  }, [employeeId, employeeStore, viewModel]);

  /** Хедер колонки "Название проекта" */
  const projectNameHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="projectName"
      store={viewModel.projects}
    />
  ), [viewModel.projects]);

  const selectedOptionsForIsRemoteWork = useMemo(() => [undefinedOption, yesOption, noOption], []);

  const selectedOptionForIsRemoteWork = useMemo(() => {
    switch (viewModel.isRemoteWork) {
      case true: return yesOption;
      case false: return noOption;
      default: return undefinedOption;
    }
  }, [viewModel.isRemoteWork]);

  const onSelectRemoteWorkSelect = useCallback((option: SelectOption | undefined) => {
    let val: boolean | null;

    switch (option?.value) {
      case 'true': val = true; break;
      case 'false': val = false; break;
      default: val = null;
    }

    viewModel.isRemoteWork = val;
  }, [viewModel]);

  /** Хэндлер изменения состояния редактирования */
  const changeEditingHandler = useCallback(() => {
    if (editing) {
      if (viewModel.validate()) {
        employeeStore.save();
        setEditing(!editing);
      }
    } else {
      setEditing(!editing);
    }
  }, [editing, employeeStore]);

  const selectPeriod = useCallback(
    (val?: SelectOption) => {
      if (val) {
        runInAction(() => {
          resourceLoadStore.period = Number(val.value);
        });
      }
    },
    [resourceLoadStore],
  );

  /** Удаление проекта у сотрудника */
  const DeleteProjectCell = useCallback<React.FC<{data: EmployeeProjectFields}>>(({ data }) => ((
    <div
      className="flex w-full h-full items-center justify-center cursor-pointer"
      onClick={async (e) => {
        e.stopPropagation();
        const confirmResult = await confirmDeleteProject();
        if (!confirmResult) {
          return;
        }
        await ResourceService.resourceInProjectDelete(data.id);
        await Promise.all([employeeStore.fetch(employeeId), resourceLoadStore.reload()]);
      }}
    >
      <MdDelete />
    </div>
  )), [confirmDeleteProject, employeeId, employeeStore, resourceLoadStore]);

  /** Обработчик архивирования проекта */
  const archiveHandler = useCallback(async () => {
    const { isDeleted } = employeeStore.viewModel;
    const [confirm, method] = isDeleted
      ? [confirmUnarchive, async () => {
        await employeeStore.unarchive();
      }]
      : [confirmArchive, async () => {
        await employeeStore.archive();
      }];

    if (await confirm()) {
      await method();
      if (employeeId && employeeStore.state.isSuccess) {
        await employeeStore.fetch(employeeId);
      }
    }
  }, [confirmArchive, confirmUnarchive, employeeId, employeeStore]);

  /** Обработчик отмены изменений */
  const cancelHandler = useCallback(async () => {
    if (isDirty && viewModel.id) {
      await employeeStore.fetch(viewModel.id);
      resetIsDirty();
    }
    setEditing(!editing);
  }, [editing, employeeStore, isDirty, resetIsDirty, viewModel.id]);

  return (
    <div className={parseStyles`w-full flex flex-col gap-card`}>
      <Card
        headerButtons={(
          <>
            {canUpdate && (
              <Button.Primary
                icon={editing ? ButtonIcons.Save : ButtonIcons.Edit}
                label={editing ? 'Сохранить' : 'Редактировать'}
                onClick={changeEditingHandler}
              />
            )}
            {editing && (
              <Button.Primary
                className="ml-2"
                icon={ButtonIcons.Cancel}
                label="Отменить"
                onClick={cancelHandler}
                type="outline"
              />
            )}
            {canDelete && (
              <Button.Primary
                className="ml-2"
                icon={employeeStore.viewModel.isDeleted ? ButtonIcons.ArchiveOut : ButtonIcons.ArchiveIn}
                isLoading={employeeStore.state.isLoading}
                label={employeeStore.viewModel.isDeleted ? 'Вернуть из архива' : 'Архивировать'}
                onClick={archiveHandler}
                type={employeeStore.viewModel.isDeleted ? 'main' : 'danger'}
              />
            )}
          </>
        )}
        headerLabel="Общая информация о сотруднике"
      >
        <FormGrid maxColumnsNumber={3}>
          <FormInput
            disabled={!editing}
            error={viewModel.errors.surname}
            label="Фамилия"
            onChange={(e) => {
              viewModel.surname = e.target.value;
            }}
            value={viewModel.surname}
          />
          <FormInput
            disabled={!editing}
            error={viewModel.errors.name}
            label="Имя"
            onChange={(e) => {
              viewModel.name = e.target.value;
            }}
            value={viewModel.name}
          />
          <FormInput
            disabled={!editing}
            error={viewModel.errors.patronymic}
            label="Отчество"
            onChange={(e) => {
              viewModel.patronymic = e.target.value;
            }}
            value={viewModel.patronymic}
          />
          <DatePicker
            disabled={!editing}
            error={viewModel.errors.birthdate}
            label="Дата рождения"
            onChange={(value) => {
              viewModel.birthdate = value;
            }}
            value={viewModel.birthdate}
          />
          <Select.Catalog
            catalogIdField="mainSpecializationId"
            catalogName="Specialization"
            catalogNameField="mainSpecializationName"
            disabled={!editing}
            label="Специализация"
            viewModel={viewModel}
          />
          <Select.Catalog
            catalogIdField="mainPositionId"
            catalogName="Position"
            catalogNameField="mainPositionName"
            disabled={!editing}
            label="Должность"
            viewModel={viewModel}
          />
          <Select.Base
            disabled={!editing}
            label="Удалённая работа"
            onSelect={onSelectRemoteWorkSelect}
            options={selectedOptionsForIsRemoteWork}
            selectedOption={selectedOptionForIsRemoteWork}
          />
          <Select.Catalog
            catalogIdField="mainQualificationId"
            catalogName="Qualification"
            catalogNameField="mainQualificationName"
            disabled={!editing}
            label="Грейд"
            viewModel={viewModel}
          />
          <Select.Async
            disabled={!editing || !canUpdateResponsible}
            error={viewModel.errors.responsibleFullName}
            label="Ответственный"
            onChange={async (value) => employeeStore.fetchEmployeeNames(value)}
            onClick={viewModel.responsibleId && employeeStore.canReadResponsible
              ? () => navigate(`/resources/employees/${viewModel.responsibleId}`)
              : undefined}
            onSelect={(option) => {
              viewModel.responsible = option;
            }}
            selectedOption={viewModel.responsible}
            clearable
          />
          <FormInput
            disabled={!editing}
            error={viewModel.errors.email}
            label="Электронная почта"
            onChange={(e) => {
              viewModel.email = e.target.value;
            }}
            value={viewModel.email}
          />
          <FormInput
            disabled={!editing}
            error={viewModel.errors.phone}
            label="Телефон"
            mask="+7(___)-___-__-__"
            onChange={(e) => {
              viewModel.phone = e.target.value;
            }}
            value={viewModel.phone}
          />
        </FormGrid>
      </Card>
      <Accordion
        contentClassName="bg-accordion-content-bg [&>div]:min-h-[350px] flex flex-col [&>div]:flex-grow [&>div]:basis-0 overflow-hidden"
        openedIds={['Projects']}
      >
        <Panel
          header="Проекты"
          headerButtons={employeeStore.canAddLoad ? (
            <Button.Primary
              icon={ButtonIcons.AddUser}
              label="Добавить сотрудника на проект"
              onClick={() => {
                setEmployeeInProjectModalInfo({});
              }}
            />
          ) : undefined}
          id="Projects"
        >
          <Table
            onRowClick={(id) => setEmployeeInProjectModalInfo({ id })}
            rowHeight={30}
            store={viewModel.projects}
          >
            <Column
              header="Название проекта"
              sortExpr="projectName"
            >
              <Column<EmployeeProjectFields, GetEmployeeByIdResponseResourceInProject>
                key="projectName"
                dataType="string"
                header={projectNameHeader}
                keyExpr="projectName"
              />
            </Column>
            <Column<EmployeeProjectFields, GetEmployeeByIdResponseResourceInProject>
              dataType="string"
              header="Роль на проекте"
              keyExpr="roles"
            />
            {projectStore.canUpdate
              ? (
                <Column<EmployeeProjectFields, GetEmployeeByIdResponseResourceInProject>
                  dataType="custom"
                  header=""
                  template={DeleteProjectCell}
                  width={50}
                />
              ) : null}
          </Table>
          <Errors store={viewModel.projects} />
        </Panel>
      </Accordion>
      {showLoads && (
        <Card
          headerButtons={(
            <div className="flex flex-1 w-full justify-end gap-2 flex-wrap">
              <div className="flex items-center gap-2">
                <div>С даты:</div>
                <DatePicker
                  clearable={false}
                  height={30}
                  onChange={(value) => {
                    runInAction(() => {
                      if (value) {
                        runInAction(() => {
                          resourceLoadStore.startDate = value.toDate();
                        });
                      }
                    });
                  }}
                  value={dayjs(resourceLoadStore.startDate)}
                  utc
                />
              </div>
              <Select.Base
                clearable={false}
                height={30}
                onSelect={selectPeriod}
                options={periods}
                selectedOption={periods.find((e) => e.value === String(resourceLoadStore.period))}
              />
            </div>
          )}
          headerLabel="Нагрузки"
        >
          <ResourceTable
            className="flex-1 pb-4"
            columnWidth={25}
            listCellWidth={300}
            rowHeight={25}
          />
          <IconDescriptions
            className="p-2 border-1"
            isVisible={false}
          />
        </Card>
      )}
      {employeeInProjectModalInfo && (
        <ProjectForm
          employeeId={employeeId}
          onClose={() => setEmployeeInProjectModalInfo(undefined)}
          onSave={() => {
            onSaveHandler();
          }}
          resourceInProjectId={employeeInProjectModalInfo.id}
        />
      )}
      <Errors store={employeeStore} />
    </div>
  );
};

export default observer(MainInfo);
