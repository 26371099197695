import type { SelectOption } from '@components/ui/Select/types';
import { EmployeeService, ResourceService } from '@servicesApi';
import type { GetEmployeeByIdResponse } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import type EmployeeNamesStore from '../Employees/EmployeeNamesStore';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';
import { EmployeeViewModel } from './EmployeeViewModel';

/** Стор сущности */
export class EmployeeStore extends EntityBaseStore<GetEmployeeByIdResponse, EmployeeViewModel> {
  public viewModel = new EmployeeViewModel({});

  @observable
  private _privilegesStore: PrivilegesStore;

  /** Список сотрудников */
  @observable
  private _employeeNamesStore: EmployeeNamesStore;

  /** Имена сотрудников */
  public employeeNames?: SelectOption[];

  constructor(privilegesStore: PrivilegesStore, employeeNamesStore: EmployeeNamesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
    this._employeeNamesStore = employeeNamesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.resources.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.resources.canDeleteById(this.viewModel.id);
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.resources.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.resources.canRead;
  }

  @computed
  public get canReadResponsible(): boolean {
    return this._privilegesStore.resources.canRead
      || (!!this.viewModel.responsibleId && this._privilegesStore.resources.isResponsibleFor(this.viewModel.responsibleId!));
  }

  @computed
  public get canUpdateResponsible(): boolean {
    return this._privilegesStore.resources.canUpdateResponsible;
  }

  @computed
  public get canAddLoad() {
    return this._privilegesStore.resources.canUpdate
      || this._privilegesStore.projects.canUpdate
      || (this.viewModel.id && this._privilegesStore.responsibleForResources.some((e) => e.id === this.viewModel.id));
  }

  /** Фетч сущности */
  public override async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await EmployeeService.detail(id);
      runInAction(() => {
        this.viewModel = new EmployeeViewModel(data);
      });
    });
  }

  /** Сохранение изменений сотрудника */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await EmployeeService.update(this.viewModel.id!, this.viewModel.getRawData());
        if (this._privilegesStore.resources.canUpdateResponsible) {
          await ResourceService.responsibleUpdate(this.viewModel.id!, { responsibleId: this.viewModel.responsibleId });
        }
      });
    } else {
      await this.runWithStateControl(async () => {
        const id = await (await EmployeeService.create(this.viewModel.getRawData())).data.id;
        if (this._privilegesStore.resources.canUpdateResponsible) {
          await ResourceService.responsibleUpdate(id!, { responsibleId: this.viewModel.responsibleId });
        }
      });
    }
  }

  public async addEmployee(): Promise<string | undefined> {
    return this.runWithStateControl(async () => {
      const { data } = await EmployeeService.create(this.viewModel.getRawData());

      return data.id;
    });
  }

  /** Архивировать сотрудника */
  public async archive(): Promise<void> {
    await this.switchArchive(true);
  }

  /** Вернуть из архива" сотрудника */
  public async unarchive(): Promise<void> {
    await this.switchArchive();
  }

  private async switchArchive(deleteRightNow?: boolean) {
    if (this.viewModel.id) {
      await this.runWithStateControl(async () => {
        await ResourceService.delete(this.viewModel.id!, { deleteRightNow });
      });
    }
  }

  /** Получить список опций для селекта */
  public async fetchEmployeeNames(search?: string, hasAccount?: boolean) {
    const result = await this._employeeNamesStore.fetchEmployeeNames(search, hasAccount);

    runInAction(() => {
      this.employeeNames = result;
    });

    return result;
  }
}
