import type { ProjectRoleTableItem, TableItemView } from '@types';
import type { GetProjectRolesResponseItem, GetProjectRolesResponseItemNotificationSettings } from '@typesApi';
import { ProjectActions } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { ErrorMessages } from 'src/enums';
import type { NotificationEvents } from '@components/Administration/types';
import BaseViewModel from '../../BaseViewModel';

/** Вью модель привилегии */
export class ProjectRolesItemViewModel extends BaseViewModel<GetProjectRolesResponseItem>
  implements TableItemView<ProjectRoleTableItem, GetProjectRolesResponseItem> {
  constructor(data: GetProjectRolesResponseItem) {
    super(data);
    makeObservable(this);
  }

  /** Респонс роли с сервера */
  getRawData() {
    return toJS(this.data);
  }

  /** Выбрана ли роль */
  @observable
    selected: boolean = false;

  /** Идентификатор роли */
  @computed get id() {
    return this.data.projectRoleId ?? '';
  }

  /** Наименование роли */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение наименования роли */
  set name(value: string) {
    this.data.name = value;
    this.validateField(
      'name',
      (val) => {
        if (this._validationInProgress && !val) {
          return ErrorMessages.requiredField;
        }
        return undefined;
      },
    );
  }

  /** Описание роли */
  @computed get description() {
    return this.data.description ?? '';
  }

  /** Изменение описания роли */
  set description(value: string) {
    this.data.description = value;
  }

  /** Набор привилегий роли */
  @computed get projectActions() {
    return this.data.projectActions ?? observable([]);
  }

  /** Изменение набора привилегий роли */
  set projectActions(value: ProjectActions[]) {
    runInAction(() => {
      this.data.projectActions = value;
    });
  }

  /** Возможность изменять ресурс на проекте */
  @computed get updateResource() {
    return this.projectActions?.includes(ProjectActions.UpdateResource);
  }

  /** Изменение изменять ресурс на проекте  */
  set updateResource(value: boolean | undefined) {
    if (this._validationInProgress) {
      return;
    }
    runInAction(() => {
      if (value) {
        this.projectActions = [...this.projectActions, ProjectActions.UpdateResource];
      } else {
        this.projectActions = this.projectActions?.filter((action) => action !== ProjectActions.UpdateResource);
      }
    });
  }

  /** Возможность редактировать проект */
  @computed get updateProject() {
    return this.projectActions?.includes(ProjectActions.UpdateProject);
  }

  /** Изменение возможности редактировать проект */
  set updateProject(value: boolean | undefined) {
    if (this._validationInProgress) {
      return;
    }
    runInAction(() => {
      if (value) {
        this.projectActions = [...this.projectActions, ProjectActions.UpdateProject];
      } else {
        this.projectActions = this.projectActions?.filter((action) => action !== ProjectActions.UpdateProject);
      }
    });
  }

  /** Возможность удалить проект */
  @computed get deleteProject() {
    return this.projectActions?.includes(ProjectActions.DeleteProject);
  }

  /** Изменение возможности удалить проек */
  set deleteProject(value: boolean | undefined) {
    if (this._validationInProgress) {
      return;
    }
    runInAction(() => {
      if (value) {
        this.projectActions = [...this.projectActions, ProjectActions.DeleteProject];
      } else {
        this.projectActions = this.projectActions?.filter((action) => action !== ProjectActions.DeleteProject);
      }
    });
  }

  /** Возможность изменить нагрузку на ресурсы проекта */
  @computed get updateProjectRequirement() {
    return this.projectActions?.includes(ProjectActions.UpdateProjectRequirement);
  }

  /** Изменение возможности изменить нагрузку на ресурсы проекта  */
  set updateProjectRequirement(value: boolean | undefined) {
    if (this._validationInProgress) {
      return;
    }
    runInAction(() => {
      if (value) {
        this.projectActions = [...this.projectActions, ProjectActions.UpdateProjectRequirement];
      } else {
        this.projectActions = this.projectActions?.filter((action) => action !== ProjectActions.UpdateProjectRequirement);
      }
    });
  }

  /** Возможность смотреть ресурс на проекте */
  @computed get readProjectRequirement() {
    return this.projectActions?.includes(ProjectActions.ReadProjectRequirement);
  }

  /** Изменение возможности смотреть ресурс на проекте */
  set readProjectRequirement(value: boolean | undefined) {
    if (this._validationInProgress) {
      return;
    }
    runInAction(() => {
      if (value) {
        this.projectActions = [...this.projectActions, ProjectActions.ReadProjectRequirement];
      } else {
        this.projectActions = this.projectActions?.filter((action) => action !== ProjectActions.ReadProjectRequirement);
      }
    });
  }

  /** Настройки уведомлений */
  @computed get notificationSettings() {
    return this.data.notificationSettings ?? [];
  }

  /** Изменения настройки уведомлений */
  set notificationSettings(value: GetProjectRolesResponseItemNotificationSettings[] | undefined) {
    runInAction(() => {
      this.data.notificationSettings = value;
    });
  }

  /** Уведомления без времени отправки */
  @computed get notificationsWithoutSendingTime(): Array<keyof typeof NotificationEvents> {
    return ['UpdateProject', 'DeleteProject', 'UpdateResource', 'DeleteResource'];
  }

  /** Настройки уведомлений */
  @computed get organizationId() {
    return this.data.organizationId ?? '';
  }
}
