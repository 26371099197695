import { Accordion, Panel, Table } from '@components/ui';
import { useConfirm, useStores } from '@hooks';
import type { RolesTableItem } from '@types';
import type { GetRolesResponseItem } from '@typesApi';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { Column, RowCheckbox, TableButtons } from '@components/ui/Table';
import { computed } from 'mobx';
import InputHeader from '@components/TableHeaders/InputHeader';
import RoleForm from '../Forms/RoleForm';
import type { RoleModalInfo } from '../types';

/** ячейка активности пользователя  */
const Checkbox: React.FC<{data: RolesTableItem}> = ({ data }) => {
  if (data.organizationId) {
    return <RowCheckbox data={data} />;
  }
  return null;
};

/** Таблица со списком ролей */
const RolesTable: React.FC = () => {
  const { rolesStore } = useStores();
  const { canCreate, canDelete } = rolesStore;

  /** Информация для модалки редактирования и добавления роли */
  const [roleModalInfo, setRoleModalInfo] = useState<RoleModalInfo>();

  /** Хук для модалки подтверждения */
  const confirm = useConfirm('Удалить выбранные роли?');

  /** Обработчик удаления ролей */
  const deleteRolesHandler = useCallback(async () => {
    const confirmed = await confirm?.();
    if (confirmed) {
      await rolesStore.delete();
    }
  }, [confirm, rolesStore]);

  useEffectOnce(() => {
    rolesStore.fetch();
    return () => {
      rolesStore.clearStore();
    };
  });

  /** Заблокирована ли кнопка Удалить */
  const isDeleteDisabled = computed(() => !rolesStore.viewModel.some((e) => e.selected)).get();

  /** Обработчик нажатия на строку таблицы */
  const onRowClickHandler = useCallback((id: string) => {
    const data = rolesStore.viewModel.find((x) => x.id === id);
    if (data) {
      setRoleModalInfo({
        roleId: data.id,
        name: data.name ?? undefined,
        description: data.description ?? undefined,
        organizationId: data.organizationId ?? undefined,
      });
    }
  }, [rolesStore.viewModel]);

  /** Хедер колонки "Название роли" */
  const roleNameHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="name"
      store={rolesStore}
    />
  ), [rolesStore]);

  return (
    <Accordion
      openedIds={['1']}
    >
      <Panel
        header="Системные роли"
        headerButtons={(
          <TableButtons
            isDeleteDisabled={isDeleteDisabled}
            name=""
            onAddClick={canCreate ? () => setRoleModalInfo({}) : undefined}
            onDeleteClick={canDelete ? () => deleteRolesHandler() : undefined}
          />
        )}
        id="1"
      >
        <Table
          onRowClick={onRowClickHandler}
          rowHeight={30}
          store={rolesStore}
        >
          {canDelete ? (
            <Column<RolesTableItem, GetRolesResponseItem>
              key="select"
              dataType="custom"
              header=""
              template={Checkbox}
              width={50}
            />
          ) : null}
          <Column
            header="Название"
            sortExpr="name"
          >
            <Column<RolesTableItem, GetRolesResponseItem>
              dataType="string"
              header={roleNameHeader}
              keyExpr="name"
              minWidth={250}
            />
          </Column>
          <Column<RolesTableItem, GetRolesResponseItem>
            dataType="string"
            header="Описание"
            keyExpr="description"
            sortable
          />
        </Table>
        {roleModalInfo && (
          <RoleForm
            onClose={() => {
              setRoleModalInfo(undefined);
            }}
            roleInfo={roleModalInfo}
          />
        )}
      </Panel>
    </Accordion>
  );
};

export default observer(RolesTable);
