import type { GetLoadResourceInProjectsResponseItemLoadResourceInProject } from '@typesApi';
import { makeObservable } from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import { PeriodViewModel } from './PeriodViewModel';

/** Стор сущности */
export class PeriodStore extends EntityBaseStore<GetLoadResourceInProjectsResponseItemLoadResourceInProject, PeriodViewModel> {
  public viewModel = new PeriodViewModel({});

  constructor(data: Partial<GetLoadResourceInProjectsResponseItemLoadResourceInProject>) {
    super();
    this.viewModel = new PeriodViewModel(data);
    makeObservable(this);
  }

  /** Фетч сущности */
  public fetch(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  public save(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
