import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams<D = any>
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: D;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

class HttpClient {
  public instance: AxiosInstance;
  public defaultFormat?: ResponseType;

  appApiUrl?: string = process.env.REACT_APP_API_URL;
  appTokenName?: string = process.env.REACT_APP_TOKEN_NAME;

  constructor() {
    const apiConnection = axios.create({ baseURL: this.appApiUrl });

    apiConnection.interceptors.request.use((config) => {
      const token = this.getToken();
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    this.instance = apiConnection;
  }

  getToken() {
    const item = window.localStorage.getItem(this.appTokenName || "token");
    return item ? this.parseJSON(item) : undefined;
  }

  private parseJSON(value: string) {
    try {
      return value === "undefined" ? undefined : JSON.parse(value !== null && value !== void 0 ? value : "");
    } catch (_a) {
      console.log("parsing error on", { value });
      return undefined;
    }
  }

  public request = async <T = any, _E = any>({
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const requestParams = params;
    const responseFormat = format || void 0;

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && { "Content-Type": type }),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

export const httpClient = new HttpClient();
