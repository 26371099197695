import { useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import type { RefObject } from 'react';
import React, {
  useCallback,
  useMemo,
  startTransition,
  useEffect,
  useState,
} from 'react';
import type { Period, UnavailablePeriod } from '@types';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { pluralize } from '@services/utils';
import type {
  GetLoadResourceInProjectsResponseItemLoadResourceInProject,
  GetResourceInProjectsResponseUnavailableResourceItem,
  GetUnavailableResourcesResponseItem,
} from '@typesApi';
import type { ResourceLoadStore } from 'src/stores/ResourceLoad/ResourceLoadStore';
import ResourceRow from '../Data/ResourceRow';
import HoveredCell from '../HoveredCell';
import { UnavailablePeriodModal, WorkingPeriodModal } from '../Modals';
import type { DayData } from '../types';
import AboutDayPopup from '../Modals/AboutDayPopup';

export interface ResourcesLoadDayData {
  index: number;
  text: string;
  total: number;
  hours: number;
  weekend: boolean;
}

export interface ResourceData {
  rowIndex: number;
  resourceName: string;
  resourceId: string;
  days: ResourcesLoadDayData[];
}

export interface DataProps {
  ganttSVGRef: RefObject<SVGElement>;
  listRef: RefObject<HTMLDivElement>;
  unavailablePeriods: GetUnavailableResourcesResponseItem[];
}

export interface ResourcesLoadCellData {
  cell: SVGElement;
  resourceInProjectId: string;
  projectName: string;
  day: ResourcesLoadDayData;
}

const Data: React.FC<DataProps> = ({ ganttSVGRef, listRef, unavailablePeriods }) => {
  const [hoveredCellData, setHoveredCellData] = useState<ResourcesLoadCellData & { rowIndex: number; shown: boolean } | null>(null);
  const [hoveredPopup, setHoveredPopup] = useState<HTMLDivElement | null>(null);
  const [shownPopupCellData, setShownPopupCellData] = useState<ResourcesLoadCellData & { rowIndex: number; shown: boolean } | null>(null);
  const [visibleRows, setVisibleRows] = useState<ResourceLoadStore['visibleResources']>([]);
  const { resourceLoadStore, privilegesStore } = useStores();
  const [
    workPeriodModalData,
    setWorkPeriodModalData,
  ] = useState<Partial<Period> & {row: number} | undefined>();
  const [
    unavailabilityPeriodModalData,
    setUnavailabilityPeriodModalData,
  ] = useState<Partial<UnavailablePeriod> | undefined>();
  const hoveredDayData = useMemo(
    () => {
      if (!shownPopupCellData) {
        return null;
      }
      const project = resourceLoadStore.viewModel.find((e) => e.resourceInProjectId === shownPopupCellData.resourceInProjectId);
      const periods = project?.loadResourceInProjects;
      const period = periods?.find((e) => {
        const startDateBeforeDay = !dayjs.utc(resourceLoadStore.startDate)
          .add(shownPopupCellData.day.index, resourceLoadStore.unitForDayJS)
          .isBefore(dayjs.utc(e.startDate), 'day');
        const finishDateAfterDay = !dayjs.utc(resourceLoadStore.startDate)
          .add(shownPopupCellData.day.index, resourceLoadStore.unitForDayJS)
          .isAfter(dayjs.utc(e.finishDate), 'day');

        return startDateBeforeDay && finishDateAfterDay;
      });
      const workingDaysOfWeek = period?.workingDaysOfWeek ?? project?.workingDaysOfWeek ?? undefined;

      if (!period) {
        return null;
      }
      const totalLoad = resourceLoadStore.projectsLoad.get(shownPopupCellData.day.index) ?? 0;

      return {
        project, period, totalLoad, day: shownPopupCellData.day, workingDaysOfWeek,
      };
    },
    [resourceLoadStore.projectsLoad, resourceLoadStore.startDate, resourceLoadStore.unitForDayJS, resourceLoadStore.viewModel, shownPopupCellData],
  );

  useEffect(() => {
    startTransition(() => {
      setVisibleRows(resourceLoadStore.visibleResources);
    });
  }, [resourceLoadStore.visibleResources]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShownPopupCellData((prev) => {
        if (hoveredPopup) {
          return prev;
        }
        if (!hoveredCellData) {
          return null;
        }
        return { ...hoveredCellData, shown: true };
      });
    }, hoveredCellData ? 400 : 200);

    return () => clearTimeout(timeout);
  }, [hoveredCellData, hoveredPopup]);

  const addResourceLoad = useCallback(
    (period: Period, row: number) => {
      resourceLoadStore.addResourceLoad(period, row);
    },
    [resourceLoadStore],
  );

  const addUnavailabilityPeriod = useCallback(
    (period: UnavailablePeriod) => {
      resourceLoadStore.addUnavailabilityPeriod(period);
    },
    [resourceLoadStore],
  );

  const removeResourceLoad = useCallback(
    (startDate: Dayjs, finishDate: Dayjs, row: number) => {
      resourceLoadStore.removeResourceLoad(startDate, finishDate, row);
    },
    [resourceLoadStore],
  );

  const hoveredCellAddUnavailabilityPeriod = useCallback(
    (startDate: Dayjs, finishDate: Dayjs) => {
      setUnavailabilityPeriodModalData({ startDate, finishDate });
    },
    [],
  );

  const changePeriod = useCallback(
    (
      y: number,
      period: GetLoadResourceInProjectsResponseItemLoadResourceInProject,
      startDate: Dayjs,
      finishDate: Dayjs,
    ) => resourceLoadStore.changePeriod(y, period, startDate, finishDate),
    [resourceLoadStore],
  );

  const changeUnavailablePeriod = useCallback(
    (y: number, period: GetResourceInProjectsResponseUnavailableResourceItem, startDate: Dayjs, finishDate: Dayjs) => {
      resourceLoadStore.changeUnavailablePeriod(y, period, startDate, finishDate);
    },
    [resourceLoadStore],
  );

  const deleteUnavailabilityPeriod = useCallback(
    (y: number, period: GetResourceInProjectsResponseUnavailableResourceItem) => {
      resourceLoadStore.deleteUnavailabilityPeriod(y, period);
    },
    [resourceLoadStore],
  );

  const editableLoadRows = useMemo(() => visibleRows.filter((e) => e.canEditWorkload).map((e) => e.rowIndex), [visibleRows]);
  const canEditUnavailablePeriod = useMemo(
    () => privilegesStore.resources.canUpdate || privilegesStore.responsibleForResources.some((e) => e.id === resourceLoadStore.id),
    [privilegesStore.resources.canUpdate, privilegesStore.responsibleForResources, resourceLoadStore.id],
  );
  const editableUnavailablePeriodRows = useMemo(
    () => (canEditUnavailablePeriod
      ? visibleRows.map((e) => e.rowIndex)
      : []),
    [canEditUnavailablePeriod, visibleRows],
  );

  return (
    <>
      <HoveredCell
        addResourceLoad={addResourceLoad}
        addUnavailabilityPeriod={hoveredCellAddUnavailabilityPeriod}
        columnWidth={resourceLoadStore.columnWidth}
        data={visibleRows}
        editableLoadRows={editableLoadRows}
        editableUnavailablePeriodRows={editableUnavailablePeriodRows}
        listRef={listRef}
        removeResourceLoad={removeResourceLoad}
        rowHeight={resourceLoadStore.rowHeight}
        scrollLeft={resourceLoadStore.scrollLeft}
        scrollTop={resourceLoadStore.scrollTop}
        startDate={resourceLoadStore.startDate}
        svgRef={ganttSVGRef}
      />
      <g>
        <g className="periods">
          {visibleRows.map((el) => (
            <g
              key={`period${el.resourceInProjectId}`}
              id={`period${el.resourceInProjectId}`}
            >
              <ResourceRow
                changePeriod={el.canEditWorkload ? changePeriod : undefined}
                changeUnavailablePeriod={el.canEditUnavailability ? changeUnavailablePeriod : undefined}
                columnWidth={resourceLoadStore.columnWidth}
                days={el.days}
                deleteUnavailabilityPeriod={el.canEditUnavailability ? deleteUnavailabilityPeriod : undefined}
                hoveredPeriodKey={`${shownPopupCellData?.rowIndex}-${hoveredDayData?.period.startDate}-${hoveredDayData?.period.finishDate}`}
                onCellHover={(e: { cell: SVGElement; day: DayData } | null): void => setHoveredCellData(e ? {
                  ...e,
                  rowIndex: el.rowIndex,
                  shown: false,
                  resourceInProjectId: el.resourceInProjectId,
                  projectName: el.projectName,
                } : null)}
                periods={el.periods}
                rowHeight={resourceLoadStore.rowHeight}
                rowIndex={el.rowIndex}
                startDate={resourceLoadStore.startDate}
                unavailableResources={unavailablePeriods}
                unitForDayJS={resourceLoadStore.unitForDayJS}
              />
            </g>
          ))}
        </g>
        {workPeriodModalData && (
          <WorkingPeriodModal
            {...workPeriodModalData}
            onClose={(e) => {
              if (e) {
                changePeriod(
                  workPeriodModalData.row,
                  {
                    ...e,
                    startDate: workPeriodModalData.startDate?.toISOString(),
                    finishDate: workPeriodModalData.finishDate?.toISOString(),
                  },
                  e.startDate,
                  e.finishDate,
                );
              }
              setWorkPeriodModalData(undefined);
            }}
            onDelete={(workPeriodModalData.startDate && workPeriodModalData.finishDate) ? () => {
              removeResourceLoad(
                workPeriodModalData.startDate!,
                workPeriodModalData.finishDate!,
                workPeriodModalData.row,
              );
              setWorkPeriodModalData(undefined);
            } : undefined}
          />
        )}
        {unavailabilityPeriodModalData && (
          <UnavailablePeriodModal
            {...unavailabilityPeriodModalData}
            onClose={(e) => {
              if (e) {
                addUnavailabilityPeriod(e);
              }
              setUnavailabilityPeriodModalData(undefined);
            }}
          />
        )}
        {!workPeriodModalData && !unavailabilityPeriodModalData
        && shownPopupCellData && shownPopupCellData.cell && shownPopupCellData.shown
        && hoveredDayData && (
          <AboutDayPopup
            fields={[
              { label: 'Название проекта', value: hoveredDayData.project?.name ?? '' },
              { label: 'Дата', value: shownPopupCellData.day.text },
              {
                label: 'Часов в этот день на проекте',
                value: String(hoveredDayData.day.hours),
                onClick: () => {
                  setWorkPeriodModalData({
                    startDate: dayjs.utc(resourceLoadStore.startDate).add(hoveredDayData.day.index, resourceLoadStore.unitForDayJS),
                    finishDate: dayjs.utc(resourceLoadStore.startDate).add(hoveredDayData.day.index, resourceLoadStore.unitForDayJS),
                    workingHoursPerDay: hoveredDayData.period.workingHoursPerDay,
                    workingDaysOfWeek: hoveredDayData.workingDaysOfWeek,
                    isWorkOnWeekends: hoveredDayData.period.isWorkOnWeekends,
                    row: shownPopupCellData.rowIndex,
                  });
                  setHoveredPopup(null);
                },
              },
              { label: 'Всего часов в этот день', value: String(hoveredDayData.totalLoad) },
              {
                label: 'Период',
                value: [
                  dayjs(hoveredDayData.period.startDate).format('DD.MM.YYYY'),
                  '-',
                  dayjs(hoveredDayData.period.finishDate).format('DD.MM.YYYY'),
                  ' по ',
                  hoveredDayData.period.workingHoursPerDay,
                  ' ',
                  pluralize(hoveredDayData.period.workingHoursPerDay!, 'часу', 'часа', 'часов'),
                ].join(''),
                onClick: () => {
                  setWorkPeriodModalData({
                    startDate: hoveredDayData.period.startDate ? dayjs.utc(hoveredDayData.period.startDate) : undefined,
                    finishDate: hoveredDayData.period.startDate ? dayjs.utc(hoveredDayData.period.finishDate) : undefined,
                    workingHoursPerDay: hoveredDayData.period.workingHoursPerDay,
                    workingDaysOfWeek: hoveredDayData.workingDaysOfWeek,
                    isWorkOnWeekends: hoveredDayData.period.isWorkOnWeekends,
                    row: shownPopupCellData.rowIndex,
                  });
                  setHoveredPopup(null);
                },
              },
            ]}
            setHoveredPopup={setHoveredPopup}
            targetRef={shownPopupCellData.cell}
          />
        )}
      </g>

    </>
  );
};

export default observer(Data);
