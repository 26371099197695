import { useStores } from '@hooks';
import { httpClient } from '@services/index';
import {
  expiresAtName,
  refreshTokenName,
  setTokenToStorage,
  tokenName,
} from '@services/utils';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import type React from 'react';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffectOnce, useReadLocalStorage } from 'usehooks-ts';

const exceptions = ['/redirect', '/registration', '/confirmEmail'];

/** Компонент работы с токенами */
const TokenComponent: React.FC = () => {
  const { accountStore } = useStores();
  const token = useReadLocalStorage<string | undefined>(tokenName);
  const refreshToken = useReadLocalStorage<string | undefined>(refreshTokenName || 'refreshToken');
  const expiresAt = useReadLocalStorage<string | undefined>(expiresAtName || 'expiresAt');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token && !exceptions.includes(location.pathname)) {
      navigate('/login');
    }
  }, [location.pathname, navigate, token, accountStore]);

  useEffect(() => {
    runInAction(() => {
      accountStore.token = token;
    });
    if (!location.pathname.includes('/registration')) {
      accountStore.fetch();
    }
  }, [location.pathname, token, accountStore]);

  useLayoutEffect(() => {
    if (expiresAt) {
      const timeout = setTimeout(async () => {
        if (!refreshToken || !await accountStore.tryRefreshToken(refreshToken)) {
          setTokenToStorage();
        }
      }, Math.max(dayjs(expiresAt).diff(dayjs()), 1));

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [refreshToken, expiresAt, accountStore]);

  useEffectOnce(() => {
    const interceptor = httpClient.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status === 401) {
          if (refreshToken && await accountStore.tryRefreshToken(refreshToken)) {
            return httpClient.instance.request(error.config);
          }
          setTokenToStorage();
          navigate('/login');
        }
        if (error.response.status === 403) {
          navigate('/accountNotActive');
        }
        if (error.response.status === 402) {
          navigate('/statusCode402');
        }
        const errors = error?.response?.data;
        throw errors;
      },
    );

    return () => {
      httpClient.instance.interceptors.response.eject(interceptor);
    };
  });

  useEffect(() => {
    if (token) {
      accountStore.fetchCurrentSubscription();
    }
  }, [token, accountStore]);

  return null;
};

export default TokenComponent;
