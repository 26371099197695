import { computed, observable } from 'mobx';
import BaseState from './BaseState';
import Status from '../../enums';
import type { ServerError } from '../../errors';

/** Состояние стора с ошибкой */
class ErrorState extends BaseState {
  @observable
  public _errorStatusCode?: number;

  @computed get errorStatusCode() {
    return this._errorStatusCode;
  }

  constructor(error?: unknown) {
    super(
      Status.Error,
      (error as ServerError)?.title ?? 'Произошла непредвиденная ошибка',
    );
    this._errorStatusCode = (error as ServerError)?.status;
  }
}

export default ErrorState;
