import EntityHeader from '@components/EntityHeader';
import EmployeesInProject from '@components/ProjectBlocks/EmployeesInProject';
import ProjectLoads from '@components/ProjectBlocks/ProjectLoads';
import ProjectMainInfo from '@components/ProjectBlocks/ProjectMainInfo';
import ResourcesInProject from '@components/ProjectBlocks/ResourcesInProject';
import Errors from '@components/ui/Errors/Errors';
import { useStores, useTitle } from '@hooks';
import { joinNonEmpty } from '@services/utils';
import { ResourceTypes } from '@typesApi';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

/** Карточка проекта */
const Project: React.FC = () => {
  /** Идентификатор открытого проекта */
  const { id } = useParams();

  const {
    projectStore, projectLoadStore,
  } = useStores();

  /** Модель проекта */
  const { viewModel } = projectStore;

  useTitle(viewModel.name ?? 'Проект');

  useEffectOnce(() => {
    runInAction(() => {
      projectLoadStore.filter.resourceTypes = [ResourceTypes.Employee];
    });
    if (id) {
      projectStore.fetch(id);
      runInAction(() => {
        projectLoadStore.id = id;
        projectLoadStore.startDate = dayjs.utc().startOf('day').toDate();
      });
    }
    return () => projectStore.clearStore();
  });

  return (
    <div className="h-full w-full flex flex-col">
      <EntityHeader
        header={joinNonEmpty(['Проект: ', viewModel.name, viewModel?.isDeleted ? ' (Архивный)' : null])}
        route="/projects"
      />
      <div className="flex flex-col gap-4 pb-4">
        <div className="flex flex-col gap-4 w-full @7xl/main:flex-row">
          <ProjectMainInfo />
          <ResourcesInProject />
        </div>
        <EmployeesInProject />
        <ProjectLoads />
        <Errors store={projectStore} />
      </div>
    </div>
  );
};

export default observer(Project);
