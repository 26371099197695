/* eslint-disable global-require */

const headerHeight = 65;

module.exports = {
  presets: [
    require('./components/ui/Input/input.config'),
    require('./components/ui/Button/button.config'),
    require('./components/LeftMenu/LeftMenu.config'),
    require('./components/ui/Select/select.config'),
    require('./components/ui/Table/table.config'),
    require('./components/ui/Accordion/accordion.config'),
    require('./components/CalendarTable/graph.config'),
    require('./components/Subscriptions/subscriptionCard.config'),
  ],
  safelist: [
    'bg-gray-300',
  ],
  theme: {
    extend: {
      height: {
        header: `${headerHeight}px`,
      },
      spacing: {
        header: `${headerHeight}px`,
      },
      minHeight: {
        header: `${headerHeight}px`,
      },
      colors: {
        error: '#B1272E',
        main: '#E97224',
        disabled: '#B1ADAB',
        label: '#616161',
        background: '#F8F9F9',
        headerHovered: 'rgba(255,255,255,0.3)',
        'page-header': 'black',
        'card-header': 'black',
        'font-main-bold': '#616161',
        'main-menu-bg': '#7F6D5D',
      },
      backgroundImage: {
        // eslint-disable-next-line max-len
        'gold-gradient': 'radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 4%, #b98c2d 30%, #a78538 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #a07f36 62.5%, #cb900c 100%)',
      },
      borderRadius: {
        main: '8px',
        secondary: '4px',
      },
      borderWidth: {
        1: '1px',
      },
      boxShadow: {
        card: '0px 4px 16px rgba(0, 0, 0, 0.12)',
      },
      fontSize: {
        'font-main': ['14px', {
          lineHeight: '18px',
          fontWeight: '600',
        }],
        'font-main-bold': ['14px', {
          lineHeight: '18px',
          fontWeight: '700',
        }],
        'font-header': ['24.5px', {
          lineHeight: '30px',
          fontWeight: '600',
        }],
        'font-sub-header': ['15.75px', {
          lineHeight: '24.5px',
          fontWeight: '700',
        }],
        label: ['12px', {
          fontWeight: 700,
        }],
        'page-header': ['24.5px', {
          lineHeight: '30px',
          fontWeight: '600',
        }],
        'card-header': ['15.75px', {
          lineHeight: '24.5px',
          fontWeight: '800',
        }],
      },
      gap: {
        card: '16px',
        form: '8px',
      },
      containers: {
        '8xl': '1300px',
      },
    },
  },
};
