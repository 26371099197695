import { ContragentService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetContragentsResponseItem } from '@typesApi';
import { makeObservable, observable } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import { CounterpartiesItemViewModel } from './CounterpartiesItemViewModel';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';

interface CounterpartiesFilter extends ListBaseFilter {
  filterName?: string;
  isDeleted?: boolean;
}

export type GetCounterpartiesResponseItemWithRequiredId = Required<Pick<GetContragentsResponseItem, 'id'>> & Omit<GetContragentsResponseItem, 'id'>

/** Стор реестра */
export class CounterpartiesStore extends BaseEntityListStore<
GetCounterpartiesResponseItemWithRequiredId,
CounterpartiesFilter,
CounterpartiesItemViewModel> {
  @observable private _privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.counterparties.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.counterparties.canDelete;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.counterparties.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.counterparties.canRead;
  }

  /** Получение списка */
  public async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ContragentService.list(this.filter);
      data.entities = data.entities?.map<GetCounterpartiesResponseItemWithRequiredId>((el) => ({ ...el, id: el.id ?? '' }));
      this.setData(data as {
        entities: GetCounterpartiesResponseItemWithRequiredId[];
        totalCount: number;
      }, CounterpartiesItemViewModel);
    });
  }

  /** Архивация выбранных контрагентов */
  public async archiveSelectedCounterparties(): Promise<void> {
    await this.runWithStateControl(async () => {
      await Promise.all(this.viewModel.filter((e) => e.selected).map((e) => ContragentService.delete(e.id, {
        deleteRightNow: true,
      })));
    });
    if (this.state.isSuccess) {
      await this.fetch();
    }
  }

  /** Восстановление выбранных контрагентов */
  public async restoreSelectedCounterparties(): Promise<void> {
    await this.runWithStateControl(async () => {
      await Promise.all(this.viewModel.filter((e) => e.selected).map((e) => ContragentService.delete(e.id, {})));
    });
    if (this.state.isSuccess) {
      await this.fetch();
    }
  }
}
