import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useConfirm, useInterceptChanges, useStores } from '@hooks';
import DrawerWithWrapper from '@components/DrawerResourceLayout/DrawerWithWrapper';
import { useEffectOnce } from 'usehooks-ts';
import PasswordFormInput from '@components/ui/Input/PasswordFormInput';
import Button from '@components/ui/Button';
import { ConfirmQuestions } from 'src/enums';

/** Форма изменения пароля */
const PasswordChangeForm: React.FC<{onClose: () => void}> = ({ onClose }) => {
  const { accountStore } = useStores();

  const { viewModel, isDirty } = useInterceptChanges(accountStore.passwordChangeViewModel);
  /** Модалка подтверждения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  /** Хэндлер обработки подтверждения или отмены закрытия модалки */
  const confirmHandler = useMemo(() => {
    if (isDirty) {
      return confirm;
    }
    return undefined;
  }, [confirm, isDirty]);

  useEffectOnce(() => () => viewModel.clear());

  /** Сохранение изменений */
  const saveHandler = useCallback(async () => {
    viewModel.validate();

    if (!viewModel.validate()) {
      return;
    }
    await accountStore.changePassword();
    if (accountStore.state.isSuccess) {
      onClose();
    }
  }, [viewModel, accountStore, onClose]);

  /** Кнопки футера модалки*/
  const footer = useMemo(() => (
    <Button.Primary
      isLoading={accountStore.state.isLoading}
      label="Сохранить"
      onClick={saveHandler}
    />
  ), [saveHandler, accountStore.state.isLoading]);

  return (
    <DrawerWithWrapper
      confirm={confirmHandler}
      footer={footer}
      onClose={onClose}
      title="Изменение пароля"
    >
      <div className="flex flex-col gap-2">
        <PasswordFormInput
          error={viewModel.errors.currentPassword}
          label="Старый пароль"
          onChange={(e) => {
            viewModel.currentPassword = e.target.value;
          }}
          placeholder="Введите старый пароль"
          type="password"
          value={viewModel.currentPassword}
        />
        <PasswordFormInput
          error={viewModel.errors.newPassword}
          label="Новый пароль"
          onChange={(e) => {
            viewModel.newPassword = e.target.value;
          }}
          placeholder="Введите новый пароль"
          type="password"
          value={viewModel.newPassword}
        />
        <PasswordFormInput
          error={viewModel.errors.confirmPassword}
          label="Подтверждение"
          onChange={(e) => {
            viewModel.confirmPassword = e.target.value;
          }}
          placeholder="Введите новый пароль повторно"
          type="password"
          value={viewModel.confirmPassword}
        />
      </div>
    </DrawerWithWrapper>
  );
};

export default observer(PasswordChangeForm);
