import { observer } from 'mobx-react-lite';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ResourceProjectsListHeader } from './ResourceProjectsListHeader';
import ResourceProjectsListTable from './ResourceProjectsListTable';

export type ResourceProjectsListProps = {
  headerHeight: number;
  rowWidth: string;
  gridHeight: number;
  resourcesListRef: React.RefObject<HTMLDivElement>;
  horizontalContainerRef: React.RefObject<HTMLDivElement>;
  horizontalContainerClass?: string;
};

const ResourceProjectsList = forwardRef<HTMLDivElement, ResourceProjectsListProps>(({
  headerHeight,
  rowWidth,
  gridHeight,
  resourcesListRef,
  horizontalContainerRef,
  horizontalContainerClass,
}, ref) => {
  const listRef = useRef<HTMLDivElement>(null);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => listRef.current);
  return (
    <div ref={resourcesListRef}>
      <ResourceProjectsListHeader
        headerHeight={headerHeight}
        rowWidth={rowWidth}
      />
      <div
        ref={horizontalContainerRef}
        className={horizontalContainerClass}
        style={gridHeight ? { height: gridHeight } : {}}
      >
        <ResourceProjectsListTable
          ref={listRef}
          rowWidth={rowWidth}
        />
      </div>
    </div>
  );
});

ResourceProjectsList.displayName = 'ResourceProjectsList';

export default observer(ResourceProjectsList);
