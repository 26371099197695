import { parseStyles } from '@services/utils';
import React from 'react';
import type { SpinnerProps } from './types';

/** Компонент загрузки */
const Spinner: React.FC<SpinnerProps> = ({ className, width = 20, height = 20 }) => (
  <div
    className={parseStyles`
      animate-spin border-r-2 border-1 bg-transparent
      border-transparent rounded-full border-r-slate-300
      ${className}  
    `}
    style={{
      width,
      height,
    }}
  />
);

export default React.memo(Spinner);
