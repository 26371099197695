import React, { useCallback, useRef, useState } from 'react';
import Modal from '@components/Modal/Modal';
import AvatarEditor from 'react-avatar-editor';
import Button from '@components/ui/Button';
import { chooseFile, MAX_FILE_SIZE } from '@services/utils';
import Label from '@components/ui/Label/Label';
import { Close, User } from '@assets';
import type { AvatarEditProps } from './types';

/** Модалка выбора и редактирования аватарки */
const AvatarEditModal: React.FC<AvatarEditProps> = ({ onClose, file, setAvatar }) => {
  const ref = useRef<AvatarEditor>(null);
  /** url картинки */
  const [src, setSrc] = useState(file);
  /** Размер картинки */
  const [scale, setScale] = useState(1);
  /** Поворот по оси z */
  const [rotate, setRotate] = useState(0);
  /** Ошибка размера изображения */
  const [imageSizeError, setImageSizeError] = useState<string>();

  /** Вернуть изначальные настройки редактора */
  const resetHandler = useCallback(() => {
    setScale(1);
    setRotate(0);
  }, []);

  /** Удалить картинку */
  const deleteHandler = useCallback(() => {
    setImageSizeError(undefined);
    setSrc(undefined);
    resetHandler();
  }, [resetHandler]);

  /** Сохранить изменения */
  const saveHandler = useCallback(async () => {
    if (!src) {
      setAvatar();
      onClose();
      resetHandler();
      return;
    }

    try {
      if (ref.current) {
        const canvas = ref.current.getImage();
        canvas.toBlob((blob) => {
          if (blob) {
            const resultFile = new File([blob], 'avatar', { type: 'image/jpeg' });
            setAvatar(resultFile);
          }
        }, 'image/jpeg');
      }
      onClose();
    } catch (error) {
      console.warn(error);
    }
  }, [onClose, resetHandler, setAvatar, src]);

  /** Изменить картинку */
  const changeImageHandler = useCallback(() => chooseFile((f) => {
    if (f.size > MAX_FILE_SIZE) {
      setImageSizeError('Размер изображения не должен превышать 10мб');
      return;
    }
    setImageSizeError(undefined);
    setSrc(URL.createObjectURL(f));
  }, ['image/png', 'image/gif', 'image/jpeg']), []);

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col">
        <Close
          className="self-end cursor-pointer"
          onClick={onClose}
        />
        <div className="mt-4 flex justify-center">
          {src ? (
            <AvatarEditor
              ref={ref}
              border={0}
              borderRadius={200}
              color={[0, 0, 0, 0.4]}
              crossOrigin="anonymous"
              height={300}
              image={src}
              rotate={rotate}
              scale={scale}
              style={{ borderRadius: '5px 5px 0 0' }}
              width={300}
            />
          ) : (
            <User
              className="h-[300px] w-[300px]"
              color="gray"
            />
          )}
        </div>
        <Button.Primary
          className="rounded-t-none"
          label="Выбрать изображение"
          onClick={changeImageHandler}
        />
        {imageSizeError && <div className="text-[11px] text-center font-bold mt-1 text-red-500">{imageSizeError}</div>}
        <div className="mt-8 mb-8 flex flex-col gap-form">
          <Label>Масштабирование</Label>
          <input
            className="range accent-main"
            disabled={!src}
            max={5}
            min={0.5}
            name="scale"
            onChange={(e) => setScale(+e.target.value)}
            step={0.01}
            type="range"
            value={scale}
          />
          <Label>Угол</Label>
          <input
            className="range accent-main"
            disabled={!src}
            max={360}
            min={0}
            name="rotate"
            onChange={(e) => setRotate(+e.target.value)}
            step={0.1}
            type="range"
            value={rotate}
          />
        </div>
        <div className="flex justify-between gap-form">
          {src && (
            <Button.Primary
              label="Удалить файл"
              onClick={deleteHandler}
              type="danger"
            />
          )}
          <Button.Primary
            className="ml-auto"
            label="Сохранить"
            onClick={saveHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AvatarEditModal;
