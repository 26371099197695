import { ProjectService } from '@servicesApi';
import type { GetProjectNamesResponseItem } from '@typesApi';
import { makeAutoObservable, runInAction } from 'mobx';

class ProjectNamesStore {
  namesList?: GetProjectNamesResponseItem[] | null;

  constructor() {
    makeAutoObservable(this);
  }

  public async fetchProjectsNames(search?: string, excludedEmployeeId?: string) {
    const { data } = await ProjectService.namesList({ filterName: search, excludedEmployeeId });
    runInAction(() => {
      this.namesList = data.entities;
    });
  }
}

export default ProjectNamesStore;
