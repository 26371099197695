import { useState } from 'react';
import type BaseViewModel from 'src/stores/BaseViewModel';

/** Устанавливает перехватчик события set на стор */
export const useInterceptChanges = <T, V extends BaseViewModel<T>, P extends V | V[]>(object: P): {
  viewModel: P;
  isDirty: boolean;
  resetIsDirty: () => void;
} => {
  const [isDirty, setIsDirty] = useState(false);

  /** Обнуляет флаг isDirty */
  const resetIsDirty = () => setIsDirty(false);

  /** Перехват сеттера */
  const changeHandler = <K extends (keyof V & string & Symbol), >(model: V, prop: K, value: V[K]) => {
    /** Если есть значение и оно не равно предыдущему значению */
    if (prop !== '_validationInProgress' && model[prop] !== value) {
      setIsDirty(true);
    }
    model[prop] = value;
    return true;
  };

  if (Array.isArray(object)) {
    return {
      viewModel: object.map((e) => new Proxy(e, {
        set: changeHandler,
      })) as typeof object,
      isDirty,
      resetIsDirty,
    };
  }

  return {
    viewModel: new Proxy(object as V, {
      set: changeHandler,
    }) as typeof object,
    isDirty,
    resetIsDirty,
  };
};
