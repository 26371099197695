import {
GetProjectRequirementPlaceByIdResponse,
GetProjectRequirementPlacesResponse,
PostProjectRequirementPlaceRequest,
PostProjectRequirementPlaceResponse,
ProblemDetails,
ProblemDetailsResponse,
PutProjectRequirementPlaceByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class ProjectRequirementPlaceService {
  static get RoutePath(): string {
    return "ProjectRequirementPlace";
  }

  /**
   * Получение потребности проекта в помещении по ид
   *
   * @request GET:/api/v1.0/ProjectRequirementPlace/{id}
   */
  static detail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetProjectRequirementPlaceByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Обновление потребности проекта в помещении
   *
   * @request PUT:/api/v1.0/ProjectRequirementPlace/{id}
   */
  static update = async (id: string, data: PutProjectRequirementPlaceByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка потребностей проектов в помещении
   *
   * @request GET:/api/v1.0/ProjectRequirementPlace
   */
  static list = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      appointments?: string[];
      minTotalCapacity?: number;
      maxTotalCapacity?: number;
      minNumberOfSeats?: number;
      maxNumberOfSeats?: number;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetProjectRequirementPlacesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Создание потребности проекта в помещении
   *
   * @request POST:/api/v1.0/ProjectRequirementPlace
   */
  static create = async (data: PostProjectRequirementPlaceRequest, params: RequestParams = {}) =>
    httpClient.request<PostProjectRequirementPlaceResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + ``,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}