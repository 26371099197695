import type { TableItemView } from '@types';
import type { GetSalaryPeriodsResponseItem } from '@typesHr';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, makeObservable, runInAction } from 'mobx';
import BaseViewModel from '../BaseViewModel';

export interface SalaryFields {
  id: string;
  salary: number | undefined;
  workingRate: number | undefined;
  startDate: Dayjs | undefined;
  finishDate: Dayjs | undefined;
  description: string | undefined;
}

/** Вью модель элемента списка */
export class SalaryPeriodsItemViewModel extends BaseViewModel<GetSalaryPeriodsResponseItem> implements TableItemView<
SalaryFields,
GetSalaryPeriodsResponseItem
> {
  constructor(data: GetSalaryPeriodsResponseItem) {
    super(data);
    makeObservable(this);
  }

  /** id */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Изменение id */
  set id(value: string) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** Получение "Оклад" */
  @computed get salary() {
    return this.data.salary ?? undefined;
  }

  /** Изменение "Оклад"  */
  set salary(value: number | undefined) {
    runInAction(() => {
      this.data.salary = value;
    });
  }

  /** Ставка на проекте */
  @computed get workingRate() {
    return this.data.workingRate ?? undefined;
  }

  /** Изменение Ставка на проекте */
  set workingRate(value: number | undefined) {
    runInAction(() => {
      this.data.workingRate = value;
    });
  }

  /** Получение "Дата начала периода" */
  @computed get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : undefined;
  }

  /** Изменение "Дата начала периода"  */
  set startDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.startDate = value?.toISOString();
    });
  }

  /** Получение "Дата окончания периода" */
  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : undefined;
  }

  /** Изменение "Дата окончания периода"  */
  set finishDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.finishDate = value?.toISOString();
    });
  }

  /** Получение "Описание" */
  @computed get description() {
    return this.data.description ?? undefined;
  }

  /** Изменение ""  */
  set description(value: string | undefined) {
    runInAction(() => {
      this.data.description = value;
    });
  }
}
