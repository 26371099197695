import DrawerWithWrapper from '@components/DrawerResourceLayout/DrawerWithWrapper';
import Button from '@components/ui/Button';
import Errors from '@components/ui/Errors/Errors';
import type { SelectOption } from '@components/ui/Select';
import { Select } from '@components/ui/Select';
import { useConfirm, useStores } from '@hooks';
import { ResourceTypes } from '@typesApi';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ConfirmQuestions, Resources } from 'src/enums';
import CreateEquipmentForm from './CreateEquipmentForm';
import CreatePlaceForm from './CreatePlaceForm';

/** Типы ресурсов для селекта без сотрудников */
const resourceTypesForSelect = Object.entries(Resources)
  .map(([value, label]) => ({ label, value }))
  .filter((option) => option.value !== 'Employee');

/** Форма добавления ресурса на проект */
const AddResourceForm: React.FC<{onClose: () => void}> = ({ onClose }) => {
  /** Сторы: ресурсы в проекте, помещений, техники */
  const {
    placeStore,
    equipmentStore,
    placeNamesStore,
    equipmentsStore,
    projectStore,
    resourcesInProjectStore,
  } = useStores();
  /** Отображение формы создания нового ресурса */
  const [showCreateResourceForm, setShowCreateResourceForm] = useState(false);
  /** Выбранный тип ресурса */
  const [selectedType, setSelectedType] = useState<SelectOption>();
  /** Выбранный ресурс (котрый будет добавлен в проект) */
  const [selectedResource, setSelectedResource] = useState<SelectOption>();
  /** Наличие изменений в форме создания ресурса */
  const [createFormIsDirty, setCreateFormIsDirty] = useState(false);
  /** Модалка подтверждения закрытия */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  /** Обработчик закрытия модалки добавления ресурса */
  const confirmHandler = useMemo(() => {
    if (createFormIsDirty) {
      return confirm;
    }
    return undefined;
  }, [confirm, createFormIsDirty]);

  /** Условия отображения формы создания техники */
  const canShowEquipmentForm = useMemo(
    () => showCreateResourceForm && selectedType?.value === ResourceTypes.Equipment,
    [selectedType?.value, showCreateResourceForm],
  );

  /** Условия отображения формы создания помещения */
  const canShowPlaceForm = useMemo(
    () => showCreateResourceForm && selectedType?.value === ResourceTypes.Place,
    [selectedType?.value, showCreateResourceForm],
  );

  /** Возможность добавить ресурс */
  const canAddResource = useMemo(
    () => selectedType?.value && selectedResource?.value,
    [selectedResource?.value, selectedType?.value],
  );

  /** Текущий стор создания ресурса */
  const currentResourceStore = useMemo(() => (selectedType?.value === ResourceTypes.Equipment
    ? equipmentStore
    : placeStore), [equipmentStore, placeStore, selectedType?.value]);

  /** Изменить тип ресурса */
  const setSelectedResourceTypeHandler = useCallback((option?: SelectOption) => {
    setSelectedType({ label: option?.label ?? '', value: option?.value ?? '' });
  }, []);

  /** Поиск ресурса */
  const searchResourceHandler = useCallback(async (search?: string) => {
    let result: SelectOption[] | undefined;

    if (selectedType) {
      if (selectedType?.value === ResourceTypes.Equipment) {
        const data = await equipmentsStore.fetchNames(search, true);
        result = data?.map((name) => ({ label: name.name ?? '', value: name.id ?? '' }));
      } else {
        const data = await placeNamesStore.fetchNames(search, true);
        result = data?.map((name) => ({ label: name.name ?? '', value: name.id ?? '' }));
      }
    }
    return result;
  }, [equipmentsStore, placeNamesStore, selectedType]);

  /** Добавление ресурса на проект */
  const addResourceInProjectHandler = useCallback(async () => {
    if (selectedResource?.value && projectStore.viewModel.id) {
      await projectStore.addResource(selectedResource.value);
      await resourcesInProjectStore.fetch();
      onClose();
    }
  }, [onClose, projectStore, resourcesInProjectStore, selectedResource?.value]);

  /** Обработчик создания нового ресурса */
  const createResourceHandler = useCallback(async () => {
    let createdResourceId: string | undefined;

    if (currentResourceStore.viewModel.validate()) {
      createdResourceId = await currentResourceStore.create();
      if (currentResourceStore.state.isSuccess && createdResourceId) {
        await currentResourceStore.fetch(createdResourceId);
        setSelectedResource({ label: currentResourceStore.viewModel.name ?? '', value: currentResourceStore.viewModel.id ?? '' });
        setShowCreateResourceForm(false);
      }
    }
  }, [currentResourceStore]);

  /** Очистка стора и изменений при unmount и при изменении стора  */
  useEffect(() => {
    setCreateFormIsDirty(false);
    return () => {
      equipmentStore.clearStore();
      placeStore.clearStore();
      setCreateFormIsDirty(false);
    };
  }, [currentResourceStore, equipmentStore, placeStore, showCreateResourceForm]);

  /** Кнопки футера модалки */
  const footer = useMemo(() => (
    <div className="flex gap-2">
      {showCreateResourceForm && (
        <Button.Primary
          label="Отменить"
          onClick={() => setShowCreateResourceForm(false)}
          type="outline"
        />
      )}
      {showCreateResourceForm
        ? (
          <Button.Primary
            isLoading={currentResourceStore.state.isLoading}
            label="Создать"
            onClick={createResourceHandler}
          />
        )
        : (
          <Button.Primary
            isDisabled={!canAddResource}
            label="Добавить на проект"
            onClick={addResourceInProjectHandler}
          />
        )}
    </div>
  ), [addResourceInProjectHandler, createResourceHandler, currentResourceStore.state.isLoading, showCreateResourceForm, canAddResource]);

  return (
    <DrawerWithWrapper
      confirm={confirmHandler}
      footer={footer}
      onClose={onClose}
      title="Добавление ресурса на проект"
    >
      <div className="flex flex-col gap-form">
        <Select.AutoComplete
          label="Тип ресурса"
          onSelect={setSelectedResourceTypeHandler}
          options={resourceTypesForSelect}
          placeholder="Выберите тип ресурса"
          selectedOption={selectedType}
        />

        {!showCreateResourceForm && (
          <>
            <Select.Async
              disabled={!selectedType}
              label="Ресурс"
              onChange={searchResourceHandler}
              onSelect={setSelectedResource}
              placeholder={selectedType ? 'Начните вводить название ресурса' : 'Необходимо выбрать тип ресурса'}
              selectedOption={selectedResource}
            />
            {projectStore.canCreate && (
              <Button.Outline
                className="self-end mt-2"
                isDisabled={!selectedType}
                label={!selectedType ? 'Создать новый ресурс (необходимо выбрать тип ресурса)' : 'Создать новый ресурс'}
                onClick={() => setShowCreateResourceForm(true)}
              />
            )}
          </>
        )}
        {canShowEquipmentForm && (<CreateEquipmentForm changeDirty={() => setCreateFormIsDirty(true)} />)}
        {canShowPlaceForm && (<CreatePlaceForm changeDirty={() => setCreateFormIsDirty(true)} />)}
        <Errors store={currentResourceStore} />
      </div>
    </DrawerWithWrapper>
  );
};

export default observer(AddResourceForm);
