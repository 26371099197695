import type { GetOrganizationByIdResponse } from '@typesApi';
import { OrganizationService, FileService } from '@servicesApi';
import { action, makeObservable, runInAction } from 'mobx';
import EntityBaseStore from '../BaseEntityStore';
import { OrganizationViewModel } from './OrganizationViewModel';

/** Стор организации */
export class OrganizationStore extends EntityBaseStore<GetOrganizationByIdResponse, OrganizationViewModel> {
  constructor() {
    super();
    makeObservable(this);
  }

  /** Вью модель организации */
  public viewModel = new OrganizationViewModel({});

  /** Получение данных об организации по id */
  public override async fetch(id: string): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await OrganizationService.detail(id);
      runInAction(() => {
        this.viewModel = new OrganizationViewModel(data);
      });
    });
  }

  /** Сохранение изменённых данных организации */
  @action
  public async save(): Promise<void> {
    await this.runWithStateControl(async () => {
      if (this.viewModel.id) {
        await OrganizationService.update(this.viewModel.id, this.viewModel.getRawData());
      }
    });
  }

  /** Загрузка нового логотипа организации */
  public async uploadAvatar(file?: File): Promise<void> {
    if (!file) {
      runInAction(() => {
        this.viewModel.logoId = undefined;
      });
      return;
    }
    await this.runWithStateControl(async () => {
      const formData = new FormData();
      formData.append('files', file);
      // @ts-ignore
      const { data } = await FileService.create(formData);
      const newImageId = Object.values(data.fileNameToFileId || [])[0];
      this.viewModel.logoId = newImageId;
    });
  }
}
