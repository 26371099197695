import { useStores, useTitle } from '@hooks';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useToggle } from 'usehooks-ts';
import { User } from '@assets';
import { useNavigate } from 'react-router-dom';
import Errors from '@components/ui/Errors/Errors';
import { getListErrors, parseStyles, setTokenToStorage } from '@services/utils';
import PasswordChangeForm from '@components/Profile/PasswordChangeForm';
import { Modal } from '@components/index';
import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import Label from '@components/ui/Label/Label';
import Image from '@components/ui/Image/Image';
import Card from '@components/ui/Card/Card';
import { BsPencilSquare } from 'react-icons/bs';
import FormGrid from '@components/ui/Form/FormGrid';
import EntityHeader from '@components/EntityHeader';
import Table from '@components/ui/Table/Table';
import { TableButtons, Column, RowCheckbox } from '@components/ui/Table';
import type { AccountRolesTableItem } from '@types';
import type { GetAccountByIdResponseEmployeeRoleItem } from '@typesApi';
import DrawerWithWrapper from '@components/DrawerResourceLayout/DrawerWithWrapper';
import type { SelectOption } from '@components/ui/Select';
import { Select } from '@components/ui/Select';
import { runInAction } from 'mobx';
import AvatarEditModal from '@components/Profile/AvatarEditModal';
import Dropdown from '@components/ui/Dropdown/Dropdown';
import type { DropdownButton } from '@components/ui/Dropdown/types';
import { ImExit } from 'react-icons/im';

/** ячейка активности пользователя  */
const Checkbox: React.FC<{data: AccountRolesTableItem}> = ({ data }) => <RowCheckbox data={data} />;

/** Страница Личного кабинета пользователя */
const Profile: React.FC = () => {
  useTitle('Личный кабинет');
  const { accountStore, rolesStore, accountsStore } = useStores();
  const [showEmailChangeInfo, setShowEmailChangeInfo] = useState<boolean>(false);
  const [roleInfo, setRoleInfo] = useState<{role?: SelectOption} | undefined>(undefined);

  /** Состояние режима редактирования */
  const [isEditMode, toggle] = useToggle(false);

  /** Модалка редактирования автарки */
  const [showAvatarEditModal, toggleAvatarEditModal] = useToggle();

  /** Условие отображение модалки изменения пароля */
  const [showPasswordChangeModal, togglePasswordChangeModal] = useToggle();

  /** Вью модель Личного кабинета */
  const { profileViewModel } = accountStore;

  const navigate = useNavigate();

  /** Загрузка на сервер новой аватарки */
  useEffect(() => () => {
    accountStore.fetch();
  }, [accountStore]);

  /** Обработчик кнопки Сохранить */
  const onSaveClick = useCallback(async () => {
    const isValid = profileViewModel.validate();
    if (isEditMode && isValid) {
      await accountStore.save();
      if (accountStore.state.isSuccess && profileViewModel.email !== profileViewModel.confirmedEmail) {
        setShowEmailChangeInfo(true);
      }
      await accountStore.fetch();
    }
    if (isValid || !isEditMode) {
      toggle();
    }
  }, [isEditMode, accountStore, toggle, profileViewModel]);

  /** Обработчик кнопки Отменить */
  const onBackClick = useCallback(() => {
    toggle();
    accountStore.fetch();
  }, [accountStore, toggle]);

  const onDrawerClose = useCallback(
    () => {
      setRoleInfo(undefined);
    },
    [],
  );

  const deleteHandler = useCallback(
    () => {
      accountStore.deleteRoles();
    },
    [accountStore],
  );

  const onRoleChangeHandler = useCallback(
    async (value?: string | undefined) => {
      runInAction(() => {
        rolesStore.filter.name = value;
      });
      await rolesStore.fetch();
      return rolesStore.rolesForSelect;
    },
    [rolesStore],
  );

  /** Кнопки футера для drawer */
  const footer = useMemo(() => {
    /** Сохранение изменений */
    const saveHandler = async () => {
      if (roleInfo?.role?.value) {
        await accountStore.addRole(roleInfo.role.value);
      }
      await accountStore.save();
      if (accountStore.state.isSuccess) {
        onDrawerClose();
        accountStore.fetch();
      }
    };

    return (
      <Button.Primary
        isLoading={accountStore.state.isLoading}
        label="Сохранить"
        onClick={saveHandler}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountStore, onDrawerClose, roleInfo?.role?.value, accountStore.state.isLoading]);

  const dropdownButtons = useMemo<DropdownButton[]>(() => [
    {
      label: 'Изменить пароль',
      onClick: togglePasswordChangeModal,
      id: 'changePassword',
    },
    {
      id: 'goToCard',
      label: 'Перейти к карточке сотрудника',
      onClick: () => {
        navigate(`/resources/employees/${profileViewModel.employeeId}`);
      },
    },
  ], [navigate, profileViewModel.employeeId, togglePasswordChangeModal]);

  const defaultDropdownButton = useMemo<DropdownButton>(() => ({
    isLoading: accountStore.state.isLoading,
    label: isEditMode ? 'Сохранить' : 'Редактировать',
    id: 'edit',
    onClick: onSaveClick,
  }), [accountStore.state.isLoading, isEditMode, onSaveClick]);

  return (
    <div className="flex flex-col grow-0 w-full h-full overflow-auto">
      <EntityHeader
        header="Личный кабинет"
      />
      <div className="flex flex-col gap-4 w-full @8xl/main:flex-row">
        <Card
          headerButtons={(
            <>
              <Dropdown
                buttons={dropdownButtons}
                defaultButton={defaultDropdownButton}
              />
              {isEditMode && (
                <Button.Primary
                  label="Отменить"
                  onClick={onBackClick}
                  type="outline"
                />
              )}
              <Button.Primary
                icon={<ImExit />}
                label="Выйти из уч. записи"
                onClick={() => setTokenToStorage()}
                type="outline"
              />
            </>
          )}
          headerLabel="Общая информация"
        >
          <div className="flex gap-10 flex-col md:flex-row">
            <div className="self-center relative w-44">
              {isEditMode && (
                <div
                  className={parseStyles`
                    absolute left-0 right-0 bottom-0 w-full h-8 m-auto bg-white bg-opacity-40 
                    hover:bg-opacity-50 active:bg-opacity-60 cursor-pointer 
                  `}
                  onClick={toggleAvatarEditModal}
                >
                  <BsPencilSquare
                    className={parseStyles`
                      absolute left-0 right-0 bottom-1.5 w-full m-auto active:scale-110
                    `}
                    size={20}
                  />
                </div>
              )}
              {profileViewModel.avatarLink ? (
                <Image
                  alt="profilePicture"
                  className="self-center rounded-full object-cover h-44 w-44"
                  imageLink={profileViewModel.avatarLink!}
                />
              ) : (
                <User
                  className="h-44 w-44"
                  color="gray"
                />
              )}
            </div>
            <FormGrid
              className="flex-grow h-fit"
              maxColumnsNumber={2}
            >
              <FormInput
                label="Фамилия"
                value={profileViewModel.surname}
                disabled
              />
              <FormInput
                label="Имя"
                value={profileViewModel.name}
                disabled
              />
              <FormInput
                label="Отчество"
                value={profileViewModel.patronymic}
                disabled
              />
              <FormInput
                disabled={!isEditMode}
                error={profileViewModel.errors.email}
                label="Email"
                onChange={(event) => {
                  profileViewModel.email = event.target.value;
                }}
                type="email"
                value={profileViewModel.email}
              />
              <FormInput
                disabled={!isEditMode}
                error={profileViewModel.errors.phone}
                label="Номер телефона"
                mask="+7(___)-___-__-__"
                onChange={(event) => {
                  profileViewModel.phone = event.target.value;
                }}
                value={profileViewModel.phone}
              />
            </FormGrid>
          </div>
        </Card>
        <Card
          className="2xl:max-w-[40%]"
          headerButtons={(
            <TableButtons
              isDeleteDisabled={profileViewModel.employeeRoles.viewModel.every((e) => !e.selected)}
              name="роль"
              onAddClick={accountsStore.canUpdate ? () => setRoleInfo({}) : undefined}
              onDeleteClick={accountsStore.canUpdate ? () => deleteHandler() : undefined}
            />
          )}
          headerLabel="Системные роли"
        >
          <Table
            rowHeight={25}
            store={profileViewModel.employeeRoles}
          >
            {accountsStore.canUpdate ? (
              <Column<AccountRolesTableItem, GetAccountByIdResponseEmployeeRoleItem>
                key="select"
                dataType="custom"
                header=""
                template={Checkbox}
                width={50}
              />
            ) : null}
            <Column<AccountRolesTableItem, GetAccountByIdResponseEmployeeRoleItem>
              dataType="string"
              header="Название"
              keyExpr="name"
            />
          </Table>
        </Card>
      </div>

      {showPasswordChangeModal && (<PasswordChangeForm onClose={togglePasswordChangeModal} />)}
      { showEmailChangeInfo && (
        <Modal
          className="max-w-[80vw]"
          onClose={() => setShowEmailChangeInfo(false)}
        >
          <Label>Для подтверждения почты перейдите по ссылке в письме</Label>
        </Modal>
      )}
      <Errors
        displayFunction={getListErrors}
        store={accountStore}
      />
      {roleInfo && (
        <DrawerWithWrapper
          footer={footer}
          onClose={onDrawerClose}
          title="Изменение ролей пользователя"
        >
          <Select.Async
            defaultOptions={rolesStore.rolesForSelect}
            label="Роль"
            onChange={onRoleChangeHandler}
            onSelect={(role) => {
              setRoleInfo({ role });
            }}
            selectedOption={roleInfo.role}
          />
        </DrawerWithWrapper>
      )}
      {showAvatarEditModal && (
        <AvatarEditModal
          file={profileViewModel.avatarLink}
          onClose={() => toggleAvatarEditModal()}
          setAvatar={(file) => accountStore.uploadAvatar(file)}
        />
      )}
    </div>
  );
};

export default observer(Profile);
