import type { TableItemView } from '@types';
import { computed } from 'mobx';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { isGroupColumn } from './typeGuards';
import type {
  Column,
  GroupColumn,
  ParsedRow,
  Row,
  UseTableProps,
} from './types';
import { getColumnKey } from './utils';

/** список строк в древовидную структуру */
function listToTree <T extends Row, EntityT>(list: ParsedRow<T, EntityT>[], hasChildrenExpr: string, parentIdExpr: string): ParsedRow<T, EntityT>[] {
  const map: Map<string, ParsedRow<T, EntityT>> = new Map();
  const roots: TableItemView<T, EntityT>[] = [];

  for (let i = 0; i < list.length; i++) {
    map.set(list[i].id, list[i]);
    list[i].children = [];
  }

  for (let i = 0; i < list.length; i++) {
    const node = list[i];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((node as any)[parentIdExpr]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const parentNode = map.get((node as any)[parentIdExpr] as string);
      if (!parentNode) {
        // eslint-disable-next-line no-continue
        continue;
      }
      parentNode?.children!.push(node);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (parentNode as any)[hasChildrenExpr] = true;
    } else {
      roots.push(node);
    }
  }
  return roots;
}

/** весь нижний уровень колонок */
function expandColumns <T extends Row, EntityT>(columns: Column<T, EntityT>[]): Exclude<Column<T, EntityT>, GroupColumn<T, EntityT>>[] {
  return columns.flatMap((col) => (isGroupColumn(col) ? expandColumns(col.columns.map((e) => ({ ...e, pinned: col.pinned }))) : col));
}

/**
 * возвращает массив с массивом колонок на каждом уровне вложенности
 * undefined если колонки нет, и на это место должна "расползтись" другая
 * */
function getLevelsOfColumns<T extends Row, EntityT>(columns: Column<T, EntityT>[]): (Column<T, EntityT> | undefined)[][] {
  const levelMap: (Column<T, EntityT> | undefined)[][] = [];
  let currentLevelColumns: (Column<T, EntityT> | undefined)[] = columns;
  let currentLevel = 0;
  levelMap[currentLevel] = columns;
  while (currentLevelColumns.some((e) => e && isGroupColumn(e) && e.columns.length)) {
    const nextLevelColumns = currentLevelColumns
      .flatMap((col) => ((col && isGroupColumn(col)) ? col.columns.map((e) => ({ ...e, pinned: col.pinned })) : undefined));
    levelMap[currentLevel + 1] = nextLevelColumns;
    currentLevelColumns = nextLevelColumns;
    currentLevel++;
  }
  return levelMap;
}

/** возвращает массив видимых строк */
function expandRows<T extends Row, EntityT>(rows: ParsedRow<T, EntityT>[], expandedIds: string[], depth = 0): ParsedRow<T, EntityT>[] {
  return rows.flatMap((row) => {
    // не спредом, потому что иначе mobx observable превратится в тыкву(js объект)
    row.depth = depth;
    row.expanded = expandedIds.includes(row.id);
    return row.expanded
      ? [row, ...expandRows(row.children ?? [], expandedIds, depth + 1)]
      : [row];
  });
}

export const useTable = <T extends Row, EntityT >(
  {
    data,
    columns,
    hasChildrenExpr = 'hasChildren',
    parentIdExpr = 'parentId',
    treeLike = false,
    readonly = false,
    reorderSessionKey,
  }: UseTableProps<T, EntityT>,
) => {
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const columnKeys = useMemo<React.Key[]>(() => columns.map((e) => getColumnKey(e) ?? ''), [columns]);
  const [orderKeysStorage, setOrderKeysStorage] = useSessionStorage<React.Key[]>(reorderSessionKey ?? '', columnKeys);
  const [orderKeys, setOrderKeys] = useState<React.Key[]>(orderKeysStorage);

  useEffect(() => {
    if (reorderSessionKey) {
      setOrderKeysStorage(orderKeys);
    }
  }, [orderKeys, reorderSessionKey, setOrderKeysStorage]);

  const order = useMemo(() => {
    const newColumns = columnKeys.filter((e) => !orderKeys.includes(e));

    const newOrder = [...orderKeys];

    newColumns.forEach((e) => {
      newOrder.splice(columnKeys.indexOf(e), 0, e);
    });
    return newOrder.map((e) => columnKeys.indexOf(e)).filter((e) => e !== -1);
  }, [columnKeys, orderKeys]);

  const sortedColumns = useMemo(() => order.map((e) => columns[e]).filter(Boolean), [order, columns]);
  const parsedColumns = useMemo(() => expandColumns(sortedColumns), [sortedColumns]);

  const levelsOfColumns = useMemo(() => getLevelsOfColumns(sortedColumns ?? []), [sortedColumns]);
  const groupedRows = computed(
    () => (treeLike ? listToTree<T, EntityT>(data, hasChildrenExpr, parentIdExpr) : data),
  ).get();
  const expandedRows = useMemo<ParsedRow<T, EntityT>[]>(
    () => (treeLike ? expandRows<T, EntityT>(groupedRows, expandedIds) : groupedRows),
    [groupedRows, expandedIds, treeLike],
  );

  const expandOrCollapseRow = useCallback(
    (id: string) => {
      setExpandedIds(expandedIds.includes(id) ? expandedIds.filter((el) => el !== id) : [...expandedIds, id]);
    },
    [expandedIds],
  );

  const reorder = useCallback((prev: number, cur: number) => {
    setOrderKeys((prevOrder) => {
      const newColumns = columnKeys.filter((e) => !prevOrder.includes(e));

      const newOrder = [...prevOrder];

      newColumns.forEach((e) => {
        newOrder.splice(columnKeys.indexOf(e), 0, e);
      });
      newOrder.splice(cur > prev ? cur - 1 : cur, 0, ...newOrder.splice(prev, 1));
      return newOrder;
    });
  }, [columnKeys, setOrderKeys]);

  return useMemo(() => ({
    columns: readonly ? parsedColumns.map((e) => ({ ...e, readonly: true })) : parsedColumns,
    rows: expandedRows,
    expandOrCollapseRow,
    levelsOfColumns,
    reorder,
  }), [expandOrCollapseRow, expandedRows, levelsOfColumns, parsedColumns, readonly, reorder]);
};
