import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import DrawerWithWrapper from '@components/DrawerResourceLayout/DrawerWithWrapper';
import { useConfirm, useStores } from '@hooks';
import type { ResourceInProjectLoadsTableItem } from 'src/types/ResourceInProjectLoadsTableItem';
import type ResourceInProjectViewModel from 'src/stores/ResourceInProject/ResourceInProjectViewModel';
import { Column, Table } from '@components/ui/Table';
import { useToggle } from 'usehooks-ts';
import { WorkingPeriodModal } from '@components/CalendarTable/Modals';
import type { Period } from '@types';
import Errors from '@components/ui/Errors/Errors';
import { MdDelete } from 'react-icons/md';
import Title from '@components/ui/Title/Title';
import Button from '@components/ui/Button';
import type { ChangeResourceInProjectFormProps } from './types';

/** Модалка изменения нагрузок ресурса на проекте */
const ChangeResourceInProjectForm: React.FC<ChangeResourceInProjectFormProps> = ({ onClose, resourceInProjectId }) => {
  const { resourceInProjectStore, projectStore, resourcesInProjectStore } = useStores();
  const { loadResourceInProjectStore } = resourceInProjectStore.viewModel;
  const [showAddLoad, toggleShowAddLoad] = useToggle();
  const { canUpdateResource } = projectStore;
  const confirmDelete = useConfirm('Удалить ресурс и его нагрузку с проекта?');

  useEffect(() => {
    resourceInProjectStore.fetch(resourceInProjectId);
  }, [resourceInProjectId, resourceInProjectStore]);

  const fetchResourcesInProject = useCallback(async (isLoadStoreUpdateSuccess: boolean) => {
    if (resourceInProjectId && isLoadStoreUpdateSuccess) {
      await Promise.all([resourceInProjectStore.fetch(resourceInProjectId), await resourcesInProjectStore.fetch()]);
    }
  }, [resourceInProjectId, resourceInProjectStore, resourcesInProjectStore]);

  /** Добавление нагрузки */
  const saveLoadHandler = useCallback(async (data?: Period) => {
    if (data) {
      await loadResourceInProjectStore.addLoad(resourceInProjectId, data);
      await fetchResourcesInProject(loadResourceInProjectStore.state.isSuccess);
    }
    toggleShowAddLoad();
  }, [fetchResourcesInProject, loadResourceInProjectStore, resourceInProjectId, toggleShowAddLoad]);

  /** Удаление нагрузки */
  const rowDeleteHandler = useCallback<React.FC<{data: ResourceInProjectLoadsTableItem}>>(
    ({ data }) => (
      <div
        className="flex w-full h-full items-center justify-center cursor-pointer"
        onClick={async () => {
          if (resourceInProjectId) {
            await loadResourceInProjectStore.deleteLoad(resourceInProjectId, data.startDate, data.finishDate);
            await fetchResourcesInProject(loadResourceInProjectStore.state.isSuccess);
          }
        }}
      >
        <MdDelete />
      </div>
    ),
    [fetchResourcesInProject, loadResourceInProjectStore, resourceInProjectId],
  );

  const deleteResourceFromProject = useCallback(async () => {
    if (await confirmDelete()) {
      if (resourceInProjectId) {
        await resourceInProjectStore.deleteResourceFromProject(resourceInProjectId);
        await resourcesInProjectStore.fetch();
        onClose?.();
      }
    }
  }, [confirmDelete, onClose, resourceInProjectId, resourceInProjectStore, resourcesInProjectStore]);

  /** Ячейка рабочих дней */
  const WorkingDaysCell: React.FC<{data: ResourceInProjectLoadsTableItem}> = useCallback(({ data }) => {
    const days = data.workingDaysOfWeek || resourceInProjectStore.viewModel.workingDaysOfWeek;

    return (
      <div
        className="h-full flex items-center p-2"
        title={days?.join(', ')}
      >
        <div className="truncate">
          {days?.map((date, index, arr) => (
            <span key={date}>
              {date}
              {(index === arr.length - 1 ? '' : ', ')}
            </span>
          ))}
        </div>
      </div>
    );
  }, [resourceInProjectStore.viewModel.workingDaysOfWeek]);

  return (
    <DrawerWithWrapper
      footer={canUpdateResource ? (
        <Button.Primary
          label="Удалить с проекта"
          onClick={deleteResourceFromProject}
          type="danger"
        />
      ) : undefined}
      onClose={onClose}
      title={resourceInProjectStore.viewModel.resourceName ?? ''}
    >
      <>
        <div className="flex items-center justify-between mb-4">
          <Title isSubtitle>Нагрузка на проекте</Title>
          {canUpdateResource && (
            <Button.Primary
              label="Добавить нагрузку"
              onClick={toggleShowAddLoad}
            />
          )}
        </div>
        <Table
          rowHeight={30}
          store={loadResourceInProjectStore}
        >
          <Column<ResourceInProjectLoadsTableItem, ResourceInProjectViewModel>
            dataType="date"
            header="Дата начала"
            keyExpr="startDate"
          />
          <Column<ResourceInProjectLoadsTableItem, ResourceInProjectViewModel>
            dataType="date"
            header="Дата окончания"
            keyExpr="finishDate"
          />
          <Column<ResourceInProjectLoadsTableItem, ResourceInProjectViewModel>
            dataType="number"
            header="Нагрузка в день"
            keyExpr="workingHoursPerDay"
          />
          <Column<ResourceInProjectLoadsTableItem, ResourceInProjectViewModel>
            dataType="custom"
            header="Рабочие дни"
            template={WorkingDaysCell}
          />
          {canUpdateResource ? (
            <Column<ResourceInProjectLoadsTableItem, ResourceInProjectViewModel>
              dataType="custom"
              header=""
              template={rowDeleteHandler}
              width={50}
            />
          ) : null}
        </Table>
        {showAddLoad && <WorkingPeriodModal onClose={saveLoadHandler} />}
        <Errors store={loadResourceInProjectStore} />
      </>
    </DrawerWithWrapper>
  );
};

export default observer(ChangeResourceInProjectForm);
