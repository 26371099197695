import { joinNonEmpty } from '@services/utils';
import { validationRequired, phoneValidation, emailValidation } from '@services/validationFunctions';
import type {
  GetAccountByIdResponse,
  GetAccountResponse,
  GetAccountResponseProject,
  GetAccountResponseResource,
  GetAccountResponseResourceInProject,
  RegistrationResponse,
} from '@typesApi';
import { computed, observable, runInAction } from 'mobx';
import { ErrorMessages } from 'src/enums';
import type { Nullable } from '../../types/base';
import BaseViewModel from '../BaseViewModel';

export type TAccountInfo = {
  /** Почта пользователя */
  email?: Nullable<string>;

  /** Пароль пользователя */
  password?: string;

  /** Повторный пароль пользователя */
  confirmPassword?: string;

  /** ИНН организации */
  organizationInn?: string;

  /** Наименование организации */
  organizationName?: Nullable<string>;

  /** Сфера деятельности */
  industryId?: string;

  /** Номер телефона */
  phone?: Nullable<string>;

  /** Пригласительный токен */
  inviteToken?: string;

  /** Проекты в которых задействован как ресурс */
  resourceInProjects?: Nullable<GetAccountResponseResourceInProject[]>;

  /** Токен ответа пользователя на капчу */
  captchaToken?: string | null;
}

export type TAccountViewModel = GetAccountByIdResponse & RegistrationResponse & TAccountInfo & GetAccountResponse

/** Вью модель сущности */
export class AccountViewModel extends BaseViewModel<TAccountViewModel> {
  @observable isInviteRegister: boolean = false;

  /* #region  setters/getters */

  /** Получение */
  get inviteToken() {
    return this.data.inviteToken;
  }

  /** Установить приграсительный токен */
  set inviteToken(inviteToken: string | undefined) {
    runInAction(() => {
      this.data.inviteToken = inviteToken;
      this.isInviteRegister = !!inviteToken;
    });
  }

  /** Получение */
  @computed get name() {
    return this.data.name ?? undefined;
  }

  /** Изменение */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
      this.validateField('name', (val) => (this.data.inviteToken ? undefined : validationRequired(val)));
    });
  }

  /** Получение */
  @computed get phone() {
    return this.data.phone;
  }

  /** Изменение */
  set phone(value: string | undefined | null) {
    runInAction(() => {
      this.data.phone = value;
      this.validateField('phone', (val) => (value ? phoneValidation(val!) : undefined));
    });
  }

  /** Получение */
  @computed get surname() {
    return this.data.surname ?? undefined;
  }

  /** Изменение */
  set surname(value: string | undefined) {
    runInAction(() => {
      this.data.surname = value;
      this.validateField('surname', (val) => (this.data.inviteToken ? undefined : validationRequired(val)));
    });
  }

  /** Получение */
  @computed get patronymic() {
    return this.data.patronymic ?? undefined;
  }

  /** Изменение */
  set patronymic(value: string | undefined) {
    runInAction(() => {
      this.data.patronymic = value;
    });
  }

  /** Получение */
  @computed get email() {
    return this.data.email ?? undefined;
  }

  /** Изменение */
  set email(value: string | undefined) {
    runInAction(() => {
      this.data.email = value;
      this.validateField('email', (val) => validationRequired(val) ?? emailValidation(val));
    });
  }

  /** Проверка совпадения паролей */
  private validatePasswordAndConfirmPassword(password: string | undefined, confirmPassword: string | undefined) {
    this.validateCustom(
      'passwordSimilar',
      () => {
        if (confirmPassword && confirmPassword !== password) {
          return ErrorMessages.passwordSimilar;
        }
        return undefined;
      },
    );
  }

  /** Получение */
  @computed get password() {
    return this.data.password ?? undefined;
  }

  /** Изменение */
  set password(value: string | undefined) {
    runInAction(() => {
      this.data.password = value;
      this.validateField('password', validationRequired);
      this.validatePasswordAndConfirmPassword(value, this.data.confirmPassword);
    });
  }

  /** Получение */
  @computed get confirmPassword() {
    return this.data.confirmPassword ?? undefined;
  }

  /** Изменение */
  set confirmPassword(value: string | undefined) {
    runInAction(() => {
      this.data.confirmPassword = value;
      this.validateField(
        'confirmPassword',
        (val) => {
          if (!val && this.data.password) {
            return ErrorMessages.passwordSimilar;
          }
          return undefined;
        },
      );
      this.validatePasswordAndConfirmPassword(this.data.password, value);
    });
  }

  /** Получение */
  @computed get industryId() {
    return this.data.industryId ?? undefined;
  }

  /** Изменение */
  set industryId(value: string | undefined) {
    runInAction(() => {
      this.data.industryId = value;
      this.validateField(
        'industryId',
        (val) => {
          if (!val && !this.isInviteRegister) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  /** Получение */
  @computed get organizationName() {
    return this.data.organizationName ?? undefined;
  }

  /** Изменение */
  set organizationName(value: string | undefined) {
    runInAction(() => {
      this.data.organizationName = value;
      this.validateField(
        'organizationName',
        (val) => {
          if (!val && !this.isInviteRegister) {
            return ErrorMessages.requiredField;
          }
          return undefined;
        },
      );
    });
  }

  /** Получение */
  @computed get organizationInn() {
    return this.data.organizationInn ?? undefined;
  }

  /** Изменение */
  set organizationInn(value: string | undefined) {
    runInAction(() => {
      this.data.organizationInn = value;
    });
  }

  @computed get fullName() {
    return joinNonEmpty([this.data.surname, this.data.name, this.data.patronymic]);
  }

  /** Получение ролей пользователя */
  @computed get employeeRoles() {
    return this.data.employeeRoles;
  }

  /** Получение id сотрудника */
  @computed get employeeId() {
    return this.data.employeeId ?? '';
  }

  /** Ссылка на аватарку */
  @computed get avatarLink() {
    return this.data.accountPictureId ? `${process.env.REACT_APP_API_URL}/api/v1.0/File/${this.data.accountPictureId}/Download` : undefined;
  }

  /** Получение идентификатора аватарки */
  @computed get accountPictureId() {
    return this.data.accountPictureId ?? undefined;
  }

  /** Изменение идентификатора аватарки  */
  set accountPictureId(value: string | undefined) {
    runInAction(() => {
      this.data.accountPictureId = value;
    });
  }

  /** Получение resourceInProjects */
  @computed get resourceInProjects() {
    return this.data.resourceInProjects;
  }

  /** Изменение resourceInProjects */
  set resourceInProjects(value: GetAccountResponseResourceInProject[] | undefined | null) {
    runInAction(() => {
      this.data.resourceInProjects = value;
    });
  }

  /** Получение проектов в котором он ответственный */
  @computed get projects() {
    return this.data.projects;
  }

  /** Изменение проектов в котором он ответственный */
  set projects(value: GetAccountResponseProject[] | undefined | null) {
    runInAction(() => {
      this.data.projects = value;
    });
  }

  /** Получение проектов в котором он ответственный */
  @computed get resources() {
    return this.data.resources;
  }

  /** Изменение проектов в котором он ответственный */
  set resources(value: GetAccountResponseResource[] | undefined | null) {
    runInAction(() => {
      this.data.resources = value;
    });
  }

  /** Получить 'Токен ответа пользователя на капчу' */
  @computed get captchaToken() {
    return this.data.captchaToken;
  }

  /** Изменить 'Токен ответа пользователя на капчу' */
  set captchaToken(value: string | null | undefined) {
    runInAction(() => {
      this.data.captchaToken = value;
    });
  }

  /* #endregion */

  /** @inheritdoc */
  public override clear() {
    super.clear();
  }
}
