import { createContext } from 'react';

interface AnalyticsTableContextType {
  boundingRect: DOMRect | null;
  setBoundingRect: (_val: DOMRect | null) => void;
}

export const AnalyticsTableContext = createContext<AnalyticsTableContextType>({
  boundingRect: null,
  setBoundingRect: (_val: DOMRect | null) => {},
});

export const AnalyticsTableScrollLeftContext = createContext({
  scrollLeft: 0,
});

export const AnalyticsTableScrollTopContext = createContext({
  scrollTop: 0,
});
