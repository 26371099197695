import { EquipmentService, ResourceService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetEquipmentsResponseItem } from '@typesApi';
import { ResourceTypes } from '@typesApi';
import { makeObservable, observable, runInAction } from 'mobx';
import BaseEntityListStore from '../BaseEntityListStore';
import type { PrivilegesStore } from '../Privileges/PrivilegesStore';
import EquipmentsViewModel from './EquipmentsViewModel';

/** Фильтр техники */
interface EquipmentFilter extends ListBaseFilter {
  /** Наименование техники */
  filterName?: string;
  /** Отображать удаленные? */
  isDeleted?: boolean;
}

/** Стор техники */
class EquipmentsStore extends BaseEntityListStore<GetEquipmentsResponseItem, EquipmentFilter, EquipmentsViewModel> {
  @observable
  private _privilegesStore: PrivilegesStore;

  @observable
  private _equipmentNames?: GetEquipmentsResponseItem[] | null;

  constructor(privilegesStore: PrivilegesStore) {
    super(20);
    makeObservable(this);
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.resources.canCreate;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.resources.canDelete
      || this._privilegesStore.responsibleForResources.some((x) => x.resourceType === ResourceTypes.Equipment);
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.resources.canUpdate;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.resources.canRead;
  }

  public async fetch(): Promise<void> {
    this.runWithStateControl(async () => {
      const { data } = await EquipmentService.list(this.filter);
      runInAction(() => {
        this.setData(data, EquipmentsViewModel);
      });
    });
  }

  /** Архивирование */
  public async archive() {
    const selectedPlaces = this.viewModel.filter((p) => p.selected);
    await this.runWithStateControl(async () => {
      await Promise.all(selectedPlaces.map((p) => ResourceService.delete(p.id, { deleteRightNow: true })));
    });
    if (this.state.isSuccess) {
      await this.fetch();
    }
  }

  /** Восстановление выбранной техники */
  public async restoreSelectedEquipment() {
    const selectedPlaces = this.viewModel.filter((p) => p.selected);
    await this.runWithStateControl(async () => {
      await Promise.all(selectedPlaces.map((p) => ResourceService.delete(p.id, { })));
    });
    this.fetch();
  }

  /** Получить список наименований помещений */
  public async fetchNames(search?: string, showOnlyEditable?: boolean) {
    const { data } = await EquipmentService.list({ filterName: search, showOnlyEditable });
    runInAction(() => {
      this._equipmentNames = data.entities;
    });
    return data.entities;
  }
}

export default EquipmentsStore;
