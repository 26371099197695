import type { PropsWithChildren } from 'react';
import type React from 'react';
import type { PanelProps } from './types';

/** панель аккордеона
 * служит только для передачи пропсов в аккордеон, не рендерится
*/
const Panel: React.FC<PropsWithChildren<PanelProps>> = () => null;

export default Panel;
