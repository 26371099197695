import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useConfirm, useStores } from '@hooks';
import { Column, Table } from '@components/ui/Table';
import type { EmployeesInProjectTableItem } from 'src/types/EmployeesInProjectTableItem';
import type { GetProjectEmployeesResponseItemWithRequiredId } from 'src/stores/Project/EmployeesInProjectStore';
import { MdDelete } from 'react-icons/md';
import { runInAction } from 'mobx';
import Errors from '@components/ui/Errors/Errors';
import Button from '@components/ui/Button';
import TablePageLayout from '@components/TablePageLayout';
import { Accordion, Panel } from '@components/ui';
import ButtonIcons from '@components/ui/Button/ButtonIcons';
import EmployeeInProjectForm from './EmployeeInProjectForm';

/** Ячейка ролей сотрудника */
const RolesCell: React.FC<{data: EmployeesInProjectTableItem}> = ({ data }) => (
  <div className="h-full flex items-center p-2">
    <div className="truncate">
      {data.projectRoles?.map((role, index) => (
        <span key={role.id}>
          {(index === 0 ? '' : ', ')}
          {role.name}
        </span>
      ))}
    </div>
  </div>
);

const EmployeesInProject: React.FC = () => {
  const {
    employeesInProjectStore, projectStore, projectLoadStore,
  } = useStores();
  /** Данные формы изменения  */
  const [employeeInProjectFormInfo, setEmployeeInProjectFormInfo] = useState<{resourceInProjectId?: string} | undefined>();
  const confirmDelete = useConfirm('Удалить сотрудника и его нагрузку с проекта?');

  const { canUpdateResource } = projectStore;

  /** Удаление нагрузки */
  const DeleteEmployeeCell = useCallback<React.FC<{data: EmployeesInProjectTableItem}>>(
    ({ data }) => (
      <div
        className="flex w-full h-full items-center justify-center cursor-pointer"
        onClick={async (e) => {
          e.stopPropagation();
          if (await confirmDelete()) {
            if (data.resourceInProjectId) {
              await employeesInProjectStore.deleteEmployeeInProject(data.resourceInProjectId);
              if (employeesInProjectStore.state.isSuccess) {
                await Promise.all([employeesInProjectStore.fetch(), projectLoadStore.reload()]);
              }
            }
          }
        }}
      >
        <MdDelete />
      </div>
    ),
    [confirmDelete, employeesInProjectStore, projectLoadStore],
  );
  DeleteEmployeeCell.displayName = 'DeleteEmployeeCell';

  useEffect(() => {
    if (projectStore.viewModel.id) {
      runInAction(() => {
        employeesInProjectStore.projectId = projectStore.viewModel.id;
      });
      employeesInProjectStore.fetch();
    }
    return () => {
      employeesInProjectStore.clearStore();
    };
  }, [employeesInProjectStore, projectStore.viewModel.id]);

  /** Кнопки хедера блока */
  const headerButtons = useMemo(() => (
    <>
      {canUpdateResource && (
        <Button.Primary
          className="py-2"
          icon={ButtonIcons.AddUser}
          label="Запланировать сотрудника на проект"
          onClick={() => setEmployeeInProjectFormInfo({})}
        />
      )}
    </>
  ), [canUpdateResource]);

  /** Хендлер нажатия на строку */
  const onRowClickHandler = useCallback((id: string) => {
    const data = employeesInProjectStore.viewModel.find((x) => x.id === id);
    if (data) {
      setEmployeeInProjectFormInfo({ resourceInProjectId: data.resourceInProjectId });
    }
  }, [employeesInProjectStore.viewModel]);

  /** Закрытие модалки добавления сотрудника на проект */
  const closeAddResourceFormHandler = useCallback(() => setEmployeeInProjectFormInfo(undefined), []);

  return (
    <Accordion
      contentClassName="bg-accordion-content-bg [&>div]:min-h-[350px] flex flex-col [&>div]:flex-grow [&>div]:basis-0 overflow-hidden"
      openedIds={['Employees']}
    >
      <Panel
        header="Сотрудники на проекте"
        headerButtons={headerButtons}
        id="Employees"
      >
        <TablePageLayout>
          <Table
            onRowClick={onRowClickHandler}
            rowHeight={30}
            store={employeesInProjectStore}
          >
            <Column<EmployeesInProjectTableItem, GetProjectEmployeesResponseItemWithRequiredId>
              dataType="string"
              header="ФИО сотрудника"
              keyExpr="fullName"
              minWidth={250}
              sortExpr="personName"
              pinned
            />
            <Column<EmployeesInProjectTableItem, GetProjectEmployeesResponseItemWithRequiredId>
              dataType="string"
              header="Специализация"
              keyExpr="mainSpecializationName"
              minWidth={150}
              sortExpr="mainSpecializationName"
            />
            <Column<EmployeesInProjectTableItem, GetProjectEmployeesResponseItemWithRequiredId>
              dataType="string"
              header="Квалификация"
              keyExpr="mainQualificationName"
              minWidth={150}
              sortable
            />
            <Column<EmployeesInProjectTableItem, GetProjectEmployeesResponseItemWithRequiredId>
              dataType="string"
              header="Должность"
              keyExpr="positionName"
              minWidth={150}
              sortExpr="positionName"
            />
            <Column<EmployeesInProjectTableItem, GetProjectEmployeesResponseItemWithRequiredId>
              dataType="string"
              header="Эл. почта"
              keyExpr="email"
              minWidth={150}
              sortExpr="email"
            />
            <Column<EmployeesInProjectTableItem, GetProjectEmployeesResponseItemWithRequiredId>
              dataType="string"
              header="Телефон"
              keyExpr="phone"
              minWidth={200}
              sortExpr="phone"
            />
            <Column<EmployeesInProjectTableItem, GetProjectEmployeesResponseItemWithRequiredId>
              dataType="custom"
              header="Роли"
              minWidth={300}
              template={RolesCell}
            />
            {canUpdateResource ? (
              <Column<EmployeesInProjectTableItem, GetProjectEmployeesResponseItemWithRequiredId>
                dataType="custom"
                header=""
                template={DeleteEmployeeCell}
                width={40}
              />
            ) : null}
          </Table>
        </TablePageLayout>
        {employeeInProjectFormInfo && (
          <EmployeeInProjectForm
            onClose={closeAddResourceFormHandler}
            projectId={projectStore.viewModel.id ?? ''}
            resourceInProjectId={employeeInProjectFormInfo.resourceInProjectId}
          />
        )}
        <Errors store={employeesInProjectStore} />
      </Panel>
    </Accordion>

  );
};

export default observer(EmployeesInProject);
