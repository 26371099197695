import Popup from '@components/Popup/Popup';
import DatePicker from '@components/ui/DatePicker/DatePicker';
import { parseStyles } from '@services/utils';
import type { ListBaseFilter } from '@types';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MdEditCalendar } from 'react-icons/md';
import { useOnClickOutside } from 'usehooks-ts';
import type { DateHeaderProps } from './types';

const DateHeader = <EntityT, FilterT extends ListBaseFilter, VmT>({
  store, fromDateField, toDateField, withTime,
}: DateHeaderProps<EntityT, FilterT, VmT>) => {
  const [open, setOpen] = useState<boolean>(false);
  const [periodError, setPeriodError] = useState<string | undefined>(undefined);
  const [filter, setFilter] = useState<{fromDateField?: string; toDateField?: string}>({ fromDateField: undefined, toDateField: undefined });
  const [label, setLabel] = useState<string>('Указать период');
  /** Реф для контейнера */
  const containerRef = useMemo(() => createRef<HTMLDivElement>(), []);
  /** Реф для outsideClick */
  const popupRef = useMemo(() => createRef<HTMLDivElement>(), []);
  /** Реф для кнопок */
  const sortBtnRef = useMemo(() => createRef<HTMLDivElement>(), []);

  /** Закрыть опции при скроле */
  const closeWhenScroll = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    window.addEventListener('wheel', closeWhenScroll);
    return () => window.removeEventListener('wheel', closeWhenScroll);
  }, [closeWhenScroll]);

  /** Перевод значения из Dayjs в нужный */
  const getDateValue = useCallback(
    (value: string | undefined) => {
      if (!value) {
        return '';
      }

      const dayjsValue = dayjs(value);

      return (withTime
        ? dayjsValue?.local().format('DD.MM.YYYY HH:mm')
        : dayjsValue?.local().format('DD.MM.YYYY'));
    },
    [withTime],
  );

  const updateLabel = useCallback(() => {
    if (filter.fromDateField && filter.toDateField && filter.fromDateField > filter.toDateField) {
      setPeriodError('text-red-500');
      setLabel('Неверно указан диапазон дат');
      return;
    }
    setPeriodError(undefined);
    if (filter.fromDateField && filter.toDateField) {
      setLabel(`${getDateValue(filter.fromDateField?.toString())} - ${getDateValue(filter.toDateField?.toString())}`);
      return;
    }
    if (filter.fromDateField) {
      setLabel(`c ${getDateValue(filter.fromDateField?.toString())}`);
      return;
    }
    if (filter.toDateField) {
      setLabel(`по ${getDateValue(filter.toDateField?.toString())}`);
      return;
    }
    setLabel('Указать период');
  }, [filter, getDateValue]);

  /** Хук для клика вне компонента */
  useOnClickOutside(popupRef, (e) => {
    const targetElement = e.target as HTMLElement;

    if (targetElement === document.activeElement || targetElement === sortBtnRef?.current) {
      return;
    }
    setOpen(false);
    if (!periodError && (filter.fromDateField !== store.filter[fromDateField] || filter.toDateField !== store.filter[toDateField])) {
      setFilter({ fromDateField: store.filter[fromDateField]?.toString(), toDateField: store.filter[toDateField]?.toString() });
      updateLabel();
      runInAction(() => {
        store.filter.pageNumber = 1;
      });
      store.fetch();
    }
  });

  return (
    <div
      ref={containerRef}
      className={parseStyles`
        flex flex-col items-center 
        w-full px-2 py-1 justify-center`}
    >
      <div className="self-stretch">
        <div
          ref={sortBtnRef}
          className={parseStyles`
            cursor-pointer h-[40px] rounded-main px-2 bg-white 
            flex items-center justify-center 
            border-1 max-w-[25vw] lg:max-w-none`}
          onClick={() => {
            setOpen(() => !open);
          }}
        >
          <span className={parseStyles`
            text-center ${periodError}
            cursor-pointer pointer-events-none 
            text-sm text-ellipsis overflow-hidden whitespace-nowrap`}
          >
            {label}
          </span>
          <MdEditCalendar className="ml-2" />
        </div>
        {(open) && (
          <Popup
            ref={popupRef}
            targetRef={containerRef.current!}
          >
            <div className={parseStyles`gap-form bg-white rounded-main p-2 border-1 shadow-md pb-4`}>
              <DatePicker
                className="[&>input]:max-h-[20px]"
                label="Начало периода"
                onChange={(value) => {
                  runInAction(() => {
                    (store.filter[fromDateField] as string | undefined) = value?.toISOString();
                  });
                }}
                value={store.filter[fromDateField] ? dayjs(store.filter[fromDateField]?.toString()) : null}
                withTime={withTime}
              />
              <DatePicker
                className="[&>input]:max-h-[20px]"
                label="Конец периода"
                onChange={(value) => {
                  runInAction(() => {
                    (store.filter[toDateField] as string | undefined) = value?.toISOString();
                  });
                }}
                value={store.filter[toDateField] ? dayjs(store.filter[toDateField]?.toString()) : null}
                withTime={withTime}
              />
            </div>
          </Popup>
        )}
      </div>
    </div>
  );
};

DateHeader.displayName = 'DateHeader';

export default observer(DateHeader);
