import { InputHeader, AccountChangeForm, AccountCreateForm } from '@components';
import AccountStatusChangeErrors from '@components/Accounts/AccountStatusChangeErrors';
import AccountStatusChangeSwitcher from '@components/Accounts/AccountStatusChangeSwitcher';
import TablePageLayout from '@components/TablePageLayout';
import { Table } from '@components/ui';
import Button from '@components/ui/Button';
import ButtonIcons from '@components/ui/Button/ButtonIcons';
import Errors from '@components/ui/Errors/Errors';
import { Column } from '@components/ui/Table';
import { useStores, useTitle } from '@hooks';
import type { AccountsTableItem } from '@types';
import type { GetAccountsResponseItem } from '@typesApi';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useEffectOnce, useToggle } from 'usehooks-ts';

/** Реестр пользователей */
const Accounts: React.FC = () => {
  useTitle('Пользователи');
  const { accountsStore } = useStores();
  const [showCreateForm, toggleCreateForm] = useToggle();
  const [showChangeForm, setShowChangeForm] = useState<{id: string}>();
  const { canCreate, canChangeState } = accountsStore;

  useEffectOnce(() => {
    runInAction(() => {
      accountsStore.filter.orderBy = 'fullName';
      accountsStore.filter.isAscending = true;
    });
    accountsStore.fetch();
  });

  const onRowClickHandler = useCallback((id: string) => {
    setShowChangeForm({ id });
  }, []);

  /** Хедер колонки "ФИО сотрудника" */
  const employeeNameHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="name"
      store={accountsStore}
    />
  ), [accountsStore]);

  return (
    <TablePageLayout
      buttons={canCreate
        ? (
          <Button.Primary
            icon={ButtonIcons.AddUser}
            isLoading={accountsStore.state.isLoading}
            label="Добавить пользователя"
            onClick={toggleCreateForm}
          />
        )
        : undefined}
      title="Пользователи"
    >
      <Table
        onRowClick={onRowClickHandler}
        rowHeight={30}
        store={accountsStore}
      >
        <Column
          header="ФИО"
          sortExpr="fullName"
          pinned
        >
          <Column<AccountsTableItem, GetAccountsResponseItem>
            dataType="string"
            header={employeeNameHeader}
            keyExpr="fullName"
            minWidth={180}
          />
        </Column>
        <Column<AccountsTableItem, GetAccountsResponseItem>
          dataType="string"
          header="Email"
          keyExpr="email"
          minWidth={180}
          sortable
        />
        <Column<AccountsTableItem, GetAccountsResponseItem>
          dataType="string"
          header="Телефон"
          keyExpr="phone"
          minWidth={180}
          sortable
        />
        <Column<AccountsTableItem, GetAccountsResponseItem>
          dataType="string"
          header="Cистемная роль"
          keyExpr="employeeRoles"
          minWidth={250}
        />
        {canChangeState ? (
          <Column<AccountsTableItem, GetAccountsResponseItem>
            dataType="custom"
            header="Активность"
            sortExpr="isActive"
            template={AccountStatusChangeSwitcher}
            width={100}
            sortable
          />
        ) : null}
      </Table>
      <Errors store={accountsStore} />
      <AccountStatusChangeErrors viewModels={accountsStore.viewModel} />
      {showCreateForm && (
        <AccountCreateForm onClose={() => {
          toggleCreateForm();
        }}
        />
      )}
      {!!showChangeForm && (
        <AccountChangeForm
          id={showChangeForm.id}
          onClose={() => {
            setShowChangeForm(undefined);
          }}
        />
      )}
    </TablePageLayout>
  );
};

export default observer(Accounts);
