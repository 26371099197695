import type { GetSubscriptionByIdResponse } from '@typesApi';
import { computed } from 'mobx';
import dayjs from 'dayjs';
import BaseViewModel from '../BaseViewModel';

/** Вью модель сущности */
export class SubscriptionViewModel extends BaseViewModel<GetSubscriptionByIdResponse> {
  /** Получить 'Название' */
  @computed get name() {
    return `Тариф: ${this.data.subscriptionType?.name ?? ''}`;
  }

  /** Получить 'Дата окончания' */
  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : undefined;
  }

  /** Получить 'Дата окончания текстом' */
  @computed get finishDateText() {
    return this.finishDate ? `Подписка действительна до: ${this.finishDate?.format('DD.MM.YYYY')}` : undefined;
  }

  /** Получить 'Использованный лимит на пользователей' */
  @computed get accountsCount() {
    return this.data.accountsCount ?? 0;
  }

  /** Получить 'Лимит на пользователей' */
  @computed get accountsLimit() {
    return this.data.subscriptionType?.accounts;
  }

  /** Получить 'Использованный лимит на ресурсы' */
  @computed get resourceCount() {
    return this.data.resourcesCount ?? 0;
  }

  /** Получить 'Лимит на ресурсы' */
  @computed get resourcesLimit() {
    return this.data.subscriptionType?.resources;
  }

  /** Получить 'Использованный лимит на проекты' */
  @computed get projectsCount() {
    return this.data.projectsCount ?? 0;
  }

  /** Получить 'Лимит на проекты' */
  @computed get projectsLimit() {
    return this.data.subscriptionType?.projects;
  }

  /** Имеются ли лимиты на проекте */
  @computed get hasLimits() {
    return !!(this.accountsLimit && this.projectsLimit && this.resourcesLimit);
  }
}
