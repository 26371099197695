import DrawerWithWrapper from '@components/DrawerResourceLayout/DrawerWithWrapper';
import Button from '@components/ui/Button';
import Errors from '@components/ui/Errors/Errors';
import { Select } from '@components/ui/Select';
import type { SelectOption } from '@components/ui/Select/types';
import { useStores } from '@hooks';
import { ResourceTypes } from '@typesApi';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Resources } from 'src/enums';
import ProjectRequirementEmployeeViewModel from 'src/stores/ProjectRequirement/ProjectRequirementEmployeeViewModel';
import ProjectRequirementEquipmentViewModel from 'src/stores/ProjectRequirement/ProjectRequirementEquipmentViewModel';
import ProjectRequirementPlaceViewModel from 'src/stores/ProjectRequirement/ProjectRequirementPlaceViewModel';
import { useEffectOnce } from 'usehooks-ts';
import ProjectRequirementEmployee from './ProjectRequirementEmployee';
import ProjectRequirementEquipment from './ProjectRequirementEquipment';
import ProjectRequirementPlace from './ProjectRequirementPlace';
import type { ProjectRequirementModalProps } from './types';

/** Форма потребности в зависимости от типа потребности */
const ProjectRequirementFrom: React.FC<ProjectRequirementModalProps> = ({
  onClose, projectName, projectRequirementId, resourceType, projectId,
}) => {
  /** Сторы */
  const { projectRequirementStore, projectNamesStore, projectRequirementsStore } = useStores();
  /** Выбранный тип ресурса */
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceTypes | undefined>(resourceType);
  /** Выбранный проект */
  const [project, setProject] = useState<SelectOption | undefined>(projectId && projectName
    ? { label: projectName, value: projectId }
    : undefined);
  /** Возможность редактирования */
  const [editing, setEditing] = useState(!projectRequirementId);

  /** Возможность отображения кнопки 'сохранить' */
  const canShowSaveButton = projectRequirementId && editing;
  /** Возможность отображения кнопки 'редактировать' */
  const canShowEditingButton = !editing && projectRequirementId;
  /** Возможность отображения кнопки 'добавить' */
  const canShowCreateButton = !projectRequirementId && project && selectedResourceType;
  /** Типы ресурсов */
  const resources = useMemo(() => Object.entries(Resources).map(([value, label]) => ({
    label, value,
  })), []);

  /** Поиск проект */
  const searchProjectNames = useCallback(async (search?: string): Promise<SelectOption[] | undefined> => {
    await projectNamesStore.fetchProjectsNames(search);
    return projectNamesStore.namesList?.map(({ id, name }) => ({ label: name ?? '', value: id ?? '' }));
  }, [projectNamesStore]);

  useEffectOnce(() => {
    projectNamesStore.fetchProjectsNames();
    return () => projectRequirementStore.viewModel.clear();
  });

  useEffect(() => {
    if (!selectedResourceType || projectRequirementId) {
      return;
    }

    switch (ResourceTypes[selectedResourceType]) {
      case ResourceTypes.Employee:
        runInAction(() => {
          projectRequirementStore.viewModel = new ProjectRequirementEmployeeViewModel({
            projectId: projectRequirementStore.viewModel.projectId,
            projectName: projectRequirementStore.viewModel.projectName,
            resourceType: projectRequirementStore.viewModel.resourceType,
          });
        });
        break;
      case ResourceTypes.Equipment:
        runInAction(() => {
          projectRequirementStore.viewModel = new ProjectRequirementEquipmentViewModel({
            projectId: projectRequirementStore.viewModel.projectId,
            projectName: projectRequirementStore.viewModel.projectName,
            resourceType: projectRequirementStore.viewModel.resourceType,
          });
        });
        break;
      case ResourceTypes.Place:
        runInAction(() => {
          projectRequirementStore.viewModel = new ProjectRequirementPlaceViewModel({
            projectId: projectRequirementStore.viewModel.projectId,
            projectName: projectRequirementStore.viewModel.projectName,
            resourceType: projectRequirementStore.viewModel.resourceType,
          });
        });
        break;
      default:
        break;
    }
  }, [projectRequirementId, projectRequirementStore, selectedResourceType]);

  /** Возвращает форму потребности в зависимости от типа */
  const requirementForm = useMemo(() => {
    if (selectedResourceType && project) {
      switch (ResourceTypes[selectedResourceType]) {
        case ResourceTypes.Employee:
          return (
            <ProjectRequirementEmployee
              disabled={!editing}
              onClose={onClose}
              projectId={project?.value}
              projectName={project?.label}
              projectRequirementId={projectRequirementId}
            />
          );
        case ResourceTypes.Equipment:
          return (
            <ProjectRequirementEquipment
              disabled={!editing}
              onClose={onClose}
              projectId={project?.value}
              projectName={project?.label}
              projectRequirementId={projectRequirementId}
            />
          );
        case ResourceTypes.Place:
          return (
            <ProjectRequirementPlace
              disabled={!editing}
              onClose={onClose}
              projectId={project?.value}
              projectName={project?.label}
              projectRequirementId={projectRequirementId}
            />
          );
        default:
          return null;
      }
    }
    return null;
  }, [selectedResourceType, project, editing, onClose, projectRequirementId]);

  /** Обработчик выбора типа ресурса */
  const onSelectResourceTypeHandler = useCallback((option?: SelectOption) => {
    if (option) {
      projectRequirementStore.viewModel.resourceType = option.value as ResourceTypes;
      setSelectedResourceType(option.value as ResourceTypes);
    }
  }, [projectRequirementStore.viewModel]);

  /** Обработчик выбора проекта */
  const onSelectProjectHandler = useCallback((option?: SelectOption) => {
    projectRequirementStore.viewModel.projectId = option?.value;
    projectRequirementStore.viewModel.projectName = option?.label;
    setProject(option);
  }, [projectRequirementStore.viewModel]);

  /** Добавление или изменение потребности */
  const requirementChangeHandler = useCallback(async () => {
    if (editing) {
      if (!projectRequirementStore.viewModel.validate()) {
        return;
      }
      if (projectRequirementId) {
        await projectRequirementStore.save();
        projectRequirementsStore.fetch();
        setEditing(false);
      } else {
        await projectRequirementStore.create();
        if (projectRequirementStore.state.isSuccess) {
          onClose();
          projectRequirementsStore.fetch();
        }
      }
    }
  }, [editing, onClose, projectRequirementId, projectRequirementStore, projectRequirementsStore]);

  const footer = useMemo(() => (
    <>
      {canShowSaveButton && (
        <Button.Primary
          key="save"
          className="self-end"
          isDisabled={projectRequirementStore.state.isLoading}
          label="Сохранить"
          onClick={requirementChangeHandler}
        />
      )}
      {canShowCreateButton && (
        <Button.Primary
          key="create"
          className="self-end"
          isDisabled={projectRequirementStore.state.isLoading}
          label="Добавить"
          onClick={requirementChangeHandler}
        />
      )}
      {canShowEditingButton && (
        <Button.Primary
          key="change"
          className="self-end"
          label="Редактировать"
          onClick={() => setEditing(true)}
        />
      )}
    </>
  ), [canShowCreateButton, canShowEditingButton, canShowSaveButton, projectRequirementStore.state.isLoading, requirementChangeHandler]);

  return (
    <DrawerWithWrapper
      footer={footer}
      onClose={onClose}
      title={projectId
        ? projectRequirementStore.viewModel.name ?? ''
        : `Добавление потребности ${selectedResourceType ?? ''}`}
    >
      <div className="flex flex-col gap-form">
        <Select.Async
          disabled={!!projectRequirementId || !editing}
          label="Проект"
          onChange={searchProjectNames}
          onSelect={onSelectProjectHandler}
          selectedOption={project}
        />
        <Select.AutoComplete
          disabled={!!projectRequirementId || !editing}
          label="Тип ресурса"
          onSelect={onSelectResourceTypeHandler}
          options={resources}
          selectedOption={selectedResourceType ? { label: Resources[selectedResourceType], value: selectedResourceType } : undefined}
        />
        {requirementForm}
      </div>
      <Errors store={projectRequirementStore} />
    </DrawerWithWrapper>
  );
};

export default observer(ProjectRequirementFrom);
