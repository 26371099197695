import type {
  GetLoadResourceInProjectsResponseItemProject,
  GetLoadResourceInProjectsResponseItemResourcesInProject,
  GetLoadResourceInProjectsResponseItemUnavailableResource,
  ResourceTypes,
} from '@typesApi';
import { makeObservable, observable } from 'mobx';

export class ProjectLoadViewModel implements GetLoadResourceInProjectsResponseItemResourcesInProject {
  @observable resourceInProjectId?: string;

  @observable name: string;

  @observable id: string;

  @observable type?: ResourceTypes;

  @observable projects: GetLoadResourceInProjectsResponseItemProject[];

  @observable unavailableResources: GetLoadResourceInProjectsResponseItemUnavailableResource[];

  constructor(data: GetLoadResourceInProjectsResponseItemResourcesInProject) {
    this.resourceInProjectId = data.resourceInProjectId;
    this.name = data.name ?? '';
    this.id = data.id!;
    this.type = data.type;
    this.projects = data.projects ?? [];
    this.unavailableResources = data.unavailableResources ?? [];
    makeObservable(this);
  }
}
