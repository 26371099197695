import {
DeleteLoadResourceInProjectByIdRequest,
DeleteLoadResourceInProjectsRequest,
DeleteResourceByIdRequest,
DeleteUnavailableReasonByResourceIdRequest,
GetLoadResourceInProjectsByResourceIdResponse,
GetResourceInProjectByIdResponse,
GetResourceInProjectsResponse,
GetUnavailableResourcesResponse,
ProblemDetails,
ProblemDetailsResponse,
PutLoadResourceInProjectByIdRequest,
PutLoadResourceInProjectsRequest,
PutResourceInProjectByIdRequest,
PutResourceInProjectByIdResponse,
PutUnavailableReasonByResourceIdRequest,
ResourceTypes,
UpdateResourceResponsibleByIdRequest} from "./types.api.g";
import { ContentType, httpClient, RequestParams } from "./apiConnection.g";

export class ResourceService {
  static get RoutePath(): string {
    return "Resource";
  }

  /**
   * Получение списка ресурсов с их участием на проектах
   *
   * @request GET:/api/v1.0/Resource/InProject
   */
  static inProjectList = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      projects?: string[];
      resources?: string[];
      resourceType?: ResourceTypes;
      resourceName?: string;
      catalogValues?: string[];
      startDateInProject?: string;
      finishDateInProject?: string;
      minHoursPerDayInProject?: number;
      maxHoursPerDayInProject?: number;
      isDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetResourceInProjectsResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/InProject`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Получение ресурса на проекте по ид
   *
   * @request GET:/api/v1.0/Resource/ResourceInProject/{id}
   */
  static resourceInProjectDetail = async (id: string, params: RequestParams = {}) =>
    httpClient.request<GetResourceInProjectByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/ResourceInProject/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * Добавить нагрузку запланированному на проект ресурсу
   *
   * @request PUT:/api/v1.0/Resource/ResourceInProject/{resourceInProjectId}
   */
  static resourceInProjectUpdate = async (
    resourceInProjectId: string,
    data: PutLoadResourceInProjectByIdRequest,
    params: RequestParams = {},
  ) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/ResourceInProject/${resourceInProjectId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удалить ресурс с проекта
   *
   * @request DELETE:/api/v1.0/Resource/ResourceInProject/{resourceInProjectId}
   */
  static resourceInProjectDelete = async (resourceInProjectId: string, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/ResourceInProject/${resourceInProjectId}`,
      method: "DELETE",
      ...params,
    });

  /**
   * Удалить нагрузку запланированному на проект ресурсу
   *
   * @request DELETE:/api/v1.0/Resource/LoadResourceInProject/{resourceInProjectId}
   */
  static loadResourceInProjectDelete = async (
    resourceInProjectId: string,
    data: DeleteLoadResourceInProjectByIdRequest,
    params: RequestParams = {},
  ) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/LoadResourceInProject/${resourceInProjectId}`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Обновление данных о недоступности ресурса
   *
   * @request PUT:/api/v1.0/Resource/{id}/UnavailableReason
   */
  static unavailableReasonUpdate = async (
    id: string,
    data: PutUnavailableReasonByResourceIdRequest,
    params: RequestParams = {},
  ) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/UnavailableReason`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удаление недоступности ресурса по ид
   *
   * @request DELETE:/api/v1.0/Resource/{id}/UnavailableReason
   */
  static unavailableReasonDelete = async (
    id: string,
    data: DeleteUnavailableReasonByResourceIdRequest,
    params: RequestParams = {},
  ) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/UnavailableReason`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удаление ресурса по ид
   *
   * @request DELETE:/api/v1.0/Resource/{id}
   */
  static delete = async (id: string, data: DeleteResourceByIdRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение списка недоступностей ресурса
   *
   * @request GET:/api/v1.0/Resource/UnavailableResources
   */
  static unavailableResourcesList = async (
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      resources?: string[];
      resourceType?: ResourceTypes;
      resourceName?: string;
      startDate?: string;
      finishDate?: string;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetUnavailableResourcesResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/UnavailableResources`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });

  /**
   * Добавление ресурса на проект
   *
   * @request PUT:/api/v1.0/Resource/{id}/Project/{projectId}
   */
  static projectUpdate = async (
    id: string,
    projectId: string,
    data: PutResourceInProjectByIdRequest,
    params: RequestParams = {},
  ) =>
    httpClient.request<PutResourceInProjectByIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/Project/${projectId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * Добавление ресурсу ответственного
   *
   * @request PUT:/api/v1.0/Resource/{id}/Responsible
   */
  static responsibleUpdate = async (
    id: string,
    data: UpdateResourceResponsibleByIdRequest,
    params: RequestParams = {},
  ) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/Responsible`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Обновление нагрузок на ресурс проекта
   *
   * @request PUT:/api/v1.0/Resource/LoadResourceInProjects
   */
  static loadResourceInProjectsUpdate = async (data: PutLoadResourceInProjectsRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/LoadResourceInProjects`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Удаление нагрузок на ресурс проекта
   *
   * @request DELETE:/api/v1.0/Resource/LoadResourceInProjects
   */
  static loadResourceInProjectsDelete = async (data: DeleteLoadResourceInProjectsRequest, params: RequestParams = {}) =>
    httpClient.request<void, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/LoadResourceInProjects`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * Получение нагрузки ресурса на проекте по ид ресурса
   *
   * @request GET:/api/v1.0/Resource/{id}/LoadResourceInProjects
   */
  static loadResourceInProjectsDetail = async (
    id: string,
    query?: {
      pageNumber?: number;
      pageSize?: number;
      orderBy?: string;
      isAscending?: boolean;
      startDate?: string;
      finishDate?: string;
      isDeleted?: boolean;
    },
    params: RequestParams = {},
  ) =>
    httpClient.request<GetLoadResourceInProjectsByResourceIdResponse, ProblemDetailsResponse>({
      path: "api/v1.0/" + this.RoutePath + `/${id}/LoadResourceInProjects`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}