import { dayOfWeekEnumToNumber } from '@services/utils';
import type { TableItemView } from '@types';
import type { GetResourceInProjectByIdResponseLoadResourceInProject } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, makeObservable, runInAction } from 'mobx';
import BaseViewModel from '../BaseViewModel';

export interface LoadResourceInProjectFields {
  id: string;
  startDate: Dayjs | null;
  finishDate: Dayjs | null;
  workingHoursPerDay?: number | undefined;
  workingDaysOfWeek?: string[] | null;
  isWorkOnWeekends?: boolean | null;
}

/** Вью модель элемента списка */
export class LoadResourceInProjectsItemViewModel
  extends BaseViewModel<GetResourceInProjectByIdResponseLoadResourceInProject>
  implements TableItemView<
  LoadResourceInProjectFields,
  GetResourceInProjectByIdResponseLoadResourceInProject
  > {
  constructor(data: GetResourceInProjectByIdResponseLoadResourceInProject) {
    super(data);
    makeObservable(this);
  }

  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получить 'Дата начала' */
  @computed get startDate() {
    return this.data.startDate ? dayjs(this.data.startDate) : null;
  }

  public set startDate(date: Dayjs | null) {
    this.data.startDate = date?.toISOString();
  }

  /** Получить 'Дата окончания' */
  @computed get finishDate() {
    return this.data.finishDate ? dayjs(this.data.finishDate) : null;
  }

  public set finishDate(date: Dayjs | null) {
    this.data.finishDate = date?.toISOString();
  }

  /** Получить 'Количество рабочих часов в день' */
  @computed get workingHoursPerDay() {
    return this.data.workingHoursPerDay;
  }

  /** Изменить 'Количество рабочих часов в день' */
  set workingHoursPerDay(value: number | undefined) {
    runInAction(() => {
      this.data.workingHoursPerDay = value;
    });
  }

  /** Получение isWorkOnWeekends */
  @computed get isWorkOnWeekends() {
    return this.data.isWorkOnWeekends;
  }

  /** Получение workingDaysOfWeek */
  @computed get workingDaysOfWeek() {
    return this.data.workingDaysOfWeek?.map((day) => dayjs().day(dayOfWeekEnumToNumber(day)).format('dd'));
  }
}
