import { CareerMeetingService } from '@servicesHr';
import type { GetCareerMeetingByIdResponse, PutCareerMeetingByIdRequest, PostCareerMeetingRequest } from '@typesHr';
import EntityBaseStore from '../BaseEntityStore';
import { CareerMeetingViewModel } from './CareerMeetingViewModel';

/** Стор сущности */
export class CareerMeetingStore extends EntityBaseStore<GetCareerMeetingByIdResponse, CareerMeetingViewModel> {
  public viewModel = new CareerMeetingViewModel({});

  /** Фетч сущности */
  public override async fetch(id: string): Promise<void> {
    this.runWithStateControl(async () => {
      const data = await CareerMeetingService.detail(id);
      this.viewModel = new CareerMeetingViewModel(data.data);
    });
  }

  /** Сохранение изменений */
  public async save(): Promise<void> {
    if (this.viewModel.id) {
      const request: PutCareerMeetingByIdRequest = {
        ...this.viewModel.getRawData(),
        invitedEmployeesIds: this.viewModel.invitedEmployeesIds,
      };
      await this.runWithStateControl(async () => {
        await CareerMeetingService.update(this.viewModel.id, request);
      });
    } else {
      const request: PostCareerMeetingRequest = {
        ...this.viewModel.getRawData(),
        invitedEmployeesIds: this.viewModel.invitedEmployeesIds,
      };
      await this.runWithStateControl(async () => {
        await CareerMeetingService.create(request);
      });
    }
  }
}
