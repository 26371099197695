import React from 'react';
import Administration from 'src/views/Administration';
import Counterparties from 'src/views/Counterparties';
import Employee from 'src/views/Employee';
import Employees from 'src/views/Employees';
import Equipments from 'src/views/Equipments';
import NoAccess from 'src/views/NoAccess';
import Places from 'src/views/Places';
import Project from 'src/views/Project';
import Projects from 'src/views/Projects';
import ProjectsLoad from 'src/views/ProjectsLoad';
import ResourcesLoad from 'src/views/ResourcesLoad';
import Accounts from 'src/views/Accounts';
import type { PrivilegesStore } from 'src/stores/Privileges/PrivilegesStore';
import { IoCalendarOutline } from 'react-icons/io5';
import { GiCargoCrane, GiHumanPyramid } from 'react-icons/gi';
import {
  MdStore,
  MdWorkOutline,
  MdManageAccounts,
  MdOutlineHandyman,
  MdSupervisorAccount,
} from 'react-icons/md';
import { TiBusinessCard } from 'react-icons/ti';
import { RiComputerLine } from 'react-icons/ri';
import { MenuIconSize } from '@components/LeftMenu/types';
import ContentLayout from '@components/ContentLayout';
import PaymentRedirect from './views/PaymentRedirect';
import Organization from './views/Organization';
import PaymentFailed from './views/PaymentFailed';

export interface RouteItem {
  /** путь к странице */
  path: string;
  /** Наименование (только для элементов ссылки которых отображаются в левом меню) */
  name?: string;
  /** Элемент для отрисовки (используется только в App) */
  element?: JSX.Element;
  /** Доступ к роуту */
  checkAccess?: (privilegesStore: PrivilegesStore) => boolean;
  /** Наличие дочерних роутов */
  children?: RouteItem[];
  /** Исконка только для отображения в левом меню */
  icon?: React.ReactNode;
  /** Отображение почты */
  needEmail?: boolean;
  id: string;
}

/** Массив роутов в проекте (используется как для роутинга так и для отрисовки элементов в левом меню) */
const projectRoutes: RouteItem[] = [
  {
    path: '/',
    id: '/',
    element: (<ContentLayout className="overflow-auto" />),
    children: [
      {
        name: 'Распределение нагрузки по проектам',
        path: 'projectsLoad',
        id: 'projectsLoad',
        element: <ProjectsLoad />,
        checkAccess: (privilegesStore) => privilegesStore.canViewProjects,
        icon: (
          <div className={`w-[${MenuIconSize}px] h-[${MenuIconSize}px] flex items-center justify-center`}>
            <IoCalendarOutline size={28} />
          </div>
        ),
      },
      {
        name: 'Нагрузка сотрудников',
        path: 'resourcesLoad',
        id: 'resourcesLoad',
        element: <ResourcesLoad />,
        checkAccess: (privilegesStore) => privilegesStore.canViewEmployees,
        icon: (
          <div className={`w-[${MenuIconSize}px] h-[${MenuIconSize}px] flex items-center justify-center`}>
            <GiCargoCrane size={28} />
          </div>
        ),
      },
    ],
  },
  {
    path: '/',
    id: '/',
    element: (<ContentLayout className="overflow-auto flex-col" />),
    children: [
      {
        name: 'Проекты',
        path: 'projects',
        id: 'projects',
        icon: <MdWorkOutline size={MenuIconSize} />,
        checkAccess: (privilegesStore) => privilegesStore.canViewProjects,
        children: [
          {
            path: '',
            id: 'projectsList',
            checkAccess: (privilegesStore) => privilegesStore.canViewProjects,
            element: <Projects />,
          },
          {
            path: ':id',
            id: 'project',
            checkAccess: (privilegesStore) => privilegesStore.canViewProjects,
            element: <Project />,
          },
        ],
      },
      {
        path: '/resources',
        id: 'resources',
        name: 'Ресурсы',
        checkAccess: (privilegesStore) => privilegesStore.canViewPlaces
          || privilegesStore.canViewPlaces
          || privilegesStore.canViewPlaces,
        icon: <GiHumanPyramid size={MenuIconSize} />,
        children: [
          {
            path: 'employees',
            id: 'employees',
            name: 'Сотрудники',
            icon: <GiHumanPyramid size={MenuIconSize} />,
            children: [
              {
                path: '',
                id: 'employeesList',
                element: <Employees />,
              },
              {
                path: ':id',
                id: 'employee',
                element: <Employee />,
              }],
          },
          {
            name: 'Помещения',
            path: 'places',
            id: 'places',
            checkAccess: (privilegesStore) => privilegesStore.canViewPlaces,
            children: [
              {
                path: '',
                id: 'placesList',
                checkAccess: (privilegesStore) => privilegesStore.canViewPlaces,
                element: <Places />,
              },
              {
                path: ':id',
                id: 'place',
                checkAccess: (privilegesStore) => privilegesStore.canViewPlaces,
                element: <Places />,
              }],
            icon: <MdStore size={MenuIconSize} />,
          },
          {
            name: 'Техника',
            path: 'equipments',
            id: 'equipments',
            checkAccess: (privilegesStore) => privilegesStore.canViewEquipments,
            children: [
              {
                path: '',
                id: 'equipmentsList',
                checkAccess: (privilegesStore) => privilegesStore.canViewEquipments,
                element: <Equipments />,
              },
              {
                path: ':id',
                id: 'equipment',
                checkAccess: (privilegesStore) => privilegesStore.canViewEquipments,
                element: <Equipments />,
              }],
            icon: <RiComputerLine size={MenuIconSize} />,
          },
        ],
      },
      // временно убран
      // {
      //   name: 'Потребности',
      //   path:'requirements',
      //   checkAccess: (privilegesStore) => privilegesStore.canViewRequirements,
      //   element: <Requirements />,
      //   icon: <VscLayers size={MenuIconSize} />,
      // },
      {
        path: 'settings',
        id: 'settings',
        name: 'Настройки',
        checkAccess: (privilegesStore) => privilegesStore.canViewRoles
          || privilegesStore.canPayOrganizationSubscription
          || privilegesStore.canViewAccounts
          || privilegesStore.canViewContragents,
        icon: (
          <div className={`w-[${MenuIconSize}px] h-[${MenuIconSize}px] flex items-center justify-center`}>
            <MdOutlineHandyman size={26} />
          </div>),
        children: [
          {
            name: 'Администрирование',
            path: 'administration',
            id: 'administration',
            element: <Administration />,
            checkAccess: (privilegesStore) => privilegesStore.canViewRoles,
            icon: (
              (
                <div className={`w-[${MenuIconSize}px] h-[${MenuIconSize}px] flex items-center justify-center`}>
                  <MdOutlineHandyman size={26} />
                </div>
              )
            ),
          },
          {
            name: 'Настройки организации',
            path: 'organization',
            id: 'organization',
            checkAccess: (privilegesStore) => privilegesStore.canPayOrganizationSubscription,
            element: <Organization />,
            icon: <MdManageAccounts size={MenuIconSize} />,
          },
          {
            name: 'Пользователи',
            icon: <MdSupervisorAccount size={MenuIconSize} />,
            checkAccess: (privilegesStore) => privilegesStore.canViewAccounts,
            path: 'users',
            id: 'users',
            element: <Accounts />,
          },
          {
            path: 'counterparties',
            id: 'counterparties',
            name: 'Контрагенты',
            checkAccess: (privilegesStore) => privilegesStore.canViewContragents,
            icon: (
              <div className={`w-[${MenuIconSize}px] h-[${MenuIconSize}px] flex items-center justify-center`}>
                <TiBusinessCard size={28} />
              </div>
            ),
            children: [
              {
                checkAccess: (privilegesStore) => privilegesStore.canViewContragents,
                path: '',
                id: '',
                element: <Counterparties />,
              },
              {
                checkAccess: (privilegesStore) => privilegesStore.canViewContragents,
                path: ':id',
                id: ':id',
                element: <Counterparties />,
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   path:'/',
  //   element: (<ContentLayout className="overflow-auto flex-col" />),
  //   children: [
  //     {
  //       name: 'Вики',
  //       path:'wiki',
  //       icon: <ImWikipedia size={MenuIconSize} />,
  //       children: [
  //         {
  //           path:'',
  //           element: <Wiki />,
  //         },
  //         {
  //           path:':id',
  //           element: <Wiki />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: 'notFound',
    id: 'notFound',
    element: <NoAccess prevPath={window.location.pathname} />,
  },
  {
    path: 'paymentRedirect/:id',
    id: 'paymentRedirect',
    element: <PaymentRedirect />,
  },
  {
    path: 'paymentFailed',
    id: 'paymentFailed',
    element: <PaymentFailed />,
  },
  {
    path: '*',
    id: '*',
    element: <NoAccess prevPath={window.location.pathname} />,
  },
];

export default projectRoutes;
