import { useStores } from '@hooks';
import { pluralize } from '@services/utils';
import type { Period, UnavailablePeriod } from '@types';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import type { RefObject } from 'react';
import React, {
  useCallback,
  useMemo,
  startTransition,
  useEffect,
  useState,
} from 'react';
import type { GetLoadResourceInProjectsResponseItemLoadResourceInProject, GetResourceInProjectsResponseUnavailableResourceItem } from '@typesApi';
import WorkingPeriodModal from '../Modals/WorkingPeriodModal';
import ProjectRow from '../Data/ProjectRow';
import ResourceRow from '../Data/ResourceRow';
import HoveredCell from '../HoveredCell';
import { UnavailablePeriodModal } from '../Modals';
import type { DayData } from '../types';
import AboutDayPopup from '../Modals/AboutDayPopup';

export interface ProjectsLoadResourceData {
  rowIndex: number;
  resourceName: string;
  resourceId: string;
  days: DayData[];
}

export interface DataProps {
  ganttSVGRef: RefObject<SVGElement>;
  listRef: RefObject<HTMLDivElement>;
}

export interface ProjectsLoadCellData {
  cell: SVGElement;
  resourceId: string;
  projectName: string;
  projectId: string;
  resourceName: string;
  day: DayData;
}

const Data: React.FC<DataProps> = ({ ganttSVGRef, listRef }) => {
  const [hoveredCellData, setHoveredCellData] = useState<ProjectsLoadCellData & { rowIndex: number; shown: boolean } | null>(null);
  const [hoveredPopup, setHoveredPopup] = useState<HTMLDivElement | null>(null);
  const [shownPopupCellData, setShownPopupCellData] = useState<ProjectsLoadCellData & { rowIndex: number; shown: boolean } | null>(null);
  const [visibleRows, setVisibleRows] = useState<typeof projectsLoadStore.visibleResources>([]);
  const [
    workPeriodModalData,
    setWorkPeriodModalData,
  ] = useState<Partial<Period> & {row: number} | undefined>();
  const [
    unavailabilityPeriodModalData,
    setUnavailabilityPeriodModalData,
  ] = useState<Partial<UnavailablePeriod> & {row: number} | undefined>();
  const { projectsLoadStore } = useStores();

  const hoveredDayData = useMemo(
    () => {
      if (!shownPopupCellData) {
        return null;
      }
      const project = projectsLoadStore.projects.find((e) => e.id === shownPopupCellData.projectId);
      const resource = project?.resourcesInProject.find((e) => e.id === shownPopupCellData.resourceId);
      const resourceProject = resource
        ?.projects
        ?.find((e) => e.projectId === shownPopupCellData.projectId);
      const periods = resourceProject?.loadResourceInProjects;
      const period = periods?.find((e) => {
        const startDateBeforeDay = !dayjs.utc(projectsLoadStore.startDate)
          .add(shownPopupCellData.day.index, projectsLoadStore.unitForDayJS)
          .isBefore(dayjs.utc(e.startDate), 'day');
        const finishDateAfterDay = !dayjs.utc(projectsLoadStore.startDate)
          .add(shownPopupCellData.day.index, projectsLoadStore.unitForDayJS)
          .isAfter(dayjs.utc(e.finishDate), 'day');

        return startDateBeforeDay && finishDateAfterDay;
      });
      const workingDaysOfWeek = period?.workingDaysOfWeek ?? resourceProject?.workingDaysOfWeek ?? undefined;

      if (!period) {
        return null;
      }
      const totalLoad = projectsLoadStore.resourcesLoad.get(resource!.id!)?.get(shownPopupCellData.day.index);

      return {
        project, resource, period, totalLoad, day: shownPopupCellData.day, workingDaysOfWeek,
      };
    },
    [shownPopupCellData, projectsLoadStore.projects, projectsLoadStore.resourcesLoad, projectsLoadStore.startDate, projectsLoadStore.unitForDayJS],
  );

  useEffect(() => {
    startTransition(() => {
      setVisibleRows(projectsLoadStore.visibleResources.sort((a) => (a.resourceId ? 1 : -1)));
    });
  }, [projectsLoadStore.visibleResources]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShownPopupCellData((prev) => {
        if (hoveredPopup) {
          return prev;
        }
        if (!hoveredCellData) {
          return null;
        }
        return { ...hoveredCellData, shown: true };
      });
    }, hoveredCellData ? 400 : 200);

    return () => clearTimeout(timeout);
  }, [hoveredCellData, hoveredPopup]);

  const addResourceLoad = useCallback(
    (period: Period, y: number): Promise<void> => projectsLoadStore.addResourceLoad(period, y),
    [projectsLoadStore],
  );

  const removeResourceLoad = useCallback(
    (startDate: dayjs.Dayjs, finishDate: dayjs.Dayjs, y: number): Promise<void> => projectsLoadStore.removeResourceLoad(startDate, finishDate, y),
    [projectsLoadStore],
  );

  const addUnavailabilityPeriod = useCallback(
    (period: UnavailablePeriod, y: number): Promise<void> => projectsLoadStore.addUnavailabilityPeriod(period, y),
    [projectsLoadStore],
  );

  const changeUnavailablePeriod = useCallback(
    (y: number, period: GetResourceInProjectsResponseUnavailableResourceItem, startDate: Dayjs, finishDate: Dayjs) => {
      projectsLoadStore.changeUnavailablePeriod(y, period, startDate, finishDate);
    },
    [projectsLoadStore],
  );

  const deleteUnavailabilityPeriod = useCallback(
    (y: number, period: GetResourceInProjectsResponseUnavailableResourceItem) => {
      projectsLoadStore.deleteUnavailabilityPeriod(y, period);
    },
    [projectsLoadStore],
  );

  const hoveredCellAddUnavailabilityPeriod = useCallback(
    (startDate: Dayjs, finishDate: Dayjs, row: number) => {
      setUnavailabilityPeriodModalData({ startDate, finishDate, row });
    },
    [],
  );

  const changePeriod = useCallback(
    (
      y: number,
      period: GetLoadResourceInProjectsResponseItemLoadResourceInProject,
      startDate: Dayjs,
      finishDate: Dayjs,
    ) => projectsLoadStore.changePeriod(y, period, startDate, finishDate),
    [projectsLoadStore],
  );

  const editableLoadRows = useMemo(() => visibleRows.filter((e) => e.canEditWorkload).map((e) => e.rowIndex), [visibleRows]);
  const editableUnavailablePeriodRows = useMemo(() => visibleRows.filter((e) => e.canEditUnavailability).map((e) => e.rowIndex), [visibleRows]);

  const onHourFieldClickHandler = useCallback(() => {
    if (!hoveredDayData || !shownPopupCellData) {
      return;
    }

    setWorkPeriodModalData({
      startDate: dayjs.utc(projectsLoadStore.startDate).add(hoveredDayData.day.index, projectsLoadStore.unitForDayJS),
      finishDate: dayjs.utc(projectsLoadStore.startDate).add(hoveredDayData.day.index, projectsLoadStore.unitForDayJS),
      workingHoursPerDay: hoveredDayData.day.hours,
      workingDaysOfWeek: hoveredDayData.workingDaysOfWeek,
      isWorkOnWeekends: hoveredDayData.period.isWorkOnWeekends,
      row: shownPopupCellData.rowIndex,
    });
    setHoveredPopup(null);
  }, [hoveredDayData, projectsLoadStore.startDate, projectsLoadStore.unitForDayJS, shownPopupCellData]);

  const onPeriodClickHandler = useCallback(() => {
    if (!hoveredDayData || !shownPopupCellData) {
      return;
    }
    setWorkPeriodModalData({
      startDate: hoveredDayData.period.startDate ? dayjs.utc(hoveredDayData.period.startDate) : undefined,
      finishDate: hoveredDayData.period.startDate ? dayjs.utc(hoveredDayData.period.finishDate) : undefined,
      workingHoursPerDay: hoveredDayData.period.workingHoursPerDay,
      workingDaysOfWeek: hoveredDayData.workingDaysOfWeek,
      isWorkOnWeekends: hoveredDayData.period.isWorkOnWeekends,
      row: shownPopupCellData.rowIndex,
    });
    setHoveredPopup(null);
  }, [hoveredDayData, shownPopupCellData]);

  return (
    <>
      <HoveredCell
        addResourceLoad={addResourceLoad}
        addUnavailabilityPeriod={hoveredCellAddUnavailabilityPeriod}
        columnWidth={projectsLoadStore.columnWidth}
        data={visibleRows}
        editableLoadRows={editableLoadRows}
        editableUnavailablePeriodRows={editableUnavailablePeriodRows}
        listRef={listRef}
        removeResourceLoad={removeResourceLoad}
        rowHeight={projectsLoadStore.rowHeight}
        scrollLeft={projectsLoadStore.scrollLeft}
        scrollTop={projectsLoadStore.scrollTop}
        startDate={projectsLoadStore.startDate}
        svgRef={ganttSVGRef}
      />
      <g>
        <g className="periods">
          {visibleRows.map((el) => (
            <g
              key={`period${el.rowIndex}`}
              id={`period${el.rowIndex}`}
            >
              {el.resourceId ? (
                <ResourceRow
                  changePeriod={el.canEditWorkload ? changePeriod : undefined}
                  changeUnavailablePeriod={el.canEditUnavailability ? changeUnavailablePeriod : undefined}
                  columnWidth={projectsLoadStore.columnWidth}
                  days={el.days}
                  deleteUnavailabilityPeriod={el.canEditUnavailability ? deleteUnavailabilityPeriod : undefined}
                  hoveredPeriodKey={`${shownPopupCellData?.rowIndex}-${hoveredDayData?.period.startDate}-${hoveredDayData?.period.finishDate}`}
                  onCellHover={(e) => setHoveredCellData(e ? {
                    ...e,
                    rowIndex: el.rowIndex,
                    shown: false,
                    projectId: el.projectId,
                    projectName: el.projectName,
                    resourceId: el.resourceId,
                    resourceName: el.resourceName,
                  } : null)}
                  periods={el.periods}
                  rowHeight={projectsLoadStore.rowHeight}
                  rowIndex={el.rowIndex}
                  startDate={projectsLoadStore.startDate}
                  unavailableResources={el.unavailableResources}
                  unitForDayJS={projectsLoadStore.unitForDayJS}
                />
              ) : (
                <ProjectRow
                  projectId={el.projectId}
                  rowIndex={el.rowIndex}
                />
              )}
            </g>
          ))}
        </g>
        {workPeriodModalData && (
          <WorkingPeriodModal
            {...workPeriodModalData}
            onClose={(e) => {
              if (e) {
                changePeriod(
                  workPeriodModalData.row,
                  {
                    ...e,
                    startDate: workPeriodModalData.startDate?.toISOString(),
                    finishDate: workPeriodModalData.finishDate?.toISOString(),
                  },
                  e.startDate,
                  e.finishDate,
                );
              }
              setWorkPeriodModalData(undefined);
            }}
            onDelete={(workPeriodModalData.startDate && workPeriodModalData.finishDate && editableLoadRows.includes(workPeriodModalData.row))
              ? () => {
                removeResourceLoad(
                  workPeriodModalData.startDate!,
                  workPeriodModalData.finishDate!,
                  workPeriodModalData.row,
                );
                setWorkPeriodModalData(undefined);
              }
              : undefined}
          />
        )}
        {unavailabilityPeriodModalData && (
          <UnavailablePeriodModal
            {...unavailabilityPeriodModalData}
            onClose={(e) => {
              if (e) {
                addUnavailabilityPeriod(e, unavailabilityPeriodModalData.row);
              }
              setUnavailabilityPeriodModalData(undefined);
            }}
          />
        )}
        {!workPeriodModalData && !unavailabilityPeriodModalData
        && shownPopupCellData && shownPopupCellData.cell && shownPopupCellData.shown
        && hoveredDayData && (
          <AboutDayPopup
            fields={[
              { label: 'Проект', value: hoveredDayData.project?.name ?? '' },
              { label: 'ФИО', value: hoveredDayData.resource?.name ?? '' },
              { label: 'Дата', value: shownPopupCellData.day.text },
              {
                label: 'Часов на данном проекте в этот день',
                value: String(hoveredDayData.day.weekend ? 0 : hoveredDayData.period?.workingHoursPerDay),
                onClick: editableLoadRows.includes(shownPopupCellData.rowIndex) ? () => onHourFieldClickHandler() : undefined,
              },
              { label: 'Всего часов в этот день', value: String(hoveredDayData.totalLoad) },
              {
                label: 'Период',
                value: [
                  dayjs(hoveredDayData.period.startDate).format('DD.MM.YYYY'),
                  '-',
                  dayjs(hoveredDayData.period.finishDate).format('DD.MM.YYYY'),
                  ' по ',
                  hoveredDayData.period.workingHoursPerDay,
                  ' ',
                  pluralize(hoveredDayData.period.workingHoursPerDay!, 'часу', 'часа', 'часов'),
                ].join(''),
                onClick: editableLoadRows.includes(shownPopupCellData.rowIndex) ? () => onPeriodClickHandler() : undefined,
              },
            ]}
            setHoveredPopup={setHoveredPopup}
            targetRef={shownPopupCellData.cell}
          />
        )}
      </g>
    </>
  );
};

export default observer(Data);
