import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { parseStyles } from '@services/utils';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Popup from '@components/Popup/Popup';
import type { DropdownProps, DropdownButton } from './types';
import Loader from '../Loader/Loader';
import buttonConfig from '../Button/button.config';

const Dropdown: React.FC<DropdownProps> = ({
  buttons, className, defaultButton, popupClassName, sameWidth, position = 'bottom-right',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  /** Реф для контейнера dropdown */
  const containerRef = useRef<HTMLDivElement>(null);
  /** Реф контейнера кнопок выпадашки */
  const buttonsContainerRef = useRef<HTMLDivElement>(null);
  /** Реф модалки */
  const popupRef = useRef<HTMLDivElement>(null);
  /** Возможность отображения выпадашки */
  const canShowPopup = isOpen && containerRef?.current;

  const [width, setWidth] = useState(0);
  // объект для того чтобы всегда перерендеривался даже если false -> false
  const [wrap, setWrap] = useState<{val: boolean}>({ val: false });

  /** Обработчик закрытия */
  const closeHandler = useCallback(() => {
    setIsOpen(false);
  }, []);

  /** Клик по кнопке из выпадашки */
  const onButtonClickHandler = useCallback((btn: DropdownButton) => {
    btn.onClick?.();
    closeHandler();
  }, [closeHandler]);

  const openCloseHandler = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  }, [isOpen]);

  /** Хук для клика вне компонента */
  useOnClickOutside(buttonsContainerRef, (e) => {
    if ((e.target as HTMLElement).id === 'toggle') {
      return;
    }
    closeHandler();
  });

  // сбрость подсчитанную ширину до wrap'а
  const reset = useCallback(() => {
    setWidth(0);
    setWrap({ val: false });
  }, []);

  // при смене параметров которые могли поменять ширину контента в кнопке пересчитываем ширину для wrap'а
  useEffect(() => {
    reset();
  }, [defaultButton?.label, className, reset]);

  useEffect(() => {
    const callback = () => {
      const curWidth = containerRef.current?.getBoundingClientRect().width ?? 0;
      setWrap((prev) => {
        /**
         * Если кнопка уже сжималась или до этого не сжималась, но ширина меньше - триггерим апдейт для нового подсчёта
         * влезает ли текст
         */
        if ((curWidth < width && !prev.val) || prev.val) {
          return { val: false };
        }
        return prev;
      });
    };
    const callback2 = () => {
      reset();
    };
    const resizeObserver = new ResizeObserver(callback);
    const mutationObserver = new MutationObserver(callback2);

    if (containerRef.current?.parentElement) {
      resizeObserver.observe(containerRef.current.parentElement);
      mutationObserver.observe(containerRef.current.parentElement, { childList: true });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [width, reset]);

  /**
   * Если ширина не установлена, значит на текущем рендере кнопка будет шириной для вмещения всего контента
   * записываем эту ширину как ширину, после которой нужно wrap'иться
   */
  useLayoutEffect(() => {
    if (!width) {
      const curWidth = containerRef.current?.getBoundingClientRect().width ?? 0;
      setWidth(curWidth);
    }
  });

  /**
   * Если рисуется главный лейбл кнопки и при этом он по ширине оказывается меньше width, значит кнопка "не влезла" и мы её wrap'им
   */
  useLayoutEffect(() => {
    const curWidth = containerRef.current?.getBoundingClientRect().width ?? 0;
    if (defaultButton?.isLoading || !width) {
      return;
    }
    if (curWidth < width && !wrap.val) {
      setWrap({ val: true });
    }
  });

  return (
    <div
      ref={containerRef}
      className={
        parseStyles`
          bg-btn-main-bg
          rounded-secondary
          ${!!width && 'overflow-hidden min-w-[30px]'}
          ${className}
        `
      }
    >
      <div
        className="flex h-full items-stretch self-end flex-1 cursor-pointer"
      >
        {(!wrap.val && defaultButton) && (
          <button
            className="
                text-white px-btn-default-x border-r-1 border-r-btn-accent py-btn-default-y h-btn-default text-btn-default
                rounded-l-secondary hover:bg-btn-main-bg-hover active:bg-btn-main-bg-active
              "
            {...defaultButton}
            type="button"
          >
            {(defaultButton?.isLoading && width)
              ? (
                <Loader
                  color={buttonConfig.theme.extend.colors['btn-main']}
                  size={15}
                />
              ) : defaultButton?.label}
          </button>
        )}
        <button
          className={
            parseStyles`
              min-w-[30px] flex justify-center hover:bg-btn-main-bg-hover active:bg-btn-main-bg-active
              ${defaultButton ? 'rounded-r-secondary' : 'rounded-secondary'}
            `
          }
          id="toggle"
          onClick={(e) => {
            if (e.target === document.activeElement) {
              setIsOpen(!isOpen);
            }
          }}
          onMouseDown={openCloseHandler}
          type="button"
        >
          <BsThreeDotsVertical
            className="py-1 h-full w-[20px] pointer-events-none"
            color="white"
          />
        </button>
      </div>
      {canShowPopup && (
        <Popup
          ref={popupRef}
          className={
            parseStyles`
              bg-white mt-1
              ${popupClassName}
            `
          }
          forceClose={() => setIsOpen(false)}
          position={position}
          sameWidth={sameWidth}
          targetRef={containerRef.current}
          catchFocus
        >
          <div
            ref={buttonsContainerRef}
            className="rounded-secondary flex flex-col"
          >
            {wrap.val && defaultButton && (
              <button
                key={defaultButton?.id ?? defaultButton?.label}
                className={parseStyles`
                  cursor-pointer px-btn-default-x py-btn-default-y h-btn-default text-btn-default mb-[2px] 
                  last:mb-0
                  focus-visible:bg-option-arrow-hover 
                  hover:bg-option-arrow-hover 
                  hover:rounded-secondary 
                `}
                onClick={() => onButtonClickHandler(defaultButton)}
                type="button"
              >
                {defaultButton.label}
              </button>
            )}
            {buttons?.map((btn) => (btn && (
              <button
                key={btn.id}
                className={
                  parseStyles`
                    cursor-pointer px-btn-default-x py-btn-default-y h-btn-default text-btn-default
                    focus-visible:bg-option-arrow-hover
                    hover:bg-option-arrow-hover
                    hover:rounded-secondary mb-[2px] last:mb-0
                  `
                }
                onClick={() => onButtonClickHandler(btn)}
                type="button"
              >
                {btn.label}
              </button>
            )))}
          </div>
        </Popup>
      )}
    </div>
  );
};

export default Dropdown;
