import React from 'react';
import { parseStyles } from '@services/utils';
import type { LabelProps } from './types';

/** Лэйбл (заголовок элемента) */
const Label: React.FC<LabelProps> = ({ children, htmlFor, className }) => (
  <label
    className={parseStyles`
      text-left text-label
      ${className}
    `}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

export default React.memo(Label);
