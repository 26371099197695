import { joinNonEmpty } from '@services/utils';
import { EmployeeService } from '@servicesApi';
import type { GetEmployeeNamesResponseItem } from '@typesApi';
import {
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';

class EmployeeNamesStore {
  @observable
    _namesList?: GetEmployeeNamesResponseItem[] | null;

  constructor() {
    makeAutoObservable(this);
  }

  /** Список наименований с соединенным полным именем сотрудника */
  @computed get namesList() {
    return this._namesList;
  }

  /** Получить сотрудников */
  public async fetchNames(search?: string, hasAccount?: boolean, showOnlyEditable?: boolean, excludedProjectId?: string) {
    const { data } = await EmployeeService.namesList({
      name: search, hasAccount, showOnlyEditable, excludedProjectId,
    });
    runInAction(() => {
      this._namesList = data.entities;
    });
    return data.entities;
  }

  /** Получить список опций для селекта */
  public async fetchEmployeeNames(search?: string, hasAccount?: boolean, showOnlyEditable?: boolean, excludedProjectId?: string) {
    const result = await this.fetchNames(search, hasAccount, showOnlyEditable, excludedProjectId);

    return result?.map((employee) => ({
      label: joinNonEmpty([employee.surname, employee.personName, employee.patronymic]),
      value: employee.id ?? '',
    }));
  }
}

export default EmployeeNamesStore;
