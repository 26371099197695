import { joinNonEmpty } from '@services/utils';
import type { TableItemView } from '@types';
import type { GetCareerMeetingsResponseItem, GetCareerMeetingsResponseItemInvitedEmployee } from '@typesHr';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, makeObservable, runInAction } from 'mobx';
import BaseViewModel from '../BaseViewModel';

export interface CareerMeetingFields {
  id: string;
  meetingDate: Dayjs | undefined;
  description: string | undefined;
  results: string | undefined;
  employeeFio: string | undefined;
  invitedEmployeesFIO: string | undefined;
}

/** Вью модель элемента списка */
export class CareerMeetingsItemViewModel extends BaseViewModel<GetCareerMeetingsResponseItem> implements TableItemView<
CareerMeetingFields,
GetCareerMeetingsResponseItem
> {
  constructor(data: GetCareerMeetingsResponseItem) {
    super(data);
    makeObservable(this);
  }

  /** id */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Изменение id */
  set id(value: string) {
    runInAction(() => {
      this.data.id = value;
    });
  }

  /** Время встречи */
  @computed get meetingDate() {
    return this.data.meetingDate ? dayjs(this.data.meetingDate) : undefined;
  }

  /** Время встречи */
  set meetingDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.meetingDate = value?.toISOString();
    });
  }

  /** описание */
  @computed get description() {
    return this.data.description ?? '';
  }

  /** Изменение Ставка на проекте */
  set description(value: string | undefined) {
    runInAction(() => {
      this.data.description = value;
    });
  }

  /** результат */
  @computed get results() {
    return this.data.results ?? '';
  }

  /** результат */
  set results(value: string | undefined) {
    runInAction(() => {
      this.data.results = value;
    });
  }

  /** Получить "ФИО сотрудника" */
  @computed get employeeFio() {
    return joinNonEmpty([this.data.employeeSurname, this.data.employeeName, this.data.employeePatronymic]);
  }

  /** Получить "Фамилия сотрудника" */
  @computed get employeeSurname() {
    return this.data.employeeSurname ?? '';
  }

  /** Изменить "Фамилия сотрудника" */
  set employeeSurname(value: string | undefined) {
    runInAction(() => {
      this.data.employeeSurname = value;
    });
  }

  /** Получить "Имя сотрудника" */
  @computed get employeeName() {
    return this.data.employeeName ?? '';
  }

  /** Изменить "Имя сотрудника" */
  set employeeName(value: string | undefined) {
    runInAction(() => {
      this.data.employeeName = value;
    });
  }

  /** Получить "Отчество сотрудника" */
  @computed get employeePatronymic() {
    return this.data.employeePatronymic ?? '';
  }

  /** Изменить "Отчество сотрудника" */
  set employeePatronymic(value: string | undefined) {
    runInAction(() => {
      this.data.employeePatronymic = value;
    });
  }

  /** Получение "Приглашенные сотрудники" */
  @computed get invitedEmployees() {
    return this.data.invitedEmployees ?? [];
  }

  /** Изменение "Приглашенные сотрудники" */
  set invitedEmployees(value: GetCareerMeetingsResponseItemInvitedEmployee[] | undefined) {
    runInAction(() => {
      this.data.invitedEmployees = value;
    });
  }

  /** Получение списка фио "Приглашенные сотрудники" */
  @computed get invitedEmployeesFIO() {
    return this.data.invitedEmployees?.map((x) => joinNonEmpty([x.surname, x.name, x.patronymic])).join(',\n') ?? '';
  }
}
