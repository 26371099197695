import { required, validationRequired } from '@services/validationFunctions';
import type { PutPasswordRequest } from '@typesApi';
import { computed, runInAction } from 'mobx';
import { ErrorMessages } from 'src/enums';
import BaseViewModel from '../BaseViewModel';

/** Модель изменения пароля */
class PasswordChangeViewModel extends BaseViewModel<PutPasswordRequest & {
  confirmPassword?: string;
}> {
/** Получение currentPassword */
  @required()
  @computed get currentPassword() {
    return this.data.currentPassword ?? '';
  }

  /** Изменение currentPassword */
  set currentPassword(value: string | undefined) {
    runInAction(() => {
      this.data.currentPassword = value;
    });
  }

  /** Получение newPassword */
  @required()
  @computed get newPassword() {
    return this.data.newPassword ?? '';
  }

  /** Изменение newPassword */
  set newPassword(value: string | undefined) {
    runInAction(() => {
      this.data.newPassword = value;
    });
  }

  /** Получение confirmPassword */
  @computed get confirmPassword() {
    return this.data.confirmPassword ?? '';
  }

  /** Изменение confirmPassword */
  set confirmPassword(value: string | undefined) {
    runInAction(() => {
      this.data.confirmPassword = value;
      this.validateField('confirmPassword', this.newPassword && value
        ? () => (this.newPassword !== value ? ErrorMessages.passwordSimilar : undefined)
        : validationRequired);
    });
  }
}

export default PasswordChangeViewModel;
