import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import type { EventTypes } from '@typesApi';
import type { ProjectRolesItemViewModel } from 'src/stores/Administration/ProjectRoles/ProjectRolesItemViewModel';
import { Select } from '@components/ui/Select';
import { FormInput } from '@components/ui/Input';
import { NotificationEvents } from '../types';

const yesOption = { label: 'Да', value: 'true' };
const noOption = { label: 'Нет', value: 'false' };
const defaultDaysToSend = 3;

/** Блок настройки уведомлений проектных ролей */
const NotificationsBlock: React.FC<{viewModel: ProjectRolesItemViewModel; disabled: boolean}> = ({ viewModel, disabled }) => {
  /** Изменение возможности уведомления через email */
  const emailNotificationChangeHandler = useCallback((eventType: keyof typeof NotificationEvents, value?: string) => {
    const active: boolean = value ? JSON.parse(value) : false;
    let settings = viewModel.notificationSettings?.slice();

    const findEvent = settings?.find((n) => n.eventType === eventType);

    if (findEvent) {
      if (!active) {
        settings = settings?.filter((i) => i.eventType !== eventType);
      } else {
        settings = settings?.map(((setting) => {
          if (setting.eventType === eventType) {
            return ({
              ...setting,
              hasEmailNotification: active,
            });
          }
          return setting;
        }));
      }
    } else {
      settings?.push({
        hasEmailNotification: active,
        daysToSend: defaultDaysToSend,
        eventType: eventType as EventTypes,
      });
    }
    viewModel.notificationSettings = settings;
  }, [viewModel]);

  /** Изменение количества дней до отправки уведомления */
  const daysToSendChangeHandler = useCallback((eventType: keyof typeof NotificationEvents, value: string) => {
    viewModel.notificationSettings = viewModel.notificationSettings?.map((setting) => {
      if (setting.eventType === eventType) {
        return ({
          ...setting,
          daysToSend: +value,
        });
      }
      return setting;
    });
  }, [viewModel]);

  return (
    <div className="grid grid-cols-3 gap-2 border-t-1 pt-4 items-center">
      <div className="font-bold">Уведомления</div>
      <div className="font-bold">Уведомление на email</div>
      <div className="font-bold">Время до отправки (дни)</div>
      {Object.entries(NotificationEvents).map(([key, value]) => {
        const eventType = key as keyof typeof NotificationEvents;
        const currentEvent = viewModel.notificationSettings
          ?.find((n) => n.eventType === eventType);

        return (
          <React.Fragment key={key}>
            <div
              className="line-clamp-2"
              title={value}
            >
              {value}
            </div>
            <Select.AutoComplete
              disabled={disabled}
              onSelect={(option) => option?.value && emailNotificationChangeHandler(eventType, option.value)}
              options={[yesOption, noOption]}
              selectedOption={currentEvent?.hasEmailNotification ? yesOption : noOption}
            />
            {viewModel.notificationsWithoutSendingTime.every((notice) => notice !== key)
              ? (
                <FormInput
                  disabled={!currentEvent?.hasEmailNotification || disabled}
                  onChange={(e) => daysToSendChangeHandler(eventType, e.target.value)}
                  placeholder={!currentEvent?.hasEmailNotification ? 'Включите уведомления' : ''}
                  type="number"
                  value={currentEvent?.daysToSend}
                />
              )
              : <div />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default observer(NotificationsBlock);
