import type { WithClassName } from '@components/ui/types';
import { parseStyles } from '@services/utils';
import type { PropsWithChildren } from 'react';
import React from 'react';

/** Лайаут бокового меню */
export const MenuLayout = React.forwardRef< HTMLDivElement, WithClassName & PropsWithChildren & {isOpen?: boolean}>(({
  children,
  className,
  isOpen,
}, ref) => (
  <div
    ref={ref}
    className={parseStyles`
      flex flex-col justify-between h-full min-w-left-menu text-left bg-main-menu-bg flex-initial
      duration-300 overflow-x-hidden scrollbar shrink-0
      ${isOpen ? 'w-openMenu' : 'w-closeMenu'}
      ${className}
    `}
    // Костыль для ресайза табличек
    onTransitionEnd={() => {
      window.dispatchEvent(new Event('resize'));
    }}
  >
    {children}
  </div>
));

MenuLayout.displayName = 'MenuLayout';
