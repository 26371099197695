import React from 'react';
import graphConfig from '../graph.config';

export const ProjectResourcesListHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
}> = ({
  headerHeight, rowWidth,
}) => (
  <div
    className="table border-graph-border border-x-0 bg-graph-header"
  >
    <div
      className="table-row list-none"
      style={{
        height: `calc(${headerHeight}px - ${graphConfig.theme.extend.borderWidth['graph-border']}*2)`,
      }}
    >
      <div
        className="table-cell align-middle text-font-main-bold truncate pl-2 border-r-graph-border"
        style={{
          minWidth: rowWidth,
        }}
      >
        Сотрудники
      </div>
    </div>
  </div>
);
