import { useStores } from '@hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ImSortAlphaAsc, ImSortAlphaDesc } from 'react-icons/im';
import graphConfig from '../graph.config';

const ResourcesListHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
}> = ({
  headerHeight, rowWidth,
}) => {
  const { resourcesLoadStore } = useStores();
  const { filter } = resourcesLoadStore;

  return (
    <div
      className="table border-graph-border border-x-0 bg-graph-header"
    >
      <div
        className="table-row list-none align-middle text-font-main-bold truncate pl-2 relative"
        style={{
          height: `calc(${headerHeight}px - ${graphConfig.theme.extend.borderWidth['graph-border']}*2)`,
        }}
      >
        <div
          className="select-none table-cell
            text-table-header-text font-bold
            border-r-graph-border
            w-full h-inherit p-0 box-border
            cursor-pointer align-middle
            hover:after:content-[''] hover:after:bg-headerHovered hover:after:w-full
            hover:after:h-full hover:after:absolute hover:after:top-0 hover:after:left-0 relative"
          onClick={() => {
            runInAction(() => {
              filter.orderBy = 'resourceName';
              filter.isAscending = !filter.isAscending;
              filter.pageNumber = 1;
            });
            resourcesLoadStore.fetch(true);
          }}
          style={{
            minWidth: rowWidth,
          }}
        >
          <div className="flex gap-1 items-center justify-center max-w-full m-[12px]">
            Ресурс
            {filter.isAscending && (
              <ImSortAlphaAsc
                className="shrink-0"
                height={20}
                width={20}
              />
            )}
            {filter.isAscending === false && (
              <ImSortAlphaDesc
                className="shrink-0"
                height={20}
                width={20}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ResourcesListHeader);
