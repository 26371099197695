import { CatalogService } from '@servicesApi';
import type { GetSingleCatalogResponseCatalogValue } from '@typesApi';
import { observable, runInAction } from 'mobx';
import type { BaseState } from '../StateStores';
import { ErrorState, FetchingState, SuccessState } from '../StateStores';

/** Стор реестра */
export class CatalogStore {
  // загруженные каталоги
  @observable
    catalogs: Map<string, GetSingleCatalogResponseCatalogValue[]> = observable.map<string, GetSingleCatalogResponseCatalogValue[]>();

  // состояния загрузки каталогов
  @observable
    catalogStates: Map<string, BaseState> = observable
      .map<string, BaseState>();

  async getCatalogValue(code: string): Promise<void> {
    const loadedValue = this.catalogs.get(code);
    const isAlreadyLoading = this.catalogStates.get(code) && !this.catalogStates.get(code)?.isError;
    if (loadedValue || isAlreadyLoading) {
      return;
    }
    this.catalogStates.set(code, new FetchingState());
    try {
      const { data: { catalogValues } } = await CatalogService.singleList({ code });
      runInAction(() => {
        this.catalogStates.set(code, new SuccessState());
        this.catalogs.set(code, catalogValues ?? []);
      });
    } catch (e) {
      runInAction(() => {
        this.catalogStates.set(code, new ErrorState(e));
      });
    }
  }
}
