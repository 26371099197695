import { useInterceptChanges, useStores, useConfirm } from '@hooks';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import DrawerWithWrapper from '@components/DrawerResourceLayout/DrawerWithWrapper';
import FormTextarea from '@components/ui/Input/FormTextarea';
import { FormInput } from '@components/ui';
import Button from '@components/ui/Button';
import Errors from '@components/ui/Errors/Errors';
import { ConfirmQuestions } from 'src/enums';

interface CounterpartyModalProps {
  onClose: () => void;
  id?: string;
}

const CounterpartyModal: React.FC<CounterpartyModalProps> = ({ onClose, id }) => {
  /** Стор контрагентов, контрагента */
  const { counterpartiesStore, counterpartyStore } = useStores();

  const { canCreate, canUpdate } = counterpartyStore;
  /** Модель, состояние измененности */
  const { viewModel, isDirty, resetIsDirty } = useInterceptChanges(counterpartyStore.viewModel);
  /** Реф для модалки подтверждения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  const [editing, setEditing] = useState(!id);

  /** Возможность отображения кнопки 'сохранить' */
  const canShowSaveButton = useMemo(() => id && editing, [id, editing]);
  /** Возможность отображения кнопки 'редактировать' */
  const canShowEditingButton = useMemo(() => !editing && id, [id, editing]);
  /** Возможность отображения кнопки 'добавить' */
  const canShowCreateButton = useMemo(() => !id, [id]);

  useEffectOnce(() => {
    if (id) {
      counterpartyStore.fetch(id);
    }
    return () => {
      counterpartyStore.clearStore();
    };
  });

  /** Обработчик создания контрагента */
  const createCounterpartyHandler = useCallback(async () => {
    if (viewModel.validate()) {
      await counterpartyStore.add();
    }
    if (counterpartyStore.state.isSuccess) {
      await counterpartiesStore.fetch();
      onClose();
    }
  }, [counterpartiesStore, counterpartyStore, onClose, viewModel]);

  /** Обработчик изменения контрагента */
  const updateCounterpartyHandler = useCallback(async () => {
    if (viewModel.validate()) {
      await counterpartyStore.save();
    }
    if (counterpartyStore.state.isSuccess) {
      await counterpartiesStore.fetch();
      onClose();
    }
  }, [counterpartiesStore, counterpartyStore, onClose, viewModel]);

  /** Обработчик отмены изменений */
  const cancelHandler = useCallback(async () => {
    if (isDirty && viewModel.id) {
      await counterpartyStore.fetch(viewModel.id);
      resetIsDirty();
    }
    setEditing(!editing);
  }, [isDirty, counterpartyStore, resetIsDirty, editing, viewModel.id]);

  /** Кнопки футера */
  const footer = useMemo(
    () => (
      <>
        {canShowSaveButton && (
          <Button.Primary
            key="save"
            className="self-end"
            isDisabled={!canUpdate}
            isLoading={counterpartyStore.state.isLoading}
            label="Сохранить"
            onClick={updateCounterpartyHandler}
          />
        )}
        {canShowCreateButton && (
          <Button.Primary
            key="create"
            className="self-end"
            isDisabled={!canCreate}
            isLoading={counterpartyStore.state.isLoading}
            label="Добавить"
            onClick={createCounterpartyHandler}
          />
        )}
        {canShowEditingButton && (
          <Button.Primary
            key="change"
            className="self-end"
            isDisabled={!canUpdate}
            isLoading={counterpartyStore.state.isLoading}
            label="Редактировать"
            onClick={() => setEditing(true)}
          />
        )}

        {editing && !canShowCreateButton && (
          <Button.Primary
            className="ml-2"
            label="Отменить"
            onClick={cancelHandler}
            type="outline"
          />
        )}
      </>
    ),
    [
      canShowSaveButton,
      canUpdate,
      counterpartyStore.state.isLoading,
      updateCounterpartyHandler,
      canShowCreateButton,
      canCreate,
      createCounterpartyHandler,
      canShowEditingButton,
      editing,
      cancelHandler],
  );

  const title = useMemo(
    () => (id
      ? `Контрагент ${viewModel.name} ${viewModel.isDeleted ? '(Архивный)' : ''}`.trim()
      : 'Создание контрагента'),
    [id, viewModel.isDeleted, viewModel.name],
  );

  return (
    <DrawerWithWrapper
      confirm={isDirty ? confirm : undefined}
      footer={footer}
      onClose={onClose}
      title={title}
    >
      <div className="flex flex-col gap-form">
        <FormInput
          disabled={!editing}
          error={viewModel.errors.name}
          label="Название"
          onChange={(event) => {
            viewModel.name = event.target.value;
          }}
          value={viewModel.name}
        />

        <FormInput
          disabled={!editing}
          label="ФИО ответственного"
          onChange={(event) => {
            viewModel.responsibleName = event.target.value;
          }}
          value={viewModel.responsibleName}
        />
        <FormTextarea
          disabled={!editing}
          label="Комментарий"
          onChange={(event) => {
            viewModel.comment = event.target.value;
          }}
          value={viewModel.comment}
        />
      </div>
      <Errors store={counterpartyStore} />
    </DrawerWithWrapper>
  );
};

export default observer(CounterpartyModal);
