import { EntityActions } from '@typesApi';
import { computed, makeObservable } from 'mobx';
import type { AccountStore } from '../Account/AccountStore';
import type { BasePrivileges } from './types';

export class ProjectsPrivileges implements BasePrivileges {
  private _privileges: EntityActions[];

  private _accountStore?: AccountStore;

  constructor(privileges: EntityActions[], accountStore?: AccountStore) {
    makeObservable(this);
    this._privileges = privileges;
    this._accountStore = accountStore;
  }

  @computed get canCreate() {
    return this._privileges.includes(EntityActions.CreateProjects);
  }

  @computed get canUpdate() {
    return this._privileges.includes(EntityActions.UpdateProjects);
  }

  @computed get canRead() {
    /** Пользователь задействован в проектах в качестве ресурса */
    const isResourceInProjects = !!this._accountStore?.viewModel.resourceInProjects?.length;
    /** Пользователь является ответственным в проекте */
    const isResponsibleInProjects = !!this._accountStore?.viewModel.projects?.length;

    return this._privileges.includes(EntityActions.ReadProjects) || isResourceInProjects || isResponsibleInProjects;
  }

  @computed get canDelete() {
    return this._privileges.includes(EntityActions.DeleteProjects);
  }

  @computed get canImport() {
    return this._privileges.includes(EntityActions.ImportEntities);
  }

  @computed get canReadProjectRoles() {
    return this._privileges.includes(EntityActions.ReadProjectRoles);
  }

  @computed get canUpdateResponsible() {
    return this._privileges.includes(EntityActions.UpdateResponsible);
  }

  /** Проверить возможность удаления */
  public canDeleteById(id?: string) {
    if (!id) {
      return this.canDelete;
    }

    return this.canDelete || this.isResponsibleFor(id);
  }

  private isResponsibleFor(id: string) {
    return this._accountStore?.responsibleInProjects.some((x) => id === x.id) ?? false;
  }
}
