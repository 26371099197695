import type { ListBaseFilter } from '@types';
import { EntityActions } from '@typesApi';
import { action, makeObservable, observable } from 'mobx';
import BaseEntityListStore from '../../BaseEntityListStore';
import { RolePrivilegesItemViewModel } from './RolePrivilegesItemViewModel';
import type { PrivilegesData } from './types';

/** Стор для таблицы привилегий у роли */
export class RolePrivilegesStore extends BaseEntityListStore<PrivilegesData, ListBaseFilter, RolePrivilegesItemViewModel> {
  /** Список привилегий */
  @observable public privileges: EntityActions[];

  constructor() {
    super();
    makeObservable(this);
    this.privileges = observable.array([]);
  }

  /** Заглушка метода fetch */
  public override async fetch(): Promise<void> {
    console.log('Method not implemented');
  }

  @action
  public setPrivileges(data: EntityActions[]): void {
    this.privileges = observable.array(data);
    const auditPrivileges: PrivilegesData = {
      id: 'audit',
      name: 'Аудит',
      readPrivilege: EntityActions.ReadAuditLog,
      createPrivilege: undefined,
      updatePrivilege: undefined,
      deletePrivilege: undefined,
      store: this,
    };
    const catalogPrivileges: PrivilegesData = {
      id: 'catalog',
      name: 'Справочник',
      readPrivilege: EntityActions.ReadCatalogs,
      createPrivilege: EntityActions.CreateCatalogs,
      updatePrivilege: EntityActions.UpdateCatalogs,
      deletePrivilege: EntityActions.DeleteCatalogs,
      store: this,
    };
    const contragentPrivileges: PrivilegesData = {
      id: 'contragent',
      name: 'Контрагент',
      readPrivilege: EntityActions.ReadContragents,
      createPrivilege: EntityActions.CreateContragents,
      updatePrivilege: EntityActions.UpdateContragents,
      deletePrivilege: EntityActions.DeleteContragents,
      store: this,
    };
    const organizationPrivileges: PrivilegesData = {
      id: 'organization',
      name: 'Организация',
      readPrivilege: undefined,
      createPrivilege: undefined,
      updatePrivilege: EntityActions.UpdateOrganization,
      deletePrivilege: undefined,
      store: this,
    };
    const projectPrivileges: PrivilegesData = {
      id: 'project',
      name: 'Проект',
      readPrivilege: EntityActions.ReadProjects,
      createPrivilege: EntityActions.CreateProjects,
      updatePrivilege: EntityActions.UpdateProjects,
      deletePrivilege: EntityActions.DeleteProjects,
      store: this,
    };
    const resourcePrivileges: PrivilegesData = {
      id: 'resource',
      name: 'Ресурс',
      readPrivilege: EntityActions.ReadResources,
      createPrivilege: EntityActions.CreateResources,
      updatePrivilege: EntityActions.UpdateResources,
      deletePrivilege: EntityActions.DeleteResources,
      store: this,
    };
    const rolePrivileges: PrivilegesData = {
      id: 'role',
      name: 'Системные роли',
      readPrivilege: EntityActions.ReadRoles,
      createPrivilege: EntityActions.CreateRoles,
      updatePrivilege: EntityActions.UpdateRoles,
      deletePrivilege: EntityActions.DeleteRoles,
      store: this,
    };
    const projectRolesPrivileges: PrivilegesData = {
      id: 'projectRole',
      name: 'Проектные роли',
      readPrivilege: EntityActions.ReadProjectRoles,
      createPrivilege: EntityActions.CreateProjectRoles,
      updatePrivilege: EntityActions.UpdateProjectRoles,
      deletePrivilege: EntityActions.DeleteProjectRoles,
      store: this,
    };
    const accountPrivileges: PrivilegesData = {
      id: 'account',
      name: 'Пользователь',
      readPrivilege: EntityActions.ReadAccounts,
      createPrivilege: EntityActions.InviteEmployees,
      updatePrivilege: EntityActions.UpdateAccounts,
      deletePrivilege: EntityActions.SetAccountState,
      store: this,
    };
    const positionSettingsPrivileges: PrivilegesData = {
      id: 'positionSettings',
      name: 'Настройки профессии',
      readPrivilege: EntityActions.ReadPositionSettings,
      createPrivilege: EntityActions.CreatePositionSettings,
      updatePrivilege: EntityActions.UpdatePositionSettings,
      deletePrivilege: EntityActions.DeletePositionSettings,
      store: this,
    };
    const salaryPrivileges: PrivilegesData = {
      id: 'salaryPeriod',
      name: 'Зарплатный период',
      readPrivilege: EntityActions.ReadSalaryPeriods,
      createPrivilege: EntityActions.CreateSalaryPeriods,
      updatePrivilege: EntityActions.UpdateSalaryPeriods,
      deletePrivilege: EntityActions.DeleteSalaryPeriods,
      store: this,

    };
    const careerMeetingsPrivileges: PrivilegesData = {
      id: 'careerMeeting',
      name: 'Карьерная встреча',
      readPrivilege: EntityActions.ReadCareerMeetings,
      createPrivilege: undefined,
      updatePrivilege: EntityActions.UpdateCareerMeetings,
      deletePrivilege: undefined,
      store: this,
    };
    const responsiblePrivileges: PrivilegesData = {
      id: 'responsible',
      name: 'Ответственный',
      readPrivilege: undefined,
      createPrivilege: undefined,
      updatePrivilege: EntityActions.UpdateResponsible,
      deletePrivilege: undefined,
      store: this,
    };
    const projectRequirementsPrivileges: PrivilegesData = {
      id: 'projectRequirements',
      name: 'Потребности проекта',
      readPrivilege: EntityActions.ReadProjectRequirements,
      createPrivilege: EntityActions.CreateProjectRequirements,
      updatePrivilege: EntityActions.UpdateProjectRequirements,
      deletePrivilege: EntityActions.DeleteProjectRequirements,
      store: this,
    };
    const importPrivileges: PrivilegesData = {
      id: 'import',
      name: 'Импорт сотрудников',
      readPrivilege: undefined,
      createPrivilege: EntityActions.ImportEntities,
      updatePrivilege: undefined,
      deletePrivilege: undefined,
      store: this,
    };

    this.setData({
      entities: [
        auditPrivileges,
        catalogPrivileges,
        contragentPrivileges,
        organizationPrivileges,
        projectPrivileges,
        resourcePrivileges,
        rolePrivileges,
        projectRolesPrivileges,
        accountPrivileges,
        positionSettingsPrivileges,
        salaryPrivileges,
        careerMeetingsPrivileges,
        responsiblePrivileges,
        projectRequirementsPrivileges,
        importPrivileges,
      ],
      totalCount: 12,
    }, RolePrivilegesItemViewModel);
  }
}
