import DateHeader from '@components/TableHeaders/DateHeader';
import InputHeader from '@components/TableHeaders/InputHeader';
import { Accordion, Panel } from '@components/ui/Accordion';
import Button from '@components/ui/Button';
import Errors from '@components/ui/Errors/Errors';
import { Column, Table } from '@components/ui/Table';
import { CareerMeetingService } from '@servicesHr';
import type { GetCareerMeetingsResponseItem } from '@typesHr';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TiDelete } from 'react-icons/ti';
import type { CareerMeetingFields } from 'src/stores/CareerMeetings/CareerMeetingsItemViewModel';
import { CareerMeetingsStore } from 'src/stores/CareerMeetings/CareerMeetingsStore';
import CareerMeetingForm from './CareerMeetingForm';

/** Карьерные встречи (компонент карточки сотрудника) */
const CareerMeetings: FC<{
  /** Идентификатор сотрудника */
  employeeId: string;
}> = ({ employeeId }) => {
  const careerMeetingsStore = useMemo(() => new CareerMeetingsStore(), []);
  const invitedCareerMeetingsStore = useMemo(() => new CareerMeetingsStore(), []);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [careerMeetingId, setCareerMeetingId] = useState<string | undefined>();

  useEffect(() => {
    if (employeeId) {
      careerMeetingsStore.filter.employeeId = employeeId;
      invitedCareerMeetingsStore.filter.invitedEmployeesIds = [employeeId];
      careerMeetingsStore.fetch();
      invitedCareerMeetingsStore.fetch();
    }
    return () => {
      careerMeetingsStore.clearStore();
    };
  }, [careerMeetingsStore, employeeId, invitedCareerMeetingsStore]);

  const onSaveHandler = useCallback(async () => {
    if (!employeeId) {
      return;
    }
    careerMeetingsStore.filter.employeeId = employeeId;
    invitedCareerMeetingsStore.filter.invitedEmployeesIds = [employeeId];
    careerMeetingsStore.fetch();
    invitedCareerMeetingsStore.fetch();
  }, [careerMeetingsStore, employeeId, invitedCareerMeetingsStore]);

  const onRowClickHandler = useCallback((id: string) => {
    setCareerMeetingId(id);
    setShowModal(true);
  }, []);

  /** ячейка удаление встречи */
  const RowDeleteCell = useCallback<React.FC<{data: CareerMeetingFields}>>(
    ({ data }) => (
      <div className="flex flex-col w-full h-full items-center justify-center">
        <TiDelete
          className="w-full h-[20px] my-auto cursor-pointer"
          onClick={async () => {
            await CareerMeetingService.delete(data.id, { deletedOn: dayjs().toISOString() });
            careerMeetingsStore.filter.employeeId = employeeId;
            careerMeetingsStore.fetch();
          }}
        />
      </div>
    ),
    [careerMeetingsStore, employeeId],
  );

  /** хедер колонки "Время встречи" для таблицы "Карьерные встречи" */
  const meetingDateHeader = useCallback<React.FC>(() => (
    <DateHeader
      fromDateField="meetingDateFrom"
      store={careerMeetingsStore}
      toDateField="meetingDateTo"
      withTime
    />
  ), [careerMeetingsStore]);

  /** хедер колонки "Время встречи" для таблицы "Карьерные встречи, куда приглашен сотрудник" */
  const invitedMeetingDateHeader = useCallback<React.FC>(() => (
    <DateHeader
      fromDateField="meetingDateFrom"
      store={invitedCareerMeetingsStore}
      toDateField="meetingDateTo"
      withTime
    />
  ), [invitedCareerMeetingsStore]);

  /** хедер колонки "Результат" для таблицы "Карьерные встречи" */
  const resultsHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="results"
      store={careerMeetingsStore}
    />
  ), [careerMeetingsStore]);

  /** хедер колонки Результат для таблицы "Карьерные встречи, куда приглашен сотрудник" */
  const invitedResultHeader = useCallback<React.FC>(() => (
    <InputHeader
      filterField="results"
      store={invitedCareerMeetingsStore}
    />
  ), [invitedCareerMeetingsStore]);

  return (
    <Accordion
      openedIds={['CareerMeetings']}
    >
      <Panel
        header="Карьерные встречи"
        headerButtons={(
          <div className="w-full flex flex-row justify-end">
            <Button.Primary
              className="ml-2"
              label="Создать"
              onClick={() => {
                setShowModal(true);
              }}
            />
          </div>
        )}
        id="CareerMeetings"
      >
        <div className="flex flex-col gap-card py-5">
          <div className="font-bold">Карьерные встречи</div>
          <div>
            <Table
              onRowClick={onRowClickHandler}
              rowHeight={30}
              store={careerMeetingsStore}
            >
              <Column
                header="Время встречи"
                sortExpr="meetingDate"
              >
                <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                  key="meetingDate"
                  dataType="date"
                  header={meetingDateHeader}
                  keyExpr="meetingDate"
                  withTime
                />
              </Column>
              <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                dataType="string"
                header="Приглашенные сотрудники"
                keyExpr="invitedEmployeesFIO"
              />
              <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                dataType="string"
                header="Описание"
                keyExpr="description"
                sortExpr="description"
              />
              <Column
                header="Результат"
                sortExpr="results"
              >
                <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                  key="results"
                  dataType="string"
                  header={resultsHeader}
                  keyExpr="results"
                />
              </Column>
              <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                key="delete"
                dataType="custom"
                header=""
                template={RowDeleteCell}
                width={20}
              />
            </Table>
            <Errors store={careerMeetingsStore} />
            <hr className="pb-4" />
          </div>
          <div className="font-bold">Карьерные встречи, куда приглашен сотрудник</div>
          <div>
            <Table
              onRowClick={onRowClickHandler}
              rowHeight={30}
              store={invitedCareerMeetingsStore}
            >
              <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                dataType="string"
                header="Сотрудник"
                keyExpr="employeeFio"
              />
              <Column
                header="Время встречи"
                sortExpr="meetingDate"
              >
                <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                  key="meetingDate"
                  dataType="date"
                  header={invitedMeetingDateHeader}
                  keyExpr="meetingDate"
                  withTime
                />
              </Column>
              <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                key="description"
                dataType="string"
                header="Описание"
                keyExpr="description"
              />
              <Column
                header="Результат"
                sortExpr="results"
              >
                <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                  key="results"
                  dataType="string"
                  header={invitedResultHeader}
                  keyExpr="results"
                />
              </Column>
              <Column<CareerMeetingFields, GetCareerMeetingsResponseItem>
                key="delete"
                dataType="custom"
                header=""
                template={RowDeleteCell}
                width={20}
              />
            </Table>
            <Errors store={invitedCareerMeetingsStore} />
          </div>
          { showModal && (
            <CareerMeetingForm
              employeeId={employeeId}
              id={careerMeetingId}
              onClose={() => {
                setCareerMeetingId(undefined);
                setShowModal(false);
              }}
              onSave={() => {
                onSaveHandler();
              }}
            />
          )}
        </div>
      </Panel>
    </Accordion>
  );
};

export default observer(CareerMeetings);
