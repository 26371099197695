import React from 'react';
import Title from './ui/Title/Title';

const ListHeader: React.FC<{title?: string; buttons: React.ReactNode}> = ({ title, buttons }) => (
  <div className="flex items-center h-header justify-between flex-shrink-0">
    {title && (<Title>{title}</Title>)}
    {buttons && (
      <div className="flex gap-form overflow-hidden flex-1 [&>*:first-child]:ml-auto">
        {buttons}
      </div>
    )}
  </div>
);

export default ListHeader;
