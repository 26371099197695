import { ProjectRoleService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { EventTypes, GetProjectRolesResponseItem } from '@typesApi';
import { ProjectActions } from '@typesApi';
import { computed, makeObservable, observable } from 'mobx';
import BaseEntityListStore from '../../BaseEntityListStore';
import { ProjectRolesItemViewModel } from './ProjectRolesItemViewModel';
import type { PrivilegesStore } from '../../Privileges/PrivilegesStore';

/** Фильтр для получения списка проектных ролей */
interface RolesFilter extends ListBaseFilter {
  /** Проектные роли */
  projectRoles?: string[];
  /** Название роли */
  projectRoleName?: string;
  /** Действия на проекте */
  projectActions?: ProjectActions[];
  /** Тип события */
  eventTypes?: EventTypes[];
  /** Удалённые проектные роли */
  isDeleted?: boolean;
  /** Сортировка по наличию действия */
  hasProjectAction?: boolean;
  /** Действия на проекте */
  projectAction?: ProjectActions;
}

/** Стор для таблицы проектных ролей */
export class ProjectRolesStore extends BaseEntityListStore<GetProjectRolesResponseItem, RolesFilter, ProjectRolesItemViewModel> {
  // private hasProjectAction: boolean;
  @observable private _privilegesStore: PrivilegesStore;

  constructor(privilegesStore: PrivilegesStore) {
    super();
    makeObservable(this);
    this.filter.hasProjectAction = false;
    this._privilegesStore = privilegesStore;
  }

  public override get canCreate(): boolean {
    return this._privilegesStore.roles.canCreateProjectRoles;
  }

  public override get canDelete(): boolean {
    return this._privilegesStore.roles.canDeleteProjectRoles;
  }

  public override get canUpdate(): boolean {
    return this._privilegesStore.roles.canUpdateProjectRoles;
  }

  public override get canRead(): boolean {
    return this._privilegesStore.roles.canReadProjectRoles;
  }

  /** Получение списка проектных ролей */
  public override async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ProjectRoleService.list(this.actionFilter);
      this.setData(data as {
        entities: GetProjectRolesResponseItem[];
        totalCount: number;
      }, ProjectRolesItemViewModel);
    });
  }

  /** Удаление ролей */
  public async delete() {
    const selectedIds = this.viewModel.filter((model) => model.selected).map((model) => model.id);
    await this.runWithStateControl(async () => {
      await Promise.all(selectedIds.map((id) => ProjectRoleService.delete(id)));
      await this.fetch();
    });
  }

  /** Получить фильтр для списка */
  @computed get actionFilter(): RolesFilter {
    const privilegeOrderBy = Object.keys(ProjectActions).find((x) => x.toLowerCase() === this.filter.orderBy?.toLowerCase());
    if (privilegeOrderBy) {
      this.filter.hasProjectAction = !this.filter.hasProjectAction;
      return {
        ...this.filter, projectAction: privilegeOrderBy as ProjectActions, orderBy: undefined,
      };
    }
    return this.filter;
  }
}
