import type { EntityActions, GetAccountByIdResponseEmployeeRoleItem } from '@typesApi';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import type { AccountRolesTableItem } from '@types';
import BaseViewModel from '../BaseViewModel';

/** Модель роли аккаунта */
export class AccountRolesItemViewModel extends BaseViewModel<GetAccountByIdResponseEmployeeRoleItem> implements AccountRolesTableItem {
  /** выбрана ли роль */
  @observable
    selected: boolean = false;

  constructor(data: GetAccountByIdResponseEmployeeRoleItem) {
    super(data);
    makeObservable(this);
  }

  /** Получить 'Ид роли' */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получить 'имя роли' */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменить 'имя роли' */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получить 'Ид глобальной роли сотрудника' */
  @computed get employeeRoleId() {
    return this.data.employeeRoleId ?? '';
  }

  /** Изменить 'Ид глобальной роли сотрудника' */
  set employeeRoleId(value: string | undefined) {
    runInAction(() => {
      this.data.employeeRoleId = value;
    });
  }

  /** Получить 'Логин - email' */
  @computed get entityActions() {
    return this.data.entityActions ?? [];
  }

  /** Изменить 'Логин - email' */
  set entityActions(value: EntityActions[]) {
    runInAction(() => {
      this.data.entityActions = value;
    });
  }
}
