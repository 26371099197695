import React from 'react';
import type { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { parseStyles } from '@services/utils';
import type { WithClassName } from './ui';

/** Лайаут блока с контентом */
const ContentLayout: React.FC<WithClassName & PropsWithChildren> = ({ children, className }) => (
  <div
    className={parseStyles`
      flex flex-1 px-5 text-left pb-4
      [&_.page-content]:self-center [&_.page-content]:lg:w-[60%] [&_.page-content]:xl:w-[80%] 
      scrollbar
      ${className}
    `}
  >
    {children ?? <Outlet />}
  </div>
);

export default ContentLayout;
