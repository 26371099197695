import type { SelectOption } from '@components/ui/Select';
import { joinNonEmpty } from '@services/utils';
import { dateIntersection, min, required } from '@services/validationFunctions';
import type { GetEquipmentByIdResponse, GetEquipmentByIdResponseProject } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { computed, runInAction } from 'mobx';
import { ErrorMessages } from 'src/enums';
import BaseViewModel from '../BaseViewModel';

class EquipmentViewModel extends BaseViewModel<GetEquipmentByIdResponse> {
  @computed get id() {
    return this.data.id;
  }

  @required()
  @computed get name() {
    return this.data.name ?? '';
  }

  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  @computed get typeId() {
    return this.data.typeId ?? '';
  }

  set typeId(value: string | undefined) {
    runInAction(() => {
      this.data.typeId = value;
    });
  }

  @required()
  @computed get typeName() {
    return this.data.typeName;
  }

  set typeName(value: string | undefined | null) {
    runInAction(() => {
      this.data.typeName = value;
    });
  }

  @computed get inventoryNumber() {
    return this.data.inventoryNumber ?? '';
  }

  set inventoryNumber(value: string | undefined) {
    runInAction(() => {
      this.data.inventoryNumber = value;
    });
  }

  @computed get parameters() {
    return this.data.parameters ?? '';
  }

  set parameters(value: string | undefined) {
    runInAction(() => {
      this.data.parameters = value;
    });
  }

  @computed get placeId() {
    return this.data.placeId;
  }

  set placeId(value: string | undefined | null) {
    runInAction(() => {
      this.data.placeId = value;
    });
  }

  @computed get placeName() {
    return this.data.placeName;
  }

  set placeName(value: string | undefined | null) {
    runInAction(() => {
      this.data.placeName = value;
    });
  }

  /** Выбранное помещение */
  @computed get selectedPlace(): SelectOption {
    return ({ label: this.placeName ?? '', value: this.placeId ?? '' });
  }

  @computed get employeeId() {
    return this.data.employeeId;
  }

  set employeeId(value: string | undefined | null) {
    runInAction(() => {
      this.data.employeeId = value;
    });
  }

  @computed get employeeName() {
    return this.data.employeeName;
  }

  set employeeName(value: string | undefined | null) {
    runInAction(() => {
      this.data.employeeName = value;
    });
  }

  @dateIntersection('useStartDate', 'endOfLifeDate')
  @computed get useStartDate() {
    return this.data.useStartDate ? dayjs(this.data.useStartDate) : undefined;
  }

  set useStartDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.useStartDate = value?.toISOString();
    });
  }

  @dateIntersection('useStartDate', 'endOfLifeDate')
  @computed get endOfLifeDate() {
    return this.data.endOfLifeDate ? dayjs(this.data.endOfLifeDate) : undefined;
  }

  set endOfLifeDate(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.endOfLifeDate = value?.toISOString();
    });
  }

  @dateIntersection('lastTechnicalInspection', 'nextTechnicalInspection', ErrorMessages.technicalInspectionDates)
  @computed get lastTechnicalInspection() {
    return this.data.lastTechnicalInspection ? dayjs(this.data.lastTechnicalInspection) : undefined;
  }

  set lastTechnicalInspection(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.lastTechnicalInspection = value?.toISOString();
    });
  }

  @dateIntersection('lastTechnicalInspection', 'nextTechnicalInspection', ErrorMessages.technicalInspectionDates)
  @computed get nextTechnicalInspection() {
    return this.data.nextTechnicalInspection ? dayjs(this.data.nextTechnicalInspection) : undefined;
  }

  set nextTechnicalInspection(value: Dayjs | undefined) {
    runInAction(() => {
      this.data.nextTechnicalInspection = value?.toISOString();
    });
  }

  @min(0)
  @computed get initialCost() {
    return this.data.initialCost;
  }

  set initialCost(value: number | undefined | null) {
    runInAction(() => {
      this.data.initialCost = value;
    });
  }

  @computed get responsibleId() {
    return this.data.responsibleId;
  }

  set responsibleId(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleId = value;
    });
  }

  /** Получение responsibleSurname */
  @computed get responsibleSurname() {
    return this.data.responsibleSurname;
  }

  /** Изменение responsibleSurname */
  set responsibleSurname(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleSurname = value;
    });
  }

  /** Получение responsibleName */
  @computed get responsibleName() {
    return this.data.responsibleName;
  }

  /** Изменение responsibleName */
  set responsibleName(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsibleName = value;
    });
  }

  /** Получение responsiblePatronymic */
  @computed get responsiblePatronymic() {
    return this.data.responsiblePatronymic;
  }

  /** Изменение responsiblePatronymic */
  set responsiblePatronymic(value: string | undefined | null) {
    runInAction(() => {
      this.data.responsiblePatronymic = value;
    });
  }

  /** Получение responsibleFullName */
  @computed get responsibleFullName() {
    return joinNonEmpty([this.data.responsibleSurname, this.data.responsibleName, this.data.responsiblePatronymic], ' ');
  }

  @computed get responsible(): SelectOption | undefined {
    return this.responsibleId ? {
      label: joinNonEmpty([this.responsibleSurname, this.responsibleName, this.responsiblePatronymic]),
      value: this.responsibleId,
    } : undefined;
  }

  set responsible(val: SelectOption | undefined) {
    this.responsibleName = val?.label;
    this.responsibleId = val?.value;
    this.responsiblePatronymic = undefined;
    this.responsibleSurname = undefined;
  }

  @computed get projects() {
    return this.data.projects;
  }

  set projects(value: GetEquipmentByIdResponseProject[] | undefined | null) {
    runInAction(() => {
      this.data.projects = value;
    });
  }

  @computed get deletedOn() {
    return this.data.deletedOn;
  }

  /** Получение флага Архивная сущность */
  @computed get isDeleted() {
    return this.data.isDeleted ?? false;
  }
}

export default EquipmentViewModel;
