import { createContext } from 'react';
import { CatalogStore } from 'src/stores/Catalog/CatalogStore';
import ProjectRequirementStore from 'src/stores/ProjectRequirement/ProjectRequirementStore';
import ProjectNamesStore from 'src/stores/Projects/ProjectNamesStore';
import { ResourcesStore } from 'src/stores/Resources/ResourcesStore';
import PlaceStore from 'src/stores/Place/PlaceStore';
import EquipmentStore from 'src/stores/Equipment/EquipmentStore';
import { RoleStore } from 'src/stores/Administration/Role/RoleStore';
import { ProjectRoleStore } from 'src/stores/Administration/ProjectRoles/ProjectRoleStore';
import { SalaryPeriodsStore } from 'src/stores/SalaryPeriods/SalaryPeriodsStore';
import { CareerMeetingsStore } from 'src/stores/CareerMeetings/CareerMeetingsStore';
import ProjectsStore from 'src/stores/Projects/ProjectsStore';
import ProjectStore from 'src/stores/Project/ProjectStore';
import { ProjectRequirementsStore } from 'src/stores/ProjectRequirements/ProjectRequirementsStore';
import { SalaryPeriodStore } from 'src/stores/SalaryPeriod/SalaryPeriodStore';
import { CareerMeetingStore } from 'src/stores/CareerMeeting/CareerMeetingStore';
import EmployeeNamesStore from 'src/stores/Employees/EmployeeNamesStore';
import { EmployeesStore } from 'src/stores/Employees/EmployeesStore';
import { EmployeeStore } from 'src/stores/Employee/EmployeeStore';
import EquipmentsStore from 'src/stores/Equipments/EquipmentsStore';
import PlacesStore from 'src/stores/Places/PlacesStore';
import PlaceNamesStore from 'src/stores/Place/PlaceNamesStore';
import { AccountsStore } from 'src/stores/Accounts/AccountsStore';
import InviteStore from 'src/stores/Accounts/InviteStore';
import { ProjectRolesStore } from 'src/stores/Administration/ProjectRoles/ProjectRolesStore';
import { RolesStore } from 'src/stores/Administration/Roles/RolesStore';
import { RolePrivilegesStore } from 'src/stores/Administration/Role/RolePrivilegesStore';
import EmployeesInProjectStore from 'src/stores/Project/EmployeesInProjectStore';
import EmployeeInProjectStore from 'src/stores/Project/EmployeeInProjectStore';
import { ResourceInProjectStore } from 'src/stores/ResourceInProject/ResourceInProjectStore';
import { ProjectRolesNamesStore } from 'src/stores/ProjectRolesNames/ProjectRolesNamesStore';
import { CounterpartiesStore } from 'src/stores/Counterparties/CounterpartiesStore';
import { CounterpartyStore } from 'src/stores/Counterparty/CounterpartyStore';
import { PrivilegesStore } from 'src/stores/Privileges/PrivilegesStore';
import { ProjectsLoadStore } from 'src/stores/ProjectsLoad/ProjectsLoadStore';
import { ResourcesLoadStore } from 'src/stores/ResourcesLoad/ResourcesLoadStore';
import { ProjectLoadStore } from 'src/stores/ProjectLoad/ProjectLoadStore';
import { ResourceLoadStore } from 'src/stores/ResourceLoad/ResourceLoadStore';
import SubscriptionsStore from 'src/stores/Subscriptions/SubscriptionsStore';
import ResourcesInProjectStore from 'src/stores/Project/ResourcesInProjectStore';
import { OrganizationStore } from 'src/stores/Organization/OrganizationStore';
import { AccountStore } from '../stores/Account/AccountStore';

const accountStore = new AccountStore();
const privilegesStore = new PrivilegesStore(accountStore);
const employeeNamesStore = new EmployeeNamesStore();
const placeNamesStore = new PlaceNamesStore();
const rolePrivilegesListStore = new RolePrivilegesStore();
const projectRolesStore = new ProjectRolesStore(privilegesStore);

export const storeContext = createContext({
  accountStore,
  privilegesStore,
  projectRolesStore,
  accountsStore: new AccountsStore(privilegesStore),
  employeeStore: new EmployeeStore(privilegesStore, employeeNamesStore),
  employeesStore: new EmployeesStore(privilegesStore),
  employeeNamesStore: new EmployeeNamesStore(),
  catalogStore: new CatalogStore(),
  projectStore: new ProjectStore(privilegesStore, employeeNamesStore),
  projectsStore: new ProjectsStore(privilegesStore, accountStore),
  projectNamesStore: new ProjectNamesStore(),
  projectRequirementStore: new ProjectRequirementStore(),
  projectRequirementsStore: new ProjectRequirementsStore(),
  salaryPeriodStore: new SalaryPeriodStore(),
  salaryPeriodsStore: new SalaryPeriodsStore(),
  careerMeetingStore: new CareerMeetingStore(),
  careerMeetingsStore: new CareerMeetingsStore(),
  resourcesStore: new ResourcesStore(),
  roleStore: new RoleStore(rolePrivilegesListStore, privilegesStore),
  rolesStore: new RolesStore(privilegesStore),
  projectRoleStore: new ProjectRoleStore(privilegesStore),
  placesStore: new PlacesStore(privilegesStore),
  placeStore: new PlaceStore(privilegesStore, employeeNamesStore),
  equipmentsStore: new EquipmentsStore(privilegesStore),
  inviteStore: new InviteStore(),
  equipmentStore: new EquipmentStore(placeNamesStore, employeeNamesStore, privilegesStore),
  employeesInProjectStore: new EmployeesInProjectStore(),
  employeeInProjectStore: new EmployeeInProjectStore(employeeNamesStore, privilegesStore),
  resourceInProjectStore: new ResourceInProjectStore(),
  projectRolesNamesStore: new ProjectRolesNamesStore(projectRolesStore),
  counterpartiesStore: new CounterpartiesStore(privilegesStore),
  counterpartyStore: new CounterpartyStore(privilegesStore),
  placeNamesStore,
  projectLoadStore: new ProjectLoadStore(privilegesStore),
  projectsLoadStore: new ProjectsLoadStore(privilegesStore),
  resourceLoadStore: new ResourceLoadStore(privilegesStore),
  resourcesLoadStore: new ResourcesLoadStore(),
  subscriptionsStore: new SubscriptionsStore(),
  resourcesInProjectStore: new ResourcesInProjectStore(),
  organizationStore: new OrganizationStore(),
});
