import type { ReactNode } from 'react';
import { Children } from 'react';
import {
  isCustomColumn,
  isCustomColumnProps,
  isGroupColumn,
  isGroupColumnProps,
} from './typeGuards';
import type {
  Column,
  ColumnProps,
  CustomColumn,
  GroupColumn,
  Row,
  TypedColumn,
} from './types';
import type ColumnComponent from './Column';

export function getColumnKey<T extends Row, K>(col: Column<T, K>) {
  return col.key ?? (typeof col.header === 'string' ? col.header : col.header.displayName);
}

/** сравнивает равны ли children для таблицы */
export function childrenSame(prev: ReactNode | ReactNode[], cur: ReactNode | ReactNode[]): boolean {
  return Children.toArray(prev).every((e, i) => {
    const curProps = (Children.toArray(cur)[i] as React.ReactElement).props as Object;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const prevProps = (e as React.ReactElement).props as any;

    return Object.entries(curProps).every(([k, val]) => {
      // если проверяемый prop - children, повторяем вложенную проверку
      if (k === 'children') {
        return childrenSame(prevProps[k] as ReactNode | ReactNode[], val);
      }
      // если проверяемый prop observer(), тогда проверяем по displayName
      if (typeof val === typeof prevProps[k]
        && typeof val === 'object'
        && val.$$typeof === prevProps[k].$$typeof
        && val.$$typeof.toString() === Symbol('react.memo').toString()
      ) {
        return val.displayName !== undefined && val.displayName === prevProps[k].displayName;
      }
      return prevProps[k] === val;
    });
  });
}

// парсинг из children пропсов колонок
export function parseChildrenColumns<T extends Row, EntityT >(
  columns: React.ReactElement<ColumnProps<T, EntityT>, typeof ColumnComponent>[],
): Column<T, EntityT>[] {
  return columns?.map((col) => {
    const colProps = col.props;
    if (isGroupColumnProps(colProps)) {
      return {
        key: col.key,
        header: colProps.header,
        readonly: colProps.readonly,
        sortExpr: colProps.sortExpr,
        sortable: colProps.sortable,
        columns: parseChildrenColumns([colProps.children].flat()),
        pinned: colProps.pinned,
        canReorder: colProps.canReorder,
      } as GroupColumn<T, EntityT>;
    }
    if (isCustomColumnProps(colProps)) {
      return { ...colProps, key: col.key } as CustomColumn<T, EntityT>;
    }
    return { ...colProps, key: col.key } as TypedColumn<T, EntityT>;
  });
}

// посчитать ключ для ячейки
export function getCellKey<T extends Row, EntityT >(row: T, column: Column<T, EntityT>): string {
  const columnKey = getColumnKey(column);
  if (isCustomColumn(column)) {
    return `${row.id}-${columnKey}`;
  }
  if (isGroupColumn(column)) {
    return `${row.id}-${columnKey}`;
  }
  return `${row.id}-${String(columnKey ?? column.keyExpr)}`;
}
