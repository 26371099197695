import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import type { IViewModel } from '@types';
import {
  ErrorState,
  FetchingState,
  SuccessState,
  BaseState,
} from './StateStores';

/** Базовый стор сущности */
abstract class EntityBaseStore<D, VmT extends IViewModel<D>> {
  /** Состояние стора */
  @observable protected _state: BaseState = new BaseState();

  /** Вью модель стора */
  @observable public abstract viewModel: VmT;

  /** Возможность создания */
  @computed public get canCreate(): boolean {
    return false;
  }

  /** Возможность удаления */
  @computed public get canDelete(): boolean {
    return false;
  }

  /** Возможность обновления */
  @computed public get canUpdate(): boolean {
    return false;
  }

  /** Возможность чтения */
  @computed public get canRead(): boolean {
    return false;
  }

  /** Состояние стора */
  @computed public get state() {
    return this._state;
  }

  constructor() {
    makeObservable(this);
  }

  /** Получение данных */
  public abstract fetch(id: number | string): Promise<void>;

  /** Сохранение данных */
  public abstract save(): Promise<void>;

  /** Выполнение кастомного метода с контролированием стейта */
  @action protected async runWithStateControl<T = void>(func: () => (Promise<T>)): Promise<T | undefined> {
    this._state = new FetchingState();
    try {
      const result = await func();
      runInAction(() => {
        this._state = new SuccessState();
      });
      return result;
    } catch (e) {
      runInAction(() => {
        this._state = new ErrorState(e);
      });
      return undefined;
    }
  }

  // #region Clear Methods

  /** Очистка стора */
  @action public clearStore() {
    this.viewModel.clear();
    this._state = new BaseState();
  }

  /** Очистка ошибки */
  @action public clearError() {
    this._state = new SuccessState();
  }

  // #endregion
}

export default EntityBaseStore;
