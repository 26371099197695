import type { UnavailablePeriod } from '@types';
import type { GetResourceInProjectsResponseUnavailableResourceItem } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import UnavailablePeriodModal from '../Modals/UnavailablePeriodModal';

interface UnavailablePeriodProps {
  period: GetResourceInProjectsResponseUnavailableResourceItem;
  rowIndex: number;
  columnWidth: number;
  rowHeight: number;
  startDate: Date;
  unitForDayJS: 'day' | 'month' | 'year' | 'week';
  changeUnavailablePeriod?: (y: number, period: GetResourceInProjectsResponseUnavailableResourceItem, startDate: Dayjs, finishDate: Dayjs) => void;
  deleteUnavailabilityPeriod?: (y: number, period: GetResourceInProjectsResponseUnavailableResourceItem) => void;
}

const UnavailablePeriodComponent: React.FC<UnavailablePeriodProps> = ({
  period, rowIndex, columnWidth, rowHeight, startDate, unitForDayJS, changeUnavailablePeriod, deleteUnavailabilityPeriod,
}) => {
  const startX = useMemo(
    () => dayjs.utc(period.startDate).diff(startDate, unitForDayJS),
    [period.startDate, startDate, unitForDayJS],
  );

  const width = useMemo(
    () => dayjs.utc(period.finishDate).diff(startDate, unitForDayJS) - startX + 1,
    [period.finishDate, startDate, unitForDayJS, startX],
  );

  const [dragX, setDragX] = useState(0);
  const [dragged, setDragged] = useState<'start' |'finish' | undefined>(undefined);
  const [
    unavailabilityPeriodModalData,
    setUnavailabilityPeriodModalData,
  ] = useState<Partial<UnavailablePeriod> & {row: number} | undefined>();

  const listener = useCallback((e: MouseEvent) => {
    setDragX((x) => x + e.movementX);
  }, []);

  const stopDragging = useCallback(
    () => {
      const dayCount = Math.round(dragX / columnWidth);
      const start = dragged === 'start' ? dayjs(period.startDate).add(dayCount, 'day') : dayjs(period.startDate);
      const finish = dragged === 'finish' ? dayjs(period.finishDate).add(dayCount, 'day') : dayjs(period.finishDate);
      changeUnavailablePeriod!(rowIndex, period, start, finish);
      setDragged(undefined);
      document.removeEventListener('mousemove', listener);
      document.removeEventListener('mouseup', stopDragging);
    },
    [changeUnavailablePeriod, rowIndex, period, dragX, columnWidth, dragged, listener],
  );

  useEffect(() => {
    if (dragged) {
      document.addEventListener('mousemove', listener);
      document.addEventListener('mouseup', stopDragging);

      return () => {
        document.removeEventListener('mousemove', listener);
        document.removeEventListener('mouseup', stopDragging);
      };
    }
    return () => {};
  }, [dragged, stopDragging, listener]);

  const rectWidth = useMemo(
    () => {
      if (dragged === 'finish') {
        return columnWidth * (width + Math.round(dragX / columnWidth));
      }
      if (dragged === 'start') {
        return columnWidth * (width - Math.round(dragX / columnWidth));
      }
      return columnWidth * width;
    },
    [columnWidth, width, dragged, dragX],
  );

  return (
    <>
      {unavailabilityPeriodModalData && (
        <UnavailablePeriodModal
          {...unavailabilityPeriodModalData}
          onClose={(e) => {
            if (e) {
              changeUnavailablePeriod!(rowIndex, { ...period, reasonId: e.reasonId, reasonName: e.reasonName }, e.startDate, e.finishDate);
            }
            setUnavailabilityPeriodModalData(undefined);
          }}
          onDelete={deleteUnavailabilityPeriod ? () => {
            deleteUnavailabilityPeriod(
              unavailabilityPeriodModalData.row,
              {
                startDate: unavailabilityPeriodModalData.startDate?.toISOString(),
                finishDate: unavailabilityPeriodModalData.finishDate?.toISOString(),
              },
            );
            setUnavailabilityPeriodModalData(undefined);
          } : undefined}
        />
      )}
      <g className="cursor-pointer">
        <defs>
          <pattern
            height="8"
            id="unavailableHatch"
            patternUnits="userSpaceOnUse"
            width="8"
          >
            <rect
              fill="rgb(255, 132, 132)"
              height={8}
              width={8}
              x={0}
              y={0}
            />
            <path
              d="M-2,2 l4,-4
              M0,8 l8,-8
              M6,10 l4,-4"
              stroke="rgb(254, 64, 64)"
              strokeWidth={2}
            />
          </pattern>
        </defs>

        <rect
          className="fill-[url(#unavailableHatch)] hover:fill-[red] unavailable-period"
          height={rowHeight - 1.5}
          onClick={
            (changeUnavailablePeriod || deleteUnavailabilityPeriod)
              ? () => {
                setUnavailabilityPeriodModalData({
                  reasonId: period.reasonId ?? undefined,
                  reasonName: period.reasonName ?? undefined,
                  startDate: period.startDate ? dayjs.utc(period.startDate) : undefined,
                  finishDate: period.finishDate ? dayjs.utc(period.finishDate) : undefined,
                  row: rowIndex,
                });
              }
              : undefined
          }
          stroke="red"
          strokeWidth={1.5}
          width={rectWidth}
          x={(startX + (dragged === 'start' ? Math.round(dragX / columnWidth) : 0)) * columnWidth}
          y={rowIndex * rowHeight + 1}
        />
        <foreignObject
          className="pointer-events-none"
          height={rowHeight}
          width={rectWidth}
          x={(startX + (dragged === 'start' ? Math.round(dragX / columnWidth) : 0)) * columnWidth}
          y={rowIndex * rowHeight}
        >
          <p
            className="text-ellipsis text-white text-font-main text-center pointer-events-none overflow-hidden"
            style={{ lineHeight: `${rowHeight}px` }}
            // @ts-ignore
            xmlns="http://www.w3.org/1999/xhtml"
          >
            {period.reasonName}
          </p>
        </foreignObject>
        {changeUnavailablePeriod && (
          <rect
            className="cursor-col-resize period-resize-bar"
            fill="transparent"
            height={rowHeight}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragged('start');
              const svgX = e.clientX - ((e.target as SVGRectElement).ownerSVGElement?.getBoundingClientRect().left ?? 0);
              setDragX(svgX - startX * columnWidth);
            }}
            stroke="transparent"
            width={5}
            x={startX * columnWidth}
            y={rowIndex * rowHeight}
          />
        )}
        {changeUnavailablePeriod && (
          <rect
            className="cursor-col-resize period-resize-bar"
            fill="transparent"
            height={rowHeight}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragged('finish');
              const svgX = e.clientX - ((e.target as SVGRectElement).ownerSVGElement?.getBoundingClientRect().left ?? 0);
              setDragX(svgX - (startX + width) * columnWidth);
            }}
            stroke="transparent"
            width={5}
            x={(startX + width) * columnWidth - 5}
            y={rowIndex * rowHeight}
          />
        )}
        {dragged && (
          <line
            stroke="blue"
            strokeWidth={2}
            x1={(startX + (dragged === 'finish' ? width : 0)) * columnWidth + dragX}
            x2={(startX + (dragged === 'finish' ? width : 0)) * columnWidth + dragX}
            y1={0}
            y2="100%"
          />
        )}
      </g>
    </>
  );
};

export default memo(UnavailablePeriodComponent);
