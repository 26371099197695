import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import type BaseEntityListStore from 'src/stores/BaseEntityListStore';
import type { ListBaseFilter } from '@types';
import {
  HiDotsHorizontal,
  HiChevronRight,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronDoubleLeft,
} from 'react-icons/hi';
import { parseStyles } from '@services/utils';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import Select from '../Select/Select';
import type { SelectOption } from '../Select/types';
import type { WithClassName } from '../types';
import { theme } from '../Button/button.config';

const BorderedButton: React.FC<PropsWithChildren & {
  current?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
} & WithClassName> = ({
  children, current = false, disabled = false, onClick, className,
}) => (
  <div
    className={parseStyles`
    ${className}
    rounded-lg w-8 h-8 flex items-center justify-center relative
    ${current && 'border-1 border-main bg-white'}
    ${disabled
    ? 'bg-btn-disabled-bg cursor-not-allowed'
    : `${!current && 'bg-pag-btn-color'} cursor-pointer hover:after:content-[''] hover:after:bg-pag-btn-hover-color 
    hover:after:w-full hover:after:h-full hover:after:absolute hover:after:top-0 hover:after:left-0`
  }
  `}
    onClick={onClick}
  >
    {children}
  </div>
);

const pageSizeOptions: SelectOption[] = [5, 10, 20, 50, 100].map((e) => ({ label: `${e}`, value: `${e}` }));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Pagination: React.FC<WithClassName & {store: BaseEntityListStore<any, ListBaseFilter, any>}> = ({ store, className }) => {
  const buttons = [];
  for (let i = 1; i <= store.pageCount - 1; i++) {
    if (store.pageCount <= 5) {
      buttons.push(i);
    } else if (store.currentPage <= 3 && i < 5) {
      buttons.push(i);
    } else if (store.currentPage > 3 && store.currentPage < store.pageCount - 1
        && (i > store.currentPage - 2 && i < store.currentPage + 2)) {
      buttons.push(i);
    } else if (store.currentPage > store.pageCount - 3 && i > store.pageCount - 4) {
      buttons.push(i);
    }
  }

  /** Является ли текущая страница первой */
  const isFirstPage = useMemo(() => (store.currentPage === 1), [store.currentPage]);

  /** Является ли текущая страница последней */
  const isLastPage = useMemo(() => (store.currentPage === store.pageCount), [store.currentPage, store.pageCount]);

  /** Является ли текущая страница второй */
  const isSecondPage = useMemo(() => (store.currentPage === 2), [store.currentPage]);

  /** Является ли текущая страница предпоследней */
  const isPenultPage = useMemo(() => (store.currentPage === store.pageCount - 1), [store.currentPage, store.pageCount]);

  return (
    <div
      className={parseStyles`
        flex flex-wrap py-2
        ${className}
      `}
    >
      <div className="flex-[1_1_0] items-center flex">
        {/* eslint-disable-next-line no-irregular-whitespace */}
        {`${1 + (store.currentPage - 1) * store.pageSize} — ${Math.min(store.pageSize * store.currentPage, store.totalCount)} из ${store.totalCount}`}
      </div>
      <div className="hidden md:flex gap-2 flex-[1_1_0] justify-center items-center ">
        {(store.pageCount > 5) && (store.currentPage > 3) && (
          <>
            <BorderedButton onClick={() => store.loadPage(1)}>1</BorderedButton>
            <HiDotsHorizontal />
          </>
        )}
        { buttons.map((num) => (
          <BorderedButton
            key={`button${num}`}
            current={store.currentPage === num}
            onClick={() => store.loadPage(num)}
          >
            {num}
          </BorderedButton>
        ))}
        { store.pageCount && (store.pageCount > store.currentPage - 1) && (
          <>
            {
              (store.currentPage < store.pageCount - 2) && (store.pageCount > 5) && (
                <HiDotsHorizontal fill={`${theme.extend.colors['btn-main-bg']}`} />
              )
            }
            <BorderedButton
              current={store.currentPage === store.pageCount}
              onClick={() => store.loadPage(store.pageCount)}
            >
              {store.pageCount}
            </BorderedButton>

          </>
        )}
      </div>
      <div className="flex md:hidden gap-2 flex-[1_1_0] justify-center items-center ">
        {!isFirstPage && (
          <>
            {!isSecondPage && (
              <BorderedButton
                className="hidden sm:flex"
                onClick={() => store.loadPage(1)}
              >
                <HiChevronDoubleLeft />
              </BorderedButton>
            )}
            <BorderedButton
              onClick={() => store.loadPage(store.currentPage - 1)}
            >
              <HiChevronLeft />
            </BorderedButton>
          </>
        )}
        <BorderedButton
          onClick={() => store.loadPage(store.currentPage)}
          current
        >
          {store.currentPage}
        </BorderedButton>
        {!isLastPage && (
          <>
            <BorderedButton
              onClick={() => store.loadPage(store.currentPage + 1)}
            >
              <HiChevronRight />
            </BorderedButton>
            {!isPenultPage && (
              <BorderedButton
                className="hidden sm:flex"
                onClick={() => store.loadPage(store.pageCount)}
              >
                <HiChevronDoubleRight />
              </BorderedButton>
            )}
          </>
        )}

      </div>
      <div className="flex font-semibold text-label  items-center gap-1 flex-[1_1_0] justify-end ">
        <span className="hidden sm:flex text-pagination-text">Показать по:</span>
        <div className="w-pagination-select">
          <Select.Base
            height={30}
            onSelect={(e) => {
              runInAction(() => {
                store.filter.pageNumber = 1;
                store.filter.pageSize = Number(e?.value);
                store.fetch();
              });
            }}
            options={pageSizeOptions}
            selectedOption={{ label: `${store.filter.pageSize}`, value: `${store.filter.pageSize}` }}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(Pagination);
