// хук для получения опций селекта
export const useSelectOptions = <T>(
  entities: (T[] | undefined)[],
  label: keyof T = 'name' as keyof T,
  value: keyof T = 'id' as keyof T,
) => entities
  ?.map((entity) => entity
    ?.map((item) => ({
      label: String(item[label]) ?? '',
      value: String(item[value]) ?? '',
    })));
