import { EntityActions } from '@typesApi';
import { computed, makeObservable } from 'mobx';
import type { BasePrivileges } from './types';

/** стор привелегий по пользователям */
export class AccountsPrivileges implements BasePrivileges {
  private _privileges: EntityActions[];

  constructor(privileges: EntityActions[]) {
    makeObservable(this);
    this._privileges = privileges;
  }

  //* Invite вместо create */
  @computed get canCreate() {
    return this._privileges.includes(EntityActions.InviteEmployees);
  }

  @computed get canUpdate() {
    return this._privileges.includes(EntityActions.UpdateAccounts);
  }

  @computed get canRead() {
    return this._privileges.includes(EntityActions.ReadAccounts);
  }

  /** не существует привилегии */
  @computed get canDelete() {
    return false;
  }

  @computed get canChangeState() {
    return this._privileges.includes(EntityActions.SetAccountState);
  }
}
