import React, { useEffect, useMemo } from 'react';
import type BaseEntityListStore from 'src/stores/BaseEntityListStore';
import type EntityBaseStore from 'src/stores/BaseEntityStore';
import { observer } from 'mobx-react-lite';
import type { ListBaseFilter } from '@types';
import { ErrorState } from 'src/stores/StateStores';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@hooks';
import InfoModal from '../InfoModal/InfoModal';

/** Компонент-модалка, для отображения ошибок */
const Errors: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: BaseEntityListStore<unknown, ListBaseFilter, any> | EntityBaseStore<unknown, any>;
  redirectIf404?: boolean;
  displayFunction?: (value?: string) => React.ReactNode;
}> = ({
  store,
  displayFunction,
  redirectIf404 = true,
}) => {
  const { state } = store;
  const { privilegesStore } = useStores();

  const navigate = useNavigate();

  useEffect(() => {
    if (redirectIf404 && state.isError && (state as ErrorState).errorStatusCode === 404) {
      navigate('/notFound', { replace: true });
    }
  }, [navigate, redirectIf404, state]);

  /** Кнопка перехода на страницу с оплатой */
  const redirectButton = useMemo(() => {
    const canShowRedirectToPayButton = state instanceof ErrorState
      && state.errorStatusCode === 412
      && privilegesStore.canPayOrganizationSubscription;

    return canShowRedirectToPayButton ? ({
      label: 'Настройки организации',
      onClick: () => navigate('/settings/organization'),
    }) : undefined;
  }, [navigate, privilegesStore.canPayOrganizationSubscription, state]);

  return (
    <>
      {state.isError && (
        <InfoModal
          auxiliaryButton={redirectButton}
          header="Произошла ошибка"
          onClose={() => {
            store.clearError();
          }}
          text={displayFunction ? displayFunction(store.state?.error) : store.state.error}
        />
      )}
    </>
  );
};

export default observer(Errors);
