import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Spinner from '@components/ui/Spinner/Spinner';
import Option from './Option';
import type { OptionsProps } from '../types';

/** Опции для селекта */
const Options = forwardRef<HTMLDivElement, OptionsProps>(({
  id,
  options,
  onSelect,
  onClose,
  selectedOption,
  showNoResultText = true,
  noResultText = 'Ничего не найдено',
  error,
  loading,
}, ref) => {
  /** Индекс элемента на который наведен выбор */
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined);

  /** Реф контейнера опций */
  const optionsContainerRef = useMemo(() => createRef<HTMLDivElement>(), []);

  /** Возможность отображения текста об отсутствии результатов */
  const canShowNoResultText = !error && showNoResultText && !options?.length;

  /** Обработчика нажатия KeyDown */
  const keydownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (options?.length) {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            setHoveredIndex((prev) => ((prev === options.length - 1) ? 0 : ((prev ?? 0) + 1)));
            return;
          case 'ArrowUp':
            e.preventDefault();
            setHoveredIndex((prev) => ((prev === 0) ? options.length - 1 : Math.max((prev ?? 0) - 1, 0)));
            return;
          case 'Enter':
            if (onSelect && options?.length && hoveredIndex != null) {
              onSelect(options[hoveredIndex]);
            }
            return;
          case 'Escape':
          case 'Tab':
            if (onClose) {
              onClose();
            }
            break;
          default:
            break;
        }
      }
    },
    [hoveredIndex, onClose, onSelect, options],
  );

  /** Хэндлер удержания выбранного блока в области видимости контейнера опций */
  const scrollToOptionHandler = useCallback(
    () => {
      if (hoveredIndex) {
        optionsContainerRef.current?.children?.[hoveredIndex]
          ?.scrollIntoView({ block: 'nearest', inline: 'center' });
      }
    },
    [hoveredIndex, optionsContainerRef],
  );

  useEffect(() => {
    window.addEventListener('keydown', keydownHandler);
    scrollToOptionHandler();
    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [hoveredIndex, keydownHandler, options, scrollToOptionHandler]);

  /** Для сброса индекса выбранного элемента при изменении количества опций */
  useEffect(() => {
    setHoveredIndex(undefined);
  }, [options?.length]);

  return (
    <div
      ref={ref}
      className="w-full"
    >
      <div
        ref={optionsContainerRef}
        className="shadow-md w-full max-h-options p-[3px] overflow-auto mt-[2px] bg-white scrollbar rounded-lg border-[1px] border-border"
        id={id}
      >
        {!loading && error && (<div className="text-error font-extrabold text-center">{error}</div>)}
        {loading && (<div className="w-full p-[10px] text-slate-400 pl-[15px] bg-white flex justify-center"><Spinner /></div>)}
        {!loading && !error && options?.map((item, index) => (
          <Option
            key={item.value}
            isHovered={index === (hoveredIndex ?? options?.findIndex((e) => e.value === selectedOption?.value))}
            isSelected={selectedOption?.value === item.value}
            label={item.label}
            onClick={() => onSelect && onSelect(item)}
            onMouseMove={() => setHoveredIndex(index)}
            value={item.value}
          />
        ))}
        {!loading && canShowNoResultText && (<div className="w-full p-[10px] text-slate-400 pl-[15px] bg-white">{noResultText}</div>)}
      </div>
    </div>
  );
});

Options.displayName = 'Options';

export default Options;
