import type { GetSubscriptionTypesResponseItem, GetSubscriptionTypesResponseItemVariant } from '@typesApi';
import { computed, makeObservable, observable } from 'mobx';
import BaseViewModel from '../BaseViewModel';

/** Модель элемента массива списка помещений */
class SubscriptionsItemViewModel extends BaseViewModel<GetSubscriptionTypesResponseItem> implements GetSubscriptionTypesResponseItem {
  @observable
    selected: boolean = false;

  constructor(data: GetSubscriptionTypesResponseItem) {
    super(data);
    makeObservable(this);
  }

  /** Получение id */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Название */
  @computed get name() {
    return this.data.name ?? '';
  }

  public get isActual(): boolean | undefined {
    return this.data.isActual;
  }

  public set isActual(value: boolean | undefined) {
    this.data.isActual = value;
  }

  /** Описание */
  public get description(): string | null | undefined {
    return this.data.description;
  }

  public set description(value: string | null | undefined) {
    this.data.description = value;
  }

  /**
   * Количество пользователей
   * @format int32
   */
  public get accounts(): number | null | undefined {
    return this.data.accounts;
  }

  public set accounts(value: number | null | undefined) {
    this.data.accounts = value;
  }

  /**
   * Количество ресурсов
   * @format int32
   */
  public get resources(): number | null {
    return this.data.resources ?? null;
  }

  public set resources(value: number | null | undefined) {
    this.data.resources = value;
  }

  /**
   * Количество проектов
   * @format int32
   */
  public get projects(): number | null {
    return this.data.projects ?? null;
  }

  public set projects(value: number | null | undefined) {
    this.data.projects = value;
  }

  /** Варианты */
  public get variants(): GetSubscriptionTypesResponseItemVariant[] | null | undefined {
    return this.data.variants?.slice().sort((a, b) => (a.term ?? 0) - (b.term ?? 0));
  }

  public set variants(value: GetSubscriptionTypesResponseItemVariant[] | null | undefined) {
    this.data.variants = value;
  }
}

export default SubscriptionsItemViewModel;
