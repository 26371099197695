import type { PlacesTableItem } from '@types';
import { joinNonEmpty } from '@services/utils';
import type { GetPlacesResponseItem, GetPlacesResponseItemProject } from '@typesApi';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import BaseViewModel from '../BaseViewModel';

/** Модель элемента массива списка помещений */
class PlacesViewModel extends BaseViewModel<GetPlacesResponseItem> implements PlacesTableItem {
  @observable
    selected: boolean = false;

  constructor(data: GetPlacesResponseItem) {
    super(data);
    makeObservable(this);
  }

  /** Получение id */
  @computed get id() {
    return this.data.id ?? '';
  }

  /** Получение name */
  @computed get name() {
    return this.data.name ?? '';
  }

  /** Изменение name */
  set name(value: string | undefined) {
    runInAction(() => {
      this.data.name = value;
    });
  }

  /** Получение appointment */
  @computed get appointment() {
    return this.data.appointment ?? '';
  }

  /** Изменение appointment */
  set appointment(value: string | undefined) {
    runInAction(() => {
      this.data.appointment = value;
    });
  }

  /** Получение address */
  @computed get address() {
    return this.data.address ?? '';
  }

  /** Изменение address */
  set address(value: string | undefined) {
    runInAction(() => {
      this.data.address = value;
    });
  }

  /** Получение totalCapacity */
  @computed get totalCapacity() {
    return this.data.totalCapacity;
  }

  /** Изменение totalCapacity */
  set totalCapacity(value: number | undefined | null) {
    runInAction(() => {
      this.data.totalCapacity = value;
    });
  }

  /** Получение numberOfSeats */
  @computed get numberOfSeats() {
    return this.data.numberOfSeats;
  }

  /** Изменение numberOfSeats */
  set numberOfSeats(value: null | undefined | number) {
    runInAction(() => {
      this.data.numberOfSeats = value;
    });
  }

  /** Получение square */
  @computed get square() {
    return this.data.square ?? undefined;
  }

  /** Изменение square */
  set square(value: number | undefined) {
    runInAction(() => {
      this.data.square = value;
    });
  }

  /** Получение responsibleId */
  @computed get responsibleId() {
    return this.data.responsibleId;
  }

  /** Изменение responsibleId */
  set responsibleId(value: string | null | undefined) {
    runInAction(() => {
      this.data.responsibleId = value;
    });
  }

  /** Получение responsibleSurname */
  @computed get responsibleSurname() {
    return this.data.responsibleSurname ?? '';
  }

  /** Изменение responsibleSurname */
  set responsibleSurname(value: string | undefined) {
    runInAction(() => {
      this.data.responsibleSurname = value;
    });
  }

  /** Получение responsibleName */
  @computed get responsibleName() {
    return this.data.responsibleName ?? '';
  }

  /** Изменение responsibleName */
  set responsibleName(value: string | undefined) {
    runInAction(() => {
      this.data.responsibleName = value;
    });
  }

  /** Получение responsiblePatronymic */
  @computed get responsiblePatronymic() {
    return this.data.responsiblePatronymic ?? '';
  }

  /** Изменение responsiblePatronymic */
  set responsiblePatronymic(value: string | undefined) {
    runInAction(() => {
      this.data.responsiblePatronymic = value;
    });
  }

  /** Получение ФИО ответственного */
  @computed get responsibleFullName() {
    return joinNonEmpty([this.data.responsibleSurname, this.data.responsibleName, this.data.responsiblePatronymic], ' ');
  }

  /** Получение lastCleaning */
  @computed get lastCleaning() {
    return this.data.lastCleaning ? dayjs(this.data.lastCleaning) : undefined;
  }

  /** Изменение lastCleaning */
  set lastCleaning(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.lastCleaning = value?.toISOString();
    });
  }

  /** Получение nextCleaning */
  @computed get nextCleaning() {
    return this.data.nextCleaning ? dayjs(this.data.nextCleaning) : undefined;
  }

  /** Изменение nextCleaning */
  set nextCleaning(value: Dayjs | undefined | null) {
    runInAction(() => {
      this.data.nextCleaning = value?.toISOString();
    });
  }

  /** Получение projects */
  @computed get projects() {
    return this.data.projects;
  }

  /** Изменение projects */
  set projects(value: GetPlacesResponseItemProject[] | undefined | null) {
    runInAction(() => {
      this.data.projects = value;
    });
  }
}

export default PlacesViewModel;
