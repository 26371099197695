import { paramsSerializer } from '@services/utils';
import { SalaryPeriodService } from '@servicesHr';
import type { ListBaseFilter } from '@types';
import type { GetSalaryPeriodsResponseItem } from '@typesHr';
import BaseEntityListStore from '../BaseEntityListStore';
import { SalaryPeriodsItemViewModel } from './SalaryPeriodsItemViewModel';

interface SalaryPeriodsFilter extends ListBaseFilter{
  /** Сотрудники */
  employeeIds?: string[];
  /** Максимальная сумма оклада */
  maxSalary?: number;
  /** Минимальная сумма оклада */
  minSalary?: number;
  /** Дата начала периода С */
  startDateFrom?: string;
  /** Дата начала периода ПО */
  startDateTo?: string;
  /** Дата окончания периода С */
  finishDateFrom?: string;
  /** Дата окончания периода ПО */
  finishDateTo?: string;
  /** Ставка в час */
  workingRate?: number;
}

/** Стор сущности */
export class SalaryPeriodsStore extends BaseEntityListStore<GetSalaryPeriodsResponseItem, SalaryPeriodsFilter, SalaryPeriodsItemViewModel> {
  constructor() {
    super(20);
  }

  /** Получение списка */
  public async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await SalaryPeriodService.list(this.filter, {
        paramsSerializer,
      });
      data.entities = data.entities?.map<GetSalaryPeriodsResponseItem>((el) => ({ ...el, id: el.id ?? '' }));
      this.setData(data as {
        entities: GetSalaryPeriodsResponseItem[];
        totalCount: number;
      }, SalaryPeriodsItemViewModel);
    });
  }
}
