import type { WithClassName } from '@components/ui';
import { parseStyles } from '@services/utils';
import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';

type ModalLayoutProps = PropsWithChildren & WithClassName & {
  duration?: number;
}

const ModalLayout = forwardRef<HTMLDivElement, ModalLayoutProps>(({ children, className, duration }, ref) => (
  <div
    ref={ref}
    className={parseStyles`
      m-auto p-4 bg-white rounded-md z-[51]
      ${className}
    `}
    onKeyDown={(e) => {
      e.stopPropagation();
    }}
    style={duration ? { transitionDuration: `${duration}ms` } : undefined}
    tabIndex={-1}
  >
    {children}
  </div>
));
ModalLayout.displayName = 'ModalLayout';

export default ModalLayout;
