import { FormInput, FormTextarea } from '@components/ui/Input';
import Errors from '@components/ui/Errors/Errors';
import { useConfirm, useInterceptChanges, useStores } from '@hooks';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { ConfirmQuestions, ProjectActionsNames } from 'src/enums';
import { useEffectOnce } from 'usehooks-ts';
import Button from '@components/ui/Button';
import CheckBox from '@components/ui/CheckBox/CheckBox';
import DrawerWithWrapper from '../../DrawerResourceLayout/DrawerWithWrapper';
import type { ProjectRoleModalProps } from '../types';
import NotificationsBlock from './NotificationsBlock';

/** Форма изменения/добавления проектной роли */
const ProjectRoleForm: React.FC<ProjectRoleModalProps> = ({ roleInfo, onClose }) => {
  const { projectRoleStore, projectRolesStore } = useStores();

  /** Проверка на изменение формы */
  const { viewModel, isDirty } = useInterceptChanges(projectRoleStore.viewModel);

  const { canUpdate } = projectRoleStore;

  /** Хук для модалки подтверждения */
  const confirm = useConfirm(ConfirmQuestions.confirmExit);

  /** Идентификатор проектной роли */
  const roleId = roleInfo?.roleId;

  /** Заголовок модального окна с ролью */
  const title = roleId ? `Проектная роль ${roleInfo.name}` : 'Создание роли';

  /** Обработчик нажатия на кнопку Сохранить */
  const saveHandler = useCallback(async (): Promise<void> => {
    if (viewModel.validate()) {
      if (viewModel.id) {
        await projectRoleStore.save();
      } else {
        await projectRoleStore.create();
      }

      if (projectRoleStore.state.isSuccess) {
        onClose();
        projectRolesStore.fetch();
      }
    }
  }, [onClose, projectRoleStore, projectRolesStore, viewModel]);

  useEffectOnce(() => {
    if (roleId) {
      projectRoleStore.fetch(roleId);
    }
    return () => {
      projectRoleStore.clearStore();
    };
  });

  /** Кнопка сохранения формы */
  const SaveButton = useMemo(() => (
    <div>
      {canUpdate && (
        <Button.Primary
          key="create"
          label="Сохранить"
          onClick={saveHandler}
        />
      )}
    </div>
  ), [canUpdate, saveHandler]);

  return (
    <DrawerWithWrapper
      className="min-w-[50%] max-w-[950px]"
      confirm={isDirty ? confirm : undefined}
      footer={SaveButton}
      onClose={onClose}
      title={title}
    >
      <div className="flex flex-col gap-form">
        <FormInput
          disabled={!canUpdate}
          error={viewModel.errors.name}
          label="Название"
          onChange={(event) => {
            viewModel.name = event.target.value;
          }}
          value={viewModel.name}
        />
        <FormTextarea
          disabled={!canUpdate}
          label="Описание"
          onChange={(event) => {
            viewModel.description = event.target.value;
          }}
          value={viewModel.description}
        />
        <div className="my-2 ml-1">Привилегии</div>
        <CheckBox
          checked={viewModel.updateResource}
          className="mb-2"
          isDisabled={!canUpdate}
          label={ProjectActionsNames.updateResource}
          onChange={() => {
            viewModel.updateResource = !viewModel.updateResource;
          }}
        />
        <CheckBox
          checked={viewModel.updateProject}
          className="mb-2"
          isDisabled={!canUpdate}
          label={ProjectActionsNames.updateProject}
          onChange={() => {
            viewModel.updateProject = !viewModel.updateProject;
          }}
        />
        <CheckBox
          checked={viewModel.deleteProject}
          className="mb-2"
          isDisabled={!canUpdate}
          label={ProjectActionsNames.deleteProject}
          onChange={() => {
            viewModel.deleteProject = !viewModel.deleteProject;
          }}
        />
        <CheckBox
          checked={viewModel.readProjectRequirement}
          className="mb-2"
          isDisabled={!canUpdate}
          label={ProjectActionsNames.readProjectRequirement}
          onChange={() => {
            viewModel.readProjectRequirement = !viewModel.readProjectRequirement;
          }}
        />
        <CheckBox
          checked={viewModel.updateProjectRequirement}
          className="mb-2"
          isDisabled={!canUpdate}
          label={ProjectActionsNames.updateProjectRequirement}
          onChange={() => {
            viewModel.updateProjectRequirement = !viewModel.updateProjectRequirement;
          }}
        />
        <NotificationsBlock
          disabled={!canUpdate}
          viewModel={viewModel}
        />
        <Errors store={projectRolesStore} />
        <Errors store={projectRoleStore} />
      </div>
    </DrawerWithWrapper>
  );
};

export default observer(ProjectRoleForm);
