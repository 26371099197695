import { ProjectRequirementService } from '@servicesApi';
import type { ListBaseFilter } from '@types';
import type { GetProjectRequirementsResponseItem } from '@typesApi';
import BaseEntityListStore from '../BaseEntityListStore';
import { RequirementsItemViewModel } from './ProjectRequirementsItemViewModel';

/** Список потребностей */
export class ProjectRequirementsStore extends BaseEntityListStore<
GetProjectRequirementsResponseItem, ListBaseFilter & {projectRequirement: string}, RequirementsItemViewModel> {
  constructor() {
    super(20);
  }

  /** Загрузка всех потребностей  */
  public async fetch(): Promise<void> {
    await this.runWithStateControl(async () => {
      const { data } = await ProjectRequirementService.list(this.filter);
      data.entities = data.entities?.map<GetProjectRequirementsResponseItem>((el) => ({ ...el, id: el.id || '' }));
      this.setData(data as {
        entities: GetProjectRequirementsResponseItem[];
        totalCount: number;
      }, RequirementsItemViewModel);
    });
  }

  /** Удаление потребностей */
  public async delete(ids: string[]) {
    await this.runWithStateControl(async () => {
      await Promise.all(ids.map((id) => ProjectRequirementService.delete(id)));
    });
  }
}
