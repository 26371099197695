import { useEffect, useState } from 'react';
import { SiteTitle } from 'src/enums';

export const useTitle = (title: string) => {
  const [prevTitle, setPrevTitle] = useState<string>();
  useEffect(() => {
    setPrevTitle((prev) => prev ?? document.title);
    if (title) {
      document.title = title;
    } else {
      document.title = prevTitle ?? SiteTitle;
    }
    return () => {
      document.title = prevTitle ?? SiteTitle;
    };
  }, [prevTitle, title]);
};
