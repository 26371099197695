import { SOnPrem, SOnline } from '@assets';
import Button from '@components/ui/Button';
import type { FunctionComponent, FC } from 'react';
import React, { useCallback } from 'react';

interface SubscriptionCardProps {
  subscriptionTypeId: string;
  variantId: string;
  name: string;
  cost?: number;
  duration: number;
  description?: string | null;
  priceDescription?: string | null;
  onVariantClick?: (subscriptionTypeId: string, variantId: string) => void;
}

const SubscriptionCard: FunctionComponent<SubscriptionCardProps> = ({
  subscriptionTypeId, variantId, name, cost, description,
  priceDescription, duration, onVariantClick,
}) => {
  const onBuyClickHandler = useCallback(async () => {
    if (subscriptionTypeId && variantId) {
      onVariantClick?.(subscriptionTypeId, variantId);
    }
  }, [onVariantClick, subscriptionTypeId, variantId]);

  return (
    <div
      className="group cursor-pointer flex flex-col border-1 border-slate-200 bg-white p-4 hover:shadow-xl transition-all w-[280px] min-w-[280px]"
      onClick={onBuyClickHandler}
    >
      <div className="text-label">Online подписка</div>
      <div className="pt-5 text-lg flex flex-row items-center">
        Тариф:
        {' '}
        {name ?? ''}
        {' '}
        <SOnline className="pb-1 h-7 w-7" />
        {`(${duration ?? ''} мес.)`}
      </div>
      <div className="pt-5 whitespace-pre-line">
        {description}
      </div>
      <div className="pt-5 self-center text-label text-main flex flex-1 flex-col items-center justify-end">
        {priceDescription}
        <Button.Primary
          className="!rounded-full self-center mt-1 !bg-orange-500 w-44 group-hover:w-60 transition-all duration-300"
          label={`КУПИТЬ ЗА ${cost?.toLocaleString() ?? ''}₽`}
        />
      </div>
    </div>
  );
};

export const OnPremiseTariff: FC = () => (
  <div
    className="group cursor-pointer flex flex-col border-1 border-slate-200 bg-white p-4 w-[280px] min-w-[280px] hover:shadow-xl transition-all"
    onClick={() => {
      window.open('mailto:info@a2planner.ru');
    }}
  >
    <div className="text-label">On-Premise решение</div>
    <div className="pt-5 text-lg flex flex-row items-center">
      Тариф: On-Premise
      <SOnPrem className="ml-1 pb-1 h-7 w-7" />
    </div>
    <div className="pt-5 whitespace-pre-line">
      {'On-Premise развертывание системы во внутреннем контуре.\n\nСоздавайте неограниченное количество записей и пользователей.'}
    </div>
    <div className="pt-5 self-center text-label text-main flex flex-1 flex-col items-center justify-end">
      Напишите нам на info@a2planner.ru
      <Button.Primary
        className="!rounded-full self-center mt-1 !border-orange-500 border-2
       !text-orange-500 !bg-white w-48 group-hover:w-60 transition-all duration-300"
        label="СВЯЗАТЬСЯ С НАМИ"
      />
    </div>
  </div>
);

export default SubscriptionCard;
