import type { ReactElement } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import './App.css';
import type { RouterProps } from 'react-router-dom';
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MainLayout from '@components/MainLayout';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import ProtectedRoute from '@components/ui/ProtectedRoute/ProtectedRoute';
import { observer } from 'mobx-react-lite';
import ContentLayout from '@components/ContentLayout';
import TestModal from './views/TestModal';
import TokenComponent from './components/TokenComponent';
import { Redirect } from './views/Redirect';
import { ConfirmModalContext } from './contexts/ConfirmModalContext';
import ConfirmModal from './components/ConfirmModal/ConfirmModal';
import type { ConfirmRefProps } from './components/ConfirmModal/types';
import { useStores } from './hooks/useStores';
import type { RouteItem } from './routes';
import projectRoutes from './routes';
import Login from './views/Login';
import Registration from './views/Registration';
import AccountNotActive from './views/AccountNotActive';
import { ConfirmEmail } from './views/ConfirmEmail';
import StatusCode402 from './views/StatusCode402';
import Profile from './views/Profile';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

const App: React.FC = () => {
  const { privilegesStore, accountStore } = useStores();
  const [ref, setRef] = useState<ConfirmRefProps | null>(null);

  const confirm = useMemo(() => {
    if (ref?.confirm) {
      return ref.confirm;
    }
    return () => {
      throw new Error('Function not implemented');
    };
  }, [ref]);

  const contextValue = useMemo(() => ({ confirm }), [confirm]);

  /** Отрисовка роутов с защитой */
  const renderRoutes = useCallback(
    (el: RouteItem): ReactElement<RouterProps> => (
      <Route
        key={el.path}
        element={(!!el.checkAccess && el.element)
          ? (
            <ProtectedRoute
              checkAccess={() => el.checkAccess!(privilegesStore)}
              component={el.element}
            />
          )
          : el.element}
        path={el.path}
      >
        {el.children?.map((e) => renderRoutes(e))}
      </Route>
    ),
    [privilegesStore],
  );

  /** Роуты приложения */
  const routes = useMemo(() => projectRoutes.map((el) => renderRoutes(el)), [renderRoutes]);

  return (
    <ConfirmModalContext.Provider value={contextValue}>
      <Router>
        <TokenComponent />
        <Routes>
          {accountStore.isActive
            ? (
              <Route element={<MainLayout />}>
                <Route
                  element={<Navigate to="/projectsLoad" />}
                  path="/"
                />
                {routes}
                <Route
                  element={(
                    <ContentLayout>
                      <Profile />
                    </ContentLayout>
                  )}
                  path="/profile"
                />
                <Route
                  element={<StatusCode402 />}
                  path="/statusCode402"
                />
              </Route>
            )
            : (
              <Route
                element={<AccountNotActive />}
                path="*"
              />
            )}
          <Route
            element={<Login />}
            path="/login"
          />
          <Route
            element={<Registration />}
            path="/registration"
          />
          <Route
            element={<ConfirmEmail />}
            path="/confirmEmail"
          />
          <Route
            element={<TestModal />}
            path="/testModal"
          />
          <Route
            element={<Redirect />}
            path="/redirect"
          />
        </Routes>
      </Router>
      <ConfirmModal
        ref={setRef}
      />
    </ConfirmModalContext.Provider>
  );
};

export default observer(App);
